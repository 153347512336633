const statusColor = {
    default: "neutral",
    danger: "error",
    ok: "success",
    warning: "warning",
    primary: "primary",
};

export default {
    id: "invoice_statuses",
    data: [
        {
            id: "DRAFT",
            label: "ref.financial_report.invoice_statuses.DRAFT",
            color: statusColor.default,
        },
        {
            id: "PENDING",
            label: "ref.financial_report.invoice_statuses.PENDING",
            color: statusColor.warning,
        },
        {
            id: "SENT",
            label: "ref.financial_report.invoice_statuses.SENT",
            color: statusColor.primary,
        },
        {
            id: "PAID",
            label: "ref.financial_report.invoice_statuses.PAID",
            color: statusColor.ok,
        },
        {
            id: "OVERDUE",
            label: "ref.financial_report.invoice_statuses.OVERDUE",
            color: statusColor.danger,
        },
        {
            id: "INCOMPLETE",
            label: "ref.financial_report.invoice_statuses.INCOMPLETE",
            color: statusColor.default,
        },
    ],
    translate: true,
};
