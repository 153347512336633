import { useCallback } from "react";
import { multiReplaceInUrlQuery } from "react-url-query";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import { useP } from "../../../../services/i18n";
import FilterIcon from "mdi-material-ui/Filter";
import makeStyles from "@mui/styles/makeStyles";
import { URL_SELECTION_KEY } from "../../../constants/urls";

const useStylesSelectionFilterChip = makeStyles((theme) => {
    return {
        icon: {
            color: theme.palette.neutral.main,
            marginLeft: 2,
        },
    };
});

export function SelectionFilterChip(props) {
    const { disableActions } = props;

    const p = useP();

    const classes = useStylesSelectionFilterChip();

    const handleDelete = useCallback(() => {
        multiReplaceInUrlQuery({
            [URL_SELECTION_KEY]: undefined,
            inSearch: true,
            fromRedirect: undefined,
            noSubmit: undefined,
        });
    }, []);

    return (
        <HiChip
            id="chip-filter-selection"
            icon={<FilterIcon />}
            label={p.t("common.search.filters.selection")}
            classes={{ icon: classes.icon }}
            onDelete={disableActions ? undefined : handleDelete}
        />
    );
}
