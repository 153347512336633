export const genders = {
    id: "genders",
    data: [
        // { id: 'U', label: 'genders.U' },
        {
            id: "F",
            label: "ref.genders.F",
            icon: "fa_venus",
        },
        {
            id: "M",
            label: "ref.genders.M",
            icon: "fa_mars",
        },
    ],
    translate: true,
};
