import React, { useCallback, useState } from "react";
import { useP, setEventTracker } from "../../../../services";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Poll from "mdi-material-ui/Poll";
import Drag from "mdi-material-ui/Drag";
import { HideAnalyticsIcon } from "../../../icons";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { PanelOrderKpis } from "./PanelOrderKpis";

const useStylesMenuAnalytics = makeStyles((theme) => ({
    root: { display: "inline-block" },
    icon: { minWidth: 40 },
    text: { ...theme.typography.body2 },
}));

export const AnalyticsMenu = (props) => {
    const {
        displayBanner,
        toggleBanner,
        orderedKpis,
        defaultKpis,
        onKpiOrderUpdate,
        persistOrderedKpis,
        kpisConfig,
    } = props;

    const p = useP();
    const classes = useStylesMenuAnalytics();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [panel, setPanel] = React.useState("menu");

    // open menu
    const handleOpen = useCallback(
        (event) => {
            setEventTracker("manage", {
                event_category: "analysis_banner",
                event_action: "manage_analysis_banner",
            });
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    // close menu
    const handleClose = useCallback(() => {
        setPanel("menu");
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleClickOrderKpis = useCallback(() => {
        setEventTracker("organise_widgets", {
            event_category: "analysis_banner",
            event_action: "organise_analysis_banner",
        });
        setPanel("order-kpis");
    }, []);

    const handleGoBack = useCallback(() => {
        setPanel("menu");
    }, []);

    const handleToggleBanner = useCallback(() => {
        handleClose();
        toggleBanner();
    }, []);

    return (
        <div className={classes.root}>
            <IconButton
                id="analytics-banner-menu-button"
                aria-controls="analytics-banner-menu-button"
                aria-haspopup="true"
                title={p.t("common.search.buttons.data_analytics")}
                onClick={handleOpen}
                color="info"
                size="large"
            >
                <Poll />
            </IconButton>
            <Menu
                id="analytics-banner-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ dense: true }}
                autoFocus={true}
            >
                {panel === "menu" && [
                    <MenuItem
                        key="analytics-banner-menu-item-display"
                        id="analytics-banner-menu-item-display"
                        className={classes.item}
                        onClick={handleToggleBanner}
                        dense
                        title={
                            displayBanner
                                ? p.t("common.search.analytics_banner.hide")
                                : p.t("common.search.analytics_banner.show")
                        }
                    >
                        <ListItemIcon sx={{ color: "neutral.main" }}>
                            {displayBanner ? (
                                <HideAnalyticsIcon color={theme.palette.neutral.main} />
                            ) : (
                                <Poll />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            className={classes.text}
                            primary={
                                displayBanner
                                    ? p.t("common.search.analytics_banner.hide")
                                    : p.t("common.search.analytics_banner.show")
                            }
                            disableTypography
                        />
                    </MenuItem>,
                    <Divider key="analytics-banner-menu-divider" />,
                    <MenuItem
                        key="analytics-banner-menu-item-widgets"
                        id="analytics-banner-menu-item-widgets"
                        className={classes.item}
                        onClick={handleClickOrderKpis}
                        dense
                        title={p.t("common.search.analytics_banner.organize")}
                        disabled={!displayBanner}
                    >
                        <ListItemIcon sx={{ color: "neutral.main" }}>
                            <Drag />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.text}
                            primary={p.t("common.search.analytics_banner.organize")}
                            disableTypography
                        />
                    </MenuItem>,
                ]}
                {panel === "order-kpis" && (
                    <PanelOrderKpis
                        orderedKpis={orderedKpis}
                        defaultKpis={defaultKpis}
                        onKpiOrderUpdate={onKpiOrderUpdate}
                        persistOrderedKpis={persistOrderedKpis}
                        onGoBack={handleGoBack}
                        kpisConfig={kpisConfig}
                        onClose={handleClose}
                    />
                )}
            </Menu>
        </div>
    );
};
