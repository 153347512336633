import {
    DISPLAY_ADDRESS,
    DISPLAY_DATE,
    DISPLAY_ICON,
    DISPLAY_IMAGE,
    DISPLAY_NUMERIC,
    DISPLAY_AMOUNT,
    DISPLAY_RATE,
    DISPLAY_SENTINEL,
    DISPLAY_SCORE,
    DISPLAY_TEXT,
    DISPLAY_LONG_TEXT,
    DISPLAY_CUSTOM_DATA,
    DISPLAY_AVATAR,
    DISPLAY_CHECKBOX,
    DISPLAY_BOOLEAN,
    DISPLAY_CHIP,
} from "./displayTypes";

import { VIEWS } from "../../constants";

/**
 * Infinite scroll variables
 */

export const MAX_ROWS = 50;
export const NB_ROWS_BACK_TO_TOP = 10;

/**
 * Cell height (px)
 */
export const CELL_HEIGHT = 40;
export const CELL_HEIGHT_DENSE = 32;
export const HEADER_CELL_HEIGHT = 40;
export const HEADER_CELL_HEIGHT_DENSE = 40;

export const getCellHeight = (dense = false) => {
    return dense ? CELL_HEIGHT_DENSE : CELL_HEIGHT;
};
export const getHeaderCellHeight = (dense = false) => {
    return dense ? HEADER_CELL_HEIGHT_DENSE : HEADER_CELL_HEIGHT;
};

/**
 * Trendchip evolution
 */
export const EVOLUTION_UP = "up";
export const EVOLUTION_DOWN = "down";
export const NO_EVOLUTION = "equal";

/**
 * Ellipsis
 */
export const ELLIPSIS_AFTER_FIRST_WORD = "after-first-word";
export const ELLIPSIS_LEFT = "left";
export const ELLIPSIS_MIDDLE = "middle";
export const ELLIPSIS_NAME = "name";
export const ELLIPSIS_RIGHT = "right";

/**
 * Fraud Result
 */
export const FRAUD_RESULT_ACCEPTED = "ACCEPTED";
export const FRAUD_RESULT_BLOCKED = "BLOCKED";
export const FRAUD_RESULT_CHALLENGED = "CHALLENGED";
export const FRAUD_RESULT_PENDING = "PENDING";
export const FRAUD_RESULT_FORCE_AUTHENTICATE = "FORCE_AUTHENTICATE";
export const FRAUD_RESULT_ASK_AUTHENTICATE = "ASK_AUTHENTICATE";

/**
 * Cell default width for each views
 * @type {{}}
 */
export const DEFAULT_WIDTHS = {
    [DISPLAY_ADDRESS]: {
        [VIEWS.LARGE]: 120,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_CHECKBOX]: {
        [VIEWS.LARGE]: 40,
        [VIEWS.MEDIUM]: 40,
        [VIEWS.SMALL]: 40,
    },
    [DISPLAY_DATE]: {
        [VIEWS.LARGE]: 250,
        [VIEWS.MEDIUM]: 140,
        [VIEWS.SMALL]: 120,
    },
    [DISPLAY_ICON]: {
        [VIEWS.LARGE]: 200,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 60,
    },
    [DISPLAY_IMAGE]: {
        [VIEWS.LARGE]: 200,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 60,
    },
    [DISPLAY_NUMERIC]: {
        [VIEWS.LARGE]: 120,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_AMOUNT]: {
        [VIEWS.LARGE]: 120,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_RATE]: {
        [VIEWS.LARGE]: 100,
        [VIEWS.MEDIUM]: 80,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_SENTINEL]: {
        [VIEWS.LARGE]: 200,
        [VIEWS.MEDIUM]: 120,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_SCORE]: {
        [VIEWS.LARGE]: 80,
        [VIEWS.MEDIUM]: 80,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_TEXT]: {
        [VIEWS.LARGE]: 200,
        [VIEWS.MEDIUM]: 120,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_LONG_TEXT]: {
        [VIEWS.LARGE]: 800,
        [VIEWS.MEDIUM]: 600,
        [VIEWS.SMALL]: 400,
    },
    [DISPLAY_CUSTOM_DATA]: {
        [VIEWS.LARGE]: 260,
        [VIEWS.MEDIUM]: 200,
        [VIEWS.SMALL]: 80,
    },
    [DISPLAY_AVATAR]: {
        [VIEWS.LARGE]: 180,
        [VIEWS.MEDIUM]: 140,
        [VIEWS.SMALL]: 60,
    },
    [DISPLAY_BOOLEAN]: {
        [VIEWS.LARGE]: 120,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 70,
    },
    [DISPLAY_CHIP]: {
        [VIEWS.LARGE]: 120,
        [VIEWS.MEDIUM]: 100,
        [VIEWS.SMALL]: 80,
    },
};

export const getDefaultWidth = (type, view = "l") => {
    if (DEFAULT_WIDTHS[type] && DEFAULT_WIDTHS[type][view]) {
        return DEFAULT_WIDTHS[type][view];
    }
    return 120;
};

/**
 * Cell types which are align right by default
 * @type {*[]}
 */
export const ALIGN_RIGHT_TYPES = [DISPLAY_NUMERIC, DISPLAY_AMOUNT, DISPLAY_RATE];
