const providers = {
    id: "providers",
    endpoint: "/internal/providers",
    withSelectedAccounts: true,
    translate: false,
    reduceFunc: (previous, current) =>
        typeof current["providerId"] !== "undefined"
            ? [
                  ...previous,
                  {
                      id: current["providerId"].toString(),
                      label: current["provider"],
                      info: current["providerId"],
                  },
              ]
            : previous,
};

export default providers;
