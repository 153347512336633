import { buildAccountDisplayPropsFromEntities } from "@console/core";

export default (exportFilters, p, accountList, businessList, maxLength) => {
    // Lors d'un export sur email, exportFilters est un string
    if ("string" === typeof exportFilters) {
        exportFilters = JSON.parse(exportFilters);
    }
    // Build account display to create default filename
    let accountIdList = [];

    // TO DO : REMOVE THIS WHEN EXPORTFILTERS WILL BE CONVERTED
    if (exportFilters["financial_operation_id"]) {
        exportFilters["opid[]"] = exportFilters["financial_operation_id"];
        delete exportFilters.financial_operation_id;
    }

    if (exportFilters["account.account_id[]"]) {
        accountIdList = Array.isArray(exportFilters["account.account_id[]"])
            ? exportFilters["account.account_id[]"]
            : [exportFilters["account.account_id[]"]];
        accountIdList = accountIdList.filter((id) => id !== "_every");
    }

    const accountDisplayProps = buildAccountDisplayPropsFromEntities(
        p,
        accountIdList,
        accountList,
        businessList,
        null,
        false // hasLoading
    );

    let label = accountDisplayProps.label;

    // handle lookup on account.name
    if (exportFilters["account.name"]) {
        const res = exportFilters["account.name"].match(/^"(.*)"$/);
        if (res) {
            label = res[1];
        }
    }

    // join term by '_', slice to max and set to uppercase
    return label.split(" ").join("_").slice(0, maxLength).toUpperCase();
};
