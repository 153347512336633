import { connect } from "react-redux";
import { Body } from "../../../components/Search/Grid/Body";

const mapStateToProps = (state, ownProps) => {
    return {
        dateFormat: state.app.settings.data.dateFormat,
        dateFormatShort: state.app.settings.data.dateFormatShort,
        dateLocale: state.app.settings.data.language.slice(0, 2),
        numberLocale: state.app.settings.data.language,
        userSettings: state.app.settings.data,
        accountIdList: state.app.global.selectedAccountIdList,
        references: state.ref,
    };
};

export default connect(mapStateToProps)(Body);
