export function filterModules(modules, canHook, isInternal) {
    return modules.filter((_module) => {
        if (_module.internal) {
            return (
                isInternal &&
                process.env.NX_IS_INTERNAL === "true" &&
                canHook("manage-module", _module.id)
            );
        }
        return canHook("manage-module", _module.id);
    });
}
