import { useCallback, useEffect } from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import { useP } from "../../../../../services/i18n";
import { useAsync } from "react-async";
import { useApi } from "../../../../../services/api";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import HiPin from "@hipay/hipay-material-ui/HiPin";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import EmailSyncOutline from "mdi-material-ui/EmailSyncOutline";
import Download from "mdi-material-ui/Download";
import { ExportByEmailIcon } from "../Menu/ExportByEmailIcon";
import { updateExportFile } from "../../../../../app/actions/appActions";

const useStylesExportFileRow = makeStyles((theme) => ({
    cell: {
        maxWidth: 200,
        maxHeight: 40,
        padding: "0px 4px",
        ...theme.typography.body3,
        borderBottom: "none",
        verticalAlign: "middle",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    contentCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        alignItems: "center",
        whiteSpace: "nowrap",
        wordBreak: "break-word",
    },
    flexContent: { display: "flex" },
    iconButton: {
        width: 32,
        height: 32,
        padding: "4px 4px",
        backgroundColor: theme.palette.neutral.main,
        margin: "3px 2px",
        "&:hover, &:focus": { backgroundColor: theme.palette.primary.dark },
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    coloredCell: { marginTop: 4 },
    iconSize20: { fontSize: 20 },
    lastCell: { justifyContent: "right" },
}));

export const ExportFileRow = (props) => {
    const { exportFile, exportItem, nbExportFiles, index, page } = props;

    const { get } = useApi();
    const classes = useStylesExportFileRow();
    const p = useP();
    const dispatch = useDispatch();

    const userSettings = useSelector((state) => state.app.settings.data);

    const { run: sendExportFile } = useAsync({
        deferFn: get,
        url: `/export-files/${exportFile.fileId}/email?hash=${exportFile.hash}`,
        undoable: false,
        successSnackbar: {
            message: p.t("common.search.export.list.export_files.snackbar.resend_success", {
                filename: exportFile.filename,
            }),
        },
        failureSnackbar: {
            message: p.t("common.search.export.list.export_files.snackbar.resend_error"),
        },
    });

    const { data: regeneratedFile, run: regenerateExportFile } = useAsync({
        deferFn: get,
        url: (fileId, hash) => `/export-files/${fileId}/regenerate?hash=${hash}`,
        undoable: true,
        pendingSnackbar: {
            message: p.t("common.search.export.list.export_files.snackbar.pending"),
        },
        successSnackbar: {
            message: p.t("common.search.export.list.export_files.snackbar.regenerate_success"),
        },
        failureSnackbar: {
            message: p.t("common.search.export.list.export_files.snackbar.regenerate_error"),
        },
    });

    const canDownload = () => {
        return ["stocked", "sent", "send_error"].includes(exportFile.status);
    };

    const handleGenerateExport = () => {
        regenerateExportFile(exportFile.fileId, exportFile.hash);
    };

    const getExpirationDate = () => {
        return moment(exportFile.dateCreated).add(7, "days");
    };

    const nbDaysBeforeExpiration = () => {
        const nbDaysDiff = getExpirationDate(exportFile)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .set("millisecond", 0)
            .diff(
                moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
                "days"
            );
        if (exportFile.status === "expired" || nbDaysDiff < 0) {
            return null;
        } else if (nbDaysDiff !== 0) {
            return p.t("common.search.export.list.export_files.nb_days", {
                smart_count: nbDaysDiff,
            });
        }
        return p.t("common.search.export.list.export_files.today");
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "created":
            case "stocked":
                return "primary";
            case "send_error":
            case "generate_error":
                return "error";
            case "sent":
                return "success";
            case "expired":
            default:
                return "neutral";
        }
    };

    useEffect(() => {
        if (regeneratedFile) {
            dispatch(updateExportFile(exportItem.exportId, regeneratedFile));
        }
    }, [regeneratedFile]);

    const handleKeyDownBtn = useCallback((event) => {
        event.stopPropagation();
        if (event.keyCode === 32 && event.currentTarget.tagName === "A") {
            // keyCode === 32 => space
            event.preventDefault();
            event.currentTarget.click();
        }
    }, []);

    return (
        <TableRow style={{ height: 40 }}>
            <TableCell padding="none" className={classes.cell} key="index">
                <div className={classes.contentCell}>{nbExportFiles - index - (page - 1) * 10}</div>
            </TableCell>
            <TableCell
                padding="none"
                className={classes.cell}
                title={moment(exportFile.dateCreated).format(`${userSettings.dateFormatShort} LTS`)}
                key="date_send"
            >
                <div className={classes.contentCell}>
                    {moment(exportFile.dateCreated).format(userSettings.dateFormatShort)}
                </div>
            </TableCell>
            <TableCell
                padding="none"
                className={classes.cell}
                key="filename"
                title={exportFile.filename}
            >
                <div className={classes.contentCell}>{exportFile.filename}</div>
            </TableCell>
            <TableCell
                padding="none"
                className={classes.cell}
                key="status"
                title={
                    exportFile.status && exportFile.status.includes("error")
                        ? p.t(`common.search.export.status.title.${exportFile.status}`)
                        : undefined
                }
            >
                {exportFile.status && (
                    <div className={classNames(classes.contentCell, classes.flexContent)}>
                        <HiColoredLabel
                            label={p.t(`common.search.export.status.${exportFile.status}`)}
                            color={getStatusColor(exportFile.status)}
                            fontSize={13}
                        />
                    </div>
                )}
            </TableCell>
            <TableCell padding="none" className={classes.cell} key="nb_lines">
                <div className={classes.contentCell}>
                    {(!!exportFile.nbItems || exportFile.nbItems === 0) && (
                        <HiPin color="neutral">{exportFile.nbItems}</HiPin>
                    )}
                </div>
            </TableCell>
            <TableCell
                padding="none"
                className={classes.cell}
                title={
                    exportFile.status !== "expired"
                        ? getExpirationDate().format(userSettings.dateFormatShort)
                        : undefined
                }
                key="expires_in"
            >
                <div className={classes.contentCell}>{nbDaysBeforeExpiration()}</div>
            </TableCell>
            <TableCell padding="none" className={classes.cell} key="actions">
                <div
                    className={classNames(
                        classes.contentCell,
                        classes.lastCell,
                        classes.flexContent
                    )}
                >
                    {canDownload() ? (
                        <>
                            <HiIconButton
                                color="#FFFFFF"
                                className={classes.iconButton}
                                href={`${process.env.NX_CONSOLE_API_URL}/export-files/${exportFile.fileId}?hash=${exportFile.hash}`}
                                title={p.t(
                                    "common.search.export.list.export_files.button.download"
                                )}
                                onKeyDown={handleKeyDownBtn}
                            >
                                <Download className={classes.iconSize20} />
                            </HiIconButton>
                            <HiIconButton
                                color="#FFFFFF"
                                className={classes.iconButton}
                                onClick={sendExportFile}
                                title={p.t("common.search.export.list.export_files.button.resend")}
                                onKeyDown={handleKeyDownBtn}
                            >
                                <ExportByEmailIcon size={20} />
                            </HiIconButton>
                        </>
                    ) : exportFile.status !== "created" ? (
                        <HiIconButton
                            color="#FFFFFF"
                            className={classes.iconButton}
                            onClick={handleGenerateExport}
                            title={p.t("common.search.export.list.export_files.button.regenerate")}
                            onKeyDown={handleKeyDownBtn}
                        >
                            <EmailSyncOutline className={classes.iconSize20} />
                        </HiIconButton>
                    ) : null}
                </div>
            </TableCell>
        </TableRow>
    );
};
