import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ConfigContext } from "./config-context";

/**
 * /!\ WARNING /!\
 * As the ConfigProvider is used by Console Master,
 * you should not introduce breaking changes !
 *
 * If updates are required, please tag old functions as deprecated.
 *
 * Provide access to master config (menu, modules, routes, ...)
 *
 * module config should have this format:
 * {{
 *     transaction: {
            attributes,
            defaultFormFields,
            id: 'transaction',
            icon: 'hi_transaction',
            label: 'app.menu.transaction.label',
            path: 'transactions',
            route: '[prefix]/transactions',
            features: [ 'qsearch', 'form', 'export' ],
            references: { reference_one : {...}}
 *     },
 *     widget: { ... }
 * }}
 *
 */
export function ConfigProvider(props) {
    const {
        menu = null, // menu
        modules = null, // module's configs
    } = props;

    if (menu === null) {
        console.error("ConfigProvider require menu configuration.");
    }
    if (modules === null) {
        console.error("ConfigProvider require modules configuration.");
    }

    const getConfigByModule = useCallback(
        (module = null) => {
            if (module && props.modules[module]) {
                return {
                    ...props.modules[module],
                    has: (f) =>
                        props.modules[module].features &&
                        props.modules[module].features.includes(f),
                };
            }

            return null;
        },
        [props.modules]
    );

    /**
     * Return modules list with active feature
     * @param feature
     * @returns {string[]}
     */
    const getModuleListByFeature = useCallback(
        (feature = null) => {
            if (feature) {
                return Object.keys(props.modules).filter(
                    (key) =>
                        props.modules[key].features && props.modules[key].features.includes(feature)
                );
            }
            return Object.keys(props.modules);
        },
        [props.modules]
    );

    /**
     * Return modules list
     * @returns {string[]}
     */
    const getModuleList = useCallback(() => getModuleListByFeature(), [getModuleListByFeature]);

    /**
     * Return modules config list with active feature
     * @param feature
     * @returns {{}[]}
     */
    const getModuleConfigListByFeature = useCallback(
        (feature = null) => {
            return getModuleListByFeature(feature).map((m) => getConfigByModule(m));
        },
        [getConfigByModule, getModuleListByFeature]
    );

    const getModuleConfigList = useCallback(
        () => getModuleConfigListByFeature(),
        [getModuleConfigListByFeature]
    );

    const getModuleConfigListByPath = useCallback(
        (path) => {
            return getModuleConfigList().filter((config) => config.path === path)[0];
        },
        [getModuleConfigList]
    );

    return (
        <ConfigContext.Provider
            value={{
                getConfigByModule,
                getModuleList,
                getModuleListByFeature,
                getModuleConfigList,
                getModuleConfigListByFeature,
                getModuleConfigListByPath,
                menu,
            }}
        >
            {React.Children.only(props.children)}
        </ConfigContext.Provider>
    );
}

ConfigProvider.propTypes = { children: PropTypes.element.isRequired };
