import {
    SUGGESTION_CLAUSE,
    SUGGESTION_HISTORY,
    SUGGESTION_KEYWORD,
    SUGGESTION_OPERATOR,
    SUGGESTION_FIELD,
    SUGGESTION_VALUE,
} from "./SuggestionManager";
import { TERM_OPERATOR } from "./QueryParser";
import * as operators from "./operators";

class QueryBuilder {
    /**
     * Return new Query & new CaretPosition from submitted suggestion and current term
     *
     * @param query
     * @param suggestion
     * @param termAnalysis
     * @param module
     * @param phrases
     * @returns {*}
     */
    hydrateQueryWithSuggestion = (query, suggestion, termAnalysis, module, phrases) => {
        switch (suggestion.type) {
            case SUGGESTION_HISTORY:
                return {
                    newQuery: suggestion.value + " ",
                    newCaretPosition: suggestion.value.length + 1,
                };

            case SUGGESTION_VALUE:
                return this.hydrateQueryWithValue(query, suggestion, termAnalysis, module, phrases);
            case SUGGESTION_CLAUSE:
                return this.hydrateQueryWithClause(
                    query,
                    suggestion,
                    termAnalysis,
                    module,
                    phrases
                );
            case SUGGESTION_FIELD:
                return this.hydrateQueryWithField(query, suggestion, termAnalysis, module, phrases);
            case SUGGESTION_OPERATOR:
                return this.hydrateQueryWithOperator(
                    query,
                    suggestion,
                    termAnalysis,
                    module,
                    phrases
                );
            case SUGGESTION_KEYWORD:
                return this.hydrateQueryWithKeyword(
                    query,
                    suggestion,
                    termAnalysis,
                    module,
                    phrases
                );
            default:
                console.warn("submit UNDEFINED suggestion type", suggestion);
                break;
        }
        return {
            newQuery: query,
            newCaretPosition: termAnalysis.start,
        };
    };

    insertSubQueryIntoQuery = (query, subQuery, start, end) => {
        return [query.slice(0, start).trim(), subQuery, query.slice(end).trim()]
            .join(" ")
            .trimLeft();
    };

    buildValueToInsert = (suggestion, phrases, module) => {
        if (
            suggestion.reference &&
            phrases.ref[module] &&
            phrases.ref[module][suggestion.reference][suggestion.value].indexOf("||||") < 0
        ) {
            return `"${phrases.ref[module][suggestion.reference][suggestion.value]}"`;
        } else if (
            suggestion.reference &&
            phrases.ref[module] &&
            phrases.ref[module][suggestion.reference][suggestion.value].indexOf("||||") >= 0
        ) {
            return `"${phrases.ref[module][suggestion.reference][suggestion.value]
                .split("||||")[0]
                .trim()}"`;
        }
        return `"${suggestion.value}"`;
    };

    hydrateQueryWithValue = (query, suggestion, termAnalysis, module, phrases) => {
        if (operators.isMultiValueOperator(termAnalysis.previousTerm.operator)) {
            return this.hydrateQueryWithMultiValue(
                query,
                suggestion,
                termAnalysis,
                module,
                phrases
            );
        } else if (suggestion.date === true) {
            let start = termAnalysis.start;
            let end = termAnalysis.end;
            let newCaretPosition = termAnalysis.start + suggestion.value.length;

            if (termAnalysis.completeTerm) {
                // target term is the second element of range
                if (termAnalysis.term && termAnalysis.term.split(" ").length === 3) {
                    // handle properly TO suggestions
                    const currentTerm = termAnalysis.completeTerm.split(" ")[2];
                    newCaretPosition =
                        termAnalysis.end - currentTerm.length + suggestion.value.length;
                    start = termAnalysis.end - currentTerm.length;
                    end = termAnalysis.end;
                } else {
                    // target term is the first element of range
                    const currentTerm = termAnalysis.completeTerm.split(" ")[0];
                    newCaretPosition = termAnalysis.start + suggestion.value.length;
                    end = termAnalysis.start + currentTerm.length;
                }
            }

            // handle full date suggestion - insert space at the end
            if (suggestion.value.length === 10) {
                newCaretPosition += 1;
            }

            return {
                newQuery: this.insertSubQueryIntoQuery(query, suggestion.value, start, end),
                newCaretPosition: newCaretPosition,
            };
        }

        const subQuery = this.buildValueToInsert(suggestion, phrases, module);

        const newQuery = this.insertSubQueryIntoQuery(
            query,
            subQuery,
            termAnalysis.start,
            termAnalysis.end
        );

        return {
            newQuery,
            newCaretPosition: termAnalysis.start + subQuery.length + 1,
        };
    };

    /**
     * Hydrate for multi value pattern
     * IN ( "value1", "value2", ..., "lastValue" )
     * Set caretPosition after inserted value
     *
     * @param query
     * @param suggestion
     * @param termAnalysis
     * @param module
     * @param phrases
     * @returns {{newQuery: *, newCaretPosition: *}}
     */
    hydrateQueryWithMultiValue = (query, suggestion, termAnalysis, module, phrases) => {
        const subQuery = this.buildValueToInsert(suggestion, phrases, module);

        // Get query before and after term (trim comma and space)
        const queryBeforeTerm = query.slice(0, termAnalysis.start).replace(/([,\s]*$)/g, "");
        const queryAfterTerm = query.slice(termAnalysis.end).replace(/(^[,\s]*)/g, "");

        // init by the begin of the query
        let newQuery = queryBeforeTerm;
        // add ', ' before new value in list if it's not the first
        newQuery += /\($/g.test(queryBeforeTerm) ? " " : ", ";
        // add value
        newQuery += subQuery;
        // add ', ' after new value in list if it's not the last
        newQuery += /^\)/g.test(queryAfterTerm) ? " " : ", ";
        // set caret position
        const newCaretPosition = newQuery.length;
        // add the end of the query
        newQuery += queryAfterTerm;

        return {
            newQuery,
            newCaretPosition,
        };
    };

    hydrateQueryWithField = (query, suggestion, termAnalysis, module, phrases) => {
        const subQuery = `"${phrases.attributes[module][suggestion.value].suggestLabel}"`;

        const start = termAnalysis ? termAnalysis.start : 0;
        const end = termAnalysis ? termAnalysis.end : 0;

        const newQuery = this.insertSubQueryIntoQuery(query, subQuery, start, end);

        return {
            newQuery,
            newCaretPosition: start + subQuery.length + 1,
        };
    };

    hydrateQueryWithOperator = (query, suggestion, termAnalysis, module, phrases) => {
        let subQuery;
        let newCaretPosition = termAnalysis.start;
        switch (suggestion.value) {
            case operators.OPERATOR_IN:
            case operators.OPERATOR_NOT_IN:
                // add parenthesis if not exist
                if (query.slice(termAnalysis.end).trimLeft().charAt(0) !== "(") {
                    subQuery = `${phrases.app.qsearch.operators[suggestion.value]} (  )`;
                    newCaretPosition -= 2;
                    break;
                }

            // wanted fallthrough
            default:
                subQuery = `${phrases.app.qsearch.operators[suggestion.value]}`;
                newCaretPosition += 1;
                break;
        }

        const newQuery = this.insertSubQueryIntoQuery(
            query,
            subQuery,
            termAnalysis.start,
            termAnalysis.end
        );

        return {
            newQuery,
            newCaretPosition: newCaretPosition + subQuery.length,
        };
    };

    hydrateQueryWithKeyword = (query, suggestion, termAnalysis, module, phrases) => {
        let subQuery = `${phrases.app.qsearch.keywords[suggestion.value]}`;

        const newQuery = this.insertSubQueryIntoQuery(
            query,
            subQuery,
            termAnalysis.start,
            termAnalysis.end
        );

        return {
            newQuery,
            newCaretPosition: termAnalysis.start + subQuery.length + 1,
        };
    };

    hydrateQueryWithClause = (query, suggestion, termAnalysis, module, phrases) => {
        let subQuery;
        if (termAnalysis.previousTerm.type === TERM_OPERATOR) {
            subQuery = `"${suggestion.value}"`;
        } else {
            let subQueryValue = `"${suggestion.value}"`;
            if (suggestion.reference) {
                subQueryValue = `"${phrases.ref[module][suggestion.reference][suggestion.value]
                    .split("||||")[0]
                    .trim()}"`;
            }
            subQuery = `"${
                phrases.attributes[module][suggestion.targetField].suggestLabel
            }" = ${subQueryValue}`;
        }

        const newQuery = this.insertSubQueryIntoQuery(
            query,
            subQuery,
            termAnalysis.start,
            termAnalysis.end
        );

        return {
            newQuery,
            newCaretPosition: termAnalysis.start + subQuery.length + 1,
        };
    };
}

export default new QueryBuilder();
