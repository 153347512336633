export const timezones = {
    id: "timezones",
    data: [
        {
            id: 9,
            label: "(GMT -11:00) Midway Island",
        },
        {
            id: 10,
            label: "(GMT -11:00) Samoa",
        },
        {
            id: 1,
            label: "(GMT -10:00) Hawaii",
        },
        {
            id: 2,
            label: "(GMT -09:00) Alaska",
        },
        {
            id: 3,
            label: "(GMT -08:00) Pacific Time (US & Canada)",
        },
        {
            id: 4,
            label: "(GMT -07:00) Arizona",
        },
        {
            id: 11,
            label: "(GMT -08:00) Tijuana",
        },
        {
            id: 5,
            label: "(GMT -07:00) Mountain Time (US & Canada)",
        },
        {
            id: 12,
            label: "(GMT -07:00) Chihuahua",
        },
        {
            id: 13,
            label: "(GMT -07:00) Mazatlan",
        },
        {
            id: 18,
            label: "(GMT -06:00) Saskatchewan",
        },
        {
            id: 6,
            label: "(GMT -06:00) Central Time (US & Canada)",
        },
        {
            id: 14,
            label: "(GMT -06:00) Central America",
        },
        {
            id: 15,
            label: "(GMT -06:00) Guadalajara",
        },
        {
            id: 16,
            label: "(GMT -06:00) Mexico City",
        },
        {
            id: 17,
            label: "(GMT -06:00) Monterrey",
        },
        {
            id: 19,
            label: "(GMT -05:00) Bogota",
        },
        {
            id: 20,
            label: "(GMT -05:00) Lima",
        },
        {
            id: 21,
            label: "(GMT -05:00) Quito",
        },
        {
            id: 7,
            label: "(GMT -05:00) Eastern Time (US & Canada)",
        },
        {
            id: 8,
            label: "(GMT -05:00) Indiana (East)",
        },
        {
            id: 22,
            label: "(GMT -04:30) Caracas",
        },
        {
            id: 24,
            label: "(GMT -04:00) La Paz",
        },
        {
            id: 25,
            label: "(GMT -04:00) Santiago",
        },
        {
            id: 23,
            label: "(GMT -04:00) Atlantic Time (Canada)",
        },
        {
            id: 27,
            label: "(GMT -03:00) Brasilia",
        },
        {
            id: 28,
            label: "(GMT -03:00) Buenos Aires",
        },
        {
            id: 26,
            label: "(GMT -03:30) Newfoundland",
        },
        {
            id: 29,
            label: "(GMT -03:00) Greenland",
        },
        {
            id: 31,
            label: "(GMT -01:00) Cape Verde Is.",
        },
        {
            id: 30,
            label: "(GMT -01:00) Azores",
        },
        {
            id: 37,
            label: "(GMT) Monrovia",
        },
        {
            id: 38,
            label: "(GMT) UTC",
        },
        {
            id: 32,
            label: "(GMT) Casablanca",
        },
        {
            id: 33,
            label: "(GMT) Dublin",
        },
        {
            id: 34,
            label: "(GMT) Edinburgh",
        },
        {
            id: 35,
            label: "(GMT) Lisbon",
        },
        {
            id: 36,
            label: "(GMT) London",
        },
        {
            id: 57,
            label: "(GMT +01:00) West Central Africa",
        },
        {
            id: 39,
            label: "(GMT +01:00) Amsterdam",
        },
        {
            id: 40,
            label: "(GMT +01:00) Belgrade",
        },
        {
            id: 41,
            label: "(GMT +01:00) Berlin",
        },
        {
            id: 42,
            label: "(GMT +01:00) Zurich",
        },
        {
            id: 43,
            label: "(GMT +01:00) Bratislava",
        },
        {
            id: 44,
            label: "(GMT +01:00) Brussels",
        },
        {
            id: 45,
            label: "(GMT +01:00) Budapest",
        },
        {
            id: 46,
            label: "(GMT +01:00) Copenhagen",
        },
        {
            id: 47,
            label: "(GMT +01:00) Ljubljana",
        },
        {
            id: 48,
            label: "(GMT +01:00) Madrid",
        },
        {
            id: 49,
            label: "(GMT +01:00) Paris",
        },
        {
            id: 50,
            label: "(GMT +01:00) Prague",
        },
        {
            id: 51,
            label: "(GMT +01:00) Rome",
        },
        {
            id: 52,
            label: "(GMT +01:00) Sarajevo",
        },
        {
            id: 53,
            label: "(GMT +01:00) Skopje",
        },
        {
            id: 54,
            label: "(GMT +01:00) Stockholm",
        },
        {
            id: 55,
            label: "(GMT +01:00) Vienna",
        },
        {
            id: 56,
            label: "(GMT +01:00) Warsaw",
        },
        {
            id: 58,
            label: "(GMT +01:00) Zagreb",
        },
        {
            id: 61,
            label: "(GMT +02:00) Cairo",
        },
        {
            id: 62,
            label: "(GMT +02:00) Harare",
        },
        {
            id: 68,
            label: "(GMT +02:00) Johannesburg",
        },
        {
            id: 59,
            label: "(GMT +02:00) Athens",
        },
        {
            id: 60,
            label: "(GMT +02:00) Bucharest",
        },
        {
            id: 63,
            label: "(GMT +02:00) Helsinki",
        },
        {
            id: 64,
            label: "(GMT +02:00) Istanbul",
        },
        {
            id: 65,
            label: "(GMT +02:00) Jerusalem",
        },
        {
            id: 66,
            label: "(GMT +02:00) Kiev",
        },
        {
            id: 67,
            label: "(GMT +03:00) Minsk",
        },
        {
            id: 69,
            label: "(GMT +02:00) Riga",
        },
        {
            id: 70,
            label: "(GMT +02:00) Sofia",
        },
        {
            id: 71,
            label: "(GMT +02:00) Tallinn",
        },
        {
            id: 72,
            label: "(GMT +02:00) Vilnius",
        },
        {
            id: 73,
            label: "(GMT +03:00) Baghdad",
        },
        {
            id: 74,
            label: "(GMT +03:00) Kuwait",
        },
        {
            id: 75,
            label: "(GMT +03:00) Moscow",
        },
        {
            id: 76,
            label: "(GMT +03:00) Nairobi",
        },
        {
            id: 77,
            label: "(GMT +03:00) Riyadh",
        },
        {
            id: 78,
            label: "(GMT +03:00) Volgograd",
        },
        {
            id: 79,
            label: "(GMT +03:30) Tehran",
        },
        {
            id: 80,
            label: "(GMT +04:00) Dubai",
        },
        {
            id: 81,
            label: "(GMT +04:00) Baku",
        },
        {
            id: 82,
            label: "(GMT +04:00) Muscat",
        },
        {
            id: 83,
            label: "(GMT +04:00) Tbilisi",
        },
        {
            id: 84,
            label: "(GMT +04:00) Yerevan",
        },
        {
            id: 85,
            label: "(GMT +04:30) Kabul",
        },
        {
            id: 86,
            label: "(GMT +05:00) Yekaterinburg",
        },
        {
            id: 87,
            label: "(GMT +05:00) Islamabad",
        },
        {
            id: 88,
            label: "(GMT +05:00) Karachi",
        },
        {
            id: 89,
            label: "(GMT +05:00) Tashkent",
        },
        {
            id: 90,
            label: "(GMT +05:30) Calcutta",
        },
        {
            id: 91,
            label: "(GMT +05:30) Kolkata",
        },
        {
            id: 92,
            label: "(GMT +05:30) New Delhi",
        },
        {
            id: 93,
            label: "(GMT +05:30) Mumbai",
        },
        {
            id: 94,
            label: "(GMT +05:30) Sri Jayawardenepura",
        },
        {
            id: 96,
            label: "(GMT +06:00) Almaty",
        },
        {
            id: 97,
            label: "(GMT +06:00) Astana",
        },
        {
            id: 98,
            label: "(GMT +06:00) Dhaka",
        },
        {
            id: 99,
            label: "(GMT +06:00) Novosibirsk",
        },
        {
            id: 100,
            label: "(GMT +06:30) Rangoon",
        },
        {
            id: 114,
            label: "(GMT +06:00) Urumqi",
        },
        {
            id: 101,
            label: "(GMT +07:00) Bangkok",
        },
        {
            id: 102,
            label: "(GMT +07:00) Hanoi",
        },
        {
            id: 103,
            label: "(GMT +07:00) Jakarta",
        },
        {
            id: 104,
            label: "(GMT +07:00) Krasnoyarsk",
        },
        {
            id: 105,
            label: "(GMT +08:00) Beijing",
        },
        {
            id: 106,
            label: "(GMT +08:00) Chongqing",
        },
        {
            id: 107,
            label: "(GMT +08:00) Hong Kong",
        },
        {
            id: 108,
            label: "(GMT +08:00) Irkutsk",
        },
        {
            id: 109,
            label: "(GMT +08:00) Kuala Lumpur",
        },
        {
            id: 110,
            label: "(GMT +08:00) Perth",
        },
        {
            id: 111,
            label: "(GMT +08:00) Singapore",
        },
        {
            id: 112,
            label: "(GMT +08:00) Taipei",
        },
        {
            id: 113,
            label: "(GMT +08:00) Ulaan Bataar",
        },
        {
            id: 115,
            label: "(GMT +09:00) Osaka",
        },
        {
            id: 116,
            label: "(GMT +09:00) Sapporo",
        },
        {
            id: 117,
            label: "(GMT +09:00) Seoul",
        },
        {
            id: 118,
            label: "(GMT +09:00) Tokyo",
        },
        {
            id: 119,
            label: "(GMT +09:00) Yakutsk",
        },
        {
            id: 120,
            label: "(GMT +09:30) Adelaide",
        },
        {
            id: 121,
            label: "(GMT +09:30) Darwin",
        },
        {
            id: 122,
            label: "(GMT +10:00) Brisbane",
        },
        {
            id: 123,
            label: "(GMT +10:00) Canberra",
        },
        {
            id: 124,
            label: "(GMT +10:00) Guam",
        },
        {
            id: 125,
            label: "(GMT +10:00) Hobart",
        },
        {
            id: 126,
            label: "(GMT +10:00) Melbourne",
        },
        {
            id: 127,
            label: "(GMT +10:00) Port Moresby",
        },
        {
            id: 128,
            label: "(GMT +10:00) Sydney",
        },
        {
            id: 129,
            label: "(GMT +10:00) Vladivostok",
        },
        {
            id: 130,
            label: "(GMT +10:00) Magadan",
        },
        {
            id: 131,
            label: "(GMT +11:00) New Caledonia",
        },
        {
            id: 132,
            label: "(GMT +11:00) Solomon Islands",
        },
        {
            id: 133,
            label: "(GMT +12:00) Auckland",
        },
        {
            id: 134,
            label: "(GMT +12:00) Fiji",
        },
        {
            id: 135,
            label: "(GMT +12:00) Kamchatka",
        },
        {
            id: 136,
            label: "(GMT +12:00) Marshall Is.",
        },
        {
            id: 137,
            label: "(GMT +12:00) Wellington",
        },
        {
            id: 138,
            label: "(GMT +13:00) Nuku'alofa",
        },
    ],
};
