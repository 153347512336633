export enum DisplayType {
    DISPLAY_ACCOUNT = "account",
    DISPLAY_ACCOUNT_NUMBER = "account_number",
    DISPLAY_ADDRESS = "address",
    DISPLAY_AMOUNT = "amount",
    DISPLAY_AUTH_STATUS = "auth_status",
    DISPLAY_AVATAR = "avatar",
    DISPLAY_CHECKBOX = "checkbox",
    DISPLAY_COUNTRY = "country",
    DISPLAY_CUSTOM_DATA = "custom_data",
    DISPLAY_DATE = "date",
    DISPLAY_EXPANDABLE = "expandable",
    DISPLAY_ICON = "icon",
    DISPLAY_IMAGE = "image",
    DISPLAY_LINK = "link",
    DISPLAY_MONTH = "month",
    DISPLAY_NUMERIC = "numeric",
    DISPLAY_CHIP = "chip",
    DISPLAY_RATE = "rate",
    DISPLAY_SENTINEL = "sentinel",
    DISPLAY_SCORE = "score",
    DISPLAY_STATUS = "status",
    DISPLAY_TEXT = "text",
    DISPLAY_LONG_TEXT = "long_text",
    DISPLAY_THIRD_PARTY_SECURITY = "third_party_security",
    DISPLAY_BOOLEAN = "boolean",
    DISPLAY_MULTIPLE_LINKS = "multiple_links",
}
