const eci = {
    id: "eci",
    data: [
        {
            id: "0",
            label: "ref.transaction.eci.value_0",
            smallLabel: "0",
        },
        {
            id: "1",
            label: "ref.transaction.eci.value_1",
            smallLabel: "1",
        },
        {
            id: "2",
            label: "ref.transaction.eci.value_2",
            smallLabel: "2",
        },
        {
            id: "3",
            label: "ref.transaction.eci.value_3",
            smallLabel: "3",
        },
        {
            id: "4",
            label: "ref.transaction.eci.value_4",
            smallLabel: "4",
        },
        {
            id: "5",
            label: "ref.transaction.eci.value_5",
            smallLabel: "5",
        },
        {
            id: "6",
            label: "ref.transaction.eci.value_6",
            smallLabel: "6",
        },
        {
            id: "7",
            label: "ref.transaction.eci.value_7",
            smallLabel: "7",
        },
        {
            id: "9",
            label: "ref.transaction.eci.value_9",
            smallLabel: "9",
        },
        {
            id: "10",
            label: "ref.transaction.eci.value_10",
            smallLabel: "10",
        },
    ],
    translate: true,
};

export default eci;
