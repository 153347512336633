const cardTypes = {
    id: "card_types",
    data: [
        {
            id: "debit",
            label: "ref.transaction.card_type.debit",
        },
        {
            id: "credit",
            label: "ref.transaction.card_type.credit",
        },
        {
            id: "charge card",
            label: "ref.transaction.card_type.charge_card",
        },
    ],
    translate: true,
};

export default cardTypes;
