import React from "react";
import { MessageBarContext } from "./MessageBar.context";
import { useLocalStorage } from "react-use";
import { useGetLatestMessageQuery } from "../../common/queries/message";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const MESSAGE_INFO_DATE_VIEWED = "message_info_date_viewed";
const MESSAGE_WARN_DATE_VIEWED = "message_warn_date_viewed";

export const MessageBarProvider = (props) => {
    const userSettings = useSelector((state) => state.app.settings.data);
    const location = useLocation();

    const [messageInfoDateViewed, setMessageInfoDateViewed] = useLocalStorage(
        MESSAGE_INFO_DATE_VIEWED,
        null
    );
    const [messageWarnDateViewed, setMessageWarnDateViewed] = useLocalStorage(
        MESSAGE_WARN_DATE_VIEWED,
        null
    );

    const latestMessageQuery = useGetLatestMessageQuery(userSettings.language, {
        staleTime: "Infinite",
    });

    const infoMessage =
        latestMessageQuery.data && latestMessageQuery.data.info
            ? latestMessageQuery.data.info
            : false;
    const warnMessage =
        latestMessageQuery.data && latestMessageQuery.data.warn
            ? latestMessageQuery.data.warn
            : false;

    const dismiss = () => {
        if (latestMessageQuery.data.info) {
            setMessageInfoDateViewed(moment().format());
        }
        if (latestMessageQuery.data.warn) {
            setMessageWarnDateViewed(moment().format());
        }
    };

    const viewed = (dateCreated, dateViewed) => {
        return dateViewed && moment(dateViewed) > moment(dateCreated);
    };

    const infoMessageViewed =
        !infoMessage || viewed(infoMessage.dateCreated, messageInfoDateViewed);
    const warnMessageViewed =
        !warnMessage || viewed(warnMessage.dateCreated, messageWarnDateViewed);

    const isVisible =
        (!infoMessageViewed || !warnMessageViewed) &&
        !location.pathname.replace(/\/accounts[-\d+]+/g, "").startsWith("/verifications");

    return (
        <MessageBarContext.Provider
            value={{
                infoMessage,
                infoMessageViewed,
                warnMessage,
                warnMessageViewed,
                isVisible,
                dismiss,
            }}
        >
            {React.Children.only(props.children)}
        </MessageBarContext.Provider>
    );
};
