/**
 * Number of item before and after current item
 * @type {number}
 */
export const SIZE_NOTICE = 10;

/**
 * Number of item to list limit before requesting new items
 * @type {number}
 */
export const MARGIN_NOTICE = 2;
