const chargebackReasons = {
    id: "chargeback_reasons",
    endpoint: "/chargeback-reasons",
    withSelectedAccounts: true,
    translate: true,
    reduceFunc: (previous, current) =>
        current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"],
                      label: "ref.transaction.chargeback_reasons." + current["id"],
                      fallbackLabel: current["name"],
                  },
              ]
            : previous,
};

export default chargebackReasons;
