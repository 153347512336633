import { useCallback, useMemo } from "react";
import classNames from "classnames";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import withStyles from "@mui/styles/withStyles";
import Popover from "@mui/material/Popover";
import Close from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/icons-material/Menu";
import FilterList from "@mui/icons-material/FilterList";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { useP } from "../../../../../../services/i18n";
import FormControlLabel from "@mui/material/FormControlLabel";
import HiSwitch from "@hipay/hipay-material-ui/HiSwitch";

const stylesColumnFormat = (theme) => ({
    menu: {
        width: 300,
        maxHeight: 700,
        paddingTop: 16,
        paddingBottom: 8,
    },
    menuTitle: {
        ...theme.typography.h3,
        marginLeft: 12,
        marginRight: 40,
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 16,
    },
    closeMenu: {
        position: "absolute",
        top: 2,
        right: 2,
    },
    menuItemBorderBottom: { borderBottom: `1px solid ${theme.palette.divider}` },
    menuItemIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
        color: theme.palette.neutral.normal,
    },
    sortActive: {
        color: theme.palette.primary.main,
        cursor: "inherit",
    },
    asc: { transform: "scale(1,-1)" },
    arrowRight: {
        position: "relative",
        top: 2,
        width: "15px",
        height: "15px",
    },
    menuItem: {
        ...theme.typography.subtitle2,
        height: 40,
        lineHeight: "40px",
        paddingLeft: 28,
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    formatField: { paddingLeft: 28 },
});

const ColumnFormat = (props) => {
    const {
        attribute,
        anchorEl,
        classes,
        title,
        open,
        onClose,
        sortable,
        sorted,
        sortDirection,
        onSort,
        columnList,
        handleColumnsOrderUpdate,
        isDisplayRank,
        isDisplaySelector,
    } = props;

    const p = useP();

    let sortFrom = "A";
    let sortTo = "Z";

    const handleSort = (direction, e) => {
        onSort(direction);
        onClose(e);
    };
    const sortAsc = (e) => handleSort("asc", e);
    const sortDesc = (e) => handleSort("desc", e);
    const sortNone = (e) => handleSort(false, e);

    const column = useMemo(() => {
        return columnList.find((col) => col.colId === attribute.id);
    }, [attribute, columnList]);

    const toggleFormatable = useCallback(() => {
        handleColumnsOrderUpdate(
            columnList.map((col) => {
                if (col.colId === attribute.id) {
                    return {
                        ...col,
                        withFormating: !col.withFormating,
                    };
                }
                return col;
            }),
            isDisplayRank,
            isDisplaySelector
        );
    }, [attribute, columnList, handleColumnsOrderUpdate, isDisplayRank, isDisplaySelector]);

    return (
        <Popover
            id="column-filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            classes={{ paper: classes.menu }}
        >
            <div className={classes.menuTitle}>
                <span>{title}</span>
                <HiIconButton className={classes.closeMenu} onClick={onClose}>
                    <Close />
                </HiIconButton>
            </div>

            <div>
                <MenuItem
                    key={"sort-none"}
                    classes={{ root: classes.menuItemBorderBottom }}
                    onClick={(event) => sortNone(event)}
                    tabIndex={0}
                >
                    <Menu classes={{ root: classes.menuItemIcon }} />
                    <span>{p.t("common.search.table.default_sort")}</span>
                </MenuItem>

                {sortable && (
                    <>
                        <MenuItem
                            key={"sort-asc"}
                            onClick={
                                !sorted || sortDirection !== "asc"
                                    ? (event) => sortAsc(event)
                                    : undefined
                            }
                            title={p.t("common.search.table.sort_indication")}
                            tabIndex={0}
                            classes={{
                                root: classNames({
                                    [classes.sortActive]: sorted && sortDirection === "asc",
                                }),
                            }}
                        >
                            <FilterList
                                className={classes.asc}
                                classes={{ root: classes.menuItemIcon }}
                            />
                            <span>
                                {`${p.t("common.search.table.sort_from")} ${sortFrom} `}
                                <ArrowRight className={classes.arrowRight} /> {sortTo}
                            </span>
                        </MenuItem>
                        <MenuItem
                            key={"sort-desc"}
                            onClick={
                                !sorted || sortDirection !== "desc"
                                    ? (event) => sortDesc(event)
                                    : undefined
                            }
                            classes={{
                                root: classNames(classes.menuItemBorderBottom, {
                                    [classes.sortActive]: sorted && sortDirection === "desc",
                                }),
                            }}
                            title={p.t("common.search.table.sort_indication")}
                            tabIndex={0}
                        >
                            <FilterList classes={{ root: classes.menuItemIcon }} />
                            <span>
                                {`${p.t("common.search.table.sort_from")} ${sortTo} `}
                                <ArrowRight className={classes.arrowRight} /> {sortFrom}
                            </span>
                        </MenuItem>
                    </>
                )}

                <div className={classes.menuItem}>
                    <span>{p.t("common.search.table.header.stats.display.title")}</span>
                </div>
                <div className={classes.formatField}>
                    <FormControlLabel
                        control={
                            <HiSwitch
                                checked={column.withFormating || false}
                                color="primary"
                                onChange={toggleFormatable}
                            />
                        }
                        label={
                            column.withFormating
                                ? p.t("common.search.table.header.stats.display.with_currency")
                                : p.t("common.search.table.header.stats.display.without_currency")
                        }
                    />
                </div>
            </div>
        </Popover>
    );
};

export default withStyles(stylesColumnFormat)(ColumnFormat);
