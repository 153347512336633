import attributes from "../../constants/attributes";

const statusesColor = {
    created: "#6864E7",
    final_ok: "#02A17B",
    final_ko: "#CB2B0B",
    refund: "#4D5255",
    intermediate: "#FF8C50",
    deducted: "#8e1e07",
};

const transactionStatuses = {
    // predefined static options
    id: "transaction_statuses",
    data: [
        {
            id: "initialisation",
            label: "ref.transaction.transaction_statuses_group.initialisation",
            children: [
                {
                    id: "created",
                    label: "ref.transaction.transaction_statuses.created",
                    color: statusesColor.created,
                },
                {
                    id: "acquirer_found",
                    label: "ref.transaction.transaction_statuses.acquirer_found",
                    color: statusesColor.final_ok,
                },
                {
                    id: "refused",
                    label: "ref.transaction.transaction_statuses.refused",
                    color: statusesColor.final_ko,
                },
                {
                    id: "acquirer_not_found",
                    label: "ref.transaction.transaction_statuses.acquirer_not_found",
                    color: statusesColor.final_ko,
                },
                {
                    id: "awaiting_terminal",
                    label: "ref.transaction.transaction_statuses.awaiting_terminal",
                    color: statusesColor.intermediate,
                },
                {
                    id: "in_progress",
                    label: "ref.transaction.transaction_statuses.in_progress",
                    color: statusesColor.intermediate,
                },
            ],
        },
        {
            id: "sentinel_evaluation",
            label: "ref.transaction.transaction_statuses_group.sentinel_evaluation",
            children: [
                {
                    id: "sentinel_result_available",
                    label: "ref.transaction.transaction_statuses.sentinel_result_available",
                    color: statusesColor.intermediate,
                },
                {
                    id: "sentinel_result_not_available",
                    label: "ref.transaction.transaction_statuses.sentinel_result_not_available",
                    color: statusesColor.intermediate,
                },
                {
                    id: "blocked",
                    label: "ref.transaction.transaction_statuses.blocked",
                    color: statusesColor.final_ko,
                },
            ],
        },
        {
            id: "authentication",
            label: "ref.transaction.transaction_statuses_group.authentication",
            children: [
                {
                    id: "authenticatable",
                    label: "ref.transaction.transaction_statuses.authenticatable",
                    color: statusesColor.intermediate,
                },
                {
                    id: "non_authenticatable",
                    label: "ref.transaction.transaction_statuses.non_authenticatable",
                    color: statusesColor.intermediate,
                },
                {
                    id: "authentication_requested",
                    label: "ref.transaction.transaction_statuses.authentication_requested",
                    color: statusesColor.intermediate,
                },
                {
                    id: "authentication_attempted",
                    label: "ref.transaction.transaction_statuses.authentication_attempted",
                    color: statusesColor.intermediate,
                },
                {
                    id: "successful_authentication",
                    label: "ref.transaction.transaction_statuses.successful_authentication",
                    color: statusesColor.final_ok,
                },
                {
                    id: "authentication_failed",
                    label: "ref.transaction.transaction_statuses.authentication_failed",
                    color: statusesColor.final_ko,
                },
                {
                    id: "challenge_requested",
                    label: "ref.transaction.transaction_statuses.challenge_requested",
                    color: statusesColor.intermediate,
                },
            ],
        },
        {
            id: "authorization",
            label: "ref.transaction.transaction_statuses_group.authorizations",
            children: [
                {
                    id: "authorization_requested",
                    label: "ref.transaction.transaction_statuses.authorization_requested",
                    color: statusesColor.intermediate,
                },
                {
                    id: "pending_payment",
                    label: "ref.transaction.transaction_statuses.pending_payment",
                    color: statusesColor.intermediate,
                },
                {
                    id: "authorized_by_payment_means",
                    label: "ref.transaction.transaction_statuses.authorized_by_payment_means",
                    color: statusesColor.final_ok,
                },
                {
                    id: "to_be_reviewed",
                    label: "ref.transaction.transaction_statuses.to_be_reviewed",
                    color: statusesColor.intermediate,
                },
                {
                    id: "refused_by_merchant",
                    label: "ref.transaction.transaction_statuses.refused_by_merchant",
                    color: statusesColor.final_ko,
                },
                {
                    id: "authorized_by_merchant",
                    label: "ref.transaction.transaction_statuses.authorized_by_merchant",
                    color: statusesColor.final_ok,
                },
                {
                    id: "authorization_cancellation_requested",
                    label: "ref.transaction.transaction_statuses.authorization_cancelled_requested",
                    color: statusesColor.intermediate,
                },
                {
                    id: "authorization_cancelled",
                    label: "ref.transaction.transaction_statuses.authorization_cancelled",
                    color: statusesColor.final_ko,
                },
                {
                    id: "authorization_refused",
                    label: "ref.transaction.transaction_statuses.authorization_refused",
                    color: statusesColor.final_ko,
                },
                {
                    id: "soft_declined",
                    label: "ref.transaction.transaction_statuses.soft_declined",
                    color: statusesColor.intermediate,
                },
                {
                    id: "expired",
                    label: "ref.transaction.transaction_statuses.expired",
                    color: statusesColor.final_ko,
                },
            ],
        },
        {
            id: "payment_operation",
            label: "ref.transaction.transaction_statuses_group.payment_operation",
            children: [
                {
                    id: "capture_requested",
                    label: "ref.transaction.transaction_statuses.capture_requested",
                    color: statusesColor.intermediate,
                },
                {
                    id: "partial_capture",
                    label: "ref.transaction.transaction_statuses.partial_capture",
                    color: statusesColor.intermediate,
                },
                {
                    id: "captured",
                    label: "ref.transaction.transaction_statuses.captured",
                    color: statusesColor.final_ok,
                },
                {
                    id: "capture_refused",
                    label: "ref.transaction.transaction_statuses.capture_refused",
                    color: statusesColor.final_ko,
                },
                {
                    id: "refund_requested",
                    label: "ref.transaction.transaction_statuses.refund_requested",
                    color: statusesColor.intermediate,
                },
                {
                    id: "partial_refund",
                    label: "ref.transaction.transaction_statuses.partially_refunded",
                    color: statusesColor.refund,
                },
                {
                    id: "refunded",
                    label: "ref.transaction.transaction_statuses.refunded",
                    color: statusesColor.refund,
                },
                {
                    id: "refund_refused",
                    label: "ref.transaction.transaction_statuses.refund_refused",
                    color: statusesColor.final_ko,
                },
                {
                    id: "credit_requested",
                    label: "ref.transaction.transaction_statuses.credit_requested",
                    color: statusesColor.intermediate,
                },
                {
                    id: "credited",
                    label: "ref.transaction.transaction_statuses.credited",
                    color: statusesColor.final_ko,
                },
            ],
        },
        {
            id: "collect",
            label: "ref.transaction.transaction_statuses_group.collect",
            children: [
                {
                    id: "partial_collect",
                    label: "ref.transaction.transaction_statuses.partial_collect",
                    color: statusesColor.intermediate,
                },
                {
                    id: "collected",
                    label: "ref.transaction.transaction_statuses.collected",
                    color: statusesColor.intermediate,
                },
                {
                    id: "partial_debit",
                    label: "ref.transaction.transaction_statuses.partially_debited",
                    color: statusesColor.refund,
                },
                {
                    id: "debited",
                    label: "ref.transaction.transaction_statuses.debited",
                    color: statusesColor.refund,
                },
                {
                    id: "partial_settlement",
                    label: "ref.transaction.transaction_statuses.partially_settled",
                    color: statusesColor.final_ok,
                },
                {
                    id: "settled",
                    label: "ref.transaction.transaction_statuses.settled",
                    color: statusesColor.final_ok,
                },
            ],
        },
        {
            id: "dispute",
            label: "ref.transaction.transaction_statuses_group.dispute",
            children: [
                {
                    id: "chargedback",
                    label: "ref.transaction.transaction_statuses.chargedback",
                    color: statusesColor.final_ko,
                },
                {
                    id: "dispute_in_progress",
                    label: "ref.transaction.transaction_statuses.dispute_in_progress",
                    color: statusesColor.intermediate,
                },
            ],
        },
    ],
    hierarchic: true,
    translate: true,
};

export const statusesThemeColor = {
    created: "primary",
    final_ok: "success",
    final_ko: "error",
    refund: "neutral",
    intermediate: "warning",
};

export const statusesThemeColorById = {
    created: statusesThemeColor.created,
    acquirer_found: statusesThemeColor.final_ok,
    refused: statusesThemeColor.final_ko,
    acquirer_not_found: statusesThemeColor.final_ko,
    awaiting_terminal: statusesThemeColor.intermediate,
    in_progress: statusesThemeColor.intermediate,
    authenticatable: statusesThemeColor.intermediate,
    non_authenticatable: statusesThemeColor.intermediate,
    sentinel_result_available: statusesThemeColor.intermediate,
    sentinel_result_not_available: statusesThemeColor.intermediate,
    blocked: statusesThemeColor.final_ko,
    authentication_requested: statusesThemeColor.intermediate,
    authentication_attempted: statusesThemeColor.intermediate,
    successful_authentication: statusesThemeColor.final_ok,
    authentication_failed: statusesThemeColor.final_ko,
    authorization_requested: statusesThemeColor.intermediate,
    pending_payment: statusesThemeColor.intermediate,
    authorized_by_payment_means: statusesThemeColor.final_ok,
    to_be_reviewed: statusesThemeColor.intermediate,
    refused_by_merchant: statusesThemeColor.final_ko,
    authorized_by_merchant: statusesThemeColor.final_ok,
    authorization_cancellation_requested: statusesThemeColor.intermediate,
    authorization_cancelled: statusesThemeColor.final_ko,
    authorization_refused: statusesThemeColor.final_ko,
    expired: statusesThemeColor.final_ko,
    capture_requested: statusesThemeColor.intermediate,
    partial_capture: statusesThemeColor.intermediate,
    captured: statusesThemeColor.final_ok,
    capture_refused: statusesThemeColor.final_ko,
    refund_requested: statusesThemeColor.intermediate,
    partial_refund: statusesThemeColor.refund,
    refunded: statusesThemeColor.refund,
    refund_refused: statusesThemeColor.final_ko,
    credit_requested: statusesThemeColor.intermediate,
    credited: statusesThemeColor.final_ko,
    partial_collect: statusesThemeColor.final_ok,
    collected: statusesThemeColor.intermediate,
    partial_debit: statusesThemeColor.refund,
    debited: statusesThemeColor.refund,
    partial_settlement: statusesThemeColor.final_ok,
    settled: statusesThemeColor.final_ok,
    chargedback: statusesThemeColor.final_ko,
    dispute_in_progress: statusesThemeColor.intermediate,
    soft_declined: statusesThemeColor.intermediate,
    challenge_requested: statusesThemeColor.intermediate,
};

export default transactionStatuses;

export const statusAttributeDate = {
    authorized_by_payment_means: attributes.authorized_by_payment_means_date.id,
    blocked: attributes.blocked_date.id,
    captured: attributes.captured_date.id,
    collected: attributes.collected_date.id,
    created: attributes.created_date.id,
    chargedback: attributes.chargeback_date.id,
    refunded: attributes.refunded_date.id,
    settled: attributes.settled_date.id,
    to_be_reviewed: attributes.to_be_reviewed_date.id,
};
