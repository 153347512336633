import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

const stylesCellDate = {
    wrapper: {
        width: "100%",
        height: 17,
        margin: "auto 0",
        display: "flex",
        lineHeight: "17px",
        verticalAlign: "middle",
    },
    rightEllipsisSpan: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        width: "100%",
    },
};

/**
 * Cette cellule permet d'afficher une date en fonction de la locale de l'utilisateur
 */
class CellDateClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Date as string
         */
        date: PropTypes.string,
        /**
         * Timezone de l'utilisateur
         */
        dateTimezone: PropTypes.object,
        /**
         * Affiche l'heure
         */
        displayTime: PropTypes.bool,
        /**
         * Override date format
         */
        format: PropTypes.string,
        /**
         * Format de la date en vue small (ex: DD/MM/YYYY)
         * @see https://momentjs.com/docs/#/parsing/string-format/
         */
        formatShort: PropTypes.string,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    static defaultProps = {
        displayTime: false,
        formatShort: "DD/MM/YYYY",
        view: "m",
    };

    formatLong = "dddd DD MMMM YYYY LTS";

    render() {
        const {
            classes,
            displayTime,
            date,
            view,
            formatShort,
            title = "",
            className,
            format,
        } = this.props;

        if (!date) {
            return "";
        }

        let mdate = moment(date);
        const _title = mdate.format(this.formatLong);
        const isTitle = title ? title : _title;

        let label;
        if (format) {
            label = mdate.format(format);
        } else if (view === "l") {
            label = displayTime
                ? mdate.format(this.formatLong)
                : mdate.format(this.formatLong.replace(" LTS", ""));
        } else if (view === "m") {
            label = displayTime ? mdate.format(`${formatShort} LTS`) : mdate.format(formatShort);
        } else {
            label = displayTime
                ? mdate.format(`${formatShort.replace("YYYY", "YY")} HH:mm`)
                : mdate.format(formatShort.replace("YYYY", "YY"));
        }

        return (
            <div
                className={classNames(classes.wrapper, className)}
                title={view === "s" ? isTitle : title ? title : undefined}
            >
                <div className={classes.rightEllipsisSpan}>{label}</div>
            </div>
        );
    }
}

export const CellDate = withStyles(stylesCellDate, { name: "HmuiCellDate" })(CellDateClass);
