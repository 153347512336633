const sddAuthenticationIndicator = {
    id: "sdd_authentication_indicator",
    data: [
        {
            id: "0",
            label: "ref.transaction.sdd_authentication_indicator.value_0",
        },
        {
            id: "1",
            label: "ref.transaction.sdd_authentication_indicator.value_1",
        },
    ],
    translate: true,
};

export default sddAuthenticationIndicator;
