import { ROLE_ADMIN, ROLE_MERCHANT, ROLE_MERCHANT_ADMIN } from "../constants";

/**
 * This function returns true if user has at least one privilege in the specified module.
 * @param user (object)
 * @param module (string)
 * @param internal (bool)
 * @returns {boolean}
 */
export const hasOneAccessOnModule = (user, module, internal) => {
    if (
        internal &&
        (user?.roles?.includes(ROLE_MERCHANT) ||
            user?.roles?.includes(ROLE_MERCHANT_ADMIN) ||
            process.env.NX_IS_INTERNAL !== "true")
    ) {
        return false;
    }
    if (user?.roles?.includes(ROLE_MERCHANT_ADMIN) || user?.roles?.includes(ROLE_ADMIN)) {
        return true;
    }

    let response = false;
    if (!user.privileges) {
        return response;
    }
    user.privileges.forEach((privilege) => {
        if (privilege.module === module) {
            response = true;
        }
    });
    return response;
};

/**
 * This function returns true if user see one account
 *  - if one account selected
 *  - if user is linked with only one account
 * @param user (object)
 * @param selectedAccountIdList (array)
 * @param accountEntities (array)
 */
export const seeOneAccount = (user, selectedAccountIdList, accountEntities) => {
    return (
        selectedAccountIdList.length === 1 ||
        (!user.allAccountsAccess && Object.keys(accountEntities).length === 1)
    );
};

/**
 * This function returns the initials (2 letters) computed with firstname and lastname
 * @param firstname
 * @param lastname
 */
export const getInitials = (firstname = "", lastname = "") => {
    let f = firstname ? firstname[0].toUpperCase() : "";
    let l = lastname ? lastname[0].toUpperCase() : "";

    return f + l;
};
