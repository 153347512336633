const dsp2Statuses = {
    id: "dsp2_statuses",
    data: [
        {
            id: "ELIGIBLE",
            label: "ref.transaction.dsp2_statuses.subject_psd2",
        },
        {
            id: "INELIGIBLE",
            label: "ref.transaction.dsp2_statuses.not_subject_psd2",
        },
    ],
    translate: true,
};

export default dsp2Statuses;
