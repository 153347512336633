import React, { createContext, useContext } from "react";
import { AbilityContext } from "./ability/ability-context";
import { AccountContext } from "./account/account-context";
import { ApiContext } from "./api/api-context";
import { ConfigContext } from "./config/config-context";
import { I18nContext } from "./i18n/i18n-context";
import { UserContext } from "./user/user-context";

const InheritedContext = createContext();

/**
 * Context Forwarder
 * get master contexts from props and provide them to module components
 *
 * @param contexts
 * @param children
 * @returns {*}
 * @constructor
 */
const ContextForwarder = ({ children, contexts }) => {
    return (
        <InheritedContext.Provider value={contexts}>
            {React.Children.only(children)}
        </InheritedContext.Provider>
    );
};

/**
 * Hook to get contexts to forward
 * @returns {{authentication: any, user: any}}
 */
const useContexts = () => ({
    ability: useContext(AbilityContext),
    account: useContext(AccountContext),
    api: useContext(ApiContext),
    config: useContext(ConfigContext),
    i18n: useContext(I18nContext),
    user: useContext(UserContext),
});

/**
 * Hook to get specified context with priority for inherited context
 * @param key
 * @param Context
 * @returns {*}
 */
const useInheritContext = (key, Context) => {
    const _master = useContext(Context);
    const _inherit = useContext(InheritedContext);

    if (_inherit) {
        return _inherit[key];
    } else if (_master !== undefined) {
        return _master;
    }

    if (process.env.NODE_ENV !== "test") {
        console.error(
            `[CORE] Context "${key}" is undefined. It could occur if inherit contexts are used outside core context (e.g. snackbar contents)`
        );
    }
    return null;
};

/**
 * HOC to get specified context with priority for inherited context
 * @param WrappedComponent
 * @param key
 * @param Context
 * @returns {function(*): *}
 */
const withInheritContext = (WrappedComponent, key, Context) => (props) => {
    const _master = useContext(Context);
    const _inherit = useContext(InheritedContext);

    let _props = {
        ...props,
        [key]: _inherit ? _inherit[key] : _master,
    };

    return <WrappedComponent {..._props} />;
};

export { ContextForwarder, useContexts, useInheritContext, withInheritContext };
