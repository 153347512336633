import { SnackbarProvider as NotistackProvider } from "notistack";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = (theme) =>
    makeStyles(() => ({
        containerRoot: {
            //Container : & > .MuiCollapse-root, & > .MuiCollapse-root .
            "& > div": { pointerEvents: "all" },
            //Wrapper: '& > .MuiCollapse-root > .MuiCollapse-wrapper, & > .MuiCollapse-root > .MuiCollapse-wrapper',
            "& > div > div": {
                padding: "6px 0px",
                transition: "padding 300ms ease 0ms",
            },
            zIndex: 9999,
        },
        info: {
            backgroundColor: theme.palette.primary.dark + " !important",
            color: theme.palette.primary.contrastText + " !important",
        },
        success: {
            backgroundColor: theme.palette.success.dark + " !important",
            color: theme.palette.success.contrastText + " !important",
        },
        error: {
            backgroundColor: theme.palette.error.dark + " !important",
            color: theme.palette.error.contrastText + " !important",
        },
        warning: {
            backgroundColor: theme.palette.warning.dark + " !important",
            color: theme.palette.warning.contrastText + " !important",
        },
        marginRight: {
            marginRight: "0.3em",
        },
    }));

export const SnackbarProvider = ({ children }) => {
    const theme = useTheme();

    const classes = useStyles(theme)();

    // configure snackbar provider.
    const snackbarConfig = {
        maxSnack: 3,
        ariaAttributes: { "aria-describedby": "message-id" },
        classes: {
            containerRoot: classes.containerRoot,
            variantInfo: classes.info,
            variantSuccess: classes.success,
            variantWarning: classes.warning,
            variantError: classes.error,
        },
    };

    return (
        <NotistackProvider
            {...snackbarConfig}
            iconVariant={{
                success: <TaskAltIcon className={classes.marginRight} />,
                error: <ErrorOutlineIcon className={classes.marginRight} />,
            }}
        >
            {children}
        </NotistackProvider>
    );
};
