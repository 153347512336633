import { useContext } from "react";
import { FavoritesActionsContext, FavoritesStateContext } from "./FavoritesContext.context";

export function useFavoritesState() {
    const context = useContext(FavoritesStateContext);

    if (context === undefined) {
        throw new Error("useFavoritesState must be used within a FavoritesProvider");
    }

    return context;
}

export function useFavoritesActions() {
    const context = useContext(FavoritesActionsContext);

    if (context === undefined) {
        throw new Error("useFavoritesActions must be used within a FavoritesProvider");
    }

    return context;
}

export function useFavorites() {
    return [useFavoritesState(), useFavoritesActions()];
}
