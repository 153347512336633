import financial_operation_modes from "./financial_operation_modes";
import financial_operation_types from "./financial_operation_types";
import invoice_statuses from "./invoice_statuses";
import reserve_released from "./reserve_released";
import payment_methods from "./payment_methods";
import payment_means from "./payment_means";
import settlement_sepa from "./settlement_sepa";
import settlement_statuses from "./settlement_statuses";

export default {
    financial_operation_modes,
    financial_operation_types,
    invoice_statuses,
    reserve_released,
    payment_methods,
    payment_means,
    settlement_statuses,
};

export const internal = { settlement_sepa };
