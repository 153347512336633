import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";

const stylesCellChip = (theme) => ({
    wrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
        height: "24px",
    },
});

/**
 * Cette cellule permet d'afficher un volume ou un montant avec devise
 */
class CellChipClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Type d'affichage de l'attribute
         */
        displayType: PropTypes.string,
        /**
         * Volume/Amount
         */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /**
         * Align (center/left/right)
         */
        align: PropTypes.oneOf(["center", "left", "right"]),
        /**
         * Couleur du chip
         */
        color: PropTypes.string,
        /**
         * Couleur du label
         */
        labelColor: PropTypes.string,
    };

    static defaultProps = {
        align: "left",
        color: "primary",
        labelColor: "primary",
    };

    render() {
        const { classes, value, color, align, labelColor } = this.props;

        return (
            <div className={classes.wrapper} style={{ justifyContent: align }}>
                <Chip
                    label={value}
                    className={classes.chip}
                    color={labelColor}
                    style={{ backgroundColor: color }}
                />
            </div>
        );
    }
}

export const CellChip = withStyles(stylesCellChip)(CellChipClass);
