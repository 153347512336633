import attributes from "./attributes";
import references from "../references";
import { AppTransaction } from "../index";
import { getGuideTourSteps } from "./guidetour";
import { FEATURE_SEARCH_SELECTION } from "@console/core";
import { SendOutlined } from "@mui/icons-material";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "transaction",
    gaming: {
        master: true,
        attributes: true,
        references: true,
    },
    attributes: attributes,
    defaultFormFields: {
        created_date: null,
        status: null,
        status_period: "current",
        payment_means: null,
        customer_email: null,
        sentinel_result: null,
        sentinel_review_result: null,
    },
    icon: SendOutlined,
    label: "menu.transaction.label",
    path: "transactions",
    route: "[prefix]/transactions",
    features: [
        FEATURE_SEARCH_SELECTION,
        "qsearch",
        "form",
        "export",
        "export-v2",
        "sliding_periods",
        "limited-search",
    ],
    export: {
        limitLiveExport: process.env.NX_TRANSACTION_LIMIT_SYNCHRONOUS_EXPORT,
        limitEmailExport: process.env.NX_TRANSACTION_LIMIT_EXPORT,
        limitBigExport: process.env.NX_TRANSACTION_LIMIT_BIG_EXPORT,
    },
    apiUrl: process.env.NX_CONSOLE_API_URL,
    suggestRoute: "/_suggest?",
    references: references,
    acl: {
        module: [
            {
                module: "transaction",
                controller: "manage",
            },
            {
                module: "transaction",
                controller: "detail",
                action: "index",
            },
        ],
        export: {
            module: "transaction",
            controller: "export",
        },
        bigExport: {
            module: "transaction",
            controller: "export",
            action: "export-big-volume",
        },
    },
    getGuideTourSteps: getGuideTourSteps,
};

export const moduleRouting = [
    {
        key: "transactions",
        path: ["/transactions", "/accounts-:ids/transactions"],
        component: AppTransaction,
    },
];
