import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFields = {
    type: null,
    status: null,
    started_at: null,
    finished_at: null,
};

export const availableFields = [
    attributes.type.id,
    attributes.status.id,
    attributes.started_at.id,
    attributes.finished_at.id,
];
