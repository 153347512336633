import moize from "moize";
import moment from "moment-timezone";
import {
    getOperatorListByAttribute,
    getRangeOperatorListByAttribute,
    isMultiValueOperator,
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
    VALUE_EMPTY,
} from "./operators";
import { foldAccents } from "../../utils/strings";
import { FORM_FIELD_DATE, FORM_FIELD_NUMERIC } from "../../common/constants/formFieldTypes";

// TERM TYPES
// Use to analyze each term in query
// To know previous term type allow to define next term.
// NONE > FREETEXT > KEYWORD > ...
// NONE > FIELD > OPERATOR > VALUE > KEYWORD > ...
// UNKNOWN - query can't find term type relative to previous term
export const TERM_NONE = "TERM_NONE";
export const TERM_KEYWORD = "TERM_KEYWORD";
export const TERM_EXCLUDE = "TERM_EXCLUDE";
export const TERM_FREETEXT = "TERM_FREETEXT";
export const TERM_FIELD = "TERM_FIELD";
export const TERM_OPERATOR = "TERM_OPERATOR";
export const TERM_OPERATOR_PREFIX = "TERM_OPERATOR_PREFIX";
export const TERM_VALUE = "TERM_VALUE";
export const TERM_RANGE_VALUE = "TERM_RANGE_VALUE";
export const TERM_UNKNOWN = "TERM_UNKNOWN";
export const TERM_OPEN_MULTI = "TERM_OPEN_MULTI";
export const TERM_CLOSE_MULTI = "TERM_CLOSE_MULTI";

class QueryParser {
    getNumericRegex = () => {
        // match float number, ex -12.34
        return "([+-]?([0-9]*[.])?[0-9]+)";
    };

    getDateRegex = () => {
        // match date, ex 31/12/2019, 01-01-2019
        return "(\\d{4}(([\\/\\-](0?[1-9]|1[012]))?[\\/\\-](0[1-9]|[12][0-9]|3[01]))?)";
    };

    getRangeRegex = (termRegex, phrases) => {
        return `(${termRegex}\\s+?${phrases.app.qsearch.operators.to}\\s+?${termRegex})`;
    };

    getNumericRangeRegex = (phrases) => {
        const numericRegex = this.getNumericRegex();
        return this.getRangeRegex(numericRegex, phrases);
    };

    getDateRangeRegex = (phrases) => {
        const dateRegex = this.getDateRegex();
        return this.getRangeRegex(dateRegex, phrases);
    };

    /**
     * Parse query to term list
     *
     * @param phrases
     * @param query
     * @param debug
     * @returns {Array}
     */
    parseQueryToTermList = (phrases, query = "", debug = false) => {
        const staticTermList = this.getStaticTermList(phrases);
        const staticTermRegex = `(${staticTermList.join(")|(")})`;

        const termList = [];
        let term;
        // match term
        // - (xxx)
        // - "xxx"
        // - 'xxx'
        // - 12.34 TO 56
        // - AND, OR, IN, NOT IN (static terms)
        // - xxx
        const regex1 = RegExp(
            `((\\(.*?\\)|".*?"|'.*?'|${this.getNumericRangeRegex(phrases)}|${this.getDateRangeRegex(
                phrases
            )}|${this.getNumericRegex()}|${this.getDateRegex()}|${staticTermRegex})|(".*?".*?)|(["'].*?)$|([^\\s]+))(?=\\s*\\s|\\s*$)`,
            "g"
        );
        while ((term = regex1.exec(query)) !== null) {
            termList.push({
                value: term[0],
                offsetStart: term.index,
                offsetEnd: term.index + term[0].length - 1,
            });
        }
        return termList;
    };

    /**
     * Caret is between end of first char and end of last char
     * - |test  = FALSE
     * - te|st  = TRUE
     * - test|  = TRUE
     * - test | = FALSE
     * @param caretPosition
     * @returns {function(*): boolean}
     */
    isTermCurrentByCaret = (caretPosition) => (term) => {
        return term.offsetStart < caretPosition && term.offsetEnd >= caretPosition - 1;
    };

    /**
     * Caret is after the char next to the last char
     * - test|  = FALSE
     * - test | = TRUE
     * @param caretPosition
     * @returns {function(*): boolean}
     */
    isTermBeforeCaret = (caretPosition) => (term) => {
        return term.offsetEnd < caretPosition - 1;
    };

    /**
     * Caret is before the first char
     * - |test = TRUE
     * @param caretPosition
     * @returns {function(*): boolean}
     */
    isTermAfterCaret = (caretPosition) => (term) => {
        return term.offsetStart >= caretPosition;
    };

    /**
     * Find if text correspond to the suggest label of a module relative attribute
     * then return targetField (attribute id)
     * else return undefined
     * @param module
     * @param text
     * @param phrases
     * @returns {*}
     */
    getAsField = (text, module, phrases) => {
        const attributePhrases = phrases.attributes[module];
        return Object.keys(attributePhrases).find(
            (attr) =>
                attributePhrases[attr].suggestLabel &&
                text.match(`^["']?${attributePhrases[attr].suggestLabel}["']?$`) // match accept quotes
        );
    };

    getAsMisformattedClause = (text, module, attributes, phrases) => {
        let found = /^["'](.*?)["'](.*)/g.exec(text);
        if (found && found[1]) {
            // find field
            const attributePhrases = phrases.attributes[module];
            const field = Object.keys(attributePhrases).find(
                (attr) => attributePhrases[attr].suggestLabel === found[1]
            );
            if (field && found[2]) {
                // find operator
                const operatorPhrases = phrases.app.qsearch.operators;
                const operatorList = getOperatorListByAttribute(attributes[field]);
                const pureText = foldAccents(found[2].toLowerCase());
                const operator = operatorList.find(
                    (o) =>
                        operatorPhrases[o] &&
                        pureText.indexOf(foldAccents(operatorPhrases[o].toLowerCase())) === 0
                );

                if (operator) {
                    const value = found[2].slice(operatorPhrases[operator].length);
                    return `${field}¤${operator}¤${value}`;
                }
            }
        }

        return null;
    };

    defaultKeywordPhrases = {
        and: "AND",
        not: "NOT",
        or: "OR",
    };

    defaultOperatorPhrases = {
        equal: "=",
        gt: ">",
        gte: ">=",
        in: "IN",
        lt: "<",
        lte: "<=",
        not_equal: "!=",
        not_in: "NOT IN",
        to: "TO",
    };

    findOperator = (text, phrases) => {
        const pureText = text ? foldAccents(text.toLowerCase()) : "";
        const operatorPhrases = phrases.app.qsearch.operators;
        return Object.keys(operatorPhrases).find(
            (operator) =>
                foldAccents(this.defaultOperatorPhrases[operator].toLowerCase()) === pureText ||
                foldAccents(operatorPhrases[operator].toLowerCase()) === pureText
        );
    };

    isOperatorSupportedByAttribute = (operator, attribute) => {
        return getOperatorListByAttribute(attribute).includes(operator);
    };

    /**
     * Find if text correspond to a valid operator for the targetField attribute
     * then return operator
     * else return undefined
     *
     * @param text
     * @param attribute
     * @param phrases
     * @param rangeOperator
     * @returns {*}
     */
    getAsOperator = (text, attribute, phrases, rangeOperator = false) => {
        const operatorPhrases = phrases.app.qsearch.operators;
        const pureText = text ? foldAccents(text.toLowerCase()) : "";

        const operatorList = rangeOperator
            ? getRangeOperatorListByAttribute(attribute)
            : getOperatorListByAttribute(attribute);

        return operatorList.find(
            (operator) =>
                operatorPhrases[operator] &&
                (foldAccents(this.defaultOperatorPhrases[operator].toLowerCase()) === pureText ||
                    foldAccents(operatorPhrases[operator].toLowerCase()) === pureText)
        );
    };

    /**
     * Find if text correspond to a valid prefix operator for the targetField attribute
     * @param text
     * @param attribute
     * @param phrases
     * @returns {*}
     */
    findAsOperatorPrefix = (text, attribute, phrases) => {
        const operatorPhrases = phrases.app.qsearch.operators;
        const pureText = text ? foldAccents(text.toLowerCase()) : "";

        const operatorList = getOperatorListByAttribute(attribute);

        return operatorList.some(
            (operator) =>
                operatorPhrases[operator] &&
                (foldAccents(this.defaultOperatorPhrases[operator].toLowerCase()).indexOf(
                    pureText
                ) === 0 ||
                    foldAccents(operatorPhrases[operator].toLowerCase()).indexOf(pureText) === 0)
        );
    };

    /**
     * Find if text correspond to a valid keyword
     * then return keyword
     * else return undefined
     * @param text
     * @param phrases
     * @returns {*}
     */
    getAsKeyword = (text, phrases) => {
        const keywordPhrases = phrases.app.qsearch.keywords;
        const pureText = text ? foldAccents(text.toLowerCase()) : "";

        return ["and", "or"].find(
            (keyword) =>
                keywordPhrases[keyword] &&
                (foldAccents(this.defaultKeywordPhrases[keyword].toLowerCase()) === pureText ||
                    foldAccents(keywordPhrases[keyword].toLowerCase()) === pureText)
        );
    };

    /**
     * Find if text correspond to an exclude keyword
     * then return keyword
     * else return undefined
     * @param text
     * @param phrases
     * @returns {*}
     */
    getAsExclude = (text, phrases) => {
        const excludePhrases = phrases.app.qsearch.keywords.not;
        const pureText = text ? foldAccents(text.toLowerCase()) : "";

        return foldAccents(this.defaultKeywordPhrases["not"].toLowerCase()) === pureText ||
            foldAccents(excludePhrases.toLowerCase()) === pureText
            ? ["not"]
            : [];
    };

    getAsNumericKeyword = (text, module, phrases) => {
        const keywordPhrases = phrases.app.qsearch.keywords;
        const pureText = text ? foldAccents(text.toLowerCase()) : "";

        return ["to"].find(
            (keyword) =>
                keywordPhrases[keyword] &&
                (foldAccents(this.defaultKeywordPhrases[keyword].toLowerCase()) === pureText ||
                    foldAccents(keywordPhrases[keyword].toLowerCase()) === pureText)
        );
    };

    getStaticTermList = (phrases) => {
        return [
            // order matter - to match (NOT IN) before just (NOT)
            ...Object.values(phrases.app.qsearch.operators),
            ...Object.values(phrases.app.qsearch.keywords),
        ];
    };

    /**
     * Return true if query match multi value pattern
     * - ( jean, "jack", 'tom' )
     * @param text
     * @returns {boolean}
     */
    isMultiValueTerm = (text) => {
        // test multi value term
        // - start by ( and end by )
        // - term values quoted or not, space by ,
        const regexMultiValue = RegExp(
            "^\\(\\s*?(((\".*?\")|('.*?')|[^\"'\\s,]+)([\\s,]+((\".*?\")|('.*?')|[^\"'\\s,]+))*\\s*?,?\\s*?)?\\)$"
        );
        return regexMultiValue.test(text);
    };

    /**
     * Return true if query match numeric range pattern
     * - 12.34 TO 15
     * @param text
     * @param phrases
     * @returns {boolean}
     */
    isNumericRangeTerm = (text, phrases) => {
        // test date range term
        const regexNumericRange = RegExp(this.getNumericRangeRegex(phrases));
        return regexNumericRange.test(text);
    };

    isNumericTerm = (text, phrases) => {
        // test date range term
        const regexNumeric = RegExp(`^${this.getNumericRegex(phrases)}$`);
        return regexNumeric.test(text);
    };

    /**
     * Return true if query match date range pattern
     * - 2018-01-02 TO 2019-03
     * @param text
     * @param phrases
     * @returns {boolean}
     */
    isDateRangeTerm = (text, phrases) => {
        // test date range term
        const regexDateRange = RegExp(this.getDateRangeRegex(phrases));
        return regexDateRange.test(text);
    };

    isDateTerm = (text, phrases) => {
        // test date range term
        const regexDate = RegExp(`^${this.getDateRegex(phrases)}$`);
        return regexDate.test(text);
    };

    isEmptyTerm = (text, phrases, prevTerm) => {
        if (
            prevTerm.type !== TERM_OPERATOR ||
            ![OPERATOR_EQUAL, OPERATOR_NOT_EQUAL].includes(prevTerm.operator)
        ) {
            return false;
        }
        return text.replace(/"/g, "") === phrases?.app?.qsearch?.values?.empty;
    };

    isRangeAttribute = (attribute) => {
        return (
            attribute.formFieldType === FORM_FIELD_NUMERIC ||
            attribute.formFieldType === FORM_FIELD_DATE
        );
    };

    /**
     * Return multi target value list
     *
     * @param text
     * @returns {Array}
     */
    parseMultiTargetValueToTermList = (text) => {
        const termList = [];

        const regexValue = RegExp(/(".*?")|('.*?')|[^"'\s(),]+/, "g");
        let term;
        while ((term = regexValue.exec(text)) !== null) {
            termList.push({
                value: term[0],
                offsetStart: term.index,
                offsetEnd: term.index + term[0].length - 1,
            });
        }

        return termList;
    };

    getAsReferenceValue = (text, reference, phrases, module) => {
        const refPhrases = phrases.ref[module][reference];
        if (refPhrases && text) {
            let value = Object.keys(refPhrases).find((ref) => {
                const match = text.match(`["']?${refPhrases[ref].replace(/\+/g, "\\+")}["']?`);
                return (
                    refPhrases[ref] &&
                    typeof refPhrases[ref] === "string" &&
                    match &&
                    refPhrases[ref].indexOf(text.replace(/"/g, "")) === 0
                );
            });

            if (/^value_[0-9]+/.test(value)) {
                // Keep only value for numeric values since translation key are like : value_2
                value = value.replace("value_", "");
            }
            return value;
        }
        return null;
    };

    /**
     * Return term value that is before caret
     * @param term
     * @param caretPosition
     * @returns {string}
     */
    getSlicedTermValue = (term, caretPosition) => {
        if (caretPosition - term.offsetEnd - 1 === 0) {
            return term.value;
        }
        return term.value.slice(0, caretPosition - term.offsetEnd - 1);
    };

    /**
     * Analyze Query
     * Parse query to term list then analyzed them to find errors
     *
     * return analysis object
     * - analyzedTermList: [
     *      type
     *      value
     *      field
     *      operator
     * ]
     * - errors: [
     *      code
     *      message
     *      meta
     * ]
     *
     * @param query
     * @param module
     * @param attributes
     * @param phrases
     * @param operatorList
     * @param debug
     * @returns {{analyzedTermList: Any.analyzedTermList, errors: Any.errors}}
     */
    analyzeQuery = (query, module, attributes, phrases, operatorList, debug = false) => {
        const termList = this.parseQuery(query);

        // detect errors in query
        let { analyzedTermList, errors } = termList.reduce(
            (memo, term) => this.analyzeTermQuery(memo, term, module, attributes, phrases, debug),
            {
                analyzedTermList: [],
                errors: [],
            }
        );

        // detect wrong end of query
        let lastTerm = analyzedTermList[analyzedTermList.length - 1];
        if (lastTerm) {
            switch (lastTerm.type) {
                case TERM_FIELD:
                    errors.push({
                        code: 87,
                        message: "Request error : query ends with field.",
                        meta: {
                            value: lastTerm.text,
                            operator_list: operatorList,
                        },
                    });
                    break;
                case TERM_OPERATOR:
                case TERM_OPEN_MULTI:
                    errors.push({
                        code: 88,
                        message: "Request error : query ends with operator.",
                        meta: { value: lastTerm.text },
                    });
                    break;
                case TERM_VALUE:
                    if (isMultiValueOperator(lastTerm.operator)) {
                        errors.push({
                            code: 89,
                            message: "Request error : query ends with opened value list.",
                        });
                    }
                    break;
                case TERM_KEYWORD:
                    errors.push({
                        code: 90,
                        message: "Request error : query ends with keyword.",
                        meta: { value: lastTerm.text },
                    });
                    break;
                default:
                    break;
            }
        }
        return {
            analyzedTermList,
            errors,
        };
    };

    /**
     * Parse query to term list
     * ex: jeanne AND "john doe" OR "jeanne doe
     * >> [ 'jeanne', 'AND', '"john doe"', 'OR', '"jeanne doe' ]
     * @param query
     * @returns {Array}
     */
    parseQuery = (query) => {
        const termList = [];
        const regexValue = RegExp(/(".*?")|(".*?$)|([^\s]+)/, "g");
        let term;
        while ((term = regexValue.exec(query)) !== null) {
            termList.push({
                text: term[0],
                offsetStart: term.index,
                offsetEnd: term.index + term[0].length - 1,
            });
        }
        return termList;
    };

    /**
     * Analyze term and fill errors list for each error found
     *
     * return analysis object
     * - analyzedTermList: [
     *      type
     *      value
     *      field
     *      operator
     * ]
     * - errors: [
     *      code
     *      message
     *      meta
     * ]
     *
     * @param analysis
     * @param term
     * @param module
     * @param attributes
     * @param phrases
     * @param debug
     * @returns {{analyzedTermList: *[], errors: *[]}}
     */
    analyzeTermQuery = (analysis, term, module, attributes, phrases, debug = false) => {
        let { text, offsetStart, offsetEnd } = term;

        const prevTerm = analysis.analyzedTermList[analysis.analyzedTermList.length - 1] || {
            type: TERM_NONE,
        };

        const attribute = attributes[prevTerm.field];

        // initial analyzed term
        let analyzedTerm = {
            text,
            offsetStart,
            offsetEnd,
            type: TERM_UNKNOWN,
        };

        let _field, _operator, _value, _keyword;

        let error;

        switch (prevTerm.type) {
            case TERM_NONE:
            case TERM_KEYWORD:
                // after none or keyword - expect a field OR a freetext
                // term could be a field
                _field = this.getAsField(text, module, phrases);
                if (_field) {
                    analyzedTerm.type = TERM_FIELD;
                    analyzedTerm.field = _field;
                } else if (/^"[^"]+$/.test(text)) {
                    // term could miss "
                    error = {
                        code: 91,
                        message: "Format error : missing closing quotes",
                        meta: { value: text },
                    };
                } else if (text.includes("¤")) {
                    error = {
                        code: 101,
                        message: "Format error : ¤ is a forbidden character",
                        meta: { value: text },
                    };
                } else {
                    // term is a freetext
                    analyzedTerm.type = TERM_FREETEXT;
                }
                break;

            case TERM_OPERATOR_PREFIX:
                // concat prefix and term as it should be an operator
                analysis.analyzedTermList.pop();
                text = prevTerm.text + " " + text;
                offsetStart = prevTerm.offsetStart;

            // wanted fallthrough
            case TERM_FIELD:
                // after field - expect operator or operator prefix
                // term could be an operator (relative to te field)
                _operator = this.findOperator(text, phrases);
                if (_operator) {
                    if (this.isOperatorSupportedByAttribute(_operator, attribute)) {
                        analyzedTerm.type = TERM_OPERATOR;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = _operator;
                        analyzedTerm.offsetStart = offsetStart;
                    } else {
                        error = {
                            code: 95,
                            message: "Field error : field do not support operator",
                            meta: {
                                operator: _operator,
                                field: prevTerm.field,
                                module,
                            },
                        };
                    }
                } else if (this.findAsOperatorPrefix(text, attribute, phrases)) {
                    // term could be a part of an operator (NOT IN, PAS DANS, ...)
                    analyzedTerm.type = TERM_OPERATOR_PREFIX;
                    analyzedTerm.field = prevTerm.field;
                } else {
                    // ERROR 002
                    error = {
                        code: 85,
                        message: "Request error : operator expected",
                        meta: { value: text },
                    };
                }

                break;

            case TERM_OPEN_MULTI:
            case TERM_OPERATOR:
                // after operator - expect value or '(' for multi values
                // term should be a value or a range value

                // ERROR 012
                if (/^"[^"]+$/.test(text)) {
                    error = {
                        code: 91,
                        message: "Format error : missing closing quotes",
                        meta: { value: text },
                    };
                } else if (attribute.formFieldType === FORM_FIELD_DATE) {
                    // if field is date
                    // term should be a date
                    if (this.isDateTerm(text, phrases)) {
                        if (moment(text).isValid()) {
                            analyzedTerm.type = TERM_VALUE;
                            analyzedTerm.field = prevTerm.field;
                            analyzedTerm.operator = prevTerm.operator;
                            analyzedTerm.value = text;
                        } else {
                            error = {
                                code: 92,
                                message: "Value error : impossible date",
                                meta: { value: text },
                            };
                        }
                    } else if (this.isEmptyTerm(text, phrases, prevTerm)) {
                        analyzedTerm.type = TERM_VALUE;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                        analyzedTerm.value = VALUE_EMPTY;
                    } else {
                        // ERROR 15
                        error = {
                            code: 92,
                            message: "Format error : invalid date",
                            meta: { value: text },
                        };
                    }
                } else if (attribute.formFieldType === FORM_FIELD_NUMERIC) {
                    // if field is numeric
                    // term should be a date
                    if (this.isNumericTerm(text)) {
                        analyzedTerm.type = TERM_VALUE;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                        analyzedTerm.value = text;
                    } else if (this.isEmptyTerm(text, phrases, prevTerm)) {
                        analyzedTerm.type = TERM_VALUE;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                        analyzedTerm.value = VALUE_EMPTY;
                    } else {
                        // ERROR 16
                        error = {
                            code: 93,
                            message: "Format error : invalid numeric",
                            meta: { value: text },
                        };
                    }
                } else if (
                    prevTerm.type !== TERM_OPEN_MULTI &&
                    isMultiValueOperator(prevTerm.operator)
                ) {
                    // some operator (in, not_in) expect multi value
                    // term should be (
                    if (text === "(") {
                        analyzedTerm.type = TERM_OPEN_MULTI;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                    } else {
                        // ERROR 031
                        error = {
                            code: 97,
                            message: "Operator error : operator expect values list ( a, b, c )",
                            meta: {
                                value: text,
                                operator: prevTerm.operator,
                            },
                        };
                    }
                } else {
                    // term should be a value relative to field
                    if (
                        attribute.reference &&
                        text !== `"${phrases?.app?.qsearch?.values?.empty}"`
                    ) {
                        _value = this.getAsReferenceValue(
                            text,
                            attribute.reference,
                            phrases,
                            module
                        );
                    } else {
                        _value = text;
                    }
                    if (_value) {
                        analyzedTerm.type = TERM_VALUE;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                        analyzedTerm.value = _value;
                    } else {
                        // ERROR 23
                        error = {
                            code: 96,
                            message: "Field error : value do not exist for field",
                            meta: {
                                value: text,
                                field: prevTerm.field,
                                module,
                            },
                        };
                    }
                }
                break;

            case TERM_VALUE:
                // after value in multi value - expect , or )
                if (isMultiValueOperator(prevTerm.operator)) {
                    if (text === ",") {
                        analyzedTerm.type = TERM_OPEN_MULTI;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                    } else if (text === ")") {
                        // term could be )
                        analyzedTerm.type = TERM_CLOSE_MULTI;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = prevTerm.operator;
                    } else {
                        // ERROR
                        error = {
                            code: 86,
                            message: "Request error : closing parenthesis expected",
                            meta: { value: text },
                        };
                    }
                    break;
                }

                // after value - expect TO or keyword

                // term could be a range operator (TO)
                _operator = this.getAsOperator(text, attribute, phrases, true);
                if (_operator && prevTerm.operator === "equal") {
                    if (
                        attribute.formFieldType === FORM_FIELD_DATE ||
                        attribute.formFieldType === FORM_FIELD_NUMERIC
                    ) {
                        analyzedTerm.type = TERM_OPERATOR;
                        analyzedTerm.field = prevTerm.field;
                        analyzedTerm.operator = _operator;
                        analyzedTerm.offsetStart = offsetStart;
                    }
                    break;
                }

            // wanted fallthrough
            case TERM_CLOSE_MULTI:
            case TERM_FREETEXT:
            case TERM_UNKNOWN:
                // term should be a keyword
                _keyword = this.getAsKeyword(text, phrases);
                if (_keyword) {
                    analyzedTerm = {
                        type: TERM_KEYWORD,
                        keyword: _keyword,
                    };
                } else if (this.getAsOperator(text, null, phrases)) {
                    // ERROR 21
                    error = {
                        code: 94,
                        message: "Request error : field do not exist",
                        meta: { value: prevTerm.text },
                    };
                } else {
                    // ERROR 1
                    error = {
                        code: 55,
                        message: "Request error : keyword expected",
                        meta: { value: text },
                    };
                }
                break;
            default:
                break;
        }

        return {
            analyzedTermList: [...analysis.analyzedTermList, analyzedTerm],
            errors: error ? [...analysis.errors, error] : analysis.errors,
        };
    };

    /**
     * Analyze term
     *
     * return analysis object
     * - term: null
     * - complete: null
     * - start:
     * - end:
     * - previousTermAnalysis: {
     *      - type
     *      - field
     *      - operator
     *      - value
     * - }
     *
     * @param term
     * @param previousTermAnalysis
     * @param module
     * @param phrases
     * @param isQuickSearch
     * @param debug
     * @returns {*}
     */
    analyzeTerm = (
        term,
        previousTermAnalysis,
        module,
        attributes,
        phrases,
        isQuickSearch = false,
        debug = false
    ) => {
        const { type, field, operator, prefix, start } = previousTermAnalysis.previousTerm;

        const attribute = attributes[field];

        // We don't find term type
        let analyzedPreviousTerm = { type: TERM_UNKNOWN };

        let _clause, _field, _operator, _keyword, _exclude;

        switch (type) {
            case TERM_NONE:
            case TERM_KEYWORD:
                // term could be a field OR a freetext
                _exclude = this.getAsExclude(term.value, phrases);
                if (_exclude.length && !isQuickSearch) {
                    analyzedPreviousTerm = {
                        type: TERM_EXCLUDE,
                        keyword: _exclude,
                        start: term.offsetStart,
                    };
                    break;
                }
            // wanted fallthrough
            case TERM_EXCLUDE:
                _field = this.getAsField(term.value, module, phrases);
                if (_field) {
                    analyzedPreviousTerm = {
                        type: TERM_FIELD,
                        field: _field,
                    };
                } else {
                    _clause = this.getAsMisformattedClause(term.value, module, attributes, phrases);
                    if (_clause) {
                        analyzedPreviousTerm = {
                            type: TERM_FREETEXT,
                            misformatClause: _clause,
                            start: term.offsetStart,
                        };
                    } else {
                        analyzedPreviousTerm = { type: TERM_FREETEXT };
                    }
                }
                break;

            case TERM_OPERATOR_PREFIX:
                // concat prefix and term as it should be an operator
                term.value = prefix + " " + term.value;
                term.offsetStart = start;

            // wanted fallthrough
            case TERM_FIELD:
                // term should be an operator (relative to te field)
                _operator = this.getAsOperator(term.value, attribute, phrases);
                if (_operator) {
                    analyzedPreviousTerm = {
                        type: TERM_OPERATOR,
                        field: field,
                        operator: _operator,
                        start: term.offsetStart,
                    };
                } else if (this.findAsOperatorPrefix(term.value, attribute, phrases)) {
                    // term could be a part of an operator (NOT IN, PAS DANS, ...)
                    analyzedPreviousTerm = {
                        type: TERM_OPERATOR_PREFIX,
                        field: field,
                        prefix: term.value,
                        start: term.offsetStart,
                    };
                }

                break;

            case TERM_OPERATOR:
                // term should be a value or a range value
                analyzedPreviousTerm = {
                    type: TERM_VALUE,
                    field: field,
                    operator: operator,
                    value: term.value,
                };

                // detect range value to not suggest range operator
                if (
                    this.isDateRangeTerm(term.value, phrases) ||
                    this.isNumericRangeTerm(term.value, phrases)
                ) {
                    analyzedPreviousTerm.type = TERM_RANGE_VALUE;
                }

                break;

            case TERM_VALUE:
                // term could be a range operator (TO)
                _operator = this.getAsOperator(term.value, attribute, phrases, true);
                if (_operator) {
                    analyzedPreviousTerm = {
                        type: TERM_OPERATOR,
                        field: field,
                        operator: _operator,
                        start: term.offsetStart,
                    };
                    break;
                }

            // wanted fallthrough
            case TERM_RANGE_VALUE:
            case TERM_FREETEXT:
            case TERM_UNKNOWN:
                // term should be a keyword
                _keyword = this.getAsKeyword(term.value, phrases);
                if (_keyword) {
                    analyzedPreviousTerm = {
                        type: TERM_KEYWORD,
                        keyword: _keyword,
                        start: term.offsetStart,
                    };
                }
                break;
            default:
                break;
        }

        return {
            term: null,
            completeTerm: null,
            start: previousTermAnalysis.start,
            end: previousTermAnalysis.end,
            previousTerm: analyzedPreviousTerm,
        };
    };

    /**
     * Fonction recursive qui analyse le dernier term de la liste en fonction de celui d'avant
     *
     * Use MOIZE to cache 30 results for this function
     *
     * @param termList
     * @param caretPosition
     * @param debug
     * @returns {*}
     */
    recursiveTermListAnalysis = moize(
        (termList, caretPosition, module, attributes, phrases, isQuickSearch, debug = false) => {
            const scanTerm = termList[termList.length - 1];
            // STOP CONDITION
            // scanTerm is undefined
            // it means that we have scanned all the list
            if (scanTerm === undefined) {
                return {
                    term: null,
                    completeTerm: null,
                    start: caretPosition,
                    end: caretPosition,
                    previousTerm: { type: TERM_NONE },
                };
            }

            // FORCE DIVE
            // term is after caret
            if (this.isTermAfterCaret(caretPosition)(scanTerm)) {
                const result = this.recursiveTermListAnalysis(
                    termList.slice(0, -1),
                    caretPosition,
                    module,
                    attributes,
                    phrases,
                    isQuickSearch,
                    debug
                );
                return result;
            }

            const previousTermAnalysis = this.recursiveTermListAnalysis(
                termList.slice(0, -1),
                caretPosition,
                module,
                attributes,
                phrases,
                isQuickSearch,
                debug
            );

            // term is before caret
            if (this.isTermBeforeCaret(caretPosition)(scanTerm)) {
                return this.analyzeTerm(
                    scanTerm,
                    previousTermAnalysis,
                    module,
                    attributes,
                    phrases,
                    isQuickSearch,
                    debug
                );
            }

            // term is current
            if (this.isTermCurrentByCaret(caretPosition)(scanTerm)) {
                return {
                    ...this.buildCurrentTerm(
                        scanTerm,
                        caretPosition,
                        previousTermAnalysis.previousTerm,
                        phrases,
                        debug
                    ),
                    previousTerm: previousTermAnalysis && previousTermAnalysis.previousTerm,
                };
            }

            return null;
        },
        {
            isDeepEqual: true,
            maxAge: 10000, // clean cache after 10s
            // onCacheHit: cache => (console.log('SAVE')),
        }
    );

    analyzeCurrentTermQuery = (
        query = "",
        caretPosition = 0,
        module,
        attributes,
        phrases,
        isQuickSearch = false,
        debug = false
    ) => {
        // INITIAL CASE
        if (caretPosition === 0 || query === "") {
            return null;
        }

        const termList = this.parseQueryToTermList(phrases, query);

        // BLANK QUERY
        if (termList.length === 0) {
            return null;
        }

        return this.recursiveTermListAnalysis(
            termList,
            caretPosition,
            module,
            attributes,
            phrases,
            isQuickSearch,
            debug
        );
    };

    /**
     * Build current term properties and hanlde exceptions for specific behaviour
     * ex: multi value
     *
     * @param scanTerm
     * @param caretPosition
     * @param previousTerm
     * @param phrases
     * @param debug
     * @returns {*}
     */
    buildCurrentTerm = (scanTerm, caretPosition, previousTerm, phrases, debug = false) => {
        // Exception relative to previous term behaviour
        if (previousTerm !== null) {
            // Analyze term as multi value
            if (
                previousTerm.type === TERM_OPERATOR &&
                isMultiValueOperator(previousTerm.operator)
            ) {
                // Value respect multi value pattern && caret is not after closing )
                if (this.isMultiValueTerm(scanTerm.value) && caretPosition <= scanTerm.offsetEnd) {
                    // parse value as term list
                    const termList = this.parseMultiTargetValueToTermList(scanTerm.value);
                    const relativeCaretPosition = caretPosition - scanTerm.offsetStart;
                    // find current term
                    const currentTerm = termList.find(
                        (term) =>
                            term.offsetStart < relativeCaretPosition &&
                            term.offsetEnd > relativeCaretPosition - 2
                    );

                    if (currentTerm) {
                        // set current term as term to query and set absolute positions to replace
                        return {
                            term: this.getSlicedTermValue(currentTerm, relativeCaretPosition),
                            completeTerm: scanTerm.value,
                            start: currentTerm.offsetStart + scanTerm.offsetStart,
                            end: currentTerm.offsetEnd + scanTerm.offsetStart + 1,
                        };
                    } // no current term -
                    return {
                        term: null,
                        completeTerm: scanTerm.value,
                        start: caretPosition,
                        end: caretPosition,
                    };
                }
            }

            // Value respect date range pattern
            if (this.isDateRangeTerm(scanTerm.value, phrases)) {
                const relativeCaretPosition = caretPosition - scanTerm.offsetStart;

                let currentTerm;
                if (scanTerm) {
                    currentTerm = {
                        ...scanTerm,
                        offsetStart: 0,
                        offsetEnd: scanTerm.offsetEnd - scanTerm.offsetStart,
                    };
                }

                if (currentTerm) {
                    return {
                        term: this.getSlicedTermValue(currentTerm, relativeCaretPosition),
                        completeTerm: scanTerm.value,
                        start: currentTerm.offsetStart + scanTerm.offsetStart,
                        end: currentTerm.offsetEnd + scanTerm.offsetStart + 1,
                    };
                }
                return {
                    term: null,
                    completeTerm: scanTerm.value,
                    start: relativeCaretPosition,
                    end: relativeCaretPosition,
                };
            }
        }

        // default
        return {
            term: this.getSlicedTermValue(scanTerm, caretPosition), // remove char after caret
            completeTerm: scanTerm.value,
            start: scanTerm.offsetStart,
            end: scanTerm.offsetEnd + 1,
        };
    };

    /**
     * Return true if query is correct based on regex
     *
     * PLus d'info sur les regex => https://www.regular-expressions.info/
     *
     * @param query
     * @param phrases
     * @returns {boolean}
     */
    isValidQuery = (query, phrases) => {
        // TODO => get dateFormat from user settings & keywords from translations ?
        const dateRegex = "([0-9]{4}(?=-)(-[0-9]{2}){0,2})";
        const tgFieldRegex = "[à-ù\\w\\s'.]+";
        const valueRegex = "[à-ù\\w\\s'@.,&*\\-+:¤]+";
        const andOr = `(${phrases["app.qsearch.keywords.and"]}|${phrases["app.qsearch.keywords.or"]})`;
        const to = `(${phrases["app.qsearch.operators.to"]})`;
        const inNotIn = `(${phrases["app.qsearch.operators.in"]}|${phrases["app.qsearch.operators.not_in"]})`;
        const not = `${phrases["app.qsearch.keywords.not"]}`;
        const regex = new RegExp(
            `^(("${tgFieldRegex}")|\\w+)(((\\s+((!=(?!\\s*${dateRegex})|=)|((>|<|>=|<=)(?=(\\s+(${dateRegex}|[0-9])+))(?!\\s*(((${dateRegex}|[0-9?]+)\\*?\\s+${to})|([0-9]*\\?[0-9]*)|([0-9]+\\*)))))\\s+(("${valueRegex}")|('${valueRegex}')|((${dateRegex}|[0-9](((\\?(?![0-9]+\\s*${to}))|(\\*(?![0-9]+)))(?!\\s+[0-9]*\\s*${to}))?)+(\\s+${to}\\s+(${dateRegex}|[0-9])+)?)|([\\w@.-?*]+)))|(\\s+${inNotIn}\\s+\\((?!\\s*\\))(\\s*(("${valueRegex}")|('${valueRegex}')|([^"'\\\\s,]+))\\s*((,(?=(\\s*[^\\s)])+))|\\)))+))?((\\s+${andOr}\\s+(${not}\\s+)?)(("${tgFieldRegex}")|\\w+))?)*\\s*`,
            "i"
        );

        let matches = query.match(regex);

        return matches ? matches[0] === matches["input"] : false;
    };

    /**
     * Encode query
     *
     * Transform natural language query (translated) into technical query
     * Transformation keep every information (field, quotes, boolean structures, ...)
     *
     * @param query
     * @param module
     * @param attributes
     * @param phrases
     * @param debug
     * @returns {Any.query}
     */
    encodeQuery = (query, module, attributes, phrases, debug = false) => {
        const termList = this.parseQueryToTermList(phrases, query, debug);
        const qSearchQuery = query;
        let { query: encodedQuery, currentClause: lastClause } = termList.reduce(
            ({ query: _query, currentClause }, term) => {
                const attribute = attributes[currentClause.field];

                let text = term.value;
                if (!currentClause.field && !currentClause.freetext) {
                    // should be FIELD or FREETEXT
                    const field =
                        text.trim() !== qSearchQuery.trim()
                            ? this.getAsField(text, module, phrases)
                            : undefined;
                    if (field) {
                        currentClause.field = field;
                    } else {
                        currentClause.freetext = text;
                    }
                } else if (currentClause.field && !currentClause.operator) {
                    // should be OPERATOR
                    const operator = this.getAsOperator(text, attribute, phrases);
                    if (operator) {
                        currentClause.operator = operator;
                    } else {
                        console.warn("Encode Query - term should be an operator");
                    }
                } else if (currentClause.field && currentClause.operator && !currentClause.value) {
                    // should be VALUE
                    currentClause.value = this.encodeValue(text, attribute, phrases, module);
                } else {
                    // should be KEYWORD
                    const keyword = this.getAsKeyword(text, phrases);
                    if (keyword) {
                        if (currentClause.freetext) {
                            _query += currentClause.freetext;
                        } else {
                            _query += `${currentClause.field}¤${currentClause.operator}¤${currentClause.value}`;
                        }

                        _query += ` _${keyword}_ `;
                        currentClause = {};
                    }
                }

                return {
                    query: _query,
                    currentClause: currentClause,
                };
            },
            {
                query: "",
                currentClause: {},
            }
        );

        if (lastClause.freetext) {
            encodedQuery += lastClause.freetext;
        } else {
            encodedQuery += `${lastClause.field}¤${lastClause.operator}¤${lastClause.value}`;
        }

        return encodedQuery;
    };

    /**
     * Encode value
     *
     * If field has reference, try to find field reference value matching text
     * Reference value id will be transform into _id_
     * ex: Visa => _visa_
     *
     * @param text
     * @param attribute
     * @param phrases
     * @param module
     * @returns {*}
     */
    encodeValue = (text, attribute, phrases, module) => {
        if (!attribute) {
            console.warn("EncodeValue - Attribute is undefined");
            return text;
        }

        let encodedValue;
        if (text === `"${phrases?.app?.qsearch?.values?.empty}"`) {
            encodedValue = VALUE_EMPTY;
        } else if (attribute.reference) {
            // has static reference

            // manage value as multi value
            let termList = [text];
            if (this.isMultiValueTerm(text)) {
                termList = this.parseMultiTargetValueToTermList(text).map((term) => term.value);
            }

            encodedValue = termList
                .map((term) => {
                    const value = this.getAsReferenceValue(
                        term,
                        attribute.reference,
                        phrases,
                        module
                    );
                    if (value) {
                        return `_${value}_`; // _fr_ (France), _visa_ (VISA)
                    }
                    return term;
                })
                .join(",");
        } else if (this.isDateRangeTerm(text, phrases) || this.isNumericRangeTerm(text, phrases)) {
            // Numeric or Date range
            encodedValue = text.replace(
                new RegExp(`\\s${phrases.app.qsearch.operators.to}\\s`),
                "_to_"
            ); // 2019-01-02_to_2019-02-31
        } else {
            encodedValue = text;
        }
        return encodedValue;
    };

    /**
     * Decode query
     *
     * Transform technical query into natural language query (translated)
     * Transformation keep every information (field, quotes, boolean structures, ...)
     *
     * @param query
     * @param module
     * @param attributes
     * @param phrases
     * @param debug
     * @returns {string}
     */
    decodeQuery = (query, module, attributes, phrases, debug = false) => {
        if (!query || query === "") {
            return "";
        }

        let decodedQuery = "";

        const orQueries = query.split(" _or_ ");

        orQueries.forEach((orQuery, orIndex) => {
            if (orIndex > 0) {
                decodedQuery += " " + phrases.app.qsearch.keywords.or + " ";
            }

            const clauseList = orQuery.split(" _and_ "); // split clause by keywords

            clauseList.forEach((clause, index) => {
                if (index > 0) {
                    decodedQuery += " " + phrases.app.qsearch.keywords.and + " ";
                }

                const term = clause.match(/(.*?)¤(.*?)¤(.*)/);

                if (term) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [text, field, operator, value, ...other] = term;
                    const attribute = attributes[field];
                    decodedQuery += this.decodeClause(attribute, operator, value, module, phrases);
                } else {
                    // freetext
                    decodedQuery += clause;
                }
            });
        });

        return decodedQuery;
    };

    /**
     * Decode query as js object
     *
     * Transform technical query into javascript object keys/values
     *
     * @param query
     * @param module
     * @param attributes
     * @param phrases
     * @param debug
     * @returns {string}
     */
    decodeQueryAsObject = (query) => {
        if (!query || query === "") {
            return null;
        }

        let decodedQuery = {};

        const orQueries = query.split(" _or_ ");

        orQueries.forEach((orQuery) => {
            const clauseList = orQuery.split(" _and_ ");

            clauseList.forEach((clause) => {
                const term = clause.match(/(.*?)¤(.*?)¤(.*)/);

                if (term) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [text, field, operator, value] = term;
                    // Remove "" at begin and end
                    decodedQuery[field] = value.slice(1, -1);
                }
            });
        });

        return decodedQuery;
    };

    decodeClause = (attribute, operator, value, module, phrases) => {
        if (!attribute) {
            console.warn("Decode Clause - field should be an attribute");
            return "";
        }

        if (!phrases || !phrases.attributes || !phrases.attributes[module]) {
            console.warn("Module " + module + " attributes translations not loaded");
            return "";
        }

        let decodedClause = '"' + phrases.attributes[module][attribute.id].suggestLabel + '"';

        const operatorList = getOperatorListByAttribute(attribute);
        if (operatorList.indexOf(operator) === -1) {
            console.warn("Decode Clause - operator should be allowed by field");
            return "";
        }

        decodedClause += " " + phrases.app.qsearch.operators[operator];

        if (value === VALUE_EMPTY) {
            decodedClause += ` "${phrases?.app?.qsearch?.values?.empty}"`;
        } else if (attribute.reference) {
            // ref value
            // is multi value
            if (/(_[A-z0-9-]+_,?)+/.test(value) && isMultiValueOperator(operator)) {
                const isMulti = isMultiValueOperator(operator);
                const refValueList = isMulti ? value.split(",") : [value];

                // add - ( "value1", "value2", "value3" )
                decodedClause += isMulti ? " ( " : " ";

                decodedClause += refValueList
                    .map((val) => {
                        return this.decodeRefValue(val, attribute.reference, phrases, module);
                    })
                    .join(", ");

                decodedClause += isMulti ? " )" : "";
            } else {
                decodedClause +=
                    " " + this.decodeRefValue(value, attribute.reference, phrases, module);
            }
        } else if (/[0-9,\-/]+_to_[0-9,\-/]+/.test(value)) {
            // is range value
            const [from, to] = value.split("_to_");
            decodedClause += ` ${from} ${phrases.app.qsearch.operators.to} ${to}`;
        } else {
            // other value
            decodedClause += " " + value;
        }

        return decodedClause;
    };

    decodeRefValue = (refValue, reference, phrases, module) => {
        if (
            refValue &&
            reference &&
            phrases &&
            phrases.ref[module][reference] &&
            /^_.*_$/.test(refValue)
        ) {
            let _value = refValue.replace(/(^_)|(_$)/g, "");
            if (!isNaN(_value)) {
                // Transform value for translation key : e.g. value_2
                _value = `value_${_value}`;
            }
            const translatedValue = phrases.ref[module][reference][_value];
            if (translatedValue) {
                return translatedValue.indexOf("||||") < 0
                    ? `"${translatedValue}"`
                    : `"${translatedValue.split("||||")[0].trim()}"`;
            }
        } else {
            // other value
            return refValue;
        }
    };
}

export default new QueryParser();
