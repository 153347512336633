/**
 * Form Field Types
 * Définis les types de champs de formulaires
 * @type {string}
 */
export const FORM_FIELD_SELECT = "FORM_FIELD_SELECT";
export const FORM_FIELD_SUGGEST = "FORM_FIELD_SUGGEST";
export const FORM_FIELD_CUSTOM_DATA = "FORM_FIELD_CUSTOM_DATA";
export const FORM_FIELD_CHECKBOX = "FORM_FIELD_CHECKBOX";
export const FORM_FIELD_SWITCH = "FORM_FIELD_SWITCH";
export const FORM_FIELD_TEXT = "FORM_FIELD_TEXT";
export const FORM_FIELD_TEXT_REFERENCE = "FORM_FIELD_TEXT_REFERENCE";
export const FORM_FIELD_DATE = "FORM_FIELD_DATE";
export const FORM_FIELD_MONTH = "FORM_FIELD_MONTH";
export const FORM_FIELD_STATUS = "FORM_FIELD_STATUS";
export const FORM_FIELD_NUMERIC = "FORM_FIELD_NUMERIC";
export const FORM_FIELD_ACCOUNT = "FORM_FIELD_ACCOUNT";
export const FORM_FIELD_NESTED_SELECT = "FORM_FIELD_NESTED_SELECT";
export const FORM_FIELD_CHECKBOX_TO_SELECT = "FORM_FIELD_CHECKBOX_TO_SELECT";

export const formFieldTypes = {
    FORM_FIELD_SELECT: "FORM_FIELD_SELECT",
    FORM_FIELD_SUGGEST: "FORM_FIELD_SUGGEST",
    FORM_FIELD_CUSTOM_DATA: "FORM_FIELD_CUSTOM_DATA",
    FORM_FIELD_CHECKBOX: "FORM_FIELD_CHECKBOX",
    FORM_FIELD_SWITCH: "FORM_FIELD_SWITCH",
    FORM_FIELD_TEXT: "FORM_FIELD_TEXT",
    FORM_FIELD_TEXT_REFERENCE: "FORM_FIELD_TEXT_REFERENCE",
    FORM_FIELD_DATE: "FORM_FIELD_DATE",
    FORM_FIELD_MONTH: "FORM_FIELD_MONTH",
    FORM_FIELD_STATUS: "FORM_FIELD_STATUS",
    FORM_FIELD_NUMERIC: "FORM_FIELD_NUMERIC",
    FORM_FIELD_ACCOUNT: "FORM_FIELD_ACCOUNT",
    FORM_FIELD_NESTED_SELECT: "FORM_FIELD_NESTED_SELECT",
    FORM_FIELD_CHECKBOX_TO_SELECT: "FORM_FIELD_CHECKBOX_TO_SELECT",
};
