import {
    FORM_FIELD_ACCOUNT,
    FORM_FIELD_CHECKBOX,
    FORM_FIELD_CUSTOM_DATA,
    FORM_FIELD_DATE,
    FORM_FIELD_NESTED_SELECT,
    FORM_FIELD_NUMERIC,
    FORM_FIELD_SELECT,
    FORM_FIELD_STATUS,
    FORM_FIELD_SUGGEST,
    FORM_FIELD_SWITCH,
    FORM_FIELD_TEXT,
    FORM_FIELD_TEXT_REFERENCE,
    FORM_FIELD_CHECKBOX_TO_SELECT,
} from "../../common/constants/formFieldTypes";

export const OPERATOR_EQUAL = "equal";
export const OPERATOR_NOT_EQUAL = "not_equal";
export const OPERATOR_IN = "in";
export const OPERATOR_NOT_IN = "not_in";
export const OPERATOR_GREATER_THAN = "gt";
export const OPERATOR_GREATER_THAN_OR_EQUAL = "gte";
export const OPERATOR_LOWER_THAN = "lt";
export const OPERATOR_LOWER_THAN_OR_EQUAL = "lte";

export const RANGE_OPERATOR_TO = "to";

export const VALUE_EMPTY = "EMPTY";

/**
 * @param attribute
 * @returns {*}
 */
export const getOperatorListByAttribute = (attribute) => {
    if (!attribute) {
        return [OPERATOR_EQUAL, OPERATOR_NOT_EQUAL];
    }

    let operatorList = [];
    switch (attribute.formFieldType) {
        case FORM_FIELD_SELECT:
        case FORM_FIELD_CHECKBOX:
        case FORM_FIELD_CHECKBOX_TO_SELECT:
        case FORM_FIELD_STATUS:
        case FORM_FIELD_ACCOUNT:
        case FORM_FIELD_NESTED_SELECT:
        case FORM_FIELD_CUSTOM_DATA:
        case FORM_FIELD_SUGGEST:
        case FORM_FIELD_TEXT_REFERENCE:
            operatorList = [OPERATOR_EQUAL, OPERATOR_NOT_EQUAL, OPERATOR_IN, OPERATOR_NOT_IN];
            break;

        case FORM_FIELD_DATE:
            operatorList = [
                OPERATOR_EQUAL,
                OPERATOR_NOT_EQUAL,
                OPERATOR_GREATER_THAN,
                OPERATOR_GREATER_THAN_OR_EQUAL,
                OPERATOR_LOWER_THAN,
                OPERATOR_LOWER_THAN_OR_EQUAL,
            ];
            break;

        case FORM_FIELD_NUMERIC:
            operatorList = [
                OPERATOR_EQUAL,
                OPERATOR_NOT_EQUAL,
                OPERATOR_GREATER_THAN,
                OPERATOR_GREATER_THAN_OR_EQUAL,
                OPERATOR_LOWER_THAN,
                OPERATOR_LOWER_THAN_OR_EQUAL,
            ];
            break;

        case FORM_FIELD_SWITCH:
        case FORM_FIELD_TEXT:
        default:
            operatorList = [OPERATOR_EQUAL, OPERATOR_NOT_EQUAL];
            break;
    }

    return operatorList;
};

export const getRangeOperatorListByAttribute = (attribute) => {
    if (!attribute) {
        return [];
    }

    return [FORM_FIELD_DATE, FORM_FIELD_NUMERIC].includes(attribute.formFieldType)
        ? [RANGE_OPERATOR_TO]
        : [];
};

export const isMultiValueOperator = (operator) => {
    return [OPERATOR_IN, OPERATOR_NOT_IN].indexOf(operator) > -1;
};
