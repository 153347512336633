import { useState } from "react";
import { useMap } from "react-use";
import {
    initialState,
    SearchSelectionActionsContext,
    SearchSelectionStateContext,
} from "./SearchSelection.context";

export function SearchSelectionProvider({ children }) {
    const [state, actions] = useMap(initialState);
    const [displayedData, setDisplayedData] = useState([]);
    const [lastIndex, setLastIndex] = useState(null);

    const handleChangeSelection = (event, index) => {
        if (event.nativeEvent.shiftKey && !!lastIndex) {
            // Multiple sélection
            let min = lastIndex - 1;
            let max = index - 1;
            if (index < lastIndex) {
                min = index - 1;
                max = lastIndex - 1;
            }

            for (let i = min; i <= max; i++) {
                if (typeof state[displayedData[index - 1].rowId] !== "undefined") {
                    actions.remove(displayedData[i].rowId);
                } else if (typeof state[displayedData[i].rowId] === "undefined") {
                    actions.set(displayedData[i].rowId, displayedData[i].datas);
                }
            }
        } else if (typeof state[displayedData[index - 1].rowId] !== "undefined") {
            actions.remove(displayedData[index - 1].rowId);
        } else {
            actions.set(displayedData[index - 1].rowId, displayedData[index - 1].datas);
        }
        setLastIndex(index);
    };

    return (
        <SearchSelectionStateContext.Provider value={state}>
            <SearchSelectionActionsContext.Provider
                value={{
                    ...actions,
                    handleChangeSelection,
                    setDisplayedData,
                }}
            >
                {children}
            </SearchSelectionActionsContext.Provider>
        </SearchSelectionStateContext.Provider>
    );
}
