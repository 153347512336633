const scaSources = {
    id: "sca_sources",
    data: [
        {
            id: "DEFAULT",
            label: "ref.transaction.sca_sources.DEFAULT",
        },
        {
            id: "MERCHANT",
            label: "ref.transaction.sca_sources.MERCHANT",
        },
        {
            id: "MIX_PAYMENT",
            label: "ref.transaction.sca_sources.MIX_PAYMENT",
        },
        {
            id: "SENTINEL",
            label: "ref.transaction.sca_sources.SENTINEL",
        },
        {
            id: "SOFT_DECLINE",
            label: "ref.transaction.sca_sources.SOFT_DECLINE",
        },
        {
            id: "COF",
            label: "ref.transaction.sca_sources.COF",
        },
        {
            id: "ACQUIRER_TRA",
            label: "ref.transaction.sca_sources.ACQUIRER_TRA",
        },
    ],
    translate: true,
};

export default scaSources;
