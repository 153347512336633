import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { ModuleContext } from "./module-context";
import { useModuleConfig } from "../config";
import { useSelector } from "react-redux";

/**
 * /!\ WARNING /!\
 * As the ModuleProvider is used by Console Master,
 * you should not introduce breaking changes !
 *
 * If updates are required, please tag old functions as deprecated.
 *
 * Provide access to master config (menu, modules, routes, ...)
 *
 * module config should have this format:
 * {{
 *     transaction: {
            attributes,
            defaultFormFields,
            id: 'transaction',
            icon: 'hi_transaction',
            label: 'app.menu.transaction.label',
            path: 'transactions',
            route: '[prefix]/transactions',
            features: [ 'qsearch', 'form', 'export' ],
 *     },
 *     widget: { ... }
 * }}
 *
 */
export function ModuleProvider({ children, module = null }) {
    const config = useModuleConfig(module);

    if (module !== null && config === null) {
        console.warn(`ModuleProvider : no config found for module ${module}`);
    }

    const coreReferences = useSelector((state) => state.ref);

    const value = useMemo(() => {
        return {
            ...config,
            features: config?.features || [],
            references: {
                ...coreReferences,
                ...(config?.references || {}),
            },
        };
    }, [config, coreReferences]);

    return (
        <ModuleContext.Provider value={value}>
            {React.Children.only(children)}
        </ModuleContext.Provider>
    );
}

ModuleProvider.propTypes = {
    children: PropTypes.element.isRequired,
    module: PropTypes.string,
};
