const references = {
    bank_information_status: {
        id: "bank_information_status",
        data: [
            {
                id: "FUNCTIONAL_ERROR",
                label: "ref.mkp_vendors.bank_information_status.FUNCTIONAL_ERROR",
                color: "error",
            },
            {
                id: "INITIALIZED",
                label: "ref.mkp_vendors.bank_information_status.INITIALIZED",
                color: "primary",
            },
            {
                id: "NOT_INITIALIZED",
                label: "ref.mkp_vendors.bank_information_status.NOT_INITIALIZED",
                color: "neutral",
            },
            {
                id: "REFUSED",
                label: "ref.mkp_vendors.bank_information_status.REFUSED",
                color: "error",
            },
            {
                id: "TECHNICAL_ERROR",
                label: "ref.mkp_vendors.bank_information_status.TECHNICAL_ERROR",
                color: "error",
            },
            {
                id: "VALIDATED",
                label: "ref.mkp_vendors.bank_information_status.VALIDATED",
                color: "success",
            },
            {
                id: "VALIDATION_IN_PROGRESS",
                label: "ref.mkp_vendors.bank_information_status.VALIDATION_IN_PROGRESS",
                color: "secondary",
            },
        ],
        translate: true,
    },
    currencies: {
        id: "currencies",
        data: [
            {
                id: "EUR",
                label: "ref.mkp_vendors.currencies.EUR",
            },
            {
                id: "USD",
                label: "ref.mkp_vendors.currencies.USD",
            },
            {
                id: "GBP",
                label: "ref.mkp_vendors.currencies.GBP",
            },
            {
                id: "CAD",
                label: "ref.mkp_vendors.currencies.CAD",
            },
            {
                id: "RUB",
                label: "ref.mkp_vendors.currencies.RUB",
            },
        ],
        translate: true,
    },
    gender: {
        id: "gender",
        data: [
            {
                id: "Unknown",
                label: "ref.mkp_vendors.gender.Unknown",
            },
            {
                id: "Mr",
                label: "ref.mkp_vendors.gender.Mr",
            },
            {
                id: "Mrs",
                label: "ref.mkp_vendors.gender.Mrs",
            },
            {
                id: "Miss",
                label: "ref.mkp_vendors.gender.Miss",
            },
        ],
        translate: true,
    },
    hipay_status: {
        id: "hipay_status",
        data: [
            {
                id: "FUNCTIONAL_ERROR_ON_CREATE",
                label: "ref.mkp_vendors.hipay_status.FUNCTIONAL_ERROR_ON_CREATE",
                color: "error",
            },
            {
                id: "FUNCTIONAL_ERROR_ON_UPDATE",
                label: "ref.mkp_vendors.hipay_status.FUNCTIONAL_ERROR_ON_UPDATE",
                color: "error",
            },
            {
                id: "INITIALIZED",
                label: "ref.mkp_vendors.hipay_status.INITIALIZED",
                color: "primary",
            },
            {
                id: "PENDING_UPDATE",
                label: "ref.mkp_vendors.hipay_status.PENDING_UPDATE",
                color: "secondary",
            },
            {
                id: "SYNCHRONIZED",
                label: "ref.mkp_vendors.hipay_status.SYNCHRONIZED",
                color: "success",
            },
            {
                id: "TECHNICAL_ERROR_ON_CREATE",
                label: "ref.mkp_vendors.hipay_status.TECHNICAL_ERROR_ON_CREATE",
                color: "error",
            },
            {
                id: "TECHNICAL_ERROR_ON_UPDATE",
                label: "ref.mkp_vendors.hipay_status.TECHNICAL_ERROR_ON_UPDATE",
                color: "error",
            },
            {
                id: "HIPAY_DISABLED",
                label: "ref.mkp_vendors.hipay_status.HIPAY_DISABLED",
                color: "error",
            },
        ],
        translate: true,
    },
    vendor_type: {
        id: "vendor_type",
        data: [
            {
                id: "COMMISSION_ACCOUNT",
                label: "ref.mkp_vendors.vendor_type.COMMISSION_ACCOUNT",
            },
            {
                id: "CORPORATION",
                label: "ref.mkp_vendors.vendor_type.CORPORATION",
            },
            {
                id: "PERSON",
                label: "ref.mkp_vendors.vendor_type.PERSON",
            },
            {
                id: "ASSOCIATION",
                label: "ref.mkp_vendors.vendor_type.ASSOCIATION",
            },
            {
                id: "CORPORATION_LICENSE",
                label: "ref.mkp_vendors.vendor_type.CORPORATION_LICENSE",
            },
        ],
        translate: true,
    },
};

export default references;
