import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFields = {
    mkp_vendor_name: null,
    status: null,
    payment_blocked: null,
    enabled: null,
    identified: null,
    bank_information_status: null,
    email: null,
    date_updated: null,
};

export const availableFields = [
    attributes.mkp_vendor_name.id,
    attributes.status.id,
    attributes.payment_blocked.id,
    attributes.enabled.id,
    attributes.identified.id,
    attributes.bank_information_status.id,
    attributes.email.id,
    attributes.vendor_type.id,
    attributes.date_updated.id,
    attributes.firstname.id,
    attributes.lastname.id,
    attributes.city.id,
    attributes.company_name.id,
    attributes.country.id,
    attributes.currency.id,
    attributes.hipay_account_id.id,
    attributes.mkp_vendor_id.id,
];
