// User service reducer
export const userReducer = (state, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.user,
            };
        case "SET_USER_INFO":
            return {
                ...state,
                userInfo: action.userInfo,
            };
        case "RESET":
            return {
                user: null,
                userInfo: null,
            };
        default:
            return state;
    }
};
