import { SetStateAction, Dispatch } from "react";
import HiDialog from "@hipay/hipay-material-ui/HiDialog";
import Form from "./Form/Form";
import useStyles from "./styleSheet";

import { Business, User, Type } from "@console/types";

export interface UpsertVerificationProps {
    title: string;
    open: boolean;
    business: Business;
    loading: boolean;
    error: boolean;
    hipayUsers: User[];
    onboardingTypes: Type[];
    kycObjects: Type[];
    state: {
        type?: string;
        setType: Dispatch<SetStateAction<string | undefined>>;
        kycManagerId?: string;
        setKycManagerId: Dispatch<SetStateAction<string | undefined>>;
        kycObject?: string;
        setKycObject: Dispatch<SetStateAction<string | undefined>>;
        goLiveExpectedDate?: Date | undefined;
        setGoLiveExpectedDate: Dispatch<SetStateAction<Date | undefined>>;
    };
    handleSubmit: () => void;
    handleClose: () => void;
}

export const UpsertVerification = (props: UpsertVerificationProps) => {
    const {
        title,
        open,
        business,
        loading,
        error,
        hipayUsers,
        onboardingTypes,
        kycObjects,
        state,
        handleClose,
        handleSubmit,
    } = props;

    const classes = useStyles();

    const Content = (
        <Form
            business={business}
            loading={loading}
            error={error}
            hipayUsers={hipayUsers}
            onboardingTypes={onboardingTypes}
            kycObjects={kycObjects}
            state={state}
            handleSubmit={handleSubmit}
        />
    );

    return (
        <HiDialog
            classes={{ paper: classes.paper }}
            id="update-verification"
            onClose={handleClose}
            open={open}
            title={title}
            content={Content}
        ></HiDialog>
    );
};

export default UpsertVerification;
