import { shallowEqual } from "react-redux";

/**
 * Compare 2 arrays and return true if arrays are equal by values
 * order could be different
 */
export function arraysAreEqualByValue(a, b) {
    return a.length === b.length && a.every((i) => b.includes(i));
}

/**
 * Regarde si le tableau array ne comporte qu'un seul élément
 * et s'il est égal à value
 * S'il s'agit d'un tableau d'objet, on regarde la valeur du parameter
 * @param array
 * @param value
 * @param parameter
 * @returns {boolean}
 */
export const hasOneValue = (array, value, parameter = undefined) => {
    if (parameter) {
        return array.length === 1 && array[0][parameter] === value;
    }
    return array.length === 1 && array[0] === value;
};

export const hasSameValues = (a, b) => {
    if (a.length !== b.length) {
        return false;
    }
    return a.sort().every((value, index) => value === b.sort()[index]);
};

export function removeDuplicatedArrayElement(list) {
    return list.reduce((memo, item) => {
        return memo.length > 0 && memo.includes(item) ? memo : [...memo, item];
    }, []);
}

/**
 * Make a shallowEqual on a list
 * Use it as equalityFn of react-redux useSelector hook
 * @param listA
 * @param listB
 * @returns {boolean}
 */
export const shallowEqualList = (listA, listB) => {
    if (listA.length !== listB.length) {
        return false;
    }

    return listA.every((objA, index) => {
        const objB = listB[index];
        return shallowEqual(objA, objB);
    });
};
