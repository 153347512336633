import React from "react";
import PropTypes from "prop-types";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { withStylesAndTranslate } from "../../../../hoc";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import classNames from "classnames";

export const stylesCellButton = {
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
    },
    icon: { margin: 2 },
    label: { fontSize: 14 },
    wrapper: { height: 40 },
};
/**
 * This cell provide a button with an icon and a label
 */
class CellButtonClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.object,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Couleur de l'icône
         */
        color: PropTypes.string,
        /**
         * Icon name [from material-design-icons](https://materialdesignicons.com/)
         * used by HiIconBuilder
         */
        icon: PropTypes.string,
        /**
         * Label
         */
        label: PropTypes.string,
    };

    static defaultProps = {
        sizeIcon: "22",
        color: "neutral",
        view: "m",
    };

    render() {
        const { classes, color, icon, label, p, view, sizeIcon, onClick, className } = this.props;

        return (
            <div className={classNames(classes.root, className)} onClick={onClick}>
                <HiButton color={color} className={classes.wrapper}>
                    <HiIcon className={classes.icon} color={color} size={sizeIcon}>
                        {icon}
                    </HiIcon>
                    {view === "l" && <div className={classes.label}>{p.t(label)}</div>}
                </HiButton>
            </div>
        );
    }
}

export const CellButton = withStylesAndTranslate(stylesCellButton)(CellButtonClass);
