const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: process.env.NX_OKTA_ISSUER,
    clientId: process.env.NX_OKTA_WIDGET_CLIENT_ID,
    redirectUri:
        process.env.NX_CONSOLE_API_URL +
        "/sso/tokens" +
        (process.env.NX_IS_INTERNAL === "true" ? "/internal" : ""),
    postLogoutRedirectUri: window.location.origin + "/login",
    tokenManager: { autoRenew: false },
};

const oktaSignInConfig = {
    baseUrl: process.env.NX_OKTA_BASE_URL,
    clientId: process.env.NX_OKTA_WIDGET_CLIENT_ID,
    redirectUri: window.location.origin + "/login",
    features: {
        registration: false,
        rememberMe: false,
        selfServiceUnlock: true,
        autoPush: true,
        webauthn: true,
    },
    authParams: {
        // If your app is configured to use the Implicit flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        // pkce: false
        issuer: process.env.NX_OKTA_ISSUER,
        scopes: ["openid", "console", "email", "offline_access"],
    },
    useClassicEngine: true,
    // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
};

const oktaSignInConfigInternal = {
    ...oktaSignInConfig,
    idps: [
        {
            type: "GOOGLE",
            id: process.env.NX_OKTA_GOOGLE_CLIENT_ID,
        },
    ],
    idpDisplay: "Primary",
};

export { oktaAuthConfig, oktaSignInConfig, oktaSignInConfigInternal };
