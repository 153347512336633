import { useState, SetStateAction, Dispatch } from "react";
import * as React from "react";
import RocketLaunch from "mdi-material-ui/Rocket";
// import PlusBoxMultiple from "mdi-material-ui/PlusBoxMultiple";
import EyeSettings from "mdi-material-ui/EyeSettings";
// import PencilBoxMultiple from "mdi-material-ui/PencilBoxMultiple";
import PlusCircle from "mdi-material-ui/PlusCircle";

import HiSelectField from "@hipay/hipay-material-ui/HiSelect/HiSelectField";
import { useP } from "@console/core";
import { HiButton } from "@hipay/design-system/components";

import useCommonStyles from "../../styleSheet";
import DatePicker from "../../DatePicker/DatePicker";
import DialogFormContent from "../../DialogFormContent/DialogFormContent";
import DialogFormActions from "../../DialogFormActions/DialogFormActions";
import useStyles from "./styleSheet";

import { Business, User, Type } from "@console/types";

export interface FormProps {
    business: Business;
    loading: boolean;
    error: boolean;
    hipayUsers: User[];
    onboardingTypes: Type[];
    kycObjects: Type[];
    state: {
        type?: string;
        setType: Dispatch<SetStateAction<string | undefined>>;
        kycManagerId?: string;
        setKycManagerId: Dispatch<SetStateAction<string | undefined>>;
        kycObject?: string;
        setKycObject: Dispatch<SetStateAction<string | undefined>>;
        goLiveExpectedDate?: Date | undefined;
        setGoLiveExpectedDate: Dispatch<SetStateAction<Date | undefined>>;
    };
    handleSubmit: () => void;
}
const Form = (props: FormProps) => {
    const {
        loading,
        error,
        hipayUsers,
        onboardingTypes,
        kycObjects,
        state: {
            type,
            setType,
            kycManagerId,
            setKycManagerId,
            kycObject,
            setKycObject,
            goLiveExpectedDate,
            setGoLiveExpectedDate,
        },
        handleSubmit,
    } = props;

    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const p = useP();

    const [height, setHeight] = useState<number>();

    const getVerificationType = (_type: string) => {
        let icon;

        switch (_type) {
            case "new_onboarding":
                icon = <RocketLaunch className={classes.verificationTypeIcon} />;
                break;
            case "upsell":
                icon = <PlusCircle className={classes.verificationTypeIcon} />;
                break;
            /* case "update":
                icon = <PencilBoxMultiple className={classes.verificationTypeIcon} />;
                break;
            case "activation_subscribed_feature":
                icon = <PlusBoxMultiple className={classes.verificationTypeIcon} />;
                break;*/
            case "periodic_risk_review":
                icon = <EyeSettings className={classes.verificationTypeIcon} />;
                break;
            default:
                return null;
        }

        return {
            label: (
                <div>
                    {icon}
                    {p.t(`modules.onboarding.notice.verification.type.${_type}`)}
                </div>
            ),
        };
    };

    const getKycObject = (_kycObject: string) => {
        return {
            label: (
                <div>{p.t(`modules.onboarding.notice.verification.kyc_object.${_kycObject}`)}</div>
            ),
        };
    };

    const selectTranslations = {
        all: p.t("modules.onboarding.select.all"),
        no_result_match: p.t("modules.onboarding.select.no_result_match"),
        search: p.t("modules.onboarding.select.search"),
        n_items_selected: p.t("modules.onboarding.select.n_items_selected"),
        one_item_selected: p.t("modules.onboarding.select.one_item_selected"),
        n_children: p.t("modules.onboarding.select.n_children"),
        one_child: p.t("modules.onboarding.select.one_child"),
    };

    const kycTypeOptions = ["upsell", "periodic_risk_review"];

    return (
        <React.Fragment>
            <DialogFormContent>
                <div className={classes.root} style={{ height }}>
                    <HiSelectField
                        id={"verification-type"}
                        className={commonClasses.formField}
                        label={p.t("modules.onboarding.notice.verification.type.label")}
                        value={type}
                        loading={loading}
                        error={error}
                        onChange={(_event: React.ChangeEvent, value: string) => setType(value)}
                        onReset={() => setType(undefined)}
                        options={
                            onboardingTypes
                                ?.filter((obType: Type) =>
                                    kycTypeOptions.includes(obType.translationCode)
                                )
                                ?.map((obType: Type) => {
                                    return {
                                        ...obType,
                                        ...getVerificationType(obType.translationCode),
                                    };
                                }) ?? []
                        }
                        hiSelectableListProps={{ hideCheckbox: true }}
                        staticPosition={true}
                        translations={selectTranslations}
                    />
                    <HiSelectField
                        id={"verification-object"}
                        className={commonClasses.formField}
                        label={p.t("modules.onboarding.notice.verification.kyc_object.label")}
                        value={kycObject}
                        loading={loading}
                        error={error}
                        onChange={(_event: React.ChangeEvent, value: string) => setKycObject(value)}
                        onReset={() => setKycObject(undefined)}
                        options={
                            kycObjects?.map((obContext: Type) => ({
                                ...obContext,
                                ...getKycObject(obContext.translationCode),
                            })) ?? []
                        }
                        hiSelectableListProps={{ hideCheckbox: true }}
                        staticPosition={true}
                        translations={selectTranslations}
                    />

                    <HiSelectField
                        id={"verification-kycManager"}
                        className={commonClasses.formField}
                        label={p.t("modules.onboarding.notice.verification.kycManager")}
                        value={kycManagerId}
                        searchable
                        loading={loading}
                        error={error}
                        staticPosition
                        options={
                            hipayUsers
                                ?.filter(
                                    (user: User) =>
                                        (user.firstname !== "" || user.lastname !== "") &&
                                        user.activated === true
                                )
                                ?.map((user: User) => ({
                                    id: user?.id,
                                    label: user?.lastname?.toUpperCase() + " " + user?.firstname,
                                })) ?? []
                        }
                        onChange={(_event: React.ChangeEvent, value: string) =>
                            setKycManagerId(value)
                        }
                        onReset={() => setKycManagerId(undefined)}
                        hiSelectableListProps={{
                            hideCheckbox: true,
                        }}
                        translations={selectTranslations}
                    />
                    <DatePicker
                        id={"onboarding-liveDate"}
                        label={p.t("modules.onboarding.notice.verification.go_live_date")}
                        value={goLiveExpectedDate}
                        disablePastDays={true}
                        onUpdate={(value: any) => setGoLiveExpectedDate(value)}
                        onBlur={() => setHeight(undefined)}
                    />
                </div>
            </DialogFormContent>
            <DialogFormActions>
                <HiButton
                    className={classes.button}
                    variant={"contained"}
                    color={"primary"}
                    disabled={!type}
                    onClick={handleSubmit}
                >
                    {p.t("modules.onboarding.notice.verification.save")}
                </HiButton>
            </DialogFormActions>
        </React.Fragment>
    );
};

export default Form;
