import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import HiCheckbox from "@hipay/hipay-material-ui/HiCheckbox";
import { translate } from "../../../../services/i18n/translate";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";

export const stylesCheckboxField = (theme) => ({
    formControlLabel: {
        marginLeft: 0,
        height: 40,
    },
    labelWithIcon: {
        display: "flex",
        alignItems: "center",
    },
});

class CheckboxField extends React.Component {
    static propTypes = {
        formField: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        options: PropTypes.array,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    };

    render() {
        const { classes, value, options, formField, onChange, name, p } = this.props;

        let _value = value ? (Array.isArray(value) ? value : [value]) : [];
        // Sort by label if activate
        if (formField.sort) {
            options.sort(function (a, b) {
                if (a.label && b.label) {
                    if (a.label.toLowerCase() > b.label.toLowerCase()) {
                        return 1;
                    }
                    if (a.label < b.label) {
                        return -1;
                    }
                }
                return 0;
            });
        }

        let checkboxOptions = [...options];
        if (formField.hasEmptyOption !== false) {
            checkboxOptions.unshift({
                id: "EMPTY",
                label: p.t("common.search.empty"),
            });
        }

        return (
            <HiFormControl label={formField.label} helperText={formField.helperText} helperIcon>
                <FormGroup>
                    {checkboxOptions.map((option) => {
                        let label = option.label;

                        if (option.icon) {
                            const additionalStyle = option.style ? option.style : {};
                            let itemIcon;
                            const iconProps = {
                                style: {
                                    color: "neutral",
                                    marginRight: 5,
                                    ...additionalStyle,
                                },
                            };
                            if (typeof option.icon === "string") {
                                itemIcon = <HiIcon icon={option.icon} {...iconProps} />;
                            } else if (typeof option.icon === "object") {
                                itemIcon = { ...option.icon };
                                itemIcon.props = {
                                    ...itemIcon.props,
                                    ...iconProps,
                                };
                            }

                            label = (
                                <div className={classes.labelWithIcon}>
                                    {itemIcon} {option.label}
                                </div>
                            );
                        }

                        return (
                            <FormControlLabel
                                key={option.id}
                                classes={{ root: classes.formControlLabel }}
                                control={
                                    <HiCheckbox
                                        checked={_value.includes(option.id)}
                                        onChange={onChange(name, option.id)}
                                        value={option.id}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
                </FormGroup>
            </HiFormControl>
        );
    }
}

export default withStyles(stylesCheckboxField)(translate(CheckboxField));
