import styled from "styled-components";

export const CarouselContainer = styled.div`
    display: flex;
    transition: ${(props) => (props.sliding ? "none" : "transform .2s linear")};
    transform: ${(props) => {
        if (!props.sliding) {
            return `translateX(-${props.slotWidth + 2 * props.spacing}px)`;
        }
        if (props.direction === "prev") {
            return `translateX(-${2 * (props.slotWidth + 2 * props.spacing)}px)`;
        }
        return "translateX(0px)";
    }};
`;
