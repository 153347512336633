const authenticationIndicator = {
    id: "authentication_indicator",
    data: [
        {
            id: "0",
            label: "ref.transaction.authentication_indicator.value_0",
            smallLabel: "0",
        },
        {
            id: "1",
            label: "ref.transaction.authentication_indicator.value_1",
            smallLabel: "1",
        },
        {
            id: "2",
            label: "ref.transaction.authentication_indicator.value_2",
            smallLabel: "2",
        },
    ],
    translate: true,
};

export default authenticationIndicator;
