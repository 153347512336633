import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFormFields = {
    financial_operation_type: null,
    financial_operation_date: null,
    financial_operation_mode: null,
    amount_incl_tax: null,
};

const formFields = [
    attributes.account.id,
    attributes.acquirer_registration_id.id,
    attributes.amount_excl_tax.id,
    attributes.amount_incl_tax.id,
    attributes.authorization_date.id,
    attributes.business_name.id,
    attributes.capture_date.id,
    attributes.custom_data.id,
    attributes.customer_id.id,
    attributes.collecting_date.id,
    attributes.financial_operation_date.id,
    attributes.financial_operation_description.id,
    attributes.financial_operation_type.id,
    attributes.invoice_number.id,
    attributes.invoice_status.id,
    attributes.ip_country.id,
    attributes.financial_operation_currency.id,
    attributes.transaction_currency.id,
    attributes.payment_means.id,
    attributes.payment_method.id,
    attributes.financial_operation_mode.id,
    attributes.reserve_release_date.id,
    attributes.settlement_date.id,
    attributes.settlement_id.id,
    attributes.amount_tax.id,
    attributes.transaction_id.id,
    attributes.reserve_released.id,
    attributes.mch_orderid.id,
    attributes.product_name.id,
    attributes.mch_operation_id.id,
    attributes.settlement_original_amount.id,
    attributes.settlement_original_currency.id,
    attributes.settlement_amount.id,
    attributes.settlement_currency.id,
    attributes.original_settlement_id.id,
    attributes.creditor_iban.id,
];

if (
    undefined !== process.env.NX_IS_INTERNAL &&
    true === JSON.parse(process.env.NX_IS_INTERNAL.toLowerCase())
) {
    formFields.push(
        attributes.acquirer_name.id,
        attributes.creditor_bic.id,
        attributes.debitor_iban.id,
        attributes.debitor_bic.id,
        attributes.settlement_sepa.id,
        attributes.settlement_status.id,
        attributes.merchant_bank_country.id,
        attributes.interchange_amount_internal.id,
        attributes.schemefee_amount_internal.id
    );
} else {
    formFields.push(attributes.schemefee_amount.id, attributes.interchange_amount.id);
}

export const availableFormFields = formFields;
