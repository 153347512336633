import React from "react";
import Icon from "@mui/material/Icon";

const SameDayRefundIcon = (props) => {
    return (
        <Icon
            style={{
                ...props.style,
                fontSize: props.size || 24,
            }}
            className={props.className}
        >
            <svg data-name="same-day-refund-icon" viewBox="0 0 29.38 29.38">
                <path
                    d="M23.69 7.71v14a2 2 0 01-2 2h-14a1.9 1.9 0 01-1.43-.59 1.94 1.94 0 01-.59-1.43v-14a2 2 0 012-2h14a1.94 1.94 0 011.43.59 1.9 1.9 0 01.59 1.43zm-14 7l5 5v-3h4v-4h-4v-3z"
                    fill="#FFFFFF"
                />
                <path d="M14.69 9.68l-5.01 5.01 5.01 5.02v-3h3.99v-4.03h-3.99v-3z" fill="#00ADE9" />
                <path
                    d="M14.69 0a14.69 14.69 0 1014.69 14.69A14.69 14.69 0 0014.69 0zm9 21.67a2 2 0 01-2 2h-14a1.9 1.9 0 01-1.43-.59 1.94 1.94 0 01-.59-1.43v-14a2 2 0 012-2h14a1.94 1.94 0 011.43.59 1.9 1.9 0 01.59 1.43z"
                    fill="#00ADE9"
                />
            </svg>
        </Icon>
    );
};

export default SameDayRefundIcon;
