import static_datas from "./static_datas";
import dynamic_datas from "./dynamic_datas";

export const references = {
    ...static_datas,
    ...dynamic_datas,
};

export * from "./dynamic_datas";
export * from "./static_datas";
export default references;
