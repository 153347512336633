import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFields = {
    external_reference_id: null,
    type: null,
    mkp_vendor_name: null,
    hipay_account_id: null,
    mkp_vendor_id: null,
    transfer_id: null,
    withdrawal_id: null,
    date_updated: null,
    status: null,
};

export const availableFields = [
    attributes.external_reference_id.id,
    attributes.type.id,
    attributes.mkp_vendor_name.id,
    attributes.hipay_account_id.id,
    attributes.mkp_vendor_id.id,
    attributes.transfer_id.id,
    attributes.withdrawal_id.id,
    attributes.date_updated.id,
    attributes.status.id,
];
