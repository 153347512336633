import { RSAA } from "redux-api-middleware";
import {
    FETCH_REFERENCES_REQUEST,
    FETCH_REFERENCES_SUCCESS,
    FETCH_REFERENCES_FAILURE,
    FETCH_EMPTY_RELATIVE_REFERENCES,
    ADD_REFERENCES_DATA,
} from "./actionTypes";
import { buildReferenceEndpoint } from "./utils";

/**
 * Fetch References
 *
 * @param references
 * @param name
 * @param args
 * @param force
 * @param baseUrl
 * @param dispatch403
 * @returns {{}}
 */
export const fetchReferences = (
    references,
    name,
    args = {},
    force = false,
    baseUrl = null,
    dispatch403 = true
) => {
    const reference = references[name];

    const endpoint = buildReferenceEndpoint(reference, args);

    if (!endpoint) {
        return {
            type: FETCH_REFERENCES_FAILURE,
            meta: {
                id: reference.id,
                endpoint: endpoint,
            },
        };
    }

    const method =
        reference && reference.args && reference.args.method ? reference.args.method : "GET";
    const body =
        reference && reference.args && reference.args.body ? reference.args.body : undefined;

    return {
        [RSAA]: {
            endpoint: endpoint,
            method: method,
            body: body,
            bailout: (store) =>
                !!store.ref[reference.id] &&
                !force &&
                store.ref[reference.id].hasOwnProperty(endpoint),
            types: [
                {
                    type: FETCH_REFERENCES_REQUEST,
                    meta: {
                        id: reference.id,
                        endpoint: endpoint,
                    },
                },
                {
                    type: FETCH_REFERENCES_SUCCESS,
                    meta: {
                        id: reference.id,
                        reference: reference,
                        endpoint: endpoint,
                        p: args.p,
                    },
                },
                {
                    type: FETCH_REFERENCES_FAILURE,
                    meta: {
                        id: reference.id,
                        endpoint: endpoint,
                        dispatch403,
                    },
                },
            ],
        },
    };
};

export const fetchEmptyReferences = (references, name) => {
    const reference = references[name];
    return {
        type: FETCH_EMPTY_RELATIVE_REFERENCES,
        meta: { id: reference.id },
    };
};

export const addReferenceData = (references, name, dataToAdd, args = {}) => {
    const reference = references[name];
    const endpoint = buildReferenceEndpoint(reference, args);

    return {
        type: ADD_REFERENCES_DATA,
        meta: {
            id: reference.id,
            endpoint,
            dataToAdd,
        },
    };
};
