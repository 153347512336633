import { useInheritContext } from "../../services/ContextForwarder";
import { ConfigProvider } from "./config";
import { withConfig } from "./withConfig";
import { ConfigContext } from "./config-context";

const useMenu = () => useInheritContext("config", ConfigContext).menu;

const useModuleConfig = (module) => {
    const { getConfigByModule } = useInheritContext("config", ConfigContext);

    if (module) {
        return getConfigByModule(module);
    }
    return null;
};

const useModuleList = () => {
    const {
        getModuleList,
        getModuleListByFeature,
        getModuleConfigList,
        getModuleConfigListByFeature,
        getModuleConfigListByPath,
    } = useInheritContext("config", ConfigContext);

    return {
        getModuleList,
        getModuleListByFeature,
        getModuleConfigList,
        getModuleConfigListByFeature,
        getModuleConfigListByPath,
    };
};

export { ConfigProvider, useMenu, useModuleConfig, useModuleList, withConfig };
