/* Load moment locales
 */
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pt";
import "moment/locale/pt-br";

/**
 * Available locales
 * Json files should exist
 *
 * @type {string[]}
 */
export const locales = {
    fr_FR: {
        id: "fr_FR",
        label: "FR",
    },
    en_US: {
        id: "en_US",
        label: "EN",
    },
    de_DE: {
        id: "de_DE",
        label: "DE",
    },
    es_ES: {
        id: "es_ES",
        label: "ES",
    },
    it_IT: {
        id: "it_IT",
        label: "IT",
    },
    nl_NL: {
        id: "nl_NL",
        label: "NL",
    },
    pt_PT: {
        id: "pt_PT",
        label: "PT",
    },
    pt_BR: {
        id: "pt_BR",
        label: "PTB",
    },
};
