import Barcode from "mdi-material-ui/Barcode";
import BookMinus from "mdi-material-ui/BookMinus";
import ContentPaste from "mdi-material-ui/ContentPaste";
import CreditCardOffOutline from "mdi-material-ui/CreditCardOffOutline";
import CreditCardOutline from "mdi-material-ui/CreditCardOutline";
import CreditCardPlusOutline from "mdi-material-ui/CreditCardPlusOutline";
import Wallet from "mdi-material-ui/Wallet";
import WalletMembership from "mdi-material-ui/WalletMembership";

export default {
    id: "payment_methods",
    data: [
        {
            id: "credit-card",
            label: "ref.transaction.payment_methods.credit-card",
            icon: <CreditCardOutline />,
        },
        {
            id: "credit-consumption",
            label: "ref.transaction.payment_methods.credit-consumption",
            icon: <BookMinus />,
        },
        {
            id: "debit-card",
            label: "ref.transaction.payment_methods.debit-card",
            icon: <CreditCardPlusOutline />,
        },
        {
            id: "ewallet",
            label: "ref.transaction.payment_methods.ewallet",
            icon: <Wallet />,
        },
        {
            id: "open-invoice",
            label: "ref.transaction.payment_methods.open-invoice",
            icon: <ContentPaste />,
        },
        {
            id: "prepaid-card",
            label: "ref.transaction.payment_methods.prepaid-card",
            icon: <WalletMembership />,
        },
        {
            id: "realtime-banking",
            label: "ref.transaction.payment_methods.realtime-banking",
            icon: <CreditCardOffOutline />,
        },
        {
            id: "payment-reference",
            label: "ref.transaction.payment_methods.payment-reference",
            icon: <Barcode />,
        },
    ],
    translate: true,
};
