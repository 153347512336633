import React from "react";
import Icon from "@mui/material/Icon";

const RecurringPaymentIcon = (props) => {
    return (
        <Icon
            style={{
                ...props.style,
                fontSize: props.size || 24,
            }}
            className={props.className}
        >
            <svg data-name="recurring-payment-icon" viewBox="0 0 29.38 29.38">
                <circle fill="#00ADE9" cx="14.69" cy="14.61" r="14.69" />
                <path
                    fill={"#fff"}
                    d="M16.58,5.21V7.06H7.16v9.42H5.31V7.06A1.81,1.81,0,0,1,7.16,5.21ZM20.32,9v1.89H11v9.37H9.05V10.85a1.88,1.88,0,0,1,.53-1.37A1.85,1.85,0,0,1,11,9Z"
                />
                <path
                    fill={"#fff"}
                    d="M24.07,22.58a1.36,1.36,0,0,1-.4,1,1.29,1.29,0,0,1-1,.42H14.22a1.34,1.34,0,0,1-1-.42,1.41,1.41,0,0,1-.4-1V14.12a1.35,1.35,0,0,1,.4-1,1.36,1.36,0,0,1,1-.4h8.46a1.37,1.37,0,0,1,1.39,1.39Zm-6.34-1.39,4.95-5-1-1-4,3.93L15.55,17l-1,1Z"
                />
            </svg>
        </Icon>
    );
};

export default RecurringPaymentIcon;
