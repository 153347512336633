export default {
    id: "transaction_currencies",
    endpoint: `${process.env.NX_FINANCIAL_OPERATION_API_URL}/transaction.currency/aggregated`,
    withSelectedAccounts: true,
    translate: true,
    reduceFunc: (previous, current) =>
        current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"],
                      label: "ref.financial_report.currencies." + current["id"],
                  },
              ]
            : previous,
};
