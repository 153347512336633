import ContentLoader from "react-content-loader";

export const LoadingFavoritesBar = () => {
    const width = window.innerWidth;
    let height = 40;

    return (
        <div
            style={{
                left: 0,
                height: height,
                width: width,
                backgroundColor: "#fff",
            }}
        >
            <ContentLoader
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                height={height}
                width={width}
            >
                <rect x={24} y={8} rx="12" ry="12" width={223} height={24} />
                <rect x={251} y={8} rx="12" ry="12" width={207} height={24} />
                <rect x={462} y={8} rx="12" ry="12" width={128} height={24} />
            </ContentLoader>
        </div>
    );
};
