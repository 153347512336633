import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useP } from "../../../services/i18n";
import SelectionMenu from "./SelectionMenu";

export function SelectionMenuButton(props) {
    const { className, ...selectionMenuProps } = props;

    const p = useP();

    const [anchorEl, setAnchorEl] = useState();

    const handleIconButtonClick = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleSelectionMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    return (
        <>
            <IconButton
                id="selection-btn"
                title={p.t("common.search.buttons.selection")}
                onClick={handleIconButtonClick}
                className={className}
                size="large"
                color="info"
            >
                <CheckBoxIcon />
            </IconButton>
            <SelectionMenu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={handleSelectionMenuClose}
                {...selectionMenuProps}
            />
        </>
    );
}

SelectionMenuButton.propTypes = { className: PropTypes.string };
