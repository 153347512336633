const itemCategories = {
    id: "item_categories",
    data: [
        {
            id: "1",
            label: "ref.transaction.item_categories.value_1",
        },
        {
            id: "2",
            label: "ref.transaction.item_categories.value_2",
        },
        {
            id: "3",
            label: "ref.transaction.item_categories.value_3",
        },
        {
            id: "4",
            label: "ref.transaction.item_categories.value_4",
        },
        {
            id: "5",
            label: "ref.transaction.item_categories.value_5",
        },
        {
            id: "6",
            label: "ref.transaction.item_categories.value_6",
        },
        {
            id: "7",
            label: "ref.transaction.item_categories.value_7",
        },
        {
            id: "8",
            label: "ref.transaction.item_categories.value_8",
        },
        {
            id: "9",
            label: "ref.transaction.item_categories.value_9",
        },
        {
            id: "10",
            label: "ref.transaction.item_categories.value_10",
        },
        {
            id: "11",
            label: "ref.transaction.item_categories.value_11",
        },
        {
            id: "12",
            label: "ref.transaction.item_categories.value_12",
        },
        {
            id: "13",
            label: "ref.transaction.item_categories.value_13",
        },
        {
            id: "14",
            label: "ref.transaction.item_categories.value_14",
        },
        {
            id: "15",
            label: "ref.transaction.item_categories.value_15",
        },
        {
            id: "16",
            label: "ref.transaction.item_categories.value_16",
        },
        {
            id: "17",
            label: "ref.transaction.item_categories.value_17",
        },
        {
            id: "18",
            label: "ref.transaction.item_categories.value_18",
        },
        {
            id: "19",
            label: "ref.transaction.item_categories.value_19",
        },
        {
            id: "20",
            label: "ref.transaction.item_categories.value_20",
        },
        {
            id: "21",
            label: "ref.transaction.item_categories.value_21",
        },
        {
            id: "22",
            label: "ref.transaction.item_categories.value_22",
        },
        {
            id: "23",
            label: "ref.transaction.item_categories.value_23",
        },
        {
            id: "24",
            label: "ref.transaction.item_categories.value_24",
        },
    ],
    translate: true,
};

export default itemCategories;
