const orderCurrency = {
    id: "order_currency",
    endpoint: "/order-currencies",
    withSelectedAccounts: true,
    translate: true,
    reduceFunc: (previous, current) =>
        current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"],
                      label: "ref.transaction.currencies." + current["id"],
                      fallbackLabel: current["id"],
                  },
              ]
            : previous,
};

export default orderCurrency;
