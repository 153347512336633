import { Attributes, DisplayType, FormFieldType } from "@console/types";

/**
 * Attributes (anciennement dataFieldType)
 *
 * Ce sont les propriétés de l'entité du module.
 * Ils permettent de générer:
 * - les champs du formulaire de recherche
 * - les colonnes du tableau de résultat
 * ... plus ou moins tout ce qui touche aux données du module.
 *
 */

const attributes: Attributes = {
    title_long: {
        displayOptions: {
            lookup: false,
            exclude: false,
            sortable: true,
            filterable: false,
        },
        displayType: DisplayType.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: FormFieldType.FORM_FIELD_TEXT,
        id: "title_long",
        order: 2,
        qsearch: false,
    },
    type: {
        displayOptions: {
            lookup: false,
            exclude: false,
            sortable: true,
            filterable: true,
        },
        displayType: DisplayType.DISPLAY_TEXT,
        formFieldOptions: { multiple: true },
        formFieldType: FormFieldType.FORM_FIELD_SELECT,
        id: "type",
        order: 3,
        qsearch: false,
        reference: "widget_types",
    },
    date_created: {
        displayOptions: {
            lookup: false,
            exclude: false,
        },
        displayType: DisplayType.DISPLAY_DATE,
        formFieldOptions: {},
        formFieldType: FormFieldType.FORM_FIELD_DATE,
        id: "date_created",
        order: 4,
        qsearch: false,
    },
};

export default attributes;
