import { UserProvider } from "./user";
import { UserContext } from "./user-context";
import { useInheritContext, withInheritContext } from "../ContextForwarder";

// Get old user object
const useUser = () => {
    const { store } = useInheritContext("user", UserContext);
    return store.user;
};
const withUser = (WrappedComponent) => withInheritContext(WrappedComponent, "user", UserContext);

// Get user info object
const useUserInfo = () => {
    const { store } = useInheritContext("user", UserContext);
    return store.userInfo;
};

const useLogout = () => {
    const { logoutUser } = useInheritContext("user", UserContext);
    return logoutUser;
};

// Get all User Context
const useUserContext = () => useInheritContext("user", UserContext);

export { UserProvider, useUser, useUserInfo, useUserContext, useLogout, withUser };
