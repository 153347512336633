import React from "react";
import CursorDefaultClickOutline from "mdi-material-ui/CursorDefaultClickOutline";

const OneClickIcon = (props) => {
    const iconSize = props.style && props.style.height ? props.style.height : 24;
    const iconStyle = {
        fontSize: iconSize,
        padding: 3,
        marginRight: 4,
        color: "#fff",
        backgroundColor: "#00ade9",
        borderRadius: "50%",
    };

    return <CursorDefaultClickOutline style={iconStyle} />;
};

export default OneClickIcon;
