import IconButton from "@mui/material/IconButton";
import { useP } from "../../../services/i18n";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewListIcon from "@mui/icons-material/ViewList";
import { GRID_VIEW } from "../../../constants";

export const SwitchViewButton = (props) => {
    const { handleSwitchView, label, currentView } = props;

    const p = useP();

    const iconComponent =
        currentView === GRID_VIEW ? (
            <DashboardIcon id="thumbnail-view" />
        ) : (
            <ViewListIcon id="list-view" />
        );

    return (
        <IconButton
            id="switch-view-btn"
            title={p.t(`common.search.buttons.${label}`)}
            onClick={handleSwitchView}
            size="large"
            color="info"
        >
            {iconComponent}
        </IconButton>
    );
};
