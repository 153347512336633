const typesColor = {
    capture: "#02A17B",
    refund: "#4D5255",
    final_ko: "#CB2B0B",
};

const operationTypes = {
    // predefined static options
    id: "operation_types",
    data: [
        {
            id: "CAPTURE",
            label: "ref.transaction.operation_types.CAPTURE",
            color: typesColor.capture,
        },
        {
            id: "CHARGEBACK",
            label: "ref.transaction.operation_types.CHARGEBACK",
            color: typesColor.final_ko,
        },
        {
            id: "CHARGEBACK_REFUND",
            label: "ref.transaction.operation_types.CHARGEBACK_REFUND",
            color: typesColor.capture,
        },
        {
            id: "CREDIT",
            label: "ref.transaction.operation_types.CREDIT",
            color: typesColor.capture,
        },
        {
            id: "REFUND",
            label: "ref.transaction.operation_types.REFUND",
            color: typesColor.refund,
        },
    ],
    translate: true,
};

export default operationTypes;
