export const getFraudRuleLabel = (rule, p) => {
    switch (rule.family) {
        case "RuleIovation":
            return p.t("ref.transaction.sentinel_rules." + rule.category);
        case "RulePaymentData":
            switch (rule.type) {
                case "PAYMENTLIST":
                    return (
                        p.t("ref.transaction.sentinel_rules." + rule.fieldName) +
                        " " +
                        p.t("ref.transaction.sentinel_rules." + rule.operator) +
                        " " +
                        (rule.fraudList ? rule.fraudList.label : "NULL")
                    );
                case "CUSTOMLIST":
                    return (
                        rule.fieldName +
                        " " +
                        p.t("ref.transaction.sentinel_rules." + rule.operator) +
                        " " +
                        (rule.fraudList ? rule.fraudList.label : "")
                    );
                case "PAYMENTDATA":
                    return (
                        p.t("ref.transaction.sentinel_rules." + rule.fieldName) +
                        " " +
                        p.t("ref.transaction.sentinel_rules." + rule.operator) +
                        " " +
                        rule.fieldValue
                    );
                case "CUSTOMDATA":
                    return (
                        rule.fieldName +
                        " " +
                        p.t("ref.transaction.sentinel_rules." + rule.operator) +
                        " " +
                        rule.fieldValue
                    );
                default:
                    throw Error("RulePaymentData not found " + rule.family + " - " + rule.type);
            }
        case "RuleCombo":
            return rule.name;
        case "RuleVelocity":
            return (
                ">= " +
                rule.fieldQuantity +
                " " +
                p.t("ref.transaction.sentinel_rules." + rule.status) +
                " = " +
                p.t("ref.transaction.sentinel_rules." + rule.field) +
                " " +
                p.t("ref.transaction.sentinel_rules.in") +
                " " +
                rule.periodQuantity +
                " " +
                p.t("ref.transaction.sentinel_rules." + rule.period, rule.periodQuantity)
            );
        case "RuleSpendingLimit":
            return (
                "> " +
                rule.spendingAmount +
                " = " +
                p.t("ref.transaction.sentinel_rules." + rule.field) +
                " " +
                p.t("ref.transaction.sentinel_rules.in") +
                " " +
                rule.periodQuantity +
                " " +
                p.t("ref.transaction.sentinel_rules." + rule.period, rule.periodQuantity)
            );
        case "RuleActivity":
            return (
                "> " +
                rule.val +
                " " +
                p.t("ref.transaction.sentinel_rules." + rule.compareField) +
                " = " +
                p.t("ref.transaction.sentinel_rules." + rule.field) +
                " " +
                p.t("ref.transaction.sentinel_rules.in") +
                " " +
                rule.periodQuantity +
                " " +
                p.t("ref.transaction.sentinel_rules." + rule.period, rule.periodQuantity)
            );
        case "RuleStatic":
            return p.t("ref.transaction.sentinel_rules." + rule.script);
        case "RuleTime":
            return (
                p.t("ref.transaction.sentinel_rules." + rule.day) +
                " " +
                rule.hourStart +
                "h - " +
                rule.hourEnd +
                "h"
            );
        case "RuleScore":
            return ">= " + rule.fraudscore;
        default:
            throw Error("Rule not found " + rule.family + " - " + rule.type);
    }
};

export const getFraudRuleFamilyCode = (rule) => {
    if (rule.family === "RulePaymentData") {
        if (rule.type === "PAYMENTLIST") {
            return `${rule.family}_${rule.type}_${rule.level}`;
        }
        return `${rule.family}_${rule.type}`;
    }
    return rule.family;
};

export const getFraudRuleFamilyLabel = (rule, p) => {
    switch (rule.family) {
        case "RuleIovation":
        case "RuleCombo":
        case "RuleVelocity":
        case "RuleSpendingLimit":
        case "RuleActivity":
        case "RuleStatic":
        case "RuleTime":
        case "RuleScore":
            return p.t("ref.transaction.sentinel_rules." + rule.family);
        case "RulePaymentData":
            switch (rule.type) {
                case "PAYMENTLIST":
                    return p.t(
                        `ref.transaction.sentinel_rules.${rule.family}.${rule.type}.${rule.level}`
                    );
                case "PAYMENTDATA":
                    return p.t(`ref.transaction.sentinel_rules.${rule.family}.${rule.type}`);
                case "CUSTOMDATA":
                case "CUSTOMLIST":
                    return p.t("ref.transaction.sentinel_rules.CustomData");
                default:
                    throw Error("RulePaymentData not found " + rule.family + " - " + rule.type);
            }
        default:
            throw Error("Rule not found " + rule.family + " - " + rule.type);
    }
};

export const getFraudActionLabel = (action, p) => {
    if (!action.includes("-") && !action.includes("+")) {
        return p.t("ref.transaction.sentinel_rules." + action);
    }
    return action;
};
