const fraudReportedByAcquirers = {
    id: "fraud_reported_by_acquirers",
    data: [
        {
            id: "1",
            label: "ref.transaction.fraud_reported_by_acquirers.yes",
        },
        {
            id: "0",
            label: "ref.transaction.fraud_reported_by_acquirers.no",
        },
    ],
    translate: true,
};

export default fraudReportedByAcquirers;
