/**
 * Retourne la chaîne de caractères passée en entrée sans les accents
 * @param toFold
 * @returns {*}
 */
export function foldAccents(toFold) {
    return toFold.replace(
        /([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])|([œ])|(['])/g,
        (str, a, c, e, i, n, o, s, u, y, ae, oe, quote) => {
            if (a) {
                return "a";
            }
            if (c) {
                return "c";
            }
            if (e) {
                return "e";
            }
            if (i) {
                return "i";
            }
            if (n) {
                return "n";
            }
            if (o) {
                return "o";
            }
            if (s) {
                return "s";
            }
            if (u) {
                return "u";
            }
            if (y) {
                return "y";
            }
            if (ae) {
                return "ae";
            }
            if (oe) {
                return "oe";
            }
            if (quote) {
                return "";
            }
            return str;
        }
    );
}

/**
 * Take a string and add the first char in uppercase for each word in it
 * @param name
 * @returns string
 */
export const getFormatedName = (name) => {
    let nameFormated = name.toLowerCase();
    for (let i = 0; i < nameFormated.length; i++) {
        if (i === 0) {
            nameFormated =
                nameFormated.substring(0, 1).toUpperCase() +
                nameFormated.substring(1, nameFormated.length);
        } else if (nameFormated[i] === " " || nameFormated[i] === "-") {
            nameFormated =
                nameFormated.substring(0, i + 1) +
                nameFormated.substring(i + 1, i + 2).toUpperCase() +
                nameFormated.substring(i + 2, nameFormated.length);
        }
    }
    return nameFormated;
};

/**
 * Highlight string by surrounding matched string partition by <strong></strong>
 *
 * @param str
 * @param query
 * @returns {*}
 */
export function highlightString(str, query) {
    const pureStr = foldAccents(str.toLowerCase());
    const pureQuery = foldAccents(query.toLowerCase());
    if (pureStr.indexOf(pureQuery) >= 0) {
        let firstPart = str.substr(0, pureStr.indexOf(pureQuery));
        let highlightPart = str.substr(pureStr.indexOf(pureQuery), query.length);
        let endPart = str.substr(pureStr.indexOf(pureQuery) + query.length);

        return `${firstPart}<strong>${highlightPart}</strong>${endPart}`;
    }
    return str;
}

/**
 * Retourne la chaine de caractères passsée en paramètre
 * en supprimant les caractères spéciaux qui ne sont pas
 * autorisés dans une query de recherche
 *
 * @param string
 * @returns {*}
 */
export const removeSpecialChars = (str) => {
    return str.replace(/[#%^{}\\~]/, "");
};

export const ucFirst = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
