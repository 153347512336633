import financial_operation_descriptions from "./financial_operation_descriptions";
import product_names from "./product_names";
import operation_currencies from "./operation_currencies";
import transaction_currencies from "./transaction_currencies";
import settlement_currencies from "./settlement_currencies";
import settlement_original_currencies from "./settlement_original_currencies";
import creditor_ibans from "./creditor_ibans";
import debitor_ibans from "./debitor_ibans";
import creditor_bics from "./creditor_bics";
import debitor_bics from "./debitor_bics";
import merchant_bank_countries from "./merchant_bank_countries";
import ip_countries from "./ip_countries";

export default {
    financial_operation_descriptions,
    operation_currencies,
    transaction_currencies,
    product_names,
    settlement_currencies,
    settlement_original_currencies,
    creditor_ibans,
    ip_countries,
};

export const internal = {
    creditor_bics,
    debitor_ibans,
    debitor_bics,
    merchant_bank_countries,
};
