import { useFullscreen } from "./useFullscreen";
import { useMessageBar } from "../services/messageBar";
import { MESSAGE_BAR_HEIGHT, TOP_BAR_HEIGHT } from "../constants";

export function useHeaderHeight() {
    const { isFullscreen } = useFullscreen();
    const { isVisible } = useMessageBar();

    return isFullscreen ? 0 : isVisible ? TOP_BAR_HEIGHT + MESSAGE_BAR_HEIGHT : TOP_BAR_HEIGHT;
}
