import ContentLoader from "react-content-loader";
import makeStyles from "@mui/styles/makeStyles";

const useStylesItemLoader = makeStyles((theme) => ({
    root: {},
    aWidget: {
        boxSizing: "border-box",
        boxShadow: "0px 2px 10px 0px #cfcfcf",
    },
    loader: {
        width: 199,
        margin: "10px 16px",
    },
}));

export const ItemLoader = () => {
    const classes = useStylesItemLoader();
    const loaderProps = { className: classes.loader };

    return (
        <div className={classes.aWidget}>
            <ContentLoader
                height={263}
                width={199}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                {...loaderProps}
            >
                <circle cx="96.23" cy="130.26" r="65.23" />
                <rect x="-1.31" y="-0.39" rx="0" ry="0" width="206.8" height="33" />
                <rect x="115.31" y="38.61" rx="0" ry="0" width="0" height="0" />
                <rect x="21.52" y="52.61" rx="0" ry="0" width="0" height="0" />
                <rect x="-2.16" y="236.61" rx="0" ry="0" width="76.26" height="24.05" />
                <rect x="58.84" y="242.61" rx="0" ry="0" width="0" height="0" />
                <rect x="149.84" y="233.61" rx="0" ry="0" width="55" height="26" />
                <rect x="171.84" y="244.61" rx="0" ry="0" width="0" height="3" />
            </ContentLoader>
        </div>
    );
};
