import { SearchSelectionProvider } from "./SearchSelection.provider";
import {
    SearchSelectionActionsContext,
    SearchSelectionStateContext,
} from "./SearchSelection.context";
import { compose } from "redux";

export function withSearchSelectionProvider(Component) {
    return function SearchSelectionProviderComponent(props) {
        return (
            <SearchSelectionProvider>
                <Component {...props} />
            </SearchSelectionProvider>
        );
    };
}

export function withSearchSelectionState(Component) {
    return function SearchSelectionStateComponent(props) {
        return (
            <SearchSelectionStateContext.Consumer>
                {(searchSelectionState) => (
                    <Component {...props} searchSelectionState={searchSelectionState} />
                )}
            </SearchSelectionStateContext.Consumer>
        );
    };
}

export function withSearchSelectionActions(Component) {
    return function SearchSelectionActionsComponent(props) {
        return (
            <SearchSelectionActionsContext.Consumer>
                {(searchSelectionActions) => (
                    <Component {...props} searchSelectionActions={searchSelectionActions} />
                )}
            </SearchSelectionActionsContext.Consumer>
        );
    };
}

export function withSearchSelection(Component) {
    return compose(withSearchSelectionState, withSearchSelectionActions)(Component);
}
