import PropTypes from "prop-types";
import classNames from "classnames";
import { lighten } from "@mui/material/styles";
import styled from "styled-components/macro";
import { useCan } from "../../../../../services";
import { CELL_HEIGHT, CELL_HEIGHT_DENSE } from "../../../../constants";
import {
    CellBuilderSearch,
    CellLayout,
    CellPinToAction,
    CellRank,
    CellCheckbox,
    CellEmpty,
} from "../../../Table";
import { withSearchSelection } from "../../../../services";

const Row = (props) => {
    const {
        attributes,
        columnList,
        datas,
        dense,
        details,
        displayAsDetailRow,
        lookupColumnIdList,
        rowId,
        isDisabledRow,
        isDisplayRank,
        isDisplaySelector,
        index,
        openedDetailName,
        p,
        userSettings,
        onClick,
        onMiddleClick,
        onClickOpenDetail,
        qsearchParams,
        searchSelectionActions,
    } = props;

    const can = useCan();

    const isSelected = typeof searchSelectionActions.get(rowId) !== "undefined";

    const handleClickRow = (event, colId) => {
        event.stopPropagation();

        if (!onClick) {
            return;
        }

        onClick(event, rowId, colId);
    };

    const handleMiddleClick = (event, colId) => {
        // Event button 1 is middle click
        if (event.button === 1) {
            event.stopPropagation();

            if (!onMiddleClick) {
                return;
            }

            onMiddleClick(event, rowId);
        }
    };

    const handleClickOpenDetail = (detailName) => (event) => {
        event.stopPropagation();
        onClickOpenDetail(event, detailName);
    };

    const handleChangeSelection = (event) => {
        if (event) {
            // Prevent error when user clicks outside of checkbox
            searchSelectionActions.handleChangeSelection(event, index);
        }
    };

    return (
        <div className={props.className}>
            <div
                id={`row-${rowId}`}
                className={classNames("lazy-row", "root", {
                    clickable: !!onClick,
                    dense: dense,
                    detail: displayAsDetailRow && !isSelected,
                    "disabled-row": isDisabledRow,
                    selected: isSelected,
                })}
                tabIndex="0"
                data-row-id={rowId}
                onClick={onClick && handleClickRow}
                onMouseUp={handleMiddleClick}
            >
                {isDisplayRank && (
                    <CellRank
                        name={"cell-rank"}
                        value={index}
                        key={`${rowId}-cell-rank`}
                        className={classNames({
                            "sticky-rank-with-selector": isDisplaySelector,
                            sticky: !isDisplaySelector,
                        })}
                    />
                )}
                {isDisplaySelector &&
                    (!displayAsDetailRow ? (
                        <CellCheckbox
                            key={`${rowId}-cell-selection`}
                            name="cell-selection"
                            checked={isSelected}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={handleChangeSelection}
                            className={classNames({
                                "sticky-with-rank": isDisplayRank,
                                sticky: !isDisplayRank,
                            })}
                        />
                    ) : (
                        <div className="empty-checkbox-cell" />
                    ))}
                {isDisplaySelector && displayAsDetailRow && <CellEmpty />}
                {columnList.map(({ colId, dataList, view, width, ...colProps }, idx) => {
                    const attribute = attributes[colId];

                    if (attribute === undefined) {
                        return null;
                    }

                    let clickableCell = !!colProps.onClick;
                    if (clickableCell && !!attribute.displayOptions.clickable) {
                        clickableCell = attribute.displayOptions.clickable(can);
                    }

                    let multiRow = false;
                    // column is from payment operation so get data in payOpDatas
                    let detailElement;
                    let isDetailCell = false;
                    const detailName = attribute.displayOptions.dataType;
                    if (details && details[detailName]) {
                        isDetailCell = true;
                        if (
                            details[detailName].rows.length > 1 &&
                            attribute.displayOptions.indicator
                        ) {
                            // PSYCHE-2273 : count only filled cells
                            const columnValues = details[detailName].rows.map(
                                (row) => row.datas[colId]
                            );
                            const number = columnValues.filter((value) => value).length;
                            multiRow = true;
                            detailElement = (
                                <CellPinToAction
                                    active={detailName === openedDetailName}
                                    number={number}
                                />
                            );
                        } else {
                            detailElement = (
                                <CellBuilderSearch
                                    key={`${rowId}-cell-${colId}`}
                                    colId={colId}
                                    {...colProps} // displayTime ...
                                    displayType={attribute.displayType}
                                    {...attribute.displayOptions}
                                    data={details[detailName].rows[0].datas[colId]}
                                    dataEntity={dataList}
                                    rowId={rowId}
                                    view={view}
                                    displayAsDetailRow
                                    p={p}
                                    userSettings={userSettings}
                                    onClick={clickableCell ? colProps.onClick : null}
                                />
                            );
                        }
                    }
                    return (
                        <CellLayout
                            key={`${rowId}-cell-${colId}`}
                            colId={colId}
                            displayType={attribute.displayType}
                            index={idx}
                            lookupColumnIdList={lookupColumnIdList}
                            isQSearchRow={
                                qsearchParams &&
                                qsearchParams.hasOwnProperty(colId) &&
                                qsearchParams[colId] === datas[colId]?.value
                            }
                            rowId={rowId}
                            view={view}
                            width={width}
                            displayAsDetailRow={displayAsDetailRow}
                            isDisplaySelector={!!isDisplaySelector}
                            isDisplayRank={!!isDisplayRank}
                            isMultiRow={multiRow}
                            onClick={handleClickOpenDetail(detailName)}
                            pinActive={detailName === openedDetailName}
                        >
                            {isDetailCell ? (
                                detailElement
                            ) : (
                                <CellBuilderSearch
                                    key={`${rowId}-cell-${colId}`}
                                    colId={colId}
                                    {...colProps} // displayTime ...
                                    displayType={attribute.displayType}
                                    {...attribute.displayOptions}
                                    data={datas && datas[colId]}
                                    dataEntity={dataList}
                                    rowId={rowId}
                                    view={view}
                                    displayAsDetailRow
                                    p={p}
                                    userSettings={userSettings}
                                    onClick={clickableCell ? colProps.onClick : null}
                                />
                            )}
                        </CellLayout>
                    );
                })}
            </div>
        </div>
    );
};

Row.propTypes = {
    columnList: PropTypes.array,
    columnDataOptions: PropTypes.object,
    index: PropTypes.number,
    datas: PropTypes.object,
    displayAsDetailRow: PropTypes.bool,
    onClick: PropTypes.func,
    onClickOpenDetail: PropTypes.func,
    rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isDisabledRow: PropTypes.bool,
};

const StyledRow = styled(Row)`
    .root {
        display: inline-flex;
        min-width: 100%;
        background-color: ${({ theme }) => theme.palette.background3};
        &:focus {
            outline: none;
            background-color: #eeeeee;
        }
        &:hover {
            background-color: #eeeeee;
        }
        height: ${CELL_HEIGHT}px;
        line-height: ${CELL_HEIGHT}px;
        white-space: nowrap;
    }

    .selected {
        background-color: ${({ theme }) => lighten(theme.palette.primary.main, 0.92)};
        &:hover,
        &:focus {
            background-color: ${({ theme }) => lighten(theme.palette.primary.main, 0.92)};
        }
    }

    .clickable {
        cursor: pointer;
    }

    .dense {
        height: ${CELL_HEIGHT_DENSE}px;
        line-height: ${CELL_HEIGHT_DENSE}px;
        &:hover {
            height: ${CELL_HEIGHT}px;
            line-height: ${CELL_HEIGHT}px;
        }
    }

    .cell {
        position: relative;
        display: inline-flex;
        justify-content: flex-start;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
    }

    .detail {
        background-color: ${({ theme }) => theme.palette.background1};
        &:hover {
            background-color: #eeeeee;
        }
    }

    .sticky {
        position: sticky;
        left: 0px;
        padding-left: 16px;
        background-color: inherit;
    }

    .sticky-rank-with-selector {
        position: sticky;
        left: 0px;
        background-color: inherit;
    }

    .sticky-cell-with-selector {
        position: sticky;
        left: 40px !important;
        background-color: inherit;
    }

    .sticky-with-rank {
        position: sticky;
        left: 40px !important;
        background-color: inherit;
    }

    .sticky-with-selector-and-rank {
        position: sticky;
        left: 80px !important;
        background-color: inherit;
    }

    .align-right {
        justify-content: flex-end;
        text-align: right;
    }

    .lookup,
    .qSearch-match {
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 4px 0;
        left: 0;
    }

    .lookup-background {
        background-color: ${({ theme }) => theme.palette.lookup.main};
        width: 100%;
        height: 100%;
    }
    .qSearch-match-background {
        background-color: ${({ theme }) => lighten(theme.palette.primary.main, 0.8)};
        border-radius: 4px;
        width: 100%;
        height: 100%;
    }

    .empty-checkbox-cell {
        width: 40px;
        max-width: 40px;
        z-index: 10;
        display: inline-flex;
        position: relative;
        padding-left: 8px;
        padding-right: 8px;
    }

    .disabled-row {
        color: ${({ theme }) => theme.palette.neutral.main};
    }

    .pin-cell-on-active {
        &:hover {
            & > div > div {
                background-color: ${({ theme }) => theme.palette.primary.dark};
            }
        }
    }

    .pin-cell {
        &:hover {
            & > div > div {
                background-color: ${({ theme }) => theme.palette.primary.main};
                color: #ffffff;
            }
        }
    }
`;

export default withSearchSelection(StyledRow);
