import React, { useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import { useOktaAuth } from "@okta/okta-react";
import { useP } from "../i18n";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import CloseIcon from "mdi-material-ui/WindowClose";
import { SET_AUTHENTICATION_NEW } from "../../app/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";

/**
 * ApiProvider
 *
 * Le contexte ApiProvider gère les appels aux Api ainsi que les snackbars à afficher.
 * ( ex:
 * - après selection de comptes
 * ...
 * Utilisation;
 * - Appeler une des fonctions disponible via le context (get, post, put, ...)
 * - Passer en paramètre un objet pour afficher une snackbar
 * - {
 *      id: Identifiant de la snackbar
 *      message: 'Message affiché sur la Snackbar'
 *      autoHideDuration: Durée d'affichage de la snackbar en ms (par défaut: 3000)
 *      handleUndo: Fonction de callback appelée lors du clic sur undo
 *      handleDo: Fonction de callback appelée lorsque la snackbar se cache (ou au clic sur la croix pour la masquer)
 *      undoMessage: Message pour le bouton 'annuler' si différent
 *      persist: true pour que la snackbar reste affichée (sans prendre en compte l'option duration)
 *      content: Fonction pour rendre un contenu personnalisé de la snackbar
 *   }
 */
const ApiProvider = ({ defaultConfig = {}, Context, children }) => {
    const p = useP();
    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const undoRef = useRef(null);
    const undoActionRef = useRef(null);

    const dispatch = useDispatch();

    const storeAuthData = useSelector((state) => state.app && state.app.authentication.data);

    /**
     * Undo snackbar
     * reject Promise and close snackbar
     *
     * @param id
     * @param reject
     * @returns {Function}
     */
    const undoSnackbar = useCallback(
        (id, reject) => () => {
            reject("undo");
            closeSnackbar(id);
        },
        []
    );

    /**
     * Undo button
     * @type {function({id?: *, resolve?: *}): *}
     */
    const UndoButton = useCallback(
        ({ id, reject }) => (
            <HiButton
                id={`undo-${id}`}
                aria-label={p.t("app.snackbar.undo")}
                autoFocus
                children={p.t("app.snackbar.undo")}
                color="inherit"
                onClick={undoSnackbar(id, reject)}
                ref={undoRef}
                action={undoActionRef}
            />
        ),
        [undoSnackbar]
    );

    /**
     * Dismiss snackbar
     * resolve Promise and close snackbar
     *
     * @param id
     * @param resolve
     * @returns {Function}
     */
    const dismissSnackbar = useCallback(
        (id, resolve) => () => {
            if (resolve) {
                resolve("dismiss");
            }
            closeSnackbar(id);
        },
        []
    );

    /**
     * Dismiss button
     * @type {function({id?: *, resolve?: *}): *}
     */
    const DismissButton = useCallback(
        ({ id, resolve }) => (
            <HiIconButton
                id={`dismiss-${id}`}
                aria-label={p.t("app.snackbar.close")}
                children={<CloseIcon />}
                color="inherit"
                onClick={dismissSnackbar(id, resolve)}
            />
        ),
        [dismissSnackbar]
    );

    /**
     * Pending snackbar
     *
     * enqueue snackbar as Promise with undo and close buttons
     * - resolve : after 3s (default) or on click on close button
     * - reject : on click on undo button (reason => 'undo')
     *
     * @type {function(*, *=): Promise<any>}
     */
    const enqueuePendingSnackbar = useCallback(
        (uniqid, snackbar = {}) => {
            return new Promise((resolve, reject) =>
                enqueueSnackbar(snackbar.message || p.t("app.snackbar.pending"), {
                    key: "pending-" + uniqid,
                    id: "pending-" + uniqid,
                    action: (key) => [
                        <UndoButton key={`undo-${key}`} id={key} reject={reject} />,
                        <DismissButton key={`dismiss-${key}`} id={key} resolve={resolve} />,
                    ],
                    autoHideDuration: 3000,
                    disableWindowBlurListener: true,
                    onClose: (event, reason, key) => {
                        if (event) {
                            event.preventDefault();
                        }
                        if (reason !== "clickaway") {
                            // don't close on clickaway
                            resolve(reason, key);
                        }
                    },
                    onEntered: () => {
                        undoRef.current.focus();
                        undoActionRef.current.focusVisible();
                    },
                    ...snackbar, // allow override snackbar
                })
            );
        },
        [p]
    );

    /**
     * Started snackbar
     *
     * add persistent snackbar with close button
     *
     * @type {function(*, *): *}
     */
    const enqueueStartedSnackbar = useCallback(
        (uniqid, snackbar) => {
            return enqueueSnackbar(snackbar.message, {
                key: "started-" + uniqid,
                id: "started-" + uniqid,
                action: (key) => [<DismissButton key={`dismiss-${key}`} id={key} />],
                autoHideDuration: 3000,
                ...snackbar, // allow override snackbar
            });
        },
        [p]
    );

    /**
     * Success snackbar
     *
     * add snackbar with close button
     *
     * @type {function(*, *): *}
     */
    const enqueueSuccessSnackbar = useCallback(
        (uniqid, snackbar) => {
            return enqueueSnackbar(snackbar.message, {
                key: "success-" + uniqid,
                id: "success-" + uniqid,
                action: (key) => [<DismissButton key={`dismiss-${key}`} id={key} />],
                disableWindowBlurListener: true,
                autoHideDuration: 3000,
                variant: "success",
                ...snackbar, // allow override snackbar
            });
        },
        [p]
    );

    /**
     * Success snackbar
     *
     * add failure snackbar with close button
     * default message exist if it's not defined
     *
     * @type {function(*, *, *=): *}
     */
    const enqueueFailureSnackbar = useCallback(
        async (uniqid, error, snackbar = {}) => {
            let message = snackbar.message || error?.statusText;
            if (typeof snackbar.message === "function") {
                message = await snackbar.message(error);
            }
            return enqueueSnackbar(message, {
                key: "failure-" + uniqid,
                id: "failure-" + uniqid,
                action: (key) => [<DismissButton key={`dismiss-${key}`} id={key} />],
                variant: "error",
                autoHideDuration: 5000,
                ...snackbar, // allow override snackbar
            });
        },
        [p]
    );

    /**
     * Call Api
     * handle classic api snackbar flow
     * via args and props such as
     * - url : url to call
     * - headers : override default headers
     * - undoable : add a snackbar with undo button
     * - onUndo : callback call on undo
     * - ***Snackbar (pending, started, success, error) : customize snackbars for each state
     *
     * @param method
     * @param controller
     * @param args
     * @param props
     * @returns {Promise<any>}
     */
    const callApi = (method, controller, args = [], props = {}) => {
        const {
            id,
            pendingSnackbar,
            startedSnackbar,
            successSnackbar,
            failureSnackbar,
            headers,
            onUndo,
            undoable,
            url,
        } = props;

        // allow some props to be customized by args
        const _id = typeof id === "function" ? id(...args) : id;
        const _url = typeof url === "function" ? url(...args) : url;
        const _pendingSnackbar =
            typeof pendingSnackbar === "function" ? pendingSnackbar(...args) : pendingSnackbar;
        const _startedSnackbar =
            typeof startedSnackbar === "function" ? startedSnackbar(...args) : startedSnackbar;
        const _successSnackbar =
            typeof successSnackbar === "function" ? successSnackbar(...args) : successSnackbar;
        const _failureSnackbar =
            typeof failureSnackbar === "function" ? failureSnackbar(...args) : failureSnackbar;

        // get data from first arg
        const _data = ["POST", "PUT", "PATCH"].includes(method) ? args[0] : null;

        let promise = new Promise((resolve) => resolve()); // auto resolve promise

        // generate uniq id from id and Date.now()
        const uniqid = _id ? `${_id}-${Date.now()}` : Date.now();

        // add pending snackbar with undo button
        if (undoable) {
            promise = enqueuePendingSnackbar(uniqid, _pendingSnackbar);
        }

        return promise
            .then((reason) => {
                // here reason could be null, timeout, dismiss

                // add started snackbar
                let startedKey;
                if (_startedSnackbar) {
                    startedKey = enqueueStartedSnackbar(uniqid, _startedSnackbar);
                }

                // fetch api
                return fetchApi(method, _url, _data, headers, controller)
                    .finally(() => {
                        // close started snackbar by key
                        if (startedKey) {
                            closeSnackbar(startedKey);
                        }
                    })
                    .then((response) => {
                        if (_successSnackbar) {
                            enqueueSuccessSnackbar(uniqid, _successSnackbar);
                        }
                        return response;
                    });
            })
            .catch((error) => {
                // handle undo rejection
                if (error === "undo") {
                    // custom undo callback
                    if (onUndo && typeof onUndo === "function") {
                        onUndo();
                    } else {
                        throw error;
                    }
                } else if (error.name !== "AbortError") {
                    if (_failureSnackbar) {
                        enqueueFailureSnackbar(uniqid, error, _failureSnackbar);
                    } else {
                        enqueueFailureSnackbar(uniqid, error, {
                            message: p.t("app.snackbar.failure"),
                        });
                    }
                    throw error;
                }
            });
    };

    /**
     * (async) fetch call
     *
     * use api url from this context
     * use token from authentication context
     *
     * @param method
     * @param path
     * @param data
     * @param headers
     * @param controller
     * @returns {Promise<any>}
     */
    const fetchApi = async (method, path, data, headers = {}, controller) => {
        let tokenToRenew = {
            accessToken: authState?.accessToken,
            claims: {},
            expiresAt: oktaAuth.authStateManager.getAuthState()?.accessToken?.expiresAt,
            scopes: ["openid", "email", "console", "offline_access"],
            authorizeUrl: process.env.NX_OKTA_ISSUER + "/v1/authorize",
            issuer: process.env.NX_OKTA_ISSUER,
            clientId: process.env.NX_OKTA_WIDGET_CLIENT_ID,
            grantType: "refresh_token",
        };

        if ((authState?.accessToken?.expiresAt - Date.now() / 1000) / 60 < 55) {
            // Renew token only if it is older than 1 minute
            oktaAuth.token.renew(tokenToRenew).then((response) => {
                const newToken = {
                    ...authState.accessToken,
                    accessToken: response.accessToken,
                    expiresAt: response.expiresAt,
                };
                oktaAuth.tokenManager.add("accessToken", newToken);
                oktaAuth.authStateManager.updateAuthState();

                dispatch({
                    type: SET_AUTHENTICATION_NEW,
                    payload: {
                        data: {
                            ...storeAuthData,
                            token: response.accessToken,
                        },
                        loading: false,
                        error: null,
                    },
                });
            });
        }

        // Override defaultUrl from headers args
        const { url = defaultConfig.url, ..._headers } = headers;

        let config = {
            headers: {
                "X-Authorization": `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
                ...defaultConfig.headers,
                ..._headers,
            },
            method,
        };

        // Let the browser generate the header : https://github.com/github/fetch/issues/505
        if (data && data instanceof FormData) {
            delete config.headers["Content-Type"];
        }

        if (method === "GET" && controller) {
            config.signal = controller.signal;
        }
        if (data && ["POST", "PUT", "PATCH"].includes(method)) {
            config.body = data;
        }

        const res = await fetch(`${url}${path}`, config);

        if (!res.ok) {
            throw res;
        }

        // return raw res for accessing headers
        if (["plain/text"].includes(headers["Accept"])) {
            return res;
        }

        // return blob for pdf
        if (["application/pdf", "application/zip", "text/csv"].includes(headers["Accept"])) {
            return res.blob();
        }

        // return null for 204 (deletion)
        if (res.status === 204) {
            return null;
        }

        return res.json();
    };

    const _get = (args, props, controller) => callApi("GET", controller, args, props);
    const _post = (args, props, controller) => callApi("POST", controller, args, props);
    const _put = (args, props, controller) => callApi("PUT", controller, args, props);
    const _patch = (args, props, controller) => callApi("PATCH", controller, args, props);
    const _delete = (args, props, controller) => callApi("DELETE", controller, args, props);
    const _head = (args, props, controller) => callApi("HEAD", controller, args, props);

    return (
        <Context.Provider
            value={{
                fetchApi,
                get: _get,
                post: _post,
                put: _put,
                patch: _patch,
                delete: _delete,
                head: _head,
            }}
        >
            {React.Children.only(children)}
        </Context.Provider>
    );
};

/**
 * Builder to create ApiProvider from base api url and context
 *
 * @param defaultConfig
 * @param Context
 * @returns {function({children: *}): *}
 * @constructor
 */
export function ApiProviderBuilder(defaultConfig, Context) {
    return ({ children }) =>
        ApiProvider({
            defaultConfig,
            Context,
            children,
        });
}
