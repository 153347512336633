export enum FormFieldType {
    FORM_FIELD_SELECT = "FORM_FIELD_SELECT",
    FORM_FIELD_SUGGEST = "FORM_FIELD_SUGGEST",
    FORM_FIELD_CUSTOM_DATA = "FORM_FIELD_CUSTOM_DATA",
    FORM_FIELD_CHECKBOX = "FORM_FIELD_CHECKBOX",
    FORM_FIELD_SWITCH = "FORM_FIELD_SWITCH",
    FORM_FIELD_TEXT = "FORM_FIELD_TEXT",
    FORM_FIELD_TEXT_REFERENCE = "FORM_FIELD_TEXT_REFERENCE",
    FORM_FIELD_DATE = "FORM_FIELD_DATE",
    FORM_FIELD_MONTH = "FORM_FIELD_MONTH",
    FORM_FIELD_STATUS = "FORM_FIELD_STATUS",
    FORM_FIELD_NUMERIC = "FORM_FIELD_NUMERIC",
    FORM_FIELD_ACCOUNT = "FORM_FIELD_ACCOUNT",
    FORM_FIELD_NESTED_SELECT = "FORM_FIELD_NESTED_SELECT",
    FORM_FIELD_CHECKBOX_TO_SELECT = "FORM_FIELD_CHECKBOX_TO_SELECT",
}
