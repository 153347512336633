import Icon from "@mui/material/Icon";

export const RecurringExportIcon = (props) => (
    <Icon
        style={{
            ...props.style,
            fontSize: props.size || 24,
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-30 0 256 256">
            <path
                d="M173 21.63h-10.78V0h-21.63v21.63H54.07V0H32.44v21.63H21.63A21.62 21.62 0 000 43.26v151.41a21.63 21.63 0 0021.63 21.63h32.44v-21.63H21.63V75.7H173v119h-32.41v21.6H173a21.64 21.64 0 0021.63-21.63V43.26A21.63 21.63 0 00173 21.63z"
                fill={props.color || "currentColor"}
            />
            <path
                d="M97.33 215.86l-56-56.05h32v-48.15h48.15v48.15h32zM41.28 232h112.11v16.17H41.28z"
                fill={props.color || "currentColor"}
            />
        </svg>
    </Icon>
);
