import { Component } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import ContentLoader from "react-content-loader";
import { withStylesAndTranslate } from "../../../../../hoc";
import { getCellHeight } from "../../../../constants";

export const stylesNextDatas = (theme) => ({
    root: {
        position: "sticky",
        left: 0,
        height: 124,
        width: window.innerWidth,
        backgroundColor: theme.palette.background3,
    },
});

class NextDatas extends Component {
    static propTypes = {
        infiniteScroll: PropTypes.bool,
        onRequestNextDatas: PropTypes.func,
    };

    componentDidMount() {
        this.props.onRequestNextDatas();
    }

    render() {
        const { classes, dense = false } = this.props;

        const width = window.innerWidth;
        const cellHeight = dense ? 32 : 40;
        const height = 3 * cellHeight + 4;

        return (
            <div className={classes.root}>
                <ContentLoader
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                    height={height}
                    width={width}
                >
                    <rect x={16} y={8} rx="3" ry="3" width={width - 32} height={cellHeight - 16} />
                    <rect
                        x={16}
                        y={cellHeight + 8}
                        rx="3"
                        ry="3"
                        width={width - 32}
                        height={cellHeight - 16}
                    />
                    <rect
                        x={16}
                        y={2 * cellHeight + 8}
                        rx="3"
                        ry="3"
                        width={width - 32}
                        height={cellHeight - 16}
                    />
                </ContentLoader>
            </div>
        );
    }
}

// eslint-disable-next-line no-class-assign
NextDatas = withStylesAndTranslate(stylesNextDatas)(NextDatas);

export const LazyLoadingNextDatas = (props) => {
    return (
        <LazyLoad
            key={props.index}
            height={getCellHeight(props.dense)}
            unmountIfInvisible
            overflow
            offset={200}
        >
            <NextDatas {...props} />
        </LazyLoad>
    );
};
