import { useContexts } from "../ContextForwarder";

import { ErrorBoundaryRoute } from "../../app/components/common/ErrorBoundaryRoute";
import { useCheckModuleAccess } from "../module/useCheckModuleAccess";

export const Route = (props) => {
    const contexts = useContexts();
    const { checkModuleAccess } = useCheckModuleAccess();

    if (!checkModuleAccess(props.key, true, true)) {
        return null;
    }

    return <ErrorBoundaryRoute contexts={contexts} {...props} />;
};
