import React from "react";
import PropTypes from "prop-types";
import HiSelectField from "@hipay/hipay-material-ui/HiSelect/HiSelectField";
import HiNestedSelectField from "@hipay/hipay-material-ui/HiSelect/HiNestedSelectField";
import { translate } from "../../../../services/i18n/translate";

class SelectField extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        error: PropTypes.bool,
        errorText: PropTypes.string,
        formField: PropTypes.object,
        hasAll: PropTypes.bool,
        id: PropTypes.string,
        lazy: PropTypes.bool,
        loading: PropTypes.bool,
        maxOptionsDisplayed: PropTypes.number,
        name: PropTypes.string,
        nested: PropTypes.bool,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        options: PropTypes.array,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    };

    render() {
        const {
            value,
            formField,
            p,
            id,
            onSubmit,
            loading,
            name,
            options,
            onChange,
            maxOptionsDisplayed,
            disabled,
            error,
            errorText,
            nested,
            hasAll,
            lazy,
        } = this.props;

        let _value;

        if (formField.multiple === false && nested !== true) {
            if (value) {
                _value = Array.isArray(value) ? value[0] : value;
            } else {
                _value = null;
            }
        } else if (value) {
            _value = Array.isArray(value) ? value : [value];
        } else {
            _value = [];
        }

        const Component = nested ? HiNestedSelectField : HiSelectField;

        let selectOptions = [...options];
        if (formField.hasEmptyOption !== false) {
            selectOptions.unshift({
                id: "EMPTY",
                label: p.t("common.search.empty"),
            });
        }

        return (
            <Component
                id={id}
                name={name}
                value={_value}
                multiple
                options={selectOptions}
                loading={loading}
                helperIcon
                searchable
                fallbackImage={formField.fallbackImage}
                onChange={onChange}
                onSubmit={onSubmit}
                disabled={disabled}
                error={error}
                errorText={errorText}
                hasAll={hasAll}
                maxOptionsDisplayed={maxOptionsDisplayed}
                translations={{
                    all: p.t("form.fields.select.all"),
                    no_result_match: p.t("form.fields.select.no_result_match"),
                    search: p.t("form.fields.select.search"),
                    n_items_selected: p.t("form.fields.select.n_items_selected"),
                    one_item_selected: p.t("form.fields.select.one_item_selected"),
                    n_children: p.t("form.fields.select.n_children"),
                    one_child: p.t("form.fields.select.one_child"),
                }}
                hiSelectableListProps={{
                    sort: formField.sort,
                    sortAppendList: ["EMPTY"],
                    hideCheckbox: formField.hideCheckbox,
                    lazy: lazy,
                }}
                {...formField}
            />
        );
    }
}

export default translate(SelectField);
