import { formFieldTypes, displayTypes } from "@console/core";
import { isInternal } from "../utils";

const externalAttributes = {
    account: {
        id: "account",
        apiField: "mchAccountId",
        formFieldType: formFieldTypes.FORM_FIELD_ACCOUNT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
    },
    account_name: {
        id: "account_name",
        apiField: "name",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: false,
            exclude: false,
            color: ({ extra }) => extra.colorCode,
        },
        qsearch: false,
    },
    account_type: {
        id: "account_type",
        apiField: "typeCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        displayOptions: {
            sortable: true,
            lookup: false,
            exclude: false,
        },
        reference: "accountTypeCodes",
        qsearch: false,
    },
    active_payment_mean: {
        id: "active_payment_mean",
        apiField: "paymentMeans.code",
        displayType: displayTypes.DISPLAY_IMAGE,
        formFieldOptions: {
            type: "image",
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        displayOptions: {
            sortable: false,
            dataType: "payment_means",
            indicator: true,
            lookup: true,
            exclude: false,
        },
        reference: "paymentMeanCodes",
        qsearch: false,
    },
    activity_sector: {
        id: "activity_sector",
        apiField: "mccCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        reference: "mccCodes",
        qsearch: false,
    },
    card_restriction: {
        id: "card_restriction",
        apiField: "cardRestrictionCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {
            sortable: false,
            lookup: true,
            exclude: false,
        },
        reference: "cardRestrictionCodes",
        qsearch: false,
    },
    billing_descriptor: {
        id: "billing_descriptor",
        apiField: "websites.billingDescriptor",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    invoicing_email: {
        id: "invoicing_email",
        apiField: "invoicing.email",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {},
        qsearch: false,
    },
    business_id: {
        id: "business_id",
        apiField: "business.tppId",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
    business_name: {
        id: "business_name",
        apiField: "business.name",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
    cms: {
        id: "cms",
        apiField: "cms",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
    currency: {
        id: "currency",
        apiField: "currencyCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        reference: "currencyCodes",
        qsearch: false,
    },
    date_created: {
        id: "date_created",
        apiField: "createdAt",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: false,
            exclude: false,
        },
        qsearch: false,
    },
    date_updated: {
        id: "date_updated",
        apiField: "updatedAt",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: false,
            exclude: false,
        },
        qsearch: false,
    },
    days_before_payment: {
        id: "days_before_payment",
        apiField: "invoicing.daysBeforePayement",
        displayType: displayTypes.DISPLAY_NUMERIC,
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    debit_bic: {
        id: "debit_bic",
        apiField: "invoicing.debitBic",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    debit_iban: {
        id: "debit_iban",
        apiField: "invoicing.debitIban",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    debit_payment_service: {
        id: "debit_payment_service",
        apiField: "invoicing.debitPaymentService",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
        reference: "enableStatus",
    },
    exchange_markup: {
        id: "exchange_markup",
        apiField: "invoicing.collectExchangeMarkup",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    home_country: {
        id: "home_country",
        apiField: "homeCountryCode",
        displayType: displayTypes.DISPLAY_IMAGE,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
        },
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
            ellipsis: "middle",
        },
        reference: "homeCountryCodes",
        qsearch: false,
    },
    id: {
        id: "id",
        apiField: "publicRef",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: false,
            exclude: false,
        },
        qsearch: false,
    },
    invoicing_mode: {
        id: "invoicing_mode",
        apiField: "invoicing.mode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        displayOptions: {
            sortable: false,
            lookup: true,
            exclude: false,
        },
        reference: "invoicingModeCodes",
        qsearch: false,
    },
    month_end_payment: {
        id: "month_end_payment",
        apiField: "invoicing.monthEndPayment",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
        reference: "enableStatus",
    },
    business_salesowner: {
        id: "business_salesowner",
        apiField: "business.salesOwner.userCache.fullName",
        displayType: displayTypes.DISPLAY_AVATAR,
        formFieldOptions: {
            helperText: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {
            sortable: false,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
    business_salesobserver: {
        id: "business_salesobserver",
        apiField: "business.salesObserver.userCache.fullName",
        displayType: displayTypes.DISPLAY_AVATAR,
        formFieldOptions: {
            helperText: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {
            sortable: false,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
    remaining_balance: {
        id: "remaining_balance",
        apiField: "balance",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: false,
            exclude: false,
            type: "numeric",
            precision: 2,
        },
        qsearch: false,
    },
    rolling_percentage_rate: {
        id: "rolling_percentage_rate",
        apiField: "reserve.rollingReserveRate",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    rolling_reserve_duration: {
        id: "rolling_reserve_duration",
        apiField: "reserve.rollingReserveDuration",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    sending_mode: {
        id: "sending_mode",
        apiField: "invoicing.mode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: false,
    },
    settlement_delay: {
        id: "settlement_delay",
        apiField: "settlement.delay",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {},
        reference: "settlementDelayCodes",
        qsearch: false,
    },
    settlement_frequency: {
        id: "settlement_frequency",
        apiField: "settlement.frequency",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {},
        reference: "settlementFrequencyCodes",
        qsearch: false,
    },
    settlement_thresholdAmount: {
        id: "settlement_thresholdAmount",
        apiField: "settlement.thresholdAmount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {
            align: "left",
            displayCurrency: true,
        },
        qsearch: false,
    },
    settlement_startDate: {
        id: "settlement_startDate",
        apiField: "settlement.startDate",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {
            align: "left",
        },
        qsearch: false,
    },
    status: {
        id: "status",
        apiField: "statusCode",
        displayType: displayTypes.DISPLAY_STATUS,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: true,
        },
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        reference: "statusCodes",
        qsearch: false,
    },
    turnover: {
        id: "turnover",
        apiField: "turnover",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
            type: "numeric",
            precision: 2,
        },
        qsearch: false,
    },
    user_name: {
        id: "user_name",
        apiField: "memberships.user.fullName",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {
            multiple: true,
        },
        displayType: displayTypes.DISPLAY_AVATAR,
        displayOptions: {
            dataType: "memberships",
            indicator: true,
            sortable: false,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
    website_url: {
        id: "website_url",
        apiField: "websites.url",
        displayType: displayTypes.DISPLAY_LINK,
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayOptions: {
            sortable: false,
            dataType: "website_urls",
            indicator: true,
            lookup: true,
            exclude: false,
        },
        qsearch: false,
    },
};

const internalAttributes = {
    api_username: {
        id: "api_username",
        apiField: "apiCredentials.username",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {
            helperText: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayOptions: {
            sortable: true,
            dataType: "api_credentials",
            indicator: true,
            lookup: false,
            exclude: false,
        },
        qsearch: false,
        internal: true,
    },
    business_unit: {
        id: "business_unit",
        apiField: "businessUnitCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
        },
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
            ellipsis: "middle",
        },
        reference: "businessUnitCodes",
        qsearch: false,
        internal: true,
    },
    invoicing_status: {
        id: "invoicing_status",
        apiField: "invoicing.enabled",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        displayOptions: {
            sortable: false,
            lookup: true,
            exclude: false,
        },
        reference: "enableStatus",
        qsearch: false,
        internal: true,
    },
    settlement_day: {
        id: "settlement_day",
        apiField: "settlement.payDays",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        displayOptions: {
            dataType: "settlement_days",
            indicator: true,
            sortable: false,
            lookup: true,
            exclude: false,
        },
        reference: "settlementDays",
        qsearch: false,
        internal: true,
    },
    settlement_status: {
        id: "settlement_status",
        apiField: "settlement.enabled",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        displayOptions: {
            sortable: false,
            lookup: true,
            exclude: false,
        },
        reference: "enableStatus",
        qsearch: false,
        internal: true,
    },
};

const attributes = { ...externalAttributes, ...(isInternal ? internalAttributes : {}) };

export default attributes;
