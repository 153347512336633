import LazyLoad from "react-lazyload";
import { getCellHeight } from "../../../../constants/grid";
import RowParent from "./RowParent";

const placeholderContent = (
    <div
        style={{
            width: window.innerWidth,
            padding: "8px 6px",
            height: 40,
            position: "sticky",
            left: 0,
        }}
    >
        <div
            style={{
                backgroundColor: "#F5F5F5",
                width: "100%",
                height: "100%",
                borderRadius: 4,
            }}
        />
    </div>
);

export const LazyLoadingRow = ({ ...props }) => {
    return (
        <LazyLoad
            key={props.index}
            height={getCellHeight(props.dense)}
            once
            overflow
            offset={400}
            placeholder={placeholderContent}
        >
            <RowParent key={props.index} {...props} />
        </LazyLoad>
    );
};
