import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
    root: {
        ...theme.typography.body2,
        paddingTop: 8,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    },
}));

export default useStyles;
