import attributesBase from "./attributes-base";
import attributesInternal from "./attributes-internal";

const attributes = attributesBase;

if (
    undefined !== process.env.NX_IS_INTERNAL &&
    true === JSON.parse(process.env.NX_IS_INTERNAL.toLowerCase())
) {
    Object.keys(attributesInternal).forEach((key) => {
        attributesInternal[key] = {
            ...attributesInternal[key],
            internal: true,
        };
    });
    Object.assign(attributes, attributesInternal);
}

export default attributes;
