const authenticationResult = {
    id: "authentication_result",
    data: [
        {
            id: "Y",
            label: "ref.transaction.authentication_result.Y",
        },
        {
            id: "A",
            label: "ref.transaction.authentication_result.A",
        },
        {
            id: "U",
            label: "ref.transaction.authentication_result.U",
        },
        {
            id: "N",
            label: "ref.transaction.authentication_result.N",
        },
        {
            id: "E",
            label: "ref.transaction.authentication_result.E",
        },
        {
            id: "X",
            label: "ref.transaction.authentication_result.X",
        },
    ],
    translate: true,
};

export default authenticationResult;
