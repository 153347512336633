const payment_means_colors = {
    "3xcb": {
        colorPrimary: "#CCD900",
        colorSecondary: "#7BC200",
    },
    "3xcb-no-fees": {
        colorPrimary: "#CCD900",
        colorSecondary: "#7BC200",
    },
    "4xcb": {
        colorPrimary: "#CCD900",
        colorSecondary: "#7BC200",
    },
    "4xcb-no-fees": {
        colorPrimary: "#CCD900",
        colorSecondary: "#7BC200",
    },
    "american-express": { colorPrimary: "#009DDC" },
    aura: {
        colorPrimary: "#007BC3",
        colorSecondary: "#FFDD53",
    },
    banamex: {
        colorPrimary: "#02BDF2",
        colorSecondary: "#012D72",
    },
    "banco-do-brasil": { colorPrimary: "#F8D117" },
    bancontactqrcode: { colorPrimary: "#005498" },
    "bbva-bancomer": { colorPrimary: "#004B93" },
    bcmc: { colorPrimary: "#005498" },
    "bcmc-mobile": { colorPrimary: "#005498" },
    "belfius-directnet": { colorPrimary: "#D1104A" },
    "bnpp-3xcb": {
        colorPrimary: "#28B968",
        colorSecondary: "#1A7A4A",
    },
    "bnpp-4xcb": {
        colorPrimary: "#28B968",
        colorSecondary: "#1A7A4A",
    },
    "boleto-bancario": { colorPrimary: "#221819" },
    bradesco: {
        colorPrimary: "#D92031",
        colorSecondary: "#A3021E",
    },
    caixa: {
        colorPrimary: "#029BDC",
        colorSecondary: "#0273AF",
    },
    "carte-cadeau": { colorPrimary: "#8BBC06" },
    "carte-titre-restaurant": { colorPrimary: "#242563" },
    cb: {
        colorPrimary: "#19984F",
        colorSecondary: "#012C61",
    },
    "cbc-online": {
        colorPrimary: "#00AFF5",
        colorSecondary: "#00366C",
    },
    cofinoga: { colorPrimary: "" },
    "credit-long": {
        colorPrimary: "#7BC200",
        colorSecondary: "#CCD900",
    },
    cup: { colorPrimary: "#414344" },
    "dcb-be-mobistar": { colorPrimary: "#00ABE1" },
    "dcb-ch-easypay": { colorPrimary: "#9C9EA1" },
    "dcb-sg-singtel": { colorPrimary: "#9C9EA1" },
    "dexia-directnet": { colorPrimary: "#D1104A" },
    discover: { colorPrimary: "" },
    giropay: { colorPrimary: "#000268" },
    ideal: {
        colorPrimary: "#FD6EB6",
        colorSecondary: "#CC0066",
    },
    illicado: { colorPrimary: "" },
    "ing-homepay": { colorPrimary: "#FF6200" },
    itau: { colorPrimary: "#312782" },
    "kbc-online": {
        colorPrimary: "#00AFF5",
        colorSecondary: "#00366C",
    },
    klarnacheckout: {
        colorPrimary: "#F2A2AE",
        colorSecondary: "#EB6F93",
    },
    klarnainvoice: {
        colorPrimary: "#F2A2AE",
        colorSecondary: "#EB6F93",
    },
    maestro: {
        colorPrimary: "#0099DF",
        colorSecondary: "#D32011",
    },
    mastercard: {
        colorPrimary: "#F79E1B",
        colorSecondary: "#EB001B",
    },
    mbway: {
        colorPrimary: "#DA0000",
        colorSecondary: "#C50000",
    },
    "multi-payment": { colorPrimary: "" },
    multibanco: {
        colorPrimary: "#3B75C4",
        colorSecondary: "#295A9B",
    },
    mybank: {
        colorPrimary: "#1DC2F1",
        colorSecondary: "#1A4C68",
    },
    oxxo: { colorPrimary: "#DA3832" },
    paypal: {
        colorPrimary: "#0288CD",
        colorSecondary: "#02368C",
    },
    paysafecard: {
        colorPrimary: "#028ACA",
        colorSecondary: "#37A4DC",
    },
    payshop: { colorPrimary: "#DF0323" },
    payulatam: { colorPrimary: "#94BE3E" },
    "postfinance-card": { colorPrimary: "#FCD205" },
    "postfinance-efinance": { colorPrimary: "#FCD205" },
    przelewy24: { colorPrimary: "#E13034" },
    "qiwi-wallet": { colorPrimary: "#FFA303" },
    "santander-cash": { colorPrimary: "#EC0400" },
    "santander-home-banking": { colorPrimary: "#EC0400" },
    sct: { colorPrimary: "#0A4A8D" },
    sdd: { colorPrimary: "#0A4A8D" },
    "sepa-direct-debit": { colorPrimary: "#0A4A8D" },
    sisal: {
        colorPrimary: "#B7D232",
        colorSecondary: "#00643B",
    },
    "sofort-uberweisung": {
        colorPrimary: "#F2A2AE",
        colorSecondary: "#EB6F93",
    },
    tpe: { colorPrimary: "" },
    visa: {
        colorPrimary: "#EC982D",
        colorSecondary: "#263379",
    },
    "webmoney-transfer": { colorPrimary: "#0067A3" },
    wechatpay: { colorPrimary: "#00CF00" },
    yandex: { colorPrimary: "#E52620" },
};

export default payment_means_colors;
