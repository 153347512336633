const financialInstitutions = {
    id: "financial_institutions",
    endpoint: "/financial-institutions",
    organizeFunc: (institutions, p) => {
        // alphabetic sort
        return institutions.sort((a, b) => {
            return a.label === b.label ? 0 : a.label < b.label ? -1 : 1;
        });
    },
    withSelectedAccounts: true,
};

export default financialInstitutions;
