import { useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import { useP } from "../../../../services/i18n";
import { handleKeyValid } from "../../../../utils/keyboard";
import { useFavorites } from "../../../services/FavoritesContext";
import { useFavoriteQuery } from "../../../queries/favorites";
import PencilOff from "mdi-material-ui/PencilOff";
import Lock from "mdi-material-ui/Lock";
import Star from "mdi-material-ui/Star";
import StarHalfFull from "mdi-material-ui/StarHalfFull";
import { setEventTracker } from "../../../../services/tracker";

export const useStylesFavoriteChip = makeStyles((theme) => ({
    root: {
        margin: "0px 2px 2px 2px",
        cursor: "pointer",
        border: "1px solid",
        backgroundColor: theme.palette.neutral.contrastText,
        borderColor: theme.palette.neutral.main,
        color: theme.palette.neutral.main,
        "&:hover": {
            backgroundColor: theme.palette.neutral.main,
            borderColor: theme.palette.neutral.contrastText,
            color: theme.palette.neutral.contrastText,
        },
        "&:focus-within": { borderStyle: "dashed" },
        outline: "none",
    },
    active: {
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.contrastText,
        },
        "&:focus-within": { borderStyle: "dashed" },
    },
}));

export function FavoriteChip(props) {
    const { favoriteId } = props;

    const classes = useStylesFavoriteChip(props);
    const p = useP();
    const history = useHistory();
    const [{ selectedFavoriteId, selectedFavorite }, { setSelectedFavorite }] = useFavorites();

    const { data: favorite = {} } = useFavoriteQuery(favoriteId);

    // currently selected
    const active = favorite.favoriteId === selectedFavoriteId;

    // cannot be edited if its a predefined favorite
    const isEditable = !p.has(favorite.name);

    // could be updated with new search
    const hasUpdate = active && selectedFavorite.hasUpdate;

    const handleClick = useCallback(
        (e) => {
            if (!active) {
                setEventTracker("activate", {
                    event_category: "search_favorites",
                    event_label: favorite.favoriteId,
                });
                setSelectedFavorite(favorite);
            } else {
                setEventTracker("deactivate", {
                    event_category: "search_favorites",
                    event_label: favorite.favoriteId,
                });
                setSelectedFavorite(null);
            }
        },
        [active, favorite, history, setSelectedFavorite]
    );

    let favIcon = <Star />;
    let title;
    if (!isEditable) {
        favIcon = <PencilOff />;
        title = p.t("common.search.favorite.favorite_chip.predefined_title");
    } else if (favorite.internal) {
        favIcon = <Lock />;
        title = p.t("common.search.favorite.favorite_chip.internal_title");
    } else if (hasUpdate) {
        favIcon = <StarHalfFull />;
    }

    return (
        <HiChip
            id={`favorite-${favoriteId}`}
            className={classNames(classes.root, { [classes.active]: active })}
            tabIndex="0"
            role="button"
            onClick={handleClick}
            onKeyDown={handleKeyValid(handleClick)}
            icon={favIcon}
            label={p.has(favorite.name) ? p.t(favorite.name) : favorite.name}
            title={title}
        />
    );
}

FavoriteChip.propTypes = {
    favoriteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

FavoriteChip.defaultProps = {};
