import React from "react";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import PropTypes from "prop-types";

const stylesCellLink = (theme) => ({
    root: {
        width: "100%",
        margin: "auto 0",
        display: "flex",
        verticalAlign: "middle",
    },
    label: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    link: {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
});

/**
 * CellText functional component
 * (don't includes complex ellipsis: middle, after-first-word...)
 *
 * @param label
 * @param color
 * @param value
 * @param active
 * @param title
 * @param view
 * @returns {*}
 * @constructor
 */
class CellLinkClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Label
         */
        label: PropTypes.string,
        /**
         * Title
         */
        title: PropTypes.string,
        /**
         * Link value
         */
        value: PropTypes.string,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    render() {
        const { classes, label, value, title = "", view, onClick, className } = this.props;

        // reformat the data provided like "http://test.com www.test.com"
        const values = value ? value.split(" ") : [];
        // prevent missing http(s) to link on our domain
        const validUrl = values[0]
            ? values[0].substring(0, 4) === "http"
                ? values[0]
                : `//${values[0]}`
            : "";
        const linkLabel = label ? label.toString() : values[0];
        const isTitle = title ? title : values[0];

        const handleClick = (e) => {
            e.stopPropagation();
            onClick && onClick(e);
        };

        return (
            <div
                className={classNames(classes.root, className)}
                title={view === "s" ? isTitle : title ? title : undefined}
                onClick={handleClick}
            >
                {onClick && (
                    <div className={classNames(classes.label, classes.link)} onClick={onClick}>
                        {linkLabel}
                    </div>
                )}
                {!onClick && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames(classes.label, classes.link)}
                        href={validUrl}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {linkLabel}
                    </a>
                )}
            </div>
        );
    }
}

export const CellLink = withStyles(stylesCellLink)(CellLinkClass);
