import { Switch } from "react-router-dom";

import { Route } from "./Route";
import { App } from "../../app/App";

export const ServiceRouter = ({ routing }) => {
    return (
        <Switch>
            {routing && routing.map(Route)}
            <Route component={App} />
        </Switch>
    );
};
