export default {
    id: "settlement_sepa",
    data: [
        {
            id: "true",
            label: "ref.financial_report.settlement_sepa.true",
            img: "/assets/financial_report/img/sepa.svg",
        },
        {
            id: "false",
            label: "ref.financial_report.settlement_sepa.false",
        },
    ],
    translate: true,
};
