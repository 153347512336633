import { useContext } from "react";
import { InnerPageContext } from "./InnerPage.context";

export function useInnerPage() {
    return useContext(InnerPageContext);
}

export const withInnerPage = (WrappedComponent) => (props) =>
    (
        <InnerPageContext.Consumer>
            {(value) => <WrappedComponent {...props} {...value} />}
        </InnerPageContext.Consumer>
    );
