import { forwardRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FavoriteChip } from "./FavoriteChip";
import { LoadingFavoritesBar } from "./LoadingFavoritesBar";
import { useFavoritesState } from "../../../services/FavoritesContext";
import { useFavoritesQuery } from "../../../queries/favorites";
import { useP } from "../../../../services/i18n";

const useStylesFavoriteBar = makeStyles((theme) => ({
    root: {
        minHeight: 88,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },
    title: {
        ...theme.typography.h3,
        width: "100%",
        height: 48,
        display: "flex",
        alignItems: "center",
        color: theme.palette.neutral.dark,
        padding: "0 24px",
    },
    list: {
        display: "flex",
        padding: "0 22px",
        flexWrap: "wrap",
        alignItems: "center",
        minHeight: 40,
        maxWidth: "100%",
        width: "100%",
    },
}));

export const FavoritesBar = forwardRef((props, ref) => {
    const classes = useStylesFavoriteBar();
    const p = useP();

    const { displayed } = useFavoritesState();

    const { data, status } = useFavoritesQuery();

    if (!displayed || !data || data.length === 0) {
        return null;
    }

    return (
        <div id="favorites-bar" className={classes.root} ref={ref}>
            <div className={classes.title}>{p.t("common.search.favorite.title")}</div>
            {status === "loading" ? (
                <LoadingFavoritesBar />
            ) : (
                <div id="favorites-bar-list" className={classes.list}>
                    {data.map((favorite) => (
                        <FavoriteChip
                            key={`favorite${favorite.favoriteId}`}
                            favoriteId={favorite.favoriteId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
});

FavoritesBar.propTypes = {};

FavoritesBar.defaultProps = {};
