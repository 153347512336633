export default {
    id: "merchant_bank_countries",
    endpoint: `${process.env.NX_FINANCIAL_OPERATION_API_URL}/settlement.bank.creditor_bank_country/aggregated`,
    withSelectedAccounts: true,
    translate: true,
    reduceFunc: (previous, current) =>
        current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"],
                      label: `ref.transaction.countries.${current["id"]}`,
                      shortLabel: current["label"],
                      img: `/assets/core/img/references/countries/${current[
                          "id"
                      ].toLowerCase()}.svg`,
                      fallbackImage: "/assets/core/img/references/countries/no-country.svg",
                  },
              ]
            : previous,
};
