const itemTypes = {
    id: "item_types",
    data: [
        {
            id: "good",
            label: "ref.transaction.item_types.good",
        },
        {
            id: "fee",
            label: "ref.transaction.item_types.fee",
        },
        {
            id: "discount",
            label: "ref.transaction.item_types.discount",
        },
    ],
    translate: true,
};

export default itemTypes;
