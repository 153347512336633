const operationColor = {
    final_ok: "primary",
    final_ko: "error",
    various: "info",
    intermediate: "secondary",
};

export default {
    id: "financial_operation_types",
    data: [
        {
            id: "payment_operation_types",
            label: "ref.financial_report.financial_operation_types_group.payment",
            children: [
                {
                    id: "SALE",
                    label: "ref.financial_report.financial_operation_types.SALE",
                    color: operationColor.final_ok,
                },
                {
                    id: "REF",
                    label: "ref.financial_report.financial_operation_types.REF",
                    color: operationColor.intermediate,
                },
                {
                    id: "CHGBCK",
                    label: "ref.financial_report.financial_operation_types.CHGBCK",
                    color: operationColor.final_ko,
                },
                {
                    id: "CHGBCK_REF",
                    label: "ref.financial_report.financial_operation_types.CHGBCK_REF",
                    color: operationColor.final_ok,
                },
                {
                    id: "CARDHOLDERCREDIT",
                    label: "ref.financial_report.financial_operation_types.CARDHOLDERCREDIT",
                    color: operationColor.intermediate,
                },
                {
                    id: "MCH_CREDIT",
                    label: "ref.financial_report.financial_operation_types.MCH_CREDIT",
                    color: operationColor.final_ok,
                },
            ],
        },
        {
            id: "fees",
            label: "ref.financial_report.financial_operation_types_group.fees",
            children: [
                {
                    id: "PERCENT",
                    label: "ref.financial_report.financial_operation_types.PERCENT",
                    color: operationColor.various,
                },
                {
                    id: "FEE",
                    label: "ref.financial_report.financial_operation_types.FEE",
                    color: operationColor.various,
                },
                {
                    id: "BONUS",
                    label: "ref.financial_report.financial_operation_types.BONUS",
                    color: operationColor.various,
                },
                {
                    id: "FINE",
                    label: "ref.financial_report.financial_operation_types.FINE",
                    color: operationColor.various,
                },
                {
                    id: "SRVCHG",
                    label: "ref.financial_report.financial_operation_types.SRVCHG",
                    color: operationColor.various,
                },
                {
                    id: "SRVFEE",
                    label: "ref.financial_report.financial_operation_types.SRVFEE",
                    color: operationColor.various,
                },
                {
                    id: "INTERCHANGE",
                    label: "ref.financial_report.financial_operation_types.INTERCHANGE",
                    color: operationColor.various,
                },
                {
                    id: "SCHEMEFEE",
                    label: "ref.financial_report.financial_operation_types.SCHEMEFEE",
                    color: operationColor.various,
                },
                {
                    id: "INVPERCENT",
                    label: "ref.financial_report.financial_operation_types.INVPERCENT",
                    color: operationColor.various,
                },
                {
                    id: "INVFEE",
                    label: "ref.financial_report.financial_operation_types.INVFEE",
                    color: operationColor.various,
                },
            ],
        },
        {
            id: "settlement",
            label: "ref.financial_report.financial_operation_types_group.settlement",
            children: [
                {
                    id: "SETTLE",
                    label: "ref.financial_report.financial_operation_types.SETTLE",
                    color: operationColor.final_ok,
                },
                {
                    id: "SPLITTING_SETTLEMENT",
                    label: "ref.financial_report.financial_operation_types.SPLITTING_SETTLEMENT",
                    color: operationColor.final_ok,
                },
                {
                    id: "SPLIT_SETTLEMENT",
                    label: "ref.financial_report.financial_operation_types.SPLIT_SETTLEMENT",
                    color: operationColor.final_ok,
                },
                {
                    id: "DEFERRED_SETTLEMENT",
                    label: "ref.financial_report.financial_operation_types.DEFERRED_SETTLEMENT",
                    color: operationColor.final_ok,
                },
                {
                    id: "ASSET_SETTLEMENT",
                    label: "ref.financial_report.financial_operation_types.ASSET_SETTLEMENT",
                    color: operationColor.final_ok,
                },
                {
                    id: "AD_SETTLE_FX_OUT",
                    label: "ref.financial_report.financial_operation_types.AD_SETTLE_FX_OUT",
                    color: operationColor.final_ok,
                },
                {
                    id: "AD_SETTLE_FX_IN",
                    label: "ref.financial_report.financial_operation_types.AD_SETTLE_FX_IN",
                    color: operationColor.final_ok,
                },
                {
                    id: "ALRSETTLE",
                    label: "ref.financial_report.financial_operation_types.ALRSETTLE",
                    color: operationColor.final_ok,
                },
            ],
        },
        {
            id: "invoice",
            label: "ref.financial_report.financial_operation_types_group.invoice",
            children: [
                {
                    id: "INVPMT",
                    label: "ref.financial_report.financial_operation_types.INVPMT",
                    color: operationColor.various,
                },
                {
                    id: "INVREPORT",
                    label: "ref.financial_report.financial_operation_types.INVREPORT",
                    color: operationColor.various,
                },
                {
                    id: "INVASSET",
                    label: "ref.financial_report.financial_operation_types.INVASSET",
                    color: operationColor.various,
                },
            ],
        },
        {
            id: "reserve",
            label: "ref.financial_report.financial_operation_types_group.reserve",
            children: [
                {
                    id: "ROLRLS",
                    label: "ref.financial_report.financial_operation_types.ROLRLS",
                    color: operationColor.various,
                },
                {
                    id: "FIXCPT",
                    label: "ref.financial_report.financial_operation_types.FIXCPT",
                    color: operationColor.various,
                },
                {
                    id: "ROLCPT",
                    label: "ref.financial_report.financial_operation_types.ROLCPT",
                    color: operationColor.various,
                },
                {
                    id: "FIXRLS",
                    label: "ref.financial_report.financial_operation_types.FIXRLS",
                    color: operationColor.various,
                },
            ],
        },
        {
            id: "treasury",
            label: "ref.financial_report.financial_operation_types_group.treasury",
            children: [
                {
                    id: "TRFFROM",
                    label: "ref.financial_report.financial_operation_types.TRFFROM",
                    color: operationColor.various,
                },
                {
                    id: "TRFTO",
                    label: "ref.financial_report.financial_operation_types.TRFTO",
                    color: operationColor.final_ok,
                },
            ],
        },
    ],
    hierarchic: false,
    translate: true,
};
