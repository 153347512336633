const refundReasons = {
    id: "refund_reasons",
    data: [
        {
            id: "cancelled-but-invoiced",
            label: "ref.transaction.refund_reasons.cancelled-but-invoiced",
        },
        {
            id: "defective",
            label: "ref.transaction.refund_reasons.defective",
        },
        {
            id: "damaged",
            label: "ref.transaction.refund_reasons.damaged",
        },
        {
            id: "missing",
            label: "ref.transaction.refund_reasons.missing",
        },
        {
            id: "not-suitable",
            label: "ref.transaction.refund_reasons.not-suitable",
        },
        {
            id: "package-not-received",
            label: "ref.transaction.refund_reasons.package-not-received",
        },
        {
            id: "package-lost",
            label: "ref.transaction.refund_reasons.package-lost",
        },
        {
            id: "client-error",
            label: "ref.transaction.refund_reasons.client-error",
        },
        {
            id: "merchant-error",
            label: "ref.transaction.refund_reasons.merchant-error",
        },
        {
            id: "commercial-operation",
            label: "ref.transaction.refund_reasons.commercial-operation",
        },
        {
            id: "missing-parts",
            label: "ref.transaction.refund_reasons.missing-parts",
        },
        {
            id: "gift-card-issue",
            label: "ref.transaction.refund_reasons.gift-card-issue",
        },
        {
            id: "delivery-time-too-long",
            label: "ref.transaction.refund_reasons.delivery-time-too-long",
        },
        {
            id: "best-price-found",
            label: "ref.transaction.refund_reasons.best-price-found",
        },
        {
            id: "suspected-fraud",
            label: "ref.transaction.refund_reasons.suspected-fraud",
        },
        {
            id: "other",
            label: "ref.transaction.refund_reasons.other",
        },
    ],
    translate: true,
};

export default refundReasons;
