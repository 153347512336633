import ContentLoader from "react-content-loader";

export const Loading = (props) => {
    const width = window.innerWidth;
    let height = window.innerHeight - 295;

    return (
        <div
            style={{
                position: "sticky",
                left: 0,
                height: height,
                width: width,
                backgroundColor: "#fff",
            }}
        >
            <ContentLoader
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                height={height}
                width={width}
            >
                <rect x={16} y={8} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={48} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={88} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={128} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={168} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={208} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={248} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={288} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={328} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={368} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={408} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={448} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={488} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={528} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={568} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={608} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={648} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={688} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={728} rx="3" ry="3" width={width - 32} height={24} />
                <rect x={16} y={768} rx="3" ry="3" width={width - 32} height={24} />
            </ContentLoader>
        </div>
    );
};
