import static_datas, { internal as static_datas_internal } from "./static_datas";
import dynamic_datas, { internal as dynamic_datas_internal } from "./dynamic_datas";

if (
    undefined !== process.env.NX_IS_INTERNAL &&
    true === JSON.parse(process.env.NX_IS_INTERNAL.toLowerCase())
) {
    Object.assign(dynamic_datas, dynamic_datas_internal);
    Object.assign(static_datas, static_datas_internal);
}

export default {
    ...static_datas,
    ...dynamic_datas,
};
