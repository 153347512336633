import { ucFirst } from "@console/core";

const cancelAuthors = {
    id: "cancel_authors",
    endpoint: "/cancel_authors",
    withSelectedAccounts: true,
    reduceFunc: (previous, current) => {
        return current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"].toString(),
                      label: ucFirst(current["firstname"]) + " " + ucFirst(current["surname"]),
                      firstname: current["firstname"] ? ucFirst(current["firstname"]) : null,
                      surname: current["surname"] ? ucFirst(current["surname"]) : null,
                  },
              ]
            : previous;
    },
};

export default cancelAuthors;
