import React from "react";
import Lock from "mdi-material-ui/Lock";
import LockOutline from "mdi-material-ui/LockOutline";
import LockOpenOutline from "mdi-material-ui/LockOpenOutline";
import LockOffOutlineIcon from "../../components/Icon/LockOffOutlineIcon";

const authStatus = {
    id: "auth_status",
    data: [
        {
            id: "no_authentication",
            label: "ref.transaction.auth_status.no_authentication",
            icon: <LockOffOutlineIcon color="neutral" />,
        },
        {
            id: "authenticable",
            label: "ref.transaction.auth_status.authenticable",
            icon: <LockOutline color="neutral" />,
        },
        {
            id: "not_authenticable",
            label: "ref.transaction.auth_status.not_authenticable",
            icon: <LockOpenOutline color="neutral" />,
        },
        {
            id: "authentication_requested",
            label: "ref.transaction.auth_status.authentication_requested",
            icon: <Lock color="neutral" />,
        },
        {
            id: "authentication_attempted",
            label: "ref.transaction.auth_status.authentication_attempted",
            icon: <Lock color="warning" />,
        },
        {
            id: "successful_authentication",
            label: "ref.transaction.auth_status.successful_authentication",
            icon: <Lock color="success" />,
        },
        {
            id: "authentication_failed",
            label: "ref.transaction.auth_status.authentication_failed",
            icon: <Lock color="error" />,
        },
        {
            id: "authentication_impossible",
            label: "ref.transaction.auth_status.authentication_impossible",
            icon: <Lock color="error" />,
        },
        {
            id: "enrollment_unknown",
            label: "ref.transaction.auth_status.enrollment_unknown",
            icon: <Lock color="error" />,
        },
    ],
    translate: true,
};

export default authStatus;
