import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

import makeStyles from "@mui/styles/makeStyles";
import ButtonBase from "@mui/material/ButtonBase";
import FilterList from "@mui/icons-material/FilterList";
import Menu from "@mui/icons-material/Menu";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import ColumnFilter from "./ColumnFilter";
import ColumnFormat from "./ColumnFormat";
import { AmountColumnMenu } from "./AmountColumnMenu";
import { ALIGN_RIGHT_TYPES, DISPLAY_AMOUNT, getDefaultWidth } from "../../../../../constants";
import { useP, useModule, buildArgs, getReferenceAsOptions } from "../../../../../../services";

const useStylesCell = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        justifyContent: "flex-start",
        paddingLeft: 8,
        paddingRight: 8,
        overflow: "hidden",
        textAlign: "left",
        verticalAlign: "middle",
        backgroundColor: theme.palette.background3,
        "&:focus": { outline: "-webkit-focus-ring-color auto 5px" },
    },
    noCursor: { cursor: "inherit" },
    sticky: {
        position: "sticky",
        left: 0,
        paddingLeft: 16,
    },
    stickyWithRank: {
        position: "sticky",
        left: "40px !important",
    },
    stickyWithSelector: { left: "40px !important" },
    stickyWithSelectorAndRank: { left: "80px !important" },
    sorted: { borderBottom: `1px solid ${theme.palette.primary.main}` },
    alignRight: { justifyContent: "flex-end" },
    labelContainer: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...theme.typography.body3,
        lineHeight: "12px",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.neutral.dark,
        display: "flex",
        alignItems: "center",
    },
    labelContainerOrdered: { fontWeight: theme.typography.fontWeightMedium },
    textLabel: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    internalIcon: {
        marginRight: theme.spacing(),
        marginBottom: 2,
    },
    icon: {
        height: 16,
        margin: "0 4px",
        transition: theme.transitions.create(["opacity", "transform"], {
            duration: theme.transitions.duration.shorter,
        }),
        userSelect: "none",
        width: 16,
        color: theme.palette.info.main,
    },
    primary: { color: theme.palette.primary.main },
    sortdesc: { transform: "rotate(0deg)" },
    sortasc: { transform: "rotate(180deg)" },
    lookedUp: { backgroundColor: theme.palette.lookup.main },
    divCell: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
}));

export const Cell = (props) => {
    /**
     * Construit une cellule d'entête avec le titre, clickable, triable et filtrable
     */
    const {
        attribute,
        colId,
        columnList,
        defaultSortParams,
        fetchAmountStats,
        filterable: attributeFilterable,
        formParam,
        formParamAccounts,
        handleColumnsOrderUpdate,
        hasColumnParam,
        index,
        isDisplaySelector,
        isDisplayRank,
        lookedUp,
        module,
        onClickBackToTop,
        onFilterChange,
        onSort,
        selectedFilterIdList,
        sortable,
        sorted,
        sortDirection,
        view,
        width,
    } = props;

    const references = useSelector((state) => state.ref);
    const globalSelectedAccountIdList = useSelector(
        (state) => state.app.global.selectedAccountIdList
    );

    const accountIdList = formParamAccounts || globalSelectedAccountIdList;

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortCol, setCurrentSortCol] = useState(null);
    const { references: moduleReferences } = useModule();

    const classes = useStylesCell();
    const p = useP();

    const openFilterMenu = useCallback(
        (event) => {
            onClickBackToTop();
            setOpen(true);
            setAnchorEl(event.currentTarget);
        },
        [onClickBackToTop]
    );

    const handleClose = useCallback((event) => {
        event.stopPropagation();
        setOpen(false);
        setAnchorEl(null);
    }, []);

    const handleFilterChange = useCallback(
        (itemIdList) => {
            onFilterChange(colId, itemIdList);
        },
        [colId, onFilterChange]
    );

    const handleClick = useCallback(() => {
        if (onSort) {
            if (sortable) {
                if (sortDirection === "asc" && currentSortCol === colId) {
                    onSort(colId, "desc");
                } else {
                    onSort(colId, "asc");
                }
            }
            setCurrentSortCol(colId);
        }
    }, [colId, currentSortCol, onSort, sortable, sortDirection]);

    const handleSort = useCallback(
        (direction) => {
            if (onSort) {
                if (direction === false) {
                    // default column sort
                    // TODO => constant
                    onSort(defaultSortParams.order_by, defaultSortParams.direction);
                } else {
                    onSort(colId, direction);
                }
            }
        },
        [colId, defaultSortParams, onSort]
    );

    if (attribute === undefined) {
        return null;
    }

    const sortIconClassName = classNames(classes.icon, classes.primary, {
        [classes[`sort${sortDirection}`]]: !!sortDirection,
    });

    let filterOptionList;
    if (attribute && attribute.reference) {
        const args = buildArgs(accountIdList);
        filterOptionList = getReferenceAsOptions(
            references,
            attribute.reference,
            p,
            args,
            moduleReferences
        );
    }

    const amountColumn = attribute.displayType === DISPLAY_AMOUNT;
    const filterable =
        amountColumn || (attributeFilterable && filterOptionList && filterOptionList.length >= 1);
    const formatable =
        attribute.displayOptions.formatable !== undefined
            ? attribute.displayOptions.formatable
            : false;

    const filterableORformatable = filterable || formatable;
    const content = (
        <>
            <span
                className={classNames(classes.labelContainer, {
                    [classes.labelContainerOrdered]: sortable && sorted,
                })}
            >
                {attribute.internal && (
                    <HiIcon
                        className={classes.internalIcon}
                        title={p.t("common.search.columns.internal")}
                        size={12}
                        icon="lock"
                        color="neutral"
                    />
                )}
                <span className={classes.textLabel}>
                    {view === "s"
                        ? p.t(`attributes.${module}.${colId}.smallLabel`)
                        : view === "m"
                        ? p.t(`attributes.${module}.${colId}.mediumLabel`)
                        : p.t(`attributes.${module}.${colId}.tableLabel`)}
                </span>
            </span>
            {!isMobile && filterableORformatable && sortable && sorted && (
                <FilterList className={sortIconClassName} />
            )}
            {!isMobile && filterableORformatable && !sorted && (
                <Menu className={classNames(classes.icon, { [classes.primary]: hasColumnParam })} />
            )}
            {!isMobile && !filterableORformatable && sortable && sorted && (
                <ArrowDownward className={sortIconClassName} />
            )}
            {!amountColumn && filterable && (
                <ColumnFilter
                    title={p.t(`attributes.${module}.${colId}.label`)}
                    selectedFilterIdList={selectedFilterIdList || formParam}
                    filterOptionList={filterOptionList}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    onSort={handleSort}
                    onFilterChange={handleFilterChange}
                    sortable={attribute.displayOptions.sortable !== false}
                    {...attribute.formFieldOptions} // type, sort...
                    sorted={sorted}
                    sortDirection={sortDirection}
                    displayType={attribute.displayType}
                />
            )}
            {!amountColumn && formatable && (
                <ColumnFormat
                    attribute={attribute}
                    title={p.t(`attributes.${module}.${colId}.label`)}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    sortable={attribute.displayOptions.sortable !== false}
                    sorted={sorted}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                    isCountry={attribute.reference === "countries"}
                    columnList={columnList}
                    handleColumnsOrderUpdate={handleColumnsOrderUpdate}
                    isDisplayRank={isDisplayRank}
                    isDisplaySelector={isDisplaySelector}
                />
            )}
            {amountColumn && (
                <AmountColumnMenu
                    attribute={attribute}
                    title={p.t(`attributes.${module}.${colId}.label`)}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    onSort={handleSort}
                    fetchAmountStats={fetchAmountStats}
                    columnList={columnList}
                    handleColumnsOrderUpdate={handleColumnsOrderUpdate}
                    isDisplayRank={isDisplayRank}
                    isDisplaySelector={isDisplaySelector}
                    sorted={sorted}
                    sortDirection={sortDirection}
                />
            )}
        </>
    );

    const cellClasses = classNames(classes.root, {
        // eslint-disable-next-line no-useless-computed-key
        ["sticky-left"]: !isMobile && index === 0,
        [classes.sticky]: !isMobile && index === 0,
        [classes.stickyWithSelector]:
            !isMobile && index === 0 && isDisplaySelector && !isDisplayRank,
        [classes.stickyWithRank]: !isMobile && index === 0 && !isDisplaySelector && isDisplayRank,
        [classes.stickyWithSelectorAndRank]:
            !isMobile && index === 0 && isDisplaySelector && isDisplayRank,
        [classes.sorted]: sortable && sorted,
        [classes.alignRight]: ALIGN_RIGHT_TYPES.includes(attribute.displayType),
        [classes.lookedUp]: lookedUp,
        [classes.noCursor]: !sortable && !filterable,
        [classes.divCell]: isMobile || (!sortable && !filterable),
    });

    if (isMobile || (!sortable && !filterable)) {
        return (
            <div
                key={`header-${colId}`}
                id={`header-${colId}`}
                className={cellClasses}
                style={{
                    width: width || getDefaultWidth(attribute.displayType, view),
                    zIndex: 13,
                }}
                title={p.t(`attributes.${module}.${colId}.tableLabel`)}
            >
                {content}
            </div>
        );
    }

    return (
        <ButtonBase
            name={`header-${colId}`}
            id={`header-${colId}`}
            className={cellClasses}
            onClick={filterableORformatable ? openFilterMenu : handleClick}
            style={{
                width: width || getDefaultWidth(attribute.displayType, view),
                zIndex: 13,
            }}
            title={p.t(`attributes.${module}.${colId}.tableLabel`)}
            disabled={isMobile || (!sortable && !filterable)}
        >
            {content}
        </ButtonBase>
    );
};

Cell.propTypes = {
    /**
     * Liste des ids de compte sélectionnés
     */
    accountIdList: PropTypes.array,
    /**
     * Attribut de la colonne
     */
    attribute: PropTypes.object,
    /**
     * Liste des colonnes du tableau de recherche.
     */
    columnList: PropTypes.array,
    /**
     * Fonction asynchrone permettant de récupérer les stats d'une colonne numerique de type montant
     */
    fetchAmountStats: PropTypes.func,
    /**
     * Paramètre displayOptions indiquant si la colonne peut être filtrée
     */
    filterable: PropTypes.bool,
    /**
     * Paramètre du formulaire pour le champ correspondant à la colonne
     */
    formParam: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    /**
     * Id des comptes sélectionnés dans le formulaire
     * (Utilisé dans le container)
     */
    formParamAccounts: PropTypes.array,
    /**
     * Fonction de màj de la configuration des colonnes (sans sauvegarde en BDD).
     */
    handleColumnsOrderUpdate: PropTypes.func,
    /**
     * Colonne a un "columnParam" actif pour colorer le burger de la colonne en bleu
     */
    hasColumnParam: PropTypes.bool,
    /**
     * True si la colonne Rank est affichée
     */
    isDisplayRank: PropTypes.bool,
    /**
     * True si la colonne de sélection est affichée
     */
    isDisplaySelector: PropTypes.bool,
    /**
     * Nom du module
     */
    module: PropTypes.string.isRequired,
    /**
     * Fonction de callback au changement du filtre - passe la liste des id des valeurs incluses
     */
    onFilterChange: PropTypes.func,
    /**
     * Fonction de callback au tri - passe la direction du tri
     */
    onSort: PropTypes.func,
    /**
     * Fonction pour scroller en haut des résultats
     */
    onClickBackToTop: PropTypes.func,
    /**
     * True si la colonne est la première colonne et doit avoir un comportement "sticky" lors du scroll horizontal
     */
    sticky: PropTypes.bool,
    /**
     * Sens du tri
     */
    sortDirection: PropTypes.oneOf(["asc", "desc"]),
    /**
     * Tri actif / inactif
     */
    sorted: PropTypes.bool,
    /**
     * View (L/M/S)
     */
    view: PropTypes.oneOf(["l", "m", "s"]),
};
Cell.defaultProps = {
    filterable: true,
    sortable: true,
    sorted: false,
    sortDirection: "asc",
    view: "l",
    dense: false,
    sticky: true,
    hasColumnParam: false,
};
