import React, { useEffect, useMemo } from "react";
import { useMount } from "react-use";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { ExportListItem } from "./Item";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import { useP } from "../../../../../services/i18n/index";
import MessageAlert from "mdi-material-ui/MessageAlert";
import { useInnerPage } from "../../../../../services/innerPage";
import { LoadingExport } from "./Loading";
import { useModule } from "../../../../../services/module";
import { useModuleConfig } from "../../../../../services/config";

const useStylesList = makeStyles((theme) => ({
    root: {
        paddingTop: 40,
        maxWidth: 740,
        margin: "auto",
    },
    createExportButton: {
        width: "100%",
        margin: "22px 0px",
        pointerEvents: "auto !important", // Allow title on disabled
    },
    emptyListContainer: { position: "relative" },
    emptyListContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        top: "150px",
        left: "50%",
        transform: "translateX(-50%)",
    },
    emptyListIcon: {
        color: "#AFB0B1",
        width: "194px !important",
        height: "194px !important",
    },
    emptyListLabel: {
        color: theme.palette.neutral.dark,
        ...theme.typography.body2,
    },
}));

/**
 *
 * onCreateExport - redirect to export form with previous search params
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function ExportList(props) {
    const { onCreateExport, formComponent, totalHits } = props;

    const module = useModule();
    const moduleConfig = useModuleConfig(module.id);
    const limitExport = useMemo(() => {
        return moduleConfig?.export?.limitEmailExport || process.env.NX_LIMIT_EXPORT;
    }, [moduleConfig]);

    const classes = useStylesList();
    const p = useP();

    const { closeInnerPage } = useInnerPage();

    const exportList = useSelector((state) => state.app.global.entities.export);

    const [openItemId, setOpenItemId] = React.useState(
        exportList.length === 1 ? exportList[0]["exportId"] : null
    );

    useEffect(() => {
        return function cleanup() {
            setOpenItemId(null);
            window.location.hash = "";
        };
    }, []);

    /*
     * Toggle item
     */
    const toggleItem = (itemId) => () => {
        if (itemId === openItemId) {
            window.location.hash = "";
            setOpenItemId(false);
        } else {
            window.location.hash = `exportId=${itemId}`;
            setOpenItemId(itemId);
        }
    };

    useMount(() => {
        setTimeout(() => {
            // focus of first element
            const firstItem = document.querySelector("#export-list > div > div");
            if (firstItem) {
                firstItem.focus();
                if (exportList?.data?.length === 1) {
                    setOpenItemId(parseInt(firstItem.dataset.exportId, 10));
                    window.location.hash = `exportId=${firstItem.dataset.exportId}`;
                }
            }
        }, 100);
    });

    useEffect(() => {
        if (exportList?.data.length && !!window.location.hash && !openItemId) {
            const hash = window.location.hash.substr(1);
            if (hash.split("=")[0] === "exportId") {
                setOpenItemId(parseInt(hash.split("=")[1], 10));
            }
        }
    }, [exportList.loading]);

    const handleKeyDown = (exportId) => (event) => {
        // Enter or Space
        if (event.key === "Enter" || event.keyCode === 32) {
            event.preventDefault();
            toggleItem(exportId)();
        }
    };

    const exportButtonDisabled = totalHits > limitExport;
    const exportButtonTooltipMessage = exportButtonDisabled
        ? p.t("common.search.export.list.create_export_tooltip", { export_limit: limitExport })
        : "";

    if (exportList?.loading) {
        return <LoadingExport />;
    } else if (exportList?.data?.length === 0) {
        return (
            <div className={classes.emptyListContainer}>
                <div className={classes.emptyListContent}>
                    <MessageAlert className={classes.emptyListIcon} />
                    <span className={classes.emptyListLabel}>
                        {p.t("common.search.export.list.no_export")}
                    </span>
                    <HiButton
                        id="add-export"
                        className={classes.createExportButton}
                        onClick={onCreateExport}
                        variant="text"
                        color="primary"
                        disabled={exportButtonDisabled}
                        title={exportButtonTooltipMessage}
                    >
                        {p.t("common.search.export.list.create_export")}
                    </HiButton>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root} id="export-list">
            {exportList?.data?.map((exportItem) => (
                <ExportListItem
                    key={exportItem.exportId}
                    active={openItemId === exportItem.exportId}
                    exportItem={exportItem}
                    onClose={closeInnerPage}
                    onClick={toggleItem(exportItem.exportId)}
                    onKeyDown={handleKeyDown(exportItem.exportId)}
                    onCreateExport={onCreateExport}
                    formComponent={formComponent}
                />
            ))}
            <div
                title={
                    !onCreateExport
                        ? p.t("common.search.export.menu.create_recurring_export.title_disabled")
                        : p.t("common.search.export.menu.create_recurring_export.title")
                }
            >
                <HiButton
                    id="add-export"
                    className={classes.createExportButton}
                    onClick={onCreateExport}
                    variant="text"
                    color="primary"
                    disabled={exportButtonDisabled || !onCreateExport}
                    title={exportButtonTooltipMessage}
                >
                    {p.t("common.search.export.list.create_export")}
                </HiButton>
            </div>
        </div>
    );
}
