import { useContext } from "react";
import { ScrollbarsProvider } from "./scrollbars";
import { ScrollbarsContext } from "./scrollbars-context";

/**
 * Scrollbars context
 * - enhance children inside react-custom-scrollbars
 * - add 'scrolled' state
 * - add 'scrollTo' function
 */
const useScrollbars = () => useContext(ScrollbarsContext);

export { ScrollbarsProvider, useScrollbars };
