import { useContext } from "react";
import {
    SearchSelectionActionsContext,
    SearchSelectionStateContext,
} from "./SearchSelection.context";

export function useSearchSelectionState() {
    const context = useContext(SearchSelectionStateContext);

    if (context === undefined) {
        throw new Error("useSearchSelectionState must be used within a SearchSelectionProvider");
    }

    return context;
}

export function useSearchSelectionActions() {
    const context = useContext(SearchSelectionActionsContext);

    if (context === undefined) {
        throw new Error("useSearchSelectionActions must be used within a SearchSelectionProvider");
    }

    return context;
}

export function useSearchSelection() {
    return [useSearchSelectionState(), useSearchSelectionActions()];
}
