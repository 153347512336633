import { formFieldTypes, displayTypes } from "@console/core";

const attributes = {
    code: {
        id: "code",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            filterable: true,
            lookup: true,
            exclude: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        reference: "user_roles",
    },
    type: {
        id: "type",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            filterable: true,
            lookup: true,
            exclude: false,
        },
        formFieldOptions: {
            hasEmptyOption: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        reference: "role_type",
    },
    description: {
        id: "description",
        displayType: displayTypes.DISPLAY_LONG_TEXT,
        displayOptions: {
            sortable: false,
            filterable: false,
            exclude: false,
            lookup: false,
        },
        reference: "role_description",
    },
    number_permissions: {
        id: "number_permissions",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            filterable: false,
        },
    },
};

export default attributes;
