const countries = {
    id: "countries",
    data: [
        {
            id: "AF",
            label: "ref.transaction.countries.AF",
            shortLabel: "AF",
            img: "/assets/core/img/references/countries/af.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AL",
            label: "ref.transaction.countries.AL",
            shortLabel: "AL",
            img: "/assets/core/img/references/countries/al.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AQ",
            label: "ref.transaction.countries.AQ",
            shortLabel: "AQ",
            img: "/assets/core/img/references/countries/aq.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "DZ",
            label: "ref.transaction.countries.DZ",
            shortLabel: "DZ",
            img: "/assets/core/img/references/countries/dz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AS",
            label: "ref.transaction.countries.AS",
            shortLabel: "AS",
            img: "/assets/core/img/references/countries/as.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AD",
            label: "ref.transaction.countries.AD",
            shortLabel: "AD",
            img: "/assets/core/img/references/countries/ad.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AO",
            label: "ref.transaction.countries.AO",
            shortLabel: "AO",
            img: "/assets/core/img/references/countries/ao.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AG",
            label: "ref.transaction.countries.AG",
            shortLabel: "AG",
            img: "/assets/core/img/references/countries/ag.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AZ",
            label: "ref.transaction.countries.AZ",
            shortLabel: "AZ",
            img: "/assets/core/img/references/countries/az.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AR",
            label: "ref.transaction.countries.AR",
            shortLabel: "AR",
            img: "/assets/core/img/references/countries/ar.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AU",
            label: "ref.transaction.countries.AU",
            shortLabel: "AU",
            img: "/assets/core/img/references/countries/au.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AT",
            label: "ref.transaction.countries.AT",
            shortLabel: "AT",
            img: "/assets/core/img/references/countries/at.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BS",
            label: "ref.transaction.countries.BS",
            shortLabel: "BS",
            img: "/assets/core/img/references/countries/bs.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BH",
            label: "ref.transaction.countries.BH",
            shortLabel: "BH",
            img: "/assets/core/img/references/countries/bh.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BD",
            label: "ref.transaction.countries.BD",
            shortLabel: "BD",
            img: "/assets/core/img/references/countries/bd.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AM",
            label: "ref.transaction.countries.AM",
            shortLabel: "AM",
            img: "/assets/core/img/references/countries/am.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BB",
            label: "ref.transaction.countries.BB",
            shortLabel: "BB",
            img: "/assets/core/img/references/countries/bb.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BE",
            label: "ref.transaction.countries.BE",
            shortLabel: "BE",
            img: "/assets/core/img/references/countries/be.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BM",
            label: "ref.transaction.countries.BM",
            shortLabel: "BM",
            img: "/assets/core/img/references/countries/bm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BT",
            label: "ref.transaction.countries.BT",
            shortLabel: "BT",
            img: "/assets/core/img/references/countries/bt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BO",
            label: "ref.transaction.countries.BO",
            shortLabel: "BO",
            img: "/assets/core/img/references/countries/bo.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BA",
            label: "ref.transaction.countries.BA",
            shortLabel: "BA",
            img: "/assets/core/img/references/countries/ba.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BW",
            label: "ref.transaction.countries.BW",
            shortLabel: "BW",
            img: "/assets/core/img/references/countries/bw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BV",
            label: "ref.transaction.countries.BV",
            shortLabel: "BV",
            img: "/assets/core/img/references/countries/bv.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BR",
            label: "ref.transaction.countries.BR",
            shortLabel: "BR",
            img: "/assets/core/img/references/countries/br.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BZ",
            label: "ref.transaction.countries.BZ",
            shortLabel: "BZ",
            img: "/assets/core/img/references/countries/bz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IO",
            label: "ref.transaction.countries.IO",
            shortLabel: "IO",
            img: "/assets/core/img/references/countries/io.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SB",
            label: "ref.transaction.countries.SB",
            shortLabel: "SB",
            img: "/assets/core/img/references/countries/sb.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VG",
            label: "ref.transaction.countries.VG",
            shortLabel: "VG",
            img: "/assets/core/img/references/countries/vg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BN",
            label: "ref.transaction.countries.BN",
            shortLabel: "BN",
            img: "/assets/core/img/references/countries/bn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BG",
            label: "ref.transaction.countries.BG",
            shortLabel: "BG",
            img: "/assets/core/img/references/countries/bg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MM",
            label: "ref.transaction.countries.MM",
            shortLabel: "MM",
            img: "/assets/core/img/references/countries/mm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BI",
            label: "ref.transaction.countries.BI",
            shortLabel: "BI",
            img: "/assets/core/img/references/countries/bi.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BY",
            label: "ref.transaction.countries.BY",
            shortLabel: "BY",
            img: "/assets/core/img/references/countries/by.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KH",
            label: "ref.transaction.countries.KH",
            shortLabel: "KH",
            img: "/assets/core/img/references/countries/kh.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CM",
            label: "ref.transaction.countries.CM",
            shortLabel: "CM",
            img: "/assets/core/img/references/countries/cm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CA",
            label: "ref.transaction.countries.CA",
            shortLabel: "CA",
            img: "/assets/core/img/references/countries/ca.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CV",
            label: "ref.transaction.countries.CV",
            shortLabel: "CV",
            img: "/assets/core/img/references/countries/cv.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KY",
            label: "ref.transaction.countries.KY",
            shortLabel: "KY",
            img: "/assets/core/img/references/countries/ky.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CF",
            label: "ref.transaction.countries.CF",
            shortLabel: "CF",
            img: "/assets/core/img/references/countries/cf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LK",
            label: "ref.transaction.countries.LK",
            shortLabel: "LK",
            img: "/assets/core/img/references/countries/lk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TD",
            label: "ref.transaction.countries.TD",
            shortLabel: "TD",
            img: "/assets/core/img/references/countries/td.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CL",
            label: "ref.transaction.countries.CL",
            shortLabel: "CL",
            img: "/assets/core/img/references/countries/cl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CN",
            label: "ref.transaction.countries.CN",
            shortLabel: "CN",
            img: "/assets/core/img/references/countries/cn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TW",
            label: "ref.transaction.countries.TW",
            shortLabel: "TW",
            img: "/assets/core/img/references/countries/tw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CX",
            label: "ref.transaction.countries.CX",
            shortLabel: "CX",
            img: "/assets/core/img/references/countries/cx.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CC",
            label: "ref.transaction.countries.CC",
            shortLabel: "CC",
            img: "/assets/core/img/references/countries/cc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CO",
            label: "ref.transaction.countries.CO",
            shortLabel: "CO",
            img: "/assets/core/img/references/countries/co.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KM",
            label: "ref.transaction.countries.KM",
            shortLabel: "KM",
            img: "/assets/core/img/references/countries/km.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "YT",
            label: "ref.transaction.countries.YT",
            shortLabel: "YT",
            img: "/assets/core/img/references/countries/yt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CG",
            label: "ref.transaction.countries.CG",
            shortLabel: "CG",
            img: "/assets/core/img/references/countries/cg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CD",
            label: "ref.transaction.countries.CD",
            shortLabel: "CD",
            img: "/assets/core/img/references/countries/cd.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CK",
            label: "ref.transaction.countries.CK",
            shortLabel: "CK",
            img: "/assets/core/img/references/countries/ck.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CR",
            label: "ref.transaction.countries.CR",
            shortLabel: "CR",
            img: "/assets/core/img/references/countries/cr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "HR",
            label: "ref.transaction.countries.HR",
            shortLabel: "HR",
            img: "/assets/core/img/references/countries/hr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CU",
            label: "ref.transaction.countries.CU",
            shortLabel: "CU",
            img: "/assets/core/img/references/countries/cu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CY",
            label: "ref.transaction.countries.CY",
            shortLabel: "CY",
            img: "/assets/core/img/references/countries/cy.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CZ",
            label: "ref.transaction.countries.CZ",
            shortLabel: "CZ",
            img: "/assets/core/img/references/countries/cz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BJ",
            label: "ref.transaction.countries.BJ",
            shortLabel: "BJ",
            img: "/assets/core/img/references/countries/bj.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "DK",
            label: "ref.transaction.countries.DK",
            shortLabel: "DK",
            img: "/assets/core/img/references/countries/dk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "DM",
            label: "ref.transaction.countries.DM",
            shortLabel: "DM",
            img: "/assets/core/img/references/countries/dm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "DO",
            label: "ref.transaction.countries.DO",
            shortLabel: "DO",
            img: "/assets/core/img/references/countries/do.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "EC",
            label: "ref.transaction.countries.EC",
            shortLabel: "EC",
            img: "/assets/core/img/references/countries/ec.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SV",
            label: "ref.transaction.countries.SV",
            shortLabel: "SV",
            img: "/assets/core/img/references/countries/sv.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GQ",
            label: "ref.transaction.countries.GQ",
            shortLabel: "GQ",
            img: "/assets/core/img/references/countries/gq.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ET",
            label: "ref.transaction.countries.ET",
            shortLabel: "ET",
            img: "/assets/core/img/references/countries/et.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ER",
            label: "ref.transaction.countries.ER",
            shortLabel: "ER",
            img: "/assets/core/img/references/countries/er.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "EE",
            label: "ref.transaction.countries.EE",
            shortLabel: "EE",
            img: "/assets/core/img/references/countries/ee.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "FO",
            label: "ref.transaction.countries.FO",
            shortLabel: "FO",
            img: "/assets/core/img/references/countries/fo.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "FK",
            label: "ref.transaction.countries.FK",
            shortLabel: "FK",
            img: "/assets/core/img/references/countries/fk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GS",
            label: "ref.transaction.countries.GS",
            shortLabel: "GS",
            img: "/assets/core/img/references/countries/gs.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "FJ",
            label: "ref.transaction.countries.FJ",
            shortLabel: "FJ",
            img: "/assets/core/img/references/countries/fj.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "FI",
            label: "ref.transaction.countries.FI",
            shortLabel: "FI",
            img: "/assets/core/img/references/countries/fi.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AX",
            label: "ref.transaction.countries.AX",
            shortLabel: "AX",
            img: "/assets/core/img/references/countries/ax.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "FR",
            label: "ref.transaction.countries.FR",
            shortLabel: "FR",
            img: "/assets/core/img/references/countries/fr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GF",
            label: "ref.transaction.countries.GF",
            shortLabel: "GF",
            img: "/assets/core/img/references/countries/gf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PF",
            label: "ref.transaction.countries.PF",
            shortLabel: "PF",
            img: "/assets/core/img/references/countries/pf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TF",
            label: "ref.transaction.countries.TF",
            shortLabel: "TF",
            img: "/assets/core/img/references/countries/tf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "DJ",
            label: "ref.transaction.countries.DJ",
            shortLabel: "DJ",
            img: "/assets/core/img/references/countries/dj.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GA",
            label: "ref.transaction.countries.GA",
            shortLabel: "GA",
            img: "/assets/core/img/references/countries/ga.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GE",
            label: "ref.transaction.countries.GE",
            shortLabel: "GE",
            img: "/assets/core/img/references/countries/ge.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GM",
            label: "ref.transaction.countries.GM",
            shortLabel: "GM",
            img: "/assets/core/img/references/countries/gm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PS",
            label: "ref.transaction.countries.PS",
            shortLabel: "PS",
            img: "/assets/core/img/references/countries/ps.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "DE",
            label: "ref.transaction.countries.DE",
            shortLabel: "DE",
            img: "/assets/core/img/references/countries/de.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GH",
            label: "ref.transaction.countries.GH",
            shortLabel: "GH",
            img: "/assets/core/img/references/countries/gh.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GI",
            label: "ref.transaction.countries.GI",
            shortLabel: "GI",
            img: "/assets/core/img/references/countries/gi.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KI",
            label: "ref.transaction.countries.KI",
            shortLabel: "KI",
            img: "/assets/core/img/references/countries/ki.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GR",
            label: "ref.transaction.countries.GR",
            shortLabel: "GR",
            img: "/assets/core/img/references/countries/gr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GL",
            label: "ref.transaction.countries.GL",
            shortLabel: "GL",
            img: "/assets/core/img/references/countries/gl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GD",
            label: "ref.transaction.countries.GD",
            shortLabel: "GD",
            img: "/assets/core/img/references/countries/gd.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GP",
            label: "ref.transaction.countries.GP",
            shortLabel: "GP",
            img: "/assets/core/img/references/countries/gp.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GU",
            label: "ref.transaction.countries.GU",
            shortLabel: "GU",
            img: "/assets/core/img/references/countries/gu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GT",
            label: "ref.transaction.countries.GT",
            shortLabel: "GT",
            img: "/assets/core/img/references/countries/gt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GN",
            label: "ref.transaction.countries.GN",
            shortLabel: "GN",
            img: "/assets/core/img/references/countries/gn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GY",
            label: "ref.transaction.countries.GY",
            shortLabel: "GY",
            img: "/assets/core/img/references/countries/gy.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "HT",
            label: "ref.transaction.countries.HT",
            shortLabel: "HT",
            img: "/assets/core/img/references/countries/ht.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "HM",
            label: "ref.transaction.countries.HM",
            shortLabel: "HM",
            img: "/assets/core/img/references/countries/hm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VA",
            label: "ref.transaction.countries.VA",
            shortLabel: "VA",
            img: "/assets/core/img/references/countries/va.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "HN",
            label: "ref.transaction.countries.HN",
            shortLabel: "HN",
            img: "/assets/core/img/references/countries/hn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "HK",
            label: "ref.transaction.countries.HK",
            shortLabel: "HK",
            img: "/assets/core/img/references/countries/hk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "HU",
            label: "ref.transaction.countries.HU",
            shortLabel: "HU",
            img: "/assets/core/img/references/countries/hu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IS",
            label: "ref.transaction.countries.IS",
            shortLabel: "IS",
            img: "/assets/core/img/references/countries/is.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IN",
            label: "ref.transaction.countries.IN",
            shortLabel: "IN",
            img: "/assets/core/img/references/countries/in.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ID",
            label: "ref.transaction.countries.ID",
            shortLabel: "ID",
            img: "/assets/core/img/references/countries/id.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IR",
            label: "ref.transaction.countries.IR",
            shortLabel: "IR",
            img: "/assets/core/img/references/countries/ir.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IQ",
            label: "ref.transaction.countries.IQ",
            shortLabel: "IQ",
            img: "/assets/core/img/references/countries/iq.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IE",
            label: "ref.transaction.countries.IE",
            shortLabel: "IE",
            img: "/assets/core/img/references/countries/ie.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IL",
            label: "ref.transaction.countries.IL",
            shortLabel: "IL",
            img: "/assets/core/img/references/countries/il.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IT",
            label: "ref.transaction.countries.IT",
            shortLabel: "IT",
            img: "/assets/core/img/references/countries/it.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CI",
            label: "ref.transaction.countries.CI",
            shortLabel: "CI",
            img: "/assets/core/img/references/countries/ci.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "JM",
            label: "ref.transaction.countries.JM",
            shortLabel: "JM",
            img: "/assets/core/img/references/countries/jm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "JP",
            label: "ref.transaction.countries.JP",
            shortLabel: "JP",
            img: "/assets/core/img/references/countries/jp.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KZ",
            label: "ref.transaction.countries.KZ",
            shortLabel: "KZ",
            img: "/assets/core/img/references/countries/kz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "JO",
            label: "ref.transaction.countries.JO",
            shortLabel: "JO",
            img: "/assets/core/img/references/countries/jo.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KE",
            label: "ref.transaction.countries.KE",
            shortLabel: "KE",
            img: "/assets/core/img/references/countries/ke.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KP",
            label: "ref.transaction.countries.KP",
            shortLabel: "KP",
            img: "/assets/core/img/references/countries/kp.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KR",
            label: "ref.transaction.countries.KR",
            shortLabel: "KR",
            img: "/assets/core/img/references/countries/kr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KW",
            label: "ref.transaction.countries.KW",
            shortLabel: "KW",
            img: "/assets/core/img/references/countries/kw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KG",
            label: "ref.transaction.countries.KG",
            shortLabel: "KG",
            img: "/assets/core/img/references/countries/kg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LA",
            label: "ref.transaction.countries.LA",
            shortLabel: "LA",
            img: "/assets/core/img/references/countries/la.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LB",
            label: "ref.transaction.countries.LB",
            shortLabel: "LB",
            img: "/assets/core/img/references/countries/lb.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LS",
            label: "ref.transaction.countries.LS",
            shortLabel: "LS",
            img: "/assets/core/img/references/countries/ls.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LV",
            label: "ref.transaction.countries.LV",
            shortLabel: "LV",
            img: "/assets/core/img/references/countries/lv.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LR",
            label: "ref.transaction.countries.LR",
            shortLabel: "LR",
            img: "/assets/core/img/references/countries/lr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LY",
            label: "ref.transaction.countries.LY",
            shortLabel: "LY",
            img: "/assets/core/img/references/countries/ly.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LI",
            label: "ref.transaction.countries.LI",
            shortLabel: "LI",
            img: "/assets/core/img/references/countries/li.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LT",
            label: "ref.transaction.countries.LT",
            shortLabel: "LT",
            img: "/assets/core/img/references/countries/lt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LU",
            label: "ref.transaction.countries.LU",
            shortLabel: "LU",
            img: "/assets/core/img/references/countries/lu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MO",
            label: "ref.transaction.countries.MO",
            shortLabel: "MO",
            img: "/assets/core/img/references/countries/mo.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MG",
            label: "ref.transaction.countries.MG",
            shortLabel: "MG",
            img: "/assets/core/img/references/countries/mg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MW",
            label: "ref.transaction.countries.MW",
            shortLabel: "MW",
            img: "/assets/core/img/references/countries/mw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MY",
            label: "ref.transaction.countries.MY",
            shortLabel: "MY",
            img: "/assets/core/img/references/countries/my.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MV",
            label: "ref.transaction.countries.MV",
            shortLabel: "MV",
            img: "/assets/core/img/references/countries/mv.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ML",
            label: "ref.transaction.countries.ML",
            shortLabel: "ML",
            img: "/assets/core/img/references/countries/ml.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MT",
            label: "ref.transaction.countries.MT",
            shortLabel: "MT",
            img: "/assets/core/img/references/countries/mt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MQ",
            label: "ref.transaction.countries.MQ",
            shortLabel: "MQ",
            img: "/assets/core/img/references/countries/mq.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MR",
            label: "ref.transaction.countries.MR",
            shortLabel: "MR",
            img: "/assets/core/img/references/countries/mr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MU",
            label: "ref.transaction.countries.MU",
            shortLabel: "MU",
            img: "/assets/core/img/references/countries/mu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MX",
            label: "ref.transaction.countries.MX",
            shortLabel: "MX",
            img: "/assets/core/img/references/countries/mx.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MC",
            label: "ref.transaction.countries.MC",
            shortLabel: "MC",
            img: "/assets/core/img/references/countries/mc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MN",
            label: "ref.transaction.countries.MN",
            shortLabel: "MN",
            img: "/assets/core/img/references/countries/mn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MD",
            label: "ref.transaction.countries.MD",
            shortLabel: "MD",
            img: "/assets/core/img/references/countries/md.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ME",
            label: "ref.transaction.countries.ME",
            shortLabel: "ME",
            img: "/assets/core/img/references/countries/me.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MS",
            label: "ref.transaction.countries.MS",
            shortLabel: "MS",
            img: "/assets/core/img/references/countries/ms.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MA",
            label: "ref.transaction.countries.MA",
            shortLabel: "MA",
            img: "/assets/core/img/references/countries/ma.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MZ",
            label: "ref.transaction.countries.MZ",
            shortLabel: "MZ",
            img: "/assets/core/img/references/countries/mz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "OM",
            label: "ref.transaction.countries.OM",
            shortLabel: "OM",
            img: "/assets/core/img/references/countries/om.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NA",
            label: "ref.transaction.countries.NA",
            shortLabel: "NA",
            img: "/assets/core/img/references/countries/na.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NR",
            label: "ref.transaction.countries.NR",
            shortLabel: "NR",
            img: "/assets/core/img/references/countries/nr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NP",
            label: "ref.transaction.countries.NP",
            shortLabel: "NP",
            img: "/assets/core/img/references/countries/np.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NL",
            label: "ref.transaction.countries.NL",
            shortLabel: "NL",
            img: "/assets/core/img/references/countries/nl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AN",
            label: "ref.transaction.countries.AN",
            shortLabel: "AN",
            img: "/assets/core/img/references/countries/an.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CW",
            label: "ref.transaction.countries.CW",
            shortLabel: "CW",
            img: "/assets/core/img/references/countries/cw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AW",
            label: "ref.transaction.countries.AW",
            shortLabel: "AW",
            img: "/assets/core/img/references/countries/aw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SX",
            label: "ref.transaction.countries.SX",
            shortLabel: "SX",
            img: "/assets/core/img/references/countries/sx.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BQ",
            label: "ref.transaction.countries.BQ",
            shortLabel: "BQ",
            img: "/assets/core/img/references/countries/bq.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NC",
            label: "ref.transaction.countries.NC",
            shortLabel: "NC",
            img: "/assets/core/img/references/countries/nc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VU",
            label: "ref.transaction.countries.VU",
            shortLabel: "VU",
            img: "/assets/core/img/references/countries/vu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NZ",
            label: "ref.transaction.countries.NZ",
            shortLabel: "NZ",
            img: "/assets/core/img/references/countries/nz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NI",
            label: "ref.transaction.countries.NI",
            shortLabel: "NI",
            img: "/assets/core/img/references/countries/ni.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NE",
            label: "ref.transaction.countries.NE",
            shortLabel: "NE",
            img: "/assets/core/img/references/countries/ne.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NG",
            label: "ref.transaction.countries.NG",
            shortLabel: "NG",
            img: "/assets/core/img/references/countries/ng.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NU",
            label: "ref.transaction.countries.NU",
            shortLabel: "NU",
            img: "/assets/core/img/references/countries/nu.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NF",
            label: "ref.transaction.countries.NF",
            shortLabel: "NF",
            img: "/assets/core/img/references/countries/nf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "NO",
            label: "ref.transaction.countries.NO",
            shortLabel: "NO",
            img: "/assets/core/img/references/countries/no.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MP",
            label: "ref.transaction.countries.MP",
            shortLabel: "MP",
            img: "/assets/core/img/references/countries/mp.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "UM",
            label: "ref.transaction.countries.UM",
            shortLabel: "UM",
            img: "/assets/core/img/references/countries/um.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "FM",
            label: "ref.transaction.countries.FM",
            shortLabel: "FM",
            img: "/assets/core/img/references/countries/fm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MH",
            label: "ref.transaction.countries.MH",
            shortLabel: "MH",
            img: "/assets/core/img/references/countries/mh.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PW",
            label: "ref.transaction.countries.PW",
            shortLabel: "PW",
            img: "/assets/core/img/references/countries/pw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PK",
            label: "ref.transaction.countries.PK",
            shortLabel: "PK",
            img: "/assets/core/img/references/countries/pk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PA",
            label: "ref.transaction.countries.PA",
            shortLabel: "PA",
            img: "/assets/core/img/references/countries/pa.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PG",
            label: "ref.transaction.countries.PG",
            shortLabel: "PG",
            img: "/assets/core/img/references/countries/pg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PY",
            label: "ref.transaction.countries.PY",
            shortLabel: "PY",
            img: "/assets/core/img/references/countries/py.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PE",
            label: "ref.transaction.countries.PE",
            shortLabel: "PE",
            img: "/assets/core/img/references/countries/pe.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PH",
            label: "ref.transaction.countries.PH",
            shortLabel: "PH",
            img: "/assets/core/img/references/countries/ph.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PN",
            label: "ref.transaction.countries.PN",
            shortLabel: "PN",
            img: "/assets/core/img/references/countries/pn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PL",
            label: "ref.transaction.countries.PL",
            shortLabel: "PL",
            img: "/assets/core/img/references/countries/pl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PT",
            label: "ref.transaction.countries.PT",
            shortLabel: "PT",
            img: "/assets/core/img/references/countries/pt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GW",
            label: "ref.transaction.countries.GW",
            shortLabel: "GW",
            img: "/assets/core/img/references/countries/gw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TL",
            label: "ref.transaction.countries.TL",
            shortLabel: "TL",
            img: "/assets/core/img/references/countries/tl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PR",
            label: "ref.transaction.countries.PR",
            shortLabel: "PR",
            img: "/assets/core/img/references/countries/pr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "QA",
            label: "ref.transaction.countries.QA",
            shortLabel: "QA",
            img: "/assets/core/img/references/countries/qa.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "RE",
            label: "ref.transaction.countries.RE",
            shortLabel: "RE",
            img: "/assets/core/img/references/countries/re.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "RO",
            label: "ref.transaction.countries.RO",
            shortLabel: "RO",
            img: "/assets/core/img/references/countries/ro.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "RU",
            label: "ref.transaction.countries.RU",
            shortLabel: "RU",
            img: "/assets/core/img/references/countries/ru.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "RW",
            label: "ref.transaction.countries.RW",
            shortLabel: "RW",
            img: "/assets/core/img/references/countries/rw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BL",
            label: "ref.transaction.countries.BL",
            shortLabel: "BL",
            img: "/assets/core/img/references/countries/bl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SH",
            label: "ref.transaction.countries.SH",
            shortLabel: "SH",
            img: "/assets/core/img/references/countries/sh.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "KN",
            label: "ref.transaction.countries.KN",
            shortLabel: "KN",
            img: "/assets/core/img/references/countries/kn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AI",
            label: "ref.transaction.countries.AI",
            shortLabel: "AI",
            img: "/assets/core/img/references/countries/ai.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "LC",
            label: "ref.transaction.countries.LC",
            shortLabel: "LC",
            img: "/assets/core/img/references/countries/lc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MF",
            label: "ref.transaction.countries.MF",
            shortLabel: "MF",
            img: "/assets/core/img/references/countries/mf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "PM",
            label: "ref.transaction.countries.PM",
            shortLabel: "PM",
            img: "/assets/core/img/references/countries/pm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VC",
            label: "ref.transaction.countries.VC",
            shortLabel: "VC",
            img: "/assets/core/img/references/countries/vc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SM",
            label: "ref.transaction.countries.SM",
            shortLabel: "SM",
            img: "/assets/core/img/references/countries/sm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ST",
            label: "ref.transaction.countries.ST",
            shortLabel: "ST",
            img: "/assets/core/img/references/countries/st.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SA",
            label: "ref.transaction.countries.SA",
            shortLabel: "SA",
            img: "/assets/core/img/references/countries/sa.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SN",
            label: "ref.transaction.countries.SN",
            shortLabel: "SN",
            img: "/assets/core/img/references/countries/sn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "RS",
            label: "ref.transaction.countries.RS",
            shortLabel: "RS",
            img: "/assets/core/img/references/countries/rs.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SC",
            label: "ref.transaction.countries.SC",
            shortLabel: "SC",
            img: "/assets/core/img/references/countries/sc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SL",
            label: "ref.transaction.countries.SL",
            shortLabel: "SL",
            img: "/assets/core/img/references/countries/sl.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SG",
            label: "ref.transaction.countries.SG",
            shortLabel: "SG",
            img: "/assets/core/img/references/countries/sg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SK",
            label: "ref.transaction.countries.SK",
            shortLabel: "SK",
            img: "/assets/core/img/references/countries/sk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VN",
            label: "ref.transaction.countries.VN",
            shortLabel: "VN",
            img: "/assets/core/img/references/countries/vn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SI",
            label: "ref.transaction.countries.SI",
            shortLabel: "SI",
            img: "/assets/core/img/references/countries/si.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SO",
            label: "ref.transaction.countries.SO",
            shortLabel: "SO",
            img: "/assets/core/img/references/countries/so.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ZA",
            label: "ref.transaction.countries.ZA",
            shortLabel: "ZA",
            img: "/assets/core/img/references/countries/za.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ZW",
            label: "ref.transaction.countries.ZW",
            shortLabel: "ZW",
            img: "/assets/core/img/references/countries/zw.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ES",
            label: "ref.transaction.countries.ES",
            shortLabel: "ES",
            img: "/assets/core/img/references/countries/es.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "EH",
            label: "ref.transaction.countries.EH",
            shortLabel: "EH",
            img: "/assets/core/img/references/countries/eh.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SD",
            label: "ref.transaction.countries.SD",
            shortLabel: "SD",
            img: "/assets/core/img/references/countries/sd.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SR",
            label: "ref.transaction.countries.SR",
            shortLabel: "SR",
            img: "/assets/core/img/references/countries/sr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SJ",
            label: "ref.transaction.countries.SJ",
            shortLabel: "SJ",
            img: "/assets/core/img/references/countries/sj.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SZ",
            label: "ref.transaction.countries.SZ",
            shortLabel: "SZ",
            img: "/assets/core/img/references/countries/sz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SE",
            label: "ref.transaction.countries.SE",
            shortLabel: "SE",
            img: "/assets/core/img/references/countries/se.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CH",
            label: "ref.transaction.countries.CH",
            shortLabel: "CH",
            img: "/assets/core/img/references/countries/ch.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "SY",
            label: "ref.transaction.countries.SY",
            shortLabel: "SY",
            img: "/assets/core/img/references/countries/sy.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TJ",
            label: "ref.transaction.countries.TJ",
            shortLabel: "TJ",
            img: "/assets/core/img/references/countries/tj.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TH",
            label: "ref.transaction.countries.TH",
            shortLabel: "TH",
            img: "/assets/core/img/references/countries/th.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TG",
            label: "ref.transaction.countries.TG",
            shortLabel: "TG",
            img: "/assets/core/img/references/countries/tg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TK",
            label: "ref.transaction.countries.TK",
            shortLabel: "TK",
            img: "/assets/core/img/references/countries/tk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TO",
            label: "ref.transaction.countries.TO",
            shortLabel: "TO",
            img: "/assets/core/img/references/countries/to.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TT",
            label: "ref.transaction.countries.TT",
            shortLabel: "TT",
            img: "/assets/core/img/references/countries/tt.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "AE",
            label: "ref.transaction.countries.AE",
            shortLabel: "AE",
            img: "/assets/core/img/references/countries/ae.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TN",
            label: "ref.transaction.countries.TN",
            shortLabel: "TN",
            img: "/assets/core/img/references/countries/tn.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TR",
            label: "ref.transaction.countries.TR",
            shortLabel: "TR",
            img: "/assets/core/img/references/countries/tr.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TM",
            label: "ref.transaction.countries.TM",
            shortLabel: "TM",
            img: "/assets/core/img/references/countries/tm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TC",
            label: "ref.transaction.countries.TC",
            shortLabel: "TC",
            img: "/assets/core/img/references/countries/tc.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TV",
            label: "ref.transaction.countries.TV",
            shortLabel: "TV",
            img: "/assets/core/img/references/countries/tv.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "UG",
            label: "ref.transaction.countries.UG",
            shortLabel: "UG",
            img: "/assets/core/img/references/countries/ug.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "UA",
            label: "ref.transaction.countries.UA",
            shortLabel: "UA",
            img: "/assets/core/img/references/countries/ua.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "MK",
            label: "ref.transaction.countries.MK",
            shortLabel: "MK",
            img: "/assets/core/img/references/countries/mk.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "EG",
            label: "ref.transaction.countries.EG",
            shortLabel: "EG",
            img: "/assets/core/img/references/countries/eg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GB",
            label: "ref.transaction.countries.GB",
            shortLabel: "GB",
            img: "/assets/core/img/references/countries/gb.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "GG",
            label: "ref.transaction.countries.GG",
            shortLabel: "GG",
            img: "/assets/core/img/references/countries/gg.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "JE",
            label: "ref.transaction.countries.JE",
            shortLabel: "JE",
            img: "/assets/core/img/references/countries/je.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "IM",
            label: "ref.transaction.countries.IM",
            shortLabel: "IM",
            img: "/assets/core/img/references/countries/im.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "TZ",
            label: "ref.transaction.countries.TZ",
            shortLabel: "TZ",
            img: "/assets/core/img/references/countries/tz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "US",
            label: "ref.transaction.countries.US",
            shortLabel: "US",
            img: "/assets/core/img/references/countries/us.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VI",
            label: "ref.transaction.countries.VI",
            shortLabel: "VI",
            img: "/assets/core/img/references/countries/vi.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "BF",
            label: "ref.transaction.countries.BF",
            shortLabel: "BF",
            img: "/assets/core/img/references/countries/bf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "UY",
            label: "ref.transaction.countries.UY",
            shortLabel: "UY",
            img: "/assets/core/img/references/countries/uy.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "UZ",
            label: "ref.transaction.countries.UZ",
            shortLabel: "UZ",
            img: "/assets/core/img/references/countries/uz.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "VE",
            label: "ref.transaction.countries.VE",
            shortLabel: "VE",
            img: "/assets/core/img/references/countries/ve.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "WF",
            label: "ref.transaction.countries.WF",
            shortLabel: "WF",
            img: "/assets/core/img/references/countries/wf.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "WS",
            label: "ref.transaction.countries.WS",
            shortLabel: "WS",
            img: "/assets/core/img/references/countries/ws.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "YE",
            label: "ref.transaction.countries.YE",
            shortLabel: "YE",
            img: "/assets/core/img/references/countries/ye.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "CS",
            label: "ref.transaction.countries.CS",
            shortLabel: "CS",
            img: "/assets/core/img/references/countries/cs.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        {
            id: "ZM",
            label: "ref.transaction.countries.ZM",
            shortLabel: "ZM",
            img: "/assets/core/img/references/countries/zm.svg",
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
    ],
    translate: true,
};

export default countries;
