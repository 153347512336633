import ContentLoader from "react-content-loader";

export const LoadingExport = () => {
    const width = window.innerWidth;
    let height = window.innerHeight;

    return (
        <div
            style={{
                marginTop: "5%",
                width: "50%",
                marginLeft: "25%",
            }}
        >
            <ContentLoader
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
                height={height}
                width={width / 2}
            >
                <rect x={16} y={8} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={48} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={88} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={128} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={168} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={208} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={248} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={288} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={328} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={368} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={368} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={368} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={368} rx="3" ry="3" width={width} height={24} />
                <rect x={16} y={368} rx="3" ry="3" width={width} height={24} />
            </ContentLoader>
        </div>
    );
};
