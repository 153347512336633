const references = {
    batch_status: {
        id: "batch_status",
        data: [
            {
                id: "IN_PROGRESS",
                label: "ref.mkp_batches.batch_status.IN_PROGRESS",
                color: "secondary",
            },
            {
                id: "SUCCESS",
                label: "ref.mkp_batches.batch_status.SUCCESS",
                color: "success",
            },
            {
                id: "ERROR",
                label: "ref.mkp_batches.batch_status.ERROR",
                color: "error",
            },
        ],
        translate: true,
    },
    batch_type: {
        id: "batch_type",
        data: [
            {
                id: "vendor-mkp-import",
                label: "ref.mkp_batches.batch_type.VENDOR_MKP_IMPORT",
            },
            {
                id: "vendor-hipay-import",
                label: "ref.mkp_batches.batch_type.VENDOR_HIPAY_IMPORT",
            },
            {
                id: "vendor-notify",
                label: "ref.mkp_batches.batch_type.VENDOR_NOTIFY",
            },
            {
                id: "kyc-mkp-import",
                label: "ref.mkp_batches.batch_type.KYC_MKP_IMPORT",
            },
            {
                id: "kyc-hipay-import",
                label: "ref.mkp_batches.batch_type.KYC_HIPAY_IMPORT",
            },
            {
                id: "bank-info-mkp-import",
                label: "ref.mkp_batches.batch_type.BANK_INFO_MKP_IMPORT",
            },
            {
                id: "bank-info-hipay-import",
                label: "ref.mkp_batches.batch_type.BANK_INFO_HIPAY_IMPORT",
            },
            {
                id: "payment-order-mkp-import",
                label: "ref.mkp_batches.batch_type.PAYMENT_ORDER_MKP_IMPORT",
            },
            {
                id: "payment-order-notify",
                label: "ref.mkp_batches.batch_type.PAYMENT_ORDER_NOTIFY",
            },
            {
                id: "payment-order-transfer",
                label: "ref.mkp_batches.batch_type.PAYMENT_ORDER_TRANSFER",
            },
            {
                id: "payment-order-withdraw",
                label: "ref.mkp_batches.batch_type.PAYMENT_ORDER_WITHDRAW",
            },
        ],
        translate: true,
    },
};

export default references;
