const chargebackTypes = {
    id: "chargeback_types",
    data: [
        {
            id: "AUTHORIZATION",
            label: "ref.transaction.chargeback_types.AUTHORIZATION",
        },
        {
            id: "CARDHOLDER DISPUTE",
            label: "ref.transaction.chargeback_types.CARDHOLDER DISPUTE",
        },
        {
            id: "FRAUD",
            label: "ref.transaction.chargeback_types.FRAUD",
        },
        {
            id: "PROCESSING ERROR",
            label: "ref.transaction.chargeback_types.PROCESSING ERROR",
        },
    ],
    translate: true,
};

export default chargebackTypes;
