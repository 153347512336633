import { formFieldTypes } from "../common/constants/formFieldTypes";
import { isEmpty } from "./objects";

/**
 * Validates the entry of a numeric form field
 * @param value
 * @return {boolean}
 */
export const checkNumericValue = (value) => {
    const compareRegex = /^((>|(>=)|<|(<=)|=|!|(!=)) ?)?-?\d*((\.|,)\d*)?$/;
    const foundCompare = value.match(compareRegex);

    const rangeRegex = /^-?\d+((\.|,)\d*)?( (T|(TO) ?(-?\d*((\.|,)\d*)?)?)?)?$/;
    const foundRange = value.match(rangeRegex);

    return foundCompare !== null || foundRange !== null;
};

/**
 * Return true if the field is a numeric value
 * @param attribute
 * @returns {*}
 */
export function isNumericFormFieldType(attribute) {
    if (attribute) {
        return attribute["formFieldType"] === formFieldTypes.FORM_FIELD_NUMERIC;
    }
    return false;
}

/**
 * Return true if the field is a date value
 * @param attribute
 * @returns {*}
 */
export function isDateFormFieldType(attribute) {
    if (attribute) {
        return attribute["formFieldType"] === formFieldTypes.FORM_FIELD_DATE;
    }
    return false;
}

/**
 * Return true if the field is a boolean value
 * @param attribute
 * @returns {*}
 */
export function isSwitchFormFieldType(attribute) {
    if (attribute) {
        return attribute["formFieldType"] === formFieldTypes.FORM_FIELD_SWITCH;
    }
    return false;
}

/**
 * Return true if the field is expected to have an array as value
 * @param formFieldType
 * @returns {*}
 */
export const isMultiValueFormFieldType = (formFieldType) => {
    return [
        formFieldTypes.FORM_FIELD_ACCOUNT,
        formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldTypes.FORM_FIELD_CHECKBOX_TO_SELECT,
        formFieldTypes.FORM_FIELD_CUSTOM_DATA,
        formFieldTypes.FORM_FIELD_SELECT,
        formFieldTypes.FORM_FIELD_NESTED_SELECT,
        formFieldTypes.FORM_FIELD_STATUS,
        formFieldTypes.FORM_FIELD_TEXT_REFERENCE,
    ].includes(formFieldType);
};

/**
 * Remove derived dates params from form object
 *
 * @param form
 * @param attributes
 *
 * @returns {{}}
 */
export const resetDerivedDates = (form = {}, attributes = {}) => {
    let _formObject = { ...form };
    Object.keys(_formObject).forEach((key) => {
        if (
            attributes[key] &&
            _formObject[key] &&
            attributes[key].formFieldType === formFieldTypes.FORM_FIELD_DATE &&
            (!!_formObject[key].derivedFrom || !!_formObject[key].derivedTo)
        ) {
            _formObject[key].derivedFrom = null;
            _formObject[key].derivedTo = null;
        }
    });
    return _formObject;
};

/**
 * Build formFields from previous form fields object and current form params
 *
 * if fromRedirect is true, it'll force form initialisation from formParams
 * it's used for redirection (don't display default fields)
 *
 * if inSearch is true, it'll update and add additional fields from formParams to previous form fields
 *
 * if both are false, it'll update and add additional fields from formParams to default form fields
 *
 * @param defaultForm (object)
 * @param prevFormFields (object)
 * @param formParams (object)
 * @param fromRedirect (bool)
 * @param inSearch (bool)
 * @param changeAccount (bool)
 * @param initEmptyForm (bool)
 * @returns {{}}
 */
export const buildFormFields = (
    defaultForm,
    prevFormFields,
    formParams,
    fromRedirect = false,
    inSearch = false,
    changeAccount = false,
    initEmptyForm = false
) => {
    // if fromRedirect, return only fields from form params
    if (fromRedirect) {
        if (isEmpty(formParams)) {
            return defaultForm;
        }
        return { ...formParams };
    }

    // update previous form fields on inSearch mode, else always reset to default fields
    const baseFormFields =
        !initEmptyForm && (inSearch || changeAccount) ? prevFormFields : defaultForm;

    let formFields = Object.keys(baseFormFields).reduce((memo, keyField) => {
        if (formParams[keyField] === undefined) {
            memo[keyField] = null;
        } else if ((baseFormFields[keyField] === null || !inSearch) && !changeAccount) {
            memo[keyField] = formParams[keyField];
        } else {
            memo[keyField] = baseFormFields[keyField];
        }
        return memo;
    }, {});

    // Add additional fields from form params
    Object.keys(formParams).forEach((keyField) => {
        if (formFields[keyField] === undefined || keyField.includes("custom_data")) {
            formFields[keyField] = formParams[keyField];
        }
    });

    return formFields;
};

/**
 * Delete spaces and double quote from the begining and the end of a string
 * @param value
 * @returns {*}
 */
export const trimSuggestInput = (value) => {
    return value.trim().replace(new RegExp('^["]+'), "").replace(new RegExp('["]+$'), "").trim();
};
