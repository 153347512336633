import grid from "./grid";
import { mapUrlParamsToSearchParams, parseUrlToParams } from "@console/core";
import attributes from "./attributes";

function getAvailableCurrencyColumn(orderedColumns) {
    const currencyItems = grid.filter((item) => {
        return !!item.displayCurrency;
    });
    const currencyItemsId = currencyItems.map((item) => {
        return item.id;
    });

    const availableCurrencyColumn = [];

    orderedColumns.forEach((column) => {
        if (currencyItemsId.includes(column.id)) {
            availableCurrencyColumn.push(column);
        }
    });

    return availableCurrencyColumn;
}

let stopPropagationEvent = (event) => {
    event.stopPropagation();
};

export function getNoticeSteps(tourOptions, p, trxId, setInitCallback) {
    let steps;

    let noticeHeader;
    let settingsBtn;
    let settingsMenu;
    let contextMenu;
    let orderColumnsItem;
    let oldNoticeItem;
    let contextMenuOverlay;
    let historyBtn;
    let historySection;
    let navArrowNext;
    let navArrowPrev;
    let contentContainer;
    let iconContainer;
    let nextTrxColor;
    let selectedKpi;
    let kpiSection;
    let kpiClicked = false;

    settingsBtn = document.getElementById(`settings-menu-button-${trxId}`);
    orderColumnsItem = document.querySelector(`#settings-menu-${trxId} #menu-item-order-columns`);
    oldNoticeItem = document.querySelector(`#settings-menu-${trxId} #menu-item-open-old-notice`);
    historyBtn = document.getElementById(`action-open-status-history-${trxId}`);
    navArrowPrev = document.getElementById("nav-previous");
    navArrowNext = document.getElementById("nav-next");

    if (navArrowNext) {
        // Target the content and the icon containers of the navigation arrow
        contentContainer = document.querySelector("#nav-next div:nth-of-type(1)");
        iconContainer = document.querySelector("#nav-next div:nth-of-type(2)");
    }

    if (setInitCallback) {
        setInitCallback({
            callback: (tour) => {
                tour.on("start", () => {
                    // Remove tabindex attribute from nav arrows and settings menu items to prevent focus conflict with gt
                    orderColumnsItem.removeAttribute("tabindex");
                    oldNoticeItem.removeAttribute("tabindex");

                    if (navArrowNext) {
                        navArrowNext.removeAttribute("tabindex");
                        if (navArrowPrev) {
                            navArrowPrev.removeAttribute("tabindex");
                        }
                    }
                });
                // Remove the stopPropagationEvent() on click() allow the user to click again on settings-menu buttons
                ["close", "cancel", "complete"].forEach((event) =>
                    tour.on(event, () => {
                        orderColumnsItem.tabIndex = -1;
                        oldNoticeItem.tabIndex = -1;
                        if (navArrowNext) {
                            navArrowNext.removeAttribute("style");
                            iconContainer.removeAttribute("style");
                            contentContainer.removeAttribute("style");
                            navArrowNext.tabIndex = 0;
                        }
                        if (navArrowPrev) {
                            navArrowPrev.tabIndex = 0;
                        }
                        if (settingsMenu) {
                            settingsMenu.removeEventListener("click", stopPropagationEvent);
                        }
                        if (kpiClicked) {
                            document.getElementById(selectedKpi).click();
                            kpiClicked = false;
                        }
                    })
                );
            },
        });
    }

    steps = [
        {
            id: "left-title",
            attachTo: {
                element: `#notice-title-${trxId}`,
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.notice.left-title.title"),
            text: p.t("modules.transaction.guidetour.notice.left-title.text"),
            buttons: [
                {
                    ...tourOptions.defaultStepOptions.buttons[0],
                    text: "",
                },
                tourOptions.defaultStepOptions.buttons[1],
            ],
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    settingsMenu = document.getElementById(`settings-menu-${trxId}`);
                    contextMenu = document.querySelector(
                        `#settings-menu-${trxId} div:nth-of-type(3)`
                    );
                    contextMenuOverlay = document.querySelector(`#settings-menu-${trxId} div`);

                    settingsMenu.removeEventListener("click", stopPropagationEvent);

                    // Close settings menu
                    if (contextMenu) {
                        contextMenuOverlay.click();
                    }

                    resolve();
                });
            },
        },
        {
            id: "settings",
            attachTo: {
                element: `#settings-menu-${trxId} div:nth-of-type(3)`,
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.notice.settings.title"),
            text: p.t("modules.transaction.guidetour.notice.settings.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    settingsMenu = document.getElementById(`settings-menu-${trxId}`);
                    settingsMenu.addEventListener("click", stopPropagationEvent);

                    if (settingsBtn) {
                        settingsBtn.click();
                    }

                    setTimeout(() => {
                        resolve();
                    }, 200);
                });
            },
        },
        {
            id: "history",
            attachTo: {
                element: `#action-open-status-history-${trxId}`,
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.notice.history.title"),
            text: p.t("modules.transaction.guidetour.notice.history.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    //unselect the kpi if it's already clicked
                    if (!navArrowNext && kpiClicked) {
                        document.getElementById(selectedKpi).click();
                        kpiClicked = false;
                    }

                    settingsMenu.removeEventListener("click", stopPropagationEvent);

                    contextMenu = document.querySelector(
                        `#settings-menu-${trxId} div:nth-of-type(3)`
                    );
                    contextMenuOverlay = document.querySelector(`#settings-menu-${trxId} div`);

                    if (navArrowNext) {
                        navArrowNext.removeAttribute("style");
                        iconContainer.removeAttribute("style");
                        contentContainer.removeAttribute("style");
                    }

                    // Close settings menu
                    if (contextMenu) {
                        contextMenuOverlay.click();
                    }

                    if (document.getElementById("history")) {
                        let historyEl = document.getElementById("history");
                        historySection = historyEl.parentElement;
                        // Check if history section is shown
                        if (historySection.clientWidth !== 300) {
                            historyBtn.click();
                        }
                    }

                    resolve();
                });
            },
        },
    ];

    // If navigation arrows are present, we add this steps to the guidetour
    if (navArrowNext) {
        steps.push({
            id: "navigation",
            attachTo: {
                element: "#nav-next",
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.notice.navigation.title"),
            text: p.t("modules.transaction.guidetour.notice.navigation.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    //unselect the kpi if it's already clicked
                    if (kpiClicked) {
                        document.getElementById(selectedKpi).click();
                        kpiClicked = false;
                    }
                    // target the nextTrx swipeable div index for extract its bg-color
                    let swipeableTrx = document.querySelector(
                        "div.react-swipeable-view-container"
                    ).children;
                    let currentTrx = document.querySelector(
                        "div.react-swipeable-view-container > div[aria-hidden='false']"
                    );
                    let index = [].indexOf.call(swipeableTrx, currentTrx) + 1;

                    nextTrxColor = window
                        .getComputedStyle(swipeableTrx[index].querySelector("#notice-header"), null)
                        .getPropertyValue("background-color");

                    //show the navigation arrow
                    navArrowNext.style.transform = "translateX(0)";

                    iconContainer.style.backgroundColor = nextTrxColor;
                    contentContainer.style.display = "inline-block";

                    setTimeout(() => {
                        resolve();
                    }, 400);
                });
            },
        });
    }

    noticeHeader = document.getElementById("notice-header");

    if (noticeHeader) {
        // We get all the kpis from the notice header
        const kpisDOM = document.querySelector("#notice-header > div:nth-child(2)").children;

        if (kpisDOM.length > 0) {
            // By default, we take the sentinel kpi (not clickable)
            selectedKpi = "kpi_sentinel";

            for (let kpi of kpisDOM) {
                // We keep the id of the first kpi which is not sentinel
                // We get the id of the paragraph corresponding to this kpi
                if (kpi.id !== "kpi_sentinel") {
                    selectedKpi = kpi.id;
                    kpiSection = selectedKpi.split("kpi_")[1];
                    break;
                }
            }

            // This step behaviour depends on the selectedKpi's value
            if (selectedKpi === "kpi_sentinel") {
                steps.push({
                    id: "kpi",
                    attachTo: {
                        element: `#${selectedKpi}`,
                        on: "auto",
                    },
                    title: p.t("modules.transaction.guidetour.notice.kpi.title"),
                    text: p.t("modules.transaction.guidetour.notice.kpi.text"),
                    beforeShowPromise: () => {
                        return new Promise((resolve) => {
                            if (navArrowNext) {
                                navArrowNext.removeAttribute("style");
                                iconContainer.removeAttribute("style");
                                contentContainer.removeAttribute("style");
                            }

                            resolve();
                        });
                    },
                });
            } else {
                steps.push({
                    id: "kpi",
                    attachTo: {
                        element: `#${kpiSection}`,
                        on: "auto",
                    },
                    title: p.t("modules.transaction.guidetour.notice.kpi.title"),
                    text: p.t("modules.transaction.guidetour.notice.kpi.text"),
                    beforeShowPromise: () => {
                        return new Promise((resolve) => {
                            if (navArrowNext) {
                                navArrowNext.removeAttribute("style");
                                iconContainer.removeAttribute("style");
                                contentContainer.removeAttribute("style");
                            }

                            if (selectedKpi) {
                                document.getElementById(selectedKpi).click();
                                kpiClicked = true;
                            }

                            setTimeout(() => {
                                resolve();
                            }, 500);
                        });
                    },
                });
            }
        } else {
            // If notice header contains zero kpis, we show the step in the center of the screen
            steps.push({
                id: "kpi",
                title: p.t("modules.transaction.guidetour.notice.kpi.title"),
                text: p.t("modules.transaction.guidetour.notice.kpi.text"),
                beforeShowPromise: () => {
                    return new Promise((resolve) => {
                        if (navArrowNext) {
                            navArrowNext.removeAttribute("style");
                        }

                        resolve();
                    });
                },
            });
        }
    }

    steps.push.apply(steps, [
        {
            id: "value",
            title: p.t("modules.transaction.guidetour.notice.value.title"),
            text: p.t("modules.transaction.guidetour.notice.value.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (selectedKpi && selectedKpi !== "kpi_sentinel" && kpiClicked) {
                        document.getElementById(selectedKpi).click();
                        kpiClicked = false;
                    }

                    resolve();
                });
            },
        },
        {
            id: "help",
            attachTo: {
                element: "#launcher",
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.notice.help.title"),
            text: p.t("modules.transaction.guidetour.notice.help.text"),
            buttons: [
                tourOptions.defaultStepOptions.buttons[0],
                {
                    ...tourOptions.defaultStepOptions.buttons[1],
                    text: p.t("guidetour.buttons.done"),
                },
            ],
        },
    ]);

    return steps;
}

export function getGuideTourSteps(moduleState, tourOptions, p, setInitCallback) {
    let steps;
    let trxId;

    let path = "/" + window.location.pathname.split("/")[1];
    let notice = document.getElementById("notice-content");

    /*
    If the notice is present in the inner page, the function returns the notice steps.
    If not, it returns module steps
    */
    if (path === "/transactions" && window.location.pathname.split("/").length === 3) {
        trxId = window.location.pathname.split("/")[2];
        return notice ? getNoticeSteps(tourOptions, p, trxId, setInitCallback) : [];
    }

    const firstRow = document.querySelector("#search-results div.lazy-row");
    if (!firstRow) {
        return [];
    }

    const params = parseUrlToParams(window.location.search);
    const searchParams = mapUrlParamsToSearchParams(attributes, params, grid);

    if (!(searchParams && searchParams.orderedColumns)) {
        return [];
    }

    const orderedColumns = searchParams.orderedColumns;

    setInitCallback({
        callback: (tour) => {
            ["close", "cancel", "complete"].forEach((event) =>
                tour.on(event, () => {
                    const settingsMenu = document.getElementById("settings-menu");
                    if (settingsMenu) {
                        settingsMenu.removeEventListener("click", stopPropagationEvent);
                    }

                    const favoriteMenu = document.getElementById("favorite-menu");
                    if (favoriteMenu) {
                        favoriteMenu.removeEventListener("click", stopPropagationEvent);
                    }

                    const columnFilterMenu = document.getElementById("column-filter-menu");
                    if (columnFilterMenu) {
                        columnFilterMenu.removeEventListener("click", stopPropagationEvent);
                    }
                })
            );
        },
    });

    let headerAmountId;
    const availableCurrencyColumn = getAvailableCurrencyColumn(orderedColumns);
    if (availableCurrencyColumn.length !== 0) {
        headerAmountId = `header-${availableCurrencyColumn[0].colId}`;
    }

    const firstRowChildrenCount = firstRow.childNodes.length;
    let cellSelector = "div:nth-child(3)";
    if (firstRowChildrenCount <= 2) {
        cellSelector = `div:nth-child(${firstRowChildrenCount})`;
    }

    steps = [
        {
            id: "parameter-button",
            attachTo: {
                element: "#settings-menu div:nth-child(3n)",
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.parameter-button.title"),
            text: p.t("modules.transaction.guidetour.parameter-button.text"),
            buttons: [
                {
                    ...tourOptions.defaultStepOptions.buttons[0],
                    text: "",
                },
                tourOptions.defaultStepOptions.buttons[1],
            ],
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    const settingsButton = document.getElementById("settings-menu-button");
                    if (settingsButton) {
                        settingsButton.click();
                    }

                    if (document.getElementById("search-form-submit")) {
                        const searchTitle = document.getElementById("search-title");
                        if (searchTitle) {
                            searchTitle.click();
                        }
                    }

                    // Fix canClickTarget: false not working for this step
                    const settingsMenu = document.getElementById("settings-menu");
                    settingsMenu.addEventListener("click", stopPropagationEvent);

                    setTimeout(() => resolve(), 200);
                });
            },
            when: {
                // Needed because when property override default show from step options
                show: tourOptions.defaultStepOptions.when.show,
                hide: function () {
                    const settingsMenu = document.getElementById("settings-menu");

                    // Fix canClickTarget: false not working for this step
                    settingsMenu.removeEventListener("click", stopPropagationEvent);

                    if (window.getComputedStyle(settingsMenu).visibility === "visible") {
                        // If setting menu is visible, close it
                        if (settingsMenu.children[0]) {
                            settingsMenu.children[0].click();
                        }
                    }
                },
            },
        },
    ];

    steps.push.apply(steps, [
        {
            id: "favorite-button",
            attachTo: {
                element: "#favorite-menu div:nth-child(3n)",
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.favorite-button.title"),
            text: p.t("modules.transaction.guidetour.favorite-button.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    const favoriteButton = document.getElementById("favorite-menu-button");
                    if (favoriteButton) {
                        favoriteButton.click();
                    }

                    // Fix canClickTarget: false not working for this step
                    const favoriteMenu = document.getElementById("favorite-menu");
                    favoriteMenu.addEventListener("click", stopPropagationEvent);

                    setTimeout(() => resolve(), 200);
                });
            },
            when: {
                // Needed because when property override default show from step options
                show: tourOptions.defaultStepOptions.when.show,
                hide: function () {
                    const favoriteMenu = document.getElementById("favorite-menu");

                    // Fix canClickTarget: false not working for this step
                    favoriteMenu.removeEventListener("click", stopPropagationEvent);

                    if (window.getComputedStyle(favoriteMenu).visibility === "visible") {
                        // If favorite menu is visible, close it
                        if (favoriteMenu.children[0]) {
                            favoriteMenu.children[0].click();
                        }
                    }
                },
            },
        },
        {
            id: "titles-line",
            arrow: false,
            attachTo: {
                element: "#search-results-header",
                on: "auto-start",
            },
            title: p.t("modules.transaction.guidetour.titles-line.title"),
            text: `${p.t("modules.transaction.guidetour.titles-line.text")}`,
            when: {
                // Needed because when property override default show from step options
                show: tourOptions.defaultStepOptions.when.show,
                // Preparation for next step
                hide: function () {
                    ["close", "cancel", "complete"].forEach((event) =>
                        this.tour.on(event, () => {
                            if (firstRow) {
                                firstRow.style["pointer-events"] = "auto";
                            }
                        })
                    );
                },
            },
        },
        {
            id: "cell",
            attachTo: {
                element: `#${firstRow.id} ${cellSelector}`,
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.cell.title"),
            text: p.t("modules.transaction.guidetour.cell.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (firstRow) {
                        firstRow.style["pointer-events"] = "none";
                    }

                    resolve();
                });
            },
        },
        {
            id: "search",
            attachTo: {
                element: "#search-title",
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.search.title"),
            text: p.t("modules.transaction.guidetour.search.text"),
        },
        {
            id: "line",
            arrow: false,
            attachTo: {
                element: firstRow,
                on: "auto-start",
            },
            title: p.t("modules.transaction.guidetour.line.title"),
            text: p.t("modules.transaction.guidetour.line.text"),
        },
    ]);

    if (headerAmountId) {
        steps.push({
            id: "amount-column",
            attachTo: {
                element: "#column-filter-menu div:nth-child(3n)",
                on: "auto",
            },
            title: p.t("modules.transaction.guidetour.amount-column.title"),
            text: p.t("modules.transaction.guidetour.amount-column.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    const headerAmount = document.getElementById(headerAmountId);
                    headerAmount.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                    });

                    setTimeout(() => {
                        if (headerAmount) {
                            headerAmount.click();
                        }

                        // Fix canClickTarget: false not working for this step
                        const columnFilterMenu = document.getElementById("column-filter-menu");
                        columnFilterMenu.addEventListener("click", stopPropagationEvent);

                        setTimeout(() => resolve(), 150);
                    }, 300);
                });
            },
            when: {
                // Needed because when property override default show from step options
                show: tourOptions.defaultStepOptions.when.show,
                hide: function () {
                    const columnFilterMenu = document.getElementById("column-filter-menu");
                    if (!columnFilterMenu) {
                        return;
                    }
                    // Fix canClickTarget: false not working for this step
                    columnFilterMenu.removeEventListener("click", stopPropagationEvent);

                    if (window.getComputedStyle(columnFilterMenu).visibility === "visible") {
                        // If column filter menu is visible, close it
                        if (columnFilterMenu.children[0]) {
                            columnFilterMenu.children[0].click();
                        }
                    }
                },
            },
        });
    }

    steps.push({
        id: "help-button",
        attachTo: {
            element: "#launcher",
            on: "auto",
        },
        title: p.t("modules.transaction.guidetour.help-button.title"),
        text: p.t("modules.transaction.guidetour.help-button.text"),
        buttons: [
            tourOptions.defaultStepOptions.buttons[0],
            {
                ...tourOptions.defaultStepOptions.buttons[1],
                text: p.t("guidetour.buttons.done"),
            },
        ],
    });

    return steps;
}
