import attributes from "./attributes";
import { defaultFormFields } from "./form";
import references from "../references";
import App from "../AppFinancialReport";
import buildInitialFilePrefix from "../services/export/buildInitialFilePrefix";
import { FEATURE_SEARCH_SELECTION } from "@console/core";
import { LeaderboardOutlined } from "@mui/icons-material";

export const moduleConfig = {
    id: "financial_report",
    attributes,
    defaultFormFields,
    gaming: {
        attributes: true,
        references: true,
    },
    icon: LeaderboardOutlined,
    label: "menu.financial_report.title",
    path: "financial_reports",
    route: "[prefix]/financial_reports",
    features: [FEATURE_SEARCH_SELECTION, "form", "qsearch", "export", "export-v2"],
    references,
    apiUrl: process.env.NX_FINANCIAL_OPERATION_API_URL,
    suggestRoute: "/_suggest?",
    acl: {
        module: [
            {
                module: "finance",
                controller: "index",
                action: "index",
            },
        ],
        export: {
            module: "finance",
            controller: "index",
            action: "index",
        },
        bigExport: {
            module: "finance",
            controller: "export",
            action: "export-big-volume",
        },
    },
    export: {
        buildInitialFilePrefix,
        limitLiveExport: process.env.NX_FINANCIAL_OPERATION_LIMIT_SYNCHRONOUS_EXPORT,
        limitEmailExport: process.env.NX_FINANCIAL_OPERATION_LIMIT_EXPORT,
        limitBigExport: process.env.NX_FINANCIAL_OPERATION_LIMIT_BIG_EXPORT,
    },
};

export const moduleRouting = [
    {
        key: "financial_reports",
        path: ["/financial_reports", "/accounts-:ids/financial_reports"],
        component: App,
    },
];
