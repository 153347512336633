import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toUserLocalNumber } from "../../../../../utils/i18n";
import { useP } from "../../../../../services/i18n";
import makeStyles from "@mui/styles/makeStyles";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import HiPagination from "@hipay/hipay-material-ui/HiPagination";
import { ExportFileRow } from "./ExportFileRow";

const useStylesExportFileList = makeStyles((theme) => ({
    resultsChip: {
        marginLeft: 8,
        verticalAlign: "bottom",
    },
    head: {
        height: 48,
        "& tr th": {
            borderBottom: `1px solid ${theme.palette.divider}`,
            ...theme.typography.body3,
            lineHeight: "12px",
            fontWeight: theme.typography.fontWeightLight,
        },
    },
    headerCell: { padding: "0px 4px" },
    headerRow: { height: 48 },
    title: {
        textAlign: "left",
        ...theme.typography.h3,
        color: theme.palette.neutral.dark,
    },
    pagination: {
        display: "flex",
        flexDirection: "row-reverse",
        paddingTop: 2,
    },
}));

export const ExportFileList = (props) => {
    const { exportFiles, exportItem } = props;

    const p = useP();
    const classes = useStylesExportFileList();
    const [page, setPage] = useState(1);

    const userSettings = useSelector((state) => state.app.settings.data);

    const handleChangePage = useCallback((event, _page) => {
        setPage(_page);
    }, []);

    const displayedData = useMemo(() => {
        let _data = [...exportFiles];
        if (_data.length > 10) {
            const itemFromIndex = (page - 1) * 10;
            let itemToIndex = page * 10;
            if (itemToIndex > _data.length) {
                itemToIndex = _data.length;
            }
            return _data.slice(itemFromIndex, itemToIndex);
        }
        return _data;
    }, [page, exportFiles]);

    const activatePagination = useMemo(() => {
        return exportFiles.length > 10;
    }, [exportFiles]);

    const pageCount = useMemo(() => {
        return Math.ceil(exportFiles.length / 10);
    }, [exportFiles]);

    if (!exportFiles || exportFiles.length < 1) {
        return null;
    }

    return (
        <div>
            <div className={classes.title}>
                <span>{p.t("common.search.export.list.export_files.title")}</span>
                <HiChip
                    classes={{ root: classes.resultsChip }}
                    label={toUserLocalNumber(exportFiles.length, userSettings.language, 0)}
                />
            </div>
            <Table>
                <TableHead className={classes.head}>
                    <TableRow className={classes.headerRow}>
                        <TableCell className={classes.headerCell} padding="none">
                            #
                        </TableCell>
                        <TableCell className={classes.headerCell} padding="none">
                            {p.t("common.search.export.list.export_files.date_sent")}
                        </TableCell>
                        <TableCell className={classes.headerCell} padding="none">
                            {p.t("common.search.export.list.export_files.filename")}
                        </TableCell>
                        <TableCell className={classes.headerCell} padding="none">
                            {p.t("common.search.export.list.export_files.status")}
                        </TableCell>
                        <TableCell className={classes.headerCell} padding="none">
                            {p.t("common.search.export.list.export_files.nb_lines")}
                        </TableCell>
                        <TableCell className={classes.headerCell} padding="none">
                            {p.t("common.search.export.list.export_files.expires_in")}
                        </TableCell>
                        <TableCell className={classes.headerCell} padding="none" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayedData.map((exportFile, index) => {
                        return (
                            <ExportFileRow
                                key={exportFile.fileId}
                                exportFile={exportFile}
                                nbExportFiles={exportFiles.length}
                                index={index}
                                exportItem={exportItem}
                                page={page}
                            />
                        );
                    })}
                </TableBody>
            </Table>
            {activatePagination && (
                <HiPagination
                    classes={{ root: classes.pagination }}
                    count={pageCount}
                    showFirstButton={pageCount > 10}
                    showLastButton={pageCount > 10}
                    onChange={handleChangePage}
                    page={page}
                />
            )}
        </div>
    );
};

ExportFileList.propTypes = { exportFiles: PropTypes.array.isRequired };

ExportFileList.defaultProps = { exportFiles: [] };
