import React, { useCallback, useMemo } from "react";
import { AccountContext } from "./account-context";
import { useSelector } from "react-redux";

/**
 * AccountContext
 * for now is just a hook to replace Account singleton, it should end up to include utils functions
 * @param children
 * @returns {*}
 * @constructor
 */
export const AccountProvider = ({ children }) => {
    const selectedAccountIdList = useSelector((state) => state.app.global.selectedAccountIdList);

    /* build url prefix from selected account id list
     */
    const urlPrefix = useMemo(() => {
        return selectedAccountIdList.length === 0
            ? ""
            : `/accounts-${selectedAccountIdList.join("-")}`;
    }, [selectedAccountIdList]);

    const getUrl = useCallback(
        (url) => {
            return urlPrefix + url.replace("/[prefix]", "");
        },
        [urlPrefix]
    );

    /* build context value
     */
    const value = useMemo(
        () => ({
            getUrl,
            urlPrefix,
        }),
        [getUrl, urlPrefix]
    );

    return (
        <AccountContext.Provider value={value}>
            {React.Children.only(children)}
        </AccountContext.Provider>
    );
};
