import { formFieldTypes, displayTypes } from "@console/core";

const attributes = {
    scope: {
        id: "scope",
        displayType: displayTypes.DISPLAY_LONG_TEXT,
        displayOptions: {
            filterable: true,
            sortable: true,
            lookup: false,
            exclude: false,
        },
    },
    category: {
        id: "category",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            filterable: true,
            sortable: true,
            lookup: true,
            exclude: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "privilege_category",
    },
    resource: {
        id: "resource",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            filterable: false,
            sortable: false,
            lookup: false,
            exclude: false,
        },
        reference: "privilege_resource",
    },
    right: {
        id: "right",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            filterable: false,
            sortable: false,
            lookup: false,
            exclude: false,
        },
        reference: "privilege_right",
    },
    description: {
        id: "description",
        displayType: displayTypes.DISPLAY_LONG_TEXT,
        displayOptions: {
            filterable: false,
            sortable: false,
            lookup: false,
            exclude: false,
        },
        reference: "privilege_description",
    },
    type: {
        id: "type",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        formFieldOptions: {
            hasEmptyOption: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        reference: "privilege_type",
    },
};

export default attributes;
