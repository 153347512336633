import { withStylesAndTranslate } from "../../../../../hoc/withStylesAndTranslate";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const stylesNoData = (theme) => ({
    root: {
        position: "sticky",
        left: 0,
        height: window.innerHeight - 295,
        width: window.innerWidth,
        backgroundColor: theme.palette.background3,
    },
    infoContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    infoDiv: {
        ...theme.typography.body2,
        lineHeight: "24px",
        color: theme.palette.neutral.main,
        margin: "auto",
        textAlign: "left",
        maxWidth: 340,
    },
});

function NoData(props) {
    const { classes, p, searchState, handleSearchAll } = props;
    return (
        <div className={classes.root}>
            <div className={classes.infoContainer}>
                <ErrorOutlineIcon color="disabled" sx={{ fontSize: 64 }} />
                {searchState === "default" ? (
                    <div>
                        <Typography
                            variant="h6"
                            dangerouslySetInnerHTML={{
                                __html: p.t("common.search.no_results_limited_title"),
                            }}
                        />
                        <Typography
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: p.t("common.search.no_results_limited_subtitle"),
                            }}
                        />

                        <br />

                        <HiButton
                            className={classes.actionButton}
                            variant="contained"
                            color="primary"
                            onClick={handleSearchAll}
                        >
                            {p.t("common.search.buttons.search")}
                        </HiButton>
                    </div>
                ) : (
                    <div>
                        <Typography
                            variant="h6"
                            dangerouslySetInnerHTML={{
                                __html: p.t("common.search.no_results_title"),
                            }}
                        />
                        <Typography
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: p.t("common.search.no_results_subtitle"),
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default withStylesAndTranslate(stylesNoData)(NoData);
