import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles/index";

const useStylesCellMultipleLinks = makeStyles((theme) => ({
    root: {
        display: "contents",
        textAlign: "left",
        lineHeight: "20px",
    },
    label: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    link: {
        color: theme.palette.primary.main,
        cursor: "pointer",
    },
    marginAlign: { marginTop: "6px" },
}));

/**
 * Cette cellule permet d'afficher plusieurs sites web
 */
export const CellMultipleLinks = (props) => {
    const { value } = props;

    const classes = useStylesCellMultipleLinks(props);

    return (
        <div className={classNames(classes.root)}>
            {value.map((linkValue) => (
                <div className={classNames(classes.marginAlign)}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames(classes.label, classes.link)}
                        href={linkValue.substring(0, 4) === "http" ? linkValue : `//${linkValue}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {linkValue}
                    </a>
                </div>
            ))}
        </div>
    );
};

CellMultipleLinks.propTypes = {
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * Link value
     */
    value: PropTypes.array,
};
