export const boolean = {
    id: "boolean",
    data: [
        {
            id: "true",
            label: "form.fields.boolean.true",
        },
        {
            id: "false",
            label: "form.fields.boolean.false",
        },
    ],
    translate: true,
};
