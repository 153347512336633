import DialogActions from "@mui/material/DialogActions/DialogActions";

import useStyles from "./stylesheet";

interface DialogFormActionsProps {
    children: React.ReactElement;
}

const DialogFormActions = (props: DialogFormActionsProps) => {
    const { children } = props;
    const classes = useStyles();

    return <DialogActions className={classes.root}>{children}</DialogActions>;
};

export default DialogFormActions;
