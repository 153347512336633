import { stringifySearchParams } from "../../utils/urls";

import {
    REGEX_COMPLETED_ELEMENT,
    REGEX_SEARCH_TEXT,
    REGEX_PARSE_W_WS,
    REGEX_PREVIOUS_VALUES,
    ELEMENTS_TYPES,
    TYPES,
    AND,
    OR,
    NOT,
    SOURCE_ORDER_DATE,
} from "./constants";

export class Suggestions {
    constructor(moduleConfig, api, suggestRouteParams) {
        this.suggestRoute = moduleConfig.suggestRoute;
        this.apiUrl = moduleConfig.apiUrl;
        this.attributes = moduleConfig.attributes || [];
        this.api = api;
        this.abortController = new AbortController();
        this.suggestRouteParams = suggestRouteParams;
    }

    /**
     * @param query
     * @param fieldName
     * @param params
     * @param rgpdCompliance
     * @returns {*}
     */
    query = (query, fieldName, params = {}, rgpdCompliance = false) => {
        if (!fieldName) {
            let qSearchFields = this.attributes.filter((attr) => attr.qsearch === true);
            fieldName = Object.keys(qSearchFields).filter((_fieldName) => {
                return (
                    (rgpdCompliance || !qSearchFields[_fieldName].rgpdCompliance) &&
                    qSearchFields[_fieldName].indexedValue !== false
                );
            });
        }

        const path =
            (this.suggestRouteParams?.baseUrl ?? this.suggestRoute) +
            stringifySearchParams({
                field: fieldName,
                query: query,
                ...params,
            });

        if (this.currentRequest) {
            // this.abortController.abort();
            this.abortController = new AbortController();
        }

        return this.api.fetchApi("GET", path, null, { url: this.apiUrl }, this.abortController);
    };

    /**
     * Method used to get all completion types corresponding to a type.
     * @param type
     * @returns {*[]}
     */
    getAllTypes = (type) => {
        let getSubs = function (_type) {
            let _subs = [];
            for (let i in TYPES) {
                if (_type === i && TYPES[i].length) {
                    _subs = [
                        ...TYPES[i].map(function (item) {
                            return item.id;
                        }),
                    ];
                }
            }
            return _subs;
        };
        let subs = getSubs(type);
        let allTypes = [...subs];
        let subLevelTypes = [];
        subs.map(function (sub) {
            let subLevel = getSubs(sub);
            if (subLevel.length) {
                subLevelTypes = subLevelTypes.concat(subLevel);
            }
            return sub;
        });
        if (subLevelTypes.length) {
            allTypes = [...subLevelTypes];
        }
        if (!allTypes.length) {
            allTypes = [type];
        }
        return allTypes;
    };

    /**
     * Method used to get Parent Type to display in front of matched value.
     * @param type
     * @returns {*}
     */
    getParentType = (type) => {
        let parentType = type;
        for (let i in TYPES) {
            for (let j in TYPES[i]) {
                if (i !== "all" && TYPES[i][j].id === type) {
                    parentType = i;
                }
            }
        }
        return parentType;
    };

    /**
     * Check if a string is an operator
     * @param p
     * @param string
     * @param operator
     * @returns {boolean}
     */
    isOperator = (p, string, operator = undefined) => {
        let value = string.trim();
        const and = p.t ? p.t(AND) : AND;
        const or = p.t ? p.t(OR) : OR;
        const not = p.t ? p.t(NOT) : NOT;
        if (operator) {
            switch (operator) {
                case and:
                    return value.toUpperCase() === and;
                case or:
                    return value.toUpperCase() === or;
                case not:
                    return value.toUpperCase() === not;
                case "(":
                    return value.toUpperCase() === "(";
                case ")":
                    return value.toUpperCase() === ")";
                default:
                    return false;
            }
        } else {
            const regex_operators = new RegExp(`^(?:${and}|${or}|${not}|\\()$`, "i");
            return regex_operators.test(value);
        }
    };

    /**
     * Check if a string is like an operator
     * @param t
     * @param string
     * @param operator
     * @returns {boolean}
     */
    isLikeOperator = (p, string) => {
        let value = string.trim().toUpperCase();
        const and = p.t ? p.t(AND) : AND;
        const or = p.t ? p.t(OR) : OR;
        const not = p.t ? p.t(NOT) : NOT;
        if (and === value || (and.length > value.length && and.indexOf(value) !== -1)) {
            return true;
        }
        if (or === value || (or.length > value.length && or.indexOf(value) !== -1)) {
            return true;
        }
        if (not === value || (not.length > value.length && not.indexOf(value) !== -1)) {
            return true;
        }
        return false;
    };

    /**
     * Parse input value and return array [ { item: , type: } ]
     */
    parse = (value, p, selectionStart) => {
        let elements = [];
        const and = p.t ? p.t(AND) : AND;
        const or = p.t ? p.t(OR) : OR;
        const not = p.t ? p.t(NOT) : NOT;
        const regex_and_or = new RegExp(`^(?:${and}|${or}|\\()\\s`, "i");

        //Parse items
        let items;
        let lastChar;
        if (selectionStart !== value.length) {
            // split string
            const firstString = value.slice(0, selectionStart);
            items = firstString.match(REGEX_PARSE_W_WS) || [];
            lastChar = firstString.slice(-1);
        } else {
            items = value.match(REGEX_PARSE_W_WS) || [];
            lastChar = value.slice(-1);
        }

        if (items.length) {
            // Parse last item only
            const index = items.length - 1;
            const query = items[index];
            // Element is a boolean operator or opened bracket
            // -> suggest prefix to a next element
            // ex: '... AND'
            // ex: '... OR '
            // ex: '... ('
            if (regex_and_or.test(query)) {
                elements.push({
                    item: query,
                    type: ELEMENTS_TYPES.boolean,
                    suggestions: [
                        {
                            id: not,
                            label: not,
                            origin: "operator",
                            type: "operator",
                            field: "",
                            query: query,
                        },
                        // {id: '(  )', label: '(  )'}, TODO - need to place carret and handle position relative suggest
                    ],
                });
            } else if (REGEX_COMPLETED_ELEMENT.test(query)) {
                // Element is a completed element or closed bracket
                // -> suggest boolean operator
                // ex: '... abc '
                // ex: '... "abc"'
                // ex: '... ) '

                // Add operators suggestions only if there is a space after word
                if (!lastChar || lastChar === " ") {
                    elements.push({
                        item: query,
                        type: ELEMENTS_TYPES.completed,
                        suggestions: this.isOperator(p, query)
                            ? []
                            : [
                                  {
                                      id: and,
                                      label: and,
                                      origin: "operator",
                                      type: "operator",
                                      field: "",
                                      query: query,
                                  },
                                  {
                                      id: or,
                                      label: or,
                                      origin: "operator",
                                      type: "operator",
                                      field: "",
                                      query: query,
                                  },
                              ],
                    });
                } else if (lastChar === ")") {
                    elements.push({
                        item: query,
                        type: ELEMENTS_TYPES.item,
                        suggestions: [],
                        query: query,
                    });
                }
            } else if (REGEX_SEARCH_TEXT.test(query)) {
                // Element is a simple text search or an exact term query (allow whitespace)
                // -> fetch suggestions (_suggest)
                // ex: '... abc'
                // ex: '... "abc '
                let options = [];
                if (items[index - 1]) {
                    if (!this.isOperator(p, items[index - 1])) {
                        options.push(
                            {
                                id: and,
                                label: and,
                                origin: "operator",
                                field: "",
                                type: "operator",
                                query: query,
                            },
                            {
                                id: or,
                                label: or,
                                origin: "operator",
                                field: "",
                                type: "operator",
                                query: query,
                            },
                            {
                                id: not,
                                label: not,
                                origin: "operator",
                                type: "operator",
                                field: "",
                                query: query,
                            }
                        );
                    } else if (
                        this.isOperator(p, items[index - 1], and) ||
                        this.isOperator(p, items[index - 1], or)
                    ) {
                        options.push({
                            id: not,
                            label: not,
                            origin: "operator",
                            type: "operator",
                            field: "",
                            query: query,
                        });
                    }
                    // Filter operators with query
                    options = options.filter(
                        (option) =>
                            items[index - 1] &&
                            option.id.toLowerCase().indexOf(query.toLowerCase()) > -1
                    );
                }
                elements.push({
                    item: query,
                    type: ELEMENTS_TYPES.item,
                    suggestions: options,
                    query: query,
                });
            }
        }

        //Returned Array containing objects representing query tokens.
        return elements;
    };

    /**
     * Method used to format correctly new value.
     *
     * @param selectedSuggestion Objet de la suggestion sélectionnée
     * @param previousValue      string ou objet de la valeur de l'input avant la sélection de la suggestion
     * @param t
     * @param selectionStart
     * @param lastSuggestionInserted
     * @returns {string}
     */
    getNewValue = (
        selectedSuggestion,
        previousValue,
        p,
        selectionStart,
        lastSuggestionInserted
    ) => {
        let _newValue = selectedSuggestion.id;
        const and = p.t ? p.t(AND) : AND;
        const or = p.t ? p.t(OR) : OR;
        const not = p.t ? p.t(NOT) : NOT;
        const matchReservedWords = [and, or, not, "( )"];
        let matches;

        let previousValueString = previousValue;
        if (typeof previousValue === "object") {
            previousValueString = previousValue.value ? previousValue.value : "";
        }

        let leftString = previousValueString;
        let rightString = "";

        if (selectionStart !== previousValueString.length) {
            leftString = previousValueString.slice(0, selectionStart);
            rightString = previousValueString.slice(selectionStart);
        }

        // If selection is an operator
        if (matchReservedWords.includes(selectedSuggestion.id)) {
            matches = leftString.match(REGEX_PREVIOUS_VALUES);
            // Last match = last item from input value
            let isLastMatchKeyword =
                Array.isArray(matches) && this.isLikeOperator(p, matches[matches.length - 1]);
            // Query is last text typed by user
            let isAndOrQuery =
                selectedSuggestion.query.trim().toLowerCase() === and.toLowerCase() ||
                selectedSuggestion.query.trim().toLowerCase() === or.toLowerCase();

            if (
                isLastMatchKeyword &&
                !(isAndOrQuery && selectedSuggestion.id.toLowerCase() === not.toLowerCase())
            ) {
                // => replace last item by the selection
                _newValue = [
                    ...matches.slice(0, -1).map((item) => item.trim()),
                    selectedSuggestion.id,
                ].join(" ");
            } else {
                // => add keyword at end of string
                _newValue = leftString.trim() + ` ${selectedSuggestion.id}`;
            }
        } else {
            // Autocomplete
            matches = leftString.match(REGEX_PREVIOUS_VALUES);
            let suggestionString = selectedSuggestion.id;
            if (!suggestionString) {
                // Format suggestion properly depending on origin
                if (selectedSuggestion.origin === "history") {
                    suggestionString = selectedSuggestion.query;
                } else if (selectedSuggestion.origin === "keyword") {
                    suggestionString = `${selectedSuggestion.field}`;
                } else if (selectedSuggestion.origin === "operator") {
                    suggestionString = `${selectedSuggestion.label}`;
                } else if (selectedSuggestion.selection) {
                    suggestionString = selectedSuggestion.field + selectedSuggestion.selection;
                    if (selectedSuggestion.sourceField !== SOURCE_ORDER_DATE) {
                        suggestionString = '"' + suggestionString + '"';
                    }
                }
            } else {
                suggestionString = `"${suggestionString}"`;
            }

            if (lastSuggestionInserted && lastSuggestionInserted.length > 0 && matches !== null) {
                // Parse last suggestion inserted & remove it
                let lastSuggestionMatches = lastSuggestionInserted.match(REGEX_PREVIOUS_VALUES);
                lastSuggestionMatches.forEach((item) => {
                    let lastIndex = matches.lastIndexOf(item);
                    matches.splice(lastIndex, 1);
                });
                _newValue = [...matches.map((item) => item.trim()), suggestionString].join(" ");
            } else {
                _newValue =
                    matches !== undefined && matches !== null
                        ? [
                              ...matches.slice(0, -1).map((item) => item.trim()),
                              suggestionString,
                          ].join(" ")
                        : suggestionString;
            }
        }

        // Paste right string to the end
        if (rightString.length) {
            if (rightString.slice(0, 1) === " ") {
                _newValue += rightString;
            } else {
                _newValue += ` ${rightString}`;
            }
        }

        return _newValue;
    };

    /**
     * Join elements on a well formatted query
     * - 1 whitespace between each elements
     * - (todo) keywords in uppercase
     * - (todo ?) brackets coherence (as much opened brackets as closed brackets)
     * @param elements
     * @returns {*}
     */
    join = (elements) => {
        let items = [];
        elements.forEach(function (i) {
            items.push(i.item);
        });
        return items.join(" ");
    };
}
