import { hasOneValue } from "./arrays";
import { highlightString } from "./strings";
import { fetchReferences } from "../services/references/actions";
import { getReference } from "../services/references/utils";
import { cleanFilePrefix } from "./export";

export function getAccountLookupFilterFilename(accountNames, polyglot) {
    let filename;
    if (!accountNames) {
        return null;
    }
    accountNames = accountNames.split("--");
    let accFilters = [];
    accountNames.forEach((accountName) => {
        if (!accountName.match(/NOT\s".*"/)) {
            accFilters.push(accountName);
        }
    }, []);
    if (accFilters.length > 1) {
        filename = polyglot.t("account_selector.n_accounts", accFilters.length);
    } else {
        filename = accFilters[0].replace(/"/g, "");
    }
    return cleanFilePrefix(filename);
}

/**
 * Build account list and business list from /accounts results
 *
 * @param accountDatas
 * @returns {{accountList, businessList}}
 */
export function buildAccountAndBusinessListFromAccountDatas(accountDatas) {
    let accountList = {};
    let businessList = {};
    accountDatas.forEach((account, index) => {
        let { business, accountId, ...accountProps } = account;
        accountList[accountId] = {
            ...accountProps,
            accountId: accountId.toString(),
            businessId: business.businessId.toString(),
        };
        if (!(business.businessId in businessList)) {
            businessList[business.businessId] = {
                ...business,
                businessId: business.businessId.toString(),
            };
        }
    });

    return {
        account: accountList,
        business: businessList,
    };
}

/**
 * Build account list and business list from /accounts results
 *
 * @param accountDatas
 * @returns {{accountList, businessList}}
 */
export function buildAccountAndBusinessMapFromAccountDatas(accountDatas) {
    let accountList = new Map();
    let businessList = new Map();
    accountDatas.forEach((account, index) => {
        let { business, accountId, ...accountProps } = account;
        accountList.set(accountId.toString(), {
            ...accountProps,
            accountId: accountId.toString(),
            businessId: business.businessId.toString(),
            business,
        });
        if (!(business.businessId in businessList)) {
            businessList.set(business.businessId.toString(), {
                ...business,
                businessId: business.businessId.toString(),
            });
        }
    });

    return {
        account: accountList,
        business: businessList,
    };
}

/**
 * Deduce account display label & icon from selected account, account entities and business entities
 *
 * @param polyglot
 * @param selectedAccountIdList
 * @param accountEntities
 * @param businessEntities
 * @param currentAccountCount
 * @param hasLoading - default true, will set label to "Loading..." if entitie isn't found else set label to '1 account'
 * @returns {{name: string, label: (*|string), icon: string, title: *}}
 */
export function buildAccountDisplayPropsFromEntities(
    polyglot,
    selectedAccountIdList = [],
    accountEntities = {},
    businessEntities = {},
    currentAccountCount = 0,
    hasLoading = true
) {
    let name = "_account";
    let label = "";
    let icon = "fa-tag";
    let color = undefined;

    switch (selectedAccountIdList.length) {
        case 0: // O accounts selected > Everywhere
            name = "_every";
            label = polyglot.t("top_bar.every_account");
            icon = "fa-archive";
            break;

        case 1:
            let account = getAccountById(selectedAccountIdList[0], accountEntities);
            if (account.name === "_every") {
                name = "_every";
                label = polyglot.t("top_bar.every_account");
                icon = "fa-archive";
            } else if (account.loading) {
                if (hasLoading) {
                    name = "_loading";
                    label = polyglot.t("account_selector.loading_account");
                } else {
                    name = "_unloaded";
                    label = polyglot.t("account_selector.n_accounts", 1);
                }
            } else {
                label = account.name;
                color = account.color;
            }
            break;

        default:
            let selectedAccountIdListByBusiness = selectedAccountIdList.reduce(
                (memo, accountId) => {
                    let _account = getAccountById(accountId, accountEntities);
                    if (memo[_account.businessId]) {
                        memo[_account.businessId].push(accountId);
                    } else {
                        memo[_account.businessId] = [accountId];
                    }
                    return memo;
                },
                {}
            );

            let selectedBusinessIdList = Object.keys(selectedAccountIdListByBusiness);

            // On regarde si on a sélectionné que des business (avec tous leurs comptes)
            const onlyBusiness = selectedBusinessIdList.every((businessId) => {
                if (businessEntities instanceof Map) {
                    let business = businessEntities.get(businessId);
                    return (
                        business !== undefined &&
                        selectedAccountIdListByBusiness[businessId].length ===
                            business.accountIdList.length
                    );
                }
                return (
                    businessEntities[businessId] !== undefined &&
                    selectedAccountIdListByBusiness[businessId].length ===
                        businessEntities[businessId].accountIdList.length
                );
            });

            if (onlyBusiness && selectedBusinessIdList.length === 1) {
                // 1 seul business
                const business =
                    businessEntities instanceof Map
                        ? businessEntities.get(selectedBusinessIdList[0])
                        : businessEntities[selectedBusinessIdList[0]];
                name = "_business";
                label = business["denomination"];
                icon = "fa-tags";
            } else if (onlyBusiness) {
                // n business
                name = "_businesses";
                label = polyglot.t("account_selector.n_businesses", selectedBusinessIdList.length);
                icon = "fa-tags";
            } else {
                name = "_accounts";
                label = polyglot.t("account_selector.n_accounts", selectedAccountIdList.length);
            }
            break;
    }

    return {
        name: name,
        label: label,
        icon: icon,
        title: selectedAccountIdList
            .map((accountId) => {
                let account = getAccountById(accountId, accountEntities);
                return account.name;
            })
            .join(", "),
        color: color,
    };
}

/**
 * Build hierarchic account list (with business as parent) used for hierarchic select
 *
 * @param accountList
 * @param businessList
 * @param highlightQuery
 * @param selectedAccountIdList
 * @returns {array}
 */
export function buildHierarchicAccountList(
    accountList = new Map(),
    businessList = new Map(),
    highlightQuery = "",
    selectedAccountIdList = [],
    onlySelect = false
) {
    let nestedAccountList = new Map();
    if (accountList.size > 0) {
        for (let [accountId, account] of accountList) {
            if (!onlySelect || selectedAccountIdList.indexOf(accountId) >= 0) {
                let accountItem = {
                    id: accountId,
                    label: account.name,
                    color: account.color,
                    secondaryLabel: account.publicref.toString(),
                    ...(highlightQuery.length > 0 && {
                        labelHighlight: highlightString(account.name, highlightQuery),
                    }),
                    type: "icon",
                    icon: "fa-tag",
                    info: account.invoicing.invoicingCurrency,
                    level: 1,
                    isBusiness: false,
                    secondaryInline: false,
                };
                let business;

                // If businessId already exist in nestedAccountList, just push account
                if (nestedAccountList.has(account.businessId.toString())) {
                    business = nestedAccountList.get(account.businessId.toString());
                    business["accountList"].push(accountItem);
                } else if (businessList.has(account.businessId.toString())) {
                    // If businessId exist in businessList, add it
                    business = businessList.get(account.businessId.toString());
                    business["accountList"] = [accountItem];
                }

                if (business) {
                    nestedAccountList.set(account.businessId, business);
                }
            }
        }
    }

    // Sort businesses by denomination
    const sortedNestedAccountList = new Map(
        [...nestedAccountList].sort((a, b) =>
            a[1]["denomination"] === b[1]["denomination"]
                ? 0
                : a[1]["denomination"] > b[1]["denomination"]
                ? 1
                : -1
        )
    );

    let hierarchicAccountList = [];
    if (sortedNestedAccountList.size) {
        for (let [businessId, business] of sortedNestedAccountList) {
            hierarchicAccountList.push({
                id: businessId,
                label: business["denomination"],
                ...(highlightQuery.length > 0 && {
                    labelHighlight: highlightString(business["denomination"], highlightQuery),
                }),
                type: "icon",
                icon: "fa-tags",
                info: `${countSelectedAccountFromBusiness(business, selectedAccountIdList)}/${
                    business.accountIdList.length
                }`,
                secondaryInline: true,
                isBusiness: true,
                children: [...business.accountList],
            });
        }
    }
    return hierarchicAccountList;
}

/**
 * Build nested account list (with business as parent) used for SelectableNestedList
 *
 * - account is selected if accountId is in selectedAccountIdList OR businessId is in selectedBusinessIdList
 * - business is selected if businessId is in selectedBusinessIdList OR selectedAccountsCount === accountsCount
 *
 * @param accountList
 * @param businessList
 * @param selectedAccountIdList
 * @param selectedBusinessIdList
 * @param accountEntities
 * @param onlySelected
 * @returns {any[]}
 */
export function buildNestedAccountList(
    accountList = {},
    businessList = {},
    selectedAccountIdList = [],
    selectedBusinessIdList = [],
    accountEntities = {},
    onlySelected = false
) {
    let nestedAccountList = {};
    Object.values(accountList).forEach((account) => {
        let selected =
            selectedAccountIdList.includes(account.accountId) ||
            selectedBusinessIdList.includes(account.businessId);
        if (!onlySelected || selected) {
            // If businessId already exist in nestedAccountList, just push account and increment business selected account count if needed
            if (account.businessId in nestedAccountList) {
                nestedAccountList[account.businessId]["accountList"].push({
                    ...account,
                    selected: selected,
                });
                nestedAccountList[account.businessId].selectedAccountsCount += selected ? 1 : 0;
            } else if (account.businessId in businessList) {
                // If businessId exist in businessList, add it
                nestedAccountList[account.businessId] = businessList[account.businessId];
                nestedAccountList[account.businessId]["accountList"] = [
                    {
                        ...account,
                        selected: selected,
                    },
                ];
                nestedAccountList[account.businessId]["selected"] = selectedBusinessIdList.includes(
                    account.businessId
                );
                nestedAccountList[account.businessId].selectedAccountsCount = selected ? 1 : 0;
            }
        }
    });

    Object.values(nestedAccountList).forEach((business) => {
        if (business.selected) {
            business.selectedAccountsCount = business["accountsCount"];
        } else if (business["accountsCount"] === business.selectedAccountsCount) {
            // If all business accounts are selected, display business as selected
            business.selected = true;
        } else {
            // Look into selectedAccountIdList if account (not present in accountList) belong to business
            business.selectedAccountsCount += selectedAccountIdList.reduce((count, accountId) => {
                return accountEntities[accountId] &&
                    accountEntities[accountId].businessId === business["businessId"] &&
                    !business.accountList.map((account) => account.accountId).includes(accountId)
                    ? count + 1
                    : count;
            }, 0);
        }
    });

    return Object.values(nestedAccountList);
}

/**
 * Count selected account from business
 *
 * @param business
 * @param selectedAccountIdList
 * @returns {number}
 */
export function countSelectedAccountFromBusiness(business, selectedAccountIdList = []) {
    return business.accountIdList.reduce(
        (count, accountId) => count + (selectedAccountIdList.includes(accountId) ? 1 : 0),
        0
    );
}

/**
 * Filter entities by returning only props with key present in idList
 * @param entities
 * @param idList
 * @returns {*}
 */
export function filterEntitiesByIdList(entities, idList = []) {
    let entityList = new Map();
    idList.forEach((entityId) => {
        if (entityId !== "_every" && entities.get(entityId)) {
            entityList.set(entityId, entities.get(entityId));
        }
    });
    return entityList;
}

/**
 * Filter business entities by returning only business with at least one selected account
 *
 * @param businessEntities
 * @param accountIdList
 * @returns {any}
 */
export function filterBusinessEntitiesByAccountIdList(businessEntities, accountIdList = []) {
    let businesses = new Map();
    for (let [businessId, business] of businessEntities) {
        if (business.accountIdList.some((aId) => accountIdList.includes(aId))) {
            businesses.set(businessId, business);
        }
    }
    return businesses;
}

/**
 * Get account by id
 * return loading account if accountId isn't yet defined in accountList
 * @param accountId
 * @param accountList
 * @returns {*}
 */
export function getAccountById(accountId, accountList) {
    if (accountId === undefined) {
        return null;
    }
    let account;

    if (accountList instanceof Map) {
        if (accountList.size) {
            account = accountList.get(accountId);
        }
        if (account === undefined) {
            return {
                accountId: accountId,
                name: accountId,
                loading: true,
            };
        }
    } else {
        account = accountList[accountId];
        if (account === undefined) {
            return {
                accountId: accountId,
                name: accountId,
                loading: true,
            };
        }
    }

    return account;
}

/**
 * Parse selected account id list from url
 * @param url
 * @returns {Array}
 */
export function getAccountIdListFromUrl(url) {
    let selectedAccountIdList = [];
    if (url) {
        let groups = url.match(/accounts((-\d+)+)/);
        if (groups && groups[1]) {
            selectedAccountIdList = groups[1].match(/(\d+)/g).map((account) => {
                // Convert id into integer
                // return parseInt(account, 10);
                return account;
            });
        }
    }
    return selectedAccountIdList;
}

export function getAccountStatusLabel(status) {
    switch (status) {
        case "DELETED":
        case "SUSPENDED":
            return "account_selector.status_code.deactivated";
        case "PENDING":
        case "AWAITING_VALIDATION":
            return "account_selector.status_code.pending";
        default:
            return null;
    }
}

/**
 * Return account list from business account id list
 * @param business
 * @param accountList
 * @returns {*}
 */
export function getBusinessAccountList(business, accountList) {
    return business.accountIdList.reduce((memo, accountId) => {
        let account = getAccountById(accountId, accountList);
        if (account) {
            memo[accountId] = account;
        }
        return memo;
    }, {});
}

/**
 * Return oldest creation date of selected accounts and limit to 5 years old
 */
export function getMinimumCreatedDate(accounts, limiter = true) {
    let minimumDate = undefined;
    if (!(Object.keys(accounts).length === 0)) {
        minimumDate = new Date(
            Object.values(accounts).sort(function (a, b) {
                return Date.parse(a.dateCreated) - Date.parse(b.dateCreated);
            })[0]["dateCreated"]
        );
    }
    if (limiter) {
        let limitDate = new Date();
        limitDate.setDate(1);
        limitDate.setFullYear(limitDate.getFullYear() - 5);
        minimumDate = limitDate > minimumDate || !minimumDate ? limitDate : minimumDate;
    }
    return minimumDate;
}

/**
 * Take a list of id and a list of account and return the oldest creation date of selected
 * accounts and limit to 5 years old
 */
export function getMinimumCreatedDateWithAnIdList(accountEntities, accountIdList, limiter = true) {
    let minimumDate = undefined;
    if (
        accountIdList.length > 0 &&
        !hasOneValue(accountIdList, "_every") &&
        Object.keys(accountEntities).length > 0
    ) {
        accountIdList.forEach((id) => {
            let accountEntity = accountEntities[id];
            if ((!minimumDate || minimumDate > accountEntity.dateCreated) && accountEntity) {
                minimumDate = accountEntity.dateCreated;
            }
        });
    }

    if (limiter) {
        let limitDate = new Date();
        limitDate.setDate(1);
        limitDate.setFullYear(limitDate.getFullYear() - 5);
        minimumDate = limitDate > minimumDate || !minimumDate ? limitDate : minimumDate;
    }
    return new Date(minimumDate);
}

/**
 * Loop over businesses to find businesses whose have each of their accounts in selected account id list
 * @param selectedAccountIdList
 * @param businessEntities
 * @returns {*}
 */
export function getSelectedBusinessIdListFromSelectedAccountIdList(
    selectedAccountIdList = [],
    businessEntities = {}
) {
    if (selectedAccountIdList.length === 0) {
        return [];
    }
    return Object.values(businessEntities).reduce((selectedBusinessIdList, business) => {
        // Check that none business account is not in selected account id list
        if (
            business.accountIdList.length > 0 &&
            !business.accountIdList.some((accountId) => !selectedAccountIdList.includes(accountId))
        ) {
            return [...selectedBusinessIdList, business.businessId];
        }
        return selectedBusinessIdList;
    }, []);
}

/**
 * Loop over businesses to find businesses whose have each of their accounts in selected account id list
 * @param selectedAccountIdList
 * @param businessEntities
 * @returns {*}
 */
export function getSelectedBusinessIdMapFromSelectedAccountIdList(
    selectedAccountIdList = [],
    businessEntities = new Map()
) {
    if (selectedAccountIdList.length === 0) {
        return [];
    }
    return Array.from(businessEntities.values()).reduce((selectedBusinessIdList, business) => {
        // Check that none business account is not in selected account id list
        if (
            business.accountIdList.length > 0 &&
            !business.accountIdList.some((accountId) => !selectedAccountIdList.includes(accountId))
        ) {
            return [...selectedBusinessIdList, business.businessId];
        }
        return selectedBusinessIdList;
    }, []);
}

/**
 * Return the list of all the params restricted by account in a list (with translation if p object is given)
 * @param moduleId
 * @param params
 * @param attributes
 * @param p
 */
export const getFiltersWithRestrictedByAccountParams = (moduleId, params, attributes, p = null) => {
    let restrictedFilters = [];
    Object.keys(params).forEach((param) => {
        const hasValue = Array.isArray(params[param]) ? params[param].length > 0 : !!params[param];
        if (hasValue) {
            if (param.startsWith("fp_") && attributes[param.substring(3, param.length)]) {
                const paramName = param.substring(3, param.length);
                if (
                    attributes[paramName].formFieldOptions.restrictedBySelectedAccountList === true
                ) {
                    restrictedFilters.push(paramName);
                }
            } else if (attributes[param] && attributes[param].formFieldOptions) {
                if (attributes[param].formFieldOptions.restrictedBySelectedAccountList === true) {
                    restrictedFilters.push(param);
                }
            }
        }
    });

    let translatedRestrictedFilters = [];
    if (p) {
        restrictedFilters.map((paramName) =>
            translatedRestrictedFilters.push(p.t(`attributes.${moduleId}.${paramName}.tableLabel`))
        );
    }

    return p ? translatedRestrictedFilters : restrictedFilters;
};

/**
 *
 * @param ids
 * @param referenceName
 * @param moduleReferences
 * @param storeReferences
 * @param selectedAccountIdList
 * @param p
 * @param dispatch
 */
export const fetchMissingEntities = (
    ids,
    referenceName,
    moduleReferences,
    storeReferences,
    selectedAccountIdList,
    p,
    dispatch
) => {
    if (ids.length < 1) {
        return;
    }
    const entities = getReference(storeReferences, referenceName, {}, moduleReferences);
    let nbInStore = 0;
    if (entities) {
        entities.data.forEach((entity) => {
            if (ids.indexOf(entity.id.toString()) >= 0) {
                nbInStore++;
            }
        });
    }
    // Fetch entities only if one is missing
    if (nbInStore !== ids.length) {
        dispatch(
            fetchReferences(moduleReferences, referenceName, {
                accountIdList: selectedAccountIdList,
                p: p,
            })
        );
    }
};

// add info (trigram) on last level options (exclude append items)
export function addOptionsInfo(options, sortAppendList = []) {
    return options.map((option) => {
        if (sortAppendList.includes(option.id)) {
            return option;
        }

        // add info recursively
        if (option.children?.length > 0) {
            return {
                ...option,
                children: addOptionsInfo(option.children),
            };
        }

        return {
            ...option,
            info:
                typeof option.info !== "undefined"
                    ? option.info
                    : isNaN(option.id)
                    ? option.id.slice(0, 3).toUpperCase()
                    : "",
        };
    });
}
