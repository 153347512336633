import React, { useEffect, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useInterval, useTimeout } from "react-use";
import { useAsync } from "react-async";
import { useSnackbar } from "notistack";

import Download from "mdi-material-ui/Download";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import LinearProgress from "@mui/material/LinearProgress";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import * as cst from "./constants";
import { buildFilePrefix } from "../../../../utils/export";

const useStylesLiveExport = makeStyles((theme) => ({
    card: {
        maxWidth: 400,
        minWidth: 344,
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        backgroundColor: "#424242",
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    errorActionRoot: {
        padding: "8px 8px 8px 16px",
        backgroundColor: theme.palette.error.dark,
        justifyContent: "space-between",
    },
    errorActionRootText: { color: theme.palette.error.contrastText },
    icons: {
        marginLeft: "auto",
        height: 40,
        padding: 2,
    },
    close: {
        padding: "8px 8px",
        color: "#e0e0e0",
    },
    collapse: {
        padding: 16,
        backgroundColor: "#e0e0e0",
    },
    loader: {
        position: "relative",
        height: 36,
    },
    loaderIcon: {
        border: `2px solid ${theme.palette.success.main}`,
        borderRadius: "50%",
        fontSize: 34,
    },
    loaderBar: {
        minWidth: 250,
        position: "absolute",
        top: 8,
        left: 32,
        backgroundColor: "transparent",
    },
    loaderFilename: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        position: "absolute",
        top: 16,
        left: 40,
    },
    positive: { backgroundColor: theme.palette.success.main },
    snackbarHeader: { color: "inherit" },
}));

// API CALLS
const baseUrl = process.env.NX_CONSOLE_API_URL;
const headers = (token) => ({
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Authorization": "Bearer " + token,
});

/*
 * Create or update export
 */
const _createExport = async ([{ id, ...data }], { token }) => {
    const response = await fetch(`${baseUrl}/exports`, {
        headers: headers(token),
        method: "POST",
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};
const _getFilesByExport = async ([id], { token }) => {
    const response = await fetch(`${baseUrl}/exports/${id}/files`, {
        headers: headers(token),
        method: "GET",
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};
const _checkExportFile = async ([file], { token }) => {
    const response = await fetch(`${baseUrl}/export-files/${file.fileId}?hash=${file.hash}`, {
        headers: headers(token),
        method: "HEAD",
    });
    if (!response.ok) {
        return null;
    } // file does not exist yet
    return true;
};

/**
 * Create export from data.
 *
 * This component is called from Export Menu (live export).
 *
 * display
 * - creation snackbar with UNDO
 * - creation pending
 * - file pending
 * - file available for download
 *
 * This component is instantiate in notistack context, which is outside inherit context provider.
 * Therefore, you cannot use inherited contexts (i18n, authentication, user, ...).
 * You should use props to get this datas.
 *
 */
export const LiveExport = React.forwardRef((props, ref) => {
    const {
        p,
        token,
        moduleConfig,
        snackbarId,
        module,
        columns,
        filters,
        filePrefix: presetFilePrefix,
        totalHits,
        separator,
        pendingMessage,
        loadingMessage,
        availableMessage,
        failMessage,
        exportConfig,
    } = props;

    const classes = useStylesLiveExport();

    const { closeSnackbar } = useSnackbar();
    const [isReady, cancel] = useTimeout(3000);

    const accountList = useSelector((state) => state.app.global.entities.account);
    const businessList = useSelector((state) => state.app.global.entities.business);

    // Close snackbar
    const dismissSnackbar = () => closeSnackbar(snackbarId);

    // Undo - cancel and close snackbar
    const undo = () => {
        cancel();
        dismissSnackbar();
    };

    // build file prefix from selected accounts
    const filePrefix = useMemo(() => {
        if (presetFilePrefix) {
            return presetFilePrefix;
        }

        return buildFilePrefix(
            moduleConfig,
            filters,
            p,
            accountList,
            businessList,
            cst.MAX_FILE_PREFIX_LENGTH
        );
    }, [filters, accountList, businessList]);

    // Async create export
    const {
        data: exportData,
        error: exportError,
        run: createExport,
    } = useAsync({
        deferFn: _createExport,
        token,
    });

    // Async get files by export
    const {
        data: exportFilesData,
        error: exportFilesError,
        isPending: exportFilesIsPending,
        run: getFilesByExport,
    } = useAsync({
        deferFn: _getFilesByExport,
        token,
    });

    // Async check file exist
    const {
        data: fileIsAvailable,
        isPending: checkingFile,
        run: checkFile,
    } = useAsync({
        deferFn: _checkExportFile,
        token,
    });

    // 1 - Create export
    useEffect(() => {
        if (isReady()) {
            // Alert on close tab if export loading
            window.onbeforeunload = function (e) {
                e = e || window.event;
                if (e) {
                    e.returnValue = "";
                }
                return "";
            };

            createExport({
                module: module,
                filePrefix: filePrefix,
                columns: JSON.stringify(columns),
                filters: JSON.stringify(filters),
                urlFilters: window.location.search,
                totalHits: totalHits,
                status: "active",
                config: exportConfig,
                // live export static props
                emails: "",
                recurrence: "once",
                separator: separator || "semicolon",
            });
        }
    }, [isReady()]);

    // remove alert on close tab
    useEffect(() => {
        if (fileIsAvailable || exportError) {
            window.onbeforeunload = null;
        }
    }, [fileIsAvailable, exportError]);

    // check if file exist every 5s
    useInterval(
        () => {
            // 2 - Get export file infos (id and hash)
            if (!exportFilesData || exportFilesData.length === 0) {
                // exportFiles not already found
                if (!exportFilesIsPending) {
                    // not pending
                    getFilesByExport(exportData.exportId);
                }
            } else if (!fileIsAvailable && !checkingFile) {
                // 3 - Check if export file is available
                // file is not already available nor pending
                checkFile(exportFilesData[0]);
            }
        },
        exportData ? 5000 : null
    ); // start when ready

    // dismiss snackbar if user logout
    useEffect(() => {
        if (!token) {
            dismissSnackbar();
        }
    }, [token]);

    const checkTranslation = (key) => {
        return p.has(key) ? p.t(key) : key;
    };

    // Error snackbar
    if (exportError || exportFilesError) {
        let errorMsg;
        if (failMessage) {
            errorMsg = checkTranslation(failMessage);
        } else {
            errorMsg =
                exportError.message === "409"
                    ? p.t("common.search.export.snackbar.create_export_conflict")
                    : p.t("common.search.export.snackbar.create_export_failed");
        }

        return (
            <Card className={classes.card} ref={ref}>
                <CardActions classes={{ root: classes.errorActionRoot }}>
                    <Typography variant="subtitle2" classes={{ root: classes.errorActionRootText }}>
                        {errorMsg}
                    </Typography>
                    <div className={classes.icons}>
                        <IconButton
                            className={classes.close}
                            onClick={dismissSnackbar}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </CardActions>
            </Card>
        );
    }

    // creating export

    // message
    let message;
    if (fileIsAvailable) {
        if (availableMessage) {
            message = checkTranslation(availableMessage);
        } else {
            message = p.t("common.search.export.snackbar.export_file_available");
        }
    } else if (exportData) {
        if (loadingMessage) {
            message = checkTranslation(loadingMessage);
        } else {
            message = p.t("common.search.export.snackbar.create_export_file_pending");
        }
    } else if (pendingMessage) {
        message = checkTranslation(pendingMessage);
    } else {
        message = p.t("common.search.export.snackbar.create_export_pending");
    }

    // filename
    let filename = filePrefix;
    if (exportFilesData && exportFilesData[0]) {
        filename = exportFilesData[0].filename;
    }

    return (
        <Card className={classes.card} ref={ref} id="export-snackbar">
            <CardActions classes={{ root: classes.actionRoot }}>
                <Typography classes={{ root: classes.snackbarHeader }} variant="subtitle2">
                    {message}
                </Typography>
                <div className={classes.icons}>
                    {!isReady() && (
                        <HiButton
                            id="undo-export"
                            children={p.t("common.search.export.snackbar.undo")}
                            color="inherit"
                            onClick={undo}
                        />
                    )}
                </div>
            </CardActions>
            <Collapse in={!!isReady()} timeout="auto" unmountOnExit>
                <Paper className={classes.collapse}>
                    {fileIsAvailable ? (
                        // download button
                        <HiButton
                            href={`${baseUrl}/export-files/${exportFilesData[0].fileId}?hash=${exportFilesData[0].hash}`}
                            children={p.t("common.search.export.snackbar.download")}
                            color="primary"
                            onClick={dismissSnackbar}
                        />
                    ) : (
                        // loader
                        <div className={classes.loader}>
                            <Download className={classes.loaderIcon} />
                            <LinearProgress
                                className={classes.loaderBar}
                                classes={{ barColorPrimary: classes.success }}
                            />
                            <div id="export-snackbar-filename" className={classes.loaderFilename}>
                                {filename}
                            </div>
                        </div>
                    )}
                </Paper>
            </Collapse>
        </Card>
    );
});
