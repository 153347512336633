import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

const stylesCellRank = (theme) => ({
    wrapper: {
        width: 40,
        height: "100%",
        position: "sticky",
        left: 0,
        paddingRight: 8,
        backgroundColor: "inherit",
        display: "flex",
        lineHeight: "17px",
        alignItems: "center",
        textAlign: "right",
        color: theme.palette.neutral.main,
        zIndex: 11,
    },
    wrapperMobile: { position: "relative" },
    numericText: {
        width: "100%",
        display: "inline-block",
        fontSize: "1em",
    },
});

/**
 * Cette cellule permet d'afficher un volume ou un montant avec devise
 */
class CellRankClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Row number
         */
        value: PropTypes.number,
    };

    render() {
        const { classes, value, className } = this.props;

        return (
            <div
                className={classNames(classes.wrapper, className, {
                    [classes.wrapperMobile]: isMobile,
                })}
            >
                <div className={classes.numericText}>{value}</div>
            </div>
        );
    }
}

export const CellRank = withStyles(stylesCellRank)(CellRankClass);
