import { queryCache, useMutation, useQuery } from "react-query";
import { useApi } from "../../services/api";
import { useP } from "../../services/i18n";
import { useModule } from "../../services/module";

export function useFavoritesQuery() {
    const api = useApi();
    const module = useModule();
    return useQuery(["favorites", module.id], (key, _module) =>
        api.fetchApi("GET", `/favorites?module=${_module}&internal=${process.env.NX_IS_INTERNAL}`)
    );
}

export function useFavoritesQueryForModule(module) {
    const api = useApi();
    return useQuery(["favorites", module], (key, _module) =>
        api.fetchApi("GET", `/favorites?module=${_module}&internal=${process.env.NX_IS_INTERNAL}`)
    );
}

export function useFavoriteQuery(favoriteId) {
    const api = useApi();
    const module = useModule();
    return useQuery(
        ["favorites", favoriteId],
        (key, _favoriteId) => _favoriteId && api.fetchApi("GET", `/favorites/${_favoriteId}`),
        {
            initialData: () => {
                if (queryCache.getQueryData(["favorites", module.id])) {
                    return queryCache
                        .getQueryData(["favorites", module.id])
                        .find((f) => f.favoriteId === favoriteId);
                }
                return undefined;
            },
        }
    );
}

export function useFavoriteCreate() {
    const api = useApi();
    const p = useP();
    const module = useModule();
    return useMutation(
        (data) =>
            api.post([JSON.stringify(data)], {
                url: "/favorites",
                startedSnackbar: {
                    message: p.t("common.search.favorite.snackbar.create.request", {
                        name: data.name,
                    }),
                },
                successSnackbar: { message: p.t("common.search.favorite.snackbar.create.success") },
                failureSnackbar: { message: p.t("common.search.favorite.snackbar.create.failure") },
            }),
        {
            onMutate: (data) => {
                // snapshot value for an eventual rollback
                const snapshotValue = queryCache.getQueryData(["favorites", module.id]);
                // optimistically add favorite
                queryCache.setQueryData(["favorites", module.id], (old) => [data, ...old]);
                // rollback value
                return snapshotValue;
            },
            onSuccess: (data) => {
                // update favorite cache
                queryCache.setQueryData(["favorites", data.favoriteId], data);
                // replace created favorite (list)
                queryCache.setQueryData(["favorites", module.id], ([tmp, ...old]) => [
                    data,
                    ...old,
                ]);
            },
            onError: (error, variables, snapshotValue) => {
                if (error.toString() !== "undo") {
                    console.error("Fail to create favorite", error, variables);
                }
                // rollback
                queryCache.setQueryData(["favorites", module.id], snapshotValue);
            },
        }
    );
}

export function useFavoriteUpdate() {
    const api = useApi();
    const p = useP();
    return useMutation(
        (data) =>
            api.put([JSON.stringify(data)], {
                url: `/favorites/${data.favoriteId}`,
                pendingSnackbar: {
                    message: p.t("common.search.favorite.snackbar.update.request", {
                        name: data.name,
                    }),
                },
                successSnackbar: { message: p.t("common.search.favorite.snackbar.update.success") },
                failureSnackbar: { message: p.t("common.search.favorite.snackbar.update.failure") },
                undoable: true,
            }),
        {
            onMutate: (data) => {
                const snapshotValue = queryCache.getQueryData(["favorites", data.favoriteId]);
                queryCache.setQueryData(["favorites", data.favoriteId], data);
                return snapshotValue;
            },
            onError: (error, variables, snapshotValue) => {
                if (error.toString() !== "undo") {
                    console.error("Fail to update favorite", error, variables);
                }
                // rollback
                queryCache.setQueryData(["favorites", snapshotValue.favoriteId], snapshotValue);
            },
        }
    );
}

export function useFavoriteDelete({ rollback }) {
    const api = useApi();
    const p = useP();
    const module = useModule();
    return useMutation(
        (id) =>
            api.delete([], {
                url: `/favorites/${id}`,
                pendingSnackbar: { message: p.t("common.search.favorite.snackbar.delete.request") },
                successSnackbar: { message: p.t("common.search.favorite.snackbar.delete.success") },
                failureSnackbar: { message: p.t("common.search.favorite.snackbar.delete.failure") },
                undoable: true,
            }),
        {
            onMutate: (id) => {
                // update list
                const snapshotValue = queryCache.getQueryData(["favorites", module.id]);
                queryCache.setQueryData(["favorites", module.id], (old) =>
                    old.filter((f) => f.favoriteId !== id)
                );
                return snapshotValue;
            },
            onError: (error, id, snapshotValue) => {
                if (error.toString() !== "undo") {
                    console.error("Fail to delete favorite", error, id);
                }
                // rollback
                if (rollback && typeof rollback === "function") {
                    rollback(id);
                }
                queryCache.setQueryData(["favorites", module.id], snapshotValue);
            },
        }
    );
}
