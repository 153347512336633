import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import classNames from "classnames";

const stylesCellIcon = {
    wrapper: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    icon: { height: "auto" },
    label: {
        display: "inline-block",
        margin: "auto 0",
        marginLeft: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: 17,
        lineHeight: "17px",
        fontSize: "1em",
    },
};

/**
 * Cette cellule permet d'afficher une icône et un label (optionnel).
 * En vue small (s) elle affiche uniquement l'icône avec une largeur fixe.
 */
class CellIconClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Couleur de l'icône
         */
        color: PropTypes.string,
        /**
         * Icon name [from material-design-icons](https://materialdesignicons.com/)
         * used by HiIconBuilder
         */
        icon: PropTypes.string,
        /**
         * Label
         */
        label: PropTypes.string,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    static defaultProps = {
        view: "m",
        color: "neutral",
    };

    render() {
        const { classes, color, icon, label, view, className } = this.props;

        return (
            <div className={classNames(classes.wrapper, className)} title={label}>
                {icon && (
                    <HiIcon
                        color={color}
                        className={classes.icon}
                        height={24}
                        icon={icon}
                        width={40}
                    />
                )}
                {view !== "s" && label && <div className={classes.label}>{label}</div>}
            </div>
        );
    }
}

export const CellIcon = withStyles(stylesCellIcon)(CellIconClass);
