import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import CalendarClock from "mdi-material-ui/CalendarClock";
import CloseCircle from "mdi-material-ui/CloseCircle";
import EmailOutline from "mdi-material-ui/EmailOutline";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ChevronUp from "mdi-material-ui/ChevronUp";
import FilterOutline from "mdi-material-ui/FilterOutline";
import EyeOutline from "mdi-material-ui/EyeOutline";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useP } from "../../../../../services/i18n";
import { useUser } from "../../../../../services/user";
import makeStyles from "@mui/styles/makeStyles";

import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import Collapse from "@mui/material/Collapse";

import { buildAccountDisplayPropsFromEntities } from "../../../../../utils/entities";
import { removeExportIntent } from "../../../../../app/actions/appActions";
import { useModule } from "../../../../../services/module";
import { useModuleConfig } from "../../../../../services/config";
import { mapUrlParamsToSearchParams, parseUrlToParams } from "../../../../../utils/urls";
import { buildExportSearchUrl } from "../../../../../utils/export";
import { ErrorBoundary } from "../../../../../app/components/common/ErrorBoundary";
import { ExportFileList } from "./ExportFileList";
import { useInnerPage } from "../../../../../services/innerPage";
import { ExportForm } from "../../../../../common/components/Search/Export/Form";
import { ExportList } from "../../../../../common/components/Search/Export/List";

import { Filters } from "../../Filters";
import { setEventTracker } from "../../../../../services/tracker";
import { weekday } from "../constants";

const useStylesItems = makeStyles((theme) => ({
    root: {
        display: "block",
        padding: 12,
        minHeight: 48,
        "&:hover,&:focus-within": {
            backgroundColor: "#FFFFFF",
            outline: "none",
        },
    },
    body: { padding: "12px 48px 0px 48px" },
    filePrefix: {
        ...theme.typography.body2,
        color: "inherit",
        paddingLeft: 12,
    },
    filePrefixActive: {
        ...theme.typography.body2,
        color: "inherit",
        paddingLeft: 0,
    },
    title: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        "& svg": { color: theme.palette.neutral.main },
        "&:hover,&:focus": { outline: "none" },
    },
    titleActive: {
        marginLeft: -12,
        marginTop: -12,
    },
    titleSecondary: {
        ...theme.typography.body3,
        color: "inherit",
        paddingLeft: 8,
    },
    active: { backgroundColor: "#FFFFFF" },
    infoDiv: {
        display: "flex",
        alignItems: "center",
        padding: "4px 0px",
    },
    infoDivAccount: {
        display: "flex",
        padding: "4px 0px",
    },
    infoText: {
        ...theme.typography.body3,
        paddingLeft: 5,
    },
    emailsDiv: {
        ...theme.typography.body2,
        paddingTop: 34,
        paddingBottom: 34,
        textAlign: "left",
    },
    emailChip: {
        backgroundColor: theme.palette.background3,
        marginTop: 8,
    },
    button: { flex: 1 },
    btnGroup: {
        display: "flex",
        padding: "24px 48px",
    },
    icon: {
        fontSize: 18,
        color: theme.palette.neutral.main,
    },
    chevronIcon: {
        position: "absolute",
        right: 24,
    },
    rootFilters: {
        paddingLeft: 4,
        paddingTop: 0,
        maxWidth: "100%",
    },
    filtersDiv: {
        display: "flex",
        alignItems: "center",
    },
    filtersContent: {
        alignItems: "flex-start",
        marginBottom: 8,
    },
    preFilters: {
        marginTop: 4,
        display: "flex",
        alignItems: "center",
    },
    filtersContainer: { width: "100%" },
    exportStatus: {
        position: "absolute",
        right: 60,
    },
    expiredExport: { color: `${theme.palette.neutral.light}` },
}));

export function ExportListItem(props) {
    const { active, exportItem, onClose, onClick, onKeyDown, onCreateExport, formComponent } =
        props;

    const classes = useStylesItems();
    const p = useP();
    const user = useUser();
    const history = useHistory();
    const { attributes, id: moduleId } = useModule();
    const moduleConfig = useModuleConfig(moduleId);

    const { openInnerPage } = useInnerPage();

    const dispatch = useDispatch();
    const accountEntities = useSelector((state) => state.app.global.entities.account);
    const businessEntities = useSelector((state) => state.app.global.entities.business);
    const userSettings = useSelector((state) => state.app.settings.data);

    const hasExportV2 = useMemo(() => {
        return moduleConfig.features.includes("export-v2");
    }, [moduleConfig]);

    /** Go back to export list from export item */
    const goBack = useCallback(() => {
        onClose();
        setEventTracker("open list", { event_category: "search_export" });

        openInnerPage(
            <ExportList onCreateExport={onCreateExport} />,
            {
                fromTopBar: false,
                titleKey: "common.search.export.list.title",
            },
            "ExportList"
        );
    }, [openInnerPage, onCreateExport, onClose]);

    /*
     * Open recurring export form for update
     */
    const editItem = useCallback(() => {
        onClose();

        let form = <ExportForm {...exportItem} />;
        if (typeof formComponent !== "undefined") {
            form = { ...formComponent };
            form.props = { ...exportItem };
        }

        openInnerPage(
            form,
            {
                fromTopBar: false,
                titleKey: "common.search.export.form.title",
                goBack,
            },
            "ExportForm"
        );
    }, [exportItem, onClose, openInnerPage, onClose]);

    /*
     * Delete export
     */
    const deleteItem = useCallback(
        (event) => {
            dispatch(removeExportIntent(exportItem, p));
            if (event) {
                event.stopPropagation();
            }
            goBack();
        },
        [dispatch, exportItem, p]
    );

    /*
     * Redirect to search with export search params & grid config
     */
    const redirectToSearch = useCallback(() => {
        onClose();

        setEventTracker("redirect to search", { event_category: "search_export" });

        const newSearchUrl = buildExportSearchUrl(exportItem);

        history.push({ search: newSearchUrl });
    }, [exportItem, history, onClose]);

    const dateCreated = moment(exportItem.dateCreated);
    const nbDayInMonth = ((dateCreated.date() / 7) | 0) + 1; // integer division

    const filters = JSON.parse(exportItem.filters);
    const accountIdList = filters.account ? filters.account.split("--") : [];
    const accountDisplay = useMemo(
        () =>
            buildAccountDisplayPropsFromEntities(
                p,
                accountIdList,
                accountEntities,
                businessEntities,
                0,
                false
            ),
        [accountIdList, accountEntities, businessEntities, p]
    );

    /**
     * Return true if export includes GDPR compliance columns
     * @param exportItem
     * @returns {boolean}
     */
    const hasGdprInfos = useMemo(() => {
        const columns = exportItem.columns && JSON.parse(exportItem.columns);
        if (columns) {
            return Object.keys(columns).some((c) => {
                const attribute = attributes[c];
                return attribute && attribute.rgpdCompliance === true;
            });
        }
        return false;
    }, [attributes, exportItem]);

    const searchParams = useMemo(() => {
        return mapUrlParamsToSearchParams(
            attributes,
            parseUrlToParams(exportItem.urlFilters),
            false,
            {},
            user.rgpdCompliance
        );
    }, [attributes, exportItem, mapUrlParamsToSearchParams, parseUrlToParams]);

    const handleKeyDownBtn = useCallback((event) => {
        event.stopPropagation();
    }, []);

    const endDateItem = useMemo(() => {
        if (!exportItem.nbOccurrence && !exportItem.endRecurrenceDay) {
            return p.t("common.search.export.list.date_end.never");
        } else if (!!exportItem.nbOccurrence && !exportItem.endRecurrenceDay) {
            return p.t("common.search.export.list.date_end.after_nb", {
                smart_count: exportItem.nbOccurrence,
            });
        } else if (!exportItem.nbOccurrence && !!exportItem.endRecurrenceDay) {
            return p.t("common.search.export.list.date_end.after_day", {
                endDate: moment(exportItem.endRecurrenceDay).format("LL"),
            });
        }
        return null;
    }, [exportItem]);

    return (
        <div
            className={classNames(classes.root, {
                [classes.active]: active,
                [classes.expiredExport]: exportItem.status === "expired",
            })}
        >
            <div
                className={classNames(classes.title, { [classes.titleActive]: active })}
                onClick={onClick}
                onKeyDown={onKeyDown}
                tabIndex={0}
                data-export-id={exportItem.exportId}
            >
                {!active ? (
                    <CalendarClock />
                ) : (
                    <HiIconButton
                        onClick={deleteItem}
                        title={p.t("common.search.export.list.delete")}
                        onKeyDown={handleKeyDownBtn}
                    >
                        <CloseCircle />
                    </HiIconButton>
                )}
                <span
                    className={classNames({
                        [classes.filePrefix]: !active,
                        [classes.filePrefixActive]: active,
                    })}
                >
                    {exportItem.filePrefix}
                </span>
                {active && hasGdprInfos && (
                    <span className={classes.titleSecondary}>
                        -&nbsp;&nbsp;
                        {p.t("common.search.export.list.presence_of_personal_informations")}
                    </span>
                )}
                {exportItem.status === "expired" && (
                    <HiColoredLabel
                        fontSize={13}
                        label={p.t("common.search.export.status.expired")}
                        color="neutral"
                        classes={{ root: classes.exportStatus }}
                    />
                )}
                {!active ? (
                    <ChevronDown className={classes.chevronIcon} />
                ) : (
                    <ChevronUp className={classes.chevronIcon} />
                )}
            </div>

            <Collapse in={active} unmountOnExit>
                <div>
                    <div className={classes.body}>
                        <div className={classes.infoDiv}>
                            <CalendarClock className={classes.icon} />
                            <span className={classes.infoText}>
                                {!exportItem.dateUpdated
                                    ? p.t("common.search.export.list.date_created", {
                                          date: dateCreated.format("LL"),
                                      })
                                    : p.t("common.search.export.list.date_updated", {
                                          date: moment(exportItem.dateUpdated).format("LL"),
                                      })}
                                &nbsp;
                                {endDateItem}
                            </span>
                        </div>
                        <div className={classes.infoDivAccount}>
                            <HiIcon icon="fa-archive" color="neutral" size={18} />
                            <div className={classes.infoText}>
                                <HiColoredLabel
                                    fontSize={13}
                                    label={accountDisplay.label}
                                    color={accountDisplay.color}
                                />
                            </div>
                        </div>
                        {moduleId === "transaction" && (
                            <div className={classes.infoDiv}>
                                <EyeOutline className={classes.icon} />
                                <span className={classes.infoText}>
                                    {exportItem?.config?.granularity
                                        ? p.t(
                                              `modules.transaction.search.export.list.granularity.${exportItem.config.granularity}`
                                          )
                                        : p.t(
                                              "modules.transaction.search.export.list.granularity.transaction"
                                          )}
                                </span>
                            </div>
                        )}
                        <div className={classes.infoDiv}>
                            <EmailOutline className={classes.icon} />
                            <span className={classes.infoText}>
                                {p.t(
                                    `common.search.export.form.recurrence.${exportItem.recurrence}`,
                                    {
                                        nth: p.t(
                                            `common.search.export.form.recurrence.monthly_day_count.day_${nbDayInMonth}`
                                        ),
                                        day: dateCreated.format("dddd"),
                                        month: dateCreated.format("MMMM"),
                                        dayNb: dateCreated.format("D"),
                                    }
                                )}
                                {exportItem.recurrence === "weekly" &&
                                    `, ${p.t("common.search.export.list.weekRecurrence", {
                                        weekDay: p.t(
                                            `common.search.export.form.weekDayPicker.${
                                                weekday[exportItem.recurrenceDay]
                                            }`
                                        ),
                                    })}`}
                                {exportItem.recurrence === "monthly" &&
                                    `, ${p
                                        .t(
                                            `common.search.export.form.month_day_recurrence.${exportItem.recurrenceDay}`
                                        )
                                        .toLowerCase()}`}
                            </span>
                        </div>
                        <div className={classes.infoDiv}>
                            <HiIcon icon="fa-at" color="neutral" size={18} />
                            <span className={classes.infoText}>{user.email}</span>
                        </div>
                        <div className={classes.infoDiv}>
                            <HiIcon icon="fa-file-o" color="neutral" size={18} />
                            <span className={classes.infoText}>
                                {p.t(`common.search.export.list.separator.${exportItem.separator}`)}
                            </span>
                        </div>
                        <ErrorBoundary>
                            <div className={classNames(classes.infoDiv, classes.filtersContent)}>
                                <div className={classes.preFilters}>
                                    <FilterOutline className={classes.icon} />
                                    <span className={classes.infoText}>
                                        {p.t("common.search.export.list.filters")}&nbsp;:&nbsp;
                                    </span>
                                </div>
                                <Filters
                                    classes={{
                                        root: classes.rootFilters,
                                        container: classes.filtersContainer,
                                    }}
                                    attributes={attributes}
                                    accountEntities={accountEntities}
                                    businessEntities={businessEntities}
                                    dateFormatShort={
                                        userSettings ? userSettings.dateFormatShort : "L"
                                    }
                                    formParams={searchParams.formParams}
                                    excludeParams={searchParams.excludeParams}
                                    lookupParams={searchParams.lookupParams}
                                    columnParams={searchParams.columnParams}
                                    selectionParam={searchParams.selectionParam}
                                    userLocale={userSettings ? userSettings.language : ""}
                                    noHeader
                                    qsearch={searchParams.qsearch}
                                    selectedAccountIdList={searchParams.formParams.account}
                                    disableActions
                                />
                            </div>
                        </ErrorBoundary>
                    </div>
                    {hasExportV2 && (
                        <ErrorBoundary>
                            <ExportFileList
                                exportFiles={exportItem.exportFiles}
                                exportItem={exportItem}
                            />
                        </ErrorBoundary>
                    )}
                    <div className={classes.btnGroup}>
                        <HiButton
                            id="modify-settings"
                            className={classes.button}
                            color="primary"
                            variant="contained"
                            onClick={editItem}
                        >
                            {p.t("common.search.export.list.modify_settings")}
                        </HiButton>
                        {exportItem.urlFilters && (
                            <HiButton
                                id="access-results"
                                className={classes.button}
                                onClick={redirectToSearch}
                            >
                                {p.t("common.search.export.list.access_results")}
                            </HiButton>
                        )}
                    </div>
                </div>
            </Collapse>
        </div>
    );
}
