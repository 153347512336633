export const sentinelColorById = {
    NOT_LAUNCHED: "#737373",
    ACCEPTED: "#02A17B",
    AUTHENTICATE: "#4D5255",
    FORCE_AUTHENTICATE: "#20272B",
    PENDING: "#6864E7",
    CHALLENGED: "#FF8C50",
    BLOCKED: "#CB2B0B",
    ALLOWED: "#02A17B",
    DENIED: "#CB2B0B",
    OVERRIDE: "#02A17B",
    "ACCEPT WITH NO PREFERENCE": "#02A17B",
};

export const getSentinelColorById = (id) => {
    return sentinelColorById[id];
};

export const sentinelResultImgById = {
    NOT_LAUNCHED: "/assets/core/img/references/sentinel_results/not-launched.svg",
    ACCEPTED: "/assets/core/img/references/sentinel_results/accepted.svg",
    AUTHENTICATE: "/assets/core/img/references/sentinel_results/ask-force-3DS.svg",
    FORCE_AUTHENTICATE: "/assets/core/img/references/sentinel_results/ask-force-3DS.svg",
    PENDING: "/assets/core/img/references/sentinel_results/not-launched.svg",
    CHALLENGED: "/assets/core/img/references/sentinel_results/challenged.svg",
    BLOCKED: "/assets/core/img/references/sentinel_results/blocked.svg",
    OVERRIDE: "/assets/core/img/references/sentinel_results/accepted.svg",
    "ACCEPT WITH NO PREFERENCE": "/assets/core/img/references/sentinel_results/accepted.svg",
};

const sentinelResults = {
    id: "sentinel_results",
    data: [
        {
            id: "NOT_LAUNCHED",
            label: "ref.transaction.sentinel_results.NOT_LAUNCHED",
            label2: "ref.transaction.sentinel_results_char.NOT_LAUNCHED",
            color: sentinelColorById["NOT_LAUNCHED"],
            evaluation: "negative",
        },
        {
            id: "ACCEPTED",
            label: "ref.transaction.sentinel_results.ACCEPTED",
            label2: "ref.transaction.sentinel_results_char.ACCEPTED",
            color: sentinelColorById["ACCEPTED"],
            evaluation: "positive",
        },
        {
            id: "OVERRIDE",
            label: "ref.transaction.sentinel_results.OVERRIDE",
            label2: "ref.transaction.sentinel_results_char.OVERRIDE",
            color: sentinelColorById["OVERRIDE"],
            evaluation: "positive",
        },
        {
            id: "ACCEPT WITH NO PREFERENCE",
            label: "ref.transaction.sentinel_results.ACCEPT WITH NO PREFERENCE",
            label2: "ref.transaction.sentinel_results_char.ACCEPT WITH NO PREFERENCE",
            color: sentinelColorById["ACCEPT WITH NO PREFERENCE"],
            evaluation: "negative",
        },
        {
            id: "AUTHENTICATE",
            label: "ref.transaction.sentinel_results.AUTHENTICATE",
            label2: "ref.transaction.sentinel_results_char.AUTHENTICATE",
            color: sentinelColorById["AUTHENTICATE"],
            evaluation: "neutral",
        },
        {
            id: "FORCE_AUTHENTICATE",
            label: "ref.transaction.sentinel_results.FORCE_AUTHENTICATE",
            label2: "ref.transaction.sentinel_results_char.FORCE_AUTHENTICATE",
            color: sentinelColorById["FORCE_AUTHENTICATE"],
            evaluation: "neutral",
        },
        {
            id: "CHALLENGED",
            label: "ref.transaction.sentinel_results.CHALLENGED",
            label2: "ref.transaction.sentinel_results_char.CHALLENGED",
            color: sentinelColorById["CHALLENGED"],
            evaluation: "negative",
        },
        {
            id: "BLOCKED",
            label: "ref.transaction.sentinel_results.BLOCKED",
            label2: "ref.transaction.sentinel_results_char.BLOCKED",
            color: sentinelColorById["BLOCKED"],
            evaluation: "negative",
        },
    ],
    translate: true,
};

export default sentinelResults;
