const references = {
    marketplace_status: {
        id: "marketplace_status",
        data: [
            {
                id: "DEACTIVATED",
                label: "ref.mkp_config.mkp_status.DEACTIVATED",
                color: "light",
                icon: "RemoveCircle",
            },
            {
                id: "SUSPENDED",
                label: "ref.mkp_config.mkp_status.SUSPENDED",
                color: "error",
                icon: "RemoveCircle",
            },
            {
                id: "LIVE",
                label: "ref.mkp_config.mkp_status.LIVE",
                color: "success",
                icon: "Check",
            },
            {
                id: "HIPAY_PAYMENT_IMPORT",
                label: "ref.mkp_config.mkp_status.HIPAY_PAYMENT_IMPORT",
                color: "primary",
                icon: "HourglassBottom",
            },
            {
                id: "MKP_PAYMENT_IMPORT",
                label: "ref.mkp_config.mkp_status.MKP_PAYMENT_IMPORT",
                color: "primary",
                icon: "HourglassBottom",
            },
            {
                id: "VENDOR_SYNCHRONIZATION",
                label: "ref.mkp_config.mkp_status.VENDOR_SYNCHRONIZATION",
                color: "primary",
                icon: "HourglassBottom",
            },
            {
                id: "PENDING_HIPAY",
                label: "ref.mkp_config.mkp_status.PENDING_HIPAY",
                color: "primary",
                icon: "HourglassBottom",
            },
            {
                id: "PENDING_MERCHANT",
                label: "ref.mkp_config.mkp_status.PENDING_MERCHANT",
                color: "secondary",
                icon: "RemoveCircle",
            },
        ],
        translate: true,
    },
    log_level: {
        id: "log_level",
        data: [
            {
                id: "INFO",
                label: "ref.mkp_config.log_level.INFO",
                color: "primary",
            },
            {
                id: "ERROR",
                label: "ref.mkp_config.log_level.ERROR",
                color: "error",
            },
            {
                id: "WARNING",
                label: "ref.mkp_config.log_level.WARNING",
                color: "warning",
            },
        ],
        translate: true,
    },
    report_status: {
        id: "status",
        data: [
            {
                id: "ERROR",
                label: "ref.mkp_config.report_status.ERROR",
                color: "error",
            },
            {
                id: "IGNORED",
                label: "ref.mkp_config.report_status.IGNORED",
                color: "neutral",
            },
            {
                id: "SUCCESS",
                label: "ref.mkp_config.report_status.SUCCESS",
                color: "success",
            },
            {
                id: "WARNING",
                label: "ref.mkp_config.report_status.WARNING",
                color: "warning",
            },
        ],
        translate: true,
    },
};

export default references;
