const refundMethods = {
    id: "refund_methods",
    data: [
        {
            id: "no_method",
            label: "ref.transaction.refund_methods.no_method",
        },
        {
            id: "iban",
            label: "ref.transaction.refund_methods.iban",
        },
        {
            id: "own_method",
            label: "ref.transaction.refund_methods.own_method",
        },
    ],
    translate: true,
};

export default refundMethods;
