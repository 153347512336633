import { buildAccountDisplayPropsFromEntities, getAccountLookupFilterFilename } from "./entities";
import * as cst from "../common/components/Search/Export/constants";

/**
 * Clean filePrefix for exports
 * - joins term by '_'
 * - slice to max
 * - set to uppercase
 * - remove all non authorized special chars (except [, ], -, _, .)
 */
export function cleanFilePrefix(filePrefix, maxSize) {
    return removeUnauthorizedRegex(
        filePrefix
            .split(" ")
            .join("_")
            .slice(0, maxSize)
            .toUpperCase()
            .replace(/[^\wà-úÀ-Ú[\]\-_\s.]/gi, "")
    );
}

/**
 * @param filePrefix
 *
 * - PSYCHE-4614 => fix unauthorized words [casino_, casino., ...] from stock1
 * => current regex on stock1.allopass.com (?:pacific[ -_.,\"\'\|]*poker|[ -_.,\"\'\|]*casino[ -_.,\"\'\|]|slot[ -_.,\"\'\|]*machines|(?:random|free|internet)+[ -_.,\"\'\|]*slots|poker|casino[ -_.,\"\'\|](?:games|action)|bet(ting)?[ -_.,\"\'\|](?:at|on)[ -_.,\"\'\|](?:home|horse))
 */
export function removeUnauthorizedRegex(filePrefix) {
    // Remove unauthorized words : PSYCHE-4614
    const stock1UnauthorizedRegex = [
        {
            regex: /casino/gi,
            replaceValue: "CASIN_O",
        },
    ];
    stock1UnauthorizedRegex.forEach(({ regex, replaceValue }) => {
        const matches = filePrefix.match(regex);
        if (matches) {
            matches.forEach((match) => {
                filePrefix = filePrefix.replace(new RegExp(match, "i"), replaceValue);
            });
        }
    });

    return filePrefix;
}

export function buildFilePrefix(moduleConfig, filters, p, accountList, businessList, maxSize) {
    if (typeof moduleConfig?.export?.buildInitialFilePrefix === "function") {
        return cleanFilePrefix(
            moduleConfig.export.buildInitialFilePrefix(
                filters,
                p,
                accountList,
                businessList,
                maxSize
            ),
            cst.MAX_FILE_PREFIX_LENGTH
        );
    }

    // Return account name if lookup
    if (filters.account_name) {
        const lookupName = getAccountLookupFilterFilename(filters.account_name, p);
        if (lookupName) {
            return cleanFilePrefix(lookupName, cst.MAX_FILE_PREFIX_LENGTH);
        }
    }

    // Build account display to create default filename
    const accountDisplayProps = buildAccountDisplayPropsFromEntities(
        p,
        filters.account ? filters.account.split("--") : [],
        accountList,
        businessList
    );
    return cleanFilePrefix(accountDisplayProps.label, cst.MAX_FILE_PREFIX_LENGTH);
}

/**
 * Build export search url from export params
 *  - add accounts to form params
 *  - remove derived dates
 * @param exportItem
 * @return string
 */
export function buildExportSearchUrl(exportItem) {
    const exportFilters = JSON.parse(exportItem.filters);
    let newSearchUrl = "";
    let urlFilters = [...new URLSearchParams(exportItem.urlFilters).entries()].reduce(
        (sum, [key, val]) => Object.assign({ [key]: val }, sum),
        {}
    );
    if (exportFilters.account) {
        urlFilters["fp_account"] = exportFilters.account;
    }

    Object.keys(urlFilters).forEach((filterKey) => {
        if (!filterKey.includes("_derivedFrom") && !filterKey.includes("_derivedTo")) {
            if (newSearchUrl !== "") {
                newSearchUrl += "&";
            }
            newSearchUrl += `${filterKey}=${urlFilters[filterKey]}`;
        }
    });
    return newSearchUrl;
}
