import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFields = {
    type: null,
};

export const availableFields = [attributes.type.id];
