import { RSAA } from "redux-api-middleware";
import * as actionTypes from "./actionTypes";

/**
 * PUT /users/me/settings
 * - request snackbar
 * - success snackbar
 * @returns {*}
 */
export const updateSettings = (settings) => {
    return {
        [RSAA]: {
            endpoint: "/users/me/settings",
            method: "PUT",
            body: JSON.stringify(settings),
            types: [
                {
                    type: actionTypes.UPDATE_SETTINGS_REQUEST,
                    meta: { snackbar: { message: "modules.preferences.snackbar.request" } },
                },
                {
                    type: actionTypes.UPDATE_SETTINGS_SUCCESS,
                    meta: { snackbar: { message: "modules.preferences.snackbar.success" } },
                },
                {
                    type: actionTypes.UPDATE_SETTINGS_FAILURE,
                    meta: { snackbar: { message: "modules.preferences.snackbar.failure" } },
                },
            ],
        },
    };
};
