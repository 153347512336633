import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
    root: {
        width: 400,
    },
    button: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    snackbar: {
        "&>div": {
            ...theme.typography.body2,
            color: theme.palette.warning.contrastText,
        },
    },
    verificationTypeIcon: {
        color: theme.palette.secondary.dark,
        fontSize: 20,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        verticalAlign: "middle",
    },
}));

export default useStyles;
