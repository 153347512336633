import { formFieldTypes, displayTypes } from "@console/core";

const attributes = {
    avatar: {
        id: "avatar",
        displayType: displayTypes.DISPLAY_AVATAR,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
    },
    email: {
        id: "email",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
    },
    firstname: {
        id: "firstname",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
    },
    lastname: {
        id: "lastname",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
    },
    type: {
        id: "type",
        internal: true,
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "user_type",
    },
    status: {
        id: "status",
        displayType: displayTypes.DISPLAY_ICON,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            type: "image",
        },
        reference: "user_status",
    },
    phone: {
        id: "phone",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
    },
    company: {
        id: "company",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
    },
    department: {
        id: "department",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        reference: "user_departments",
    },
    partnership: {
        id: "partnership",
        internal: true,
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            lookup: true,
            exclude: false,
        },
        formFieldOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        reference: "user_partnerships",
    },
    country: {
        id: "country",
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: {
            exclude: false,
            lookup: true,
            ellipsis: "middle",
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        reference: "countries",
    },
    number_business: {
        id: "number_business",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            indicator: true,
        },
    },
    number_accounts: {
        id: "number_accounts",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            indicator: true,
        },
    },
    number_permissions: {
        id: "number_permissions",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            indicator: true,
        },
    },
    gdpr_access: {
        id: "gdpr_access",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { hasEmptyOption: false },
        reference: "user_gdpr",
    },
    created_at: {
        id: "created_at",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: true,
        },
    },
    updated_at: {
        id: "updated_at",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: true,
        },
    },
    role: {
        id: "role",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hideCheckbox: true,
            hasEmptyOption: false,
            multiple: false,
            searchable: true,
        },
        reference: "user_roles",
    },
    privileges: {
        id: "privileges",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        reference: "user_privileges",
    },
};

export default attributes;
