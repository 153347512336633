import { DisplayType } from "./DisplayType";

export enum DisplayOptionsType {
    NUMERIC = "numeric",
}

export enum EllipsisType {
    MIDDLE = "middle",
}

export enum DisplayOptionsDataType {
    PAYMENT_OPERATION = "payment_operation",
    CUSTOM_DATE = "custom_data",
}

export interface DisplayOptions {
    /**
     * Enable lookup
     */
    lookup?: boolean;

    /**
     * Enable exclude
     */
    exclude?: boolean;

    /**
     * Enable sorting
     */
    sortable?: boolean;

    /**
     * Enable filtering
     */
    filterable?: boolean;

    /**
     * Display options type
     */
    type?: DisplayOptionsType;

    /**
     * Notice type
     */
    noticeType?: DisplayType;

    /**
     * Precision
     */
    precision?: number;

    /**
     * Ellipsis type
     */
    ellipsis?: EllipsisType;

    /**
     * Amount stats
     */
    amountStats?: boolean;

    /**
     * If true, the format callback is used to display data
     */
    formatable?: boolean;

    /**
     * Format callback to change the data displayed
     */
    format?: (properties: any) => any;

    /**
     * Show a specific title
     */
    title?: (properties: object, p?: any) => string;

    /**
     * Clickable
     */
    clickable?: (can: any) => boolean;

    /**
     * Show a specific label
     */
    label?: (value: any, p: any) => string;

    /**
     * Color
     */
    color?: (properties: object) => string;

    /**
     * Data type
     */
    dataType?: DisplayOptionsDataType;

    /**
     * If true, an indicator is shown
     */
    indicator?: boolean;

    /**
     * If true, the cell width cannot be changed
     */
    fixedCellWidth?: boolean;
}
