import { ucFirst } from "@console/core";

const disputeAuthors = {
    id: "dispute_authors",
    endpoint: "/dispute_authors",
    withSelectedAccounts: true,
    reduceFunc: (previous, current) => {
        return current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"].toString(),
                      label: ucFirst(current["firstname"]) + " " + ucFirst(current["surname"]),
                  },
              ]
            : previous;
    },
};

export default disputeAuthors;
