import auth_status from "./auth_status";
import auth_methods from "./auth_methods";
import authentication_indicator from "./authentication_indicator";
import authentication_results from "./authentication_results";
import boolean_references from "./boolean_references";
import card_types from "./card_types";
import chargeback_types from "./chargeback_types";
import countries from "./countries";
import customer_types from "./customer_types";
import devices from "./devices";
import dsp2_statuses from "./dsp2_statuses";
import eci from "./eci";
import eci_out from "./eci_out";
import item_categories from "./item_categories";
import item_types from "./item_types";
import locales from "./locales";
import one_click from "./one_click";
import operation_mode from "./operation_mode";
import operation_statuses from "./operation_statuses";
import operation_types from "./operation_types";
import payment_methods from "./payment_methods";
import refund_methods from "./refund_methods";
import sca_preferences from "./sca_preferences";
import sca_sources from "./sca_sources";
import sdd_authentication_indicator from "./sdd_authentication_indicator";
import sdd_display_page from "./sdd_display_page";
import sentinel_results from "./sentinel_results";
import sentinel_review_results from "./sentinel_review_results";
import sentinel_rules_family from "./sentinel_rules_family";
import transaction_statuses from "./transaction_statuses";
import third_party_security from "./third_party_security";
import cancel_reasons from "./cancel_reasons";
import refund_reasons from "./refund_reasons";
import recurring_payment from "./recurring_payment";
import dispute_reasons from "./dispute_reasons";
import same_day_refund from "./same_day_refund";
import fraud_reported_by_acquirers from "./fraud_reported_by_acquirers";
import three_dsv2_integration from "./three_dsv2_integration";

export const static_datas = {
    auth_status,
    auth_methods,
    authentication_indicator,
    authentication_results,
    boolean_references,
    cancel_reasons,
    card_types,
    chargeback_types,
    countries,
    customer_types,
    devices,
    dispute_reasons,
    dsp2_statuses,
    eci,
    eci_out,
    item_categories,
    item_types,
    locales,
    one_click,
    operation_mode,
    operation_statuses,
    operation_types,
    payment_methods,
    recurring_payment,
    refund_methods,
    refund_reasons,
    same_day_refund,
    sca_preferences,
    sca_sources,
    sdd_authentication_indicator,
    sdd_display_page,
    sentinel_results,
    sentinel_review_results,
    sentinel_rules_family,
    transaction_statuses,
    third_party_security,
    three_dsv2_integration,
    fraud_reported_by_acquirers,
};

export * from "./transaction_statuses";
export default static_datas;
