import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { setTag } from "@sentry/react";
import moment from "moment-timezone";
import { useQueue } from "react-use";
import Polyglot from "node-polyglot";
import { useUser } from "../user";
import { I18nContext } from "./i18n-context";
import { locales } from "./locales";
import { AppLoading } from "../../app/components/AppLoading";
import * as lexicons from "../../common/constants/lexicons";

const FALLBACK_LANGUAGE = "en_US";
const FRENCH = "fr_FR";
const ATTRIBUTES = "attributes";
const REFERENCES = "references";
const GAMING_ATTRIBUTES = "gamings_attributes";
const GAMING_REFERENCES = "gamings_references";
/**
 * Return browser locale if available
 * (default en_US)
 *
 * @param defaultLocale
 * @returns {string}
 */
const getBrowserLocale = (defaultLocale = FALLBACK_LANGUAGE) => {
    // default value
    let value = defaultLocale;

    let lang = navigator.language.replace("-", "_");

    // HotFix for non supported languages
    if (lang !== "fr_FR" && lang !== "fr") {
        lang = FALLBACK_LANGUAGE;
    }

    if (
        lang &&
        lang.length === 5 && // format 'fr_FR'
        locales.hasOwnProperty(lang)
    ) {
        value = lang;
    } else if (lang && lang.length === 2) {
        Object.values(locales).forEach((locale) => {
            if (locale.id.indexOf(lang) === 0) {
                value = locale.id;
            }
        });
    }

    return value;
};

/**
 * Set moment locale
 * as moment-timezone is a peerDependency in console
 * this will set moment locale for every modules
 * @param locale
 */
const setMomentLocale = (locale) => {
    let iso2 = locale ? locale.toString().substring(0, 2).toLowerCase() : "en"; // format locale
    moment.updateLocale(iso2, { week: { dow: 1 } }); // week start on Monday
};

/**
 * Set moment timezone
 * as moment-timezone is a peerDependency in console
 * this will set moment locale for every modules
 * @param timezone
 */
const setMomentTimezone = (timezone) => {
    moment.tz.setDefault(timezone);
};

/**
 * Change the translation of gaming fields
 * @param modulePhrases
 * @param gamingPhrases
 */
const setGamingLexicon = (modulePhrases, gamingPhrases) => {
    if (modulePhrases && !!gamingPhrases) {
        Object.keys(gamingPhrases).forEach((key) => {
            let gamingTranslation = gamingPhrases[key];
            if (gamingTranslation && typeof gamingTranslation === "object") {
                modulePhrases[key]
                    ? setGamingLexicon(modulePhrases[key], gamingPhrases[key])
                    : (modulePhrases[key] = gamingTranslation);
            } else {
                modulePhrases[key] = gamingTranslation;
            }
        });
    }

    return modulePhrases;
};

/**
 * Fetch phrases by module and filename (current locale)
 *
 * (add nocache params with package version to disable browsers caching on new deployment)
 *
 * @param module
 * @param filename
 * @param locale
 * @param signal
 * @returns {Promise<Promise<any> | void>}
 */
const fetchPhrasesByFilename = (
    filename = "phrases",
    locale = FALLBACK_LANGUAGE,
    { signal } = {}
) => {
    const isGamingFile = !!filename.match(/(gaming_)\w+/g);
    const url = `/assets${isGamingFile ? "/gaming" : ""}/${filename}_${locale}.json`;
    return fetchPhrases(url, isGamingFile, locale, filename, signal);
};

const fetchPhrasesByModule = (
    module,
    filename = "phrases",
    locale = FALLBACK_LANGUAGE,
    { signal } = {}
) => {
    const isGamingFile = !!filename.match(/(gaming_)\w+/g);
    const url = `/assets/${module}/translate/${
        isGamingFile ? "gaming/" : ""
    }${filename}_${locale}.json`;
    return fetchPhrases(url, isGamingFile, locale, filename, signal);
};

const fetchPhrases = (url, isGamingFile, locale, filename, { signal } = {}) => {
    if (!locales.hasOwnProperty(locale)) {
        console.warn(`Cannot fetch ${url}`);
    }
    return fetch(`${url}?nocache=${process.env.NX_VERSION}`, { signal })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (![FALLBACK_LANGUAGE, FRENCH].includes(locale) && response.status === 404) {
                return fetchPhrasesByFilename(filename, FALLBACK_LANGUAGE, { signal });
            }
        })
        .catch((error) => {
            console.error(`An error occurred while fetching translation file ${url}`, error);
            if (!isGamingFile && ![FALLBACK_LANGUAGE, FRENCH].includes(locale)) {
                return fetchPhrasesByFilename(filename, FALLBACK_LANGUAGE, { signal });
            }
        });
};

/**
 * /!\ WARNING /!\
 * As the I18n is used by Console Master,
 * you should not introduce breaking changes !
 *
 * If updates are required, please tag old functions as deprecated.
 */
export function I18nProvider({ children }) {
    const [locale, setLocale] = useState();
    const [lexicon, setLexicon] = useState();
    const [phrases, setPhrases] = useState();
    const [fallbackPhrases, setFallbackPhrases] = useState();
    /*
     * Apply user language
     */
    const user = useUser();
    useEffect(() => {
        //user is null > set default locale (use case: Login page)
        if (user === null) {
            setLocale(getBrowserLocale());
        } else if (user && user.settings) {
            // user is defined > set user locale && lexicon
            setLocale(user.settings.language);
            setLexicon(user.settings.lexicon);
            setMomentLocale(user.settings.language);
            setMomentTimezone(user.settings.timezone.name);
        }
    }, [user]);

    const settings = useSelector((state) => state.app.settings.data);
    useEffect(() => {
        if (settings) {
            /*
             * Apply user settings to moment
             */
            setMomentLocale(settings.language);
            setMomentTimezone(settings.timezone.name);
        }
    }, [settings]);

    /*
     * Apply phrases loaded to state
     * (i) we use temporary queue to avoid concurrent injectPhrases that could produce wrong override
     */
    const { add, remove, first, size } = useQueue();
    useEffect(() => {
        if (size > 0) {
            const { target, module, _p = null, _gaming = false, fallback = false } = first;
            const phraseTarget = phrases && phrases[target];
            const fallbackTarget = fallbackPhrases && fallbackPhrases[target];
            if (_gaming) {
                if (fallback) {
                    setFallbackPhrases({
                        ...fallbackPhrases,
                        [target]: {
                            ...fallbackTarget,
                            [module]: { ...setGamingLexicon(fallbackPhrases[target][module], _p) },
                        },
                    });
                } else {
                    setPhrases({
                        ...phrases,
                        [target]: {
                            ...phraseTarget,
                            [module]: { ...setGamingLexicon(phrases[target][module], _p) },
                        },
                    });
                }
            } else if (fallback) {
                setFallbackPhrases({
                    ...fallbackPhrases,
                    [target]: {
                        ...fallbackTarget,
                        [module]: _p,
                    },
                });
            } else {
                setPhrases({
                    ...phrases,
                    [target]: {
                        ...phraseTarget,
                        [module]: _p,
                    },
                });
            }
            remove();
        }
    }, [phrases, add, remove, first, size, fallbackPhrases]);

    // fetch translations files
    async function loadAllFiles(abortController) {
        try {
            // core/phrases_FR_fr.json
            let newPhrases = await fetchPhrasesByModule("core", "phrases", locale, abortController);
            let newFallbackPhrases;

            let i18nTypes = [ATTRIBUTES, REFERENCES];

            if (![FALLBACK_LANGUAGE, FRENCH].includes(locale)) {
                newFallbackPhrases = await fetchPhrasesByModule(
                    "core",
                    "phrases",
                    FALLBACK_LANGUAGE,
                    abortController
                );
            }

            // then fetch all modules phrases files
            const concatPhrases = (newPhrases, target, module, _phrases, _gaming = false) => {
                let ret;
                if (_gaming && newPhrases) {
                    if (target && module) {
                        ret = {
                            ...newPhrases,
                            [target]: {
                                ...(newPhrases && newPhrases[target]),
                                [module]: {
                                    ...setGamingLexicon(newPhrases[target][module], _phrases),
                                },
                            },
                        };
                    } else {
                        //Concat the core gaming_phrases translation (within module or target)
                        ret = {
                            ...newPhrases,
                            ...setGamingLexicon(newPhrases, _phrases),
                        };
                    }
                } else if (
                    [ATTRIBUTES, REFERENCES, GAMING_ATTRIBUTES, GAMING_REFERENCES].includes(module)
                ) {
                    ret = {
                        ...newPhrases,
                        [target]: {
                            ...(newPhrases && newPhrases[module]),
                            ..._phrases,
                        },
                    };
                } else {
                    ret = {
                        ...newPhrases,
                        [target]: {
                            ...(newPhrases && newPhrases[target]),
                            [module]: _phrases,
                        },
                    };
                }
                return ret;
            };

            if (lexicon === lexicons.GAMING_LEXICON) {
                i18nTypes.concat([GAMING_ATTRIBUTES, GAMING_REFERENCES]);

                // core/gaming_phrases_FR_fr.json
                await fetchPhrasesByModule("core", "gaming_phrases", locale, abortController).then(
                    (_p) => (newPhrases = concatPhrases(newPhrases, null, null, _p, true))
                );
                if (![FALLBACK_LANGUAGE, FRENCH].includes(locale)) {
                    await fetchPhrasesByModule(
                        "core",
                        "gaming_phrases",
                        FALLBACK_LANGUAGE,
                        abortController
                    ).then(
                        (_p) =>
                            (newFallbackPhrases = concatPhrases(
                                newFallbackPhrases,
                                null,
                                null,
                                _p,
                                true
                            ))
                    );
                }
            }

            let promises = [];
            // Will contain all the promise overriding translation files from the module
            let gaming_promises = [];
            let fallback_promises = [];

            const getModuleName = (filename) => {
                let module = filename;
                if (filename === REFERENCES || filename === GAMING_REFERENCES) {
                    module = "ref";
                } else if (filename === GAMING_ATTRIBUTES) {
                    module = ATTRIBUTES;
                }
                return module;
            };

            const fetchAndConcat = (filename, gaming = false) => {
                let module = getModuleName(filename);
                const phrases = fetchPhrasesByFilename(filename, locale, abortController).then(
                    (_p) => (newPhrases = concatPhrases(newPhrases, module, filename, _p, gaming))
                );
                gaming ? gaming_promises.push(phrases) : promises.push(phrases);
            };

            const fallback = (filename) => {
                let module = getModuleName(filename);
                if (![FALLBACK_LANGUAGE, FRENCH].includes(locale)) {
                    fallback_promises.push(
                        fetchPhrasesByFilename(filename, FALLBACK_LANGUAGE, abortController).then(
                            (_p) =>
                                (newFallbackPhrases = concatPhrases(
                                    newFallbackPhrases,
                                    module,
                                    filename,
                                    _p
                                ))
                        )
                    );
                }
            };

            i18nTypes.forEach((filename) => {
                fetchAndConcat(filename);
                fallback(filename);
            });

            // wait for each promise to be resolve
            await Promise.all(promises).then(() => Promise.all(gaming_promises));
            await Promise.all(fallback_promises);
            return {
                newPhrases,
                newFallbackPhrases,
            };
        } catch (error) {
            console.error("An error occurred while fetching translation files.", error);
            return error;
        }
    }

    /*
     * Fetch core translations then REPLACE phrases and set locale
     * Fetch modules master, attributes, references translations then UPDATE phrases
     */
    useEffect(() => {
        const abortController = new AbortController();
        if (locale) {
            // load then setPhrases
            loadAllFiles(abortController).then((_phrases) => {
                setPhrases(_phrases.newPhrases);
                setFallbackPhrases(_phrases.newFallbackPhrases);
            });
        }
        return () => abortController.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, lexicon]);

    /**
     * Set sentry scope
     */
    useEffect(() => {
        setTag("locale", locale);
    }, [locale]);

    const injectPhrases = useCallback(
        (module, filename = "phrases", target = "modules") => {
            if (module) {
                /*
                 * As translations are static files,
                 * if translation key already exist then do not fetch phrases
                 */
                let _gaming = !!filename.match(/(gaming_)\w+/g);
                if (!(phrases && phrases[target] && phrases[target][module]) || _gaming) {
                    fetchPhrasesByModule(module, filename, locale).then((_p) => {
                        // enqueue phrases
                        add({
                            target,
                            module,
                            _p,
                            _gaming,
                            fallback: false,
                        });
                    });
                    if (![FALLBACK_LANGUAGE, FRENCH].includes(locale)) {
                        fetchPhrasesByModule(module, filename, FALLBACK_LANGUAGE).then((_p) => {
                            // enqueue phrases
                            add({
                                target,
                                module,
                                _p,
                                _gaming,
                                fallback: true,
                            });
                        });
                    }
                }
            }
        },
        [locale, phrases, setPhrases, setFallbackPhrases]
    );

    const fallbackPolyglot = new Polyglot({
        locale: FALLBACK_LANGUAGE,
        phrases: fallbackPhrases,
        allowMissing: false,
    });

    const p = new Polyglot({
        locale,
        phrases,
        allowMissing: true,
        onMissingKey: (key, options) => {
            return fallbackPolyglot.t(key, options);
        },
    });

    return (
        <I18nContext.Provider
            value={{
                p,
                locale,
                phrases,
                setLocale,
                injectPhrases,
                lexicon,
                setLexicon,
            }}
        >
            {phrases ? (
                React.Children.only(children)
            ) : (
                <AppLoading message={"Loading translations..."} />
            )}
        </I18nContext.Provider>
    );
}
