import moize from "moize";
import { getReferenceAsEntities, getReferenceAsOptions } from "../services/references/utils";

/**
 *
 * @param attribute
 * @param args          - object with selected account id list { accountIdList: [...] }
 * @param storeRef      - references (from redux store)
 * @param p             - polyglot
 * @param references    - modules references (ex : @hipay/transaction-console/references/index.js)
 * @returns {*}
 */
export function getAttributeOptions(attribute, args, storeRef, p, references) {
    if (attribute === undefined || !attribute.reference) {
        return undefined;
    }
    return getReferenceAsOptions(storeRef, attribute.reference, p, args, references);
}

/**
 * Get Entity Object (flatten and keyed and translated) from OptionsList
 *
 * @param attribute
 * @param args
 * @param storeRef
 * @param p
 * @returns {*}
 */
export const getAttributeEntities = moize(
    (attribute, args, storeRef, p, references) => {
        if (attribute === undefined || !attribute.reference) {
            return null;
        }
        return getReferenceAsEntities(storeRef, attribute.reference, args, p, references);
    },
    { maxSize: 50 }
);
