import withStyles from "@mui/styles/withStyles";

import KpiTemplate from "./KpiTemplate";

export const styles = (theme) => ({
    content: {
        width: "100%",
        height: "100%",
        lineHeight: "30px",
    },
    value: {
        minWidth: "100%",
        ...theme.typography.h3,
        fontWeight: theme.typography.fontWeightRegular,
        color: "#FFFFFF",
    },
    addons: {
        minWidth: "100%",
        ...theme.typography.body2,
        color: "#FFFFFF",
    },
});

const KpiKeyValueClass = (props) => {
    const { classes, label, value, addons, str_value = null, onClick = null } = props;

    const title = str_value && str_value !== "" ? str_value : null;

    return (
        <KpiTemplate header={label} onClick={onClick}>
            <div className={classes.content}>
                <div className={classes.value} style={{ fontSize: props.size }} title={title}>
                    {value}
                </div>
                <div className={classes.addons}>{addons || ""}</div>
            </div>
        </KpiTemplate>
    );
};

export const KpiKeyValue = withStyles(styles)(KpiKeyValueClass);
