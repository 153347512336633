import React, { useEffect } from "react";
import { useAsync } from "react-async";
import { useTimeout } from "react-use";
import { useSnackbar } from "notistack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import HiButton from "@hipay/hipay-material-ui/HiButton";

export const useStylesDeleteExport = makeStyles((theme) => ({
    card: {
        maxWidth: 400,
        minWidth: 344,
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        backgroundColor: "#424242",
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    actionRootSuccess: {
        padding: "8px 8px 8px 16px",
        backgroundColor: theme.palette.success.main,
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    actionRootFail: {
        padding: "8px 8px 8px 16px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    icons: {
        marginLeft: "auto",
        height: 40,
        padding: 2,
    },
    close: {
        padding: "8px 8px",
        color: "#e0e0e0",
    },
    snackbarHeader: { color: "inherit" },
}));

// API CALLS
const baseUrl = process.env.NX_CONSOLE_API_URL;
const headers = (token) => ({
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Authorization": "Bearer " + token,
});

/*
 * Delete export
 */
const _deleteExport = async ([{ id, ...data }], { token }) => {
    const response = await fetch(`${baseUrl}/exports/${data.exportId}`, {
        headers: headers(token),
        method: "DELETE",
    });
    if (!response.ok) {
        throw new Error(response.status.toString());
    }
    return response;
};

export const DeleteExport = React.forwardRef((props, ref) => {
    const {
        p,
        token,
        snackbarId,
        exportItem,
        onResolve,
        pendingMessage,
        loadingMessage,
        successMessage,
        failMessage,
    } = props;

    const classes = useStylesDeleteExport();
    const { closeSnackbar } = useSnackbar();
    const [isReady, cancel] = useTimeout(3000);
    const _isReady = isReady();

    // Close snackbar
    const dismissSnackbar = () => closeSnackbar(snackbarId);

    // Undo - cancel and close snackbar
    const undo = () => {
        cancel();
        dismissSnackbar();
    };

    const {
        data: exportData,
        error: exportError,
        run: deleteExport,
    } = useAsync({
        deferFn: _deleteExport,
        token,
        onResolve: onResolve,
    });

    useEffect(() => {
        if (_isReady) {
            deleteExport(exportItem);
        }
    }, [_isReady]);

    // Dismiss snackbar if user logout
    useEffect(() => {
        if (!token) {
            dismissSnackbar();
        }
    }, [token]);

    const checkTranslation = (key) => {
        return p.has(key) ? p.t(key) : key;
    };

    // Error snackbar
    if (exportError) {
        return (
            <Card className={classes.card} ref={ref}>
                <CardActions classes={{ root: classes.actionRootFail }}>
                    <Typography variant="subtitle2">{checkTranslation(failMessage)}</Typography>
                    <div className={classes.icons}>
                        <IconButton className={classes.close} onClick={dismissSnackbar}>
                            <Close />
                        </IconButton>
                    </div>
                </CardActions>
            </Card>
        );
    }

    let cardActionsStyle = classes.actionRoot;
    if (_isReady && !!exportData) {
        cardActionsStyle = classes.actionRootSuccess;
    }

    return (
        <Card className={classes.card} ref={ref} id="export-snackbar">
            <CardActions classes={{ root: cardActionsStyle }}>
                <Typography classes={{ root: classes.snackbarHeader }} variant="subtitle2">
                    {_isReady
                        ? exportData
                            ? checkTranslation(successMessage)
                            : checkTranslation(loadingMessage)
                        : checkTranslation(pendingMessage)}
                </Typography>
                <div className={classes.icons}>
                    {_isReady ? (
                        <IconButton
                            id="close-export"
                            className={classes.close}
                            onClick={dismissSnackbar}
                        >
                            <Close />
                        </IconButton>
                    ) : (
                        <HiButton
                            id="undo-export"
                            children={p.t("common.search.export.snackbar.undo")}
                            color="inherit"
                            onClick={undo}
                        />
                    )}
                </div>
            </CardActions>
        </Card>
    );
});
