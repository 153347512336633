export const SEARCH_ACTION = "search";
export const LOOKUP_ACTION = "lookup";
export const EXCLUDE_ACTION = "exclude";
export const COPY_ACTION = "copy";
export const OPENNEWTAB_ACTION = "open_new_tab";
export const OPENFULLVIEW_ACTION = "open_full_view";
export const DIVIDER = "_";

export const SEARCH_ACTIONS = [
    LOOKUP_ACTION,
    EXCLUDE_ACTION,
    DIVIDER,
    COPY_ACTION,
    OPENNEWTAB_ACTION,
    OPENFULLVIEW_ACTION,
];
export const NOTICE_ACTIONS = [SEARCH_ACTION, DIVIDER, COPY_ACTION];
