import { useState } from "react";
import { useEventListener } from "../hooks/useEventListener";

const getBrowserFunctions = () => {
    const fnMap = [
        [
            "requestFullscreen",
            "exitFullscreen",
            "fullscreenElement",
            "fullscreenEnabled",
            "fullscreenchange",
            "fullscreenerror",
        ],
        // New WebKit
        [
            "webkitRequestFullscreen",
            "webkitExitFullscreen",
            "webkitFullscreenElement",
            "webkitFullscreenEnabled",
            "webkitfullscreenchange",
            "webkitfullscreenerror",
        ],
        // Old WebKit
        [
            "webkitRequestFullScreen",
            "webkitCancelFullScreen",
            "webkitCurrentFullScreenElement",
            "webkitCancelFullScreen",
            "webkitfullscreenchange",
            "webkitfullscreenerror",
        ],
        [
            "mozRequestFullScreen",
            "mozCancelFullScreen",
            "mozFullScreenElement",
            "mozFullScreenEnabled",
            "mozfullscreenchange",
            "mozfullscreenerror",
        ],
        [
            "msRequestFullscreen",
            "msExitFullscreen",
            "msFullscreenElement",
            "msFullscreenEnabled",
            "MSFullscreenChange",
            "MSFullscreenError",
        ],
    ];

    const ret = {};

    fnMap.forEach((fnSet) => {
        if (fnSet && fnSet[1] in document) {
            fnSet.forEach((_fn, i) => {
                ret[fnMap[0][i]] = fnSet[i];
            });
        }
    });

    return ret;
};

/**
 * useFullscreen
 * A hook that helps make the document fullscreen
 */
export function useFullscreen() {
    const fn = getBrowserFunctions();
    const eventNameMap = {
        change: fn.fullscreenchange,
        error: fn.fullscreenerror,
    };

    const [isFullscreen, setIsFullscreen] = useState(Boolean(document[fn.fullscreenElement]));
    const [element, setElement] = useState(document[fn.fullscreenElement]);

    useEventListener(fn.fullscreenchange, (event) => {
        setIsFullscreen(Boolean(document[fn.fullscreenElement]));
    });

    const request = (_element) =>
        new Promise((resolve, reject) => {
            const onFullScreenEntered = () => {
                setIsFullscreen(true);
                off("change", onFullScreenEntered);
                resolve();
            };

            on("change", onFullScreenEntered);

            _element = _element || document.documentElement;
            setElement(_element);

            Promise.resolve(_element[fn.requestFullscreen]()).catch(reject);
        });

    const on = (event, callback) => {
        const eventName = eventNameMap[event];
        if (eventName) {
            document.addEventListener(eventName, callback, false);
        }
    };

    const off = (event, callback) => {
        const eventName = eventNameMap[event];
        if (eventName) {
            document.removeEventListener(eventName, callback, false);
        }
    };

    const exit = () =>
        new Promise((resolve, reject) => {
            if (!document[fn.fullscreenElement]) {
                resolve();
                return;
            }

            const onFullScreenExit = () => {
                setIsFullscreen(false);
                off("change", onFullScreenExit);
                resolve();
            };

            on("change", onFullScreenExit);
            setElement(null);

            Promise.resolve(document[fn.exitFullscreen]()).catch(reject);
        });

    const toggle = (_element) => {
        return document[fn.fullscreenElement] ? exit() : request(_element);
    };

    return {
        isEnabled: Boolean(document[fn.fullscreenEnabled]),
        toggle,
        onChange: (callback) => {
            on("change", callback);
        },
        onError: (callback) => {
            on("error", callback);
        },
        request,
        exit,
        isFullscreen,
        element,
    };
}
