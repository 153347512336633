import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    paper: {
        overflow: "initial",
        padding: "22px",
    },
});

export default useStyles;
