const booleanReferences = {
    id: "boolean_references",
    data: [
        {
            id: "1",
            label: "ref.transaction.boolean_references.yes",
        },
        {
            id: "0",
            label: "ref.transaction.boolean_references.no",
        },
    ],
    translate: true,
};

export default booleanReferences;
