const sentinelProfiles = {
    id: "sentinel_profiles",
    endpoint: "/sentinel/profiles",
    reduceFunc: (previous, current) => [
        ...previous,
        {
            id: current["fraudProfileid"].toString(),
            label: current["name"],
        },
    ],
    withSelectedAccounts: true,
};

export default sentinelProfiles;
