import PropTypes from "prop-types";
import { useP } from "../../../../services/i18n";
import HiDateRangeSelector from "@hipay/hipay-material-ui/HiDatePicker/HiDateRangeSelector";
import { getDatesFromInterval } from "../../../../utils/dates";

export function DateSelectorField(props) {
    const {
        formField,
        dateFormat,
        error,
        fromError,
        id,
        onBlur,
        onChange,
        timezoneName,
        toError,
        value,
        minimumDate,
        moduleConfig,
    } = props;

    const p = useP();

    let _value = value || {};

    if (_value.interval && _value.interval !== "custom") {
        //Set Dates according to period if not custom.
        const dates = getDatesFromInterval(_value.interval);
        _value = {
            to: new Date(dates.to.format("YYYY-MM-DD")),
            from: new Date(dates.from.format("YYYY-MM-DD")),
            interval: _value.interval,
        };
    }

    const availableOptionKeys = moduleConfig.features.includes("sliding_periods")
        ? [
              "cd",
              "pd",
              "cw",
              "pw",
              "cm",
              "pm",
              "cq",
              "pq",
              "cy",
              "yy",
              "1",
              "7",
              "30",
              "365",
              "custom",
          ]
        : ["cd", "pd", "cw", "pw", "cm", "pm", "cq", "pq", "cy", "yy", "custom"];

    return (
        <HiDateRangeSelector
            idRange={id}
            idSelect={id}
            disableFutureDays
            onChange={onChange}
            minimumDate={minimumDate}
            onBlur={onBlur}
            returnSelectValue
            from={_value.from}
            to={_value.to}
            defaultPreset={_value.interval}
            translations={{
                today: p.t("form.fields.date_range_selector.today"),
                hour: p.t("form.fields.date_range_selector.hour"),
                minute: p.t("form.fields.date_range_selector.minute"),
                month: p.t("form.fields.date_range_selector.month"),
                quarter: p.t("form.fields.date_range_selector.quarter"),
                week: p.t("form.fields.date_range_selector.week"),
                year: p.t("form.fields.date_range_selector.year"),
                day: p.t("form.fields.date_range_selector.day"),
                days: p.t("form.fields.date_range_selector.days"),
                custom_period: p.t("form.fields.date_range_selector.custom_period"),
                yesterday: p.t("form.fields.date_range_selector.yesterday"),
                last_24h: p.t("form.fields.date_range_selector.last_24h"),
                from: p.t("form.fields.date_range_selector.from"),
                to: p.t("form.fields.date_range_selector.to"),
                to_now: p.t("form.fields.date_range_selector.to_now"),
                to_today: p.t("form.fields.date_range_selector.to_today"),
                current_week: p.t("form.fields.date_range_selector.current_week"),
                previous_week: p.t("form.fields.date_range_selector.previous_week"),
                current_month: p.t("form.fields.date_range_selector.current_month"),
                previous_month: p.t("form.fields.date_range_selector.previous_month"),
                current_quarter: p.t("form.fields.date_range_selector.current_quarter"),
                previous_quarter: p.t("form.fields.date_range_selector.previous_quarter"),
                short_week: p.t("form.fields.date_range_selector.short_week"),
                year_on_year: p.t("form.fields.date_range_selector.year_on_year"),
                yearAndMonth: p.t("form.fields.date_range_selector.yearAndMonth"),
                yearAndQuarter: p.t("form.fields.date_range_selector.yearAndQuarter"),
                current_year: p.t("form.fields.date_range_selector.current_year"),
                invalid_format: p.t("form.fields.date_range_selector.error.invalid_format"),
                to_superior_from: p.t("form.fields.date_range_selector.error.to_superior_from"),
                missing_date_from: p.t("form.fields.date_range_selector.error.missing_date_from"),
                missing_date_to: p.t("form.fields.date_range_selector.error.missing_date_to"),
                short_quarter: p.t("form.fields.date_range_selector.short_quarter"),
                date_superior_max_date: p.t(
                    "form.fields.date_range_selector.error.date_superior_max_date"
                ),
                preset_periods: p.t("form.fields.date_range_selector.preset_periods"),
                custom_periods: p.t("form.fields.date_range_selector.custom_periods"),
                custom_period_info: p.t("form.fields.date_range_selector.custom_period_info"),
                sliding_periods: p.t("form.fields.date_range_selector.sliding_periods"),
            }}
            availableOptionKeys={availableOptionKeys}
            fromError={error && !!fromError}
            toError={error && !!toError}
            errorText={fromError || toError}
            locale={p.currentLocale}
            labelFrom={p.t("form.fields.date_range_selector.from")}
            labelTo={p.t("form.fields.date_range_selector.to")}
            format={dateFormat}
            timezoneName={timezoneName}
            {...formField}
        />
    );
}

DateSelectorField.propTypes = {
    dateFormat: PropTypes.string,
    error: PropTypes.bool,
    formField: PropTypes.object,
    fromError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    id: PropTypes.string,
    moduleConfig: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    timezoneName: PropTypes.string,
    toError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    value: PropTypes.object,
};

DateSelectorField.defaultProps = {};
