import { forwardRef, useCallback, useMemo } from "react";
import { useCopyToClipboard } from "react-use";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import LookupIcon from "@mui/icons-material/GpsFixed";
import CloseCircleOutline from "mdi-material-ui/CloseCircleOutline";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import OpenInNew from "mdi-material-ui/OpenInNew";
import { useP } from "../i18n";
import { setEventTracker } from "../tracker";

export const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 12,
        color: theme.palette.neutral.main,
    },
}));

export const useListItemTextStyles = makeStyles((theme) => ({
    primary: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...theme.typography.body2,
    },
}));

/**
 * Menu Item Copy
 * copy value to clipboard
 * [ Copy "..." ]
 */
export const MenuItemCopy = forwardRef(({ id, data, onClose }, ref) => {
    const classes = useStyles();
    const listItemTextClasses = useListItemTextStyles();
    const p = useP();

    const { copyLabel, value, label = value } = data;

    // eslint-disable-next-line no-unused-vars
    const [, copyToClipboard] = useCopyToClipboard();
    const handleClickCopy = useCallback(() => {
        setEventTracker("copy", {
            event_category: "search_context",
            event_action: "search_context_copy",
        });

        copyToClipboard(copyLabel || label);
        onClose();
    }, [id, copyToClipboard, onClose, label, copyLabel]);

    return (
        <MenuItem ref={ref} id="search_context_menu_copy" onClick={handleClickCopy}>
            <CopyIcon className={classes.icon} />
            <ListItemText
                classes={listItemTextClasses}
                primary={p.t("common.context_menu.copy", { value: copyLabel || label })}
            />
        </MenuItem>
    );
});

/**
 * Menu Item Search
 * redirect to Search with lookup on value
 * [ Search "..." ]
 */
export const MenuItemSearch = forwardRef(
    ({ id, data, onClose, onSearch, isLookup = false }, ref) => {
        const classes = useStyles();
        const listItemTextClasses = useListItemTextStyles();
        const p = useP();

        const { value, label = value } = data;

        const handleClickLookup = useCallback(() => {
            setEventTracker("lookup", {
                event_category: "search_context",
                event_action: "search_context_lookup",
            });

            onSearch(id, value || value === 0 || value === false ? value : "EMPTY", "lookup");
            onClose();
        }, [id, onSearch, value]);

        const _label = useMemo(() => {
            if (!label && !value && value !== 0) {
                return p.t("common.context_menu.no_value");
            }
            return label;
        }, [label, value]);

        const menuItemLabelKey = useMemo(() => {
            return isLookup ? "common.context_menu.look_up" : "common.context_menu.search";
        }, [isLookup]);

        return (
            <MenuItem ref={ref} id="search_context_menu_lookup" onClick={handleClickLookup}>
                <LookupIcon className={classes.icon} />
                <ListItemText
                    classes={listItemTextClasses}
                    primary={p.t(menuItemLabelKey, { value: _label })}
                />
            </MenuItem>
        );
    }
);

export const MenuItemExclude = forwardRef(({ id, data, onClose, onSearch }, ref) => {
    const classes = useStyles();
    const listItemTextClasses = useListItemTextStyles();
    const p = useP();

    const { value, label = value } = data;

    const handleClickExclude = useCallback(() => {
        setEventTracker("exclude", {
            event_category: "search_context",
            event_action: "search_context_exclude",
        });

        onSearch(id, value || value === 0 || value === false ? value : "EMPTY", "exclude");
        onClose();
    }, [id, onSearch, value]);

    const _label = useMemo(() => {
        if (!label && !value && value !== 0) {
            return p.t("common.context_menu.no_value");
        }
        return label;
    }, [label, value]);

    return (
        <MenuItem ref={ref} id="search_context_menu_exclude" onClick={handleClickExclude}>
            <CloseCircleOutline className={classes.icon} />
            <ListItemText
                classes={listItemTextClasses}
                primary={p.t("common.context_menu.exclude", { value: _label })}
            />
        </MenuItem>
    );
});

export const MenuItemOpenNotice = forwardRef(({ rowId, onClose, onClick }, ref) => {
    const classes = useStyles();
    const listItemTextClasses = useListItemTextStyles();
    const p = useP();

    const handleClickOpenNotice = useCallback(() => {
        onClick();
        onClose();
    }, [onClick, onClose]);

    return (
        <MenuItem ref={ref} id="context_menu_open_notice" onClick={handleClickOpenNotice}>
            <OpenInNew className={classes.icon} />
            <ListItemText
                classes={listItemTextClasses}
                primary={p.t("common.context_menu.open_in_new_tab")}
            />
        </MenuItem>
    );
});
