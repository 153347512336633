import React from "react";
import Icon from "@mui/material/Icon";

const LockOffOutlineIcon = (props) => {
    return (
        <Icon
            style={{
                ...props.style,
                fontSize: props.size,
                fill: props.htmlColor,
            }}
            className={props.className}
        >
            <svg
                data-name="lock-off-outline-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <path
                    className="cls-1"
                    d="M23,21.4l-3-3h0l-2-2h0l-5.55-5.55h0l-2-2h0L9,7.37H9L7.18,5.54h0L5.1,3.47,3.69,4.89,7,8.18v.67H6a2,2,0,0,0-1.41.56A2.12,2.12,0,0,0,4,10.82v10a2.12,2.12,0,0,0,.61,1.41A2,2,0,0,0,6,22.82H18a1.91,1.91,0,0,0,1.4-.56A2,2,0,0,0,20,21.17l1.65,1.65Zm-5-.55H6v-10H9.62L12.84,14a2.07,2.07,0,0,0-.84-.18,2,2,0,0,0-1.41,3.38,2.12,2.12,0,0,0,1.41.61,2.08,2.08,0,0,0,1.4-.61A1.9,1.9,0,0,0,14,15.83a2.07,2.07,0,0,0-.18-.84L18,19.2Z"
                />
                <path
                    className="cls-1"
                    d="M9.89,4.72A3,3,0,0,1,12,3.83a3,3,0,0,1,3,3v2H13.31l2,2H18v2.72l2,2V10.82a2.08,2.08,0,0,0-.61-1.41A1.91,1.91,0,0,0,18,8.85H17v-2A5,5,0,0,0,8.46,3.29c-.12.13-.21.26-.32.39L9.57,5.11A3.89,3.89,0,0,1,9.89,4.72Z"
                />
            </svg>
        </Icon>
    );
};

export default LockOffOutlineIcon;
