import { useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import { useHistory, useLocation } from "react-router-dom";
import { useP } from "../../../services/i18n";
import { drySearchUrl, urlSearchParamsToString } from "../../../utils/urls";
import { getDatesFromInterval } from "../../../utils/dates";
import { useFavoriteQuery } from "../../queries/favorites";
import {
    FavoritesActionsContext,
    FavoritesStateContext,
    initialState,
} from "./FavoritesContext.context";

export function FavoritesContextProvider({ children }) {
    const history = useHistory();
    const location = useLocation();
    const p = useP();

    // Display / Hide favorites upon Search results (persist on LS)
    const [displayed, setDisplayed] = useLocalStorage(
        "hipay_display_favorites",
        initialState.displayed,
        {
            serializer: (value) => value.toString(),
            deserializer: (value) => value === "true",
        }
    );

    // Selected favorite id
    const [selectedFavoriteId, setSelectedFavoriteId] = useState();
    /* Previous Search
     * back to previous search state (filters, columns, ...) when deselect/delete favorite
     */
    const [previousSearch, setPreviousSearch] = useState();

    const { data } = useFavoriteQuery(selectedFavoriteId);

    const selectedFavorite = useMemo(() => {
        if (!data) {
            return null;
        }

        return {
            ...data,
            hasUpdate: data.urlSearch !== drySearchUrl(location.search),
            isEditable: !p.has(data.name), // cannot edit predefined favorite
        };
    }, [data, p, location]);

    const setSelectedFavorite = useCallback(
        (favorite) => {
            if (favorite === null) {
                history.push(`${previousSearch}&fromRedirect=true`);
                setSelectedFavoriteId(null);
            } else {
                // first selection of favorite
                if (!selectedFavoriteId) {
                    setPreviousSearch(drySearchUrl(location.search));
                }

                // Update dates from url
                let urlParams = new URLSearchParams(favorite.urlSearch);
                for (let [key, value] of urlParams) {
                    if (key.includes("date_interval") && value !== "custom") {
                        // Adapt dates
                        const { from, to } = getDatesFromInterval(value);
                        urlParams.set(key.replace("interval", "from"), from.format("YYYY-MM-DD"));
                        urlParams.set(key.replace("interval", "to"), to.format("YYYY-MM-DD"));

                        favorite.urlSearch = `?${drySearchUrl(urlParams.toString())}`;
                    }
                }

                // redirect to favorite search
                history.push(`?${urlSearchParamsToString(urlParams)}&fromRedirect=true`);
                setSelectedFavoriteId(favorite.favoriteId);
            }
        },
        [
            history,
            location,
            previousSearch,
            selectedFavoriteId,
            setPreviousSearch,
            setSelectedFavoriteId,
        ]
    );

    const state = useMemo(() => {
        return {
            displayed,
            selectedFavoriteId,
            selectedFavorite,
        };
    }, [displayed, selectedFavoriteId, selectedFavorite]);

    const actions = useMemo(() => {
        return {
            setDisplayed,
            setSelectedFavorite,
        };
    }, [setSelectedFavorite, setDisplayed]);

    return (
        <FavoritesStateContext.Provider value={state}>
            <FavoritesActionsContext.Provider value={actions}>
                {children}
            </FavoritesActionsContext.Provider>
        </FavoritesStateContext.Provider>
    );
}
