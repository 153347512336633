import { ThemeProvider } from ".";
import { useSelector } from "react-redux";

/**
 * ConsoleContextThemeProvider init the ThemeProvider with user settings in the Console context
 */
export const ConsoleContextThemeProvider = ({ children }) => {
    const settings = useSelector((state) => state.app.settings.data);

    const currency = settings?.currency;
    const locale = settings?.language?.slice(0, 2);

    return (
        <ThemeProvider locale={locale} currency={currency}>
            {children}
        </ThemeProvider>
    );
};
