import Icon from "@mui/material/Icon";

export const ExportByEmailIcon = (props) => (
    <Icon
        style={{
            ...props.style,
            fontSize: props.size || 24,
        }}
    >
        <svg viewBox="-12 0 256 256">
            <path
                d="M204.91 0H22.77A22.84 22.84 0 000 22.77v136.61a22.84 22.84 0 0022.77 22.77H89.3v-22.77H22.77V45.54l91.07 56.92 91.07-56.92v94.76h22.77V22.77A22.84 22.84 0 00204.91 0zm-91.07 79.69L22.77 22.77h182.14z"
                fill={props.color || "currentColor"}
            />
            <path
                d="M160.25 215.86l-56-56.05h32v-48.15h48.15v48.15h32zM104.2 232h112.1v16.17H104.2z"
                fill={props.color || "currentColor"}
            />
        </svg>
    </Icon>
);
