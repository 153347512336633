import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useP } from "../../../../services/i18n";
import { useModule } from "../../../../services/module";
import { useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Cog from "mdi-material-ui/Cog";
import ArrowDown from "mdi-material-ui/ArrowDown";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import Drag from "mdi-material-ui/Drag";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import { ErrorBoundary } from "../../../../app/components/common/ErrorBoundary";
import { useFavorites } from "../../../services/FavoritesContext";
import { useFavoriteUpdate } from "../../../queries/favorites";
import { buildUrlGrid, updateGridParamsInSearchUrl } from "../../../../utils/urls";
import { ChooseAndOrganize } from "../../ChooseAndOrganizeContainer/ChooseAndOrganize";
import { foldAccents } from "../../../../utils/strings";
import { FEATURE_SEARCH_SELECTION } from "../../../constants/features";
import { setEventTracker } from "../../../../services/tracker";

export const useItemStyles = makeStyles((theme) => ({
    item: {
        "&:hover, &:focus": {
            backgroundColor: theme.palette.action.hover,
            outline: "none",
        },
        lineHeight: "1",
        minHeight: 40,
        pointerEvents: "auto !important", // allow title on disabled
    },
    icon: { minWidth: 40 },
    text: { ...theme.typography.body2 },
}));

const Item = forwardRef((props, ref) => {
    const { name, onClick, icon, label, title, autoFocus } = props;

    const classes = useItemStyles(props);

    return (
        <MenuItem
            key={`menu-item-${name}`}
            id={`menu-item-${name}`}
            className={classes.item}
            onClick={onClick}
            dense
            title={title}
            ref={ref}
            autoFocus={autoFocus}
        >
            <ListItemIcon className={classes.icon} children={icon} sx={{ color: "neutral.main" }} />
            <ListItemText className={classes.text} primary={label} disableTypography />
        </MenuItem>
    );
});

const PanelOrderColumns = forwardRef((props, ref) => {
    const {
        onGoBack,
        columns,
        orderedColumns,
        persistOrderedColumns,
        updateOrderedColumns,
        defaultGrid,
        mandatoryColumnId,
        isDisplayRank,
        isDisplaySelector,
        onClose,
    } = props;

    const p = useP();
    const classes = useStyles();
    const { id: module, features } = useModule();
    const location = useLocation();
    const userSettings = useSelector((state) => state.app.settings.data);

    const [displaySelector, setDisplaySelector] = useState(isDisplaySelector);
    const [displayRank, setDisplayRank] = useState(isDisplayRank);

    const [{ selectedFavorite }] = useFavorites();
    const [updateFavorite] = useFavoriteUpdate();

    // current grid from url
    const currentGrid = useMemo(() => {
        const currentGridMatch = /grid=([0-9A-Z_]*)/.exec(location.search);
        return currentGridMatch && currentGridMatch[1];
    }, [location.search]);

    const currentRank = useMemo(() => {
        const currentRankMatch = /rank=(true|false)/.exec(location.search);
        return currentRankMatch && currentRankMatch[1];
    }, [location.search]);

    const currentSelector = useMemo(() => {
        const currentSelectorMatch = /selector=(true|false)/.exec(location.search);
        return currentSelectorMatch && currentSelectorMatch[1];
    }, [location.search]);

    /**
     * Find grid changes
     * - if favorite is selected, compare current grid to favorite grid
     * - else, compare current grid to user settings grid (or default)
     * if there is no changes, return null.
     */
    const gridHasChanges = useMemo(() => {
        if (currentGrid) {
            // favorite is selected
            if (selectedFavorite) {
                // but has no updates
                if (!selectedFavorite.hasUpdate) {
                    return false;
                }

                // cannot edit predefined favorite
                if (selectedFavorite && p.has(selectedFavorite.name)) {
                    return false;
                }

                // compare location grid to favorite grid
                const favoriteGrid = /grid=([0-9A-Z_]*)/.exec(selectedFavorite.urlSearch);
                const favoriteRank = /rank=(true|false)/.exec(selectedFavorite.urlSearch);
                const favoriteSelector = /selector=(true|false)/.exec(selectedFavorite.urlSearch);
                if (
                    favoriteGrid &&
                    favoriteGrid[1] === currentGrid &&
                    favoriteRank &&
                    favoriteRank[1] === currentRank &&
                    favoriteSelector &&
                    favoriteSelector[1] === currentSelector
                ) {
                    return false;
                }
            } else {
                // compare location grid to user settings (or default)
                let baseGrid = defaultGrid;
                let baseRank = true;
                let baseSelector = true;
                if (userSettings.searchResultsConfig && userSettings.searchResultsConfig[module]) {
                    baseGrid = userSettings.searchResultsConfig[module].columns;
                    baseRank = !!userSettings.searchResultsConfig[module].rank;
                    baseSelector = !!userSettings.searchResultsConfig[module].selector;
                }
                if (
                    buildUrlGrid(baseGrid) === currentGrid &&
                    baseRank.toString() === currentRank &&
                    baseSelector.toString() === currentSelector
                ) {
                    return false;
                }
            }
        } else {
            return false;
        }

        return true;
    }, [
        currentGrid,
        currentRank,
        currentSelector,
        selectedFavorite,
        userSettings.searchResultsConfig,
    ]);

    /**
     * Persist grid changes
     * - if a favorite is selected, persist it
     * - else, persist grid on user settings
     * @type {Function}
     */
    const persistGridChanges = useCallback(() => {
        if (selectedFavorite) {
            updateFavorite({
                ...selectedFavorite,
                urlSearch: updateGridParamsInSearchUrl(
                    selectedFavorite.urlSearch,
                    currentGrid,
                    currentRank,
                    currentSelector
                ),
            });
        } else {
            persistOrderedColumns();
        }
    }, [currentGrid, currentRank, currentSelector, persistOrderedColumns, selectedFavorite]);

    const filterSuggestions = useCallback((items, query) => {
        // If there is a searchValue, we filter the orderedColumns, otherwise all the columns are suggested
        return query
            ? items.filter((item) => {
                  let label = item.label || p.t(`attributes.${module}.${item.colId}.label`);
                  return (
                      foldAccents(label.toLowerCase()).search(
                          foldAccents(query.trim().toLowerCase())
                      ) !== -1
                  );
              })
            : items;
    }, []);

    const columnsLabels = useMemo(() => {
        let obj = {};
        defaultGrid.forEach((orderedColumn) => {
            obj[orderedColumn.id] = p.t(`attributes.${module}.${orderedColumn.colId}.label`);
        });
        return obj;
    }, [defaultGrid, module]);

    const sortItems = useCallback((list) => {
        return list.sort(
            (a, b) =>
                !b.displayed &&
                !a.displayed &&
                p
                    .t(`attributes.${module}.${a.colId}.label`)
                    .localeCompare(p.t(`attributes.${module}.${b.colId}.label`))
        );
    }, []);

    const toggleDisplayRank = useCallback(() => {
        let display = !displayRank;
        setDisplayRank(display);
        updateOrderedColumns(orderedColumns, display, displaySelector);
    }, [displayRank, displaySelector, orderedColumns, updateOrderedColumns]);

    const toggleDisplaySelector = useCallback(() => {
        let display = !displaySelector;
        setDisplaySelector(display);
        updateOrderedColumns(orderedColumns, displayRank, display);
    }, [displaySelector, displayRank, orderedColumns, updateOrderedColumns]);

    const pinnedItems = useMemo(() => {
        let items = [];
        items.push({
            id: "rank",
            displayed: displayRank,
            fixedCellWidth: true,
            draggable: false,
            onToggleDisplay: toggleDisplayRank,
            label: p.t("common.search.columns.rank"),
        });
        if (features.includes(FEATURE_SEARCH_SELECTION)) {
            items.push({
                id: "selector",
                displayed: displaySelector,
                fixedCellWidth: true,
                draggable: false,
                onToggleDisplay: toggleDisplaySelector,
                label: p.t("common.search.columns.selector"),
            });
        }
        return items;
    }, [features, displaySelector, displayRank, toggleDisplaySelector, toggleDisplayRank]);

    const handleUpdateOrderedColumns = useCallback(
        (orderedItems) => {
            updateOrderedColumns(orderedItems, displayRank, displaySelector);
        },
        [displayRank, displaySelector]
    );

    const handleRestoreDefaults = () => {
        setDisplayRank(false);
        setDisplaySelector(false);
    };

    return (
        <div ref={ref}>
            <div className={classes.header}>
                <HiIconButton onClick={onGoBack}>
                    <ArrowLeft />
                </HiIconButton>
                <span>{p.t("common.search.settings.order_columns")}</span>
            </div>
            <ErrorBoundary>
                <ChooseAndOrganize
                    name="search"
                    orderedItems={orderedColumns}
                    defaultItems={defaultGrid}
                    updateOrderedItems={handleUpdateOrderedColumns}
                    persistOrderedItems={persistGridChanges}
                    filterSuggestionsFn={filterSuggestions}
                    itemsLabels={columnsLabels}
                    displaySearchInput
                    hasUpdates={gridHasChanges}
                    itemsType="column"
                    columns={columns}
                    sortItemsFn={sortItems}
                    pinnedItems={pinnedItems}
                    onRestoreDefaults={handleRestoreDefaults}
                    mandatoryItemId={mandatoryColumnId}
                    onClose={onClose}
                />
            </ErrorBoundary>
        </div>
    );
});

export const useStyles = makeStyles((theme) => ({
    root: { display: "inline-block" },
    info: {
        fontWeight: "300",
        fontSize: 12,
    },
    icon: { "&primary": { color: theme.palette.primary.main } },
    header: { ...theme.typography.h3 },
}));

export const SettingsMenu = (props) => {
    const {
        onSort,
        columns,
        defaultGrid,
        orderedColumns,
        isDisplayRank,
        isDisplaySelector,
        handleColumnsOrderUpdate,
        persistOrderedColumns,
        mandatoryColumnId,
    } = props;

    const p = useP();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [panel, setPanel] = React.useState("menu");

    // open menu
    const handleOpen = useCallback(
        (event) => {
            setEventTracker("manage", {
                event_category: "search_settings",
                event_action: "manage_search_settings",
            });
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    // close menu
    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setPanel("menu");
    }, [setAnchorEl]);

    // reset sort from default
    const sortByDefault = useCallback(() => {
        setEventTracker("default_sort", {
            event_category: "search_settings",
            event_action: "default_sort_search_settings",
        });
        onSort(null, null);
        handleClose();
    }, [onSort, handleClose]);

    // open organize columns panel
    const openPanelMenu = useCallback(() => {
        setPanel("menu");
    }, [setPanel]);

    // open organize columns panel
    const openPanelOrderColumn = useCallback(() => {
        setEventTracker("organise_columns", {
            event_category: "search_settings",
            event_action: "organise_columns_search_settings",
        });
        setPanel("order-columns");
    }, [setPanel]);

    return (
        <div className={classes.root}>
            <IconButton
                id="settings-menu-button"
                aria-controls="settings-menu-button"
                aria-haspopup="true"
                onClick={handleOpen}
                title={p.t("common.search.buttons.settings")}
                className={classes.icon}
                size="large"
            >
                <Cog />
            </IconButton>
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ dense: true }}
                disableEnforceFocus
            >
                {panel === "menu" && [
                    <Item
                        key="sort-by-default"
                        name="sort-by-default"
                        onClick={sortByDefault}
                        icon={<ArrowDown />}
                        label={p.t("common.search.settings.sort_by_default")}
                        title={p.t("common.search.settings.sort_by_default")}
                    />,
                    <Item
                        key="order-columns"
                        name="order-columns"
                        onClick={openPanelOrderColumn}
                        icon={<Drag />}
                        label={p.t("common.search.settings.order_columns")}
                        title={p.t("common.search.settings.order_columns")}
                    />,
                ]}
                {panel === "order-columns" && (
                    <PanelOrderColumns
                        columns={columns}
                        onClose={handleClose}
                        onGoBack={openPanelMenu}
                        defaultGrid={defaultGrid}
                        orderedColumns={orderedColumns}
                        mandatoryColumnId={mandatoryColumnId}
                        isDisplayRank={isDisplayRank}
                        isDisplaySelector={isDisplaySelector}
                        updateOrderedColumns={handleColumnsOrderUpdate}
                        persistOrderedColumns={persistOrderedColumns}
                    />
                )}
            </Menu>
        </div>
    );
};
