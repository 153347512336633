import { StyledEngineProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { DesignSystemProvider } from "@hipay/design-system/context";

import { generateClassName } from "../../generateClassName";

export const ThemeProvider = ({ children, locale, currency }) => {
    /**
     * StyledEngineProvider: Inject Style Mui Engine first for backward compatibility with JSS
     * DesignSystemProvider: Init HiPay theme : Mui Theming + Pass Mui theme to Styled Components + Design System translations
     * StylesProvider: JSS backward (makeStyle) (To remove when new generate classnames)
     */
    return (
        <StyledEngineProvider injectFirst>
            <DesignSystemProvider locale={locale} currency={currency}>
                <StylesProvider generateClassName={generateClassName}>{children}</StylesProvider>
            </DesignSystemProvider>
        </StyledEngineProvider>
    );
};
