// import nested from "@mui/styles/ThemeProvider/nested";

const pseudoClasses = [
    "checked",
    "disabled",
    "error",
    "focused",
    "focusVisible",
    "required",
    "expanded",
    "selected",
];

const generateClassNameOptions = {
    disableGlobal: true,
    productionPrefix: "h",
};

// Returns a function which generates unique class names based on counters.
// When new generator function is created, rule counter is reset.
//
// It"s inspired by
// https://github.com/mui-org/material-ui/blob/next/packages/material-ui-styles/src/createGenerateClassName/createGenerateClassName.js
function createGenerateClassName(options = {}) {
    const { disableGlobal = false, productionPrefix = "jss" } = options;

    let ruleCounter = 0;

    const getNextCounterId = () => {
        ruleCounter += 1;
        if (process.env.NODE_ENV !== "production") {
            if (ruleCounter >= 1e10) {
                console.warn(
                    [
                        "Material-UI: You might have a memory leak.",
                        "The ruleCounter is not supposed to grow that much.",
                    ].join("")
                );
            }
        }
        return ruleCounter;
    };

    return (rule, styleSheet) => {
        const name = styleSheet.options.name;

        // Is a global static MUI style?
        if (name && name.indexOf("Mui") === 0 && !styleSheet.options.link && !disableGlobal) {
            // We can use a shorthand class name, we never use the keys to style the components.
            if (pseudoClasses.indexOf(rule.key) !== -1) {
                return `Mui-${rule.key}`;
            }

            const prefix = `${name}-${rule.key}`;

            // if (!styleSheet.options.theme[nested]) {
            //     return prefix;
            // }

            return `${prefix}-${getNextCounterId()}`;
        }

        const minifyClassNames =
            process.env.NX_MINIFY_CLASSNAMES === "true" || process.env.NX_ENV === "prod";

        if (minifyClassNames) {
            return `${productionPrefix}${getNextCounterId()}`;
        }

        return `${styleSheet.options.classNamePrefix}-${rule.key}-${getNextCounterId()}`;
    };
}

export const generateClassName = createGenerateClassName(generateClassNameOptions);
