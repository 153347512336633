import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import Scrollbars from "react-custom-scrollbars";
import HiSearchField from "@hipay/hipay-material-ui/HiForm/HiSearchField";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import Close from "mdi-material-ui/Close";
import PlusCircle from "mdi-material-ui/PlusCircle";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiSelectableList from "@hipay/hipay-material-ui/HiSelectableList";
import Popover from "@mui/material/Popover";
import { foldAccents } from "../../../../utils/strings";

import { useMap } from "react-use";

import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../../services/i18n";
import { useUser } from "../../../../services/user";
import { useModule } from "../../../../services/module";
import { setEventTracker } from "../../../../services/tracker";

const useStylesFormFieldsManager = makeStyles((theme) => ({
    menu: {
        width: 300,
        paddingTop: 16,
    },
    menuTitle: {
        ...theme.typography.h3,
        marginLeft: 12,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 16,
    },
    menuItemIcon: {
        width: 20,
        height: 20,
        padding: 10,
        color: theme.palette.neutral.main,
    },
    menuItemFiler: {
        color: theme.palette.neutral.dark,
        height: 40,
        display: "inline-block",
        lineHeight: "40px",
        textAlign: "center",
        width: "100%",
    },
    closeMenu: {
        width: "24px",
        height: "24px",
        color: theme.palette.neutral.main,
        cursor: "pointer",
        float: "right",
        marginRight: 10,
        marginTop: -5,
    },
    menuItemBorderBottom: { borderBottom: "1px solid #E4E7E8" },
    buttonGroup: { textAlign: "center" },
    buttonGroupSubmit: {
        textAlign: "center",
        margin: "20px auto",
    },
    addButton: {
        color: theme.palette.primary.main,
        height: 40,
        textTransform: "uppercase",
    },
    buttonIcon: { marginRight: 4 },
    buttonDisabled: { color: theme.palette.action.disabled },
    validationButton: {
        height: 36,
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    cancelButton: {
        height: 36,
        color: theme.palette.primary.main,
    },
    privateIcon: { margin: "0 4px -2px 4px" },
    textLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

export function FormFieldsManager(props) {
    const { availableFields, className, currentFields, onSubmit } = props;

    const classes = useStylesFormFieldsManager();
    const p = useP();
    const user = useUser();
    const { id: moduleId, attributes } = useModule();
    const accountEntities = useSelector((state) => state.app.global.entities.account);

    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [fieldMap, { set, reset }] = useMap({});

    const buttonContainerRef = useRef();

    const hasMultipleAccounts = useMemo(() => {
        return user.allAccountsAccess || Object.keys(accountEntities).length > 1;
    }, [user.allAccountsAccess, accountEntities]);

    /*
     * build list from props
     */
    useEffect(() => {
        reset();
        availableFields.forEach((f) => {
            let attribute = attributes[f];

            if (attribute) {
                // filter availableFields by user privilege
                if (
                    // attribute require user to have access to multiple accounts
                    (!attribute.needMultipleAccounts || hasMultipleAccounts) &&
                    // attribute require user to have access to gdpr
                    (!attribute.rgpdCompliance || user.rgpdCompliance) &&
                    // filter internal attribute
                    (!attribute.internal || process.env.NX_IS_INTERNAL === "true")
                ) {
                    set(f, currentFields.includes(f) && !attribute.multiple);
                }
            }
        });
    }, [
        availableFields,
        currentFields,
        hasMultipleAccounts,
        attributes,
        reset,
        set,
        user.rgpdCompliance,
    ]);

    /*
     * Open menu
     * @param e
     */
    const handleOpen = useCallback(
        (e) => {
            setEventTracker("add_new_filter", {
                event_category: "advanced_search",
                event_action: "advanced_search_add_new_filter",
            });
            setAnchorEl(e.currentTarget);
        },
        [setAnchorEl]
    );

    /*
     * Close and reset suggestion list
     */
    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setSearchValue("");
    }, [setAnchorEl, setSearchValue]);

    /*
     * Call onSubmit with selected field IDs and close component
     * remove currentFields
     */
    const handleSubmit = useCallback(() => {
        let newFieldIdList = Object.keys(fieldMap)
            .filter((f) => {
                // remove not selected
                // remove if attribute is undefined
                // remove existing and not multiple
                return (
                    fieldMap[f] &&
                    attributes[f] &&
                    (!currentFields.includes(f) || Boolean(attributes[f].multiple))
                );
            })
            .map((f) => {
                // if multiple, increment
                if (attributes[f].multiple) {
                    let usedIndexes = [];
                    currentFields.forEach((currentId) => {
                        let match = currentId.match(new RegExp(`${f}[_0-9]+$`, "ig"));
                        if (match) {
                            usedIndexes.push(currentId.split("_").pop());
                        }
                    });
                    let j = 0;
                    for (let i = 1; i <= usedIndexes.length; i++) {
                        if (!usedIndexes.includes(i.toString())) {
                            return `${f}_${i}`;
                        }
                        j = i;
                    }
                    return `${f}_${j + 1}`;
                }
                return f;
            });

        onSubmit(newFieldIdList);
        handleClose();
    }, [currentFields, fieldMap, attributes, handleClose, onSubmit]);

    /*
     * @param event
     * @param value
     */
    const handleSearch = (event, value) => setSearchValue(value);

    /*
     * Ajoute / Supprime l'id de l'item du selectedIdList
     * @param event
     * @param item
     */
    const toggleItem = (event, item) => {
        set(item.id, !fieldMap[item.id]);
    };

    const onKeyDown = useCallback((event) => {
        let nextItem;
        switch (event.key) {
            case "ArrowDown":
                event.preventDefault();
                nextItem = document.activeElement.nextSibling;
                break;
            case "ArrowUp":
                event.preventDefault();
                nextItem = document.activeElement.previousSibling;
                break;
            case "Tab":
                event.preventDefault();
                nextItem = buttonContainerRef.current.firstChild;
                break;
            case "Escape":
                handleClose();
                break;
            case " ":
                event.preventDefault();
                toggleItem(null, { id: event.target.id });
                break;
            case "Enter":
                event.preventDefault();
                handleSubmit();
                break;
            default:
                break;
        }
        if (nextItem) {
            nextItem.focus();
        }
    }, []);

    const effectiveFields = useMemo(() => {
        const foldSearchValue = foldAccents(searchValue.toLowerCase());

        return (
            Object.keys(fieldMap)
                // Translate label
                .map((f) => ({
                    id: f,
                    label: (
                        <>
                            {attributes[f]?.internal && (
                                <HiIcon
                                    size={14}
                                    icon="lock"
                                    color="neutral"
                                    className={classes.privateIcon}
                                />
                            )}
                            <span className={classes.textLabel}>
                                {p.t(`attributes.${moduleId}.${f}.formLabel`)}
                            </span>
                        </>
                    ),
                    foldLabel: foldAccents(p.t(`attributes.${moduleId}.${f}.label`).toLowerCase()),
                    title: p.t(`attributes.${moduleId}.${f}.label`),
                }))
                // Filter fields with search value
                .filter((el) => el.foldLabel.indexOf(foldSearchValue) !== -1)
                // Sort by label
                .sort((a, b) => {
                    if (a.foldLabel === b.foldLabel) {
                        return 0;
                    }
                    return a.foldLabel > b.foldLabel ? 1 : -1;
                })
        );
    }, [fieldMap, searchValue]);

    const effectiveDisabledIdList = useMemo(() => {
        return currentFields.filter(
            (f) =>
                !!attributes[f.replace(new RegExp("_[0-9]+$", "ig"), "")] &&
                !attributes[f.replace(new RegExp("_[0-9]+$", "ig"), "")].multiple
        );
    }, [currentFields]);

    const effectiveSelectedIdList = useMemo(() => {
        return Object.keys(fieldMap).filter(
            (f) => fieldMap[f] || (currentFields.includes(f) && !attributes[f].multiple)
        );
    }, [fieldMap, currentFields]);

    return (
        <div id="add-new-filters" className={classNames(classes.root, className)}>
            <HiButton color="primary" className={classes.addButton} onClick={handleOpen}>
                <PlusCircle classes={{ root: classes.buttonIcon }} />{" "}
                {p.t("common.search.buttons.new_filter")}
            </HiButton>
            <Popover
                id="form-fields-manager"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                classes={{ paper: classes.menu }}
                onClose={handleClose}
            >
                <div className={classes.menuTitle}>
                    <span>{p.t("common.search.form_fields_manager.title")}</span>
                    <Close classes={{ root: classes.closeMenu }} onClick={handleClose} />
                </div>

                <HiSearchField
                    itemList={[]}
                    onSearch={handleSearch}
                    placeholder={p.t("common.search.form_fields_manager.search")}
                    autoFocus
                />
                <Scrollbars autoHeight autoHeightMax={400}>
                    <HiSelectableList
                        disabledItemIdList={effectiveDisabledIdList}
                        itemList={effectiveFields}
                        onSelect={toggleItem}
                        onKeyDown={onKeyDown}
                        selectedItemIdList={effectiveSelectedIdList}
                    />
                </Scrollbars>
                <div className={classes.buttonGroupSubmit} ref={buttonContainerRef}>
                    <HiButton
                        id="form-fields-manager-apply"
                        color="primary"
                        variant="contained"
                        classes={{ root: classNames(classes.button, classes.validationButton) }}
                        onClick={handleSubmit}
                    >
                        {p.t("common.search.buttons.apply")}
                    </HiButton>
                    <HiButton
                        id="form-fields-manager-cancel"
                        classes={{ root: classNames(classes.button, classes.cancelButton) }}
                        onClick={handleClose}
                    >
                        {p.t("common.search.buttons.cancel")}
                    </HiButton>
                </div>
            </Popover>
        </div>
    );
}
