import queryString from "query-string";
import moize from "moize";
import { hasOneValue } from "../../utils/arrays";
import { isEmpty } from "../../utils/objects";

// ####################################################################################################################
// BUILDER FUNCTIONS

export const reduceFetchedReference = (data, reference, p) => {
    if (reference.reduceFunc !== undefined) {
        data = data.reduce(reference.reduceFunc, reference.reduceInitialValue || []);
    }

    if (reference.organizeFunc !== undefined) {
        data = reference.organizeFunc(data, p);
    }

    return data;
};

export const buildReferenceEndpoint = (reference, args = {}) => {
    if (!reference) {
        throw new Error("Undefined reference");
    }

    let endpoint =
        typeof reference.endpoint === "function"
            ? reference.endpoint(args) // use args in endpoint function (ex: sentinel_rules)
            : reference.endpoint;

    // generic args
    if (!isEmpty(args) && endpoint !== null) {
        let urlParams = {};

        // add selected accounts
        if (
            reference.withSelectedAccounts &&
            args.accountIdList &&
            args.accountIdList.length > 0 &&
            !hasOneValue(args.accountIdList, "_every")
        ) {
            urlParams["accountId"] = args.accountIdList.join("--");
        }

        // add currency
        if (args.currency) {
            urlParams["currency"] = args.currency;
        }

        if (!isEmpty(urlParams)) {
            endpoint += "?" + queryString.stringify(urlParams);
        }
    }

    return endpoint;
};

export const getReference = moize(
    (storeRef, name, args, references) => {
        if (!name) {
            return;
        }
        // if require dynamic reference
        if (references && references[name] !== undefined && !!references[name].endpoint) {
            // build endpoint from arguments
            const endpoint = buildReferenceEndpoint(references[name], args);

            // reference with same endpoint exist
            if (storeRef[name] && storeRef[name][endpoint]) {
                return storeRef[name][endpoint];
            }

            // return empty reference object
            return {
                data: [],
                loading: false,
                error: null,
                endpoint,
                unfetched: true,
            };
        } else if (references && references[name] && !!references[name]["data"]) {
            // should be static reference name
            return references[name];
        } else if (storeRef[name]) {
            // should be static reference name
            return storeRef[name];
        }
        // undefined reference
        return { error: `undefined reference ${name}` };
    },
    { maxSize: 50 }
);

export const getReferenceAsEntities = (storeRef, name, args, p, references) => {
    const reference = getReference(storeRef, name, args, references);
    if (reference && reference.data && !reference.loading) {
        return buildReferenceEntities(reference.data, reference.translate, p);
    }
    return null;
};

export const buildReferenceLabel = moize(
    (item, p) => {
        if (typeof item.label === "function") {
            return item.label(p);
        } else if (item.fallbackLabel) {
            return p.has(item.label) ? p.t(item.label, { smart_count: 1 }) : item.fallbackLabel;
        }
        return p.has(item.label)
            ? p.t(item.label, { smart_count: 1 })
            : item.label.split(".").pop();
    },
    {
        maxSize: 50,
        // onCacheHit: () => console.log('MEMOIZE - buildReferenceLabel')
    }
);

/**
 * Get Reference as Entity Object (flatten and keyed and translated) from OptionsList
 *
 * @param optionList
 * @param translate
 * @param p
 * @param initObject
 * @returns {*}
 */
export const buildReferenceEntities = moize(
    (optionList, translate, p, initObject = {}) => {
        if (!optionList) {
            return [];
        }
        return optionList.reduce((memo, option) => {
            // Recursively insert children
            if (option.children && option.children.length > 0) {
                return buildReferenceEntities(option.children, translate, p, memo);
            }
            if (translate) {
                memo[option.id] = {
                    ...option,
                    label: buildReferenceLabel(option, p),
                    ...(option.label2 && { label2: p.t(option.label2, { smart_count: 1 }) }),
                };
            } else {
                memo[option.id] = { ...option };
            }
            return memo;
        }, initObject);
    },
    {
        maxSize: 50,
        // onCacheHit: () => console.log('MEMOIZE - buildReferenceEntities')
    }
);

export const getReferenceAsOptions = (storeRef, name, p, args, references) => {
    const reference = getReference(storeRef, name, args, references);
    if (reference && reference.data && !reference.loading) {
        return buildReferenceOptions(reference.data, reference.translate, p);
    }
    return [];
};

/**
 * Translate options from references or staticOptions
 * handle hierarchic by children prop
 *
 * @param optionList
 * @param translate
 * @param p
 * @returns {*}
 */
const buildReferenceOptions = moize(
    (optionList, translate, p) => {
        if (translate) {
            return optionList.map((option) => {
                return {
                    ...option,
                    label: buildReferenceLabel(option, p),
                    info: typeof option.info === "function" ? option.info(p) : option.info,
                    ...(option.label2 && { label2: p.t(option.label2, { smart_count: 1 }) }), // secondary translated label
                    ...(option.children &&
                        option.children.length > 0 && {
                            children: buildReferenceOptions(option.children, translate, p),
                        }),
                };
            });
        }
        return optionList;
    },
    {
        maxSize: 50,
        // onCacheHit: () => console.log('MEMOIZE - buildReferenceOptions')
    }
);

export const buildArgs = moize((accountIdList) => ({ accountIdList }), {
    maxSize: 10,
    // onCacheHit: () => console.log('MEMOIZE - buildArgs')
});
