const thirdPartySecurity = {
    id: "thirdPartySecurity",
    data: [
        {
            id: "ask_for_3DS",
            label: "ref.transaction.third_party_security.ask_for_3DS",
        },
        {
            id: "force_3DS",
            label: "ref.transaction.third_party_security.force_3DS",
        },
        {
            id: "authenticated",
            label: "ref.transaction.third_party_security.authenticated",
        },
        {
            id: "cardholder_not_enrolled",
            label: "ref.transaction.third_party_security.cardholder_not_enrolled",
        },
    ],
};

export default thirdPartySecurity;
