import curry from "lodash/curry";

const EVENT_TYPE = "event";
const CONFIG_TYPE = "config";

export function callTracker(typeName, actionName, data) {
    if (!window.gtag) {
        console.error("window.gtag is not present.");
        return;
    }

    window.gtag(typeName, actionName, data);
}

export const setEventTracker = curry(callTracker)(EVENT_TYPE);
export const setConfigTracker = curry(callTracker)(CONFIG_TYPE, process.env.NX_GA_TRACKING_ID);
