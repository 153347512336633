import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFields = {
    month: null,
    status: null,
    number: null,
    issue_date: null,
    due_date: null,
};

export const availableFields = [
    attributes.account.id,
    attributes.month.id,
    attributes.status.id,
    attributes.number.id,
    attributes.currency.id,
    attributes.exchange_currency.id,
    attributes.payment_date.id,
    attributes.issue_date.id,
    attributes.due_date.id,
    attributes.start_period.id,
    attributes.end_period.id,
];
