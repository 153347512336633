import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import HiAlertModal from "@hipay/hipay-material-ui/HiAlertModal";
import { ItemAddable } from "./ItemAddable";
import { ItemLoader } from "./ItemLoader";
import { useHistory } from "react-router-dom";
import { useP } from "../../../../services/i18n";
import { WIDGET_TYPE } from "../../../../constants";
import isRequiredIf from "react-proptype-conditional-require/isRequiredIf";
import PropTypes from "prop-types";

const useStylesThumbnailView = makeStyles((theme) => ({
    root: {
        padding: "1px",
        backgroundColor: "white",
    },
    stickyTop: {
        position: "sticky",
        top: 0,
        zIndex: 17,
        backgroundColor: theme.palette.background1,
    },
    results_title: {
        height: 48,
        padding: "0 24px",
        ...theme.typography.h3,
        color: theme.palette.text.primary,
    },
    title_left: {
        textAlign: "left",
        padding: "12px 0",
    },
    title_center: { textAlign: "center" },
    title_right: {
        textAlign: "right",
        padding: "12px 0",
        color: theme.palette.neutral.light,
    },
    title: { textAlign: "center" },
    grid: {
        margin: "20px 0",
        justifyContent: "center",
        display: "grid",
        gridAutoFlow: "row dense",
        gridGap: "18px 24px",
        gridTemplateColumns: "repeat(auto-fill, " + 247 + "px)",
    },
    collapseButton: {
        position: "absolute",
        right: 10,
        top: 10,
    },
    backButton: {
        position: "absolute",
        zIndex: 18,
        margin: 8,
    },
    chipTitle: { marginLeft: 8 },
}));

export const ThumbnailView = (props) => {
    const {
        canAddItem,
        onAddItem,
        redirectUrl,
        module,
        data,
        loading,
        itemType,
        itemContent,
        onRowClick,
        onRowMiddleClick,
    } = props;

    const p = useP();
    const classes = useStylesThumbnailView();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [addable, setAddable] = useState(canAddItem);
    const loadingData = [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
    ];

    const handleRedirect = () => {
        history.push(redirectUrl);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddItem = (item) => {
        setAddable(false);
        onAddItem(item).then((response) => {
            if (response.error && response.payload && response.payload.status === 507) {
                setOpen(true);
            } else {
                handleRedirect();
            }
        });
    };

    const handleClick = (e, item) => {
        // Event button 1 is middle click
        if (e.button === 1) {
            if (!onRowMiddleClick) {
                return;
            }

            onRowMiddleClick(e, item.id);
        } else {
            if (!onRowClick) {
                return;
            }

            onRowClick(e, item.id, !!e.ctrlKey);
        }
    };

    let gridElements;

    if (loading) {
        gridElements = loadingData.map((item, index) => {
            return <ItemLoader key={index} />;
        });
    } else {
        gridElements = data.map((item, index) => {
            switch (itemType) {
                case WIDGET_TYPE:
                    return (
                        <ItemAddable
                            key={index}
                            item={item.datas}
                            index={index}
                            addItem={handleAddItem}
                            addable={addable}
                            type={itemType}
                            ThumbnailContent={itemContent}
                            handleClick={handleClick}
                        />
                    );
                default:
                    return <div key={index} />;
            }
        });
    }

    return (
        <div className={classes.root}>
            <HiAlertModal
                id={`${module}-store-modal`}
                open={open}
                title={p.t(`modules.${module}.store.max_items_title`)}
                content={p.t(`modules.${module}.store.max_items_content`)}
                labelSubmitButton={p.t(`modules.${module}.buttons.ok`)}
                submitColor="primary"
                onSubmitClick={handleRedirect}
                onClose={handleClose}
                backgroundIcon="mdi_bulletin_board"
                iconSize={123}
            />
            <div className={classes.grid}>{gridElements}</div>
        </div>
    );
};

ThumbnailView.propTypes = {
    canAddItem: PropTypes.bool,
    onAddItem: isRequiredIf(
        PropTypes.func,
        (props, propName, componentName) => props.hasOwnProperty("canAddItem") && props.canAddItem
    ),
    redirectUrl: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    data: PropTypes.array,
    loading: PropTypes.bool,
    itemType: PropTypes.string.isRequired,
};

ThumbnailView.defaultProps = { canAddItem: true };
