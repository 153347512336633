import moment from "moment-timezone";
import moize from "moize";

import {
    getOperatorListByAttribute,
    getRangeOperatorListByAttribute,
    OPERATOR_EQUAL,
    OPERATOR_NOT_EQUAL,
} from "./operators";

import { foldAccents } from "../../utils/strings";
import { isDateFormFieldType, isNumericFormFieldType } from "../../utils/forms";
import { stringifySearchParams } from "../../utils/urls";

import QueryParser, {
    TERM_UNKNOWN,
    TERM_NONE,
    TERM_FREETEXT,
    TERM_KEYWORD,
    TERM_FIELD,
    TERM_OPERATOR,
    TERM_VALUE,
    TERM_RANGE_VALUE,
} from "./QueryParser";
import { FORM_FIELD_SUGGEST } from "../../common/constants/formFieldTypes";

// SUGGESTION TYPES
// It defines how to
// - display the suggestion (icon, text, highlight, ...)
// - hydrate the query on suggestion submit
export const SUGGESTION_CLAUSE = "SUGGESTION_CLAUSE"; // [Q] FIELD + = + VALUE

export const SUGGESTION_FIELD = "SUGGESTION_FIELD"; // [?] FIELD
export const SUGGESTION_KEYWORD = "SUGGESTION_KEYWORD"; // [CO] KEYWORD
export const SUGGESTION_OPERATOR = "SUGGESTION_OPERATOR"; // [>=] OPERATOR
export const SUGGESTION_VALUE = "SUGGESTION_VALUE"; // [Q] VALUE
export const SUGGESTION_HISTORY = "SUGGESTION_HISTORY"; // [G] HISTORY QUERY
export const SUGGESTION_NOTICE = "SUGGESTION_NOTICE"; // [>>] NOTICE - Exception - submit will redirect to module's notice

// Minimum required characters in term to throw request
// maximise to improve fluidity
// minimise to raise suggestions
export const MIN_CHAR_TO_REQUEST = 3;
export const MIN_CHAR_TO_REQUEST_AFTER_TG_FIELD = 1;

// Compose suggestion list
export const MAX_HISTORY = 3;
export const MAX_NOTICE = 5;
export const MAX_FIELD = 5;
export const MAX_OPERATOR = 6;
export const MAX_VALUE = 5;
export const MAX_CLAUSE = 5;
export const MAX_KEYWORD = 5;
// Max total suggestions
export const MAX_SUGGESTION = 10;

/**
 * pad number with 0 returning string
 * (12, 4) => '0012'
 * @param number
 * @param length
 * @returns {string}
 */
const pad = (number, length) => ("" + number).padStart(length, "0");

/**
 * Build year suggestions
 * @param value
 * @returns {Array}
 */
export const buildYearSuggestions = (value = "") => {
    // match number with 0 to 3 digits
    if (/^[0-9]{0,3}$/g.test(value)) {
        const minYear = parseInt(value.padEnd(4, "0"), 10); // XX00
        const maxYear = parseInt(value.padEnd(4, "9"), 10); // XX99
        const currentYear = moment().format("YYYY");
        let years = [];

        // return 5 last years from now (>minYear)
        if (minYear <= currentYear && maxYear >= currentYear) {
            do {
                years.push(pad(currentYear - years.length, 4));
            } while (years.length < 5 && currentYear - years.length > minYear);
        } else if (minYear > currentYear) {
            // return 5 years from minYear
            do {
                years.push(pad(minYear + years.length, 4));
            } while (years.length < 5);
        } else if (maxYear < currentYear) {
            // return 5 years from maxYear
            do {
                years.push(pad(maxYear - years.length, 4));
            } while (years.length < 5);
        }

        return years;
    }

    return [];
};

/**
 * Build month suggestions
 *
 * @param year
 * @param value
 * @returns {Array}
 */
export const buildMonthSuggestions = (year, value = "") => {
    let maxMonth = year.toString() === moment().format("YYYY") ? moment().format("MM") : 12;
    let months = [];

    // return 5 months from max month
    if (value === "") {
        while (months.length < 5 && maxMonth > 0) {
            months.push(`${year}-${pad(maxMonth, 2)}`);
            maxMonth -= 1;
        }
    } else if (value === "1") {
        // return max 3 months from 12
        maxMonth = maxMonth < 10 ? 12 : maxMonth; // allow suggest futur months
        while (months.length < 3 && maxMonth > 9) {
            months.push(`${year}-${pad(maxMonth, 2)}`);
            maxMonth -= 1;
        }
    } else if (value === "0") {
        // return 5 months from 9
        maxMonth = Math.min(maxMonth, 9);
        while (months.length < 5 && maxMonth > 0) {
            months.push(`${year}-${pad(maxMonth, 2)}`);
            maxMonth -= 1;
        }
    }

    return months;
};

/**
 * Build day suggestions
 *
 * @param value
 * @param month
 * @param year
 * @returns {Array}
 */
export const buildDaySuggestions = (month, year, value = "") => {
    // match number with 0 to 3 digits
    if (/^[0-3]?$/g.test(value)) {
        let yyyyMM = moment([year, month - 1]);
        let maxDay =
            yyyyMM.format("YYYY-MM") === moment().format("YYYY-MM")
                ? moment().format("DD")
                : yyyyMM.daysInMonth();
        let minDay = 1;

        // filter max & min day by value
        if (value === "0") {
            maxDay = Math.min(maxDay, 9);
        } else if (value === "1") {
            minDay = 10;
            maxDay = minDay > maxDay ? 14 : Math.min(maxDay, 19);
        } else if (value === "2") {
            minDay = 20;
            maxDay = minDay > maxDay ? 24 : Math.min(maxDay, 29);
        } else if (value === "3") {
            minDay = 30;
            maxDay = minDay > maxDay ? 31 : Math.min(maxDay, 31);
        }

        let days = [];

        // return 5 months from max day
        while (days.length < 5 && maxDay >= minDay) {
            days.push(`${yyyyMM.format("YYYY-MM")}-${pad(maxDay, 2)}`);
            maxDay -= 1;
        }

        return days;
    }

    return [];
};

/**
 * Get dates suggestions (YYYY | YYYY-MM | YYYY-MM-DD)
 *
 * @param value
 * @returns {Array}
 */
export const buildDatesSuggestions = (value = "") => {
    // handle range value - split "dateFrom TO dateTo"
    const splitVal = value ? value.split(" ") : [];

    let val = "";
    if (splitVal.length === 1) {
        val = splitVal[0];
    } // dateFrom
    if (splitVal.length === 2) {
        return [];
    } // TO
    if (splitVal.length === 3) {
        val = splitVal[2];
    } // dateTo

    let suggestions = [];

    if (val === "") {
        suggestions = buildYearSuggestions();
    } else {
        // split elements
        const [year, month, day] = val.split("-");

        if ((!year || !isNaN(year)) && (!month || !isNaN(month)) && (!day || !isNaN(day))) {
            if (!year || year.length < 4) {
                suggestions = buildYearSuggestions(year);
            } else if (year.length === 4 && (!month || month.length < 2)) {
                suggestions = buildMonthSuggestions(year, month);
            } else if (year.length === 4 && month.length === 2 && (!day || day.length < 2)) {
                suggestions = buildDaySuggestions(month, year, day);
            } else if (moment(val, "YYYY-MM-DD", true).isValid()) {
                suggestions = [val];
            }
        }
    }

    return suggestions.map((label) => ({
        label,
        labelHighlight:
            val === ""
                ? label
                : `<strong>${label.slice(0, val.length)}</strong>${label.slice(val.length)}`,
    }));
};

export class SuggestionManager {
    constructor(moduleConfig, phrases, api) {
        this.moduleConfig = moduleConfig;
        this.attributes = moduleConfig.attributes || [];
        this.phrases = phrases;
        this.api = api;
        this.abortController = new AbortController();
    }

    getAttributeByModule = (attr) => {
        return this.attributes[attr];
    };

    getIdentifierAttributeIdListByModule = () => {
        return Object.values(this.attributes)
            .filter((attr) => attr.identifier === true)
            .map((attr) => attr.id);
    };

    getQuickSearchFieldsByModule = (hasMultipleAccount) => {
        return Object.values(this.attributes)
            .filter((attr) => {
                let displayField = attr.qsearch === true;
                if (displayField && attr.needMultipleAccounts === true) {
                    displayField = hasMultipleAccount;
                }
                return displayField;
            })
            .reduce(
                (memo, attr) => ({
                    ...memo,
                    [attr.id]: attr,
                }),
                {}
            );
    };

    getQsearchSuggestAttributeListByModule = () => {
        return Object.values(this.attributes)
            .filter((attr) => attr.qsearch === true && attr.formFieldType === FORM_FIELD_SUGGEST)
            .reduce((memo, attr) => [...memo, this.attributes[attr.id]], []);
    };

    getQsearchReferenceAttributeListByModule = () => {
        return Object.values(this.attributes)
            .filter((attr) => attr.qsearch === true && attr.reference)
            .reduce((memo, attr) => [...memo, this.attributes[attr.id]], []);
    };

    isQsearchSuggestAttribute = (attrId, rgpdCompliance = false) => {
        const attribute = this.attributes[attrId];
        return (
            attribute &&
            attribute.qsearch === true &&
            attribute.formFieldType === FORM_FIELD_SUGGEST &&
            (attribute.rgpdCompliance !== true || rgpdCompliance)
        );
    };

    cancelSuggestFunc;

    getSuggestionList = (
        query,
        caretPosition,
        searchHistory,
        module,
        phrases,
        rgpdCompliance,
        args,
        hasMultipleAccount
    ) => {
        if (this.cancelSuggestFunc) {
            this.cancelSuggestFunc();
        } // cancel current suggestion request if not finished

        // Analyze query
        const termAnalysis = QueryParser.analyzeCurrentTermQuery(
            query,
            caretPosition,
            module,
            this.attributes,
            phrases,
            true
        );

        // Fetch suggestions from API (promise or null)
        const promise = this.fetchSuggestions(termAnalysis, module, args, rgpdCompliance); // use cached fetch

        // Get static suggestion from history, keywords, operators, attributes label or static references label
        let suggestionList = [
            ...this.buildHistorySuggestions(query, searchHistory, module, phrases).slice(
                0,
                query === "" ? 10 : MAX_HISTORY
            ),
            ...this.buildFieldSuggestions(
                termAnalysis,
                module,
                phrases,
                rgpdCompliance,
                hasMultipleAccount
            ).slice(0, MAX_FIELD),
            ...this.buildOperatorSuggestions(termAnalysis, module, phrases).slice(0, MAX_OPERATOR),
            ...this.buildKeywordSuggestions(termAnalysis, module, phrases).slice(0, MAX_KEYWORD),
            ...this.buildValueSuggestions(termAnalysis, module, phrases, rgpdCompliance).slice(
                0,
                MAX_VALUE
            ),
        ].slice(0, MAX_SUGGESTION);

        return {
            termAnalysis,
            suggestionList,
            promise,
        };
    };

    /**
     * Fetch Suggestions (asynchronous)
     * return a promise or null
     * @param termAnalysis
     * @param module
     * @param args
     * @param rgpdCompliance
     * @returns {*}
     */
    fetchSuggestions = (termAnalysis, module, args, rgpdCompliance) => {
        // do not request if current term is null or contains special chars or term < MIN_CHAR_TO_REQUEST
        if (
            termAnalysis === null ||
            termAnalysis.term === null ||
            termAnalysis.term === '"' ||
            termAnalysis.term.search(/[?*]/i) !== -1 ||
            (termAnalysis.term.length < MIN_CHAR_TO_REQUEST &&
                termAnalysis.previousTerm.type !== TERM_OPERATOR) ||
            (termAnalysis.term.length < MIN_CHAR_TO_REQUEST_AFTER_TG_FIELD &&
                !termAnalysis.previousTerm.type === TERM_OPERATOR)
        ) {
            return null;
        }

        let fieldNameList = [];
        switch (termAnalysis.previousTerm.type) {
            case TERM_NONE:
            case TERM_KEYWORD:
                // all available target field suggest for module
                fieldNameList = this.getQsearchSuggestAttributeListByModule().map(
                    (attr) => attr.id
                );
                break;
            case TERM_OPERATOR:
                if (
                    this.isQsearchSuggestAttribute(termAnalysis.previousTerm.field, rgpdCompliance)
                ) {
                    fieldNameList = [termAnalysis.previousTerm.field];
                }
                break;
            default:
                break;
        }

        if (fieldNameList.length > 0) {
            return this.getFetchSuggestionsPromise(
                fieldNameList,
                trimQuotes(termAnalysis.term),
                args
            );
        }

        return null;
    };

    getFetchSuggestionsPromise = (fieldNameList, query, args) => {
        const path =
            this.moduleConfig.suggestRoute +
            stringifySearchParams({
                field: fieldNameList,
                query: query,
                ...args, // account, ...
            });

        if (this.currentRequest) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        this.currentRequest = this.api.fetchApi(
            "GET",
            path,
            null,
            { url: this.moduleConfig.apiUrl },
            this.abortController
        );
        return this.currentRequest;
    };

    /**
     * Decode query from history list
     * ex:
     * - encoded query: customer_name¤equal¤Jean
     * - decoded query: "Nom du client" = Jean (fr)
     *
     * Decoding query is require to find matching item in search histories
     *
     * @param historyList
     * @param module
     * @param phrases
     * @returns {*}
     */
    decodeHistoriesQuery = (historyList, module, phrases) => {
        return historyList
            .filter((item) => item && item.query)
            .map((item) => ({
                ...item,
                decodedQuery: QueryParser.decodeQuery(item.query, module, this.attributes, phrases),
            }));
    };

    // moize decode history
    MemoizedDecodeHistoriesQuery = moize(this.decodeHistoriesQuery, { isDeepEqual: true });

    buildHistorySuggestions = (query, historyList, module, phrases) => {
        const moduleHistoryList = historyList[module];

        if (
            moduleHistoryList === undefined ||
            moduleHistoryList.data === null ||
            moduleHistoryList.data.length === 0 ||
            moduleHistoryList.loading ||
            moduleHistoryList.error !== null
        ) {
            return [];
        }

        // Get decoded history list to search in it
        // use memoization because decode is expensive
        const decodedHistoryList = this.MemoizedDecodeHistoriesQuery(
            moduleHistoryList.data,
            module,
            phrases
        );

        const pureQuery = query.toLowerCase();

        return (
            decodedHistoryList
                // match query in history decoded query
                .filter((item) => item.decodedQuery.toLowerCase().indexOf(pureQuery) > -1)
                // build history suggestion
                .map((item) => ({
                    type: SUGGESTION_HISTORY,
                    value: item.decodedQuery,
                    accounts: item.selectedAccountIdList || [],
                }))
        );
    };

    /**
     * Find attribute id list by matching suggestLabel
     *
     * @param query
     * @param module
     * @param phrases
     * @param rgpdCompliance
     * @returns {string[]}
     */
    findTargetFieldList = (query, module, phrases, rgpdCompliance = false) => {
        const attributePhrases = phrases.attributes[module];

        const pureQuery = foldAccents(trimQuotes(query.toLowerCase()));

        let result = [];
        if (attributePhrases) {
            result = Object.keys(attributePhrases).filter(
                (attr) =>
                    // is attribute
                    this.attributes[attr] &&
                    this.attributes[attr].qsearch === true &&
                    // check RGPD compliance
                    (rgpdCompliance || this.attributes[attr].rgpdCompliance !== true) &&
                    // match on suggestLabel
                    attributePhrases[attr]["suggestLabel"] &&
                    foldAccents(attributePhrases[attr]["suggestLabel"].toLowerCase()).indexOf(
                        pureQuery
                    ) >= 0
            );
        }

        return result;
    };

    buildFieldSuggestions = (termAnalysis, module, phrases, rgpdCompliance, hasMultipleAccount) => {
        const qSearchFields = this.getQuickSearchFieldsByModule(hasMultipleAccount);

        if (!qSearchFields) {
            return [];
        }

        if (termAnalysis === null || termAnalysis.term === null) {
            if (
                termAnalysis &&
                termAnalysis.previousTerm &&
                [TERM_NONE, TERM_KEYWORD].indexOf(termAnalysis.previousTerm.type) < 0
            ) {
                return [];
            }

            return Object.values(qSearchFields)
                .filter((field) => !field.rgpdCompliance || rgpdCompliance === true)
                .sort((a, b) => a.order - b.order)
                .slice(0, 5)
                .map((field) => ({
                    type: SUGGESTION_FIELD,
                    value: field.id,
                }));
        }

        if ([TERM_NONE, TERM_KEYWORD].indexOf(termAnalysis.previousTerm.type) >= 0) {
            const targetFieldList = this.findTargetFieldList(
                termAnalysis.term,
                module,
                phrases,
                rgpdCompliance
            );

            return (
                targetFieldList
                    .filter((field) => qSearchFields[field])
                    // sort by config order
                    .sort((a, b) => {
                        return qSearchFields[a].order > qSearchFields[b].order
                            ? 1
                            : qSearchFields[b].order > qSearchFields[a].order
                            ? -1
                            : 0;
                    })
                    .map((targetField) => ({
                        type: SUGGESTION_FIELD,
                        value: targetField,
                    }))
            );
        }

        return [];
    };

    findOperatorList = (query, attribute, module, phrases, rangeOperator = false) => {
        const operatorPhrases = phrases.app.qsearch.operators;
        const pureQuery = query ? foldAccents(query.toLowerCase()) : "";

        const operatorList = rangeOperator
            ? getRangeOperatorListByAttribute(attribute)
            : getOperatorListByAttribute(attribute);

        return operatorList.filter(
            (operator) =>
                operatorPhrases[operator] &&
                foldAccents(operatorPhrases[operator].toLowerCase()).indexOf(pureQuery) >= 0
        );
    };

    buildOperatorSuggestions = (termAnalysis, module, phrases) => {
        if (termAnalysis === null) {
            return [];
        } // no default operators

        let operatorList = [];
        if (termAnalysis.previousTerm.type === TERM_FIELD) {
            // find coherent operator for attribute formFieldType
            operatorList = this.findOperatorList(
                termAnalysis.term,
                this.getAttributeByModule(termAnalysis.previousTerm.field),
                module,
                phrases
            );
        } else if (
            termAnalysis.previousTerm.type === TERM_VALUE &&
            termAnalysis.previousTerm.operator === OPERATOR_EQUAL
        ) {
            if (
                termAnalysis.previousTerm.value.replace(/"/g, "") !==
                phrases?.app?.qsearch?.values?.empty
            ) {
                // find coherent range operator for attribute formFieldType
                operatorList = this.findOperatorList(
                    termAnalysis.term,
                    this.getAttributeByModule(termAnalysis.previousTerm.field),
                    module,
                    phrases,
                    true
                );
            }
        }

        return operatorList.map((operator) => ({
            type: SUGGESTION_OPERATOR,
            value: operator,
        }));
    };

    findReferenceValueList = (query, refPhrases) => {
        const pureQuery = query ? foldAccents(trimQuotes(query.toLowerCase())) : "";

        return Object.keys(refPhrases).filter((ref) => {
            if (typeof refPhrases[ref] === "string") {
                return foldAccents(refPhrases[ref].toLowerCase()).indexOf(pureQuery) >= 0; // match translated ref
            }
            return false;
        });
    };

    buildEmptyValueSuggestion = (termAnalysis, attribute, value, phrases) => {
        if (
            termAnalysis.previousTerm.type === TERM_OPERATOR &&
            [OPERATOR_EQUAL, OPERATOR_NOT_EQUAL].includes(termAnalysis.previousTerm.operator)
        ) {
            const emptyTrans = phrases?.app?.qsearch?.values?.empty;
            const searchValue =
                termAnalysis?.term === phrases?.app?.qsearch?.operators?.to
                    ? null
                    : termAnalysis?.term;
            if (
                !searchValue ||
                foldAccents(emptyTrans.toLowerCase()).indexOf(searchValue.toLowerCase()) === 0
            ) {
                return [
                    {
                        type: SUGGESTION_VALUE,
                        value: emptyTrans,
                        reference: false,
                        targetField: termAnalysis.previousTerm.field,
                        operator: termAnalysis.previousTerm.operator,
                        highlight: !searchValue
                            ? emptyTrans
                            : `<strong>${emptyTrans.slice(
                                  0,
                                  searchValue.length
                              )}</strong>${emptyTrans.slice(searchValue.length)}`,
                    },
                ];
            }
        }
        return [];
    };

    buildValueSuggestions = (termAnalysis, module, phrases, rgpdCompliance) => {
        if (termAnalysis === null) {
            return [];
        } // no default target value

        // After operator, find value relative to field
        if (termAnalysis.previousTerm.type === TERM_OPERATOR) {
            const attribute = this.attributes[termAnalysis.previousTerm.field];
            if (
                // is attribute
                attribute &&
                // check RGPD compliance
                (rgpdCompliance || attribute.rgpdCompliance !== true)
            ) {
                let _valSuggs = this.buildEmptyValueSuggestion(
                    termAnalysis,
                    attribute,
                    termAnalysis.term,
                    phrases
                );

                // has static reference
                if (attribute.reference && phrases.ref[module][attribute.reference]) {
                    const referenceValueList = this.findReferenceValueList(
                        termAnalysis.term,
                        phrases.ref[module][attribute.reference]
                    );

                    return [
                        ..._valSuggs,
                        ...referenceValueList.map((targetValue) => ({
                            type: SUGGESTION_VALUE,
                            value: targetValue,
                            reference: attribute.reference,
                            targetField: termAnalysis.previousTerm.field,
                            operator: termAnalysis.previousTerm.operator,
                        })),
                    ];
                } else if (isDateFormFieldType(attribute)) {
                    // is date attribute
                    const dateValue =
                        termAnalysis.term === phrases.app.qsearch.operators.to
                            ? null
                            : termAnalysis.term;
                    let dateList = [];
                    if (
                        termAnalysis?.previousTerm?.type === TERM_OPERATOR &&
                        termAnalysis?.previousTerm?.operator !== OPERATOR_NOT_EQUAL
                    ) {
                        dateList = buildDatesSuggestions(dateValue);
                    }
                    return [
                        ..._valSuggs,
                        ...dateList.map((date) => ({
                            type: SUGGESTION_VALUE,
                            value: date.label,
                            highlight: date.labelHighlight,
                            targetField: termAnalysis.previousTerm.field,
                            operator: termAnalysis.previousTerm.operator,
                            date: true,
                        })),
                    ];
                }

                return [..._valSuggs];
            }
        }

        // From freetext, find every matching value in reference
        if (
            termAnalysis.term &&
            termAnalysis.term.length >= MIN_CHAR_TO_REQUEST &&
            (termAnalysis.previousTerm.type === TERM_NONE ||
                termAnalysis.previousTerm.type === TERM_KEYWORD)
        ) {
            // object: { referenceName => attributeId }
            const attributeByUniqueReference = this.getQsearchReferenceAttributeListByModule()
                .sort((a, b) => a.order - b.order)
                .reduce((memo, attr) => {
                    // add attribute
                    // if relative reference is not already search by another top level attribute
                    // and phrases exist for the reference
                    if (
                        !memo.hasOwnProperty(attr.reference) &&
                        phrases.ref[module][attr.reference] &&
                        (rgpdCompliance || attr.rgpdCompliance !== true)
                    ) {
                        memo[attr.reference] = attr.id;
                    }
                    return memo;
                }, {});

            let result = [];
            Object.keys(attributeByUniqueReference).some((reference) => {
                const referenceValueList = this.findReferenceValueList(
                    termAnalysis.term,
                    phrases.ref[module][reference]
                );

                if (referenceValueList.length > 0) {
                    result.push(
                        ...referenceValueList.map((value) => ({
                            type: SUGGESTION_CLAUSE,
                            value: value,
                            reference: reference,
                            targetField: attributeByUniqueReference[reference],
                            operator: OPERATOR_EQUAL,
                        }))
                    );
                }

                // continue until it find 5 results
                return result.length > 5;
            });

            return result;
        }

        return [];
    };

    buildKeywordSuggestions = (termAnalysis, module, phrases) => {
        if (termAnalysis === null) {
            return [];
        } // no default keyword

        if (
            termAnalysis.previousTerm.type === TERM_VALUE ||
            termAnalysis.previousTerm.type === TERM_RANGE_VALUE ||
            termAnalysis.previousTerm.type === TERM_FREETEXT
        ) {
            const keywordPhrases = phrases.app.qsearch.keywords;
            const pureQuery = termAnalysis.term ? foldAccents(termAnalysis.term.toLowerCase()) : "";

            const keywordList = ["and", "or"].filter(
                (keyword) =>
                    keywordPhrases[keyword] &&
                    foldAccents(keywordPhrases[keyword].toLowerCase()).indexOf(pureQuery) >= 0
            );

            return keywordList.map((keyword) => ({
                type: SUGGESTION_KEYWORD,
                value: keyword,
            }));
        }
        return [];
    };

    /**
     * Build Suggestions from qsearch api response
     * - build SUGGESTION_CLAUSE
     * - build SUGGESTION_NOTICE (if matched term is entity identifier)
     *
     * @param data
     * @param termAnalysis
     * @param module
     * @returns {*}
     */
    buildResponseSuggestions = (data, termAnalysis, module) => {
        // Get module identifier attribute
        // ex: transaction => [ trxid, acquirer_id, ... ] (specify in attributes)
        const identifierSourceFields = this.getIdentifierAttributeIdListByModule();

        // default operator to equal
        const operator =
            termAnalysis.previousTerm.type === TERM_OPERATOR
                ? termAnalysis.previousTerm.operator
                : OPERATOR_EQUAL;

        const { noticeSuggestionList, clauseSuggestionList } = data.reduce(
            (memo, _data) => {
                // is notice if source field is an identifier attribute && operator is equal
                if (
                    identifierSourceFields.includes(_data.sourceField) &&
                    operator === OPERATOR_EQUAL &&
                    _data.redirectId !== undefined
                ) {
                    return {
                        ...memo,
                        noticeSuggestionList: [
                            ...memo.noticeSuggestionList,
                            {
                                type: SUGGESTION_NOTICE,
                                value: _data.id,
                                highlight: _data.labelHighlight,
                                targetField: _data.sourceField,
                                operator: operator,
                                redirectId: _data.redirectId,
                            },
                        ],
                    };
                }
                return {
                    ...memo,
                    clauseSuggestionList: [
                        ...memo.clauseSuggestionList,
                        {
                            type:
                                termAnalysis.previousTerm.type === TERM_OPERATOR
                                    ? SUGGESTION_VALUE
                                    : SUGGESTION_CLAUSE,
                            value: _data.id,
                            highlight: _data.labelHighlight,
                            targetField: _data.sourceField,
                            operator: operator,
                        },
                    ],
                };
            },
            {
                noticeSuggestionList: [],
                clauseSuggestionList: [],
            }
        );

        return [
            ...noticeSuggestionList.slice(0, MAX_NOTICE),
            ...clauseSuggestionList.slice(0, MAX_CLAUSE),
        ];
    };

    buildEmptySuggestionListMessage = (termAnalysis, p) => {
        if (termAnalysis) {
            if (termAnalysis.previousTerm.type === TERM_UNKNOWN) {
                return p.t("app.qsearch.helper_messages.incorrect_query");
            }

            const attribute = this.attributes[termAnalysis.previousTerm.field];
            const minCharToRequest = attribute
                ? MIN_CHAR_TO_REQUEST_AFTER_TG_FIELD
                : MIN_CHAR_TO_REQUEST;

            // exception numeric field - no suggestions
            if (attribute && isNumericFormFieldType(attribute)) {
                return p.t("app.qsearch.helper_messages.field_do_not_provide_suggestions", {
                    field: p.t(
                        `attributes.${this.moduleConfig.id}.${termAnalysis.previousTerm.field}.suggestLabel`
                    ),
                });
            }

            // term null - require 3 chars to start looking for suggestions
            if (termAnalysis.term === null || termAnalysis.term === '"') {
                return p.t(
                    "app.qsearch.helper_messages.require_n_char_to_start_looking_for_suggestions",
                    { smart_count: minCharToRequest }
                );
            }

            // specials chars - search with special characters ? or * does not suggest anything
            if (termAnalysis.term.search(/[?*]/i) > -1) {
                return p.t("app.qsearch.helper_messages.search_with_special_chars_do_not_suggest");
            }

            if (termAnalysis.previousTerm.field) {
                const translatedField = p.t(
                    `attributes.${this.moduleConfig.id}.${termAnalysis.previousTerm.field}.suggestLabel`
                );

                if (termAnalysis.previousTerm.operator) {
                    if (termAnalysis.previousTerm.value) {
                        // Here handle exceptions (date/numeric ranges, multi values, ...)

                        // else expect keywords
                        return p.t("app.qsearch.helper_messages.query_expect_keyword_among_list", {
                            list: ["and", "or"]
                                .map((k) => p.t("app.qsearch.keywords." + k))
                                .join(", "),
                        });
                    } // term don't match any reference/suggest value

                    // field date
                    if (isDateFormFieldType(attribute)) {
                        // handle no suggestion between range values
                        const splitTerm = termAnalysis.term.split(" ");
                        if (
                            splitTerm.length === 2 &&
                            moment(splitTerm[0], "YYYY-MM-DD", true).isValid()
                        ) {
                            return null;
                        }

                        // more than 10 chars - hours and minutes are not searchable
                        if (termAnalysis.term.length > 10) {
                            return p.t(
                                "app.qsearch.helper_messages.field_date_hours_and_minutes_are_not_searchable"
                            );
                        }

                        // default - date format is incorrect
                        return p.t("app.qsearch.helper_messages.field_date_incorrect_format");
                    } else if (termAnalysis.term.length < minCharToRequest) {
                        // require 3 char to start looking for suggestion
                        return p.t(
                            "app.qsearch.helper_messages.require_n_char_to_start_looking_for_suggestions",
                            { smart_count: minCharToRequest }
                        );
                    }

                    // default - no suggestion found for {field} matching {term}
                    return p.t(
                        "app.qsearch.helper_messages.no_suggestion_found_for_field_matching",
                        {
                            field: translatedField,
                            query: trimQuotes(termAnalysis.term),
                        }
                    );
                } // term don't match any available operators
                const operatorList = getOperatorListByAttribute(attribute);
                return p.t("app.qsearch.helper_messages.field_expect_operator_among_list", {
                    field: translatedField,
                    list: operatorList.map((o) => p.t("app.qsearch.operators." + o)).join(", "),
                });
            } // term don't match any field or freetext
            if (termAnalysis.term !== null && termAnalysis.term.length >= minCharToRequest) {
                return p.t("app.qsearch.helper_messages.no_suggestion_match", {
                    query: termAnalysis.term,
                });
            }
            return p.t(
                "app.qsearch.helper_messages.require_n_char_to_start_looking_for_suggestions",
                { smart_count: minCharToRequest }
            );
        }
        return "";
    };
}

export const trimQuotes = (str) => {
    return str.replace(/(^["'])|(["']$)/g, "");
};
