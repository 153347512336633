const cancelReasons = {
    id: "cancel_reasons",
    data: [
        {
            id: "try-before-you-buy",
            label: "ref.transaction.cancel_reasons.try-before-you-buy",
        },
        {
            id: "stop-service-after-free-period",
            label: "ref.transaction.cancel_reasons.stop-service-after-free-period",
        },
        {
            id: "change-of-mind",
            label: "ref.transaction.cancel_reasons.change-of-mind",
        },
        {
            id: "delivery-time-too-long",
            label: "ref.transaction.cancel_reasons.delivery-time-too-long",
        },
        {
            id: "other",
            label: "ref.transaction.cancel_reasons.other",
        },
    ],
    translate: true,
};

export default cancelReasons;
