/**
 * Specific functions for custom data fields
 */

/**
 * Return first part of query field
 * custom_data-1 => custom_data
 * old_custom_data => old_custom_data
 * @param param {string}
 * @return {string}
 */
export const getCustomDataAttributeId = (param) => {
    return param.split("-")[0];
};

/**
 * Récupère le champs custom_data de l'url pour le mettre dans les props de la recherche
 * old_custom_data-1=value1 => { old_custom_data_1 : { key: "1", value: "value1" } }
 * old_custom_data=1 => { old_custom_data_1 : { key: 1, value: "" } }
 * old_custom_data=1--2 => { old_custom_data_1 : { key: 1, value: "" }, old_custom_data_2 : { key: 2, value: "" } }
 * @param key
 * @param prefixKey
 * @param dryKey
 * @param urlParams
 * @param multiFieldsIndexes
 * @param memo
 */
export const mapCustomDataUrlToSearchParams = (
    key,
    prefixKey,
    dryKey,
    multiFieldsIndexes,
    urlParams,
    memo
) => {
    let fieldName = dryKey.split("-")[0];
    let paramKey = dryKey.split("-")[1];
    let paramValue = urlParams[key];

    if (!multiFieldsIndexes[prefixKey + fieldName]) {
        multiFieldsIndexes[prefixKey + fieldName] = 1;
    }

    // Key(s) only
    if (!paramKey && !dryKey.includes("-")) {
        paramKey = urlParams[key];
        paramValue = "";
        let keys = paramKey.split("--");

        keys.forEach((_key) => {
            memo[`${prefixKey}Params`][
                `${fieldName}_${multiFieldsIndexes[prefixKey + fieldName]}`
            ] = {
                key: _key,
                value: "",
            };
            multiFieldsIndexes[prefixKey + fieldName]++;
        });
        return;
    }

    // Key + value(s)
    let values = !paramValue.includes("--") ? [paramValue] : paramValue.split("--");

    if (prefixKey === "exclude") {
        memo[`${prefixKey}Params`][`${fieldName}_${multiFieldsIndexes[prefixKey + fieldName]}`] = {
            key: paramKey,
            value: paramValue,
        };
        multiFieldsIndexes[prefixKey + fieldName]++;
    } else {
        values.forEach((value) => {
            let keyName =
                prefixKey !== "lookup"
                    ? `${fieldName}_${multiFieldsIndexes[prefixKey + fieldName]}`
                    : `${fieldName}`;
            memo[`${prefixKey}Params`][keyName] = {
                key: paramKey,
                value: value,
            };
            multiFieldsIndexes[prefixKey + fieldName]++;
        });
    }
};

/**
 * Supprime le champs Custom data de l'url
 * fp_old_custom_data=1--2--3 => Remove key 2 => fp_old_custom_data=1--3
 * @param prefix
 * @param attributeId
 * @param value
 * @return {{[p: string]: *}}
 */
export const buildToDeleteCustomData = (prefix, attributeId, value) => {
    if (value.value) {
        return { [`${prefix}${attributeId}-${value.key}`]: undefined };
    }
    // Only key
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let newValue;
    const oldValue = urlParams.get(`${prefix}${attributeId}`);

    if (oldValue !== null) {
        const splited = oldValue.split("--");
        newValue = splited.filter((k) => k.toString() !== value.key.toString());
        if (newValue.length) {
            newValue = newValue.join("--");
        } else {
            newValue = undefined;
        }
    }

    return { [`${prefix}${attributeId}`]: newValue };
};

/**
 * Construit le libellé du chipFilter pour les custom data
 * @param module
 * @param attributeId
 * @param params
 * @param param
 * @param isExclude
 * @param p
 * @return {*}
 */
export const buildCustomDataChipFilterLabel = (
    module,
    attributeId,
    params,
    param,
    isExclude,
    p
) => {
    if (params[param].key === "EMPTY") {
        return `${p.t(`attributes.${module}.${attributeId}.tableLabel`)} : ${p.t(
            "common.context_menu.no_value"
        )}`;
    } else if (typeof params[param] === "object") {
        return params[param].key && params[param].value && params[param].value !== "EMPTY"
            ? isExclude
                ? `${p.t("NOT")} ${params[param].key} : ${params[param].value.replace(/--/g, ", ")}`
                : `${params[param].key} : ${params[param].value}`
            : `${p.t(`attributes.${module}.${attributeId}.tableLabel`)} : ${params[param].key}`;
    }
    return params[param];
};
