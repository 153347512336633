import { useInheritContext } from "../../services/ContextForwarder";
import { I18nProvider } from "./i18n";
import { translate } from "./translate";
import { locales } from "./locales";
import { I18nContext } from "./i18n-context";

const useP = () => useInheritContext("i18n", I18nContext).p;

const useLocale = () => {
    const { locale, setLocale } = useInheritContext("i18n", I18nContext);
    return [locale, setLocale];
};

const useLexicon = () => {
    const { lexicon, setLexicon } = useInheritContext("i18n", I18nContext);
    return [lexicon, setLexicon];
};

const usePhrases = () => {
    const { phrases, injectPhrases } = useInheritContext("i18n", I18nContext);
    return [phrases, injectPhrases];
};

export { I18nProvider, locales, translate, useLocale, useLexicon, useP, usePhrases };
