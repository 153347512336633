import DialogContent from "@mui/material/DialogContent/DialogContent";

import useStyles from "./stylesheet";

interface DialogFormContentProps {
    children: React.ReactElement;
}

const DialogFormContent = (props: DialogFormContentProps) => {
    const { children } = props;
    const classes = useStyles();

    return <DialogContent className={classes.root}>{children}</DialogContent>;
};

export default DialogFormContent;
