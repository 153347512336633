import { withStylesAndTranslate } from "../../../../../hoc/withStylesAndTranslate";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";

export const stylesError = (theme) => ({
    root: {
        position: "sticky",
        left: 0,
        height: window.innerHeight - 295,
        width: window.innerWidth,
        backgroundColor: theme.palette.background3,
    },
    errorContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    errorDiv: {
        ...theme.typography.body2,
        margin: "auto",
        color: theme.palette.neutral.main,
        lineHeight: 1.8,
        textAlign: "left",
        maxWidth: 340,
    },
});

const Error = ({ classes, p }) => {
    return (
        <div className={classes.root}>
            <div className={classes.errorContainer}>
                <HiIcon color="neutral" icon="hi_briefcase_rescue" size={175} />
                <div
                    className={classes.errorDiv}
                    dangerouslySetInnerHTML={{ __html: p.t("errors.message") }}
                />
            </div>
        </div>
    );
};

export default withStylesAndTranslate(stylesError)(Error);
