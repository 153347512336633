import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import CloseCircleOutline from "mdi-material-ui/CloseCircleOutline";
import CrosshairsGps from "mdi-material-ui/CrosshairsGps";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import { useP } from "../../../../services/i18n";
import { useModule } from "../../../../services/module";
import { getAttributeEntities, getAttributeOptions } from "../../../../utils/attributes";
import { buildCustomDataChipFilterLabel } from "../../../../utils/custom-data";
import {
    getDatesPreviousPeriod,
    getDatesNextPeriod,
    getFormattedPeriod,
} from "../../../../utils/dates";
import { buildAccountDisplayPropsFromEntities } from "../../../../utils/entities";
import { isMultiValueFormFieldType } from "../../../../utils/forms";
import { translateQuery, toUserLocalNumber } from "../../../../utils/i18n";
import { displayTypes } from "../../../../common/constants/displayTypes";
import { formFieldTypes } from "../../../../common/constants/formFieldTypes";
import * as appActions from "../../../../app/actions/appActions";

const useStylesSearchFilters = makeStyles((theme) => ({
    root: {
        display: "flex",
        textAlign: "left",
        minHeight: 40,
        padding: "6px 24px",
    },
    leftHeader: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        lineHeight: "25px",
    },
    container: {
        display: "flex",
        width: "calc(100% - 42px)",
        flexWrap: "wrap",
        "& > div": { margin: "1px 2px" },
    },
    excludeChipFilter: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        fill: theme.palette.error.contrastText,
    },
    lookupChipFilter: { backgroundColor: theme.palette.lookup.main },
    lookupChipFilterIcon: { color: theme.palette.neutral.main },
    formParamDisabled: {
        background: `linear-gradient(-46deg, ${theme.palette.neutral.main} 25%, ${theme.palette.neutral.ultraLight} 25%, ${theme.palette.neutral.ultraLight} 50%, ${theme.palette.neutral.main} 50%, ${theme.palette.neutral.main} 75%, ${theme.palette.neutral.ultraLight} 75%)`,
        backgroundSize: "30px 30px",
        color: "#FFF",
    },
    formParamDisabledText: { color: "#FFF" },
}));

/**
 * Return formatted filter prefix from one or many values
 * @param polyglot
 * @param value
 * @param isExclude
 * @returns {string}
 */
const getFilterPrefix = (polyglot, value, isExclude = false) => {
    if (value !== "" && polyglot.has(value)) {
        return polyglot.t(value) + polyglot.t("colon") + (isExclude ? polyglot.t("NOT") : "");
    }
    return "";
};

export const SearchFilters = (props) => {
    const {
        onDateNavigation,
        onDeleteFilter,
        attributes,
        dateFormatShort,
        accountEntities,
        businessEntities,
        columnParams,
        formParams,
        excludeParams,
        lookupParams,
        onBackToPeriod,
        selectedAccountIdList,
        userLocale,
        disableActions,
    } = props;

    const p = useP();
    const classes = useStylesSearchFilters(props);
    const { id: module, references } = useModule();
    const storeRef = useSelector((state) => state.ref);

    const dispatch = useDispatch();
    const fetchSelectedAccountList = (accountIdList) =>
        dispatch(appActions.fetchSelectedAccountList(accountIdList));

    /**
     * Delete filter
     * @param id
     * @param type
     * @param value
     * @returns {Function}
     */
    const handleDelete = (id, type, value) => () => {
        if (onDeleteFilter) {
            onDeleteFilter(id, type, value);
        } else {
            console.warn("onDeleteFilter is undefined.");
        }
    };

    /**
     * Previous date navigation
     * @param id
     * @param value
     * @returns {Function}
     */
    const handleFilterDatePrevious = (id, value) => () => {
        let newDates =
            value.hasOwnProperty("derivedFrom") && value.hasOwnProperty("derivedTo")
                ? getDatesPreviousPeriod(value.interval, value.derivedFrom, value.derivedTo)
                : getDatesPreviousPeriod(value.interval, value.from, value.to);

        if (onDateNavigation) {
            onDateNavigation(
                id,
                newDates.dateFrom.toDate(), // from
                newDates.dateTo.toDate(), //to
                value.interval
            );
        } else {
            console.warn("onDateNavigation is undefined.");
        }
    };

    /**
     * Next date navigation
     * @param id
     * @param value
     * @returns {Function}
     */
    const handleFilterDateNext = (id, value) => () => {
        let newDates =
            value.hasOwnProperty("derivedFrom") && value.hasOwnProperty("derivedTo")
                ? getDatesNextPeriod(value.interval, value.derivedFrom, value.derivedTo)
                : getDatesNextPeriod(value.interval, value.from, value.to);

        if (onDateNavigation) {
            onDateNavigation(
                id,
                newDates.dateFrom.toDate(), // from
                newDates.dateTo.toDate() //to
            );
        } else {
            console.warn("onDateNavigation is undefined.");
        }
    };

    /**
     * @param type
     * @returns {Function}
     */
    const buildChipFilter = (type) => (param) => {
        const attribute = attributes[param.replace(new RegExp("_[0-9]+$", "ig"), "")];
        if (attribute && attribute.displayType) {
            let _params = props[`${type}Params`];
            let value;
            if (!_params) {
                value = props[type];
            } else {
                value = _params[param];
            }

            let chipProps = {
                label: "",
                onDelete: !disableActions ? handleDelete(param, type, value) : undefined,
                id: `chip-${type}-${param}`,
            };

            let isExclude = type === "exclude";
            let isLookup = type === "lookup";
            if (!value && !isLookup && !isExclude) {
                return null;
            }

            // TODO => improve & harmonize label handling
            switch (attribute.displayType) {
                case displayTypes.DISPLAY_DATE:
                    // Display: date.from - date.to
                    // Add Date navigation buttons (previous/next)
                    if (
                        value &&
                        ((typeof value === "object" && value.to && value.from) ||
                            typeof value === "string")
                    ) {
                        let dateStart;
                        let dateEnd;
                        let navigate = false;
                        let navigable = !/^[0-9]+$/.test(value?.interval);

                        // Date as string (from lookup)
                        if (typeof value === "string") {
                            if (value.indexOf("EMPTY") < 0) {
                                dateStart = moment(value);
                                dateEnd = moment(value);
                            }
                        } else if (onBackToPeriod && value.derivedFrom && value.derivedTo) {
                            // Derived dates from navigation (except for export filters)
                            dateStart = moment(value.derivedFrom);
                            dateEnd = moment(value.derivedTo);
                            navigate = true;
                        } else {
                            dateStart = moment(value.from);
                            dateEnd = moment(value.to);
                        }
                        let todayDate = moment();
                        const nextDates = isExclude
                            ? undefined
                            : getDatesNextPeriod(value.interval, dateStart, dateEnd);
                        const isNextDateToToday = isExclude
                            ? undefined
                            : moment(nextDates.dateTo).isSameOrBefore(
                                  moment(new Date(todayDate.format("YYYY-MM-DD"))),
                                  "days"
                              );

                        let label;
                        if (!dateStart && !dateEnd) {
                            // Lookup or exclude empty
                            label =
                                (isExclude ? `${p.t("NOT")} ` : "") +
                                p.t("common.context_menu.no_value");
                        } else {
                            label = getFormattedPeriod(
                                p,
                                dateFormatShort,
                                dateStart,
                                dateEnd,
                                value.interval,
                                navigate
                            );
                        }

                        chipProps = {
                            ...chipProps,
                            label: `${p.t(
                                `attributes.${module}.${attribute.id}.tableLabel`
                            )} : ${label}`,
                            ...(navigate &&
                                onBackToPeriod && {
                                    icon: "fa-history",
                                    onIconClick: onBackToPeriod(param),
                                }),
                            ...(!isLookup &&
                                !isExclude &&
                                !!onDateNavigation &&
                                navigable && {
                                    onPrevious: handleFilterDatePrevious(param, value),
                                    onNext: isNextDateToToday
                                        ? handleFilterDateNext(param, value)
                                        : undefined,
                                }),
                        };
                    } else {
                        return null; // should not render filter
                    }
                    break;

                case displayTypes.DISPLAY_CUSTOM_DATA:
                    chipProps.label = buildCustomDataChipFilterLabel(
                        module,
                        attribute.id,
                        _params,
                        param,
                        isExclude,
                        p
                    );
                    break;

                case displayTypes.DISPLAY_MONTH:
                    const month = moment(value, "MM-yyyy");
                    chipProps.label = `${p.t(
                        `attributes.${module}.${attribute.id}.tableLabel`
                    )} : ${month.format("MMMM yyyy")}`;
                    break;

                case displayTypes.DISPLAY_TEXT:
                default:
                    // exception for status which is relative to status_period
                    let labelParam =
                        param === "status"
                            ? _params["status_period"]
                                ? `${_params["status_period"]}_${param}`
                                : param
                            : attribute.id;

                    chipProps.prefix =
                        p.t(`attributes.${module}.${labelParam}.tableLabel`) + p.t("colon");

                    if (isMultiValueFormFieldType(attribute.formFieldType)) {
                        if (!Array.isArray(value)) {
                            value = [value];
                        }

                        let args = {};

                        if (selectedAccountIdList) {
                            args.accountIdList = selectedAccountIdList;
                        }

                        let relativeValue;
                        if (attribute.relativeField) {
                            relativeValue = _params[attribute.relativeField][0];
                            if (relativeValue === undefined) {
                                console.warn(
                                    `Relative field ${attribute.relativeField} of dataField ${attribute.id} not found.`
                                );
                            } else {
                                args[attribute.relativeField] = relativeValue;
                            }
                        }

                        if (attribute.formFieldType === formFieldTypes.FORM_FIELD_ACCOUNT) {
                            // check if some selected accounts in aren't loaded in account entities then request them
                            if (
                                value[0] !== "_every" &&
                                !value.every((accountId) => accountId in accountEntities)
                            ) {
                                fetchSelectedAccountList(value);
                            }

                            chipProps = {
                                ...chipProps,
                                ...buildAccountDisplayPropsFromEntities(
                                    p,
                                    value,
                                    accountEntities,
                                    businessEntities
                                ),
                            };
                        } else if (value.length === 1) {
                            // Get attribute entities list
                            const attributeEntities = getAttributeEntities(
                                attribute,
                                args,
                                storeRef,
                                p,
                                references
                            );
                            let item;
                            if (attributeEntities) {
                                item = attributeEntities[value[0]];
                            }

                            if (item === undefined) {
                                // handle empty filter
                                if (value[0] === "EMPTY") {
                                    chipProps.label = p.t("common.search.empty");
                                } else if (
                                    attribute.displayOptions &&
                                    attribute.displayOptions.label &&
                                    typeof attribute.displayOptions.label === "function"
                                ) {
                                    // handle display option label (ex: transaction.retraction_delay)
                                    chipProps.label = attribute.displayOptions.label(value[0], p);
                                } else {
                                    chipProps.label = value[0];
                                }
                            } else {
                                chipProps = {
                                    ...chipProps,
                                    ...item,
                                    id: chipProps.id,
                                };
                                // Replace props "icon" by "labelIcon" to remove the icon before label on HiChip component
                                if (item.icon) {
                                    delete chipProps.icon;
                                    chipProps.labelIcon = item.icon;
                                    // Remove props "style"
                                    if (item.style) {
                                        delete chipProps.style;
                                    }
                                }
                            }
                            chipProps.prefix = getFilterPrefix(
                                p,
                                `attributes.${module}.${labelParam}.tableLabel`,
                                isExclude
                            );
                        } else {
                            if (attribute.formFieldType === formFieldTypes.FORM_FIELD_STATUS) {
                                const options = getAttributeOptions(attribute, args, storeRef, p);
                                Object.values(options).forEach((statusGroup) => {
                                    if (
                                        statusGroup.children &&
                                        statusGroup.children.length === value.length &&
                                        statusGroup.children.every((status) => {
                                            return value.includes(status.id);
                                        })
                                    ) {
                                        chipProps.label = statusGroup.label;
                                    }
                                });
                            }
                            if (!chipProps.label) {
                                chipProps.label =
                                    (isExclude ? p.t("NOT") + " " : "") +
                                    value.length +
                                    " " +
                                    p.t(`modules.${module}.many_attributes`);
                                // chipProps.label = this.getManyFilterLabel(labelParam, value.length, isExclude);
                            }
                        }
                    } else if (attribute.formFieldType === formFieldTypes.FORM_FIELD_SWITCH) {
                        // Chip for boolean values
                        let _val = !Array.isArray(value) ? [value] : value;

                        if (isExclude) {
                            _val[0] = value.indexOf("1") >= 0 ? "1" : "0";
                            if (value.indexOf("0") >= 0 && _val[0] !== "0") {
                                _val[1] = "0";
                            }
                        }

                        if (_val.length === 1) {
                            chipProps.label = p.t(
                                `attributes.${module}.${labelParam}.valueLabel_${_val[0]}`
                            );
                        } else {
                            // Exclude 2 values (true & false)
                            let linkWord = ` ${p.t("AND")} `;
                            if (isExclude) {
                                linkWord += `${p.t("NOT")} `;
                            }
                            chipProps.label =
                                p.t(`attributes.${module}.${labelParam}.valueLabel_${_val[0]}`) +
                                linkWord +
                                p.t(`attributes.${module}.${labelParam}.valueLabel_${_val[1]}`);
                        }
                        chipProps.prefix = getFilterPrefix(
                            p,
                            `attributes.${module}.${labelParam}.tableLabel`,
                            isExclude
                        );
                    } else if (props[param]) {
                        chipProps.label = props[param];
                    } else if (
                        [displayTypes.DISPLAY_NUMERIC, displayTypes.DISPLAY_AMOUNT].includes(
                            attribute.displayType
                        )
                    ) {
                        if (isExclude) {
                            chipProps.label = _params[param].replace(/"[0-9\-.]*"/g, (match) => {
                                match = match.replace(/"/g, "");
                                return toUserLocalNumber(
                                    Number(match),
                                    userLocale,
                                    attribute.displayOptions.precision || 0
                                );
                            });
                        } else if (!isNaN(_params[param])) {
                            chipProps.label = toUserLocalNumber(
                                Number(_params[param]),
                                userLocale,
                                attribute.displayOptions.precision || 0
                            );
                        } else {
                            chipProps.label = _params[param];
                        }
                    } else if (
                        attribute.displayOptions &&
                        attribute.displayOptions.label &&
                        typeof attribute.displayOptions.label === "function"
                    ) {
                        // handle display option label (ex: transaction.retraction_delay)
                        chipProps.label = translateQuery(
                            attribute.displayOptions.label(value, p),
                            p
                        );
                    } else if (type === "lookup") {
                        chipProps.label = `"${_params[param]}"`;
                    } else {
                        chipProps.label = translateQuery(_params[param], p);
                    }
                    break;
            }

            if (isExclude) {
                chipProps.icon = <CloseCircleOutline />;
            } else if (type === "lookup") {
                chipProps.icon = <CrosshairsGps />;
            } else if (type === "column") {
                chipProps.icon = "menu";
            }

            if (chipProps.label && (isExclude || isLookup)) {
                chipProps.label = chipProps.label
                    // Remove outer parentheses
                    .replace(/\((\(*(?:[^)(]*|\([^)]*\))*\)*)\)/g, "$1")
                    // remove quotes (except for only spaces)
                    .replace(/"(\s*)"/g, "'$1'")
                    .replace(/"(.+)"/, "$1")
                    .replace(/"((?:\s*[^\s"']+\s*)+)"/g, "$1")
                    .replace(/'(\s*)'/g, '"$1"')
                    // translate empty value
                    .replace("EMPTY", p.t("common.context_menu.no_value"));
            }

            return (
                <HiChip
                    key={param}
                    classes={{
                        root: classNames(classes.chipFilter, {
                            [classes.excludeChipFilter]: isExclude,
                            [classes.lookupChipFilter]: isLookup,
                            [classes.formParamDisabled]:
                                type === "form" && (columnParams[param] || lookupParams[param]),
                        }),
                        prefix: classNames({
                            [classes.excludeChipFilter]: isExclude,
                            [classes.formParamDisabledText]:
                                type === "form" && (columnParams[param] || lookupParams[param]),
                        }),
                        label: classNames({
                            [classes.excludeChipFilter]: isExclude,
                            [classes.formParamDisabledText]:
                                type === "form" && (columnParams[param] || lookupParams[param]),
                        }),
                        eraseIcon: classNames({
                            [classes.excludeChipFilter]: isExclude,
                            [classes.formParamDisabledText]:
                                type === "form" && (columnParams[param] || lookupParams[param]),
                        }),
                        icon: classNames({ [classes.lookupChipFilterIcon]: isLookup }),
                    }}
                    {...chipProps}
                />
            );
        }
    };

    return [
        Object.keys(formParams).map(buildChipFilter("form")),
        Object.keys(lookupParams).map(buildChipFilter("lookup")),
        Object.keys(excludeParams).map(buildChipFilter("exclude")),
        Object.keys(columnParams).map(buildChipFilter("column")),
    ];
};

SearchFilters.propTypes = {
    /**
     * Column params object
     */
    columnParams: PropTypes.object.isRequired,
    /**
     * Form params object
     */
    formParams: PropTypes.object.isRequired,
    /**
     * Exclude params object
     */
    excludeParams: PropTypes.object.isRequired,
    /**
     * Lookup params object
     */
    lookupParams: PropTypes.object.isRequired,
    /**
     * Callback - Back to reference (url) period
     */
    onBackToPeriod: PropTypes.func,
    /**
     * Callback - date navigation with ID, FROM, TO
     */
    onDateNavigation: PropTypes.func,
    /**
     * Callback - delete filter with ID
     */
    onDeleteFilter: PropTypes.func,
    /**
     * Locale from user settings
     */
    userLocale: PropTypes.string,
};

SearchFilters.defaultProps = {};
