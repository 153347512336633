import { useState } from "react";

/**
 * CONSTANTS
 */
// const LS_SEARCH_RESULT_CONTEXT = 'search-results-context';
const LS_SEARCH_RESULT_CONTEXT = process.env.NX_NOTICE_CONTEXT;
/**
 * Number of item before and after current item
 * @type {number}
 */
export const SIZE = 10;

/**
 * Number of item to list limit before requesting new items
 * @type {number}
 */
export const MARGIN = 2;

/**
 * Create searchResults object from id
 */
const fromId = (id = null) => ({
    referer: null,
    query: null,
    total: id ? 1 : 0,
    entities: {},
    idList: id ? [id] : [],
    outset: 0,
});

/**
 * Create searchResults object from string
 * - parse string
 */
const fromString = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
};

/**
 * Create searchResults object from LocalStorage OR fromId
 */
const fromLocalStorage = (id = null) => {
    try {
        // get from local storage by key
        const item = window.localStorage.getItem(LS_SEARCH_RESULT_CONTEXT);
        if (item) {
            // clear local storage
            window.localStorage.removeItem(LS_SEARCH_RESULT_CONTEXT);
            return fromString(item);
        }
    } catch (error) {
        console.warn(error);
    }
    // else
    return fromId(id);
};

// create and persist search result in local storage
function setSearchResult(history, query, total, datas, rowId) {
    try {
        const referer = history.createHref(history.location);

        let searchResult = {
            referer,
            query,
            total,
        };

        if (datas && datas.length > 0) {
            // absolute index of the first item of idList ( currentId - SIZE )
            const outset = Math.max(0, datas.findIndex((entity) => entity.rowId === rowId) - SIZE);

            // persist results as entities
            const { entities, idList } = datas
                .slice(outset, Math.min(datas.length, outset + 2 * SIZE + 1))
                .reduce(
                    (memo, entity) => {
                        let id = entity.rowId.toString();
                        return {
                            entities: {
                                ...memo.entities,
                                [id]: {
                                    data: entity,
                                    loading: false,
                                    error: null,
                                },
                            },
                            idList: [...memo.idList, id],
                        };
                    },
                    {
                        entities: {},
                        idList: [],
                    }
                );

            searchResult.entities = entities;
            searchResult.idList = idList;
        } else {
            searchResult.entities = {};
            searchResult.idList = [rowId];
        }

        // save to local storage
        window.localStorage.setItem(LS_SEARCH_RESULT_CONTEXT, JSON.stringify(searchResult));
    } catch (error) {
        // A more advanced implementation would handle the error case
        console.warn(error);
    }
}

// Consumer Hook - consume (read and remove) data from local storage
function useSearchResult(id = null) {
    // Init state from localStorage or default object
    const [searchResult] = useState(() => fromLocalStorage(id));

    // { referer, query, total, entities, idList, outset }
    return searchResult;
}

export { setSearchResult, useSearchResult };
