import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
    root: {
        ...theme.typography.body2,
        textAlign: "left",
        width: "100%",
        padding: "0px 24px",
        "&:first-child": {
            padding: "0px 24px",
        },
    },
}));

export default useStyles;
