import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Drag from "mdi-material-ui/Drag";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { useP } from "../../../../services/i18n";
import { handleKeyValid } from "../../../../utils/keyboard";
import { updateGridParamsInSearchUrl } from "../../../../utils/urls";
import { useFavorites } from "../../../services/FavoritesContext";
import { useFavoriteUpdate } from "../../../queries/favorites";

const useStylesGridChange = makeStyles((theme) => ({
    root: {
        ...theme.typography.body3,
        height: 24,
        width: "fit-content",
        maxWidth: "100%",
        margin: "0 0 4px 8px",
        display: "inline-flex",
        alignItems: "center",
        border: "1px solid",
        borderRadius: 12,
        paddingRight: 2,
        backgroundColor: theme.palette.neutral.main,
        borderColor: theme.palette.neutral.main,
        "&>div": { color: theme.palette.neutral.contrastText },
    },
    saveContainer: {
        height: "100%",
        width: 24,
        marginRight: 4,
        borderRadius: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        outline: "none",
        cursor: "pointer",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.neutral.contrastText,
            "&>span": { color: theme.palette.neutral.main },
        },
    },
    eraseIcon: {
        cursor: "pointer",
        marginLeft: 4,
        color: theme.palette.neutral.contrastText,
        "&:hover, &:focus": { color: theme.palette.neutral.dark },
    },
    dragIcon: {
        color: theme.palette.neutral.contrastText,
        fontSize: 20,
    },
}));

/**
 * Display "Column changed" chip if current columns order is different from selected favorite or user settings
 * @param props
 * @returns {*}
 * @constructor
 */
export const GridChange = (props) => {
    const { persistOrderedColumns, onResetColumnsOrder } = props;

    const classes = useStylesGridChange();
    const p = useP();
    const [{ selectedFavorite }] = useFavorites();
    const [updateFavorite] = useFavoriteUpdate();
    const location = useLocation();
    const history = useHistory();

    // cannot be edited if its a predefined favorite
    const isEditable = !(selectedFavorite && p.has(selectedFavorite.name));

    // current grid from url
    const currentGrid = useMemo(() => {
        const currentGridMatch = /grid=([0-9A-Z_]*)/.exec(location.search);
        return currentGridMatch && currentGridMatch[1];
    }, [location.search]);

    // current grid from url
    const currentRank = useMemo(() => {
        const currentRankMatch = /rank=(true|false)/.exec(location.search);
        return currentRankMatch && currentRankMatch[1];
    }, [location.search]);

    // current grid from url
    const currentSelector = useMemo(() => {
        const currentSelectorMatch = /selector=(true|false)/.exec(location.search);
        return currentSelectorMatch && currentSelectorMatch[1];
    }, [location.search]);

    /**
     * Persist grid changes
     * - if a favorite is selected, persist it
     * - else, persist grid on user settings
     * @type {Function}
     */
    const persistGridChanges = useCallback(() => {
        if (selectedFavorite) {
            updateFavorite({
                ...selectedFavorite,
                urlSearch: updateGridParamsInSearchUrl(
                    selectedFavorite.urlSearch,
                    currentGrid,
                    currentRank,
                    currentSelector
                ),
            });
        } else {
            persistOrderedColumns();
        }
    }, [
        currentGrid,
        currentRank,
        currentSelector,
        persistOrderedColumns,
        selectedFavorite,
        updateFavorite,
    ]);

    /**
     * Reset grid changes
     * - if a favorite is selected, reset to favorite grid
     * - else, reset to user settings grid
     * @type {Function}
     */
    const resetGridChanges = useCallback(() => {
        if (selectedFavorite) {
            let newLocation = location.search;

            const favoriteGridMatch = /grid=([0-9A-Z_]*)/.exec(selectedFavorite.urlSearch);
            const favoriteGrid = favoriteGridMatch && favoriteGridMatch[1];
            newLocation = newLocation.replace(
                /grid=[0-9A-Z_]*/,
                favoriteGrid ? `grid=${favoriteGrid}` : ""
            );

            const favoriteRankMatch = /rank=(true|false)/.exec(selectedFavorite.urlSearch);
            const favoriteRank = favoriteRankMatch && favoriteRankMatch[1];
            newLocation = newLocation.replace(
                /rank=(true|false)/,
                favoriteRank ? `rank=${favoriteRank}` : ""
            );

            const favoriteSelectorMatch = /selector=(true|false)/.exec(selectedFavorite.urlSearch);
            const favoriteSelector = favoriteSelectorMatch && favoriteSelectorMatch[1];
            newLocation = newLocation.replace(
                /selector=(true|false)/,
                favoriteSelector ? `selector=${favoriteSelector}` : ""
            );

            newLocation = newLocation.replace(/&$/, "");
            history.replace(newLocation);
        } else {
            onResetColumnsOrder();
        }
    }, [history, location, onResetColumnsOrder, selectedFavorite]);

    return (
        <div id="chip-changed-columns" className={classes.root}>
            {isEditable ? (
                <div
                    tabIndex="0"
                    role="button"
                    className={classes.saveContainer}
                    onKeyDown={handleKeyValid(persistGridChanges)}
                    onClick={persistGridChanges}
                >
                    <HiIcon
                        icon="save"
                        size={16}
                        title={p.t("common.search.filters.changed_columns_save")}
                    />
                </div>
            ) : (
                <Drag className={classes.dragIcon} />
            )}
            <div>{p.t("common.search.filters.changed_columns")}</div>
            <HiIcon
                classes={{ root: classes.eraseIcon }}
                id="chip-changed-columns-delete"
                icon="cancel"
                onClick={resetGridChanges}
                onKeyDown={handleKeyValid(resetGridChanges)}
                size={20}
                title={p.t("common.search.filters.changed_columns_cancel")}
                tabIndex={0}
            />
        </div>
    );
};

GridChange.propTypes = {
    /**
     * Callback function to set columns order to default (or user's preferences).
     */
    onResetColumnsOrder: PropTypes.func.isRequired,
    /**
     * Callback function to save current columns order into user preferences.
     */
    persistOrderedColumns: PropTypes.func.isRequired,
};
