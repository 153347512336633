import { formFieldTypes, displayTypes } from "@console/core";

const attributes = {
    payment_order_key: {
        id: "payment_order_key",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 1,
    },
    external_reference_id: {
        id: "external_reference_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 2,
    },
    type: {
        id: "type",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        order: 3,
        reference: "payment_orders_type",
    },
    mkp_vendor_name: {
        id: "mkp_vendor_name",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 4,
    },
    mkp_vendor_name_url: {
        id: "mkp_vendor_name_url",
        displayType: displayTypes.DISPLAY_LINK,
        displayOptions: {},
    },
    hipay_account_id: {
        id: "hipay_account_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 5,
    },
    mkp_vendor_id: {
        id: "mkp_vendor_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 6,
    },
    transfer_id: {
        id: "transfer_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 7,
    },
    withdrawal_id: {
        id: "withdrawal_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 8,
    },
    amount: {
        id: "amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            displayCurrency: true,
            align: "left",
            precision: 2,
            sortable: true,
            exclude: false,
            lookup: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            onlyNumbers: true,
        },
        order: 9,
    },
    status: {
        id: "status",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        order: 10,
        reference: "payment_orders_status",
    },
    date_created: {
        id: "date_created",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            displayTime: true,
            lookup: true,
            sortable: true,
            exclude: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        order: 11,
    },
    date_updated: {
        id: "date_updated",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            displayTime: true,
            sortable: true,
            exclude: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        order: 12,
    },
    nb_retry: {
        id: "nb_retry",
        displayType: displayTypes.DISPLAY_NUMERIC,
        displayOptions: {
            align: "left",
            sortable: false,
            exclude: false,
            lookup: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            onlyNumbers: true,
        },
        order: 13,
    },
    technical_status: {
        id: "status",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        order: 14,
    },
};

export default attributes;
