// PERMISSIONS
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_INTERNAL = "ROLE_INTERNAL";
export const ROLE_MERCHANT = "ROLE_MERCHANT";
export const ROLE_MERCHANT_ADMIN = "ROLE_MERCHANT_ADMIN";

// main colors
export const mainColors = [
    "red",
    "pink",
    "purple",
    "deepPurple",
    "indigo",
    "blue",
    "lightBlue",
    "cyan",
    "teal",
    "green",
    "lightGreen",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deepOrange",
];

export const mainPalette = [500, 700, 900];

/**
 * Cell size [L] / [M] / [S]
 */
export const VIEWS = {
    LARGE: "l",
    MEDIUM: "m",
    SMALL: "s",
};

export const LANGUAGES = {
    FRENCH: "fr_FR",
    ENGLISH: "en_US",
    GERMAN: "de_DE",
    SPANISH: "es_ES",
    ITALIAN: "it_IT",
    DUTCH: "nl_NL",
    PORTUGUESE: "pt_PT",
    PORTUGUESE_BRAZIL: "pt_BR",
};

export const availableLanguages = [
    {
        id: LANGUAGES.FRENCH,
        label: "FR",
        isoCode: "fr",
        environment: ["local", "dev", "test", "preprod", "stage", "prod"],
    },
    {
        id: LANGUAGES.ENGLISH,
        label: "EN",
        isoCode: "en",
        environment: ["local", "dev", "test", "preprod", "stage", "prod"],
    },
    {
        id: LANGUAGES.GERMAN,
        label: "DE",
        isoCode: "de",
        environment: ["local", "dev", "test"],
    },
    {
        id: LANGUAGES.SPANISH,
        label: "ES",
        isoCode: "es",
        environment: ["local", "dev", "test"],
    },
    {
        id: LANGUAGES.ITALIAN,
        label: "IT",
        isoCode: "it",
        environment: ["local", "dev", "test", "preprod", "stage", "prod"],
    },
    {
        id: LANGUAGES.DUTCH,
        label: "NL",
        isoCode: "nl",
        environment: ["local", "dev", "test"],
    },
    {
        id: LANGUAGES.PORTUGUESE,
        label: "PT",
        isoCode: "pt",
        environment: ["local", "dev", "test"],
    },
    {
        id: LANGUAGES.PORTUGUESE_BRAZIL,
        label: "PTB",
        isoCode: "ptb",
        environment: ["local", "dev", "test"],
    },
].filter((locale) => locale.environment.indexOf(process.env.NX_ENV) >= 0);

//SEARCH VIEW
export const THUMBNAIL_VIEW = "THUMBNAIL";
export const GRID_VIEW = "GRID";
//SEARCH GRID VIEW ITEMS TYPE
export const WIDGET_TYPE = "WIDGET_TYPE";

export const TOP_BAR_HEIGHT = 64;
export const MESSAGE_BAR_HEIGHT = 50;

export const LIMITED_SEARCH_STATUS = {
    DEFAULT: "default",
    UNLIMITED: "unlimited",
};

export const cst = {
    ROLE_ADMIN,
    ROLE_INTERNAL,
    ROLE_MERCHANT,
    ROLE_MERCHANT_ADMIN,
    mainColors,
    VIEWS,
    LANGUAGES,
    availableLanguages,
    THUMBNAIL_VIEW,
    GRID_VIEW,
    WIDGET_TYPE,
    TOP_BAR_HEIGHT,
    MESSAGE_BAR_HEIGHT,
    LIMITED_SEARCH_STATUS,
};
