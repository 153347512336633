const customerTypes = {
    id: "customer_types",
    data: [
        {
            id: "B2B",
            label: "ref.transaction.customer_types.B2B",
        },
        {
            id: "B2C",
            label: "ref.transaction.customer_types.B2C",
        },
    ],
    translate: true,
};

export default customerTypes;
