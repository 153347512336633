import { FavoritesContextProvider } from "./FavoritesContext.provider";
import { FavoritesStateContext, FavoritesActionsContext } from "./FavoritesContext.context";
import { compose } from "redux";

export function withFavoritesProvider(Component) {
    return function FavoritesProviderComponent(props) {
        return (
            <FavoritesContextProvider>
                <Component {...props} />
            </FavoritesContextProvider>
        );
    };
}

export function withFavoritesState(Component) {
    return function FavoritesStateComponent(props) {
        return (
            <FavoritesStateContext.Consumer>
                {(favoritesState) => <Component {...props} favoritesState={favoritesState} />}
            </FavoritesStateContext.Consumer>
        );
    };
}

export function withFavoritesActions(Component) {
    return function SearchFavoritesActionsComponent(props) {
        return (
            <FavoritesActionsContext.Consumer>
                {(favoritesActions) => <Component {...props} favoritesActions={favoritesActions} />}
            </FavoritesActionsContext.Consumer>
        );
    };
}

export function withFavorites(Component) {
    return compose(withFavoritesState, withFavoritesActions)(Component);
}
