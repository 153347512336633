const statusColor = {
    default: "info",
    ok: "success",
    primary: "primary",
    warning: "warning",
};

export default {
    id: "settlement_statuses",
    data: [
        {
            id: "CREATED",
            label: "ref.financial_report.settlement_statuses.CREATED",
            color: statusColor.default,
        },
        {
            id: "SENT",
            label: "ref.financial_report.settlement_statuses.SENT",
            color: statusColor.warning,
        },
        {
            id: "NOTIFIED",
            label: "ref.financial_report.settlement_statuses.NOTIFIED",
            color: statusColor.primary,
        },
        {
            id: "EXECUTED",
            label: "ref.financial_report.settlement_statuses.EXECUTED",
            color: statusColor.ok,
        },
    ],
    translate: true,
};
