const locales = {
    id: "locales",
    data: [
        {
            id: "en_GB",
            label: "ref.transaction.locales.en_GB",
        },
        {
            id: "en_US",
            label: "ref.transaction.locales.en_US",
        },
        {
            id: "fr_FR",
            label: "ref.transaction.locales.fr_FR",
        },
    ],
    translate: true,
};

export default locales;
