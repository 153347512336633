import { Component } from "react";
import PropTypes from "prop-types";
import moize from "moize";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { withStylesAndTranslate } from "../../../../../hoc";
import { capitalize } from "@hipay/hipay-material-ui/utils/helpers";
import { LazyLoadingRow } from "./LazyLoadingRow";
import { LazyLoadingNextDatas } from "./LazyLoadingNextDatas";
import Error from "./Error";
import { Loading } from "./Loading";
import NoData from "./NoData";
import { getDayLabelFromRelativeDate, getAttributeEntities } from "../../../../../utils";
import moment from "moment-timezone";
import { buildArgs } from "../../../../../services";

const groupRowsByOrderDate = (data, groupByAttribute) => {
    return data.reduce((memo, row) => {
        const rowDate = moment(row.datas[groupByAttribute].value)
            .format("YYYY-MM-DDTHH:mm:ssZ")
            .slice(0, 10);
        if (rowDate in memo) {
            memo[rowDate].push(row);
        } else {
            memo[rowDate] = [row];
        }
        return memo;
    }, {});
};

const MemoizedGroupByDate = moize(groupRowsByOrderDate, { maxSize: 1 });

export const stylesBody = (theme) => ({
    stickyGroupByRow: {
        padding: 16,
        zIndex: 12,
        position: "sticky",
        left: 0,
        top: 276,
        width: window.innerWidth,
        height: 48,
        backgroundColor: "#F8F9FB",
        textAlign: "left",
        boxShadow: "0px 2px 8px -1px rgba(0, 0, 0, 0.3) inset",
    },
    dateLabel: {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightLight,
    },
    notFiltered: { top: 236 },
    noFavoritesBar: {
        top: 188,
        "&$notFiltered": { top: 148 },
    },
});

class BodyClass extends Component {
    static propTypes = {
        index: PropTypes.number,
        /**
         * Columns list (include attribute entities)
         */
        columnList: PropTypes.array,
        /**
         * Rows list
         */
        data: PropTypes.array,
        /**
         * Format des dates à l'affichage
         */
        dateLocale: PropTypes.string,
        /**
         * How many lines does chip fitlers have.
         */
        chipFilterLines: PropTypes.number,
        /**
         * How many lines does chip favorites have.
         */
        chipFavoritesLines: PropTypes.number,
        /**
         * Favorites bar is visible
         */
        isDisplayFavorites: PropTypes.bool,
        /**
         * Rank column is visible
         */
        isDisplayRank: PropTypes.bool,
        /**
         * Callback function - request next datas (infinite scroll)
         */
        onRequestNextDatas: PropTypes.func,
        /**
         * Callback function - click on row (redirect to notice)
         */
        onRowClick: PropTypes.func,
        /**
         * State of the search if its limited or not
         */
        searchState: PropTypes.string,
        /**
         * Params from quick search
         */
        qsearchParams: PropTypes.object,
        /**
         * Callback function - search more than two years
         */
        handleSearchAll: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = { screenWidth: window.innerWidth };
    }

    // return columnList with defined entities
    mapColumnListEntities = (attributes, columnList, storeRef, args, p, moduleReferences) => {
        return columnList.map((c) => ({
            ...c,
            dataList: getAttributeEntities(
                attributes[c.colId],
                args,
                storeRef,
                p,
                moduleReferences
            ),
        }));
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }

    updateWidth = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    MemoizedMapColumnListEntities = moize(this.mapColumnListEntities, { maxSize: 1 });

    render() {
        const {
            accountIdList,
            attributes,
            chipFilterLines,
            chipFavoritesLines,
            classes,
            columnList,
            data,
            error,
            groupByAttribute,
            groupByDate,
            hasNextDatas,
            loading,
            lookupColumnIdList,
            isDisplaySelector,
            isDisplayRank,
            isDisplayFavorites,
            onRequestNextDatas,
            onRowClick,
            onRowMiddleClick,
            p,
            references,
            userSettings,
            moduleReferences,
            searchState,
            qsearchParams,
            handleSearchAll,
        } = this.props;

        const { screenWidth } = this.state;

        // Display error view
        if (error) {
            return <Error error={error} />;
        }

        // Loading table view
        if (loading && data.length === 0) {
            return <Loading />;
        }

        // No datas table view
        if (data.length === 0) {
            return <NoData searchState={searchState} handleSearchAll={handleSearchAll} />;
        }

        const columnListWithData = this.MemoizedMapColumnListEntities(
            attributes,
            columnList,
            references,
            buildArgs(accountIdList),
            p,
            moduleReferences
        );

        if (groupByDate) {
            // Group row by groupByAttribute (displayType DATE)
            const groupedRows = MemoizedGroupByDate(data, groupByAttribute);
            let row_num = 0;
            return (
                <>
                    {Object.keys(groupedRows).map((date, index) => (
                        <div key={`${date}-${index}`} id={`search-results-body-${index}`}>
                            <StickyGroupByRow
                                classes={classes}
                                date={date}
                                p={p}
                                isDisplayFavorites={isDisplayFavorites}
                                chipFilterLines={chipFilterLines}
                                chipFavoritesLines={chipFavoritesLines}
                                screenWidth={screenWidth}
                            />
                            {groupedRows[date].map((row, _index) => {
                                row_num++;
                                return (
                                    <LazyLoadingRow
                                        key={`${date}-${_index}`}
                                        index={row_num}
                                        attributes={attributes}
                                        columnList={columnListWithData}
                                        dense={false}
                                        lookupColumnIdList={lookupColumnIdList}
                                        onClick={onRowClick}
                                        onMiddleClick={onRowMiddleClick}
                                        isDisplaySelector={isDisplaySelector}
                                        isDisplayRank={isDisplayRank}
                                        userSettings={userSettings}
                                        qsearchParams={qsearchParams}
                                        {...row}
                                    />
                                );
                            })}
                        </div>
                    ))}
                    {hasNextDatas && (
                        <LazyLoadingNextDatas
                            onRequestNextDatas={onRequestNextDatas}
                            infiniteScroll
                        />
                    )}
                </>
            );
        }

        return (
            <div id={"search-results-body"}>
                {data.map((row, index) => (
                    <LazyLoadingRow
                        key={`${index}-${row.rowId}`}
                        index={index + 1}
                        attributes={attributes}
                        columnList={columnListWithData}
                        dense={false}
                        lookupColumnIdList={lookupColumnIdList}
                        onClick={onRowClick}
                        onMiddleClick={onRowMiddleClick}
                        isDisplaySelector={isDisplaySelector}
                        isDisplayRank={isDisplayRank}
                        userSettings={userSettings}
                        qsearchParams={qsearchParams}
                        {...row}
                    />
                ))}
                {hasNextDatas && (
                    <LazyLoadingNextDatas onRequestNextDatas={onRequestNextDatas} infiniteScroll />
                )}
            </div>
        );
    }
}

export const Body = withStylesAndTranslate(stylesBody)(BodyClass);

/**
 * Sticky Group by Date Row
 * @param classes
 * @param date
 * @param p
 * @param isDisplayFavorites
 * @param chipFilterLines
 * @param chipFavoritesLines
 * @param screenWidth
 * @returns {*}
 * @constructor
 */
const StickyGroupByRow = ({
    classes,
    date,
    p,
    isDisplayFavorites,
    chipFilterLines,
    chipFavoritesLines,
    screenWidth,
}) => {
    let top = 100;
    if (!isMobile) {
        // add result bar height
        top += 48;
        // add filter block height
        if (chipFilterLines) {
            top += 40;
            // add extra filter lines height
            if (chipFilterLines > 1) {
                top += (chipFilterLines - 1) * 26 - 2;
            }
        }
        // add favorite block height
        if (isDisplayFavorites && chipFavoritesLines) {
            top += 48;
            // add single line margin
            if (chipFavoritesLines === 1) {
                top += 14;
            }
            // add extra favorite lines height
            top += chipFavoritesLines * 26;
        }
    }

    let style = {
        top: top + "px",
        width: screenWidth,
    };

    return (
        <div
            className={classNames(classes.stickyGroupByRow, {
                [classes.notFiltered]: chipFilterLines === 0,
                [classes.noFavoritesBar]: !isDisplayFavorites,
            })}
            style={style}
        >
            <div className={classes.dateLabel}>
                {capitalize(getDayLabelFromRelativeDate(p, date))}
            </div>
        </div>
    );
};
