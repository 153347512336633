export function getRedirectToUrl(location) {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("redirectTo")) {
        const redirectTo = queryParams.get("redirectTo");
        if (isRedirectToOldInterface(redirectTo) || isRedirectToIntelligence(redirectTo)) {
            return redirectTo;
        }
    }

    return false;
}

export function isRedirectToOldInterface(redirectTo) {
    const oldInterfaceUrl = new URL(String(process.env.NX_OLD_INTERFACE_BASE_URL));

    return (
        redirectTo &&
        typeof redirectTo === "string" &&
        process.env.NX_OLD_INTERFACE_BASE_URL &&
        redirectTo.includes(oldInterfaceUrl.host)
    );
}

export function isRedirectToIntelligence(redirectTo) {
    const intelligenceUrl = new URL(String(process.env.NX_INTELLIGENCE_URL));
    const rx = /(?:[a-z]*-)?(.*)$/gm;
    const intelligenceMatch = rx.exec(intelligenceUrl.host);

    return (
        redirectTo &&
        typeof redirectTo === "string" &&
        process.env.NX_INTELLIGENCE_URL &&
        intelligenceMatch &&
        intelligenceMatch.length >= 1 &&
        intelligenceMatch[1] &&
        redirectTo.includes(intelligenceMatch[1])
    );
}

export function getRedirectToIntelligence(redirectTo, token) {
    const auth = token ? "?token=" + token : "";
    return redirectTo + auth;
}
