import React from "react";
import PropTypes from "prop-types";
import { translate } from "../../../../services/i18n/translate";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import HiSwitchState from "@hipay/hipay-material-ui/HiSwitch/HiSwitchState";
import HiNestedSelect from "@hipay/hipay-material-ui/HiSelect/HiNestedSelect";
import withStyles from "@mui/styles/withStyles";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

export const stylesStatusField = (theme) => ({
    container: {
        display: "flex",
        height: 40,
    },
    mobileContainer: {
        display: "flex",
        flexDirection: "column",
    },
    mobileSwitch: { marginBottom: 8 },
    statusSelect: { width: "calc(100% - 200px - 12px)" },
});

class StatusField extends React.Component {
    static propTypes = {
        containerRef: PropTypes.func,
        containerWidth: PropTypes.number,
        fieldOptions: PropTypes.object,
        formField: PropTypes.object,
        multiFields: PropTypes.object,
        name: PropTypes.string,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        options: PropTypes.array,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    };

    render() {
        const {
            classes,
            name,
            onChange,
            onSubmit,
            value,
            options,
            fieldOptions,
            formField,
            containerWidth,
            p,
            multiFields,
        } = this.props;

        let _value = value ? (Array.isArray(value) ? value : [value]) : [];
        return (
            <HiFormControl
                ref={(container) => this.props.containerRef(container)}
                label={formField.label}
                helperText={formField.helperText}
                helperIcon
            >
                <div
                    className={classNames({
                        [classes.container]: !isMobile,
                        [classes.mobileContainer]: isMobile,
                    })}
                >
                    <HiSwitchState
                        classes={{ root: classNames({ [classes.mobileSwitch]: isMobile }) }}
                        name={fieldOptions.multiFields.period.id}
                        leftLabel={p.t("form.fields.switch_state.past")}
                        rightLabel={p.t("form.fields.switch_state.current")}
                        leftValue="past"
                        rightValue="current"
                        checked={multiFields.period || fieldOptions.multiFields.period.value}
                        color="primary"
                        onChange={onChange(fieldOptions.multiFields.period.id)}
                    />
                    <HiNestedSelect
                        hasAll
                        id={name}
                        name={name}
                        options={options}
                        value={_value}
                        onChange={onChange(name)}
                        onSubmit={onSubmit}
                        multiple
                        nbChildrenAsInfo
                        classes={{ root: classes.statusSelect }}
                        searchable
                        containerWidth={containerWidth}
                        align="right"
                        translations={{
                            all: p.t("form.fields.select.all"),
                            no_result_match: p.t("form.fields.select.no_result_match"),
                            search: p.t("form.fields.select.search"),
                            n_items_selected: p.t("form.fields.select.n_items_selected"),
                            one_item_selected: p.t("form.fields.select.one_item_selected"),
                            n_children: p.t("form.fields.select.n_children"),
                            one_child: p.t("form.fields.select.one_child"),
                        }}
                        {...formField}
                    />
                </div>
            </HiFormControl>
        );
    }
}

export default withStyles(stylesStatusField, {})(translate(StatusField));
