import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useAsync } from "react-async";
import { useSelector } from "react-redux";
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Close from "@mui/icons-material/Close";
import ArrowRight from "@mui/icons-material/ArrowRight";
import FilterList from "@mui/icons-material/FilterList";
import Menu from "@mui/icons-material/Menu";

import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";

import { useP } from "../../../../../../services/i18n";
import { toUserLocalNumber } from "../../../../../../utils/i18n";
import HiSwitch from "@hipay/hipay-material-ui/HiSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useModule } from "../../../../../../services/module";

const useStylesAmountColumnMenu = makeStyles((theme) => ({
    menu: {
        width: 300,
        maxHeight: 700,
        paddingTop: 16,
        paddingBottom: 8,
    },
    menuTitle: {
        ...theme.typography.h3,
        marginLeft: 12,
        marginRight: 40,
        whiteSpace: "prewrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 16,
    },
    menuItemIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
        color: theme.palette.neutral.normal,
    },
    menuItemFilter: {
        ...theme.typography.subtitle2,
        color: theme.palette.neutral.dark,
        height: 40,
        lineHeight: "40px",
        paddingLeft: 28,
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    closeMenu: {
        position: "absolute",
        top: 2,
        right: 2,
    },
    arrowRight: {
        position: "relative",
        top: 2,
        width: "15px",
        height: "15px",
    },
    menuItemBorderBottom: { borderBottom: `1px solid ${theme.palette.divider}` },
    statsButton: {
        width: "calc(100% - 56px)",
        marginLeft: 28,
    },
    statsItem: {
        color: theme.palette.neutral.dark,
        ...theme.typography.body2,
        display: "flex",
        alignItems: "center",
        height: 40,
        padding: "8px 8px 8px 28px",
    },
    statsItemLabel: { minWidth: 70 },
    statsItemValue: {
        width: "100%",
        marginLeft: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    loader: {
        width: 0,
        margin: "16px auto",
    },
    statsDetail: {
        backgroundColor: theme.palette.background1,
        boxShadow: "inset 0px 2px 8px -3px rgba(0, 0, 0, 0.29)",
    },
    statsDetailItem: {
        ...theme.typography.body2,
        display: "flex",
        alignItems: "center",
        height: 40,
        padding: "0px 8px 0px 52px",
    },
    statsDetailPayOpItem: { padding: "0px 8px 0px 28px" },
    seeMoreBtn: {
        ...theme.typography.caption,
        color: theme.palette.neutral.main,
        cursor: "pointer",
        whiteSpace: "nowrap",
        outline: "none",
        "&:hover, &:focus": { color: theme.palette.primary.main },
    },
    statsDetailCurrency: {
        minWidth: 38,
        width: 38,
    },
    statsDetailGroupBy: {
        minWidth: 58,
        width: 58,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    statsTotal: {
        maxWidth: 120,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    statsTotalWithoutButton: { maxWidth: 190 },
    userDefaultCurrencyAmount: {
        ...theme.typography.caption,
        color: theme.palette.neutral.main,
        marginLeft: 12,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    groupByAmount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: 24,
        width: "100%",
        overflow: "hidden",
    },
    asc: { transform: "scale(1,-1)" },
    groupByOverflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    displayCurrencyField: { paddingLeft: 28 },
    sortActive: {
        color: theme.palette.primary.main,
        cursor: "inherit",
    },
}));

export const AmountColumnMenu = (props) => {
    let {
        anchorEl,
        attribute,
        fetchAmountStats,
        open,
        onClose,
        onSort,
        title,
        columnList,
        handleColumnsOrderUpdate,
        isDisplayRank,
        isDisplaySelector,
        sorted,
        sortDirection,
    } = props;

    const classes = useStylesAmountColumnMenu();
    const p = useP();

    const userSettings = useSelector((state) => state.app.settings.data);
    const module = useModule();

    const [selectedItem, setSelectedItem] = useState(null);
    const [statsData, setStatsData] = useState(null);

    const { data, isPending, error, run } = useAsync({ deferFn: fetchAmountStats });

    useEffect(() => {
        if (open === false) {
            setSelectedItem(null);
        }
    }, [open]);

    useEffect(() => {
        if (!isPending) {
            setStatsData(data);
        }
    }, [data, isPending]);

    useEffect(() => {
        setStatsData(null);
    }, [window.location.href, error]);

    const handleSort = (direction, e) => {
        onSort(direction);
        onClose(e);
    };

    const sortAsc = (e) => handleSort("asc", e);
    const sortDesc = (e) => handleSort("desc", e);
    const sortNone = (e) => handleSort(false, e);

    const handleClickStatsBtn = () => {
        run(attribute.id);
    };

    const toggleVisibleStats = (statsName) => () => {
        if (selectedItem === statsName) {
            setSelectedItem(null);
        } else {
            setSelectedItem(statsName);
        }
    };

    const handleKeyDown = (statsName) => (event) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            // Enter || Space
            toggleVisibleStats(statsName)();
        }
    };

    const column = useMemo(() => {
        return columnList.find((col) => col.colId === attribute.id);
    }, [attribute, columnList]);

    const toggleDisplayCurrency = useCallback(() => {
        handleColumnsOrderUpdate(
            columnList.map((col) => {
                if (col.colId === attribute.id) {
                    return {
                        ...col,
                        displayCurrency: !col.displayCurrency,
                    };
                }
                return col;
            }),
            isDisplayRank,
            isDisplaySelector
        );
    }, [attribute, columnList, handleColumnsOrderUpdate, isDisplayRank, isDisplaySelector]);

    const statsButton = isPending ? (
        <div className={classes.loader}>
            <HiLoader loading />
        </div>
    ) : (
        <HiButton
            title={p.t("common.search.table.header.stats.button.title")}
            className={classes.statsButton}
            variant="text"
            onClick={handleClickStatsBtn}
        >
            {p.t("common.search.table.header.stats.button.label")}
        </HiButton>
    );

    const details = useCallback(
        (aggName) => {
            if (statsData && statsData[statsData.groupByKey]) {
                return (
                    <div id={`stats-detail-${aggName}`} className={classes.statsDetail}>
                        {Object.keys(statsData[statsData.groupByKey]).map((groupBy) => {
                            let content = null;
                            if (statsData.groupByKey === "currencies") {
                                content = currencyDetail(aggName, groupBy);
                            } else if (statsData.groupByKey === "operation_types") {
                                content = operationTypeDetail(aggName, groupBy);
                            }
                            return content;
                        })}
                    </div>
                );
            }
            return null;
        },
        [statsData, userSettings]
    );

    const currencyDetail = useCallback(
        (aggName, groupBy) => {
            const groupByAmount = toUserLocalNumber(
                statsData[statsData.groupByKey][groupBy][aggName]["trx_currency"],
                userSettings.language,
                2
            );
            const userDefaultCurrencyAmount = toUserLocalNumber(
                statsData[statsData.groupByKey][groupBy][aggName]["user_currency"],
                userSettings.language,
                2,
                userSettings.currency
            );
            return (
                <div className={classes.statsDetailItem} key={groupBy}>
                    <div className={classes.statsDetailCurrency} title={groupBy}>
                        {groupBy}
                    </div>
                    <div className={classes.groupByAmount}>
                        <div title={groupByAmount}>{groupByAmount}</div>
                        <div
                            className={classes.userDefaultCurrencyAmount}
                            title={userDefaultCurrencyAmount}
                        >
                            {userDefaultCurrencyAmount}
                        </div>
                    </div>
                </div>
            );
        },
        [statsData, userSettings]
    );

    const operationTypeDetail = useCallback(
        (aggName, groupBy) => {
            const groupByAmount = toUserLocalNumber(
                statsData[statsData.groupByKey][groupBy][aggName],
                userSettings.language,
                2,
                userSettings.currency
            );
            return (
                <div
                    className={classNames(classes.statsDetailItem, classes.statsDetailPayOpItem)}
                    key={groupBy}
                >
                    <div
                        className={classes.statsDetailGroupBy}
                        title={p.t(`ref.${module.id}.operation_types.${groupBy}`)}
                    >
                        {p.t(`ref.${module.id}.operation_types.${groupBy}`)}
                    </div>
                    <div className={classes.groupByAmount}>
                        <div className={classes.groupByOverflow} title={groupByAmount}>
                            {groupByAmount}
                        </div>
                    </div>
                </div>
            );
        },
        [statsData, userSettings]
    );

    const showSeeMoreBtn =
        statsData &&
        statsData[statsData.groupByKey] &&
        Object.keys(statsData[statsData.groupByKey]).length > 1;

    return (
        <Popover
            id="column-filter-menu"
            anchorEl={anchorEl}
            open={open}
            classes={{ paper: classes.menu }}
            onClose={onClose}
            disableEnforceFocus
        >
            <div className={classes.menuTitle}>
                <span>{title}</span>
                <HiIconButton className={classes.closeMenu} onClick={onClose}>
                    <Close />
                </HiIconButton>
            </div>

            {attribute.displayOptions.sortable !== false && (
                <div>
                    <MenuItem
                        key={"sort-none"}
                        classes={{ root: classes.menuItemBorderBottom }}
                        onClick={sortNone}
                        tabIndex={0}
                    >
                        <Menu classes={{ root: classes.menuItemIcon }} />
                        <span>{p.t("common.search.table.default_sort")}</span>
                    </MenuItem>
                    <MenuItem
                        tabIndex={0}
                        key={"sort-asc"}
                        onClick={(!sorted || sortDirection !== "asc") && sortAsc}
                        title={p.t("common.search.table.sort_indication_amount", {
                            currencyIsoCode: userSettings.currency,
                        })}
                        classes={{
                            root: classNames({
                                [classes.sortActive]: sorted && sortDirection === "asc",
                            }),
                        }}
                    >
                        <FilterList
                            className={classes.asc}
                            classes={{ root: classes.menuItemIcon }}
                        />
                        <span>
                            {p.t("common.search.table.sort_from")} 0{" "}
                            <ArrowRight className={classes.arrowRight} /> 9
                        </span>
                    </MenuItem>
                    <MenuItem
                        key={"sort-desc"}
                        onClick={(!sorted || sortDirection !== "desc") && sortDesc}
                        classes={{
                            root: classNames(classes.menuItemBorderBottom, {
                                [classes.sortActive]: sorted && sortDirection === "desc",
                            }),
                        }}
                        title={p.t("common.search.table.sort_indication_amount", {
                            currencyIsoCode: userSettings.currency,
                        })}
                        tabIndex={0}
                    >
                        <FilterList classes={{ root: classes.menuItemIcon }} />
                        <span>
                            {p.t("common.search.table.sort_from")} 9{" "}
                            <ArrowRight className={classes.arrowRight} /> 0
                        </span>
                    </MenuItem>
                </div>
            )}

            <div className={classes.menuItemFilter}>
                <span>{p.t("common.search.table.header.stats.display.title")}</span>
            </div>
            <div className={classes.displayCurrencyField}>
                <FormControlLabel
                    control={
                        <HiSwitch
                            checked={column.displayCurrency || false}
                            color="primary"
                            onChange={toggleDisplayCurrency}
                        />
                    }
                    label={
                        column.displayCurrency
                            ? p.t("common.search.table.header.stats.display.with_currency")
                            : p.t("common.search.table.header.stats.display.without_currency")
                    }
                />
            </div>

            {attribute.displayOptions.amountStats === true && (
                <>
                    <div className={classes.menuItemFilter}>
                        <span>{p.t("common.search.table.header.stats.title")}</span>
                    </div>

                    {!statsData ? (
                        statsButton
                    ) : (
                        <>
                            <div id={"stats-number"} className={classes.statsItem}>
                                <div className={classes.statsItemLabel}>
                                    {p.t("common.search.table.header.stats.number")}
                                </div>
                                <div className={classes.statsItemValue}>
                                    <HiChip
                                        label={toUserLocalNumber(
                                            statsData.number,
                                            userSettings.language,
                                            0
                                        )}
                                    />
                                    {showSeeMoreBtn && (
                                        <div
                                            tabIndex={0}
                                            className={classes.seeMoreBtn}
                                            onKeyDown={handleKeyDown("number")}
                                            onClick={toggleVisibleStats("number")}
                                        >
                                            {selectedItem === "number"
                                                ? p.t("button.see_less")
                                                : p.t("button.see_more")}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {selectedItem === "number" && statsData[statsData.groupByKey] && (
                                <div id={"stats-detail-number"} className={classes.statsDetail}>
                                    {Object.keys(statsData[statsData.groupByKey]).map((groupBy) => {
                                        return (
                                            <div
                                                key={groupBy}
                                                className={classNames(classes.statsDetailItem, {
                                                    [classes.statsDetailPayOpItem]:
                                                        statsData.groupByKey === "operation_types",
                                                })}
                                            >
                                                <div
                                                    className={classNames({
                                                        [classes.statsDetailCurrency]:
                                                            statsData.groupByKey === "currencies",
                                                        [classes.statsDetailGroupBy]:
                                                            statsData.groupByKey !== "currencies",
                                                    })}
                                                >
                                                    {statsData.groupByKey === "currencies"
                                                        ? groupBy
                                                        : p.t(
                                                              `ref.${module.id}.${statsData.groupByKey}.${groupBy}`
                                                          )}
                                                </div>
                                                <div className={classes.groupByAmount}>
                                                    {toUserLocalNumber(
                                                        statsData[statsData.groupByKey][groupBy][
                                                            "number"
                                                        ],
                                                        userSettings.language,
                                                        0
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <div id={"stats-sum"} className={classes.statsItem}>
                                <div className={classes.statsItemLabel}>
                                    {p.t("common.search.table.header.stats.sum")}
                                </div>
                                <div className={classes.statsItemValue}>
                                    <span
                                        className={classNames(classes.statsTotal, {
                                            [classes.statsTotalWithoutButton]: !showSeeMoreBtn,
                                        })}
                                        title={toUserLocalNumber(
                                            statsData.sum,
                                            userSettings.language,
                                            2,
                                            userSettings.currency
                                        )}
                                    >
                                        {toUserLocalNumber(
                                            statsData.sum,
                                            userSettings.language,
                                            2,
                                            userSettings.currency
                                        )}
                                    </span>
                                    {showSeeMoreBtn && (
                                        <div
                                            tabIndex={0}
                                            className={classes.seeMoreBtn}
                                            onKeyDown={handleKeyDown("sum")}
                                            onClick={toggleVisibleStats("sum")}
                                        >
                                            {selectedItem === "sum"
                                                ? p.t("button.see_less")
                                                : p.t("button.see_more")}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {selectedItem === "sum" && details("sum")}
                            <div id={"stats-average"} className={classes.statsItem}>
                                <div className={classes.statsItemLabel}>
                                    {p.t("common.search.table.header.stats.average")}
                                </div>
                                <div className={classes.statsItemValue}>
                                    <span
                                        className={classNames(classes.statsTotal, {
                                            [classes.statsTotalWithoutButton]: !showSeeMoreBtn,
                                        })}
                                        title={toUserLocalNumber(
                                            statsData.average,
                                            userSettings.language,
                                            2,
                                            userSettings.currency
                                        )}
                                    >
                                        {toUserLocalNumber(
                                            statsData.average,
                                            userSettings.language,
                                            2,
                                            userSettings.currency
                                        )}
                                    </span>
                                    {showSeeMoreBtn && (
                                        <div
                                            tabIndex={0}
                                            className={classes.seeMoreBtn}
                                            onKeyDown={handleKeyDown("average")}
                                            onClick={toggleVisibleStats("average")}
                                        >
                                            {selectedItem === "average"
                                                ? p.t("button.see_less")
                                                : p.t("button.see_more")}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {selectedItem === "average" && details("average")}
                            <div id={"stats-minimum"} className={classes.statsItem}>
                                <div className={classes.statsItemLabel}>
                                    {p.t("common.search.table.header.stats.minimum")}
                                </div>
                                <div className={classes.statsItemValue}>
                                    <span>
                                        {toUserLocalNumber(
                                            statsData.min,
                                            userSettings.language,
                                            2,
                                            userSettings.currency
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div id={"stats-maximum"} className={classes.statsItem}>
                                <div className={classes.statsItemLabel}>
                                    {p.t("common.search.table.header.stats.maximum")}
                                </div>
                                <div className={classes.statsItemValue}>
                                    <span>
                                        {toUserLocalNumber(
                                            statsData.max,
                                            userSettings.language,
                                            2,
                                            userSettings.currency
                                        )}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </Popover>
    );
};

AmountColumnMenu.propTypes = {
    /**
     * Attribut associé à la colonne
     */
    attribute: PropTypes.object,
    /**
     * Élément de référence sur lequelle positionner le menu
     */
    anchorEl: PropTypes.object,
    /**
     * Liste des colonnes affichées dans le tableau des résultats de recherche.
     */
    columnList: PropTypes.array,
    /**
     * Fonction asynchrone permettant de récupérer les stats d'une colonne numerique de type montant
     */
    fetchAmountStats: PropTypes.func,
    /**
     * Fonction de màj de la configuration des colonnes (sans sauvegarde en BDD).
     */
    handleColumnsOrderUpdate: PropTypes.func,
    /**
     * True si la colonne Rank est affichée.
     */
    isDisplayRank: PropTypes.bool,
    /**
     * True si la colonne Selector est affichée.
     */
    isDisplaySelector: PropTypes.bool,
    /**
     * Fonction de callback pour la fermeture du menu
     */
    onClose: PropTypes.func.isRequired,
    /**
     * Fonction de callback au tri - passe la direction du tri
     */
    onSort: PropTypes.func,
    /**
     * Affiche / Cache l'élément
     */
    open: PropTypes.bool.isRequired,
    /**
     * Sens du tri
     */
    sortDirection: PropTypes.oneOf(["asc", "desc"]),
    /**
     * Tri actif / inactif
     */
    sorted: PropTypes.bool,
};
