import { useSelector } from "react-redux";

import { useInheritContext } from "../ContextForwarder";
import { ConfigContext } from "../config/config-context";
import { useCan } from "../ability";

export const useCheckModuleAccess = () => {
    const { getConfigByModule } = useInheritContext("config", ConfigContext);
    const can = useCan();
    // eslint-disable-next-line
    // @ts-ignore
    const featureFlags = useSelector((state) => state.app.features);

    const checkModuleAccess = (
        moduleId: string,
        noConfigAnswer = false,
        checkAclKey = false
    ): boolean => {
        const moduleConfig = getConfigByModule(moduleId);

        // If module config not found, return noConfigAnswer boolean
        // No access to module by default
        // We use true for Routes not linked to a module
        if (!moduleConfig) {
            return noConfigAnswer;
        }
        // For routing, accept route where moduleConfig exists and no ACL key
        // Ex with login when disconnected
        if (checkAclKey && !moduleConfig.acl) {
            return noConfigAnswer;
        }

        // If item has "internal" option & we are not in Console Internal, hide item
        if (
            moduleConfig.internal &&
            moduleConfig.internal !== (process.env["NX_IS_INTERNAL"] === "true")
        ) {
            return false;
        }

        if (
            moduleConfig.featureFlags?.length &&
            !featureFlags.includes(moduleConfig.featureFlags)
        ) {
            return false;
        }

        // Check logged user has access to module
        return can("manage-module", moduleId);
    };

    return { checkModuleAccess };
};
