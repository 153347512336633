import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useP } from "../../../../../services/i18n";
import { useModule } from "../../../../../services/module";
import { useModuleConfig } from "../../../../../services/config";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import { useOktaAuth } from "@okta/okta-react";
import { useUser } from "../../../../../services/user";
import { useInnerPage } from "../../../../../services/innerPage";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DownloadIcon from "mdi-material-ui/Download";
import CalendarClockIcon from "mdi-material-ui/CalendarClock";
import { ExportByEmailIcon } from "./ExportByEmailIcon";
import { RecurringExportIcon } from "./RecurringExportIcon";
import HiPin from "@hipay/hipay-material-ui/HiPin";

import { ExportForm } from "../Form";
import { ExportList } from "../List";
import { LiveExport } from "../LiveExport";
import { SendToEmail } from "../SendToEmail";
import { FEATURE_SEARCH_SELECTION } from "../../../../constants/features";
import { useCan } from "../../../../../services/ability";
import { toUserLocalNumber } from "../../../../../utils/i18n";
import { setEventTracker } from "../../../../../services/tracker";

const useStylesMenu = makeStyles((theme) => ({
    root: { display: "inline-block" },
    item: {
        "&:hover, &:focus": {
            backgroundColor: theme.palette.action.hover,
            outline: "none",
        },
        lineHeight: "1",
        minHeight: 56,
        pointerEvents: "auto !important", // allow title on disabled
    },
    itemRoot: { margin: 0 },
    info: {
        fontWeight: "300",
        fontSize: 12,
    },
    icon: { "&primary": { color: theme.palette.primary.main } },
    recurringExportPin: {
        minWidth: 15,
        padding: "0px 5px",
        position: "absolute",
        left: 24,
        top: 25,
    },
}));

export function ExportMenu({
    orderedColumns,
    searchParams,
    totalHits,
    selectionParam,
    mergeSelectionParam,
    formComponent,
    onClickLive,
    onClickEmail,
    labels,
}) {
    const p = useP();
    const classes = useStylesMenu();
    const module = useModule();
    const moduleConfig = useModuleConfig(module.id);

    const { authState } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;
    const user = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const can = useCan();
    const exportList = useSelector((state) => state.app.global.entities.export);
    const userSettings = useSelector((state) => state.app.settings.data);

    const { openInnerPage } = useInnerPage();

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        // If isset export id in hash on mount => open export item into inner page
        if (window.location.hash !== "") {
            const hash = window.location.hash.substr(1);
            if (hash.split("=")[0] === "exportId") {
                openInnerPage(
                    <ExportList onCreateExport={openForm(true)} />,
                    {
                        fromTopBar: false,
                        titleKey: "common.search.export.list.title",
                    },
                    "ExportList"
                );
            }
        }
    }, []);

    const handleClick = (event) => {
        setEventTracker("click_export_management", {
            event_category: "exports_transaction",
            event_action: "exports_transaction_click_export_management",
        });
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const limitLiveExport = useMemo(() => {
        return moduleConfig?.export?.limitLiveExport || process.env.NX_LIMIT_SYNCHRONOUS_EXPORT;
    }, [moduleConfig]);

    const limitEmailExport = useMemo(() => {
        return moduleConfig?.export?.limitEmailExport || process.env.NX_LIMIT_EXPORT;
    }, [moduleConfig]);

    const limitBigExport = useMemo(() => {
        return moduleConfig?.export?.limitBigExport || null;
    }, [moduleConfig]);

    const canSelectInSearch = useMemo(() => {
        return module && module.features.includes(FEATURE_SEARCH_SELECTION);
    }, [module]);

    // build export filters
    const filters = useMemo(() => {
        if (canSelectInSearch) {
            if (mergeSelectionParam && typeof mergeSelectionParam === "function") {
                return mergeSelectionParam(searchParams, selectionParam);
            }
            console.warn(
                "Props mergeSelectionParam is required as a function to handle selection in exports"
            );
        }
        return searchParams;
    }, [searchParams, selectionParam, canSelectInSearch]);

    // build export columns
    const columns = useMemo(() => {
        if (typeof moduleConfig.exportColumnsFormatter === "function") {
            return moduleConfig.exportColumnsFormatter(orderedColumns, p);
        }
        let _columns = {};
        orderedColumns.forEach((column) => {
            if (column.displayed === true) {
                _columns[column.colId] = {
                    label: p.t(`attributes.${module.id}.${column.colId}.tableLabel`),
                };
                if (column.hasOwnProperty("displayCurrency")) {
                    _columns[column.colId].displayCurrency = column.displayCurrency;
                } else if (column.hasOwnProperty("withFormating")) {
                    _columns[column.colId].withFormating = column.withFormating;
                } else if (column.hasOwnProperty("displayTime")) {
                    _columns[column.colId].displayTime = column.displayTime;
                }
            }
        });
        return _columns;
    }, [orderedColumns, moduleConfig, p]);

    /*
     * Live export
     * open live export snackbar
     */
    const liveExport = useCallback(() => {
        handleClose();

        setEventTracker("click_export_management", {
            event_category: "exports_transaction",
            event_action: "exports_transaction_create_direct_exports",
        });

        if (typeof onClickLive === "function") {
            onClickLive();
        } else {
            enqueueSnackbar("", {
                persist: true,
                content: (key) => (
                    <LiveExport
                        p={p}
                        token={token}
                        moduleConfig={moduleConfig}
                        snackbarId={key}
                        module={module.id}
                        columns={columns}
                        filters={filters}
                        totalHits={totalHits}
                    />
                ),
            });
        }
    }, [columns, filters, totalHits, onClickLive]);

    /*
     * Send export to email
     * persist export
     */
    const sendToEmail = useCallback(
        (itemKey = null) => {
            handleClose();

            setEventTracker("click_export_management", {
                event_category: "exports_transaction",
                event_label: itemKey ? "big_volume_exports" : "email_exports",
                event_action:
                    "exports_transaction_create_" +
                    (itemKey ? "big_volume_exports" : "email_exports"),
            });

            if (typeof onClickEmail === "function") {
                onClickEmail();
            } else {
                enqueueSnackbar("", {
                    persist: true,
                    content: (key) => (
                        <SendToEmail
                            p={p}
                            token={token}
                            user={user}
                            moduleConfig={moduleConfig}
                            snackbarId={key}
                            module={module.id}
                            columns={columns}
                            filters={filters}
                            totalHits={totalHits}
                            redOnError={true}
                        />
                    ),
                });
            }
        },
        [columns, filters, totalHits, onClickEmail]
    );

    /** Go back to export list from export item */
    const goBack = useCallback(() => {
        handleClose();

        openInnerPage(
            <ExportList onCreateExport={openForm(true)} totalHits={totalHits} />,
            {
                fromTopBar: false,
                titleKey: "common.search.export.list.title",
            },
            "ExportList"
        );
    }, []);

    /*
     * Open recurring export form
     */
    const openForm = useCallback(
        (withGoBack = false) =>
            () => {
                setEventTracker("click_export_management", {
                    event_category: "exports_transaction",
                    event_action: "exports_transaction_create_recurring_exports",
                });

                handleClose();

                let form = <ExportForm columns={columns} filters={filters} totalHits={totalHits} />;
                if (typeof formComponent !== "undefined") {
                    form = { ...formComponent };
                    form.props = {
                        columns,
                        filters,
                        totalHits,
                    };
                }

                openInnerPage(
                    form,
                    {
                        fromTopBar: false,
                        titleKey: "common.search.export.form.title",
                        goBack: withGoBack ? goBack : undefined,
                    },
                    "ExportForm"
                );
            },
        [columns, filters, totalHits]
    );

    /*
     * Open recurring export list
     */
    const openList = useCallback(() => {
        setEventTracker("click_export_management", {
            event_category: "exports_transaction",
            event_action: "exports_transaction_view_recurring_exports",
        });

        handleClose();

        openInnerPage(
            <ExportList
                formComponent={formComponent}
                onCreateExport={openForm(true)}
                totalHits={totalHits}
            />,
            {
                fromTopBar: false,
                titleKey: "common.search.export.list.title",
            },
            "ExportList"
        );
    }, [columns, filters, totalHits]);

    // Build menu item list
    const menuList = useMemo(() => {
        let list = [];
        const canEmailExport = totalHits && totalHits < limitEmailExport;
        const canRecurringExport = totalHits < limitEmailExport;

        // Live export
        let liveExportDisabled = !totalHits || totalHits > limitLiveExport;
        const maxExportInfoKey = labels?.infoKey || "common.search.export.menu.max_results_info";
        const maxResultsDisabledTitleKey =
            labels?.titleDisabledKey || "common.search.export.menu.max_results_disabled";

        list.push({
            name: "live_export",
            onClick: liveExport,
            disabled: liveExportDisabled,
            icon: <DownloadIcon />,
            info: p.t(maxExportInfoKey, {
                limit: toUserLocalNumber(limitLiveExport, userSettings.language),
            }),
            label: p.t("common.search.export.menu.live_export.label"),
            title: liveExportDisabled
                ? p.t(maxResultsDisabledTitleKey, {
                      limit: toUserLocalNumber(limitLiveExport, userSettings.language),
                  })
                : p.t("common.search.export.menu.live_export.title"),
        });

        // Send export
        list.push({
            name: "send_export",
            onClick: () => sendToEmail(),
            disabled: !canEmailExport,
            icon: <ExportByEmailIcon />,
            info: p.t(maxExportInfoKey, {
                limit: toUserLocalNumber(limitEmailExport, userSettings.language),
            }),
            label: p.t("common.search.export.menu.send_export.label"),
            title: !canEmailExport
                ? p.t(maxResultsDisabledTitleKey, {
                      limit: toUserLocalNumber(limitEmailExport, userSettings.language),
                  })
                : p.t("common.search.export.menu.send_export.title"),
        });

        // big export
        const bigExportAcl = moduleConfig?.acl?.bigExport;
        let canBigExport =
            !bigExportAcl ||
            can(bigExportAcl.action, `${bigExportAcl.module}/${bigExportAcl.controller}`);
        const disableBigExport =
            !canBigExport ||
            !totalHits ||
            totalHits <= limitEmailExport ||
            (limitBigExport !== null && totalHits > limitBigExport);

        let bigExportInfo = p.t("common.search.export.menu.big_export.info");
        let bigExportTitle = p.t("common.search.export.menu.big_export.title");
        if (!canBigExport) {
            bigExportInfo = p.t("common.search.export.menu.big_export.info_no_acl");
            bigExportTitle = p.t("common.search.export.menu.big_export.title_disabled");
        } else if (totalHits <= limitEmailExport) {
            bigExportInfo =
                labels?.big_info ||
                p.t("common.search.export.menu.big_export.info_not_any_results", {
                    limit: toUserLocalNumber(+limitEmailExport + 1, userSettings.language),
                });
            if (limitBigExport !== null) {
                bigExportTitle = p.t("common.search.export.menu.big_export.title_limited");
            }
        } else if (limitBigExport !== null) {
            if (totalHits > limitBigExport) {
                bigExportInfo = p.t(maxExportInfoKey, {
                    limit: toUserLocalNumber(limitBigExport, userSettings.language),
                });
                bigExportTitle = p.t(maxResultsDisabledTitleKey, {
                    limit: toUserLocalNumber(limitBigExport, userSettings.language),
                });
            } else {
                bigExportInfo = p.t(maxExportInfoKey, {
                    limit: toUserLocalNumber(limitBigExport, userSettings.language),
                });
                bigExportTitle = p.t("common.search.export.menu.big_export.title_limited");
            }
        }

        list.push({
            name: "big_export",
            onClick: () => sendToEmail("big_export"),
            disabled: disableBigExport,
            icon: <ExportByEmailIcon />,
            info: bigExportInfo,
            label: p.t("common.search.export.menu.big_export.label"),
            title: bigExportTitle,
        });

        // Create recurring export
        list.push({
            name: "create_recurring_export",
            onClick: openForm(),
            disabled: !canRecurringExport,
            icon: <RecurringExportIcon />,
            info: p.t(maxExportInfoKey, {
                limit: toUserLocalNumber(limitEmailExport, userSettings.language),
            }),
            label: p.t("common.search.export.menu.create_recurring_export.label"),
            title: !canRecurringExport
                ? p.t(maxResultsDisabledTitleKey, {
                      limit: toUserLocalNumber(limitEmailExport, userSettings.language),
                  })
                : p.t("common.search.export.menu.create_recurring_export.title"),
        });

        // Export list
        list.push({
            name: "export_list",
            onClick: openList,
            icon: (
                <React.Fragment>
                    <CalendarClockIcon />
                    {!!exportList.data && !!exportList?.data.length && (
                        <HiPin color="neutral" className={classes.recurringExportPin}>
                            {exportList?.data.length}
                        </HiPin>
                    )}
                </React.Fragment>
            ),
            label: p.t("common.search.export.menu.export_list.label"),
            info:
                exportList && exportList?.data?.length
                    ? p.t("common.search.export.menu.export_list.nb_items", {
                          smart_count: exportList?.data?.length,
                      })
                    : p.t("common.search.export.menu.export_list.no_items"),
            title:
                !exportList || exportList?.data?.length === 0
                    ? p.t("common.search.export.menu.export_list.title_disabled")
                    : p.t("common.search.export.menu.export_list.title"),
        });

        return list;
    }, [totalHits, exportList, columns]);

    const disableMenuBtn = useMemo(() => {
        return user.internalstaff && process.env.NX_IS_INTERNAL !== "true";
    }, [user]);

    return (
        <div className={classes.root}>
            <IconButton
                id="export-menu-button"
                aria-controls="export-menu-buttoncreateReccuringExport"
                aria-haspopup="true"
                onClick={handleClick}
                title={p.t("common.search.buttons.export_menu")}
                className={classes.icon}
                disabled={disableMenuBtn}
                size="large"
            >
                <DownloadIcon />
            </IconButton>
            <Menu
                id="export-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ dense: true }}
            >
                {menuList.map(({ name, onClick, disabled, icon, info, label, title }) => (
                    <MenuItem
                        key={`export-menu-item-${name}`}
                        id={`export-menu-item-${name}`}
                        className={classes.item}
                        disabled={!!disabled}
                        onClick={!disabled ? onClick : null}
                        dense
                        title={title}
                    >
                        {icon && <ListItemIcon sx={{ color: "neutral.main" }}>{icon}</ListItemIcon>}
                        <ListItemText
                            primary={label}
                            secondary={info}
                            classes={{
                                root: classes.itemRoot,
                                secondary: classes.info,
                            }}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
