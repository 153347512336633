const threeDsV2Integration = {
    id: "three_dsv2_integration",
    data: [
        {
            id: "NOT_APPLICABLE",
            label: "ref.transaction.three_dsv2_integration.not_applicable",
        },
        {
            id: "COMPLIANT",
            label: "ref.transaction.three_dsv2_integration.compliant",
        },
        {
            id: "DEFAULT",
            label: "ref.transaction.three_dsv2_integration.default",
        },
    ],
    translate: true,
};

export default threeDsV2Integration;
