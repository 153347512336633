import withStyles from "@mui/styles/withStyles";

import KpiTemplate from "./KpiTemplate";

const styles = (theme) => ({
    content: {
        width: "100%",
        height: "100%",
        ...theme.typography.h5,
        lineHeight: "1.05em",
    },
    value: {
        color: "#FFF",
        width: "100%",
    },
});

const KpiListClass = (props) => {
    const { classes, label, values, onClick = null } = props;

    const fontSize = Math.round((1 - 0.06 * (values.length - 1)) * 10) / 10;

    return (
        <KpiTemplate header={label} onClick={onClick}>
            <div className={classes.content}>
                {values.map((value, index) => (
                    <div
                        className={classes.value}
                        style={{ fontSize: `${fontSize}em` }}
                        key={index}
                    >
                        {value}
                    </div>
                ))}
            </div>
        </KpiTemplate>
    );
};

export const KpiList = withStyles(styles)(KpiListClass);
