const sentinelRulesFamily = {
    id: "sentinel_rules_family",
    data: [
        {
            id: "activity",
            label: "ref.transaction.sentinel_rules_family.activity",
        },
        {
            id: "blacklist",
            label: "ref.transaction.sentinel_rules_family.blacklist",
        },
        {
            id: "chargeback_data",
            label: "ref.transaction.sentinel_rules_family.chargeback_data",
        },
        {
            id: "coherence",
            label: "ref.transaction.sentinel_rules_family.coherence",
        },
        {
            id: "combo",
            label: "ref.transaction.sentinel_rules_family.combo",
        },
        {
            id: "custom-data",
            label: "ref.transaction.sentinel_rules_family.custom-data",
        },
        {
            id: "greylist",
            label: "ref.transaction.sentinel_rules_family.greylist",
        },
        {
            id: "iovation",
            label: "ref.transaction.sentinel_rules_family.iovation",
        },
        {
            id: "payment-data",
            label: "ref.transaction.sentinel_rules_family.payment-data",
        },
        {
            id: "score",
            label: "ref.transaction.sentinel_rules_family.score",
        },
        {
            id: "spending-limit",
            label: "ref.transaction.sentinel_rules_family.spending-limit",
        },
        {
            id: "time",
            label: "ref.transaction.sentinel_rules_family.time",
        },
        {
            id: "velocity",
            label: "ref.transaction.sentinel_rules_family.velocity",
        },
        {
            id: "whitelist",
            label: "ref.transaction.sentinel_rules_family.whitelist",
        },
    ],
    translate: true,
};

export default sentinelRulesFamily;
