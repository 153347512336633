export default {
    // predefined static options
    id: "financial_operation_modes",
    data: [
        {
            id: "COLLECTING",
            label: "ref.financial_report.financial_operation_modes.COLLECTING",
        },
        {
            id: "GATEWAY",
            label: "ref.financial_report.financial_operation_modes.GATEWAY",
        },
    ],
    translate: true,
};
