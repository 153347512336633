import moment from "moment-timezone";
import { capitalize } from "@mui/material/utils";
import { CMP_PERIOD_PREVIOUS_PERIOD, CMP_PERIOD_PREVIOUS_YEAR } from "../common/constants/periods";
import { parseUrlToParams } from "./urls";

/**
 * Return dateFrom & dateTo formatted properly with locale
 * & dates from previous period
 *
 * @param interval (custom, cd, pd, cm, pm...)
 * @param dateFrom
 * @param dateTo
 * @param navigate
 * @param p (polyglot)
 * @returns {{dateFromTitle: string, dateToTitle: string, previousDateFromTitle: string, previousDateToTitle: string}}
 */
export function formatPeriod(
    interval,
    dateFrom,
    dateTo,
    navigate,
    p,
    comparativePeriod = CMP_PERIOD_PREVIOUS_PERIOD
) {
    dateFrom = moment(dateFrom);
    dateTo = moment(dateTo);
    let dateFromTitle = "";
    let dateToTitle = "";
    let previousDateFromTitle = "";
    let previousDateToTitle = "";

    if (dateFrom.get("year") === dateTo.get("year")) {
        if (dateFrom.get("month") === dateTo.get("month")) {
            dateFromTitle = dateFrom.format("D");
        } else {
            dateFromTitle = dateFrom.format("D MMMM");
        }
        dateToTitle = dateTo.format("D MMMM YYYY");
    } else {
        dateFromTitle = dateFrom.format("D MMMM YYYY");
        dateToTitle = dateTo.format("D MMMM YYYY");
    }
    let daysInterval = dateTo.diff(dateFrom, "days");

    let previousDateTo, previousDateFrom;
    if (comparativePeriod === CMP_PERIOD_PREVIOUS_YEAR) {
        previousDateFrom = moment(dateFrom).subtract(1, "years");
        previousDateTo = moment(dateTo).subtract(1, "years");
    } else if (interval === "custom") {
        previousDateTo = moment(dateFrom).subtract(1, "days");
        previousDateFrom = moment(previousDateTo).subtract(daysInterval, "days");
    } else {
        const dates = getPreviousDatesFromInterval(interval, dateFrom, dateTo);
        previousDateFrom = dates.previousDateFrom;
        previousDateTo = dates.previousDateTo;
    }

    if (previousDateFrom.get("year") === previousDateTo.get("year")) {
        if (previousDateFrom.get("month") === previousDateTo.get("month")) {
            previousDateFromTitle = previousDateFrom.format("D");
        } else {
            previousDateFromTitle = previousDateFrom.format("D MMMM");
        }
        previousDateToTitle = previousDateTo.format("D MMMM YYYY");
    } else {
        previousDateFromTitle = previousDateFrom.format("D MMMM YYYY");
        previousDateToTitle = previousDateTo.format("D MMMM YYYY");
    }

    let dateDisplayed = p.t("period_interval", {
        date_from: dateFromTitle,
        date_to: dateToTitle,
    });
    if (daysInterval === 0) {
        dateDisplayed = dateFrom.format("D MMMM YYYY");
    }
    if (interval !== "custom" && !navigate) {
        if (p.phrases[`form.fields.date_range_selector.${interval}`]) {
            if (interval === "cw" || interval === "cq" || interval === "pw" || interval === "pq") {
                dateDisplayed =
                    p.t(`modules.dashboard.period.${interval}`, {
                        week: dateTo.isoWeek(),
                        quarter: dateTo.quarter(),
                    }) +
                    ", " +
                    dateDisplayed;
            } else {
                dateDisplayed =
                    p.t(`form.fields.date_range_selector.${interval}`) + ", " + dateDisplayed;
            }
        }
    } else {
        dateDisplayed = dateDisplayed.charAt(0).toUpperCase() + dateDisplayed.substr(1);
    }

    return {
        dateDisplayed,
        dateFromTitle,
        dateToTitle,
        previousDateFromTitle,
        previousDateToTitle,
        daysInterval,
        previousDateTo,
        previousDateFrom,
        dateTo,
        dateFrom,
    };
}

/**
 * Returns dates from & to according
 *
 * @param interval
 * @param today
 * @returns {{from: *, to: *}}
 */
export function getDatesFromInterval(interval, today = moment()) {
    let from;
    let to;
    switch (interval) {
        case "cd":
            from = moment(today).startOf("day");
            to = moment(today).endOf("day");
            break;
        case "pd":
            from = moment(today).subtract(1, "days");
            to = moment(today).subtract(1, "days").endOf("day");
            break;
        case "cw":
            from = moment(today).startOf("week");
            to = moment(today);
            break;
        case "pw":
            from = moment(today).startOf("week").subtract(1, "week");
            to = moment(today).startOf("week").subtract(1, "day");
            break;
        case "cm":
            from = moment(today).startOf("month");
            to = moment(today);
            break;
        case "pm":
            from = moment(today).startOf("month").subtract(1, "month");
            to = moment(today).startOf("month").subtract(1, "day");
            break;
        case "cq":
            from = moment(today).startOf("quarter");
            to = moment(today);
            break;
        case "pq":
            from = moment(today).startOf("quarter").subtract(1, "quarter");
            to = moment(today).startOf("quarter").subtract(1, "day");
            break;
        case "cy":
            from = moment(today).startOf("year");
            to = moment(today);
            break;
        case "yy":
            from = moment(today).subtract(1, "year");
            to = moment(today);
            break;
        default:
            if (parseInt(interval) > 0) {
                from = moment(today).subtract(interval, "day");
                to = moment(today);
            }
            break;
    }
    return {
        from,
        to,
    };
}

/**
 * Calculate & returns next period dates
 *
 * @param period
 * @param dateFromCurrent
 * @param dateToCurrent
 * @returns {{dateFrom: moment.Moment, dateTo: moment.Moment}}
 */
export function getDatesNextPeriod(interval, dateFromCurrent, dateToCurrent) {
    dateFromCurrent = moment(dateFromCurrent);
    dateToCurrent = moment(dateToCurrent);

    let dateFrom = moment(dateFromCurrent).set("hours", 0);
    let dateTo = moment(dateToCurrent).set("hours", 0);
    let newDateTo = null;
    let newDateFrom = null;
    if (interval === "custom") {
        //add one day to dateTo to get next period dateFrom
        newDateFrom = dateTo.add(1, "days");
        let daysInterval = moment(dateToCurrent).set("hours", 0).diff(dateFrom, "days");
        // calculate new date to
        newDateTo = moment(newDateFrom).add(daysInterval, "days");
    } else {
        const dates = getNextDatesFromInterval(interval, dateFromCurrent, dateToCurrent);
        newDateFrom = dates.nextDateFrom;
        newDateTo = dates.nextDateTo;
    }

    return {
        dateFrom: newDateFrom,
        dateTo: newDateTo,
    };
}

/**
 * Calculate & returns previous period dates
 *
 * @param period
 * @param dateFromCurrent
 * @param dateToCurrent
 * @returns {{dateFrom: moment.Moment, dateTo: moment.Moment}}
 */
export function getDatesPreviousPeriod(period, dateFromCurrent, dateToCurrent) {
    dateFromCurrent = moment(dateFromCurrent);
    dateToCurrent = moment(dateToCurrent);

    let newDateTo = null;
    let newDateFrom = null;
    if (period === "custom") {
        let dateFrom = moment(dateFromCurrent).set("hours", 0);
        //remove one day to dateFrom to get previous period dateTo
        newDateTo = dateFrom.subtract(1, "days");
        let daysInterval = moment(dateToCurrent).diff(moment(dateFromCurrent), "days");
        //calculate new date from
        newDateFrom = moment(newDateTo).subtract(daysInterval, "days");
    } else {
        const dates = getPreviousDatesFromInterval(period, dateFromCurrent, dateToCurrent);
        newDateFrom = dates.previousDateFrom;
        newDateTo = dates.previousDateTo;
    }

    return {
        dateFrom: moment(newDateFrom),
        dateTo: moment(newDateTo),
    };
}

/**
 * Return day label from date (relative to current date)
 * will format a date with different strings depending on how close from today
 * ex: (current date: 7.11.17) date: 7.10.17 -> label: 'Yesterday'
 * ex: (current date: 7.11.17) date: 23.6.17 -> label: 'June 23'
 * ex: (current date: 7.11.17) date: 15.12.16 -> label: 'December 15, 2016'
 *
 * @param polyglot
 * @param date
 * @returns {*}
 */
export function getDayLabelFromRelativeDate(polyglot, date) {
    const today = moment().startOf("day");
    const d = moment(date);

    if (today.diff(d, "days") === 0) {
        return polyglot.t("moment.today");
    } else if (today.diff(d, "days") === 1) {
        return polyglot.t("moment.yesterday");
    } else if (today.diff(d, "years") === 0) {
        switch (moment.locale()) {
            case "fr":
                return d.format("D MMMM");
            default:
                return d.format("MMMM D");
        }
    } else {
        return d.format("LL");
    }
}

/**
 * Period as string for filters (dashboard and search)
 *
 * @param p
 * @param dateFormatShort
 * @param dateFrom
 * @param dateTo
 * @param interval
 * @param navigate
 * @returns {*}
 */
export const getFormattedPeriod = (
    p,
    dateFormatShort,
    dateFrom,
    dateTo,
    interval = "",
    navigate = false
) => {
    if (dateFrom && dateTo) {
        if (interval === "" || interval === "custom" || navigate) {
            let dateStart = moment(dateFrom);
            let dateEnd = moment(dateTo);

            if (dateStart.format("LL") !== dateEnd.format("LL")) {
                return p.t("form.fields.date_range_selector.interval", {
                    from: dateStart.format(dateFormatShort),
                    to: dateEnd.format(dateFormatShort),
                });
            }
            return dateStart.format(dateFormatShort);
        } else if (/^[0-9]+$/.test(interval)) {
            return p.t("form.fields.date_range_selector.n_days", {
                smart_count: parseInt(interval),
            });
        }
        return p.t(`form.fields.date_range_selector.${interval}`);
    }
};

/**
 * Deduce the best way to display interval
 * @param from
 * @param to
 * @param p
 * @param fullLabel
 * @returns {string}
 */
export function getIntervalLabelFromDates(from, to, p, fullLabel = false) {
    let _from = moment(from);
    let _to = moment(to);
    const interval = moment.duration(_to.diff(_from)).asMilliseconds();
    const oneDay = moment.duration(1, "d").asMilliseconds();
    let label;
    const hourFormat = moment.locale() === "en" ? "hh A" : "LT"; // 12 AM for 'en', 12:00 for others
    if (interval < oneDay) {
        // hours
        if (fullLabel) {
            // 14:00 - 16:00 <br> Sunday September 2, 2018
            label = `${_from.format(hourFormat)} - ${_to.format(hourFormat)}<br>${capitalize(
                _from.format("dddd LL")
            )}`;
        } else if (_from.format("hourFormat") === "00") {
            // For the first hour of day
            // 00:00 - 02:00 <br> Sun 02
            label = `${_from.format(hourFormat)} - ${_to.format(hourFormat)}<br>${capitalize(
                _from.format("ddd DD")
            )}`;
        } else {
            // 02:00 - 04:00
            label = `${_from.format(hourFormat)} - ${_to.format(hourFormat)}`;
        }
    } else if (interval === oneDay) {
        // 1 day
        if (fullLabel) {
            // Sunday September 2, 2018
            label = _from.format("dddd LL");
        } else if (_from.format("DD") === "01") {
            // 1 <br> Sep
            label = _from.format("D[<br>]MMM");
        } else {
            // 2
            label = _from.format("D");
        }
    } else if (
        moment(from).isSame(moment(to).subtract(1, "second").startOf("month"), "day") && // from is the first day of month
        moment(to).subtract(1, "second").isSame(moment(from).endOf("month"), "day") // to is the last day of month
    ) {
        // 1 month
        if (fullLabel) {
            // September 2018
            label = _from.format("MMMM YYYY");
        } else if (_from.format("MM") === "01") {
            // For the first month of year
            // Sep <br> 2018
            label = _from.format("MMM[<br>]YYYY");
        } else {
            // Sep
            label = _from.format("MMM");
        }
    } else if (fullLabel) {
        label = `${p.t("moment._from")} ${_from.format("dddd D")} ${p.t("moment.to")} ${_to
            .subtract(1, "second")
            .format("dddd D MMMM")}`;
    } else {
        label = `${_from.format("D")} ${p.t("moment.to")} ${_to
            .subtract(1, "second")
            .format("D[<br>]MMM")}`;
    }
    return capitalize(label);
}

/**
 * Returns dates from & to according
 *
 * @param interval
 * @param dateFromCurrent
 * @param dateToCurrent
 * @returns {{dateFrom: *, dateTo: *}}
 */
export function getNextDatesFromInterval(interval, dateFromCurrent, dateToCurrent) {
    let nextDateFrom;
    let nextDateTo;
    switch (interval) {
        default:
        case "cd":
        case "pd":
            nextDateFrom = moment(dateFromCurrent).add(+1, "days");
            nextDateTo = moment(dateToCurrent).add(+1, "days");
            break;
        case "cw":
        case "pw":
            nextDateFrom = moment(dateFromCurrent).add(+1, "weeks");
            nextDateTo = moment(dateToCurrent).add(+1, "weeks");
            break;
        case "cm":
            nextDateFrom = moment(dateFromCurrent).add(+1, "months");
            nextDateTo = moment(dateToCurrent).add(+1, "months");
            break;
        case "pm":
            nextDateFrom = moment(dateFromCurrent).add(+1, "months").startOf("month");
            nextDateTo = moment(dateToCurrent).add(+1, "months").endOf("month");
            break;
        case "cq":
        case "pq":
            nextDateFrom = moment(dateFromCurrent)
                .startOf("quarter")
                .add(+3, "months")
                .startOf("quarter");
            nextDateTo = moment(dateToCurrent)
                .startOf("quarter")
                .add(+3, "months")
                .endOf("quarter");
            break;
        case "cy":
            nextDateFrom = moment(dateFromCurrent).add(+1, "years").startOf("year");
            nextDateTo = moment(dateToCurrent).add(+1, "years");
            break;
        case "yy":
            nextDateFrom = moment(dateFromCurrent).add(1, "years");
            nextDateTo = moment(dateToCurrent).add(1, "years");
            break;
    }
    return {
        nextDateFrom,
        nextDateTo,
    };
}

/**
 * Returns dates from & to according
 *
 * @param interval
 * @param dateFromCurrent
 * @param dateToCurrent
 * @returns {{dateFrom: *, dateTo: *}}
 */
export function getPreviousDatesFromInterval(interval, dateFromCurrent, dateToCurrent) {
    let previousDateFrom;
    let previousDateTo;
    switch (interval) {
        default:
        case "cd":
        case "pd":
            previousDateFrom = moment(dateFromCurrent).add(-1, "days");
            previousDateTo = moment(dateToCurrent).add(-1, "days");
            break;
        case "cw":
        case "pw":
            previousDateFrom = moment(dateFromCurrent).add(-1, "weeks");
            previousDateTo = moment(dateToCurrent).add(-1, "weeks");
            break;
        case "cm":
            previousDateFrom = moment(dateFromCurrent).add(-1, "months");
            previousDateTo = moment(dateToCurrent).add(-1, "months");
            break;
        case "pm":
            previousDateFrom = moment(dateFromCurrent).add(-1, "months").startOf("month");
            previousDateTo = moment(dateToCurrent).add(-1, "months").endOf("month");
            break;
        case "cq":
            let toMonthDiff =
                -1 - moment(dateToCurrent).endOf("quarter").diff(moment(dateToCurrent), "months");
            previousDateFrom = moment(dateFromCurrent)
                .startOf("quarter")
                .add(-1, "months")
                .startOf("quarter");
            previousDateTo = moment(dateToCurrent)
                .startOf("quarter")
                .add(toMonthDiff, "months")
                .add(moment(dateToCurrent).format("D") - 1, "days");
            break;
        case "pq":
            previousDateFrom = moment(dateFromCurrent)
                .startOf("quarter")
                .add(-1, "months")
                .startOf("quarter");
            previousDateTo = moment(dateToCurrent)
                .startOf("quarter")
                .add(-1, "months")
                .endOf("quarter");
            break;
        case "cy":
            previousDateFrom = moment(dateFromCurrent).add(-1, "years").startOf("year");
            previousDateTo = moment(dateToCurrent).add(-1, "years");
            break;
        case "yy":
            previousDateFrom = moment(dateFromCurrent).subtract(1, "years");
            previousDateTo = moment(dateToCurrent).subtract(1, "years");
            break;
    }
    return {
        previousDateFrom,
        previousDateTo,
    };
}

export const recalculateDatesFromIntervalInUrl = (favorites) => {
    Object.values(favorites).map((favorite) => {
        const interval_dates = favorite.urlSearch.match(/fp_\w+_interval/g);
        if (interval_dates) {
            let dates = [];
            const urlParams = parseUrlToParams(favorite.urlSearch);
            interval_dates.forEach((attribute) => {
                if (urlParams[attribute] !== "custom") {
                    dates = getDatesFromInterval(urlParams[attribute]);
                    urlParams[attribute.replace("interval", "from")] =
                        dates.from.format("YYYY-MM-DD");
                    urlParams[attribute.replace("interval", "to")] = dates.to.format("YYYY-MM-DD");
                }
            });

            favorite.urlSearch = "?" + Object.entries(urlParams).join("&").split(",").join("=");
        }

        return favorite;
    });

    return favorites;
};

/**
 * Date as string format "YYYY-MM-DD"
 * @param date
 * @returns {*}
 */
export function toISODateString(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
}

/**
 * Convert date to local date
 * @param {Date} date
 * @returns Date
 */
export function convertUTCDateToLocalDate(date) {
    var localOffset = date.getTimezoneOffset() * 60000;
    var localTime = date.getTime();

    date = localTime - localOffset;

    return new Date(date);
}

/**
 * Checks if
 * - dateFrom and dateTo are defined
 * - dateFrom <= dateTo
 * - Diff between dateFrom and dateTo <= 1 year
 * @param p
 * @param dateFrom
 * @param fromError
 * @param dateTo
 * @param toError
 * @param maxDays
 * @return {{fromError: *, toError: *}}
 */
export function checkDateRange(p, dateFrom, fromError, dateTo, toError, maxDays = 365) {
    let fromErrorTmp = fromError;
    let toErrorTmp = toError;

    if (!dateFrom && !fromError) {
        fromErrorTmp = p.t("form.fields.date_range_selector.error.missing_date_from");
    } else if (!dateTo && !toError) {
        toErrorTmp = p.t("form.fields.date_range_selector.error.missing_date_to");
    } else if (dateFrom && dateTo && dateFrom > dateTo) {
        fromErrorTmp = p.t("form.fields.date_range_selector.error.to_superior_from");
        toErrorTmp = p.t("form.fields.date_range_selector.error.to_superior_from");
    }

    if (maxDays && dateFrom && dateTo && moment(dateTo).diff(moment(dateFrom), "days") > maxDays) {
        fromErrorTmp = p.t("form.fields.date_range_selector.error.one_year_max", {
            value: maxDays,
        });
        toErrorTmp = p.t("form.fields.date_range_selector.error.one_year_max", { value: maxDays });
    } else if (dateFrom && dateTo && dateFrom <= dateTo) {
        fromErrorTmp = false;
        toErrorTmp = false;
    }

    return {
        fromError: fromErrorTmp,
        toError: toErrorTmp,
    };
}
