import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStylesAndTranslate } from "../../../../hoc/withStylesAndTranslate";
import { compose } from "recompose";
import { withConfig } from "../../../../services/config";
import { withModule } from "../../../../services/module";

import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import Body from "../../../containers/Search/Grid/BodyContainer";
import { HeaderCell } from "./Header";
import { getNextItemSelectable } from "@hipay/hipay-material-ui/utils/helpers";
import { DISPLAY_DATE } from "../../../constants/displayTypes";
import { ContextMenuProvider } from "../../../../services/ContextMenu";

const stylesGrid = (theme) => ({
    root: {
        position: "relative",
        display: "inline-block",
        minHeight: window.innerHeight - 239,
        ...theme.typography.body3,
        backgroundColor: theme.palette.background3,
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 20,
        paddingBottom: 20,
        minWidth: "100%",
    },
    backToTop: {
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 13,
        width: window.innerWidth,
        height: 0,
        transition: "top 0.6s ease-in-out",
        marginTop: theme.spacing(4),
    },
    backToTopButton: { margin: "0 auto" },
    buttonIcon: {
        margin: 4,
        color: theme.palette.background3,
    },
    notFiltered: { minHeight: window.innerHeight - 190 },
    noFavoritesBar: {},
});

class Grid extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        contextMenuProps: PropTypes.object,
        data: PropTypes.array,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        fetchAmountStats: PropTypes.func,
        handleColumnsOrderUpdate: PropTypes.func,
        loading: PropTypes.bool,
        totalHits: PropTypes.number,
        storeRef: PropTypes.object,
        onChangeSearchParam: PropTypes.func,
        sortedColumnId: PropTypes.string,
        sortDirection: PropTypes.oneOf(["asc", "desc"]),
        onSort: PropTypes.func,
        addLookupValue: PropTypes.func,
        addExcludeValue: PropTypes.func,
        onRequestNextDatas: PropTypes.func,
        userSettings: PropTypes.object,
        orderedColumns: PropTypes.array,
        selectedAccountIdList: PropTypes.array,
        authentication: PropTypes.object,
        columnParams: PropTypes.object,
        isDisplayFavorites: PropTypes.bool,
        chipFilterLines: PropTypes.number,
        chipFavoritesLines: PropTypes.number,
        onRowClick: PropTypes.func,
        onRowMiddleClick: PropTypes.func,
        searchState: PropTypes.string,
        handleSearchAll: PropTypes.func,
    };

    constructor(props) {
        super(props);

        // Fetch options for displayed columns select fields
        if (props.orderedColumns && props.orderedColumns.length > 0) {
            props.orderedColumns.forEach(({ colId, displayed }) => {
                if (displayed) {
                    const attribute = props.attributes[colId];
                    if (attribute === undefined) {
                        console.warn(`DataFieldType ${colId} is undefined`);
                    } else if (
                        attribute.hasOwnProperty("reference") &&
                        props.storeRef[attribute.reference] === undefined
                    ) {
                        const args = {
                            accountIdList: props.selectedAccountIdList,
                            p: props.p,
                        };
                        props.fetchReferences(props.references, attribute.reference, args, true);
                    }
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        let columnsChanged = this.props.orderedColumns !== prevProps.orderedColumns;
        let accountsChanged =
            JSON.stringify(this.props.selectedAccountIdList) !==
            JSON.stringify(prevProps.selectedAccountIdList);
        if (columnsChanged || accountsChanged) {
            this.props.orderedColumns.forEach(({ colId, displayed }) => {
                if (displayed) {
                    const attribute = this.props.attributes[colId];
                    if (attribute === undefined) {
                        console.warn(`DataFieldType ${colId} is undefined`);
                    } else if (attribute.hasOwnProperty("reference")) {
                        if (
                            (columnsChanged &&
                                this.props.storeRef[attribute.reference] === undefined) ||
                            (accountsChanged &&
                                attribute?.formFieldOptions?.restrictedBySelectedAccountList)
                        ) {
                            const args = {
                                accountIdList: this.props.selectedAccountIdList,
                                p: this.props.p,
                            };
                            this.props.fetchReferences(
                                this.props.references,
                                attribute.reference,
                                args,
                                true
                            );
                        }
                    }
                }
            });
        }
    }

    handleFilterChange = (id, value) => {
        this.props.onChangeSearchParam(id, value);
    };

    handleSearch = (field, value, type) => {
        if (type === "lookup") {
            this.props.addLookupValue(field, value);
        } else if (type === "exclude") {
            this.props.addExcludeValue(field, value);
        }
    };

    handleKeyDown = (event) => {
        const { onRowClick } = this.props;

        if (document.activeElement.dataset.rowId) {
            if (event.key !== "Tab" && event.key !== "Enter") {
                event.preventDefault();
            }

            let nextItem;
            if (event.key === "ArrowDown") {
                nextItem = getNextItemSelectable(document.activeElement, "down");
                if (nextItem) {
                    nextItem.focus();
                }
            } else if (event.key === "ArrowUp") {
                nextItem = getNextItemSelectable(document.activeElement, "up");
                if (nextItem) {
                    nextItem.focus();
                }
            } else if (
                onRowClick &&
                event.key === "Enter" &&
                document.activeElement &&
                document.activeElement.dataset.rowId
            ) {
                onRowClick(event, document.activeElement.dataset.rowId);
            }
        }
    };

    handleRowClick = (e, rowId, colId) => {
        const { onRowClick } = this.props;

        if (!onRowClick) {
            return;
        }

        onRowClick(e, rowId, !!e.ctrlKey, colId);
    };

    handleRowMiddleClick = (e, rowId) => {
        const { onRowMiddleClick } = this.props;

        if (!onRowMiddleClick) {
            return;
        }

        onRowMiddleClick(e, rowId);
    };

    render() {
        const {
            attributes,
            groupByAttribute,
            classes,
            module,
            data = [],
            loading,
            error,
            totalHits,
            orderedColumns,
            sortedColumnId,
            sortDirection,
            onSort,
            columnParams,
            formParams,
            qsearchParams,
            lookupColumnIdList,
            onRequestNextDatas,
            onClickBackToTop,
            p,
            userSettings,
            isDisplayFavorites,
            selectedAccountIdList,
            chipFilterLines,
            chipFavoritesLines,
            isDisplaySelector,
            isDisplayRank,
            onRowClick,
            onRowMiddleClick,
            defaultSortParams,
            fetchAmountStats,
            handleColumnsOrderUpdate,
            references,
            contextMenuProps,
            searchState,
            handleSearchAll,
        } = this.props;

        const hasNextDatas = data.length < totalHits;

        return (
            <ContextMenuProvider
                attributes={attributes}
                onSearch={this.handleSearch}
                extraItems={contextMenuProps ? contextMenuProps.items : []}
                onRowClick={contextMenuProps ? contextMenuProps.onRowClick : undefined}
            >
                <div
                    id="search-results"
                    className={classNames(classes.root, {
                        [classes.notFiltered]: chipFilterLines === 0,
                        [classes.noFavoritesBar]: !isDisplayFavorites,
                    })}
                    onKeyDown={this.handleKeyDown}
                >
                    <div id="back-to-top" className={classes.backToTop}>
                        <HiButton
                            className={classes.backToTopButton}
                            color="primary"
                            variant="contained"
                            onClick={onClickBackToTop}
                        >
                            <HiIcon className={classes.buttonIcon} icon="arrow_upward" size={18} />
                            {p.t("common.search.table.back_to_top")}
                            <HiIcon className={classes.buttonIcon} icon="arrow_upward" size={18} />
                        </HiButton>
                    </div>
                    <HeaderCell
                        module={module}
                        attributes={attributes}
                        onSort={onSort}
                        sortDirection={sortDirection}
                        sortColumnId={sortedColumnId}
                        onFilterChange={this.handleFilterChange}
                        columnList={orderedColumns}
                        lookupColumnIdList={lookupColumnIdList}
                        columnParams={columnParams}
                        formParams={formParams}
                        dense
                        chipFilterLines={chipFilterLines}
                        chipFavoritesLines={chipFavoritesLines}
                        selectedAccountIdList={selectedAccountIdList}
                        isDisplaySelector={isDisplaySelector}
                        isDisplayRank={isDisplayRank}
                        isDisplayFavorites={isDisplayFavorites}
                        onClickBackToTop={onClickBackToTop}
                        defaultSortParams={defaultSortParams}
                        fetchAmountStats={fetchAmountStats}
                        handleColumnsOrderUpdate={handleColumnsOrderUpdate}
                    />
                    <Body
                        attributes={attributes}
                        groupByAttribute={groupByAttribute}
                        columnList={orderedColumns}
                        data={data}
                        dateLocale={userSettings.language}
                        dense
                        error={error}
                        loading={loading}
                        lookupColumnIdList={lookupColumnIdList}
                        groupByDate={
                            sortedColumnId === groupByAttribute &&
                            attributes[groupByAttribute].displayType === DISPLAY_DATE
                        }
                        hasNextDatas={hasNextDatas}
                        onRequestNextDatas={onRequestNextDatas}
                        onRowClick={onRowClick && this.handleRowClick}
                        onRowMiddleClick={onRowMiddleClick && this.handleRowMiddleClick}
                        chipFilterLines={chipFilterLines}
                        chipFavoritesLines={chipFavoritesLines}
                        isDisplayFavorites={isDisplayFavorites}
                        isDisplaySelector={isDisplaySelector}
                        isDisplayRank={isDisplayRank}
                        moduleReferences={references}
                        searchState={searchState}
                        qsearchParams={qsearchParams}
                        handleSearchAll={handleSearchAll}
                    />
                </div>
            </ContextMenuProvider>
        );
    }
}

export default compose(withStylesAndTranslate(stylesGrid), withConfig, withModule)(Grid);
