const sentinelColorById = {
    NOT_LAUNCHED: "#737373",
    ACCEPTED: "#02A17B",
    AUTHENTICATE: "#4D5255",
    FORCE_AUTHENTICATE: "#20272B",
    PENDING: "#6864E7",
    CHALLENGED: "#FF8C50",
    BLOCKED: "#CB2B0B",
    ALLOWED: "#02A17B",
    DENIED: "#CB2B0B",
};

export const sentinelReviewResultImgById = {
    ALLOWED: "/assets/core/img/references/sentinel_results/allowed.svg",
    DENIED: "/assets/core/img/references/sentinel_results/denied.svg",
};

const sentinelReviewResults = {
    id: "sentinel_review_results",
    data: [
        {
            id: "ALLOWED",
            label: "ref.transaction.sentinel_review_results.ALLOWED",
            label2: "ref.transaction.sentinel_review_results_char.ALLOWED",
            color: sentinelColorById["ALLOWED"],
        },
        {
            id: "DENIED",
            label: "ref.transaction.sentinel_review_results.DENIED",
            label2: "ref.transaction.sentinel_review_results_char.DENIED",
            color: sentinelColorById["DENIED"],
        },
    ],
    translate: true,
};

export default sentinelReviewResults;
