import { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { isMobile } from "react-device-detect";
import { useP } from "../../../../services/i18n";
import { WIDGET_TYPE } from "../../../../constants";

const useStylesItemAddable = makeStyles((theme) => ({
    root: { background: "#fff" },
    aItem: {
        boxSizing: "border-box",
        boxShadow: "0px 2px 10px 0px #cfcfcf",
        "& > img": {
            margin: "0 auto",
            width: 232,
            height: 188,
            display: "block",
        },
    },
    titleContainer: {
        height: 64,
        textOverflow: "ellipsis",
        padding: "10px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: { marginLeft: "auto" },
    buttonsContainer: {
        height: 48,
        padding: "10px 8px",
        "& > button": { lineHeight: "14px" }, // (48 - button padding)
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
}));

export function ItemAddable(props) {
    const p = useP();
    const classes = useStylesItemAddable();

    const { addable, addItem, index, item, type, handleClick } = props;

    const itemContent = useMemo(() => {
        switch (type) {
            case WIDGET_TYPE:
                return (
                    <props.ThumbnailContent
                        item={item}
                        onClickContent={(e) => handleClick(e, item)}
                    />
                );
            default:
                return (
                    <>
                        <div className={classes.titleContainer}>
                            <div />
                        </div>
                        <div />
                    </>
                );
        }
    }, [type]);

    return (
        <div className={classes.root} key={index} data-slug={item.id}>
            <div className={classes.aItem}>
                {itemContent}
                <div className={classes.buttonsContainer}>
                    {!isMobile && (
                        <HiButton
                            id={"add-" + item.id}
                            onClick={() => addItem(item)}
                            color="primary"
                            size="small"
                            disabled={!addable}
                        >
                            {p.t("common.search.buttons.add")}
                        </HiButton>
                    )}
                    {!item.active && (
                        <HiIcon
                            id={item.id + "-not-active"}
                            icon="visibility_off"
                            className={classes.icon}
                            size={20}
                            color="error"
                            title={p.t("common.search.inactive")}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

ItemAddable.propTypes = {
    addable: PropTypes.bool,
    addItem: PropTypes.func,
    index: PropTypes.any,
    item: PropTypes.any,
    type: PropTypes.any,
};
