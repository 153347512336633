import card_categories from "./card_categories";
import financial_institutions from "./financial_institutions";
import order_currency from "./order_currency";
import payment_means from "./payment_means";
import cms from "./cms";
import sentinel_profiles from "./sentinel_profiles";
import sentinel_rules from "./sentinel_rules";
import stores from "./stores";
import refusal_reasons from "./refusal_reasons";
import authors from "./authors";
import cancel_authors from "./cancel_authors";
import dispute_authors from "./dispute_authors";
import chargeback_reasons from "./chargeback_reasons";
import customer_operating_systems from "./customer_operating_systems";
import customer_companies from "./customer_companies";
import acquirers from "./acquirers";
import providers from "./providers";

export const dynamics_datas = {
    card_categories,
    financial_institutions,
    order_currency,
    payment_means,
    cms,
    sentinel_profiles,
    sentinel_rules,
    stores,
    refusal_reasons,
    authors,
    cancel_authors,
    dispute_authors,
    chargeback_reasons,
    customer_operating_systems,
    customer_companies,
    acquirers,
    providers,
};

export default dynamics_datas;
