import React, { useCallback, useMemo, useState } from "react";
import { InnerPageContext } from "./InnerPage.context";
import { PageLayout } from "./InnerPage.layout";
import { useP } from "../i18n";
import { ErrorBoundary } from "../../app/components/common/ErrorBoundary";
import { setEventTracker } from "../tracker";

export const InnerPageProvider = (props) => {
    const [content, setContent] = useState(null);
    const [options, setOptions] = useState({});
    const [actions, setActions] = useState([]);
    const [tagName, setTagName] = useState("");

    const p = useP();

    const openInnerPage = useCallback(
        (_content = null, _options = {}, _tagName = "", _actions = []) => {
            // track inner pages as screen views
            setEventTracker("screen_view", { screen_name: _tagName });

            setContent(_content);
            setOptions(_options);
            setActions(_actions);
            setTagName(_tagName);
        },
        [setContent, setOptions]
    );

    const closeInnerPage = useCallback(() => {
        setContent(null);
        setOptions({});
        setActions([]);
        setTagName("");
    }, [setContent, setOptions]);

    const getInnerComponent = () => {
        return tagName;
    };

    const displayedContent = useMemo(() => {
        if (options.withLayout === false) {
            return content;
        }

        return (
            <PageLayout
                onClose={closeInnerPage}
                title={options.titleKey ? p.t(options.titleKey) : ""}
                actions={actions}
                goBack={options.goBack}
                fromTopbar={options.fromTopBar}
            >
                {content}
            </PageLayout>
        );
    }, [options, content]);

    return (
        <InnerPageContext.Provider
            value={{
                openInnerPage,
                closeInnerPage,
                getInnerComponent,
            }}
        >
            {React.Children.only(props.children)}
            {!!content && (
                <ErrorBoundary>
                    <div id="inner-page">{displayedContent}</div>
                </ErrorBoundary>
            )}
        </InnerPageContext.Provider>
    );
};
