import * as React from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";

export const HideAnalyticsIcon = (props) => {
    return (
        <Icon
            style={{
                ...props.style,
                fontSize: props.size,
            }}
        >
            <svg viewBox="0 0 23.94 24.57">
                <path
                    d="M20.95 14.14h-3.99v.98l3.99 3.99v-4.97zM16.96 17.67l-3-3-4.03-4.03-5.15-5.15L3.5 6.76l1.38 1.38H2.94v13.97h3.98V10.18l3.01 3v8.93h4.03v-4.89l3 3v1.89h1.9l1.45 1.46 1.27-1.28-.63-.63-3.99-3.99zM13.96 2.14H9.93v5.95l4.03 4.03V2.14z"
                    fill={props.color || "currentColor"}
                />
            </svg>
        </Icon>
    );
};

HideAnalyticsIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.object,
};

HideAnalyticsIcon.defaultProps = {
    color: "#010101",
    size: 24,
    style: {},
};
