import React, { useEffect, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTimeout } from "react-use";
import { useAsync } from "react-async";
import { useSnackbar } from "notistack";

import HiButton from "@hipay/hipay-material-ui/HiButton";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { addExport } from "../../../../app/actions/appActions";
import * as cst from "./constants";
import { buildFilePrefix } from "../../../../utils/export";

const useStylesSendToMail = makeStyles((theme) => ({
    card: {
        maxWidth: 400,
        minWidth: 344,
    },
    actionRoot: {
        padding: "8px 8px 8px 16px",
        backgroundColor: "#424242",
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    actionRootText: { color: theme.palette.getContrastText("#424242") },
    actionRootSuccess: {
        padding: "8px 8px 8px 16px",
        backgroundColor: theme.palette.success.main,
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    actionRootFail: {
        padding: "8px 8px 8px 16px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText("#424242"),
        justifyContent: "space-between",
    },
    actionRootFailText: { color: theme.palette.getContrastText(theme.palette.error.main) },
    icons: {
        marginLeft: "auto",
        height: 40,
        padding: 2,
    },
    close: {
        padding: "8px 8px",
        color: "#e0e0e0",
    },
    collapse: {
        padding: 16,
        backgroundColor: "#e0e0e0",
    },
    snackbarHeader: { color: "inherit" },
}));

// API CALLS
const baseUrl = process.env.NX_CONSOLE_API_URL;
const headers = (token) => ({
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Authorization": "Bearer " + token,
});

/*
 * Create or update export
 * if id is defined >> update
 * else             >> create
 */
const _createExport = async ([{ id, ...data }], { token }) => {
    const response = id
        ? await fetch(`${baseUrl}/exports/${id}`, {
              headers: headers(token),
              method: "PUT",
              body: JSON.stringify(data),
          })
        : await fetch(`${baseUrl}/exports`, {
              headers: headers(token),
              method: "POST",
              body: JSON.stringify(data),
          });
    if (!response.ok) {
        const status = response.status.toString();
        const responseObject = await response.json();
        throw new Error(
            JSON.stringify({
                ...responseObject,
                status,
            })
        );
    }
    return response.json();
};

/**
 * Create export from data then send file to user email
 *
 * This component is called from Export Menu (send to email).
 *
 * display
 * - creation pending with UNDO
 * - creation pending
 * - successfully created
 *
 * This component is instantiate in notistack context, which is outside inherit context provider.
 * Therefore, you cannot use inherited contexts (i18n, authentication, user, ...).
 * You should use props to get this datas.
 *
 */
export const SendToEmail = React.forwardRef((props, ref) => {
    const {
        p,
        token,
        user,
        moduleConfig,
        snackbarId,
        exportId,
        module,
        columns,
        filters,
        filePrefix: presetFilePrefix,
        recurrence,
        nbOccurrence,
        separator,
        totalHits,
        urlFilters,
        email,
        onResolve,
        pendingMessage,
        loadingMessage,
        successMessage,
        failMessage,
        greenOnSuccess,
        redOnError,
        exportConfig,
    } = props;

    const classes = useStylesSendToMail();
    const location = useLocation();

    const { closeSnackbar } = useSnackbar();
    const [isReady, cancel] = useTimeout(3000);
    const _isReady = isReady();
    const dispatch = useDispatch();
    const accountList = useSelector((state) => state.app.global.entities.account);
    const businessList = useSelector((state) => state.app.global.entities.business);

    // Close snackbar
    const dismissSnackbar = () => closeSnackbar(snackbarId);

    // Undo - cancel and close snackbar
    const undo = () => {
        cancel();
        dismissSnackbar();
    };

    // build file prefix from selected accounts
    const filePrefix = useMemo(() => {
        if (presetFilePrefix) {
            return presetFilePrefix;
        }

        return buildFilePrefix(
            moduleConfig,
            filters,
            p,
            accountList,
            businessList,
            cst.MAX_FILE_PREFIX_LENGTH
        );
    }, [filters, accountList, businessList]);

    // Async create export
    const {
        data: exportData,
        error: exportError,
        run: createExport,
    } = useAsync({
        deferFn: _createExport,
        token,
        onResolve: onResolve,
    });

    useEffect(() => {
        if (_isReady) {
            createExport({
                module: module,
                columns: JSON.stringify(columns),
                filters: JSON.stringify(filters),
                urlFilters: urlFilters || location.search,
                totalHits: totalHits,
                emails: email || user.email,
                status: "active",
                config: exportConfig,
                // default form value
                filePrefix: filePrefix,
                recurrence: recurrence || "once",
                nbOccurrence: nbOccurrence,
                separator: separator || "semicolon",
                id: exportId,
            });
        }
    }, [_isReady]);

    // update export list on success
    useEffect(() => {
        if (exportData && exportData.recurrence !== "once") {
            dispatch(addExport(exportData));
        }
    }, [exportData]);

    // dismiss snackbar if user logout
    useEffect(() => {
        if (!token || !user) {
            dismissSnackbar();
        }
    }, [token, user]);

    if (!token || !user) {
        return null;
    }

    const checkTranslation = (key) => {
        return p.has(key) ? p.t(key) : key;
    };

    // Error snackbar
    if (exportError) {
        const exportErrorMessage = JSON.parse(exportError.message);

        let errorMsg;
        if (exportErrorMessage.detail === "Recipient not found") {
            errorMsg = p.t("common.search.export.snackbar.export_recipient_not_found");
        } else if (failMessage) {
            errorMsg = checkTranslation(failMessage);
        } else {
            errorMsg =
                exportErrorMessage.status === "409"
                    ? p.t("common.search.export.snackbar.create_export_conflict")
                    : p.t("common.search.export.snackbar.create_export_failed");
        }

        let errorCardActionsStyle = classes.actionRoot;
        let errorCardActionsStyleText = classes.actionRootText;
        if (redOnError) {
            errorCardActionsStyle = classes.actionRootFail;
            errorCardActionsStyleText = classes.actionRootFailText;
        }

        return (
            <Card className={classes.card} ref={ref}>
                <CardActions classes={{ root: errorCardActionsStyle }}>
                    <Typography variant="subtitle2" classes={{ root: errorCardActionsStyleText }}>
                        {errorMsg}
                    </Typography>
                    <div className={classes.icons}>
                        <IconButton
                            className={classes.close}
                            onClick={dismissSnackbar}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </CardActions>
            </Card>
        );
    }

    // creating export
    let successMsg;
    if (successMessage) {
        successMsg = checkTranslation(successMessage);
    } else {
        successMsg = p.t("common.search.export.snackbar.create_export_success");
    }

    let loadingMsg;
    if (loadingMessage) {
        loadingMsg = checkTranslation(loadingMessage);
    } else {
        loadingMsg = p.t("common.search.export.snackbar.create_export_pending");
        if (totalHits > process.env.NX_LIMIT_EXPORT) {
            // 1000 rows ~= 1 second
            const duration = Math.ceil(totalHits / 1000 / 60);
            loadingMsg = p.t("common.search.export.snackbar.create_big_export_pending", {
                duration,
            });
        }
    }

    let pendingMsg;
    if (pendingMessage) {
        pendingMsg = checkTranslation(pendingMessage);
    } else {
        pendingMsg = p.t("common.search.export.snackbar.create_export_pending");
    }

    let cardActionsStyle = classes.actionRoot;
    if (greenOnSuccess && _isReady && !!exportData) {
        cardActionsStyle = classes.actionRootSuccess;
    }

    return (
        <Card className={classes.card} ref={ref} id="export-snackbar">
            <CardActions classes={{ root: cardActionsStyle }}>
                <Typography classes={{ root: classes.snackbarHeader }} variant="subtitle2">
                    {_isReady
                        ? exportId
                            ? exportData
                                ? successMsg
                                : p.t("common.search.export.snackbar.update_export_pending")
                            : exportData
                            ? successMsg
                            : loadingMsg
                        : pendingMsg}
                </Typography>
                <div className={classes.icons}>
                    {_isReady ? (
                        <IconButton
                            id="close-export"
                            className={classes.close}
                            onClick={dismissSnackbar}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <HiButton
                            id="undo-export"
                            children={p.t("common.search.export.snackbar.undo")}
                            color="inherit"
                            onClick={undo}
                        />
                    )}
                </div>
            </CardActions>
            <Collapse in={!!exportData} timeout="auto" unmountOnExit>
                <Paper className={classes.collapse}>
                    <Typography variant="body2">
                        {!!exportData &&
                            p.t(
                                `common.search.export.snackbar.${
                                    exportData.recurrence !== "once" ? "recurring_" : ""
                                }export_sent_to_email`,
                                {
                                    filename: exportData.filePrefix,
                                    period: p.t(
                                        `common.search.export.snackbar.period.${exportData.recurrence}`
                                    ),
                                    timeAvailable: p.t(
                                        "common.search.export.snackbar.time_available.7days"
                                    ),
                                }
                            )}
                    </Typography>
                    <Typography variant="caption">{email || user.email}</Typography>
                </Paper>
            </Collapse>
        </Card>
    );
});
