import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import HiDynamicSelect from "@hipay/hipay-material-ui/HiSelect/HiDynamicSelect";
import HiSelect from "@hipay/hipay-material-ui/HiSelect/HiSelect";
import HiSuggestSelect from "@hipay/hipay-material-ui/HiSelect/HiSuggestSelect";
import HiInput from "@hipay/hipay-material-ui/HiForm/HiInput";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { usePrevious } from "react-use";
import { useP } from "../../../../services/i18n";
import { useApi } from "../../../../services/api";
import { useAsync } from "react-async";

const useStylesCustomDataField = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: 40,
    },
    rootMobile: {
        display: "flex",
        flexDirection: "column",
    },
    key: {
        paddingRight: 10,
        width: "50%",
    },
    keyMobile: { marginBottom: 12 },
    value: { width: "50%" },
    suggestRoot: { maxWidth: 212 },
    suggestPopper: { width: "50%" },
    inputText: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 222,
    },
    newCustomDataHelper: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        float: "right",
    },
    popperMobile: { top: "unset !important" },
}));

export const CustomDataField = (props) => {
    const {
        id,
        value,
        formField,
        selectedAccountIdList,
        options,
        onChange,
        onResetSuggest,
        attribute,
        onSearchSuggest,
        onSelectSuggest,
        query,
        onChangeKeySelect,
        onSubmit,
        inputRef,
        formFields,
    } = props;

    const [loading, setLoading] = useState(props.loading);
    const [keyValue, setKeyValue] = useState("");
    const [page, setPage] = useState(1);
    const [error, setError] = useState(props.error);
    const [errorText, setErrorText] = useState(props.errorText);
    const [keySuggestions, setKeySuggestions] = useState([]);
    const prevValue = usePrevious(props.value);

    const classes = useStylesCustomDataField();
    const p = useP();

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setErrorText(props.errorText);
    }, [props.errorText]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        if (
            isMobile &&
            props.value &&
            ((!prevValue && props.value) || props.value.key !== prevValue.key)
        ) {
            setKeyValue(props.value.key);
            setKeySuggestions([]);
        }
    }, [props.value]);

    const { get } = useApi();
    const {
        data: suggestionsData,
        error: suggestionsError,
        isPending,
        run: fetchSuggestions,
        cancel: cancelFetch,
    } = useAsync({
        deferFn: get,
        url: (search) => `/_suggest?${search}`,
    });

    useEffect(() => {
        if (suggestionsData) {
            setKeySuggestions(suggestionsData);
            setLoading(false);
            setError(false);
            props.handleFormErrors(id, false);
        } else if (suggestionsError) {
            setKeySuggestions([]);
            setLoading(false);
            setError(true);
            setErrorText(p.t("form.fields.autosuggest.error"));
            props.handleFormErrors(id, true);
        }
    }, [suggestionsData, suggestionsError]);

    let oldKeys = [];
    let onSearchKeyFunction;
    let disabled = !value;
    let searchValue;
    let searchable = true;
    let KeyComponent = isMobile ? HiSuggestSelect : HiDynamicSelect; // AutoSuggest for keys on Mobile device
    let valueComponent;

    // Disable old_custom_data keys already selected
    const disabledItemIdList = useMemo(
        function () {
            let keys = [];
            if (id.indexOf("old_") >= 0) {
                for (let i = 0; i < Object.keys(formFields).length; i++) {
                    let key = Object.keys(formFields)[i];
                    if (
                        key.includes("old_custom_data") &&
                        formFields[key] &&
                        (!value || formFields[key].key !== value.key)
                    ) {
                        keys.push(formFields[key].key);
                    }
                }
                return keys;
            }
        },
        [formFields]
    );

    if (id.indexOf("old_") >= 0) {
        for (let i = 1; i <= 10; i++) {
            oldKeys.push({
                id: i.toString(),
                label: i.toString(),
            });
        }
        disabled = !value;
        searchable = false;
        // eslint-disable-next-line no-unused-vars
        KeyComponent = HiSelect;

        valueComponent = (
            <HiSuggestSelect
                autoComplete="off"
                id={`${id}_value`}
                onChange={onChange(`${id}_key`)}
                onSearch={onSearchSuggest}
                onReset={onResetSuggest}
                onSelect={onSelectSuggest}
                options={options}
                value={value && value.value && value.value !== "EMPTY" ? value.value : ""}
                helperIcon
                translations={{
                    no_result_match: p.t("form.fields.autosuggest.no_result_match", {
                        value: value && value.value,
                    }),
                    min_length: p.t("form.fields.autosuggest.min_length", { smart_count: 1 }),
                }}
                disabled={disabled}
                classes={{ popper: classes.suggestPopper }}
                inputClasses={{ inputText: classes.inputText }}
                loading={loading}
                showNoResults={query.length > 0 && options.length === 0 && !loading && !error}
                showMinLength={!value || !value.value || value.value.length < 1}
                inputRef={(el) => inputRef(el)}
                align="right"
                {...formField}
            />
        );
    } else {
        onSearchKeyFunction = (_page) => (event, val) => {
            const _value = val ? val : event.target.value;
            setKeyValue(_value);
            setPage(_page + 1);
            if (_value.length > 0) {
                setLoading(true);
                let account = selectedAccountIdList.length
                    ? `account=${selectedAccountIdList.join("--")}&`
                    : "";
                if (isPending) {
                    cancelFetch();
                }

                fetchSuggestions(`${account}field=${attribute.id}&query=${_value}&p=${_page}`);
            } else if (keySuggestions.length > 0) {
                setKeySuggestions([]);
            }
        };
        searchValue = keyValue;

        if (!disabled) {
            disabled = !value.key || value.key.length === 0;
        }

        valueComponent = (
            <HiInput
                autoComplete="off"
                inputId={`${id}_value`}
                onChange={onChange(`${id}_value`)}
                value={value && value.value && value.value !== "EMPTY" ? value.value : ""}
                disabled={disabled}
                inputRef={(el) => inputRef(el)}
                align="right"
                onReset={props.onResetInput}
                classes={{ inputText: classes.inputText }}
                {...formField}
            />
        );
    }

    let keyOptions = [];
    if (oldKeys.length) {
        keyOptions = oldKeys;
    } else if (keySuggestions.length > 0) {
        keyOptions = keySuggestions;
    } else if (value && value.key) {
        keyOptions = [
            {
                id: value.key,
                label: value.key,
            },
        ];
    } else if (value && typeof value === "string") {
        keyOptions = [
            {
                id: value,
                label: value,
            },
        ];
    }

    return (
        <HiFormControl
            label={formField.label}
            helperText={formField.helperText}
            helperIcon
            error={error}
            errorText={errorText}
        >
            <div
                className={classNames({
                    [classes.root]: !isMobile,
                    [classes.rootMobile]: isMobile,
                })}
            >
                <div
                    className={classNames({
                        [classes.key]: !isMobile,
                        [classes.keyMobile]: isMobile,
                    })}
                >
                    <KeyComponent
                        id={`${id}_key`}
                        name={`${id}_key`}
                        options={keyOptions}
                        value={isMobile ? keyValue : !value ? [] : value.key ? value.key : value}
                        searchable={searchable}
                        hiSelectableListProps={{
                            hideCheckbox: true,
                            disabledItemIdList: disabledItemIdList,
                        }}
                        hiSelectInputProps={{ value: !value ? "" : value.key ? value.key : value }}
                        multiple={false}
                        dynamic={oldKeys.length === 0}
                        onChange={onChangeKeySelect(id)}
                        onSelect={onChangeKeySelect(id)} //Mobile
                        onSearch={searchable ? onSearchKeyFunction(1) : undefined}
                        searchValue={searchValue}
                        onSubmit={onSubmit}
                        translations={{
                            no_result_match: p.t("form.fields.select.no_result_match"),
                            search: p.t("form.fields.select.search"),
                            min_length: p.t("form.fields.autosuggest.min_length", {
                                smart_count: 1,
                            }),
                        }}
                        loading={isPending}
                        showNoResults={
                            keyValue.length > 0 &&
                            keyOptions.length === 0 &&
                            (oldKeys.length > 0 || !loading) &&
                            !error
                        }
                        showMinLength={keyValue.length === 0}
                        onNextPage={searchable ? onSearchKeyFunction(page) : undefined}
                        classes={{
                            root: classNames({ [classes.suggestRoot]: !isMobile }),
                            popper: classNames({ [classes.popperMobile]: isMobile }),
                        }}
                    />
                </div>
                <div className={classNames({ [classes.value]: !isMobile })}>
                    {valueComponent}
                    {id.indexOf("old_") < 0 && (
                        <span className={classes.newCustomDataHelper}>
                            {p.t("form.fields.custom_data.value.helperText")}
                        </span>
                    )}
                </div>
            </div>
        </HiFormControl>
    );
};

CustomDataField.propTypes = {
    attribute: PropTypes.object,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    formField: PropTypes.object,
    handleFormErrors: PropTypes.func,
    id: PropTypes.string,
    inputRef: PropTypes.func,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeKeySelect: PropTypes.func,
    onResetSuggest: PropTypes.func,
    onResetInput: PropTypes.func,
    onSearchSuggest: PropTypes.func,
    onSelectSuggest: PropTypes.func,
    onSubmit: PropTypes.func,
    query: PropTypes.string,
    selectedAccountIdList: PropTypes.array,
    value: PropTypes.object,
};
