import makeStyles from "@mui/styles/makeStyles";

const useCommonStyles = makeStyles((theme: any) => ({
    addButton: {
        margin: "10px 15px",
        textAlign: "left",
    },
    businessIcon: {
        paddingRight: 4,
    },
    card: {
        boxShadow: "0px 1px 5px #0000003D",
        borderRadius: 3,
        textAlign: "start",
    },
    cardButton: {
        display: "flex",
        paddingTop: 8,
        marginLeft: 16,
    },
    cardContent: {
        ...theme.typography.body1,
        padding: `${theme.spacing(6)} !important`,
        textAlign: "left",
        display: "flex",
        flexWrap: "wrap",
        "& > div:last-child": {
            paddingBottom: 0,
        },
    },
    cardContentNoPadding: {
        ...theme.typography.body2,
        padding: "0px !important",
        textAlign: "left",
    },
    endAdornment: {
        padding: "0px 5px",
    },
    formField: {
        marginBottom: theme.spacing(5),
        width: "100%",
        "&  label": {
            maxWidth: "500px",
            whiteSpace: "normal",
        },
        "&  a": {
            color: theme.palette.primary.dark,
        },
    },
    linkMargin: {
        "& a": {
            marginLeft: "3px",
        },
    },
    maskedInput: {
        width: "100%",
        "&  input": {
            padding: "0px 10px",
            height: "23px",
            width: "100%",
        },
    },
    maskedField: {
        height: "100%",
        "&:hover": {
            "&>*:nth-child(1)": {
                color: theme.palette.primary.main,
            },
        },
    },
    maskedInputLabel: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
    },
    formFieldNoPaddingBottom: {
        width: "100%",
    },
    fieldLabel: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
    },
    fullWidthBlockContainer: {
        marginLeft: "calc(50% - 50vw)",
        marginRight: "calc(50% - 50vw)",
        padding: 24,
    },
    fullWidthBlockContent: {
        padding: "0 !important",
    },
    optionalText: {
        width: "100%",
        "&> label": {
            display: "inline-block",
            "&> span": {
                float: "right",
            },
        },
    },
    optionalUploadFieldText: {
        width: "100%",
        "&> label": {
            "&> span": {
                float: "right",
            },
        },
    },
    radioGroup: {
        display: "flex",
        flexDirection: "row",
    },
    stepper: {
        position: "fixed",
        zIndex: 1,
        left: 0,
    },
    subBlockTitleContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 17,
        marginTop: 23,
        height: 24,
    },
    subBlockTitle: {
        ...theme.typography.h3,
    },
    select: {
        width: 67,
    },
    submitButton: {
        width: "100%",
        borderRadius: "4px",
    },
    editContainer: {
        height: "100%",
        margin: "0 auto",
        paddingBottom: 50,
        paddingTop: 5,
        width: 548,
    },
    noticeContent: {
        padding: "95px 0 0 0",
        minHeight: "100%",
        backgroundColor: theme.palette.background1,
        backgroundImage: "initial",
        "&$topbarCollapsed": {
            minHeight: "calc(100% + 64px)",
        },
    },
    notice: {
        "& #notice-content": {
            paddingTop: "156px",
        },
        "& #notice-header": {
            zIndex: 10,
        },
        "& #notice-header > div": {
            height: "64px !important",
        },
    },
    snackbar: {
        "&>div": {
            ...theme.typography.body2,
            color: theme.palette.warning.contrastText,
        },
    },
    link: {
        "& a": {
            color: theme.palette.primary.dark + "!important",
        },
    },
    topbarCollapsed: {},
}));

export default useCommonStyles;
