const eci_out = {
    id: "eci_out",
    data: [
        {
            id: "5",
            label: "ref.transaction.eci_out.value_5",
            smallLabel: "5",
        },
        {
            id: "6",
            label: "ref.transaction.eci_out.value_6",
            smallLabel: "6",
        },
        {
            id: "7",
            label: "ref.transaction.eci_out.value_7",
            smallLabel: "7",
        },
    ],
    translate: true,
};

export default eci_out;
