import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";

const stylesCellText = {
    wrapper: {
        width: "100%",
        height: 17,
        margin: "auto 0",
        display: "flex",
        lineHeight: "17px",
        verticalAlign: "middle",
    },
    leftEllipsisSpan: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        direction: "rtl",
    },
    rightEllipsisSpan: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
    },
    leftEllipsisSpanFullWidth: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        direction: "rtl",
        width: "100%",
    },
    rightEllipsisSpanFullWidth: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
    },
    noEllipsisSpan: {
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "pre",
    },
};

class CellTextClass extends React.Component {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Couleur du text & du background
         */
        color: PropTypes.string,
        /**
         * Ellipsis
         */
        ellipsis: PropTypes.oneOf(["left", "right", "middle", "name", "after-first-word"]),
        /**
         * Label à afficher
         */
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        /**
         * Value à afficher
         */
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    static defaultProps = {
        label: "",
        value: "",
        ellipsis: "right",
    };

    cellText = null;

    constructor(props) {
        super(props);

        this.buildEllipsis = this.buildEllipsis.bind(this);
    }

    /**
     * TODO - Ellipsis (middle, after-first-word)
     */
    componentDidMount() {
        this.buildEllipsis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.label !== this.props.label) {
            this.buildEllipsis();
        }
    }

    buildEllipsis() {
        /**
         * Calcul l'espace disponible dans la cellule en plus du premier mot
         * si il reste de l'espace, définit la largeur du span pour le reste du texte
         */
        if (this.props.ellipsis === "after-first-word" || this.props.ellipsis === "name") {
            if (this.cellText !== null) {
                const [startSpan, endSpan] = this.cellText.getElementsByTagName("span");
                if (typeof startSpan !== "undefined" && typeof endSpan !== "undefined") {
                    const availableEndSpace = this.cellText.offsetWidth - startSpan.offsetWidth - 2;
                    if (availableEndSpace > 0) {
                        endSpan.style.width = `${availableEndSpace}px`;
                    } else {
                        startSpan.style.width = "100%";
                    }
                }
            }
        }

        /**
         * Calcul l'espace pris par les 2 spans (les 2 moitiés du texte),
         * si ils dépassent la largeur de la cellule :
         * - diminue d'autant et proportionnellement chaque span
         * - ajoute l'ellipse à gauche de la deuxième moitié du texte.
         */
        if (this.props.ellipsis === "middle") {
            if (this.cellText !== null) {
                const [startSpan, endSpan] = this.cellText.getElementsByTagName("span");
                if (typeof startSpan !== "undefined" && typeof endSpan !== "undefined") {
                    const availableEndSpace =
                        this.cellText.offsetWidth - startSpan.offsetWidth - endSpan.offsetWidth;
                    if (availableEndSpace < 0) {
                        startSpan.style.width = "50%";
                        endSpan.style.width = "49%";
                    } else {
                        startSpan.style.width = "initial";
                        endSpan.style.width = "initial";
                    }
                }
            }
        }
    }

    render() {
        const { classes, label, ellipsis, color, value } = this.props;

        let start;
        let end;
        let valueElement = "";

        const valueString = label ? label.toString() : value.toString();

        switch (ellipsis) {
            case "name":
            case "after-first-word":
                // Split value in 2 strings around first whitespace
                start = valueString.substr(0, valueString.indexOf(" "));
                end = valueString.substr(valueString.indexOf(" ") + 1);
                if (start === "") {
                    start = end;
                    end = "";
                }
                valueElement = (
                    <div
                        ref={(div) => {
                            this.cellText = div;
                        }}
                    >
                        <span className={classes.rightEllipsisSpan}>{`${start} `}</span>
                        <span className={classes.leftEllipsisSpan}>{end}</span>
                    </div>
                );
                break;

            case "middle":
                // Split value in 2 strings
                start = valueString.substr(0, Math.round(valueString.length / 2));
                end = valueString.substr(Math.round(valueString.length / 2));
                valueElement = (
                    <div
                        ref={(div) => {
                            this.cellText = div;
                        }}
                    >
                        <span className={classes.noEllipsisSpan}>{start}</span>
                        <span className={classes.leftEllipsisSpan}>{end}</span>
                    </div>
                );
                break;

            case "left":
                valueElement = color ? (
                    <HiColoredLabel
                        className={classes.leftEllipsisSpan}
                        label={valueString}
                        color={color}
                        fontSize={13}
                    />
                ) : (
                    <div className={classes.leftEllipsisSpanFullWidth}>{valueString}</div>
                );
                break;

            case "right":
            default:
                valueElement = color ? (
                    <HiColoredLabel
                        className={classes.rightEllipsisSpan}
                        label={valueString}
                        color={color}
                        fontSize={13}
                    />
                ) : (
                    <div className={classes.rightEllipsisSpanFullWidth}>{valueString}</div>
                );
                break;
        }

        return <div className={classes.wrapper}>{valueElement}</div>;
    }
}

export const CellText = withStyles(stylesCellText)(CellTextClass);
