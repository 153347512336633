import React from "react";
import OneClickIcon from "../../components/Icon/OneClickIcon";

const oneClick = {
    id: "one_click",
    data: [
        {
            id: "1",
            label: "ref.transaction.one_click.yes",
            icon: <OneClickIcon />,
            showLabel: true,
        },
        {
            id: "0",
            label: "ref.transaction.one_click.no",
        },
    ],
    translate: true,
};

export default oneClick;
