import { useMemo } from "react";
import classNames from "classnames";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { isMobile } from "react-device-detect";
import { useSearchSelectionState } from "../../services";
import { toUserLocalNumber } from "../../../utils";
import { useSelector } from "react-redux";
import { useP } from "../../../services";

import HiChip from "@hipay/hipay-material-ui/HiChip";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import { SelectionMenuButton } from "./SelectionMenuButton";

import { FavoriteMenu } from "./Favorite";
import { ExportMenu } from "./Export";
import { SettingsMenu } from "./Settings";
import { AnalyticsMenu } from "./AnalyticsBanner";
import { SwitchViewButton } from "./SwitchViewButton";
import { THUMBNAIL_VIEW } from "../../../constants";
import Typography from "@mui/material/Typography";

const useStylesResultsHeader = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 60,
        padding: "0 24px",
        ...theme.typography.h3,
        color: theme.palette.text.primary,
    },
    rootMobile: {
        flexDirection: "column",
        height: "auto",
    },
    subRoot: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        ...theme.typography.body2,
        padding: "5px 0",
        color: theme.palette.text.primary,
    },
    rootSelection: { backgroundColor: lighten(theme.palette.primary.main, 0.76) },
    resultsChip: {
        marginLeft: 8,
        verticalAlign: "bottom",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.main, 0.76),
    },
    titleLeft: {
        textAlign: "left",
        padding: "8px 0",
        height: "100%",
        width: "45%",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    subTitleLeft: {
        textAlign: "left",
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    titleLeftMobile: { width: "100%" },
    titleCenter: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
    },
    titleRight: {
        textAlign: "right",
        height: "100%",
        width: "45%",
    },
    titleRightMobile: {
        position: "absolute",
        right: 8,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: "pointer",
        display: "inline-block",
    },
}));

export const ResultsHeader = (props) => {
    const {
        actions,
        searchResults,
        displayAnalyticsMenu,
        totalHits,
        isWatchingMultipleAccount,
        defaultOrderedKpis,
        onKpiOrderUpdate,
        persistOrderedKpis,
        toggleBanner,
        onSort,
        attributes,
        columns,
        defaultGrid,
        mandatoryColumnId,
        isDisplayRank,
        isDisplaySelector,
        handleColumnsOrderUpdate,
        handleUnlimitedSearch,
        persistOrderedColumns,
        orderedColumns,
        mergeSelectionParam,
        handleSwitchView,
        switchLabel,
        currentView,
        analytics,
        searchParams,
        selectionParam,
        userCanExport,
        selectionMenuProps,
        searchState,
    } = props;
    const { loading } = searchResults;

    const classes = useStylesResultsHeader();
    const p = useP();
    const selectionState = useSearchSelectionState();

    const userSettings = useSelector((state) => state.app.settings.data);

    const selectionSize = useMemo(() => {
        return Object.keys(selectionState).length;
    }, [selectionState]);

    const gaTrack = () => {
        window.gtag("event", "remove two year limit", {
            event_category: "search_settings",
            event_action: "remove_two_year_limit_search_settings",
        });
    };

    const handleClickUnlimitedSearch = () => {
        handleUnlimitedSearch();
        gaTrack();
    };

    return (
        <div
            className={classNames(classes.root, {
                [classes.rootMobile]: isMobile,
                // eslint-disable-next-line no-extra-boolean-cast
                [classes.rootSelection]: !!selectionSize,
            })}
        >
            <div className={classNames(classes.titleLeft, { [classes.titleLeftMobile]: isMobile })}>
                <div>
                    {
                        // eslint-disable-next-line no-extra-boolean-cast
                        !!selectionSize ? (
                            <span id="results-title-selected">
                                {p.t("common.search.selected_results", selectionSize)}
                            </span>
                        ) : (
                            <span>{p.t("common.search.results")}</span>
                        )
                    }
                    {!selectionSize && totalHits !== undefined && (
                        <HiChip
                            id="results-header-chip"
                            classes={{ root: classes.resultsChip }}
                            label={toUserLocalNumber(totalHits, userSettings.language, 0)}
                        />
                    )}
                </div>

                <div>
                    {searchState === "show-limited" && (
                        <div
                            className={classNames(classes.subRoot, {
                                [classes.rootMobile]: isMobile,
                                // eslint-disable-next-line no-extra-boolean-cast
                                [classes.rootSelection]: !!selectionSize,
                            })}
                        >
                            <div
                                className={classNames(classes.subTitleLeft, {
                                    [classes.titleLeftMobile]: isMobile,
                                })}
                            >
                                <Typography variant="body3">
                                    {p.t("common.search.sub_results")}
                                    <div
                                        className={classes.link}
                                        onClick={handleClickUnlimitedSearch}
                                    >
                                        &nbsp;{p.t("common.search.show_all")}
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.titleCenter}>
                {loading ? (
                    <div
                        style={{
                            margin: "16px auto",
                            width: "1px",
                        }}
                    >
                        <HiLoader id="search-header-loader" loading={true} />
                    </div>
                ) : null}
            </div>

            <div
                className={classNames({
                    [classes.titleRight]: !isMobile,
                    [classes.titleRightMobile]: isMobile,
                })}
            >
                {actions.map((action) => {
                    switch (action.key) {
                        case "selection":
                            // eslint-disable-next-line no-extra-boolean-cast
                            if (!!selectionSize) {
                                return (
                                    <SelectionMenuButton key="selection" {...selectionMenuProps} />
                                );
                            }
                            return null;
                        case "favorites":
                            return <FavoriteMenu defaultGrid={defaultGrid} key="favorites" />;
                        case "exports":
                            if (!isMobile && userCanExport) {
                                return (
                                    <ExportMenu
                                        key="exports"
                                        totalHits={totalHits}
                                        orderedColumns={orderedColumns}
                                        searchParams={searchParams}
                                        selectionParam={selectionParam}
                                        mergeSelectionParam={mergeSelectionParam}
                                        formComponent={action.formComponent}
                                        onClickLive={action.onClickLiveExport}
                                        onClickEmail={action.onClickEmailExport}
                                        labels={action.labels}
                                    />
                                );
                            }
                            return null;
                        case "analytics":
                            if (displayAnalyticsMenu) {
                                return (
                                    <AnalyticsMenu
                                        key="analytics"
                                        toggleBanner={toggleBanner}
                                        defaultKpis={defaultOrderedKpis}
                                        onKpiOrderUpdate={onKpiOrderUpdate}
                                        persistOrderedKpis={persistOrderedKpis}
                                        isWatchingMultipleAccount={isWatchingMultipleAccount}
                                        {...analytics}
                                    />
                                );
                            }
                            return null;
                        case "switch_view":
                            // eslint-disable-next-line no-extra-boolean-cast
                            if (!!selectionSize) {
                                return null;
                            }
                            return (
                                <SwitchViewButton
                                    key="switch_view"
                                    handleSwitchView={handleSwitchView}
                                    label={switchLabel}
                                    currentView={currentView}
                                />
                            );
                        case "organize":
                            if (currentView === THUMBNAIL_VIEW) {
                                return null;
                            }
                            return (
                                <SettingsMenu
                                    key="organize"
                                    onSort={onSort}
                                    attributes={attributes}
                                    columns={columns}
                                    defaultGrid={defaultGrid}
                                    orderedColumns={orderedColumns}
                                    mandatoryColumnId={mandatoryColumnId}
                                    isDisplayRank={isDisplayRank}
                                    isDisplaySelector={isDisplaySelector}
                                    handleColumnsOrderUpdate={handleColumnsOrderUpdate}
                                    persistOrderedColumns={persistOrderedColumns}
                                />
                            );
                        default:
                            if (action.menuItem) {
                                return action.menuItem;
                            }
                            return null;
                    }
                })}
            </div>
        </div>
    );
};
