import {
    getFraudActionLabel,
    getFraudRuleFamilyCode,
    getFraudRuleFamilyLabel,
    getFraudRuleLabel,
} from "../utils";

const sentinelRules = {
    id: "sentinel_rules",
    endpoint: ({ sentinel_profile }) => {
        if (
            (Array.isArray(sentinel_profile) && sentinel_profile.length > 0) ||
            (!Array.isArray(sentinel_profile) && !!sentinel_profile)
        ) {
            return `/sentinel/profiles/${sentinel_profile}/rules`;
        }
        return null;
    },
    organizeFunc: (rules) => {
        // sort by family
        rules.sort((a, b) => a.family <= b.family);
        // build hierarchic fraud rules list group by families
        return (
            Object.values(
                rules.reduce((hierarchicRules, rule) => {
                    const ruleFamilyCode = getFraudRuleFamilyCode(rule);
                    let itemOrder = null;
                    if ("hourStart" in rule) {
                        switch (rule.day) {
                            case "MON":
                                itemOrder = "1";
                                break;
                            case "TUE":
                                itemOrder = "2";
                                break;
                            case "WED":
                                itemOrder = "3";
                                break;
                            case "THU":
                                itemOrder = "4";
                                break;
                            case "FRI":
                                itemOrder = "5";
                                break;
                            case "SAT":
                                itemOrder = "6";
                                break;
                            case "SUN":
                                itemOrder = "7";
                                break;
                            default:
                                itemOrder = "8";
                        }
                        let hStart = rule.hourStart;
                        if (hStart < 10) {
                            hStart = "0" + hStart;
                        }
                        itemOrder = itemOrder + hStart;
                    }
                    if (ruleFamilyCode in hierarchicRules) {
                        if (rule.action !== "NONE(0)") {
                            hierarchicRules[ruleFamilyCode].children.push({
                                id: rule.ruleid.toString(),
                                label: (p) => getFraudRuleLabel(rule, p),
                                info: (p) => getFraudActionLabel(rule.action, p),
                                itemOrder: itemOrder
                                    ? itemOrder
                                    : (p) => getFraudRuleLabel(rule, p),
                            });
                        }
                    } else {
                        hierarchicRules[ruleFamilyCode] = {
                            id: ruleFamilyCode.toString(),
                            label: (p) => getFraudRuleFamilyLabel(rule, p),
                            children: [],
                        };
                        if (rule.action !== "NONE(0)") {
                            hierarchicRules[ruleFamilyCode].children.push({
                                id: rule.ruleid.toString(),
                                label: (p) => getFraudRuleLabel(rule, p),
                                info: (p) => getFraudActionLabel(rule.action, p),
                                itemOrder: itemOrder
                                    ? itemOrder
                                    : (p) => getFraudRuleLabel(rule, p),
                            });
                        }
                    }
                    return hierarchicRules;
                }, {})
            )
                .sort((a, b) => (a.label === b.label ? 0 : a.label < b.label ? -1 : 1))
                //Filter empty rules
                .filter((item) => {
                    return item.children.length ? true : false;
                })
                .map((item, k) => {
                    item.children.sort((a, b) =>
                        a.itemOrder === b.itemOrder ? 0 : a.itemOrder < b.itemOrder ? -1 : 1
                    );
                    return item;
                })
        );
    },
    translate: true,
};

export default sentinelRules;
