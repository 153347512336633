import { translate } from "../../../../../services/i18n/translate";

import moment from "moment-timezone";
import { formatRate } from "@hipay/hipay-material-ui/utils/helpers";
import { toUserLocalNumber } from "../../../../../utils/i18n";

import {
    TYPE_AMOUNT,
    TYPE_LIST_OF_DATES,
    TYPE_RATE,
    TYPE_DURATION,
    TYPE_TEXT,
} from "../../../../constants/kpiTypes";

import { KpiKeyValue, KpiList } from "../../../../components/Search/AnalyticsBanner/Kpi/";

const KpiBuilder = (props) => {
    const { p, module, data, userSettings } = props;

    let value;
    let values;
    let label = data.label;
    let data_to_pass;
    let addons;
    let str_value = "";

    let handleClick = null;

    if (data.onClick !== null) {
        handleClick = (e) => {
            data.onClick(e, data);
        };
    }

    if (data.default === true && data.displayOptions.default) {
        value = data.displayOptions.default.value(
            {
                ...data.value,
                userSettings: { ...userSettings },
            },
            p
        );
        if (data.displayOptions.displayDate) {
            addons = data.displayOptions.default.date({ ...data.date }, p);
        }
        data_to_pass = {
            label,
            value,
            addons,
            str_value,
            onClick: handleClick,
        };
        return <KpiKeyValue {...data_to_pass} />;
    }

    switch (data.displayType) {
        case TYPE_AMOUNT:
            value = toUserLocalNumber(
                Number(data.value),
                userSettings.language,
                data.precision,
                data.currency,
                "s"
            );
            str_value = toUserLocalNumber(
                Number(data.value),
                userSettings.language,
                data.precision,
                data.currency,
                null
            );
            if (data.displayOptions.displayDate && data.date) {
                let mdate = moment(data.date);
                let date_str = mdate.format(userSettings.dateFormatShort);
                addons = p.t(`modules.${module}.kpi.${data.id}.date`, { date: date_str });
            }
            data_to_pass = {
                label,
                value,
                addons,
                str_value,
                onClick: handleClick,
            };
            return <KpiKeyValue {...data_to_pass} />;
        case TYPE_LIST_OF_DATES:
            values = data.value
                .filter((day) => day.value === true)
                .map((day) => {
                    return moment().isoWeekday(parseInt(day.id, 10)).format("dddd");
                });
            data_to_pass = {
                label,
                values,
                str_value,
                onClick: handleClick,
            };
            return <KpiList {...data_to_pass} />;
        case TYPE_RATE:
            value = formatRate(data.value, "l", userSettings.language.slice(0, 2));
            if (data.displayOptions.displayDate && data.date) {
                let mdate = moment(data.date);
                let date_str = mdate.format(userSettings.dateFormatShort);
                addons = p.t(`modules.${module}.kpi.${data.id}.date`, { date: date_str });
            }
            data_to_pass = {
                label,
                value,
                addons,
                str_value,
                onClick: handleClick,
            };
            return <KpiKeyValue {...data_to_pass} />;
        case TYPE_DURATION:
            value = p.t(`modules.${module}.kpi.${data.id}.date_default`, {
                y: data.value.y,
                m: data.value.m,
                d: data.value.d,
            });
            data_to_pass = {
                label,
                value,
                str_value,
                onClick: handleClick,
            };
            return <KpiKeyValue {...data_to_pass} />;
        case TYPE_TEXT:
            if (!!data.displayOptions.value && typeof data.displayOptions.value === "function") {
                value = data.displayOptions.value(
                    {
                        ...data.value,
                        userSettings: { ...userSettings },
                    },
                    p
                );
            }
            data_to_pass = {
                label,
                value,
                str_value,
            };
            return <KpiKeyValue {...data_to_pass} />;
        default:
            data_to_pass = {
                ...data,
                label,
                str_value,
                onClick: handleClick,
            };
            return <KpiKeyValue {...data_to_pass} />;
    }
};

export default translate(KpiBuilder);
