import { formFieldTypes, displayTypes } from "@console/core";

const attributes = {
    mkp_vendor_name: {
        id: "mkp_vendor_name",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 1,
    },
    status: {
        id: "status",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "hipay_status",
        order: 2,
    },
    payment_blocked: {
        id: "payment_blocked",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "boolean",
        order: 3,
    },
    enabled: {
        id: "enabled",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "boolean",
        order: 4,
    },
    identified: {
        id: "identified",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "boolean",
        order: 5,
    },
    bank_information_status: {
        id: "bank_information_status",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        reference: "bank_information_status",
        order: 6,
    },
    email: {
        id: "email",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 7,
    },
    vendor_type: {
        id: "vendor_type",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        reference: "vendor_type",
        order: 8,
    },
    date_updated: {
        id: "date_updated",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            exclude: false,
            displayTime: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        order: 9,
    },
    gender: {
        id: "gender",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        reference: "gender",
        order: 10,
    },
    firstname: {
        id: "firstname",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 11,
    },
    lastname: {
        id: "lastname",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 12,
    },
    street: {
        id: "street",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 13,
    },
    zipcode: {
        id: "zipcode",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 14,
    },
    city: {
        id: "city",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 15,
    },
    phone: {
        id: "phone",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 16,
    },
    bank_country: {
        id: "bank_country",
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
            ellipsis: "middle",
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        reference: "countries",
        order: 17,
    },
    bank_name: {
        id: "bank_name",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 18,
    },
    bank_address: {
        id: "bank_address",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 19,
    },
    bank_zip_code: {
        id: "bank_zip_code",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 20,
    },
    bank_city: {
        id: "bank_city",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 21,
    },
    iban: {
        id: "iban",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            lookup: true,
            exclude: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 22,
    },
    swift: {
        id: "swift",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 23,
    },
    company_name: {
        id: "company_name",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 24,
    },
    country: {
        id: "country",
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
            ellipsis: "middle",
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        reference: "countries",
        order: 25,
    },
    currency: {
        id: "currency",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        reference: "currencies",
        order: 26,
    },
    hipay_account_id: {
        id: "hipay_account_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 27,
    },
    mkp_vendor_id: {
        id: "mkp_vendor_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 28,
    },
    vatnumber: {
        id: "vatnumber",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 29,
    },
};

export default attributes;
