const references = {
    payment_orders_type: {
        id: "payment_orders_type",
        data: [
            {
                id: "COMMISSIONS",
                label: "ref.mkp_payment_orders.payment_orders_type.COMMISSIONS",
            },
            {
                id: "SALES",
                label: "ref.mkp_payment_orders.payment_orders_type.SALES",
            },
        ],
        translate: true,
    },
    payment_orders_status: {
        id: "payment_orders_status",
        data: [
            {
                id: "EXCLUDED",
                label: "ref.mkp_payment_orders.payment_orders_status.EXCLUDED",
                color: "neutral",
            },
            {
                id: "FUNCTIONAL_ERROR",
                label: "ref.mkp_payment_orders.payment_orders_status.FUNCTIONAL_ERROR",
                color: "error",
            },
            {
                id: "INITIALIZED",
                label: "ref.mkp_payment_orders.payment_orders_status.INITIALIZED",
                color: "secondary",
            },
            {
                id: "TRANSFER_FAILED",
                label: "ref.mkp_payment_orders.payment_orders_status.TRANSFER_FAILED",
                color: "error",
            },
            {
                id: "TRANSFER_REQUESTED",
                label: "ref.mkp_payment_orders.payment_orders_status.TRANSFER_REQUESTED",
                color: "primary",
            },
            {
                id: "TRANSFER_SUCCESS",
                label: "ref.mkp_payment_orders.payment_orders_status.TRANSFER_SUCCESS",
                color: "success",
            },
            {
                id: "TRANSFER_TECHNICAL_ERROR",
                label: "ref.mkp_payment_orders.payment_orders_status.TRANSFER_TECHNICAL_ERROR",
                color: "error",
            },
            {
                id: "WITHDRAWAL_FAILED",
                label: "ref.mkp_payment_orders.payment_orders_status.WITHDRAWAL_FAILED",
                color: "error",
            },
            {
                id: "WITHDRAWAL_REQUESTED",
                label: "ref.mkp_payment_orders.payment_orders_status.WITHDRAWAL_REQUESTED",
                color: "secondary",
            },
            {
                id: "WITHDRAWAL_SUCCESS",
                label: "ref.mkp_payment_orders.payment_orders_status.WITHDRAWAL_SUCCESS",
                color: "success",
            },
            {
                id: "WITHDRAWAL_TECHNICAL_ERROR",
                label: "ref.mkp_payment_orders.payment_orders_status.WITHDRAWAL_TECHNICAL_ERROR",
                color: "error",
            },
        ],
        translate: true,
    },
    operations_status: {
        id: "operations_status",
        data: [
            {
                id: "PENDING",
                label: "ref.mkp_payment_orders.operations_status.PENDING",
                color: "secondary",
            },
            {
                id: "ACCEPTED",
                label: "ref.mkp_payment_orders.operations_status.ACCEPTED",
                color: "success",
            },
            {
                id: "REFUSED",
                label: "ref.mkp_payment_orders.operations_status.REFUSED",
                color: "error",
            },
            {
                id: "REJECTED",
                label: "ref.mkp_payment_orders.operations_status.REJECTED",
                color: "error",
            },
        ],
        translate: true,
    },
};

export default references;
