import PropTypes from "prop-types";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

import { useP } from "../../../../services/i18n";

const useStylesCellBoolean = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: 19,
        lineHeight: "19px",
        margin: "auto 0",
        verticalAlign: "middle",
        width: "100%",
    },
    coloredLabel: {
        width: 50,
        textAlign: "center",
    },
    nowrap: { whiteSpace: "nowrap" },
    marginLeft: { marginLeft: 4 },
}));

/**
 * CellBoolean
 * display boolean value with YES/NO label
 */
export function CellBoolean(props) {
    const p = useP();
    const { icon, value, color, className, style = {}, showLabel, label } = props;

    const classes = useStylesCellBoolean(props);

    let _fullLabel = label;
    if (!_fullLabel || typeof _fullLabel === "undefined") {
        // Get YES/NO label
        if (typeof value !== "undefined" && (!value || value === "0" || value === "false")) {
            _fullLabel = p.t("common.search.table.cell.boolean.no");
        } else if (typeof value !== "undefined") {
            _fullLabel = p.t("common.search.table.cell.boolean.yes");
        }
    }

    let itemIcon;
    if (icon) {
        const iconProps = {
            style: {
                color: color,
                ...style,
            },
        };
        if (typeof icon === "string") {
            itemIcon = <HiIcon icon={icon} {...iconProps} />;
        } else if (typeof icon === "object") {
            itemIcon = { ...icon };
            itemIcon.props = {
                ...itemIcon.props,
                ...iconProps,
            };
        }
    }

    let _activeValue = value;
    if (typeof _activeValue === "string") {
        // value => 0/1
        _activeValue = parseInt(value, 10);
    }

    return (
        <div className={classNames(classes.wrapper, className)} title={_fullLabel}>
            {!!itemIcon && itemIcon}
            {showLabel && <span className={classes.marginLeft}>{_fullLabel}</span>}
            {!itemIcon && (
                <HiColoredLabel
                    className={classes.coloredLabel}
                    active={!!_activeValue}
                    color={color}
                    label={_fullLabel}
                    fontSize={13}
                />
            )}
        </div>
    );
}

CellBoolean.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    view: PropTypes.oneOf(["l", "m", "s"]),
    showLabel: PropTypes.bool,
};

CellBoolean.defaultProps = {
    color: "info",
    view: "m",
    showLabel: false,
};
