import makeStyles from "@mui/styles/makeStyles";

const useCommonStyles = makeStyles(() => ({
    root: {
        position: "initial",
        "& [class^=HmuiHiDatePicker-root]": {
            position: "initial",
        },
        "& .DayPickerInput > div:nth-child(2)": {
            width: 400,
        },
        margin: "0 0 10px 0",
    },
}));

export default useCommonStyles;
