const sddDisplayPage = {
    id: "sdd_display_page",
    data: [
        {
            id: "0",
            label: "ref.transaction.sdd_display_page.value_0",
        },
        {
            id: "1",
            label: "ref.transaction.sdd_display_page.value_1",
        },
    ],
    translate: true,
};

export default sddDisplayPage;
