import attributes from "./attributes";
import { Grid } from "@console/types";

/**
 * Grid
 *
 * L'état initial du tableau de résultat.
 * / ! \ Penser à mettre une view par défaut pour ne pas casser
 *       l'affichage du chip 'changedColumns' dans les résultats de recherche
 */

const grid: Grid[] = [
    {
        id: "339",
        colId: attributes["title_long"].id,
        displayed: true,
        width: 400,
    },
    {
        id: "340",
        colId: attributes["type"].id,
        displayed: true,
        view: "l",
    },
    {
        id: "341",
        colId: attributes["date_created"].id,
        displayed: true,
        view: "l",
    },
];

export default grid;

/**
 * Default sort params
 */
export const defaultSortParams = {
    order_by: "date_created",
    direction: "desc",
};
