import { useCallback, useEffect, useRef } from "react";
import pure from "recompose/pure";
import classNames from "classnames";
import { getDefaultWidth, ALIGN_RIGHT_TYPES } from "../../../constants/grid";
import { isMobile } from "react-device-detect";

const CellLayoutClass = ({
    colId,
    children,
    displayType,
    index,
    lookupColumnIdList,
    isQSearchRow,
    rowId,
    view,
    isDisplaySelector,
    isDisplayRank,
    isMultiRow = false,
    onClick,
    pinActive = false,
    onContextMenu,
    width,
}) => {
    const ref = useRef();

    const handleClick = useCallback(
        (e) => {
            return isMultiRow && onClick(e);
        },
        [isMultiRow, onClick]
    );

    // reinit cell sticky left visual effect
    useEffect(() => {
        if (index !== 0) {
            ref.current.style.zIndex = 10;
            ref.current.style.boxShadow = "";
        }
    }, [ref, index]);

    return (
        <div
            ref={ref}
            key={`${rowId}-cell-${colId}`}
            name={`cell-${colId}`}
            className={classNames("cell", {
                "sticky-with-selector-and-rank":
                    !isMobile && index === 0 && isDisplaySelector && isDisplayRank,
                "sticky-with-rank": !isMobile && index === 0 && !isDisplaySelector && isDisplayRank,
                "sticky-cell-with-selector":
                    !isMobile && index === 0 && isDisplaySelector && !isDisplayRank,
                "pin-cell": isMultiRow && !pinActive,
                "pin-cell-on-active": isMultiRow && pinActive,
                "sticky-left": !isMobile && index === 0,
                sticky: !isMobile && index === 0 && !isDisplaySelector && !isDisplayRank,
                "align-right": ALIGN_RIGHT_TYPES.includes(displayType),
            })}
            onClick={handleClick}
            style={{
                width: width || getDefaultWidth(displayType, view),
                minWidth: width || getDefaultWidth(displayType, view),
                zIndex: 10,
            }}
            onContextMenu={onContextMenu ? onContextMenu : undefined}
        >
            {lookupColumnIdList.includes(colId) && !isQSearchRow && (
                <div className="lookup">
                    <div className="lookup-background" />
                </div>
            )}
            {isQSearchRow && (
                <div className="qSearch-match">
                    <div className="qSearch-match-background" />
                </div>
            )}
            {children}
        </div>
    );
};

export const CellLayout = pure(CellLayoutClass);
