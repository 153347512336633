import { formFieldTypes, displayTypes } from "@console/core";

export default {
    account: {
        id: "account",
        formFieldType: formFieldTypes.FORM_FIELD_ACCOUNT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        jsonApiConf: { valuePath: "account.accountId" },
    },
    account_id: {
        id: "account_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        jsonApiConf: { valuePath: "account.publicRef" },
    },
    account_name: {
        id: "account_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        jsonApiConf: {
            valuePath: "account.name",
            additional: [
                {
                    id: "color",
                    valuePath: "account.color",
                },
                {
                    id: "publicRef",
                    valuePath: "account.publicRef",
                },
                {
                    id: "id",
                    valuePath: "account.accountId",
                },
            ],
        },
    },
    business_id: {
        id: "business_id",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        jsonApiConf: { valuePath: "account.business.businessId" },
    },
    business_name: {
        id: "business_name",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        jsonApiConf: { valuePath: "account.business.businessName" },
    },
    due_date: {
        id: "due_date",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayOptions: { exclude: null },
        jsonApiConf: { valuePath: "dueDate" },
    },
    currency: {
        id: "currency",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            sort: true,
            restrictedBySelectedAccountList: true,
            hasEmptyOption: false,
            noData: "attributes.invoice.currency.noData",
        },
        jsonApiConf: { valuePath: "currency" },
        reference: "invoice_currencies",
    },
    exchange_currency: {
        id: "exchange_currency",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        jsonApiConf: { valuePath: "exchangeCurrency" },
        reference: "exchange_currencies",
    },
    exchange_gross_amount: {
        id: "exchange_gross_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "exchangeGrossAmount",
            additional: [
                {
                    id: "currency",
                    valuePath: "exchangeCurrency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    exchange_net_amount: {
        id: "exchange_net_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "exchangeNetAmount",
            additional: [
                {
                    id: "currency",
                    valuePath: "exchangeCurrency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    exchange_rate: {
        id: "exchange_rate",
        displayType: displayTypes.DISPLAY_NUMERIC,
        formFieldOptions: {},
        displayOptions: { exclude: false },
        jsonApiConf: { valuePath: "exchangeRate" },
    },
    exchange_vat: {
        id: "exchange_vat",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "exchangeVat",
            additional: [
                {
                    id: "currency",
                    valuePath: "exchangeCurrency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    gross_amount: {
        id: "gross_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "grossAmount",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    invoice_id: {
        id: "invoice_id",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        jsonApiConf: { valuePath: "invId" },
    },
    issue_date: {
        id: "issue_date",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayOptions: { exclude: null },
        jsonApiConf: { valuePath: "issueDate" },
    },
    month: {
        id: "month",
        displayType: displayTypes.DISPLAY_MONTH,
        formFieldType: formFieldTypes.FORM_FIELD_MONTH,
        formFieldOptions: {},
        displayOptions: { exclude: false },
        jsonApiConf: { valuePath: "startPeriod" },
    },
    net_amount: {
        id: "net_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "netAmount",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    number: {
        id: "number",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        jsonApiConf: { valuePath: "number" },
    },
    payment_date: {
        id: "payment_date",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        jsonApiConf: { valuePath: "paymentDate" },
    },
    prepaid_amount: {
        id: "prepaid_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "prepaidAmount",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    status: {
        id: "status",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {},
        jsonApiConf: { valuePath: "status" },
        reference: "invoice_statuses",
    },
    vat_amount: {
        id: "vat_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        formFieldOptions: {},
        displayOptions: {
            precision: 2,
            amountStats: true,
        },
        jsonApiConf: {
            valuePath: "vatAmount",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 2,
                },
            ],
        },
    },
    start_period: {
        id: "start_period",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        jsonApiConf: { valuePath: "startPeriod" },
    },
    end_period: {
        id: "end_period",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        jsonApiConf: { valuePath: "endPeriod" },
    },
};
