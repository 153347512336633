import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

const stylesCellAddress = () => ({
    wrapper: {
        width: "100%",
        height: 17,
        margin: "auto 0",
        display: "flex",
        lineHeight: "17px",
        verticalAlign: "middle",
    },
    rightEllipsisSpan: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        width: "100%",
    },
});

/**
 * Cette cellule permet d'afficher une adresse ou le nom d'un lieu
 * - si le nom du lieu est défini, on affiche le lieu et la ville
 * - sinon on affiche la ville et le pays
 * - tous les champs définis sont affichés dans le tooltip
 */
class CellAddressClass extends React.PureComponent {
    static propTypes = {
        /**
         * Ville
         */
        city: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Pays
         */
        country: PropTypes.string,
        /**
         * Code ISO du pays
         */
        isoCountry: PropTypes.string,
        /**
         * Nom du lieu
         */
        name: PropTypes.string,
        /**
         * Code postal
         */
        postalCode: PropTypes.string,
        /**
         * Numéro et nom de la rue
         */
        streetAddress: PropTypes.string,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    static defaultProps = { view: "l" };

    render() {
        const {
            classes,
            streetAddress,
            name,
            city,
            postalCode,
            view,
            country,
            isoCountry,
            title = "",
            className,
        } = this.props;

        const labelItems = (view === "l" ? [name, city, country] : [name, city, isoCountry]).filter(
            (item) => item !== undefined
        );
        const label = labelItems && labelItems.length > 0 ? labelItems.join(", ") : "";

        let _title = "";
        if (name) {
            _title += `${name}\n`;
        }
        if (streetAddress) {
            _title += `${streetAddress}\n`;
        }
        if (city && postalCode) {
            _title += `${city}, ${postalCode}\n`;
        }
        if (city && !postalCode) {
            _title += `${city}\n`;
        }
        if (postalCode && !city) {
            _title += `${postalCode}\n`;
        }
        if (country && isoCountry) {
            _title += `${country}, ${isoCountry}`;
        }
        if (country && !isoCountry) {
            _title += country;
        }
        if (isoCountry && !country) {
            _title += isoCountry;
        }

        const isTitle = title ? title : _title;

        return (
            <div
                className={classNames(classes.wrapper, className)}
                title={view === "s" ? isTitle : title ? title : undefined}
            >
                <div className={classes.rightEllipsisSpan}>{label}</div>
            </div>
        );
    }
}

export const CellAddress = withStyles(stylesCellAddress, { name: "HmuiCellAddress" })(
    CellAddressClass
);
