import PropTypes from "prop-types";
import classNames from "classnames";
import WindowClose from "mdi-material-ui/WindowClose";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { Title } from "../../app/components/common/Title";
import { useFullscreen } from "../../hooks/useFullscreen";
import { useEventListener } from "../../hooks/useEventListener";
import { useP } from "../i18n";
import makeStyles from "@mui/styles/makeStyles";
import { useHeaderHeight } from "../../hooks/useHeaderHeight";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        width: "100%",
        height: ({ headerHeight }) => `calc(100% - ${headerHeight}px)`,
        zIndex: 20,
        overflowY: "auto",
        overflowX: "hidden",
        top: ({ headerHeight }) => headerHeight,
        backgroundColor: theme.palette.background1,
    },
    fromTopbar: { backgroundColor: theme.palette.background3 },
    fullscreen: { height: "100%" },
    header: {
        width: "100%",
        backgroundColor: theme.palette.background1,
        zIndex: 2,
    },
    title: {
        textAlign: "center",
        paddingTop: 22,
        ...theme.typography.h3,
    },
    buttons: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(3),
        zIndex: 20,
    },
    separator: {
        height: 1,
        width: 50,
        margin: "0 auto",
        backgroundColor: theme.palette.common.lightBlack,
    },
    iconSize: { fontSize: 24 },
    goBackButton: {
        position: "absolute",
        top: 8,
        left: 12,
        zIndex: 20,
    },
}));

/**
 * Classic Page component
 * used as page layout for accountSelector, changeDateDialog, ...
 */
export function PageLayout(props) {
    const { children, onClose, title, actions, goBack, fromTopbar } = props;

    const headerHeight = useHeaderHeight();
    const classes = useStyles({
        ...props,
        headerHeight,
    });
    const p = useP();
    const { isFullscreen } = useFullscreen();

    const handleKeyDown = useEventListener("keydown", (event) => {
        if (event.key === "Escape") {
            onClose();
        }
    });

    return (
        <div
            className={classNames(classes.root, {
                [classes.fullscreen]: isFullscreen,
                [classes.fromTopbar]: fromTopbar,
            })}
            id="inner-page-container"
            onKeyDown={handleKeyDown}
        >
            {goBack && (
                <div key="go-back-div" className={classes.goBackButton}>
                    <HiIconButton
                        id="goback-innerpage"
                        onClick={goBack}
                        title={p.t("button.goback")}
                    >
                        <ArrowLeft className={classes.iconSize} />
                    </HiIconButton>
                </div>
            )}
            <Title
                actions={[
                    actions?.map((action) => (
                        <HiIconButton
                            key={action.id}
                            id={action.id}
                            title={action.title}
                            onClick={action.onClick}
                        >
                            {typeof action.icon === "string" ? (
                                <HiIcon icon={action.icon} color="neutral" size={24} />
                            ) : (
                                action.icon
                            )}
                        </HiIconButton>
                    )),
                    <HiIconButton
                        key="close-innerpage"
                        id="close-innerpage"
                        onClick={onClose}
                        title={p.t("button.close")}
                    >
                        <WindowClose className={classes.iconSize} />
                    </HiIconButton>,
                ]}
                value={title}
                withLine
                level={1}
                fromTopbar={fromTopbar}
            />
            <div>{children}</div>
        </div>
    );
}

PageLayout.propTypes = {
    children: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

PageLayout.defaultProps = {};
