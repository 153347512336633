import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
    root: {
        width: 160,
        height: 120,
        color: "white",
        border: "#fff 1px solid",
        borderRadius: 3,
        textAlign: "center",
        margin: "auto",
    },
    header: {
        display: "flex",
        width: "100%",
        height: 60,
        fontSize: 15,
    },
    hText: {
        margin: "auto",
        padding: `0 ${theme.spacing(1)}`,
    },
    content: {
        display: "flex",
        width: "100%",
        height: 60,
        padding: 10,
    },
    clickable: {
        cursor: "pointer",
        "&:hover": { boxShadow: "1px 1px 8px 2px #4846a1" },
    },
});

const KpiTemplate = (props) => {
    const { classes, header, children, onClick = null } = props;

    return (
        <div
            className={classNames(classes.root, { [classes.clickable]: onClick !== null })}
            onClick={onClick}
        >
            <div className={classes.header}>
                <div className={classes.hText}>{header}</div>
            </div>
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default withStyles(styles)(KpiTemplate);
