import { useCallback } from "react";
import PropTypes from "prop-types";
import { multiReplaceInUrlQuery, addUrlProps } from "react-url-query";
import makeStyles from "@mui/styles/makeStyles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FilterIcon from "mdi-material-ui/Filter";
import { useP } from "../../../services/i18n";
import { useSearchSelection } from "../../services/SearchSelection";
import { buildSelectionParam } from "../../../utils/urls";

const useStylesSelectionMenu = makeStyles((theme) => ({
    itemIcon: { minWidth: "inherit" },
    primary: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...theme.typography.body2,
    },
}));

function SelectionMenu(props) {
    const { onClose, url, items, ...menuProps } = props;

    const classes = useStylesSelectionMenu();

    const p = useP();

    const [searchSelectionMap, { reset: resetSelection }] = useSearchSelection();

    const handleFilterMenuItemClick = useCallback(() => {
        const selectionParam = buildSelectionParam(Object.keys(searchSelectionMap));

        multiReplaceInUrlQuery({
            ...selectionParam,
            inSearch: true,
            fromRedirect: undefined,
            noSubmit: undefined,
        });

        onClose();
        resetSelection();
    }, [searchSelectionMap, resetSelection, url, onClose]);

    const handleExtraItemsClick = (menuItem) => (event) => {
        menuItem.onClick(event);
        onClose();
    };

    return (
        <Menu id="selection-menu" onClose={onClose} {...menuProps}>
            <MenuItem id="selection-menu-filter" onClick={handleFilterMenuItemClick}>
                <ListItemIcon className={classes.itemIcon} sx={{ color: "neutral.main" }}>
                    <FilterIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.primary }}
                    primary={p.t("common.search.selection.menuItems.filter")}
                />
            </MenuItem>
            {!!items &&
                items.length &&
                items.map((menuItem) => {
                    return (
                        <MenuItem
                            key={menuItem.id}
                            id={menuItem.id}
                            onClick={handleExtraItemsClick(menuItem)}
                        >
                            {menuItem.content}
                        </MenuItem>
                    );
                })}
        </Menu>
    );
}

SelectionMenu.propTypes = {
    onClose: PropTypes.func.isRequired,
    urlParams: PropTypes.objectOf(PropTypes.string),
};

function mapUrlToProps(url) {
    return { url };
}

export default addUrlProps({ mapUrlToProps })(SelectionMenu);
