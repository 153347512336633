import { ApiProvider } from "./api";
import { ApiContext } from "./api-context";
import { useInheritContext, withInheritContext } from "../ContextForwarder";

const useApi = () => useInheritContext("api", ApiContext);
const withApi = (WrappedComponent) => withInheritContext(WrappedComponent, "api", ApiContext);

export { ApiProvider, useApi, withApi };

export * from "./builder";
