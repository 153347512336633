import PropTypes from "prop-types";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

const useStylesCellScore = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 19,
        lineHeight: "19px",
        margin: "auto 0",
        verticalAlign: "middle",
    },
    smartDecision: {
        marginLeft: 2,
        display: "inline-block",
        minWidth: 18,
    },
    nowrap: { whiteSpace: "nowrap" },
}));

/**
 * CellStore
 * display colored number with sign (+/-)
 */
export function CellScore(props) {
    const { value, active, color, className } = props;

    const classes = useStylesCellScore(props);

    return (
        <div className={classNames(classes.wrapper, className)}>
            <HiColoredLabel
                active={active}
                classes={{ root: classes.label }}
                color={color}
                label={value > 0 ? `+${value}` : `${value}`}
                fontSize={13}
            />
        </div>
    );
}

CellScore.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.number,
    active: PropTypes.bool,
};

CellScore.defaultProps = {
    active: false,
    color: "neutral",
};
