import React from "react";
import RecurringPaymentIcon from "../../components/Icon/RecurringPaymentIcon";

const recurringPayment = {
    id: "recurring_payment",
    data: [
        {
            id: "1",
            label: "modules.transaction.attributes.boolean.values.yes",
            icon: <RecurringPaymentIcon />,
            showLabel: true,
        },
        {
            id: "0",
            label: "modules.transaction.attributes.boolean.values.no",
        },
    ],
    translate: true,
};

export default recurringPayment;
