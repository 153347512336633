import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import moment from "moment-timezone";
import { useUser } from "../../../../services/user";
import { useApi } from "../../../../services/api";
import HiSelectField from "@hipay/hipay-material-ui/HiSelect/HiSelectField";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { Title } from "../../common/Title";
import { toUserLocalNumber } from "../../../../utils/i18n";
import { availableLanguages } from "../../../../constants";
import { updateSettings } from "../../../actions/userActions";

import makeStyles from "@mui/styles/makeStyles";
import { useP, useLocale, useLexicon } from "../../../../services/i18n";
import { lexicons } from "../../../../common/constants/lexicons";

const useStylesPreference = makeStyles((theme) => ({
    root: {
        textAlign: "left",
        margin: "auto",
        maxWidth: 500,
        padding: 0,
    },
    loader: {
        width: 13,
        margin: "auto",
    },
    title: {
        paddingTop: 20,
        marginBottom: 16,
        textAlign: "center",
    },
    formContainer: { padding: "16px 8px 0 8px" },
    button: {
        minWidth: 250,
        margin: "auto",
        marginTop: 40,
        display: "block",
    },
    field: { marginBottom: 24 },
    footerDiv: {
        height: "100%",
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: -1,
        backgroundColor: theme.palette.background1,
    },
    errorDiv: {
        ...theme.typography.subtitle2,
        backgroundColor: theme.palette.error.main,
        color: "#FFFFFF",
        padding: "16px 8px 16px 32px",
        verticalAlign: "middle",
        borderRadius: 2,
        position: "relative",
        marginBottom: 5,
    },
    errorDivIcon: {
        position: "absolute",
        left: 5,
        top: 10,
    },
}));

const dateFormatShortList = [
    {
        id: "DD/MM/YYYY",
        label: moment().format("DD/MM/YYYY"),
    },
    {
        id: "DD.MM.YYYY",
        label: moment().format("DD.MM.YYYY"),
    },
    {
        id: "YYYY-MM-DD",
        label: moment().format("YYYY-MM-DD"),
    },
    {
        id: "YYYY/MM/DD",
        label: moment().format("YYYY/MM/DD"),
    },
];

// Add info with formatted number
const currencies = ["EUR", "GBP", "CAD", "USD", "PLN", "CHF", "BRL", "JPY"];

export function Preference() {
    const classes = useStylesPreference();
    const p = useP();
    const api = useApi();
    const user = useUser();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [locale, setLocale] = useLocale();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [lexicon, setLexicon] = useLexicon();

    const { data, loading, error } = useSelector((state) => state.app.settings);

    const timezones = useSelector((state) => state.ref.timezones);

    const dispatch = useDispatch();
    const dispatchSettings = (settings) => dispatch(updateSettings(settings));

    const [dateFormat, setDateFormat] = useState(data.dateFormatShort);
    const [language, setLanguage] = useState(data.language);
    const [consoleLexicon, setConsoleLexicon] = useState(data.lexicon);
    const [timezone, setTimezone] = useState(data.timezone.id);
    const [currency, setCurrency] = useState(data.currency);

    // submit form
    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            let tz = timezones.data.filter((_tz) => _tz.id === timezone);

            // update polyglot
            setLocale(language);
            setLexicon(consoleLexicon);

            dispatchSettings({
                dateFormatShort: dateFormat,
                currency: currency,
                timezone: tz.length === 1 ? tz[0] : null,
                language: language,
                lexicon: consoleLexicon,
            });

            const oktaData = {
                locale: language,
                login: user.email,
                userId: user.id,
            };

            api.fetchApi("POST", "/users/okta", JSON.stringify(oktaData), {
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [language, consoleLexicon, timezone, dateFormat, currency]
    );

    // Add locales info with formatted number
    const locales = availableLanguages.map((_locale) => ({
        ..._locale,
        info: toUserLocalNumber(12358.47, _locale.id, 2, currency),
        label: p.t(`modules.preferences.${_locale.isoCode}`),
    }));

    // build errors
    const errors =
        error && error.response && error.response.violations
            ? error.response.violations.reduce((memo, violation) => {
                  memo[violation.propertyPath] = p.t("errors.invalid_select"); //violation.message;
                  return memo;
              }, {})
            : {};

    return (
        <div>
            <Title level={1} value={p.t("modules.preferences.title")} withLine />
            {!timezones || timezones.loading ? (
                <div className={classes.loader}>
                    <HiLoader loading />
                </div>
            ) : (
                <form
                    className={classnames(classes.root, classes.formContainer)}
                    onSubmit={handleSubmit}
                >
                    <div className={classes.field}>
                        <HiSelectField
                            id={"language"}
                            name={"language"}
                            value={language}
                            label={p.t("modules.preferences.language")}
                            onChange={(e, v) => setLanguage(v)}
                            options={locales}
                            hiSelectableListProps={{ hideCheckbox: true }}
                        />
                    </div>
                    <div className={classes.field}>
                        <HiSelectField
                            id={"timezone"}
                            name={"timezone"}
                            value={timezone}
                            label={p.t("modules.preferences.timezone")}
                            onChange={(e, v) => setTimezone(v)}
                            options={timezones.data}
                            searchable
                            hiSelectableListProps={{ hideCheckbox: true }}
                        />
                    </div>
                    <div className={classes.field}>
                        <HiSelectField
                            id={"lexicon"}
                            name={"lexicon"}
                            value={consoleLexicon}
                            label={p.t("modules.preferences.lexicon")}
                            onChange={(e, v) => setConsoleLexicon(v)}
                            options={lexicons.map((_lexicon) => ({
                                id: _lexicon,
                                label: p.t(`modules.preferences.lexicons.${_lexicon}`),
                            }))}
                            hiSelectableListProps={{ hideCheckbox: true }}
                        />
                    </div>
                    <div className={classes.field}>
                        <HiSelectField
                            id={"dateFormatShort"}
                            name={"dateFormatShort"}
                            value={dateFormat}
                            error={errors.dateFormatShort}
                            errorText={errors.dateFormatShort}
                            label={p.t("modules.preferences.date_format")}
                            onChange={(e, v) => setDateFormat(v)}
                            options={dateFormatShortList}
                            hiSelectableListProps={{ hideCheckbox: true }}
                        />
                    </div>
                    <div className={classes.field}>
                        <HiSelectField
                            id={"currency"}
                            name={"currency"}
                            value={currency}
                            label={p.t("modules.preferences.preferred_currency")}
                            onChange={(e, v) => setCurrency(v)}
                            options={currencies.map((_currency) => ({
                                id: _currency,
                                label: p.t(`modules.preferences.currencies.${_currency}`),
                            }))}
                            searchable
                            hiSelectableListProps={{ hideCheckbox: true }}
                        />
                    </div>
                    {error && (
                        <div className={classes.errorDiv}>
                            <HiIcon icon="warning" className={classes.errorDivIcon} />
                            {p.t("errors.global")}
                        </div>
                    )}
                    <HiButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {p.t("modules.preferences.apply")}
                    </HiButton>
                </form>
            )}
            <div className={classes.footerDiv} />
        </div>
    );
}
