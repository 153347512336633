import attributes from "./attributes";
import { isInternal } from "../utils";

/**
 * Form
 */
export const defaultFields = {
    account: null,
    date_created: null,
    home_country: null,
    manager: null,
    active_payment_mean: null,
    status: null,
    website_url: null,
};

const availableFieldsBase = [
    attributes.account.id,
    attributes.activity_sector.id,
    attributes.date_created.id,
    attributes.home_country.id,
    attributes.id.id,
    attributes.business_salesowner.id,
    attributes.business_salesobserver.id,
    attributes.active_payment_mean.id,
    attributes.status.id,
    attributes.user_name.id,
    attributes.website_url.id,
    attributes.business_id.id,
    attributes.invoicing_mode.id,
    attributes.account_type.id,
];

const availableFieldsInternal = isInternal
    ? [
          attributes.api_username.id,
          attributes.invoicing_status.id,
          attributes.settlement_status.id,
          attributes.settlement_day.id,
      ]
    : [];

export const availableFields = [...availableFieldsBase, ...availableFieldsInternal];
