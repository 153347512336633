const operationStatuses = {
    // predefined static options
    id: "operation_statuses",
    data: [
        {
            id: "CANCELLED",
            label: "ref.transaction.operation_statuses.CANCELLED",
        },
        // {id: 'CHARGEBACK', label: 'ref.transaction.operation_statuses.CHARGEBACK'},
        {
            id: "COMPLETED",
            label: "ref.transaction.operation_statuses.COMPLETED",
        },
        {
            id: "CREATED",
            label: "ref.transaction.operation_statuses.CREATED",
        },
        {
            id: "FAILURE",
            label: "ref.transaction.operation_statuses.FAILURE",
        },
        {
            id: "NOT_PROCESSED",
            label: "ref.transaction.operation_statuses.NOT_PROCESSED",
        },
        {
            id: "PENDING",
            label: "ref.transaction.operation_statuses.PENDING",
        },
        // {id: 'PROCESSING', label: 'ref.transaction.operation_statuses.PROCESSING'},
        {
            id: "RECONCILED",
            label: "ref.transaction.operation_statuses.RECONCILED",
        },
        {
            id: "REMITTED",
            label: "ref.transaction.operation_statuses.REMITTED",
        },
        {
            id: "REQUESTED",
            label: "ref.transaction.operation_statuses.REQUESTED",
        },
        {
            id: "RETRIED",
            label: "ref.transaction.operation_statuses.RETRIED",
        },
        {
            id: "SPLIT",
            label: "ref.transaction.operation_statuses.SPLIT",
        },
    ],
    translate: true,
};

export default operationStatuses;
