export const NOT = "NOT";
export const AND = "AND";
export const OR = "OR";
export const SAUF = "SAUF";
export const ET = "ET";
export const OU = "OU";
export const PREFIX_KEYWORDS = [NOT, SAUF];
export const BOOLEAN_KEYWORDS = [AND, OR, ET, OU];
export const STRUCTURE_KEYWORDS = ["(", ")"];

// Suggest fields types
export const TYPE_ACCOUNT = "account";
export const TYPE_BUSINESS = "business";
export const TYPE_CUSTOMER = "customer";
export const TYPE_ORDER = "order";
export const TYPE_PAYMENT = "payment";
export const TYPE_TRANSACTION = "transaction";

export const SOURCE_ACCOUNT_ID = "account_id";
export const SOURCE_ACCOUNT_NAME = "account_name";
export const SOURCE_ACCOUNT_NUMBER = "account_number";
export const SOURCE_ACQUIRER_TRANSACTION_ID = "acquirer_trxid";
export const SOURCE_BUSINESS_ID = "business_id";
export const SOURCE_BUSINESS_NAME = "business_name";
export const SOURCE_CARD_TOKEN = "card_token";
export const SOURCE_CUSTOMER_ACCOUNT_NUMBER = "customer_account_number";
export const SOURCE_CUSTOMER_CITY = "customer_city";
export const SOURCE_CUSTOMER_COUNTRY = "customer_country";
export const SOURCE_CUSTOMER_EMAIL = "customer_email";
export const SOURCE_CUSTOMER_ID = "customer_id";
export const SOURCE_CUSTOMER_NAME = "customer_name";
export const SOURCE_MID = "mid";
export const SOURCE_ORDER_DATE = "order_date";
export const SOURCE_MERCHANT_ORDER_ID = "merchant_order_id";
export const SOURCE_PHONE_NUMBER = "phone_number";
export const SOURCE_TRANSACTION_ID = "trxid";

export const keywordsSuggestions = [
    {
        origin: "keyword",
        type: "search",
        field: TYPE_TRANSACTION,
        query: "",
    },
    {
        origin: "keyword",
        type: "search",
        field: TYPE_ACCOUNT,
        query: "",
    },
    {
        origin: "keyword",
        type: "search",
        field: TYPE_BUSINESS,
        query: "",
    },
    {
        origin: "keyword",
        type: "search",
        field: TYPE_CUSTOMER,
        query: "",
        rgpdCompliance: true,
    },
    {
        origin: "keyword",
        type: "search",
        field: TYPE_ORDER,
        query: "",
    },
    {
        origin: "keyword",
        type: "search",
        field: TYPE_PAYMENT,
        query: "",
    },
];

// Element is a completed element or closed bracket
// -> suggest boolean operator
// ex: '... abc '
// ex: '... "abc"'
// ex: '... ) '
export const REGEX_COMPLETED_ELEMENT = /(^".+(?:"\s?)$)|([^"]+\s$)|(\)\s?)/;
// Element is a simple text search or an exact term query (allow whitespace, at and point)
// -> fetch suggestions (_suggest)
// ex: '... abc'
// ex: '... "abc '
export const REGEX_SEARCH_TEXT = /(^"(\w@*\.*-*)+[^"]$)|(^[^"\s]+$)/;

export const REGEX_WITH_WHITESPACE = /^"?([^"]*)/;

export const ELEMENTS_TYPES = {
    boolean: "boolean",
    completed: "completed",
    item: "item",
};

/*export const TYPES_WEIGHTS = {
    [SOURCE_ACCOUNT_ID]: 1,
    [SOURCE_ACCOUNT_NAME]: 2,
    [SOURCE_BUSINESS_ID]: 1,
    [SOURCE_BUSINESS_NAME]: 2,
    [SOURCE_CUSTOMER_ID]: 1,
    [SOURCE_CUSTOMER_COUNTRY]: 6,
    [SOURCE_CUSTOMER_CITY]: 5,
    [SOURCE_CUSTOMER_NAME]: 2,
    [SOURCE_CUSTOMER_EMAIL]: 3,
    [SOURCE_PHONE_NUMBER]: 4,
    [SOURCE_ORDER_ID]: 1,
    [SOURCE_CUSTOMER_ACCOUNT_NUMBER]: 3,
    [SOURCE_CARD_TOKEN]: 2,
    [SOURCE_TRANSACTION_ID]: 1
};*/

export const TYPES = {
    ids: [TYPE_ACCOUNT, TYPE_BUSINESS, TYPE_CUSTOMER, TYPE_ORDER, TYPE_PAYMENT, TYPE_TRANSACTION],
    all: [
        { id: TYPE_ACCOUNT },
        { id: TYPE_BUSINESS },
        { id: TYPE_CUSTOMER },
        { id: TYPE_ORDER },
        { id: TYPE_PAYMENT },
        { id: TYPE_TRANSACTION },
    ],
    account: [
        { id: SOURCE_ACCOUNT_ID },
        { id: SOURCE_ACCOUNT_NAME },
        { id: SOURCE_ACCOUNT_NUMBER },
    ],
    business: [{ id: SOURCE_BUSINESS_ID }, { id: SOURCE_BUSINESS_NAME }],
    customer: [
        { id: SOURCE_CUSTOMER_ID },
        // {
        //     id: SOURCE_CUSTOMER_COUNTRY,
        // },
        { id: SOURCE_CUSTOMER_CITY },
        { id: SOURCE_CUSTOMER_NAME },
        { id: SOURCE_CUSTOMER_EMAIL },
        { id: SOURCE_PHONE_NUMBER },
    ],
    order: [{ id: SOURCE_MERCHANT_ORDER_ID }],
    payment: [{ id: SOURCE_CUSTOMER_ACCOUNT_NUMBER }, { id: SOURCE_CARD_TOKEN }],
    transaction: [
        { id: SOURCE_TRANSACTION_ID },
        { id: SOURCE_ACQUIRER_TRANSACTION_ID },
        { id: SOURCE_MID },
    ],
    // custom_data: [
    //     {
    //         id: SOURCE_TRANSACTION_ID,
    //     },
    //     {
    //         id: SOURCE_TRANSACTION_ID,
    //     }
    // ]
};

export const orderedFields = {
    search_type: { order: 1 },
    email: { order: 2 },
    transaction_statuses: { order: 3 },
    customer_account_number: { order: 4 },
    trxid: { order: 5 },
    merchant_order_id: { order: 6 },
    card_token: { order: 7 },
    customer_name: { order: 8 },
    custom_data: { order: 9 },
    customer_id: { order: 10 },
    account_id: { order: 11 },
    account_name: { order: 12 },
    business_id: { order: 13 },
    business_name: { order: 14 },
    customer_address: { order: 15 },
    payment_means: { order: 16 },
    payment_method: { order: 17 },
    order_date: { order: 19 },
    phone_number: { order: 20 },
    customer_country: { order: 41 },
    acquirer_trxid: { order: 48 },
    mid: { order: 76 },
};

//Recupère le dernier élément
// Tableau avec tous les éléments et une chaîne avec un espace à la fin
// Ex
// 'Jean ' => ['Jean', ' ']
// 'Jean "Pierre ' => ['Jean', '"Pierre ']
export const REGEX_PREVIOUS_VALUES = /\s?("([^"])*"?|([^"()\s]+)|\(|\))\s?/g;

//Recupère le dernier élément
// Tableau avec tous les éléments et une chaîne avec un espace à la fin
// On recherche n'importe quel élément et un élément avec potentiellement un espace à la fin
// Ex
// 'Jean ' => ["Jean "]
// 'Jean "Pierre ' => ['Jean', '"Pierre ']
export const REGEX_PARSE_W_WS = /("(?:[^"])*"?|(?:[^"()\s]+|\(|\))(?:\s$)?)/g;
