import React, { useEffect, useReducer, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAsync } from "react-async";
import { setUser as setSentryUser } from "@sentry/react";

import { UserContext } from "./user-context";
import { userReducer } from "./user-reducer";

import { AppLoading } from "../../app/components/AppLoading";
import { SET_USER } from "../../app/actions/actionTypes";
import { disconnect } from "../../app/actions/authenticationActions";
import { useOktaAuth } from "@okta/okta-react";
import { parseJwt } from "../../utils/jwt";

/**
/* Fetch User from Console API
*/
export const fetchUser = async ([token]) => {
    const response = await fetch(`${process.env.NX_CONSOLE_API_URL}/users/me`, {
        headers: {
            "X-Authorization": `Bearer ${token}`,
            accept: "application/json",
        },
    });
    if (!response.ok) {
        const textResponse = await response.text();
        throw new Error(textResponse);
    }
    return response.json();
};

/* Fetch User Info from User Management API */
export const fetchUserInfo = async ([token]) => {
    const { sub: username } = parseJwt(token);

    const response = await fetch(
        `${process.env.NX_USER_MANAGEMENT_API_URL}/v1/users/${username}/profile`,
        {
            headers: {
                "X-Authorization": `Bearer ${token}`,
                accept: "application/json",
            },
        }
    );
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

const defaultState = {
    userInfo: null,
    user: null,
};

export const UserProvider = ({ initialState = defaultState, children }) => {
    const [store, dispatchStore] = useReducer(userReducer, initialState);
    const dispatchRedux = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;

    // Check Token expiration
    const tokenExpired = useMemo(() => {
        if (!token) {
            return null;
        }

        // If token, check expiration
        const tokenDecoded = parseJwt(token);
        const { exp: tokenExpiration } = tokenDecoded;
        const now = Math.floor(Date.now() / 1000);

        return tokenExpiration < now;
    }, [token]);

    // Fetch User from Console API
    const {
        data: user, // rename result 'data' as 'user'
        run: getUser, // manually call
        setData: setUser, // manually set data, use for reset on logout
        error: getUserError,
    } = useAsync({
        deferFn: fetchUser,
        initialValue: null,
    });

    // Fetch User info from User API management
    const {
        data: userInfo,
        run: getUserInfo,
        setData: setUserInfo, // manually set data, use for reset on logout
        error: getUserInfoError,
    } = useAsync({
        deferFn: fetchUserInfo,
        initialValue: null,
    });

    // Logout a user properly
    const logoutUser = (errorMessage = null) => {
        // Call API to revoke token
        oktaAuth.revokeAccessToken();
        // Close Okta session locally
        oktaAuth.closeSession();
        // Remove token from redux store and add error message to display on login page
        dispatchRedux(disconnect(errorMessage));

        if (location.pathname !== "/login") {
            // Do not redirect on login page already on it (PSYCHE-4901: fix deeplinks)
            history.push("/login");
        }
    };

    // If no token, reset store
    // Use case: init without token
    // Use case: logout
    useEffect(() => {
        if (!token) {
            // reset store
            dispatchStore({ type: "RESET" });
            // reset react-async calls
            setUser(null);
            setUserInfo(null);
        } else if (tokenExpired) {
            // If token expired on page loading, logout user
            logoutUser();
        }
    }, [token, dispatchStore, tokenExpired]);

    // Logout if invalid token
    // Use case: user load console with an invalid jwt token in his browser
    // Use case: internal user load console without his VPN
    useEffect(() => {
        if (getUserError) {
            let errorJson, errorMessage;

            try {
                errorJson = JSON.parse(getUserError.message);
            } catch (e) {
                // Do nothing if not JSON error
            }
            errorMessage = errorJson?.detail?.includes("VPN is deactivated")
                ? "modules.authentication.app.login.vpn_error"
                : "modules.authentication.app.login.error";

            logoutUser(errorMessage);
        }
    }, [getUserError]);

    // If /profile fail
    // Use case: user load console with blocked account for example
    useEffect(() => {
        if (getUserInfoError) {
            logoutUser();
        }
    }, [getUserInfoError]);

    // When token, call API to get user
    // When user is ready, save it on store
    // Use case: init with token
    // Use case: login
    useEffect(() => {
        if (!token || tokenExpired) {
            return;
        }

        if (!user) {
            getUser(token);
        } else {
            dispatchStore({
                type: "SET_USER",
                user: user,
            });
            dispatchRedux({
                type: SET_USER,
                payload: user,
            });
        }
    }, [token, user, dispatchStore, getUser, tokenExpired]);

    // When token, call API to get user info
    // When userInfo is ready, save it on store
    // Use case: init with token
    // Use case: login
    useEffect(() => {
        if (!token || tokenExpired) {
            return;
        }

        if (!userInfo) {
            getUserInfo(token);
        } else {
            dispatchStore({
                type: "SET_USER_INFO",
                userInfo: userInfo,
            });
        }
    }, [token, userInfo, dispatchStore, getUserInfo, tokenExpired]);

    /**
     * Set sentry scope
     */
    useEffect(() => {
        setSentryUser(user);
    }, [user]);

    return (
        <UserContext.Provider
            value={{
                store,
                dispatchStore,
                logoutUser,
            }}
        >
            {authState === null || (token && (!store.user || !store.userInfo)) ? (
                <AppLoading message={"Loading user...."} />
            ) : (
                React.Children.only(children)
            )}
        </UserContext.Provider>
    );
};
