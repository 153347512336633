const refusalReasons = {
    id: "refusal_reasons",
    endpoint: "/refusal-reasons",
    withSelectedAccounts: true,
    translate: true,
    reduceFunc: (previous, current) =>
        current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"].toString(),
                      label: `ref.transaction.refusal_reasons.value_${current["id"]}`,
                      fallbackLabel: current["id"],
                  },
              ]
            : previous,
};

export default refusalReasons;
