/**
 * Kpi types
 */
export const TYPE_AMOUNT = "type_amount";
export const TYPE_AMOUNT_WITH_DATE = "type_amount_with_date";
export const TYPE_DATE = "type_date";
export const TYPE_LIST_OF_DATES = "type_list_of_dates";
export const TYPE_RATE = "type_rate";
export const TYPE_DURATION = "type_duration";
export const TYPE_TEXT = "type_text";
