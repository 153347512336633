import { useMemo, useCallback } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import makeStyles from "@mui/styles/makeStyles";
import HiCheckbox from "@hipay/hipay-material-ui/HiCheckbox";

const useStylesCellCheckbox = makeStyles((theme) => {
    return {
        wrapper: {
            width: 40,
            height: "100%",
            position: "sticky",
            left: 0,
            paddingRight: 8,
            backgroundColor: "inherit",
            display: "flex",
            lineHeight: "17px",
            alignItems: "center",
            textAlign: "right",
            color: theme.palette.neutral.main,
            zIndex: 11,
        },
        wrapperMobile: { position: "relative" },
    };
});

export function CellCheckbox(props) {
    const { className, name } = props;

    const classes = useStylesCellCheckbox();

    const cellCheckboxClassName = useMemo(() => {
        return classNames(classes.wrapper, className, { [classes.wrapperMobile]: isMobile });
    });

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        props.onChange();
    }, []);

    return (
        <div name={name} className={cellCheckboxClassName} onClick={handleClick}>
            <HiCheckbox {...props} />
        </div>
    );
}

CellCheckbox.propTypes = HiCheckbox.propTypes;

CellCheckbox.defaultProps = HiCheckbox.defaultProps;
