import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../../services/i18n";
import { useModule } from "../../../../services/module";
import { QsearchFilter } from "./QsearchFilter";
import { GridChange } from "./GridChange";
import { SearchFilters } from "./SearchFilters";
import { SelectionFilterChip } from "./SelectionFilterChip";
import { buildUrlGrid } from "../../../../utils/urls";
import { useFavorites } from "../../../services/FavoritesContext";

const useStylesFilters = makeStyles((theme) => ({
    root: {
        display: "flex",
        textAlign: "left",
        minHeight: 40,
        padding: "6px 24px",
    },
    leftHeader: {
        ...theme.typography.body3,
        color: theme.palette.text.secondary,
        lineHeight: "25px",
    },
    container: {
        display: "flex",
        width: "calc(100% - 42px)",
        flexWrap: "wrap",
        "& > div": { margin: "1px 2px" },
    },
}));

export const Filters = (props) => {
    const {
        forwardRef,
        noHeader,
        qsearch,
        onDeleteFilter,
        columnParams,
        formParams,
        excludeParams,
        lookupParams,
        selectionParam,
        defaultGrid,
        persistOrderedColumns,
        onResetColumnsOrder,
        disableActions,
        attributes,
    } = props;

    const p = useP();
    const classes = useStylesFilters(props);
    const [{ selectedFavorite }] = useFavorites();
    const location = useLocation();
    const { id: module } = useModule();
    const userSettings = useSelector((state) => state.app.settings.data);

    // current grid from url
    const currentGrid = useMemo(() => {
        const currentGridMatch = /grid=([0-9A-Z_]*)/.exec(location.search);
        return currentGridMatch && currentGridMatch[1];
    }, [location.search]);

    const currentRank = useMemo(() => {
        const currentRankMatch = /rank=(true|false)/.exec(location.search);
        return currentRankMatch && currentRankMatch[1];
    }, [location.search]);

    const currentSelector = useMemo(() => {
        const currentSelectorMatch = /selector=(true|false)/.exec(location.search);
        return currentSelectorMatch && currentSelectorMatch[1];
    }, [location.search]);

    /**
     * Find grid changes
     * - if favorite is selected, compare current grid to favorite grid
     * - else, compare current grid to user settings grid (or default)
     * if there is no changes, return null.
     */
    const gridHasChanges = useMemo(() => {
        if (currentGrid && !disableActions) {
            // favorite is selected
            if (selectedFavorite) {
                // but has no updates
                if (!selectedFavorite.hasUpdate) {
                    return false;
                }
                // has update (but it could be only filters)

                // compare location grid to favorite grid
                const favoriteGrid = /grid=([0-9A-Z_]*)/.exec(selectedFavorite.urlSearch);
                const favoriteRank = /rank=(true|false)/.exec(selectedFavorite.urlSearch);
                const favoriteSelector = /selector=(true|false)/.exec(selectedFavorite.urlSearch);
                if (
                    favoriteGrid &&
                    favoriteGrid[1] === currentGrid &&
                    favoriteRank &&
                    favoriteRank[1] === currentRank &&
                    favoriteSelector &&
                    favoriteSelector[1] === currentSelector
                ) {
                    return false;
                }
            } else {
                // compare location grid to user settings (or default)
                let baseGrid = defaultGrid;
                let baseRank = true;
                let baseSelector = true;
                if (userSettings.searchResultsConfig && userSettings.searchResultsConfig[module]) {
                    baseGrid = userSettings.searchResultsConfig[module].columns;
                    if (process.env.NX_IS_INTERNAL === "false") {
                        baseGrid = userSettings.searchResultsConfig[module].columns.filter(
                            (c) => !attributes[c.colId]?.internal
                        );
                    }
                    baseRank = !!userSettings.searchResultsConfig[module].rank;
                    baseSelector = !!userSettings.searchResultsConfig[module].selector;
                }
                if (
                    buildUrlGrid(baseGrid) === currentGrid &&
                    baseRank === !!JSON.parse(currentRank) &&
                    baseSelector === !!JSON.parse(currentSelector)
                ) {
                    return false;
                }
            }
        } else {
            return false;
        }

        return true;
    }, [
        currentGrid,
        currentRank,
        currentSelector,
        defaultGrid,
        module,
        selectedFavorite,
        userSettings.searchResultsConfig,
        disableActions,
    ]);

    let count =
        Object.keys(formParams).length +
        Object.keys(excludeParams).length +
        Object.keys(lookupParams).length +
        Object.keys(columnParams).length +
        (selectionParam.length > 0 ? 1 : 0) +
        (qsearch ? 1 : 0) +
        (gridHasChanges ? 1 : 0);
    if (count === 0) {
        return null;
    }

    return (
        <div id="advanced-search-filters" className={classes.root} ref={forwardRef}>
            {!noHeader && (
                <span className={classes.leftHeader}>
                    {p.t("common.search.filters.title", { smart_count: count })}
                </span>
            )}
            <div className={classes.container}>
                <QsearchFilter onDeleteFilter={onDeleteFilter} qsearch={qsearch} />
                <SearchFilters {...props} />
                {selectionParam && selectionParam.length > 0 && (
                    <SelectionFilterChip disableActions={disableActions} />
                )}
                {gridHasChanges && (
                    <GridChange
                        persistOrderedColumns={persistOrderedColumns}
                        onResetColumnsOrder={onResetColumnsOrder}
                    />
                )}
            </div>
        </div>
    );
};

Filters.propTypes = {
    /**
     * Column params object
     */
    columnParams: PropTypes.object.isRequired,
    /**
     * Form params object
     */
    formParams: PropTypes.object.isRequired,
    /**
     * If true hides changed columns chip && remove buttons
     */
    disableActions: PropTypes.bool.isRequired,
    /**
     * Exclude params object
     */
    excludeParams: PropTypes.object.isRequired,
    /**
     * Lookup params object
     */
    lookupParams: PropTypes.object.isRequired,
    /**
     * Callback - Back to reference (url) period
     */
    onBackToPeriod: PropTypes.func,
    /**
     * Callback - date navigation with ID, FROM, TO
     */
    onDateNavigation: PropTypes.func,
    /**
     * Callback - delete filter with ID
     */
    onDeleteFilter: PropTypes.func,
    /**
     * Callback to reset columns order to user settings
     */
    onResetColumnsOrder: PropTypes.func,
    /**
     * Callback to save columns order.
     */
    persistOrderedColumns: PropTypes.func,
    /**
     * Quick Search phrase
     */
    qsearch: PropTypes.string,
    /**
     * Locale from user settings
     */
    userLocale: PropTypes.string,
    /**
     * Default Grid, to detect changes if user has not yet saved settings
     */
    defaultGrid: PropTypes.array.isRequired,
    /**
     * Selection param
     */
    selectionParam: PropTypes.array,
};

Filters.defaultProps = {
    changedColumns: false,
    defaultGrid: [],
    disableActions: false,
    noHeader: false,
};
