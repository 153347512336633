import { lazy } from "react";
import { AppCommon } from "@console/core";

const Root = lazy(() => import("./Root"));

const AppFinancialReport = (props: any) => {
    return <AppCommon ModuleRoot={Root} module={"financial_report"} {...props} />;
};

export default AppFinancialReport;
