import { FlexCarousel } from "./FlexCarousel";
import KpiBuilder from "../../../containers/Search/AnalyticsBanner/Kpi/KpiBuilder";
import { SLOT_WIDTH, SLOT_SPACING, SLOT_WIDTH_MOBILE } from "../../../constants";
import { isBrowser } from "react-device-detect";

export const ContentAnalytics = (props) => {
    const { datas, userSettings, module } = props;

    return (
        <div style={{ height: "100%" }}>
            <FlexCarousel
                minSpacing={SLOT_SPACING}
                slotWidth={isBrowser ? SLOT_WIDTH : SLOT_WIDTH_MOBILE}
            >
                {datas.map((data, index) => (
                    <KpiBuilder
                        key={index}
                        data={data}
                        module={module}
                        userSettings={userSettings}
                    />
                ))}
            </FlexCarousel>
        </div>
    );
};
