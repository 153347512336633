import { forwardRef, useCallback, useState } from "react";
import { useP, useModule, setEventTracker, useInnerPage } from "../../../../services";
import { useFavorites } from "../../../services";
import { useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Star from "mdi-material-ui/Star";
import StarOff from "mdi-material-ui/StarOff";
import StarOutline from "mdi-material-ui/StarOutline";
import StarHalfFull from "mdi-material-ui/StarHalfFull";
import Pencil from "mdi-material-ui/Pencil";
import TrashCan from "mdi-material-ui/TrashCan";

import { drySearchUrl, hasInternalParams } from "../../../../utils";
import {
    useFavoriteCreate,
    useFavoriteDelete,
    useFavoritesQuery,
    useFavoriteUpdate,
} from "../../../queries";
import { FavoriteForm } from "../../../../app";

export const useItemStylesFavorite = makeStyles((theme) => ({
    item: {
        "&:hover, &:focus": {
            backgroundColor: theme.palette.action.hover,
            outline: "none",
        },
        lineHeight: "1",
        minHeight: 40,
        pointerEvents: "auto !important", // allow title on disabled
    },
    icon: { minWidth: 40 },
    text: { ...theme.typography.body2 },
}));

const Item = forwardRef((props, ref) => {
    const { name, onClick, icon, label, title, autoFocus } = props;

    const classes = useItemStylesFavorite(props);

    return (
        <MenuItem
            key={`favorite-menu-item-${name}`}
            id={`favorite-menu-item-${name}`}
            className={classes.item}
            onClick={onClick}
            dense
            title={title}
            ref={ref}
            autoFocus={autoFocus}
        >
            <ListItemIcon className={classes.icon} children={icon} sx={{ color: "neutral.main" }} />
            <ListItemText className={classes.text} primary={label} disableTypography />
        </MenuItem>
    );
});

export const useStylesFavoriteMenu = makeStyles((theme) => ({
    root: { display: "inline-block" },
    info: {
        fontWeight: "300",
        fontSize: 12,
    },
    iconSelected: { color: theme.palette.primary.main },
}));

export function FavoriteMenu(props) {
    const p = useP();
    const classes = useStylesFavoriteMenu();
    const module = useModule();
    const location = useLocation();

    const { openInnerPage, closeInnerPage } = useInnerPage();

    const [anchorEl, setAnchorEl] = useState(null);

    // open menu
    const handleOpen = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    // close menu
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const { data } = useFavoritesQuery();
    const [{ displayed, selectedFavorite }, { setDisplayed, setSelectedFavorite }] = useFavorites();

    const handleClickDisplay = useCallback(() => {
        setEventTracker("show", { event_category: "favourites", event_action: "show_favourites" });
        setDisplayed(true);
        handleClose();
    }, [handleClose, setDisplayed]);

    const handleClickHide = useCallback(() => {
        setEventTracker("hide", { event_category: "favourites", event_action: "hide_favourites" });
        setDisplayed(false);
        handleClose();
    }, [handleClose, setDisplayed]);

    const [createFavorite] = useFavoriteCreate();
    const [updateFavorite] = useFavoriteUpdate();
    const [deleteFavorite] = useFavoriteDelete({
        rollback: () => setSelectedFavorite(selectedFavorite),
    });

    // Open Create Favorite form
    const openCreateForm = useCallback(
        (e) => {
            setEventTracker("create_favourite", {
                event_category: "favourites",
                event_action: "create_favourites",
            });
            setDisplayed(true);
            handleClose();

            const onSubmitForm = (_data) => {
                _data.favoriteId = `tmp-${Date.now()}`;
                _data.internal = hasInternalParams(
                    _data.urlSearch,
                    module.attributes,
                    props.defaultGrid
                );
                setSelectedFavorite(_data);
                createFavorite(_data, {
                    onSuccess: (newFavorite) => {
                        // set created favorite as selected with true id
                        setSelectedFavorite(newFavorite);
                    },
                });
            };

            openInnerPage(
                <FavoriteForm module={module.id} onSubmitForm={onSubmitForm} />,
                {
                    fromTopBar: false,
                    titleKey: "common.search.favorite.favorite_form.title.create",
                },
                "FavoriteForm"
            );
        },
        [handleClose, openInnerPage, module, createFavorite, setDisplayed, setSelectedFavorite]
    );

    // Open Update Favorite form
    const openUpdateForm = useCallback(
        (e) => {
            setEventTracker("edit_favourite", {
                event_category: "favourites",
                event_action: "edit_favourites",
            });
            setDisplayed(true);
            handleClose();

            openInnerPage(
                <FavoriteForm
                    module={module.id}
                    onSubmitForm={updateFavorite}
                    favoriteItem={selectedFavorite}
                />,
                {
                    fromTopBar: false,
                    titleKey: "common.search.favorite.favorite_form.title.update",
                },
                "FavoriteForm",
                [
                    {
                        id: "delete-favorite",
                        title: p.t("common.search.favorite.favorite_form.delete"),
                        icon: <TrashCan />,
                        onClick: () => {
                            deleteFavorite(selectedFavorite.favoriteId);
                            setSelectedFavorite(null);
                            closeInnerPage();
                        },
                    },
                ]
            );
        },
        [
            p,
            handleClose,
            module,
            selectedFavorite,
            setDisplayed,
            setSelectedFavorite,
            updateFavorite,
            deleteFavorite,
            openInnerPage,
            closeInnerPage,
        ]
    );

    const handleClickSave = useCallback(
        (e) => {
            setEventTracker("save_favourite", {
                event_category: "favourites",
                event_action: "save_favourites",
            });
            let _data = {
                ...selectedFavorite,
                urlSearch: drySearchUrl(location.search),
            };
            _data.internal = hasInternalParams(
                location.search,
                module.attributes,
                props.defaultGrid
            );
            updateFavorite(_data);
            handleClose();
        },
        [location, selectedFavorite, updateFavorite, handleClose]
    );

    const handleClickDelete = useCallback(
        (e) => {
            deleteFavorite(selectedFavorite.favoriteId);
            setSelectedFavorite(null);
            handleClose();
        },
        [deleteFavorite, selectedFavorite, setSelectedFavorite, handleClose]
    );

    return (
        <div className={classes.root}>
            <IconButton
                id="favorite-menu-button"
                aria-controls="favorite-menu-button"
                aria-haspopup="true"
                onClick={handleOpen}
                title={p.t("common.search.favorite.title")}
                // eslint-disable-next-line no-extra-boolean-cast
                className={!!selectedFavorite ? classes.iconSelected : ""}
                size="large"
            >
                {selectedFavorite && selectedFavorite.hasUpdate ? <StarHalfFull /> : <Star />}
            </IconButton>
            <Menu
                id="favorite-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ dense: true }}
                autoFocus={true}
                disableEnforceFocus
            >
                {data &&
                    data.length > 0 && [
                        displayed ? (
                            <Item
                                key="hide"
                                name="hide"
                                onClick={handleClickHide}
                                icon={<StarOff />}
                                label={p.t("common.search.favorite.menu.hide")}
                                title={p.t("common.search.favorite.menu.hide")}
                            />
                        ) : (
                            <Item
                                key="display"
                                name="display"
                                onClick={handleClickDisplay}
                                icon={<Star />}
                                label={p.t("common.search.favorite.menu.display")}
                                title={p.t("common.search.favorite.menu.display")}
                            />
                        ),
                        <Divider key="divider_1" />,
                    ]}
                <Item
                    key="create"
                    name="create"
                    onClick={openCreateForm}
                    icon={<StarOutline />}
                    label={p.t("common.search.favorite.menu.create")}
                    title={p.t("common.search.favorite.menu.create")}
                />
                {selectedFavorite &&
                    selectedFavorite.isEditable && [
                        <Divider key="divider_2" />,
                        selectedFavorite.hasUpdate && (
                            <Item
                                key="save"
                                name="save"
                                onClick={handleClickSave}
                                icon={<StarHalfFull />}
                                label={p.t("common.search.favorite.menu.save")}
                                title={p.t("common.search.favorite.menu.save")}
                            />
                        ),
                        <Item
                            key="update"
                            name="update"
                            onClick={openUpdateForm}
                            icon={<Pencil />}
                            label={p.t("common.search.favorite.menu.update")}
                            title={p.t("common.search.favorite.menu.update")}
                        />,
                        <Item
                            key="delete"
                            name="delete"
                            onClick={handleClickDelete}
                            icon={<TrashCan />}
                            label={p.t("common.search.favorite.menu.delete")}
                            title={p.t("common.search.favorite.menu.delete")}
                        />,
                    ]}
            </Menu>
        </div>
    );
}
