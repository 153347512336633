import React from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withStyles from "@mui/styles/withStyles";
import { translate } from "../../../../services/i18n";
import { withConfig } from "../../../../services/config";
import moment from "moment-timezone";

import CloseCircle from "mdi-material-ui/CloseCircle";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiSuggestSelectField from "@hipay/hipay-material-ui/HiSelect/HiSuggestSelectField";
import HiSwitch from "@hipay/hipay-material-ui/HiSwitch/HiSwitch";
import HiTextField from "@hipay/hipay-material-ui/HiForm/HiTextField";
import HiAlertModal from "@hipay/hipay-material-ui/HiAlertModal";

import * as referencesActions from "../../../../services/references/actions";
import { formFieldTypes } from "../../../../common/constants/formFieldTypes";
import * as appActions from "../../../../app/actions/appActions";
import { Suggestions } from "../../../../services/Suggestions/Suggestions";
import { withApi } from "../../../../services/api";

import { ELEMENTS_TYPES } from "../../../../services/Suggestions/constants";
import { AccountField } from "../../../../app/components/common/AccountField";

import {
    getMinimumCreatedDate,
    getSelectedBusinessIdMapFromSelectedAccountIdList,
} from "../../../../utils/entities";
import { getReference, getReferenceAsOptions } from "../../../../services/references/utils";
import { checkNumericValue, trimSuggestInput } from "../../../../utils/forms";
import { translateKeywordsOperators } from "../../../../utils/i18n";

import SelectField from "../../../../common/components/Search/Form/SelectField";
import { CustomDataField } from "../../../../common/components/Search/Form/CustomDataField";
import CheckboxField from "../../../../common/components/Search/Form/CheckboxField";
import { DateSelectorField } from "../../../../common/components/Search/Form/DateSelectorField";
import StatusField from "../../../../common/components/Search/Form/StatusField";
import QueryParser, {
    TERM_NONE,
    TERM_KEYWORD,
    TERM_EXCLUDE,
} from "../../../../services/qsearch/QueryParser";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { format } from "date-fns";

export const stylesFormFieldBuilder = (theme) => ({
    formField: {
        paddingTop: 22,
        display: "flex",
        position: "relative",
    },
    formControl: {
        flex: "auto",
        maxWidth: "100%",
    },
    formControlLabel: {
        marginLeft: 0,
        height: 40,
    },
    customDataSuggestPopper: { width: "50%" },
    deleteFilter: {
        color: theme.palette.neutral.main,
        width: 40,
        height: 40,
        position: "absolute",
        right: 0,
        bottom: 0,
        padding: 0,
    },
    accountFilterBlock: { textAlign: "center" },
    accountFilterSpan: { margin: "0 4px" },
    privateIcon: { margin: "0 4px" },
});

class FormFieldBuilder extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        /**status_period: 'past',
         * Add delete button to field
         */
        deletable: PropTypes.bool,
        /**
         * Id >> dataFieldType ID
         */
        id: PropTypes.string.isRequired,
        /**
         * Action to perform when form field has errors
         */
        handleFormErrors: PropTypes.func,
        /**
         * Is form field in error or not
         */
        hasError: PropTypes.bool.isRequired,
        /**
         * Available translated options got from references or staticOptions
         */
        options: PropTypes.array,
        /**
         * Values of linked fields of the forms
         * ex: status_period for status
         */
        linkedFields: PropTypes.object,
        /**
         * Callback change field value
         */
        onChange: PropTypes.func,
        /**
         * Callback remove field
         */
        onRemove: PropTypes.func,
        /**
         * Callback reset field to default/undefined value
         */
        onReset: PropTypes.func,
        /**
         * Relative reference name (optional)
         */
        reference: PropTypes.string,
        /**
         * Module references list
         */
        moduleReferences: PropTypes.object,
        /**
         * Value of field
         */
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.object,
            PropTypes.array,
        ]),
        /**
         * Suggestion route params
         */
        suggestRouteParams: PropTypes.object,
    };

    static defaultProps = {
        deletable: false,
        hasError: false,
        linkedFields: {},
    };

    fieldNumericOptions = [
        {
            id: "=",
            label: "=",
        },
        {
            id: ">",
            label: ">",
        },
        {
            id: ">=",
            label: ">=",
        },
        {
            id: "<",
            label: "<",
        },
        {
            id: "<=",
            label: "<=",
        },
        {
            id: "!=",
            label: "!=",
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            loading: props.loading,
            error: props.hasError,
            errorText: "",
            fromError: undefined,
            toError: undefined,
            inputValue: "",
            query: "",
            containerWidth: 0,
            // Usefull for dynamic selects (e.g. custom data)
            page: 1,
            restrictedAlertOpen: false,
        };

        // If the required references are not load/available
        if (
            props.reference &&
            (props.options === undefined || props.options.length === 0) &&
            // if field is relative to another, fetch ref only if relative field value is defined
            (!props.isRelativeField || props.relativeFieldValue)
        ) {
            props.fetchReferences(props.moduleReferences, props.reference, props.args, true);
        }

        // If fieldType ACCOUNT
        if (props.fieldType === formFieldTypes.FORM_FIELD_ACCOUNT) {
            let accountIdList = props.value
                ? Array.isArray(props.value)
                    ? props.value
                    : [props.value]
                : [];
            this.state.selectedBusinessIdList = getSelectedBusinessIdMapFromSelectedAccountIdList(
                accountIdList,
                this.state.businessList
            );
        }

        // If fieldType CUSTOM_DATA
        if (props.fieldType === formFieldTypes.FORM_FIELD_CUSTOM_DATA) {
            this.state.keySuggestions = []; // Needed for custom data => 2 fields with suggestions
            this.state.keyValue = "";
        }

        let moduleConfig = props.getConfigByModule(props.module);
        this.suggestionManager = new Suggestions(moduleConfig, props.api, props.suggestRouteParams);

        // Method for debounce api calls
        this.debounceSuggestionQuery = _debounce(this.suggestionQuery.bind(this), 750);
    }

    suggestionQuery(query, fieldName, params, name) {
        this.suggestionManager
            .query(query, fieldName, params)
            .then((response) => {
                this.setState((prevState) => {
                    if (prevState.error) {
                        this.props.handleFormErrors(name, false);
                    }
                    return {
                        ...prevState,
                        suggestions: [...response].slice(0, 5),
                        loading: false,
                        error: false,
                    };
                });
            })
            .catch((error) => {
                if (error.message) {
                    // error from api else => cancel function
                    this.setState({
                        loading: false,
                        error: true,
                        errorText: this.props.p.t("form.fields.select.error"),
                    });
                    this.props.handleFormErrors(name, true);
                }
            });
    }

    componentDidMount() {
        if (this.props.fieldType === formFieldTypes.FORM_FIELD_STATUS && this.container) {
            this.setState({ containerWidth: findDOMNode(this.container).clientWidth });
        } else if (this.props.fieldType === formFieldTypes.FORM_FIELD_NUMERIC) {
            this.setState({ suggestions: this.fieldNumericOptions });
        }
    }

    /**
     * Needed to compare options (array)
     * @param nextProps
     * @param nextState
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return (
            JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
            JSON.stringify(this.state) !== JSON.stringify(nextState)
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            fetchReferences,
            errorField,
            fieldType,
            handleFormErrors,
            hasError,
            id,
            p,
            reference,
            isRelativeField,
            relativeFieldValue,
            selectedAccountIdList,
            fieldOptions,
            moduleReferences,
        } = this.props;

        if (fieldType === formFieldTypes.FORM_FIELD_SELECT) {
            if (errorField && !hasError) {
                this.setState({ error: true });
                handleFormErrors(id, true);
                this.setState({ errorText: p.t("form.fields.select.error") });
            } else if (!errorField && hasError) {
                this.setState({ error: false });
                handleFormErrors(id, false);
            }
        }

        if (prevProps.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }

        if (
            // fetch options if field has dependency to another field value which changed
            (isRelativeField &&
                relativeFieldValue &&
                relativeFieldValue !== prevProps.relativeFieldValue) ||
            // fetch options if field is restricted by selected accounts and selected accounts changed
            (fieldOptions.restrictedBySelectedAccountList &&
                prevProps.selectedAccountIdList !== selectedAccountIdList)
        ) {
            fetchReferences(moduleReferences, reference, this.props.args, true);
        }
    }

    getModuleAttributes = () => {
        let moduleConfig = this.props.getConfigByModule(this.props.module);
        return moduleConfig ? moduleConfig.attributes : [];
    };

    /**
     * Handle filter change as select or input
     * @param name
     * @returns {Function}
     */
    handleChange = (name) => (event, value) => {
        this.props.onChange(name, value !== undefined ? value : event.target.value);
    };

    /**
     * Handle filter change as select or input
     * @param id
     * @returns {Function}
     */
    handleChangeCustomDataKey = (id) => (event, value) => {
        if (typeof value === "object") {
            // Mobile => suggest field
            value = value.label;
        }

        this.props.onChange(`${id}_key`, value !== undefined ? value : event.target.value);
        this.props.onChange(`${id}_value`, "");
        if (this.input) {
            setTimeout(() => {
                this.input.focus();
            });
        }
        this.setState({ query: "" });
    };

    /**
     * Handle filter change as select or input
     * @param name
     * @param value
     * @returns {Function}
     */
    handleReset =
        (name, value = []) =>
        () => {
            this.props.onChange(name, value);
            if (this.state.error) {
                // Remove errors if needed
                this.setState({ error: false });
                this.props.handleFormErrors(name, false);
            }
        };

    /**
     * Handle filter change as checkboxes options
     * @param name
     * @param value
     * @returns {Function}
     */
    handleChangeCheckbox = (name, value) => (event, checked) => {
        let selectedList = this.props.value ? [...this.props.value] : [];
        if (checked === true) {
            selectedList.push(value);
        } else {
            selectedList.splice(selectedList.indexOf(value), 1);
        }

        this.props.onChange(name, selectedList);
    };

    /**
     * Handle filter change as date range selector
     * @param name
     * @returns {Function}
     */
    handleChangeDate = (name) => (type, value) => {
        let key = type === "period" ? "interval" : type;

        if (["fromError", "toError"].includes(key)) {
            this.setState({
                [key]: value,
                error: false,
            });
        } else if (key === "interval") {
            this.props.handleFormErrors(name, false);
            this.props.onChange(name, { [key]: value });
        } else {
            if (type === "period") {
                this.props.handleFormErrors(name, false);
            }
            this.props.onChange(name, { [key]: value });
        }
    };

    handleChangeMonth = (name) => (value) => {
        // If empty value return it
        if (!value) {
            this.props.onChange(name, value);
        } else {
            // Format date value
            this.props.onChange(name, format(value, "MM-yyyy"));
        }
    };

    handleBlurDate = (name) => () => {
        setTimeout(() => {
            let { fromError, toError } = this.state;
            const { p, value } = this.props;
            let from = value.from;
            let to = value.to;

            if (from === undefined && fromError === undefined) {
                fromError = p.t("form.fields.date_range_selector.error.missing_date_from");
            } else if (to === undefined && toError === undefined) {
                toError = p.t("form.fields.date_range_selector.error.missing_date_to");
            } else if (from && to && from > to) {
                fromError = p.t("form.fields.date_range_selector.error.to_superior_from");
                toError = p.t("form.fields.date_range_selector.error.to_superior_from");
            }

            if (fromError || toError) {
                this.setState((prevState) => ({
                    ...prevState,
                    error: true,
                    fromError: fromError,
                    toError: toError,
                }));
                this.props.handleFormErrors(name, true);
            } else {
                this.setState({ error: false });
                this.props.handleFormErrors(name, false);
            }
        }, 1);
    };

    /**
     * Trigger request when user type in autosuggest field
     *
     * Parse the value to determine the query on to request
     * Send request to elasticsearch to get suggestions
     * Compose suggestions with keywords
     *
     * @param name
     * @param additionalParams
     * @returns {Function}
     */
    handleSearchSuggest =
        (name, additionalParams = {}) =>
        (event) => {
            const { p, selectedAccountIdList } = this.props;

            const _value = event.target.value;
            if (_value.length === 0 && this.state.error) {
                // Remove errors if needed
                this.setState({ error: false });
                this.props.handleFormErrors(name, false);
            }

            const selectionStart =
                event.type === "change" ? event.target.selectionStart : _value.length;
            const elements = this.suggestionManager.parse(_value, p, selectionStart);

            const el = elements[elements.length - 1];
            let options = [];
            if (el && el.type === "completed") {
                this.setState({ loading: false });
            }
            if (el !== undefined && el.suggestions) {
                options = el.suggestions;

                const termAnalysis = QueryParser.analyzeCurrentTermQuery(
                    _value,
                    this.input.selectionStart,
                    this.props.module,
                    this.getModuleAttributes(),
                    this.props.phrases
                );
                const isSuggestable =
                    termAnalysis &&
                    termAnalysis.previousTerm &&
                    [TERM_NONE, TERM_EXCLUDE, TERM_KEYWORD].indexOf(
                        termAnalysis.previousTerm.type
                    ) >= 0;
                if (el.type === ELEMENTS_TYPES.item && el.query && isSuggestable) {
                    this.setState({ loading: true });
                    let params = {
                        ...additionalParams,
                        ...(selectedAccountIdList.length && {
                            account: selectedAccountIdList.join("--"),
                        }),
                    };

                    if (
                        name.match(new RegExp("_value", "ig")) &&
                        typeof this.props.value === "object" &&
                        this.props.value.key
                    ) {
                        params.key = this.props.value.key;
                    }

                    // Name without index & key/value
                    let fieldName = name
                        .replace(new RegExp("_key|_value", "ig"), "")
                        .replace(new RegExp("_[0-9]+$", "ig"), "");
                    this.debounceSuggestionQuery(el.query, fieldName, params, name);
                }
            }

            this.setState((prevState) => ({
                ...prevState,
                suggestions: options,
                query: (el && el.query) || "",
            }));

            this.handleChangeSuggest(name, _value);
        };

    handleResetSuggest =
        (name, defaultSuggestions = []) =>
        () => {
            this.props.handleFormErrors(name, false);
            this.props.onReset(name)();
            this.setState({
                query: "",
                suggestions: defaultSuggestions,
            });
        };

    /**
     * Handle suggest select
     *
     * @param name
     */
    handleSelectSuggest = (name) => (event, value) => {
        const {
            p,
            value: _value, // >> previousValue = this.props.value or '' (default)
        } = this.props;

        let previousValue = "";
        if (_value) {
            if (typeof _value === "string") {
                previousValue = _value;
            } else {
                previousValue = _value.value || "";
            }
        }

        const translatedPreviousValue = translateKeywordsOperators(
            previousValue,
            this.props.p.phrases,
            "keywords"
        );
        let newValue = `${this.suggestionManager
            .getNewValue(value, translatedPreviousValue.trim(), p, this.input.selectionStart)
            .trim()} `;

        let selectionStart;
        let scrollLeft;
        if (
            this.input.selectionStart === previousValue.length ||
            previousValue.substring(this.input.selectionStart).trim().length === 0
        ) {
            // if caret at end of string or end only with whitespaces
            selectionStart = newValue.length;
            scrollLeft = this.input.scrollWidth;
        } else {
            selectionStart = this.input.selectionStart - this.state.query.length + value.id.length;
            // Add 2 characters for '"'
            if (!value.type || value.type !== "operator") {
                selectionStart += 2;
            }
            scrollLeft = selectionStart * 8;
        }

        const elements = this.suggestionManager.parse(newValue, p, selectionStart);
        const el = elements[elements.length - 1];

        this.setState((prevState) => ({
            ...prevState,
            suggestions: el !== undefined && el.suggestions ? el.suggestions : [],
            query: "",
        }));
        this.handleChangeSuggest(name, newValue, true);

        // Set cursor on scroll on good position
        setTimeout(() => {
            if (this.input) {
                this.input.focus();
                this.input.scrollLeft = scrollLeft;
                this.input.setSelectionRange(selectionStart, selectionStart);
            }
        }, 1);
    };

    /**
     * For suggest field
     * replace keyword terms by their untranslated key
     * OU => OR
     * ET => AND
     * SAUF => NOT
     * @param name
     * @param value
     * @param fromSelected
     */
    handleChangeSuggest = (name, value, fromSelected = false) => {
        const englishValue = translateKeywordsOperators(
            value,
            this.props.p.phrases,
            "keywords",
            true
        );
        const translatedValue = translateKeywordsOperators(value, this.props.p.phrases, "keywords");
        this.props.onChange(name, englishValue);
        if (value !== translatedValue && fromSelected !== true) {
            // Set caret position properly
            const selectionStart =
                this.input.selectionStart - (value.length - translatedValue.length);
            setTimeout(() => {
                if (this.input) {
                    this.input.setSelectionRange(selectionStart, selectionStart);
                }
            }, 1);
        }
    };

    /**
     * Handle paste data into numeric input
     * remove spaces
     * @param name
     */
    handlePasteQueryNumeric = (name) => (event) => {
        event.preventDefault();
        let value = event.target.value;
        let pasteData = event.clipboardData.getData("text/plain").trim();

        if (pasteData) {
            let formatData = pasteData.replace(" ", "");
            if (value.charAt(event.target.selectionStart - 1) === "(") {
                formatData = ` ${formatData}`;
            }
            const query =
                value.slice(0, event.target.selectionStart) +
                formatData +
                value.slice(event.target.selectionEnd);
            this.updateNumericValue(name, query);
        }
    };

    /**
     * Handle paste data into suggest input
     * remove spaces
     * @param name
     */
    handlePasteQueryText = (name) => (event) => {
        event.preventDefault();
        let value = event.target.value;
        let pasteData = trimSuggestInput(event.clipboardData.getData("text/plain"));

        if (pasteData) {
            const matches = pasteData.match(/[^\n]+/g);
            let formatData = `"${matches.join('", "')}"`;
            if (value.charAt(event.target.selectionStart - 1) === "(") {
                formatData = ` ${formatData}`;
            }
            const query =
                value.slice(0, event.target.selectionStart) +
                formatData +
                value.slice(event.target.selectionEnd);
            this.handleChangeSuggest(name, query);
        }
    };

    /**
     * Analyze request when user type in numeric field
     * Compose suggestions with keywords
     * @param name
     * @param value
     * @return {null}
     */
    updateNumericValue = (name, value) => {
        // Check if last character is accepted
        if (!checkNumericValue(value)) {
            return null;
        }

        const { p } = this.props;

        let options = [];
        if (typeof value !== "undefined") {
            if (!value || value.length === 0) {
                options = this.fieldNumericOptions;
            } else if (/^-?[0-9]+([.,][0-9]+)?\s$/.test(value)) {
                // Element is a completed number
                // -> suggest range keyword (TO, A, À)
                // ex: '... TO '
                // ex: '... A '
                options = [
                    {
                        id: "TO",
                        label: p.t("TO"),
                    },
                ];
            }
        }

        this.setState((prevState) => ({
            ...prevState,
            suggestions: options,
        }));

        this.handleChangeSuggest(name, value);
    };

    /**
     * Handle Numeric search
     * @param name
     */
    handleSearchNumeric = (name) => (event) => {
        const value = event.target.value;
        this.updateNumericValue(name, value);
    };

    /**
     * Handle Numeric select
     * @param name
     */
    handleSelectNumeric = (name) => (event, value) => {
        // >> previousValue = this.props.value or '' (default)
        const { value: previousValue = "" } = this.props;

        let newValue = value.id;
        if (value.id === "TO") {
            newValue = previousValue.trim() + ` ${value.id} `;
        }

        this.setState((prevState) => ({
            ...prevState,
            suggestions: [],
        }));

        this.handleChangeSuggest(name, newValue);
    };

    cancelRequestFunc;

    handleChangeAccount = (name) => (selectedAccountIdList) => {
        this.props.onChange(name, selectedAccountIdList);
    };

    toggleRestrictedAlert = () => {
        this.setState({ restrictedAlertOpen: !this.state.restrictedAlertOpen });
    };

    onDeleteAccount = () => {
        this.toggleRestrictedAlert();
        this.handleRemove();
    };

    handleRemove = () => {
        if (this.state.error === true) {
            this.props.handleFormErrors(this.props.id, false);
        }
        this.props.onRemove(this.props.id);
    };

    handleBlurSuggest = () => {
        this.setState({
            loading: false,
            suggestions: [],
        });
    };

    handleFocusSuggest = (name) => (event) => {
        if (event.nativeEvent.sourceCapabilities !== null) {
            this.handleSearchSuggest(name)(event);
        }
    };

    getInputRef = (el) => {
        this.input = el;
    };

    getContainer = (el) => {
        this.container = el;
    };

    render() {
        const {
            attribute,
            classes,
            deletable,
            id,
            fieldType,
            fieldOptions,
            multiFields,
            onReset,
            options = [...this.state.suggestions],
            p,
            value,
            loading: loadingProp,
            minimumDate,
            selectedAccountIdList,
            module,
            restrictedFilters,
            formFields,
        } = this.props;

        const { loading: loadingState } = this.state;

        let formField = {
            label: (
                <span>
                    {attribute.internal && (
                        <HiIcon
                            size={14}
                            icon="lock"
                            color="neutral"
                            className={classes.privateIcon}
                        />
                    )}
                    <span>
                        {p.t(
                            `attributes.${module}.${id.replace(
                                new RegExp("_[0-9]+$", "ig"),
                                ""
                            )}.formLabel`
                        )}
                    </span>
                </span>
            ),
            ...fieldOptions,
            helperText: fieldOptions.helperText
                ? p.t(
                      `attributes.${module}.${id.replace(
                          new RegExp("_[0-9]+", "ig"),
                          ""
                      )}.helperText`
                  )
                : undefined,
        };

        let formFieldComponent;
        let name = id;
        // eslint-disable-next-line no-unused-vars
        let _value;
        let placeholder;

        switch (fieldType) {
            case formFieldTypes.FORM_FIELD_SELECT:
            case formFieldTypes.FORM_FIELD_NESTED_SELECT:
                if (loadingProp) {
                    placeholder = p.t("form.fields.select.loading");
                } else if (options.length === 0 && !loadingState && fieldOptions.noData) {
                    placeholder = p.t(fieldOptions.noData, {
                        smart_count: selectedAccountIdList.length,
                    });
                } else {
                    formFieldComponent = (
                        <SelectField
                            hasAll={fieldType === formFieldTypes.FORM_FIELD_NESTED_SELECT}
                            nested={fieldType === formFieldTypes.FORM_FIELD_NESTED_SELECT}
                            id={id}
                            name={name}
                            value={value}
                            loading={loadingState}
                            formField={formField}
                            error={this.state.error}
                            errorText={this.state.errorText}
                            disabled={this.state.error === true}
                            options={options}
                            onChange={this.handleChange(name)}
                            onSubmit={this.props.onSubmit}
                            lazy={fieldOptions.lazy}
                            maxOptionsDisplayed={10}
                        />
                    );
                }
                break;

            case formFieldTypes.FORM_FIELD_SUGGEST:
                // eslint-disable-next-line no-use-before-define
                _value = value
                    ? translateKeywordsOperators(value, this.props.p.phrases, "keywords")
                    : "";

                formFieldComponent = (
                    <HiSuggestSelectField
                        id={id}
                        autoComplete="off"
                        onSearch={this.handleSearchSuggest(name)}
                        onReset={this.handleResetSuggest(name)}
                        onSelect={this.handleSelectSuggest(name)}
                        onBlurInput={this.handleBlurSuggest}
                        onFocusInput={this.handleFocusSuggest(name)}
                        options={options}
                        // eslint-disable-next-line no-use-before-define
                        value={_value}
                        helperIcon
                        searchable
                        loading={loadingState}
                        translations={{
                            min_length: p.t("form.fields.autosuggest.min_length", {
                                smart_count: 1,
                            }),
                            no_result_match: p.t("form.fields.autosuggest.no_result_match", {
                                value: this.state.query,
                            }),
                        }}
                        // eslint-disable-next-line no-use-before-define
                        error={this.state.error && _value.length > 0}
                        errorText={this.state.errorText}
                        // eslint-disable-next-line
                        showNoResults={
                            this.state.query.length > 0 &&
                            options.length === 0 &&
                            !loadingState &&
                            !this.state.error
                        }
                        // eslint-disable-next-line
                        showMinLength={_value.length < 3}
                        inputRef={(el) => (this.input = el)}
                        {...formField}
                        HiInputProps={{ onPaste: this.handlePasteQueryText(name) }}
                    />
                );
                break;

            case formFieldTypes.FORM_FIELD_CUSTOM_DATA:
                formFieldComponent = (
                    <CustomDataField
                        id={id}
                        value={value}
                        options={options}
                        loading={loadingState}
                        formField={formField}
                        attribute={attribute}
                        query={this.state.query}
                        error={this.state.error}
                        errorText={this.state.errorText}
                        onChange={this.handleChange}
                        onChangeKeySelect={this.handleChangeCustomDataKey}
                        onSearchSuggest={this.handleSearchSuggest(`${id}_value`)}
                        onResetSuggest={this.handleResetSuggest(`${id}`)}
                        onResetInput={this.handleReset(`${id}_value`, "")}
                        onSelectSuggest={this.handleSelectSuggest(`${id}_value`)}
                        handleFormErrors={this.props.handleFormErrors}
                        selectedAccountIdList={selectedAccountIdList}
                        inputRef={this.getInputRef}
                        module={module}
                        formFields={formFields}
                    />
                );

                break;

            case formFieldTypes.FORM_FIELD_CHECKBOX:
                formFieldComponent = (
                    <CheckboxField
                        formField={formField}
                        name={name}
                        onChange={this.handleChangeCheckbox}
                        options={options}
                        value={value}
                    />
                );
                break;

            case formFieldTypes.FORM_FIELD_SWITCH:
                // eslint-disable-next-line no-use-before-define
                formFieldComponent = (
                    <HiFormControl label={formField.label}>
                        <HiSwitch
                            // eslint-disable-next-line no-use-before-define
                            checked={!!value}
                            color="primary"
                            onChange={this.handleChange(name)}
                        />
                    </HiFormControl>
                );
                break;

            case formFieldTypes.FORM_FIELD_TEXT:
            case formFieldTypes.FORM_FIELD_TEXT_REFERENCE:
                // eslint-disable-next-line no-use-before-define
                _value = value || "";

                formFieldComponent = (
                    <HiTextField
                        id={name}
                        onChange={this.handleChange(name)}
                        // eslint-disable-next-line no-use-before-define
                        value={_value}
                        onReset={onReset(name)}
                        helperIcon={true}
                        {...formField}
                    />
                );
                break;

            case formFieldTypes.FORM_FIELD_DATE:
                formFieldComponent = (
                    <DateSelectorField
                        id={id}
                        onChange={this.handleChangeDate(name)}
                        onBlur={this.handleBlurDate(name)}
                        minimumDate={minimumDate}
                        dateFormat={this.props.dateFormat}
                        timezoneName={this.props.timezoneName}
                        error={this.state.error}
                        fromError={this.state.fromError}
                        toError={this.state.toError}
                        formField={formField}
                        value={value || {}}
                        moduleConfig={this.props.getConfigByModule(this.props.module)}
                    />
                );
                break;

            case formFieldTypes.FORM_FIELD_MONTH:
                const maximumDate = new Date(
                    minimumDate.getFullYear() + 7,
                    minimumDate.getMonth() + 1
                );

                _value = !value ? "" : moment(value, "MM-yyyy").format("MMMM yyyy");

                formFieldComponent = (
                    <div>
                        <MobileDatePicker
                            views={["year", "month"]}
                            cancelText={p.t("button.cancel")}
                            minDate={minimumDate}
                            maxDate={maximumDate}
                            value={_value}
                            onChange={this.handleChangeMonth(name)}
                            showToolbar={false}
                            PopperProps={{ placement: "right-start" }}
                            renderInput={({ inputProps, InputProps, inputRef }) => {
                                return (
                                    <HiTextField
                                        {...formField}
                                        HiInputProps={{
                                            ...inputProps,
                                            ...InputProps,
                                            placeholder: null,
                                            value: _value,
                                        }}
                                        customRef={inputRef}
                                        helperText={null}
                                    />
                                );
                            }}
                        />
                    </div>
                );
                break;

            case formFieldTypes.FORM_FIELD_STATUS:
                formFieldComponent = (
                    <StatusField
                        containerRef={this.getContainer}
                        containerWidth={this.state.containerWidth}
                        fieldOptions={fieldOptions}
                        formField={formField}
                        multiFields={multiFields}
                        name={name}
                        onChange={this.handleChange}
                        onSubmit={this.props.onSubmit}
                        options={options}
                        value={value}
                    />
                );
                break;

            case formFieldTypes.FORM_FIELD_NUMERIC:
                const termAnalysis2 = QueryParser.analyzeCurrentTermQuery(
                    value,
                    this.input ? this.input.selectionStart : 0,
                    this.props.module,
                    this.getModuleAttributes(),
                    this.props.phrases
                );
                // eslint-disable-next-line no-use-before-define
                _value = value
                    ? termAnalysis2 &&
                      termAnalysis2.previousTerm &&
                      [TERM_NONE, TERM_KEYWORD].indexOf(termAnalysis2.previousTerm.type) < 0
                        ? translateKeywordsOperators(value, this.props.p.phrases, "keywords")
                        : value
                    : "";
                formFieldComponent = (
                    <HiSuggestSelectField
                        id={id}
                        autoComplete="off"
                        onSearch={this.handleSearchNumeric(name)}
                        onReset={this.handleResetSuggest(name, this.fieldNumericOptions)}
                        onSelect={this.handleSelectNumeric(name)}
                        options={options}
                        // eslint-disable-next-line no-use-before-define
                        value={_value}
                        helperIcon
                        searchable
                        loading={loadingState}
                        translations={{
                            no_result_match: p.t("form.fields.select.no_result_match"),
                            search: p.t("form.fields.select.search"),
                        }}
                        {...formField}
                        inputProps={{ onPaste: this.handlePasteQueryNumeric(name) }}
                    />
                );
                break;

            case formFieldTypes.FORM_FIELD_ACCOUNT:
                if (!this.state.loading) {
                    _value =
                        !this.state.loading && value
                            ? Array.isArray(value)
                                ? value
                                : [value]
                            : []; // default

                    formFieldComponent = (
                        <AccountField
                            id={name}
                            name={name}
                            formField={formField}
                            onChange={this.handleChangeAccount(name)}
                            onSubmit={this.props.onSubmit}
                            selectedAccountIdList={_value}
                            restrictedFilters={restrictedFilters}
                        />
                    );
                }
                break;

            case formFieldTypes.FORM_FIELD_CHECKBOX_TO_SELECT:
                if (loadingProp) {
                    placeholder = p.t("form.fields.select.loading");
                } else if (options.length === 0 && !loadingState && fieldOptions.noData) {
                    placeholder = p.t(fieldOptions.noData, {
                        smart_count: selectedAccountIdList.length,
                    });
                } else if (options.length > 6) {
                    formFieldComponent = (
                        <SelectField
                            hasAll={false}
                            nested={false}
                            id={id}
                            name={name}
                            value={value}
                            loading={loadingState}
                            formField={formField}
                            error={this.state.error}
                            errorText={this.state.errorText}
                            disabled={this.state.error === true}
                            options={options}
                            onChange={this.handleChange(name)}
                            onSubmit={this.props.onSubmit}
                            lazy={fieldOptions.lazy}
                            maxOptionsDisplayed={10}
                        />
                    );
                } else {
                    formFieldComponent = (
                        <CheckboxField
                            formField={formField}
                            name={name}
                            onChange={this.handleChangeCheckbox}
                            options={options}
                            value={value}
                        />
                    );
                }
                break;

            default:
                break;
        }

        if (!formFieldComponent) {
            formFieldComponent = (
                <HiTextField
                    id={id}
                    label={formField.selects ? formField.selects[0].label : formField.label}
                    disabled
                    placeholder={placeholder}
                />
            );
        }

        const emptyValue =
            !this.props.value || (Array.isArray(this.props.value) && !this.props.value.length);
        const restricted =
            fieldType === formFieldTypes.FORM_FIELD_ACCOUNT &&
            restrictedFilters.length > 0 &&
            !emptyValue;

        return (
            <div id={id} className={classes.formField}>
                <div className={classes.formControl} style={{ paddingRight: deletable ? 40 : 0 }}>
                    {formFieldComponent}
                </div>
                {deletable && (
                    <HiIconButton
                        id={`${id}_delete_button`}
                        className={classes.deleteFilter}
                        style={{
                            ...(fieldType === formFieldTypes.FORM_FIELD_CHECKBOX && { bottom: 7 }),
                        }} // Switch has not top label
                        onClick={restricted ? this.toggleRestrictedAlert : this.handleRemove}
                        size={"small"}
                    >
                        <CloseCircle fontSize={"small"} />
                    </HiIconButton>
                )}
                {restricted && (
                    <HiAlertModal
                        id="alert-restricted"
                        open={this.state.restrictedAlertOpen}
                        title={p.t("alert.information")}
                        content={p.t("alert.restricted_filters.form.body", {
                            filters_name: restrictedFilters.join(", "),
                            smart_count: restrictedFilters.length,
                        })}
                        onSubmitClick={this.onDeleteAccount}
                        onCancelClick={this.toggleRestrictedAlert}
                        labelSubmitButton={p.t("alert.action.ok")}
                        labelCancelButton={p.t("alert.action.cancel")}
                    />
                )}
            </div>
        );
    }
}

/**
 * Get and translate field options from reference
 * @param state
 * @param ownProps
 * @returns {{}}
 */
const mapStateToProps = (state, ownProps) => {
    const reference = getReference(
        state.ref,
        ownProps.reference,
        ownProps.args,
        ownProps.moduleReferences
    );
    const selectedAccountEntities = Object.keys(state.app.global.entities.account)
        .filter((key) => state.app.global.selectedAccountIdList.includes(key))
        .reduce((obj, key) => {
            obj[key] = state.app.global.entities.account[key];
            return obj;
        }, {});

    return {
        ...(reference && {
            options:
                reference && reference.data !== undefined
                    ? getReferenceAsOptions(
                          state.ref,
                          ownProps.reference,
                          ownProps.p,
                          ownProps.args,
                          ownProps.moduleReferences
                      )
                    : [],
            loading: reference.loading,
            errorField: reference.error,
            selectedAccountIdList: state.app.global.selectedAccountIdList,
        }),
        ...(ownProps.fieldType === formFieldTypes.FORM_FIELD_ACCOUNT && {
            accountEntities: new Map(Object.entries(state.app.global.entities.account)),
            businessEntities: new Map(Object.entries(state.app.global.entities.business)),
            selectedAccountIdList: state.app.global.selectedAccountIdList,
            loading: false,
        }),
        ...(ownProps.fieldType === formFieldTypes.FORM_FIELD_DATE && {
            dateFormat: state.app.settings.data.dateFormatShort,
            minimumDate: getMinimumCreatedDate(selectedAccountEntities),
            timezoneName: state.app.settings.data.timezone.name,
        }),
        ...(ownProps.fieldType === formFieldTypes.FORM_FIELD_MONTH && {
            minimumDate: getMinimumCreatedDate(selectedAccountEntities),
        }),
        ...((ownProps.fieldType === formFieldTypes.FORM_FIELD_SUGGEST ||
            ownProps.fieldType === formFieldTypes.FORM_FIELD_CUSTOM_DATA) && {
            selectedAccountIdList: state.app.global.selectedAccountIdList,
            loading: false,
        }),
        ...(ownProps.selectedAccountIdList &&
            ownProps.selectedAccountIdList.length > 0 && {
                selectedAccountIdList: ownProps.selectedAccountIdList,
            }),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        ...((ownProps.reference || ownProps.relativeReference) && {
            fetchReferences: (references, name, args, force) =>
                ownProps.relativeFieldValue !== "EMPTY"
                    ? dispatch(referencesActions.fetchReferences(references, name, args, force))
                    : dispatch(referencesActions.fetchEmptyReferences(references, name)),
        }),
        ...(ownProps.fieldType === formFieldTypes.FORM_FIELD_ACCOUNT && {
            fetchSelectedAccountList: (accountIdList) =>
                dispatch(appActions.fetchSelectedAccountList(accountIdList)),
        }),
    };
};

// TODO - split components & containers
export default compose(
    translate,
    withStyles(stylesFormFieldBuilder),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withConfig,
    withApi
)(FormFieldBuilder);
