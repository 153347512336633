import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { setAuthentication } from "../../app/actions/authenticationActions";

import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { oktaAuthConfig } from "../../okta-config";
import {
    isRedirectToIntelligence,
    isRedirectToOldInterface,
    getRedirectToIntelligence,
} from "../../utils/redirectTo";
import { SET_AUTHENTICATION_NEW } from "../../app/actions/actionTypes";

const SSO_REDIRECT = "sso_redirect";

export const OktaAuthentication = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const redirectLocation = useSelector((state) => state.app.global.redirectLocation);
    const storeAuthData = useSelector((state) => state.app && state.app.authentication.data);

    const oktaAuth = useMemo(() => {
        return new OktaAuth(oktaAuthConfig);
    }, []);

    useEffect(() => {
        async function init() {
            // eslint-disable-next-line no-extra-boolean-cast
            if (window.location.pathname === "/login/sso" && !!oktaAuth) {
                const urlParams = new URLSearchParams(window.location.search);

                const token = oktaAuth?.authStateManager?._authState?.accessToken?.accessToken;
                let goTo = window.localStorage.getItem(SSO_REDIRECT);
                // eslint-disable-next-line no-extra-boolean-cast
                if (!!goTo) {
                    goTo = goTo.replace(/['"]+/g, "");
                    window.localStorage.removeItem(SSO_REDIRECT);
                }

                // eslint-disable-next-line no-extra-boolean-cast
                if (!!token) {
                    // User already logged in
                    history.push(goTo ?? "/dashboard");
                    // eslint-disable-next-line no-extra-boolean-cast
                } else if (!!urlParams.get("code")) {
                    // Parse url -> gets okta authorization code & requests token
                    oktaAuth.token
                        .parseFromUrl({ code: urlParams.get("code") })
                        .then((response) => {
                            // Store tokens into app to handle api calls
                            oktaAuth.tokenManager.setTokens(response?.tokens);

                            dispatch({
                                type: SET_AUTHENTICATION_NEW,
                                payload: {
                                    data: {
                                        ...storeAuthData,
                                        token: response?.tokens?.accessToken?.accessToken,
                                        // refresh_token, TODO GET REFRESH_TOKEN
                                    },
                                    loading: false,
                                    error: null,
                                },
                            });
                            history.push(goTo ?? "/dashboard");
                        });
                } else if (!token) {
                    const redirectTo = urlParams.get("redirectTo");
                    // eslint-disable-next-line no-extra-boolean-cast
                    if (!!redirectTo) {
                        // Store redirectTo into localStorage to keep the information after authentication redirects
                        window.localStorage.setItem(SSO_REDIRECT, JSON.stringify(redirectTo));
                    }
                    await oktaAuth.signInWithRedirect({
                        responseType: "code",
                        scopes: ["openid", "console", "email", "offline_access"],
                    });
                }
            }
        }
        init();
    }, [oktaAuth, redirectLocation]);

    const customAuthHandler = () => {
        history.push("/login");
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const oktaToken = _oktaAuth?.authStateManager?._authState?.accessToken?.accessToken;

        const params = new URLSearchParams(window.location.search);

        // Save okta access token into store for RSAA requests
        // Called when user submits login form
        dispatch(setAuthentication({ token: oktaToken }));

        if (params.get("redirectTo") && isRedirectToOldInterface(params.get("redirectTo"))) {
            history.push("/tpp/redirect?redirectTo=" + params.get("redirectTo"));
        } else if (params.get("redirectTo") && isRedirectToIntelligence(params.get("redirectTo"))) {
            window.location.href = getRedirectToIntelligence(params.get("redirectTo"), oktaToken);
        } else {
            // eslint-disable-next-line no-extra-boolean-cast
            history.push(!!originalUri ? originalUri : "/dashboard");
        }
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            {children}
        </Security>
    );
};
