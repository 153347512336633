import React, { useEffect, useState } from "react";
import { Ability, AbilityBuilder } from "@casl/ability";
import { AbilityContext } from "./ability-context";
import { useUser } from "../user";
import { useModuleList } from "../config";
import moize from "moize";

export const defineAbilitiesForUser = (user, moduleConfigList) => {
    const { rules, can, cannot } = AbilityBuilder.extract();

    const privilegeSlug = (module, controller, action) => {
        return `${module || "default"}/${controller || "default"}/${action || "default"}`;
    };

    const MemoizedPrivilegeSlug = moize(privilegeSlug, { maxSize: 1 });

    if (user) {
        if (
            user.roles &&
            (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_MERCHANT_ADMIN"))
        ) {
            /*
             * Users with ROLE_ADMIN can do everything (manage, all).
             */
            can("manage", "all");
        } else {
            let aclModuleList = {};
            if (moduleConfigList) {
                moduleConfigList.forEach((config) => {
                    if (config.acl && config.acl.module) {
                        config.acl.module.forEach((acl) => {
                            aclModuleList[
                                MemoizedPrivilegeSlug(acl.module, acl.controller, acl.action)
                            ] = {
                                acl: acl,
                                can: false,
                            };
                        });
                    }
                });
            }
            /*
             * Create casl abilities based on old interface users privileges.
             * from : module: transaction, controller: index, action: refund-transaction
             * to   : can('refund-transaction', 'transaction/index')
             * use  : <Can I="refund-transaction" of="transaction/index">...</Can>
             */
            user.privileges.forEach(({ module, controller, action }) => {
                can(action || "default", `${module || "default"}/${controller || "default"}`);

                // Manage modules specifics acl
                if (moduleConfigList) {
                    let moduleAcl =
                        aclModuleList[MemoizedPrivilegeSlug(module, controller, action)]?.acl;

                    if (!moduleAcl && action === null && !!module && !!controller) {
                        // User access to controller without any specific action
                        for (let key in aclModuleList) {
                            if (
                                aclModuleList.hasOwnProperty(key) &&
                                key.indexOf(`${module}/${controller}/`) === 0
                            ) {
                                aclModuleList[key].can = true;
                            }
                        }
                    } else if (
                        moduleAcl &&
                        module === moduleAcl.module &&
                        controller === moduleAcl.controller &&
                        (!moduleAcl.action || action === moduleAcl.action || !action)
                    ) {
                        aclModuleList[MemoizedPrivilegeSlug(module, controller, action)].can = true;
                    }
                }
            });

            /*
             * Add casl abilities based on user settings
             */
            if (user.allAccountsAccess === true) {
                can("manage", "all-accounts");
            }

            if (moduleConfigList) {
                moduleConfigList.forEach((config) => {
                    let hasAccessToModule = true;
                    if (config.acl && config.acl.module) {
                        config.acl.module.forEach((acl) => {
                            if (
                                !aclModuleList[
                                    MemoizedPrivilegeSlug(acl.module, acl.controller, acl.action)
                                ].can
                            ) {
                                hasAccessToModule = false;
                            }
                        });
                    }

                    if (hasAccessToModule) {
                        can("manage-module", config.id);
                    }
                });
            }
        }

        // Dissociate rgpdCompliance and admin roles
        if (user.rgpdCompliance === true) {
            can("read", "gdpr");
        } else {
            cannot("read", "gdpr");
        }
    }

    return new Ability(rules);
};

export const AbilityProvider = ({ children }) => {
    const [ability, setAbility] = useState();
    const { getModuleConfigList } = useModuleList();

    const user = useUser();

    /*
     * Update abilities on user change
     */
    useEffect(() => {
        setAbility(defineAbilitiesForUser(user, getModuleConfigList()));
    }, [user, getModuleConfigList]);

    return (
        <AbilityContext.Provider value={ability}>
            {React.Children.only(children)}
        </AbilityContext.Provider>
    );
};
