const acquirers = {
    id: "acquirers",
    endpoint: "/internal/acquirers",
    withSelectedAccounts: true,
    translate: false,
    reduceFunc: (previous, current) =>
        typeof current["acquirerId"] !== "undefined"
            ? [
                  ...previous,
                  {
                      id: current["acquirerId"].toString(),
                      label: current["acquirer"],
                      info: current["acquirerId"],
                  },
              ]
            : previous,
};

export default acquirers;
