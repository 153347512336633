const storesData = {
    id: "stores",
    endpoint: "/store-names",
    withSelectedAccounts: true,
    organizeFunc: (stores, p) => {
        const mappedStores = stores.map((store) => {
            return {
                id: store.id.toString(),
                label: store.storeName,
            };
        });

        // alphabetic sort
        return mappedStores.sort((a, b) => {
            return a.label.toLowerCase() === b.label.toLowerCase()
                ? 0
                : a.label.toLowerCase() < b.label.toLowerCase()
                ? -1
                : 1;
        });
    },
};

export default storesData;
