import * as cst from "./constants";

/**
 * Notice Context
 *
 * This is an object build from a redirection
 * to have every informations required to manage Notice navigation.
 *
 * example:
 * 1/ Redirection from Search
 * {
 *      referer: '/search?inSearch=true',
 *      query: 'order_by=created_date&direction=desc',
 *      total: 789,
 *      entities: { 123456789: { ... }, ..., 123456799: { ... } },
 *      idList: [ 123456789, ..., 113456799 ],
 *      outset: 44,
 * }
 *
 */
export class NoticeContext {
    constructor(referer, query, total, entities, idList, outset) {
        /**
         * Referer (string) - url redirection came from
         * * used for back button
         */
        this.referer = referer;
        /**
         * Query (string) - request the results came from
         * * used for fetch prev/next entities
         * * don't have size/offset params
         */
        this.query = query;
        /**
         * Total (number) - number of results for the query
         * * used to know if there is next results
         */
        this.total = total;
        /**
         * Entities (object) - module relative entities keyed by id
         * * following data/loading/error pattern
         */
        this.entities = entities;
        /**
         * IdList (array) - ordered array of module relative entities id
         * * navigation is based on it
         */
        this.idList = idList;
        /**
         * Outset (number) - absolute index of the first idList element into total entities results
         * * used to calculate the absolute offset of prev/next request
         */
        this.outset = outset;
    }

    static fromContext(history, query, total, datas, rowId, allDatas = false) {
        let referer = history.createHref(history.location);

        // absolute index of the first item of idList ( currentId - SIZE_NOTICE )
        let outset = allDatas
            ? 0
            : Math.max(0, datas.findIndex((entity) => entity.rowId === rowId) - cst.SIZE_NOTICE);
        // index of the last item of idList
        let sliceLimit = allDatas
            ? datas.length
            : Math.min(datas.length, outset + 2 * cst.SIZE_NOTICE + 1);

        // persist results as entities
        const { entities, idList } = datas.slice(outset, sliceLimit).reduce(
            (memo, entity) => {
                let id = entity.rowId.toString();
                return {
                    entities: {
                        ...memo.entities,
                        [id]: {
                            data: entity,
                            loading: false,
                            error: null,
                        },
                    },
                    idList: [...memo.idList, id],
                };
            },
            {
                entities: {},
                idList: [],
            }
        );

        return new NoticeContext(referer, query, total, entities, idList, outset);
    }

    static fromNoContext(rowId) {
        return new NoticeContext(null, null, 1, {}, [rowId], 0);
    }

    /**
     * Create notice context from LocalStorage OR from NoContext
     *
     * @returns {*}
     */
    static fromLocalStorage(rowId = null) {
        // From LS
        const noticeContext = window.localStorage.getItem(process.env.NX_NOTICE_CONTEXT);
        if (noticeContext) {
            // clear LS
            window.localStorage.removeItem(process.env.NX_NOTICE_CONTEXT);
            return NoticeContext.fromString(noticeContext);
        }
        // From no context
        return NoticeContext.fromNoContext(rowId);
    }

    /**
     * Create NoticeContext from JSON
     *
     * @param json
     * @returns {NoticeContext}
     */
    static fromJSON = (json) => {
        const { referer, query, total, entities, idList, outset } = json;
        return new NoticeContext(referer, query, total, entities, idList, outset);
    };

    /**
     * Create NoticeContext from stringified JSON
     * @param str
     * @returns {*}
     */
    static fromString = (str) => {
        try {
            return NoticeContext.fromJSON(JSON.parse(str));
        } catch (e) {
            return null;
        }
    };

    toJSON = () => ({
        referer: this.referer,
        query: this.query,
        total: this.total,
        entities: this.entities,
        idList: this.idList,
        outset: this.outset,
    });

    toString = () => JSON.stringify(this.toJSON());

    equals(o) {
        if (o instanceof NoticeContext) {
            return this.toString() === o.toString();
        }
        return false;
    }

    /**
     * Specified id is present in entities
     * @param id
     * @returns {boolean}
     */
    hasEntity(id) {
        return this.entities.hasOwnProperty(id);
    }
    /**
     * Get entity by id from entities
     * @param id
     * @returns {boolean}
     */
    getEntity(id) {
        return this.entities[id];
    }

    /**
     * Can navigate as long as
     * * there is more than one result
     * * idList is set
     * @returns {boolean}
     */
    canNavigate() {
        return this.total > 1 && this.idList.length > 1;
    }

    /**
     * Return previous id in idList
     * @param id
     * @returns {*}
     */
    getPreviousId(id) {
        const relativeIndex = this.idList.indexOf(id);
        if (relativeIndex > 0) {
            return this.idList[relativeIndex - 1];
        }
        return null;
    }

    /**
     * Return next id in idList
     * @param id
     * @returns {*}
     */
    getNextId(id) {
        const relativeIndex = this.idList.indexOf(id);
        if (relativeIndex !== -1 && relativeIndex < this.idList.length - 1) {
            return this.idList[relativeIndex + 1];
        }
        return null;
    }

    /**
     * Previous limit is reached
     * means that current notice is under the MARGIN from the first loaded entity
     * manager should request for previous entities
     * @param relativeIndex - index of the current entity in idList
     * @returns {boolean}
     */
    previousLimitIsReached = (relativeIndex) =>
        relativeIndex < cst.MARGIN_NOTICE && this.outset > 0;

    /**
     * Next limit is reached
     * means that current notice is under the MARGIN from the last loaded entity
     * manager should request for next entities
     * @param relativeIndex - index of the current entity in idList
     * @returns {boolean}
     */
    nextLimitIsReached = (relativeIndex) =>
        this.idList.length - relativeIndex <= cst.MARGIN_NOTICE &&
        this.outset + this.idList.length < this.total;

    /**
     * Fetch previous entities
     * @param fetchEntitiesByQuery
     */
    fetchPreviousEntities(fetchEntitiesByQuery) {
        const offset = Math.max(0, this.outset - cst.SIZE_NOTICE);
        const size = Math.min(cst.SIZE_NOTICE, this.outset);
        fetchEntitiesByQuery(this.query, offset, size, "prev");
    }

    /**
     * Fetch next entities
     * @param fetchEntitiesByQuery
     */
    fetchNextEntities(fetchEntitiesByQuery) {
        const offset = this.outset + this.idList.length;
        const size = Math.min(cst.SIZE_NOTICE, this.total - this.idList.length - this.outset);
        fetchEntitiesByQuery(this.query, offset, size, "next");
    }

    /**
     * Manage notice entities
     * call to fetch function if required by current notice position
     * fetch previous entities if previous margin is reached
     * fetch next entities if next margin is reached
     *
     * @param currentId
     * @param fetchEntitiesByQuery
     */
    manageNoticeEntities(currentId, fetchEntitiesByQuery) {
        if (this.canNavigate()) {
            const relativeIndex = this.idList.indexOf(currentId);

            if (relativeIndex === -1) {
                console.warn(
                    `Current entities id "${currentId}" is not found in notice context id list`
                );
            } else {
                // Fetch previous entities if MARGIN is reached
                if (this.previousLimitIsReached(relativeIndex)) {
                    this.fetchPreviousEntities(fetchEntitiesByQuery);
                }

                // Fetch next entities if MARGIN is reached
                if (this.nextLimitIsReached(relativeIndex)) {
                    this.fetchNextEntities(fetchEntitiesByQuery);
                }
            }
        }
    }
}
