import * as colors from "@mui/material/colors";
import { mainColors, mainPalette } from "../constants";

/**
 * Generate Profile Color from initials
 * @type {[*]}
 */
export function generateProfileColor(value) {
    // Get main color from first letter
    let mainColor = mainColors[value.charCodeAt(0) % mainColors.length];
    // Get palette from second letter
    let palette = mainPalette[value.charCodeAt(1) % mainPalette.length];
    return colors[mainColor][palette];
}
