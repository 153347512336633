import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import HiPin from "@hipay/hipay-material-ui/HiPin";
import classNames from "classnames";

const stylesCellPinToAction = (theme) => ({
    wrapper: {
        width: "100%",
        display: "inline-flex",
        margin: "auto",
        justifyContent: "inherit",
    },
    pinZeroActive: {
        "&:hover": {
            backgroundColor: theme.palette.neutral.main,
            color: "#FFFFFF",
        },
    },
    pinZeroInactive: {
        "&:hover": {
            backgroundColor: theme.palette.neutral.main,
            color: "#FFFFFF",
        },
    },
});

/**
 * Cette cellule permet d'afficher un pin avec callback (optionnel).
 */
class CellPinToActionClass extends React.PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Pin number to display
         */
        number: PropTypes.number,
        /**
         * Fonction de callback onClick sur le pin
         */
        onClick: PropTypes.func,
    };

    static defaultProps = {
        active: false,
        number: 0,
    };

    render() {
        const { classes, number, active, className } = this.props;

        if (number === undefined) {
            return "";
        }

        return (
            <div className={classNames(classes.wrapper, className)} data-pin="true">
                <HiPin
                    color={active && number !== 0 ? "primary" : "default"}
                    className={classNames({
                        [classes.pinActive]: active && number !== 0,
                        [classes.pinZeroActive]: active && number === 0,
                        [classes.pinZeroInactive]: !active && number === 0,
                        [classes.pinInactive]: !active && number !== 0,
                    })}
                >
                    {number}
                </HiPin>
            </div>
        );
    }
}

export const CellPinToAction = withStyles(stylesCellPinToAction, { name: "HmuiCellPin" })(
    CellPinToActionClass
);
