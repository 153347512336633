import React from "react";
import SameDayRefundIcon from "../../components/Icon/SameDayRefundIcon";

const sameDayRefund = {
    id: "same_day_refund",
    data: [
        {
            id: "1",
            label: "modules.transaction.attributes.boolean.values.yes",
            icon: <SameDayRefundIcon />,
            showLabel: true,
        },
        {
            id: "0",
            label: "modules.transaction.attributes.boolean.values.no",
        },
    ],
    translate: true,
};

export default sameDayRefund;
