import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { translate } from "../../../../services/i18n/translate";
import withStyles from "@mui/styles/withStyles";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import FormFieldBuilder from "../../../containers/Search/Form/FormFieldBuilder";
import { FormFieldsManager } from "../../../containers/Search/Form/FormFieldsManager";
import { formFieldTypes } from "../../../constants/formFieldTypes";

import { arraysAreEqualByValue } from "../../../../utils/arrays";
import { getAttributeEntities } from "../../../../utils/attributes";
import { getFiltersWithRestrictedByAccountParams } from "../../../../utils/entities";
import { buildFormFields } from "../../../../utils/forms";
import { setEventTracker } from "../../../../services/tracker";

const stylesForm = (theme) => ({
    root: {},
    form: {
        width: "100%",
        maxWidth: 500,
        margin: "auto",
        padding: "0 8px 0 8px",
        textAlign: "left",
    },
    formField: {
        paddingTop: 22,
        display: "flex",
        position: "relative",
    },
    formControls: {
        paddingTop: 38,
        paddingBottom: 26,
    },
    formButton: { width: "50%" },
    formButtonLarge: { width: "100%" },
    newFilters: { paddingTop: 20 },
    formControl: { flex: "auto" },
    deleteFilter: {
        color: theme.palette.neutral.main,
        width: 40,
        height: 40,
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    formControlLabel: {
        marginLeft: 0,
        height: 40,
    },
    customDataSuggestPopper: { width: "50%" },
});

class FormClass extends React.Component {
    static propTypes = {
        /**
         * Selected module
         */
        module: PropTypes.string.isRequired,
        /**
         * AccountEntities
         */
        accountEntities: PropTypes.object,
        /**
         * Attributes
         */
        attributes: PropTypes.object,
        /**
         * Available fields on form
         */
        availableFields: PropTypes.array,
        /**
         * Default form fields to intialize form when deletable
         */
        defaultForm: PropTypes.object,
        /**
         * Form fields are not deletable
         */
        deletable: PropTypes.bool,
        /**
         * Object with form fields & values
         */
        formParams: PropTypes.object.isRequired,
        /**
         * Is the form displayed from TopBar (QuickForm)
         */
        isQuickForm: PropTypes.bool.isRequired,
        /**
         * Add "New Filters" button (FormFieldsManager)
         * allow to add new fields to the form
         */
        newFilters: PropTypes.bool,
        /**
         * Callback redirect
         * Si définie, on affiche un lien vers la recherche avancée
         */
        onRedirectToSearch: PropTypes.func,
        /**
         * Callback submit form params
         */
        onSubmit: PropTypes.func.isRequired,
        /**
         * Object with connected user informations
         */
        connectedUser: PropTypes.object,
        /**
         * Set form fields from formParams
         */
        fromRedirect: PropTypes.bool,
        /**
         * In "inSearch" mode, form will only update and add additional fields from form params
         */
        inSearch: PropTypes.bool,
        /**
         * Module references list
         */
        moduleReferences: PropTypes.object,
        /**
         * Store references list
         */
        storeRef: PropTypes.object,
        /**
         * Suggestion route params
         */
        suggestRouteParams: PropTypes.object,
    };

    static defaultProps = {
        deletable: true,
        newFilters: true,
        fromRedirect: false,
        inSearch: false,
        isQuickForm: false,
    };

    constructor(props) {
        super(props);

        const emptyFormParams = Object.keys(props.formParams).length === 0;

        this.state = {
            formError: [],
            formFields: buildFormFields(
                props.defaultForm,
                {},
                props.formParams,
                props.fromRedirect,
                props.inSearch,
                false,
                emptyFormParams
            ),
            formOptions: {},
            options: {},
            suggestions: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset form when module or defaultForm changes (from quickForm) - PSYCHE-3865
        /**
         * Update formFields if location.key has changed
         * - reset deleted params
         * - set undefined params
         * - keep others fields
         */
        if (
            prevProps.module !== this.props.module ||
            JSON.stringify(prevProps.defaultForm) !== JSON.stringify(this.props.defaultForm)
        ) {
            let newFields = buildFormFields(
                this.props.defaultForm,
                {},
                this.props.formParams,
                this.props.fromRedirect,
                this.props.inSearch,
                false,
                true
            );
            this.setState(() => ({ formFields: newFields }));
        } else {
            if (
                JSON.stringify(prevProps.location.key) !== JSON.stringify(this.props.location.key)
            ) {
                let changeAccount =
                    (!prevProps.match.params.ids && !!this.props.match.params.ids) ||
                    (!!prevProps.match.params.ids && !this.props.match.params.ids);
                if (!changeAccount && prevProps.match.params.ids && this.props.match.params.ids) {
                    changeAccount = !arraysAreEqualByValue(
                        prevProps.match.params.ids.split("-"),
                        this.props.match.params.ids.split("-")
                    );
                }
                this.setState((_prevState) => ({
                    formFields: buildFormFields(
                        this.props.defaultForm,
                        _prevState.formFields,
                        this.props.formParams,
                        this.props.fromRedirect,
                        this.props.inSearch,
                        changeAccount
                    ),
                }));
            }

            // Suppression des champs dépendants de la sélection des comptes
            if (
                ((Array.isArray(prevState.formFields.account) &&
                    prevState.formFields.account.length) ||
                    (Array.isArray(this.state.formFields.account) &&
                        this.state.formFields.account.length)) &&
                JSON.stringify(prevState.formFields.account) !==
                    JSON.stringify(this.state.formFields.account)
            ) {
                this.deleteRestrictedParams(false, this.state.formFields.account);
            } else if (
                !this.state.formFields.account &&
                this.props.selectedAccountIdList &&
                JSON.stringify(prevProps.selectedAccountIdList) !==
                    JSON.stringify(this.props.selectedAccountIdList)
            ) {
                this.deleteRestrictedParams(true, this.props.selectedAccountIdList);
            }
        }
    }

    deleteRestrictedParams = (isGlobalList, selectedAccountIdList = []) => {
        const { attributes, storeRef, p, enqueueSnackbar, onDeleteFilter, module } = this.props;
        const { formFields } = this.state;
        let deletedFilterLabels = [];
        Object.keys(formFields).forEach((key) => {
            const attribute = attributes[key.replace(new RegExp("_[0-9]+$", "ig"), "")];
            const attributeEntities = getAttributeEntities(
                attribute,
                { accountIdList: selectedAccountIdList },
                storeRef,
                p
            );
            if (
                formFields[key] &&
                attribute &&
                attribute.formFieldOptions.restrictedBySelectedAccountList
            ) {
                Object.values(formFields[key]).forEach((value) => {
                    if (!attributeEntities || attributeEntities[value]) {
                        if (isGlobalList) {
                            onDeleteFilter(key, "form", formFields[key]);
                        }

                        this.setState((prevState) => ({
                            ...prevState,
                            formFields: {
                                ...prevState.formFields,
                                [key]: [],
                            },
                        }));
                    }
                });
                deletedFilterLabels.push(p.t(`attributes.${module}.${attribute.id}.tableLabel`));
            }
        });
        if (deletedFilterLabels.length > 0) {
            enqueueSnackbar(
                p.t("common.search.filters.snackbar_filter_deleted", {
                    filters_name: deletedFilterLabels.join(", "),
                    smart_count: deletedFilterLabels.length,
                })
            );
        }
    };

    handleFormErrors = (id, formError) => {
        let prevFormError = [...this.state.formError];
        if (formError && this.state.formError.indexOf(id) < 0) {
            this.setState({ formError: prevFormError.concat([id]) });
        } else if (!formError && this.state.formError.indexOf(id) >= 0) {
            this.setState({ formError: prevFormError.filter((fieldId) => fieldId !== id) });
        }
    };

    /**
     * Call onSubmit to request on form values
     */
    handleFormSubmit = (event) => {
        event.preventDefault();

        this.props.onSubmit(this.state.formFields);
    };

    /**
     * Call onRedirectToSearch to redirect with form values
     */
    handleRedirectToSearch = () => {
        this.props.onRedirectToSearch(this.state.formFields);
    };

    /**
     * Remove field from form
     *
     * @param id
     * @returns {Function}
     */
    handleRemoveFormField = (id) => {
        setEventTracker("remove_filter", {
            event_category: "advanced_search",
            event_action: "advanced_search_remove_filter",
        });
        const dryId = id.replace(new RegExp("_[0-9]+$", "ig"), "");
        const attribute = this.props.attributes[dryId];
        this.setState((prevState) => {
            let newFormFields = { ...prevState.formFields };

            // Remove relative multiFields
            if (attribute.formFieldOptions.multiFields) {
                Object.values(attribute.formFieldOptions.multiFields).forEach((f) => {
                    delete newFormFields[f.id];
                });
            }

            // Remove relativeFormFields
            if (attribute.formFieldOptions.relativeFormFields) {
                attribute.formFieldOptions.relativeFormFields.forEach((k) => {
                    delete newFormFields[k];
                });
            }

            // Remove form field
            delete newFormFields[id];

            return {
                ...prevState,
                formFields: newFormFields,
            };
        });
    };

    /**
     * Add selected field in formFieldsManager to form
     *
     * @param formFields
     */
    handleSubmitFieldsManager = (formFields) => {
        this.setState((prevState) => ({
            ...prevState,
            formFields: formFields.reduce(
                (memo, fId) => ({
                    ...memo,
                    [fId]: null,
                }),
                prevState.formFields
            ),
        }));
    };

    /**
     * Update formFields value on user action
     *
     * @param name
     * @param value
     */
    handleChangeFormField = (name, value) => {
        this.setState((prevState) => {
            // Name without index & key/value
            const attributeName = name
                .replace(new RegExp("_key|_value", "ig"), "")
                .replace(new RegExp("_[0-9]+$", "ig"), "");

            const attribute = this.props.attributes[attributeName];

            let newFormFields = { ...prevState.formFields };

            // FORM_FIELD_CUSTOM_DATA
            if (attribute && attribute.formFieldType === formFieldTypes.FORM_FIELD_CUSTOM_DATA) {
                const customDataFieldName = name.replace(new RegExp("_key|_value", "ig"), "");
                const match = name.match(new RegExp("_key|_value", "ig"));
                if (match) {
                    const subName = match[0].replace("_", "");
                    newFormFields[customDataFieldName] = {
                        ...prevState.formFields[customDataFieldName],
                        [subName]: value,
                    };
                } else {
                    return null;
                }
            } else if (attribute && attribute.formFieldType === formFieldTypes.FORM_FIELD_DATE) {
                newFormFields[name] = {
                    ...prevState.formFields[name],
                    ...value,
                };
            } else if (attribute && attribute.formFieldType === formFieldTypes.FORM_FIELD_SWITCH) {
                newFormFields[name] = value || undefined;
            } else {
                // OTHERS / DEFAULT
                newFormFields[name] = value;
            }

            // Add default multiFields value if undefined
            if (attribute && attribute.formFieldOptions && attribute.formFieldOptions.multiFields) {
                Object.values(attribute.formFieldOptions.multiFields).forEach((field) => {
                    if (!newFormFields[field.id]) {
                        newFormFields[field.id] = field.value;
                    }
                });
            }

            // Reset relative field
            if (
                attribute &&
                attribute.formFieldOptions &&
                attribute.formFieldOptions.relativeFormFields
            ) {
                attribute.formFieldOptions.relativeFormFields.forEach((fId) => {
                    newFormFields[fId] = undefined;
                });
            }

            return {
                ...prevState,
                formFields: { ...newFormFields },
            };
        });
    };

    /**
     * Reset field value
     * only enable for text/suggest field
     * @param name
     */
    handleResetFormField = (name) => () => {
        this.setState((prevState) => ({
            ...prevState,
            formFields: {
                ...prevState.formFields,
                [name]:
                    typeof prevState.formFields[name] === "object"
                        ? {
                              ...prevState.formFields[name],
                              value: "",
                          }
                        : "",
            },
        }));
    };

    renderField = (id, index, isRelative = false, fieldDeletable = true) => {
        const {
            deletable,
            p,
            minimumDate,
            connectedUser,
            attributes,
            storeRef,
            moduleReferences,
            module,
            isQuickForm,
            accountEntities,
            suggestRouteParams,
        } = this.props;

        const { formFields, formError } = this.state;

        let indexedId = id;
        id = id.replace(new RegExp("_[0-9]+$", "ig"), "");
        const attribute = attributes[id];

        if (
            attribute &&
            (!attribute.rgpdCompliance || connectedUser.rgpdCompliance) &&
            attribute.formFieldType
        ) {
            /*
             * If the field is linked to another field,
             * let it be displayed as a linked field, do not display it
             * as a standalone field
             */
            if (attribute.relativeField && !isRelative) {
                return;
            }

            if (
                !connectedUser.allAccountsAccess &&
                attribute.needMultipleAccounts &&
                accountEntities &&
                Object.keys(accountEntities).length === 1
            ) {
                return;
            }

            const args = {
                accountIdList: formFields.account,
                sentinel_profile: formFields["sentinel_profile"],
                p,
            };

            const restrictedFilters = getFiltersWithRestrictedByAccountParams(
                module,
                formFields,
                attributes,
                p
            );

            const linkedFields = attribute.formFieldOptions.linkedFields; // Get value from linked fields
            const multiFields = attribute.formFieldOptions.multiFields; // Get value from multiFields
            const fieldProps = {
                id: indexedId,
                value: formFields[indexedId],
                fieldType: attribute.formFieldType,
                fieldOptions: {
                    ...attribute.formFieldOptions,
                    ...(attribute.formFieldOptions.translations && {
                        translations: Object.keys(attribute.formFieldOptions.translations).reduce(
                            (memo, key) => {
                                return {
                                    ...memo,
                                    [key]: p.t(attribute.formFieldOptions.translations[key]),
                                };
                            }
                        ),
                    }),
                },
                relativeReference: attribute.relativeReference,
                reference: attribute.reference,
                storeRef: storeRef,
                moduleReferences: moduleReferences,
                onChange: this.handleChangeFormField,
                ...(deletable && { onRemove: this.handleRemoveFormField }),
                onReset: this.handleResetFormField,
                onSubmit: this.handleFormSubmit,
                // Get linkedFields from form as object
                ...(linkedFields && {
                    linkedFields: Object.keys(linkedFields).reduce((memo, field) => {
                        return {
                            ...memo,
                            [field]: formFields[linkedFields[field]],
                        };
                    }, {}),
                }),
                // Get multiFields values from form as object
                ...(multiFields && {
                    multiFields: Object.keys(multiFields).reduce((memo, field) => {
                        return {
                            ...memo,
                            [field]: formFields[multiFields[field].id],
                        };
                    }, {}),
                }),
                isRelativeField: isRelative,
                // Get relativeField value from form
                ...(attribute.relativeField && {
                    relativeFieldValue: formFields[attribute.relativeField],
                }),
                deletable: deletable && fieldDeletable,
                handleFormErrors: this.handleFormErrors,
                hasError: formError.includes(indexedId),
                minimumDate: { minimumDate },
                selectedAccountIdList:
                    formFields.account && formFields.account.length
                        ? formFields.account
                        : this.props.selectedAccountIdList,
                args: args,
                attribute: attribute,
                module: module,
                isQuickForm,
                restrictedFilters: restrictedFilters,
                formFields: formFields,
                suggestRouteParams: suggestRouteParams,
            };
            fieldProps.args.accountIdList = [...fieldProps.selectedAccountIdList];

            let ret = [<FormFieldBuilder key={`${id}_${index}`} {...fieldProps} />];

            if (attribute.formFieldOptions && attribute.formFieldOptions.relativeFormFields) {
                for (let i = 0; i < attribute.formFieldOptions.relativeFormFields.length; ++i) {
                    ret.push(
                        this.renderField(
                            attribute.formFieldOptions.relativeFormFields[i],
                            index,
                            true,
                            false
                        )
                    );
                }
            }

            return ret;
        }
        return null;
    };

    render() {
        const { classes, availableFields, newFilters, onRedirectToSearch, p } = this.props;

        const { formFields, formError } = this.state;

        return (
            <form id="search-form" className={classes.form} onSubmit={this.handleFormSubmit}>
                {Object.keys(formFields).map((id, index) => {
                    return this.renderField(id, index);
                })}

                {newFilters && (
                    <FormFieldsManager
                        className={classes.newFilters}
                        availableFields={availableFields}
                        onSubmit={this.handleSubmitFieldsManager}
                        currentFields={Object.keys(formFields)}
                    />
                )}

                <div className={classes.formControls}>
                    {onRedirectToSearch && (
                        <HiButton
                            id="search-form-redirect"
                            className={classes.formButton}
                            variant="text"
                            color="primary"
                            onClick={this.handleRedirectToSearch}
                            disabled={formError.length > 0}
                        >
                            {p.t("common.search.buttons.advanced_search")}
                        </HiButton>
                    )}
                    <HiButton
                        id="search-form-submit"
                        type="submit"
                        className={classNames(classes.formButton, {
                            [classes.formButtonLarge]: !onRedirectToSearch,
                        })}
                        variant="contained"
                        color="primary"
                        onClick={this.handleFormSubmit}
                        disabled={formError.length > 0}
                    >
                        {p.t("common.search.buttons.search")}
                    </HiButton>
                </div>
            </form>
        );
    }
}

export const Form = compose(withSnackbar, withStyles(stylesForm), withRouter, translate)(FormClass);
