import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import ArrowLeft from "mdi-material-ui/ArrowLeft";
import { ErrorBoundary } from "../../../../app/components/common/ErrorBoundary";
import { ChooseAndOrganize } from "../../ChooseAndOrganizeContainer/ChooseAndOrganize";
import { useP } from "../../../../services/i18n";
import { useModule } from "../../../../services/module";

const useStylesPanelOrderKpis = makeStyles((theme) => ({ header: { ...theme.typography.h3 } }));

export const PanelOrderKpis = React.forwardRef((props, ref) => {
    const { onClose, onGoBack, orderedKpis, defaultKpis, onKpiOrderUpdate, persistOrderedKpis } =
        props;

    const classes = useStylesPanelOrderKpis();
    const p = useP();
    const { id: moduleId } = useModule();
    const userSettings = useSelector((state) => state.app.settings.data);

    const kpisConfig = useMemo(
        () => userSettings?.analyticsBannerConfig?.[moduleId]?.kpis || [],
        [userSettings]
    );

    const kpiLabels = useMemo(() => {
        let obj = {};
        defaultKpis.forEach((kpi) => {
            obj[kpi.id] = p.t(`modules.${moduleId}.kpi.${kpi.id}.label`);
        });
        return obj;
    }, [defaultKpis]);

    const hasUpdates = useMemo(() => {
        let updated = false;

        const displayedConfig = kpisConfig?.filter((kpi) => !!kpi.displayed) || [];
        const displayedOrderedKpis = orderedKpis.filter((kpi) => !!kpi.displayed);

        if (displayedConfig.length && displayedConfig.length === displayedOrderedKpis.length) {
            displayedOrderedKpis.forEach((orderedKpi, index) => {
                if (
                    !updated &&
                    !!orderedKpi.displayed &&
                    JSON.stringify(orderedKpi) !== JSON.stringify(displayedConfig[index])
                ) {
                    updated = true;
                }
            });
        } else {
            updated = true;
        }
        return updated;
    }, [kpisConfig, orderedKpis]);

    const handleKeyDown = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    return (
        <div ref={ref}>
            <div className={classes.header}>
                <HiIconButton onKeyDown={handleKeyDown} onClick={onGoBack} color="neutral">
                    <ArrowLeft />
                </HiIconButton>
                <span>{p.t("common.search.settings.order_columns")}</span>
            </div>
            <ErrorBoundary>
                <ChooseAndOrganize
                    name="analytics-banner"
                    orderedItems={orderedKpis}
                    defaultItems={defaultKpis}
                    updateOrderedItems={onKpiOrderUpdate}
                    persistOrderedItems={persistOrderedKpis}
                    itemsLabels={kpiLabels}
                    hasUpdates={hasUpdates}
                    onClose={onClose}
                />
            </ErrorBoundary>
        </div>
    );
});
