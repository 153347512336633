import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ContextMenuContext } from "./ContextMenu.context";
import {
    MenuItemCopy,
    MenuItemSearch,
    MenuItemExclude,
    MenuItemOpenNotice,
} from "./ContextMenu.menuItems";
import { setEventTracker } from "../tracker";

/**
 * Context Menu
 * Provide a function onContextMenu to trigger menu
 * Display a menu with attribute/value actions :
 * - lookup
 * - copy
 */
export const ContextMenuProvider = ({ children, ...props }) => {
    const { attributes, onSearch, extraItems, onRowClick } = props;

    /* state context menu
     */
    const [{ mouseX, mouseY, data = {}, actions = [] }, setState] = React.useState({
        mouseX: null,
        mouseY: null,
    });

    /* close context menu
     */
    const handleClose = useCallback(() => {
        setState({
            mouseX: null,
            mouseY: null,
        });
    }, [setState]);

    const onContextMenu = useCallback(
        (event, _data, _actions) => {
            event.preventDefault();
            setState({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                data: _data,
                actions: _actions,
            });
        },
        [setState]
    );

    const searchItems = useCallback(
        (isLookup = false) => {
            return Object.keys(data).map((attributeName) => {
                const attribute = attributes[attributeName];
                const displayItem =
                    attribute && data[attributeName] && attribute.displayOptions.lookup !== false;
                return (
                    displayItem && (
                        <MenuItemSearch
                            key={`context-menu-item-search-${attributeName}`}
                            id={attributeName}
                            data={data[attributeName]}
                            onClose={handleClose}
                            onSearch={onSearch}
                            isLookup={isLookup}
                        />
                    )
                );
            });
        },
        [data, attributes, handleClose, onSearch]
    );

    const searchItemsSearch = useMemo(() => {
        return searchItems();
    }, [searchItems]);

    const searchItemsLookup = useMemo(() => {
        return searchItems(true);
    }, [searchItems]);

    const copyItems = useMemo(() => {
        return Object.keys(data).map((attributeName) => {
            const displayItem =
                data[attributeName] &&
                (!!data[attributeName].value || data[attributeName].value === 0);
            return (
                displayItem && (
                    <MenuItemCopy
                        key={`context-menu-item-copy-${attributeName}`}
                        id={attributeName}
                        data={data[attributeName]}
                        onClose={handleClose}
                    />
                )
            );
        });
    }, [data, attributes, handleClose]);

    const excludeItems = useMemo(() => {
        return Object.keys(data).map((attributeName) => {
            const attribute = attributes[attributeName];
            let displayItem =
                attribute && data[attributeName] && attribute.displayOptions.exclude !== false;
            if (displayItem && typeof attribute.displayOptions.exclude !== "undefined") {
                displayItem = attribute.displayOptions.exclude === data[attributeName].value;
            }

            return (
                displayItem && (
                    <MenuItemExclude
                        key={`context-menu-item-exclude-${attributeName}`}
                        id={attributeName}
                        data={data[attributeName]}
                        onClose={handleClose}
                        onSearch={onSearch}
                    />
                )
            );
        });
    }, [data, attributes, handleClose]);

    const onClickNoticeItem = useCallback(
        (event) => {
            if (data.rowId) {
                setEventTracker("open in new tab", {
                    event_category: "search_context",
                    event_action: "search_context_open_in_new_tab",
                });

                onRowClick(event, data.rowId, true);
            }
        },
        [onRowClick, data]
    );

    const openNoticeItems = useMemo(() => {
        const displayItem = !!onRowClick;
        return (
            displayItem && (
                <MenuItemOpenNotice
                    key="context-menu-item-open-notice"
                    onClose={handleClose}
                    onClick={onClickNoticeItem}
                />
            )
        );
    }, [data, handleClose]);

    const handleCustomItemClick = (item) => (event) => {
        if (item.onClick) {
            item.onClick(event);
        }
        handleClose();
    };

    const items = useMemo(() => {
        const action_items = {
            search: searchItemsSearch,
            lookup: searchItemsLookup,
            exclude: excludeItems,
            copy: copyItems,
            open_new_tab: openNoticeItems,
            _: <Divider key="divider" />,
        };
        let menuItems = actions
            .map((action) => {
                if (!action_items.hasOwnProperty(action)) {
                    return null;
                }
                return action_items[action];
            })
            .filter((action) => action !== null);

        if (extraItems && extraItems.length > 0) {
            extraItems.forEach((item) => {
                let display = true;
                if (typeof item.display === "function") {
                    display = item.display(data);
                }
                let content = item.content;
                if (
                    typeof item.searchSelectionMap !== "undefined" &&
                    typeof item.searchSelectionMap[data.rowId] !== "undefined"
                ) {
                    content = item.contentSelection;
                }
                if (display) {
                    menuItems.push([
                        <MenuItem
                            id={item.id}
                            data-row-id={`${data.rowId}`}
                            onClick={handleCustomItemClick(item)}
                        >
                            {content}
                        </MenuItem>,
                    ]);
                }
            });
        }
        return menuItems;
    }, [actions, searchItemsSearch, copyItems]);

    return (
        <ContextMenuContext.Provider value={onContextMenu}>
            {children}
            {!!data && (
                <Menu
                    keepMounted
                    open={mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        mouseY !== null && mouseX !== null
                            ? {
                                  top: mouseY,
                                  left: mouseX,
                              }
                            : undefined
                    }
                    elevation={1}
                    MenuListProps={{ disablePadding: true }}
                    style={{ width: 352 }}
                    id="context-menu-provider"
                >
                    {items}
                </Menu>
            )}
        </ContextMenuContext.Provider>
    );
};

ContextMenuProvider.propTypes = {
    attributes: PropTypes.object.isRequired,
    onSearch: PropTypes.func,
};
