export const MAX_FILE_PREFIX_LENGTH = 30;

export const weekday = {
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
    7: "sunday",
};
