import { useQuery } from "react-query";
import { useApi } from "../../services/api";

const QUERY_MESSAGE_KEY = "messages";

export function useGetLatestMessageQuery(locale, queryConfig = {}) {
    const api = useApi();
    const isInternal = "true" === process.env.NX_IS_INTERNAL;
    return useQuery(
        [QUERY_MESSAGE_KEY, locale],
        () =>
            api.fetchApi(
                "GET",
                `/messages/latest/${isInternal ? "internal" : "external"}?_locale=${locale}`
            ),
        queryConfig
    );
}
