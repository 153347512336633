import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEvent, useMount } from "react-use";
import { useMutation } from "react-query";
import { useP } from "../../../../../services/i18n";
import { useModule } from "../../../../../services/module";
import { useUser } from "../../../../../services/user";
import { useInnerPage } from "../../../../../services/innerPage";
import Delete from "mdi-material-ui/Delete";

import HiSelectField from "@hipay/hipay-material-ui/HiSelect/HiSelectField";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HiTextField from "@hipay/hipay-material-ui/HiForm/HiTextField";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import HiFormLabel from "@hipay/hipay-material-ui/HiForm/HiFormLabel";
import HiRadio from "@hipay/hipay-material-ui/HiRadio";
import HiInput from "@hipay/hipay-material-ui/HiForm/HiInput";
import HiDatePicker from "@hipay/hipay-material-ui/HiDatePicker/HiDatePicker";
import Calendar from "mdi-material-ui/Calendar";
import { WeekDayPicker } from "./WeekDayPicker";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { useModuleConfig } from "../../../../../services/config";
import { removeExportIntent, addExport } from "../../../../../app/actions/appActions";
import { useApi } from "../../../../../services/api";

import * as cst from "../constants";
import { buildFilePrefix } from "../../../../../utils/export";

const useStylesForm = makeStyles((theme) => ({
    intervalMessage: {
        ...theme.typography.body2,
        color: theme.palette.text.primary,
        textAlign: "left",
        display: "flex",
        marginBottom: 24,
    },
    infoIcon: { color: theme.palette.text.primary },
    message: { marginLeft: 8 },
    form: {
        marginTop: 24,
        maxWidth: 500,
        margin: "auto",
    },
    formField: {
        paddingBottom: 22,
        textAlign: "left",
    },
    formHelper: {
        display: "flex",
        textAlign: "left",
        marginBottom: 22,
        "&>div": {
            marginLeft: 8,
            fontSize: 14,
            lineHeight: "22px",
        },
    },
    submitButton: { width: "100%" },
    deleteButton: {
        width: "100%",
        marginTop: 24,
    },
    autoWidth: { width: "auto !important" },
    relativeContainer: { position: "relative" },
    endRecurrenceInput: { width: 64 },
    endRecurrenceContainer: {
        ...theme.typography.body2,
        color: theme.palette.neutral.dark,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: 22,
        left: 94,
        height: 36,
    },
    endRecurrenceDayContainer: {
        position: "absolute",
        top: 60,
        left: 94,
        width: 240,
        height: 36,
    },
    occurrenceLabel: { marginLeft: 8 },
}));

export function ExportForm(props) {
    const { ...exportItem } = props;

    const accountList = useSelector((state) => state.app.global.entities.account);
    const businessList = useSelector((state) => state.app.global.entities.business);
    const userSettings = useSelector((state) => state.app.settings.data);

    const classes = useStylesForm();
    const p = useP();
    const module = useModule();
    const moduleConfig = useModuleConfig(module.id);
    const user = useUser();
    const location = useLocation();
    const dispatch = useDispatch();
    const api = useApi();

    const { closeInnerPage } = useInnerPage();

    // get initial file prefix from selected accounts
    const initialFilePrefix = useMemo(() => {
        if (exportItem.filePrefix) {
            return exportItem.filePrefix;
        }

        const filtersObj = exportItem.filters;
        return buildFilePrefix(
            moduleConfig,
            filtersObj,
            p,
            accountList,
            businessList,
            cst.MAX_FILE_PREFIX_LENGTH
        );
    }, [exportItem, accountList, businessList, moduleConfig]);

    const [createExport] = useMutation((data) =>
        api.post([JSON.stringify(data)], {
            url: "/exports",
            startedSnackbar: {
                message: p.t("common.search.export.recurring.snackbar.create.request", {
                    name: filePrefix,
                }),
            },
            successSnackbar: {
                message: p.t("common.search.export.recurring.snackbar.create.success"),
            },
            failureSnackbar: {
                message: p.t("common.search.export.recurring.snackbar.create.failure"),
            },
        })
    );
    const [updateExport] = useMutation((data) =>
        api.put([JSON.stringify(data)], {
            url: `/exports/${exportItem.exportId}`,
            startedSnackbar: {
                message: p.t("common.search.export.recurring.snackbar.create.request", {
                    name: filePrefix,
                }),
            },
            successSnackbar: {
                message: p.t("common.search.export.recurring.snackbar.create.success"),
            },
            failureSnackbar: {
                message: p.t("common.search.export.recurring.snackbar.create.failure"),
            },
        })
    );

    // MOUNT
    useEffect(() => {
        document.getElementById("export-form-recurrence").focus();
    }, []);

    // FORM STATE
    const [recurrence, setRecurrence] = useState(exportItem.recurrence || null);
    const [filePrefix, setFilePrefix] = useState(initialFilePrefix);
    const [filePrefixError, setFilePrefixError] = useState(null);
    const [separator, setSeparator] = useState(exportItem.separator || "semicolon");

    const [endRecurrence, setEndRecurrence] = useState(() => {
        if (typeof exportItem.exportId === "undefined") {
            return "after_nb";
        }
        let _endRecurrence = "never";
        if (exportItem.nbOccurrence) {
            _endRecurrence = "after_nb";
        } else if (exportItem.endRecurrenceDay) {
            _endRecurrence = "after_day";
        }
        return _endRecurrence;
    });
    const [nbOccurrence, setNbOccurrence] = useState(exportItem.nbOccurrence || 5);
    const [endRecurrenceDay, setEndRecurrenceDay] = useState(() => {
        if (exportItem.endRecurrenceDay) {
            return moment(exportItem.endRecurrenceDay).format(userSettings.dateFormatShort);
        }
        return null;
    });

    const [weekDayRecurrence, setWeekDayRecurrence] = useState(() => {
        let _weekDayRecurrence = "1";
        if (recurrence === "weekly" && !!exportItem.recurrenceDay) {
            _weekDayRecurrence = exportItem.recurrenceDay;
        }
        return _weekDayRecurrence;
    });

    const [monthDayRecurrence, setMonthDayRecurrence] = useState(() => {
        let _monthDayRecurrence = "first_day";
        if (recurrence === "monthly" && !!exportItem.recurrenceDay) {
            _monthDayRecurrence = exportItem.recurrenceDay;
        }
        return _monthDayRecurrence;
    });

    const hasDefinedDateInterval = useMemo(() => {
        const urlFilter = exportItem.urlFilters || location.search;
        const nonCustomOccurrences = (urlFilter.match(/_interval=(?!custom)/g) || []).length;
        // Export must have at least 1 date filter non custom
        return nonCustomOccurrences > 0;
    }, [exportItem.filters, location.search]);

    const error = !!(filePrefixError || recurrence === null || !hasDefinedDateInterval);

    // SETTERS
    const handleChangeRecurrence = (event, value) => setRecurrence(value);
    const handleChangeFilePrefix = (event) => setFilePrefix(event.target.value);
    const handleResetFilePrefix = () => setFilePrefix("");
    const handleChangeSeparator = (event, value) => setSeparator(value);
    const handleChangeEndRecurrence = (event, value) => setEndRecurrence(value);
    const handleChangeNbOccurrence = (event) => setNbOccurrence(parseInt(event.target.value, 10));
    const handleChangeEndRecurrenceDay = (value) => setEndRecurrenceDay(value);
    const handleChangeWeekDayRecurrence = (event) =>
        setWeekDayRecurrence(event.currentTarget.dataset.value);
    const handleChangeMonthDayRecurrence = (event, value) => setMonthDayRecurrence(value);

    // BLURS
    const handleBlurNbOccurrence = useCallback(() => {
        if (nbOccurrence > 999) {
            setNbOccurrence(999);
        } else if (!nbOccurrence) {
            setNbOccurrence(1);
        }
    }, [nbOccurrence]);

    // VALIDATORS
    const checkFilePrefixError = useCallback(() => {
        let validFilePrefixRegex = new RegExp(
            "^([\\w]|[à-ú]|[À-Ú]|[._\\-\\[\\]]){1," + cst.MAX_FILE_PREFIX_LENGTH + "}$"
        );
        if (!validFilePrefixRegex.test(filePrefix)) {
            setFilePrefixError("Invalid file prefix format.");
        } else {
            setFilePrefixError(null);
        }
    }, [filePrefix]);

    // EVENTS
    const onKeyDown = useCallback((event) => {
        if (event.key === "Escape") {
            closeInnerPage();
        } else if (event.key === "Enter") {
            event.stopPropagation();
            if (event.currentTarget.id === "export-form-separator") {
                onSubmit();
            }
        }
    }, []);

    useEvent("keydown", onKeyDown);

    // MOUNT
    useMount(() => {
        setTimeout(() => {
            checkFilePrefixError();
        }, 100);
    });

    // CALLBACKS

    const _dataToSend = useMemo(() => {
        const editMode = !!exportItem.exportId;
        let recurrenceDay = null;
        if (recurrence === "monthly") {
            recurrenceDay = monthDayRecurrence;
        } else if (recurrence === "weekly") {
            recurrenceDay = weekDayRecurrence;
        }

        return {
            module: module.id,
            recurrence,
            filePrefix,
            separator,
            columns: editMode ? exportItem.columns : JSON.stringify(exportItem.columns),
            filters: editMode ? exportItem.filters : JSON.stringify(exportItem.filters),
            recurrenceDay,
            nbOccurrence: endRecurrence === "after_nb" ? nbOccurrence : null,
            endRecurrenceDay:
                endRecurrence === "after_day" ? moment(endRecurrenceDay).format() : null,
            urlFilters: exportItem.urlFilters || location.search,
            emails: user.email,
            id: exportItem.exportId || null,
            dateUpdated: exportItem.exportId ? moment().format() : null,
            status: "active",
        };
    }, [
        exportItem,
        module,
        recurrence,
        monthDayRecurrence,
        weekDayRecurrence,
        filePrefix,
        separator,
        endRecurrence,
        nbOccurrence,
        endRecurrenceDay,
        user,
    ]);

    const initialData = useMemo(() => {
        return _dataToSend;
    }, []);

    // Submit form
    const onSubmit = useCallback(() => {
        if (!error && !disabled) {
            // close inner page
            closeInnerPage();

            if (exportItem.exportId) {
                updateExport(_dataToSend, {
                    onSuccess: (newExport) => {
                        // add created export to store
                        dispatch(addExport(newExport));
                    },
                });
            } else {
                createExport(_dataToSend, {
                    onSuccess: (newExport) => {
                        // add created export to store
                        dispatch(addExport(newExport));
                    },
                });
            }
        }
    }, [error, exportItem, filePrefix, recurrence, separator]);

    // RENDER

    const dateCreated = exportItem.dateCreated ? moment(exportItem.dateCreated) : moment();

    let disabledItemIdList = [];
    // 2) Disable recurring export on relative month's day if it's the fifth week of month (because every months doesn't have five weeks)
    if (((dateCreated.date() / 7) | 0) >= 4) {
        disabledItemIdList = ["monthly_day"];
    }

    const disabled = useMemo(() => {
        if (endRecurrence === "after_day" && !(endRecurrenceDay instanceof Date)) {
            // User selects end recurrence after day but day is null
            return true;
        } else if (endRecurrence === "after_nb" && !nbOccurrence) {
            // User selects end recurrence after nb but nb is null
            return true;
        }
        // Disable button if export item has no updates
        return JSON.stringify(initialData) === JSON.stringify(_dataToSend);
    }, [initialData, _dataToSend]);

    /*
     * Delete export
     */
    const deleteItem = useCallback(
        (event) => {
            dispatch(removeExportIntent(exportItem, p));
            if (event) {
                event.stopPropagation();
            }
            closeInnerPage();
        },
        [dispatch, exportItem, p]
    );

    const handleNbOccurrenceKeyDown = useCallback((event) => {
        if (event.key === "Tab" && !event.shiftKey) {
            document.getElementById("export-form-file_prefix").focus();
        }
    }, []);

    return (
        <div id="export-form" className={classes.form}>
            {!hasDefinedDateInterval && (
                <div id="intervalMessage" className={classes.intervalMessage}>
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    <span
                        className={classes.message}
                        dangerouslySetInnerHTML={{
                            __html: p.t("common.search.export.form.interval_message"),
                        }}
                    />
                </div>
            )}
            <div className={classes.formField} id={"export-form-recurrence-field"}>
                <HiSelectField
                    id="export-form-recurrence"
                    name="recurrence"
                    label={p.t("common.search.export.form.recurrence.label")}
                    options={[
                        {
                            id: "daily",
                            label: p.t("common.search.export.form.recurrence.daily"),
                        },
                        {
                            id: "weekly",
                            label: p.t("common.search.export.form.recurrence.weekly"),
                        },
                        {
                            id: "monthly",
                            label: p.t("common.search.export.form.recurrence.monthly"),
                        },
                    ]}
                    value={recurrence}
                    onChange={handleChangeRecurrence}
                    multiple={false}
                    hiSelectableListProps={{
                        disabledItemIdList: disabledItemIdList,
                        hideCheckbox: true,
                    }}
                    onSubmit={onSubmit}
                />
            </div>
            {recurrence === "weekly" && (
                <WeekDayPicker onChange={handleChangeWeekDayRecurrence} value={weekDayRecurrence} />
            )}
            {recurrence === "monthly" && (
                <div className={classes.formField} id={"export-form-month_day_recurrence-field"}>
                    <HiFormControl component="fieldset">
                        <HiFormLabel component="legend">
                            {p.t("common.search.export.form.month_day_recurrence.label")}
                        </HiFormLabel>
                        <RadioGroup
                            id="export-form-month_day_recurrence"
                            aria-label={p.t("common.search.export.form.month_day_recurrence.label")}
                            name="month_day_recurrence"
                            value={monthDayRecurrence}
                            onChange={handleChangeMonthDayRecurrence}
                            onSubmit={onSubmit}
                        >
                            <FormControlLabel
                                value="first_day"
                                control={<HiRadio color="primary" />}
                                label={p.t(
                                    "common.search.export.form.month_day_recurrence.first_day"
                                )}
                            />
                            <FormControlLabel
                                value="last_day"
                                control={<HiRadio color="primary" />}
                                label={p.t(
                                    "common.search.export.form.month_day_recurrence.last_day"
                                )}
                            />
                        </RadioGroup>
                    </HiFormControl>
                </div>
            )}
            {!!recurrence && (
                <div
                    className={classNames(classes.formField, classes.relativeContainer)}
                    id="export-form-end_recurrence-field"
                >
                    <HiFormControl className={classes.autoWidth} component="fieldset">
                        <HiFormLabel component="legend">
                            {p.t("common.search.export.form.end_recurrence.label")}
                        </HiFormLabel>
                        <RadioGroup
                            id="export-form-end_recurrence"
                            aria-label={p.t("common.search.export.form.end_recurrence.label")}
                            name="end_recurrence"
                            value={endRecurrence}
                            onChange={handleChangeEndRecurrence}
                            onSubmit={onSubmit}
                        >
                            <FormControlLabel
                                value="after_nb"
                                control={<HiRadio color="primary" />}
                                label={p.t("common.search.export.form.end_recurrence.after_nb")}
                            />
                            <FormControlLabel
                                value="after_day"
                                control={<HiRadio color="primary" />}
                                label={p.t("common.search.export.form.end_recurrence.after_day")}
                            />
                            <FormControlLabel
                                value="never"
                                control={<HiRadio color="primary" />}
                                label={p.t("common.search.export.form.end_recurrence.never")}
                            />
                        </RadioGroup>
                    </HiFormControl>
                    <div
                        id="export-form-end_recurrence-occurrence"
                        className={classes.endRecurrenceContainer}
                    >
                        <HiInput
                            className={classes.endRecurrenceInput}
                            type="number"
                            value={nbOccurrence}
                            onChange={handleChangeNbOccurrence}
                            onBlur={handleBlurNbOccurrence}
                            inputProps={{
                                min: 1,
                                max: 999,
                                maxLength: 3,
                                disabled: endRecurrence !== "after_nb",
                            }}
                            onKeyDown={handleNbOccurrenceKeyDown}
                            onSubmit={onSubmit}
                        />
                        <span className={classes.occurrenceLabel}>
                            {p.t("common.search.export.form.end_recurrence.occurrence")}
                        </span>
                    </div>
                    <div
                        id="export-form-end_rencurrence-day"
                        className={classes.endRecurrenceDayContainer}
                    >
                        <HiDatePicker
                            id="endRecurrenceDay"
                            value={endRecurrenceDay}
                            onChange={handleChangeEndRecurrenceDay}
                            disablePastDays
                            HiInputProps={{
                                startAdornment: <Calendar />,
                                onSubmit: onSubmit,
                                disabled: endRecurrence !== "after_day",
                            }}
                            format={userSettings.dateFormatShort}
                        />
                    </div>
                </div>
            )}
            <div className={classes.formField} id={"export-form-file_prefix-field"}>
                <HiTextField
                    id="export-form-file_prefix"
                    name="file_prefix"
                    label={p.t("common.search.export.form.file_prefix.label")}
                    helperIcon
                    helperText={p.t("common.search.export.form.file_prefix.help", {
                        length: cst.MAX_FILE_PREFIX_LENGTH,
                    })}
                    errorText={p.t("common.search.export.form.file_prefix.errorText", {
                        length: cst.MAX_FILE_PREFIX_LENGTH,
                    })}
                    error={!!filePrefixError}
                    onChange={handleChangeFilePrefix}
                    onReset={handleResetFilePrefix}
                    value={filePrefix}
                    onBlur={checkFilePrefixError}
                    HiInputProps={{
                        onSubmit: onSubmit,
                        inputProps: { maxLength: cst.MAX_FILE_PREFIX_LENGTH },
                    }}
                />
            </div>
            <div className={classes.formField} id={"export-form-separator-field"}>
                <HiFormControl component="fieldset">
                    <HiFormLabel component="legend">
                        {p.t("common.search.export.form.separator.label")}
                    </HiFormLabel>
                    <RadioGroup
                        id="export-form-separator"
                        aria-label={p.t("common.search.export.form.separator.label")}
                        name="separator"
                        value={separator}
                        onChange={handleChangeSeparator}
                        onKeyDown={onKeyDown}
                        onSubmit={onSubmit}
                    >
                        <FormControlLabel
                            value="semicolon"
                            control={<HiRadio color="primary" />}
                            label={p.t("common.search.export.form.separator.semicolon")}
                        />
                        <FormControlLabel
                            value="comma"
                            control={<HiRadio color="primary" />}
                            label={p.t("common.search.export.form.separator.comma")}
                        />
                    </RadioGroup>
                </HiFormControl>
            </div>
            <Box textAlign="left" marginBottom={8} id={"export-form-email-field"}>
                <Typography variant="body2">
                    {p.t("common.search.export.form.send_to_email")}
                </Typography>
                <Typography variant="caption">{user.email}</Typography>
            </Box>
            <HiButton
                id="export-form-submit"
                color="primary"
                variant="contained"
                className={classes.submitButton}
                onClick={onSubmit}
                disabled={disabled || error}
            >
                {p.t("common.search.export.form.submit")}
            </HiButton>
            {!!exportItem.exportId && (
                <HiButton
                    id="export-form-remove-item"
                    color="neutral"
                    variant="text"
                    className={classes.deleteButton}
                    onClick={deleteItem}
                >
                    <Delete />
                    {p.t("common.search.export.form.delete")}
                </HiButton>
            )}
        </div>
    );
}
