const operationMode = {
    // predefined static options
    id: "operation_mode",
    data: [
        {
            id: "COLLECTING",
            label: "ref.transaction.operation_mode.COLLECTING",
        },
        {
            id: "GATEWAY",
            label: "ref.transaction.operation_mode.GATEWAY",
        },
        {
            id: "GATEWAY+",
            label: "ref.transaction.operation_mode.GATEWAY+",
        },
        {
            id: "GATEWAY++",
            label: "ref.transaction.operation_mode.GATEWAY++",
        },
        {
            id: "MOCKED",
            label: "ref.transaction.operation_mode.MOCKED",
        },
        {
            id: "TESTING",
            label: "ref.transaction.operation_mode.TESTING",
        },
    ],
    translate: true,
};

export default operationMode;
