import { useEffect, useState } from "react";
import moment from "moment";

import { useP } from "@console/core";
import HiDatePicker from "@hipay/hipay-material-ui/HiDatePicker";

import { MIN_DATE, REGEX_DATE } from "./constants";

import useStyles from "./styleSheet";

interface DatePickerProps {
    id?: string;
    disabled?: boolean;
    disableFutureDays?: boolean;
    disablePastDays?: boolean;
    label: string;
    value?: Date | null;
    onUpdate: (value: Date | null) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    placeholder?: string;
}

const DatePicker = (props: DatePickerProps) => {
    const {
        id,
        disabled,
        disableFutureDays,
        disablePastDays,
        label,
        value,
        onUpdate,
        onFocus,
        onBlur,
        placeholder,
    } = props;

    const classes = useStyles();

    const p = useP();

    const [date, setDate] = useState<string | undefined>(
        value ? moment(value).format("YYYY-MM-DD") : undefined
    );
    const [dateError, setDateError] = useState(false);

    useEffect(() => {
        if (value) setDate(moment(value).format("YYYY-MM-DD"));
        else setDate(undefined);
    }, [value, setDate]);

    return (
        <HiDatePicker
            id={id}
            classes={{
                root: classes.root,
            }}
            label={label}
            value={
                dateError || date === undefined ? date : new Date(moment(date).format("YYYY-MM-DD"))
            }
            minimumDate={MIN_DATE}
            format={"YYYY-MM-DD"}
            enableTime={false}
            hideOnDayClick={true}
            disabled={disabled}
            disableFutureDays={disableFutureDays}
            disablePastDays={disablePastDays}
            error={dateError}
            errorText={p.t("modules.onboarding.datepicker.invalid_date")}
            onReset={() => {
                setDateError(false);
                setDate(undefined);
                onUpdate(null);
            }}
            onChange={(_value: string) => {
                if (typeof _value === "object") {
                    setDateError(false);
                    setDate(moment(_value).format("YYYY-MM-DD"));
                    onUpdate(new Date(_value));
                } else {
                    setDate(_value);
                    if (_value.match(REGEX_DATE) && moment(_value).isValid()) {
                        setDateError(false);
                        onUpdate(new Date(_value));
                    } else setDateError(true);
                }
            }}
            translations={{ today: p.t("modules.onboarding.datepicker.today") }}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
        />
    );
};

export default DatePicker;
