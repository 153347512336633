import keycode from "keycode";

/**
 * Appelle la fonction callback si la touche pressée est un espace ou entrée
 * @param e event key down
 * @param fn callback function
 */
export function handleKeyDown(e, fn) {
    if (keycode(e) === "space" || keycode(e) === "enter") {
        e.preventDefault();
        fn();
    }
}

export function handleKeyValid(fn) {
    return (e) => {
        if (keycode(e) === "space" || keycode(e) === "enter") {
            e.preventDefault();
            fn(e);
        }
    };
}
