import pure from "recompose/pure";

const styleRoot = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};
let styleImg = {};
const styleShortLabel = {
    display: "inline-block",
    margin: "auto 0",
    marginLeft: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: 17,
    lineHeight: "17px",
    fontSize: "1em",
};
const styleLabel = {
    display: "inline-block",
    margin: "auto 0",
    marginLeft: 5,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: 17,
    lineHeight: "17px",
    fontSize: "1em",
    maxWidth: "100%",
};

const handleError = (fallbackImage) => (e) => {
    if (fallbackImage) {
        e.target.src = `${fallbackImage}`;
    } else {
        e.target.style.display = "none";
    }
};

/**
 * Cette cellule permet d'afficher une image/logo (24px largeur) et un label (optionnel).
 * En vue large, aucun tooltip n'est affiché
 * En vue small (s) elle affiche uniquement l'image avec une largeur fixe.
 *
 * Version component as function pour être utilisé par moize.
 */
const CellImageStyledClass = ({
    path = "",
    label = "",
    shortLabel = "",
    size = 24,
    view = "s",
    fallbackImage = "",
    className,
}) => {
    if (!path || path === "") {
        return "";
    }

    let showShortLabel = shortLabel && view === "m";
    let showLabel = label && (view === "l" || (view === "m" && !shortLabel));
    styleImg = {
        ...styleImg,
        minWidth: size,
    };

    return (
        <div className={className} style={styleRoot} title={view === "s" ? label : undefined}>
            <img
                src={path}
                alt={label}
                style={styleImg}
                width={size}
                onError={handleError(fallbackImage)}
            />
            {showShortLabel && <span style={styleShortLabel}>{shortLabel}</span>}
            {showLabel && <div style={styleLabel}>{label}</div>}
        </div>
    );
};

export const CellImageStyled = pure(CellImageStyledClass);
