import { useMemo } from "react";
import { useSelector } from "react-redux";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";

export const DatePickerProvider = ({ children }) => {
    const language = useSelector((state) => state.app.settings.data.language);

    const locale = useMemo(() => {
        return language === "fr_FR" ? frLocale : enLocale;
    }, [language]);

    return (
        <LocalizationProvider locale={locale} dateAdapter={AdapterDateFns}>
            {children}
        </LocalizationProvider>
    );
};
