import styled from "styled-components";

export const CarouselSlot = styled.div`
    display: inline-flex;
    width: ${(props) => props.width}px;
    order: ${(props) => props.order};
    &:nth-child(${(props) => props.nbslots}n+1) {
        border-right: solid #fff 1px;
    }
`;
