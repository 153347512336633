const sca_references = {
    id: "sca_preferences",
    data: [
        {
            id: "1",
            label: "ref.transaction.sca_preferences.value_1",
        },
        {
            id: "2",
            label: "ref.transaction.sca_preferences.value_2",
        },
        {
            id: "3",
            label: "ref.transaction.sca_preferences.value_3",
        },
        {
            id: "4",
            label: "ref.transaction.sca_preferences.value_4",
        },
        {
            id: "5",
            label: "ref.transaction.sca_preferences.value_5",
        },
        {
            id: "7",
            label: "ref.transaction.sca_preferences.value_7",
        },
    ],
    translate: true,
};

export default sca_references;
