import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

const stylesCellImage = () => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    img: { margin: "auto 0" },
    label: {
        display: "inline-block",
        margin: "auto 0",
        marginLeft: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        height: 17,
        lineHeight: "17px",
        fontSize: "1em",
    },
});

/**
 * Cette cellule permet d'afficher une image/logo (24px largeur) et un label (optionnel).
 * En vue large, aucun tooltip n'est affiché
 * En vue small (s) elle affiche uniquement l'image avec une largeur fixe.
 */
class CellImageClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Label
         */
        label: PropTypes.string,
        /**
         * Image path
         */
        path: PropTypes.string,
        /**
         * Short label
         */
        shortLabel: PropTypes.string,
        /**
         * Image size
         */
        size: PropTypes.number,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    static defaultProps = {
        size: 24,
        view: "s",
    };

    render() {
        const { classes, path, label, shortLabel, size, view, className } = this.props;

        if (!path || path === "") {
            return "";
        }

        let showShortLabel = shortLabel && view === "m";
        let showLabel = label && (view === "l" || (view === "m" && !shortLabel));

        return (
            <div className={classNames(classes.wrapper, className)} title={label}>
                <img src={path} alt={label} className={classes.img} width={size} />
                {showShortLabel && <span className={classes.label}>{shortLabel}</span>}
                {showLabel && <div className={classes.label}>{label}</div>}
            </div>
        );
    }
}

export const CellImage = withStyles(stylesCellImage)(CellImageClass);
