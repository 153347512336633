import React from "react";
import PropTypes from "prop-types";
import moize from "moize";
import classNames from "classnames";
import { withStylesAndTranslate } from "../../../../../../hoc";
import { addOptionsInfo } from "../../../../../../utils";

import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Close from "@mui/icons-material/Close";
import ArrowRight from "@mui/icons-material/ArrowRight";
import FilterList from "@mui/icons-material/FilterList";
import Menu from "@mui/icons-material/Menu";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiNestedSelectContent, {
    getRecursiveFinalItemIdList,
} from "@hipay/hipay-material-ui/HiSelect/HiNestedSelectContent";
import HiPin from "@hipay/hipay-material-ui/HiPin";
import { DISPLAY_BOOLEAN } from "../../../../../constants";

// moize translations object
const pureTranslations = moize((p) => ({
    no_result_match: p.t("form.fields.select.no_result_match"),
    search: p.t("form.fields.select.search"),
    n_children: p.t("form.fields.select.n_children"),
    one_child: p.t("form.fields.select.one_child"),
}));

const stylesColumnFilter = (theme) => ({
    menu: {
        width: 300,
        paddingTop: 16,
    },
    menuTitle: {
        ...theme.typography.h3,
        marginLeft: 12,
        marginRight: 40,
        whiteSpace: "prewrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: 16,
    },
    menuItemIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
        color: theme.palette.neutral.normal,
    },
    menuItemFilter: {
        ...theme.typography.subtitle2,
        color: theme.palette.neutral.dark,
        height: 40,
        lineHeight: "40px",
        paddingLeft: 28,
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    closeMenu: {
        position: "absolute",
        top: 2,
        right: 2,
    },
    arrowRight: {
        position: "relative",
        top: 2,
        width: "15px",
        height: "15px",
    },
    menuItemBorderBottom: { borderBottom: `1px solid ${theme.palette.divider}` },
    buttonGroup: {
        display: "flex",
        padding: 0,
    },
    buttonGroupSubmit: {
        textAlign: "center",
        margin: "20px auto",
    },
    submitButton: {
        height: 40,
        fontSize: 14,
        "&:first-child": { marginRight: 16 },
    },
    button: {
        minHeight: 40,
        fontSize: 14,
        width: "50%",
    },
    buttonCentered: { margin: "auto" },
    buttonDisabled: { color: theme.palette.action.disabled },
    asc: { transform: "scale(1,-1)" },
    maxSelected: { padding: "0px 28px" },
    sortActive: {
        color: theme.palette.primary.main,
        cursor: "inherit",
    },
});

const MemoizedOptions = moize(addOptionsInfo);

/**
 * Construit le menu de filtre d'une colonne avec les options de tri et un multi-select des valeurs possible
 * (uniquement pour les colonnes ayant une liste fermé de valeurs possibles)
 */
class ColumnFilter extends React.Component {
    static propTypes = {
        /**
         * Élément de référence sur lequelle positionner le menu
         */
        anchorEl: PropTypes.object,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Liste fermé des valeurs disponibles sur lesquelles filtrer (inclure/exclure)
         */
        filterOptionList: PropTypes.array.isRequired,
        /**
         * Can select empty option to filter on empty fields
         */
        hasEmptyOption: PropTypes.bool.isRequired,
        /**
         * Nombre max d'items sélectionnables. Si undefined => pas de limite.
         */
        maxSelectable: PropTypes.number,
        /**
         * Fonction de callback pour la fermeture du menu
         */
        onClose: PropTypes.func.isRequired,
        /**
         * Fonction de callback au changement du filtre - passe la liste des valeurs inclu ses
         */
        onFilterChange: PropTypes.func,
        /**
         * Fonction de callback au tri - passe la direction du tri
         */
        onSort: PropTypes.func,
        /**
         * Affiche / Cache l'élément
         */
        open: PropTypes.bool.isRequired,
        /**
         * Liste des id des valeurs sélectionnées
         */
        selectedFilterIdList: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        /**
         * FormField option sort
         */
        sort: PropTypes.bool,
        /**
         * Sens du tri
         */
        sortDirection: PropTypes.oneOf(["asc", "desc"]),
        /**
         * Tri actif / inactif
         */
        sorted: PropTypes.bool,
        /**
         * FormField option sortAppendList
         */
        sortAppendList: PropTypes.array,
        /**
         * Titre de la colonne
         */
        title: PropTypes.string,
        /**
         * Type des données de la colonne
         */
        type: PropTypes.string,
    };

    static defaultProps = {
        hasEmptyOption: true,
        sortAppendList: ["EMPTY"],
    };

    constructor(props) {
        super(props);

        let selectOptions = [...props.filterOptionList];
        if (props.hasEmptyOption !== false) {
            selectOptions.unshift({
                id: "EMPTY",
                label: props.p.t("common.search.empty"),
            });
        }

        // Fix for Switch Form Fields
        let selectedIds = props.selectedFilterIdList;
        if (!!selectedIds && !Array.isArray(selectedIds)) {
            selectedIds = [selectedIds];
        } else if (!selectedIds) {
            selectedIds = [];
        }
        this.state = {
            initialFilterIdList: selectedIds,
            // by default all filters are selected
            selectedFilterIdList: selectedIds,
            options: selectOptions,
        };

        this.handleSort = this.handleSort.bind(this);
        this.selectAllFilters = this.selectAllFilters.bind(this);
        this.deselectAllFilters = this.deselectAllFilters.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextIdList = this.props.selectedFilterIdList || [];
        let prevIdList = prevProps.selectedFilterIdList || [];
        if (
            JSON.stringify(prevIdList) !== JSON.stringify(nextIdList) ||
            (prevProps.open && !this.props.open)
        ) {
            // Fix for Switch Form Fields
            let selectedIds = nextIdList;
            if (!!selectedIds && !Array.isArray(selectedIds)) {
                selectedIds = [selectedIds];
            } else if (!selectedIds) {
                selectedIds = [];
            }
            this.setState({
                initialFilterIdList: selectedIds,
                selectedFilterIdList: selectedIds,
            });
        }
    }

    /**
     * Ajoute / Supprime l'id de l'item du selectedFilterIdList
     *
     * @param event
     * @param selectedIdList
     */
    handleChange = (event, selectedIdList) => {
        if (!this.props.maxSelectable || this.props.maxSelectable >= selectedIdList.length) {
            this.setState({ selectedFilterIdList: selectedIdList });
        }
    };

    /**
     * Sélectionne tous les items filtrés
     */
    selectAllFilters() {
        this.setState({ selectedFilterIdList: getRecursiveFinalItemIdList(this.state.options) });
    }

    /**
     * Désélectionne tous les items filtrés
     */
    deselectAllFilters() {
        this.setState({ selectedFilterIdList: [] });
    }

    handleSort = (direction, e) => {
        this.props.onSort(direction);
        this.props.onClose(e);
    };

    sortAsc = (e) => this.handleSort("asc", e);
    sortDesc = (e) => this.handleSort("desc", e);
    sortNone = (e) => this.handleSort(false, e);

    areArraysEqual = (arrayA, arrayB) => {
        if (arrayA.length !== arrayB.length) {
            return false;
        }

        for (let i = 0; i < arrayA.length; i++) {
            if (arrayA[i] !== arrayB[i]) {
                return false;
            }
        }

        return true;
    };

    handleSubmit = (e) => {
        if (!this.areArraysEqual(this.state.initialFilterIdList, this.state.selectedFilterIdList)) {
            this.setState({ initialFilterIdList: this.state.selectedFilterIdList });
            this.props.onFilterChange(this.state.selectedFilterIdList);
        }
        this.props.onClose(e);
    };

    render() {
        const {
            anchorEl,
            classes,
            type,
            title,
            open,
            onClose,
            p,
            sort,
            sortAppendList,
            isCountry,
            maxSelectable,
            sortable,
            sorted,
            sortDirection,
            numericId,
            displayType,
        } = this.props;

        const { initialFilterIdList, selectedFilterIdList } = this.state;

        let sortFrom = "A";
        let sortTo = "Z";
        if (numericId === true) {
            sortFrom = "0";
            sortTo = "9";
        }

        const showSortTitle = displayType !== DISPLAY_BOOLEAN && numericId !== true;

        return (
            <Popover
                id="column-filter-menu"
                anchorEl={anchorEl}
                open={open}
                classes={{ paper: classes.menu }}
                onClose={onClose}
            >
                <div className={classes.menuTitle}>
                    <span>{title}</span>
                    <HiIconButton className={classes.closeMenu} onClick={onClose}>
                        <Close />
                    </HiIconButton>
                </div>

                <div>
                    <MenuItem
                        key={"sort-none"}
                        classes={{ root: classes.menuItemBorderBottom }}
                        onClick={this.sortNone}
                        tabIndex={0}
                    >
                        <Menu classes={{ root: classes.menuItemIcon }} />
                        <span>{p.t("common.search.table.default_sort")}</span>
                    </MenuItem>

                    {sortable && (
                        <React.Fragment>
                            <MenuItem
                                key={"sort-asc"}
                                onClick={
                                    !sorted || sortDirection !== "asc" ? this.sortAsc : undefined
                                }
                                title={
                                    showSortTitle &&
                                    (isCountry
                                        ? p.t("common.search.table.sort_indication_country")
                                        : p.t("common.search.table.sort_indication"))
                                }
                                tabIndex={0}
                                classes={{
                                    root: classNames({
                                        [classes.sortActive]: sorted && sortDirection === "asc",
                                    }),
                                }}
                            >
                                <FilterList
                                    className={classes.asc}
                                    classes={{ root: classes.menuItemIcon }}
                                />
                                <span>
                                    {p.t("common.search.table.sort_from")} {sortFrom}{" "}
                                    <ArrowRight className={classes.arrowRight} /> {sortTo}
                                </span>
                            </MenuItem>
                            <MenuItem
                                key={"sort-desc"}
                                onClick={
                                    !sorted || sortDirection !== "desc" ? this.sortDesc : undefined
                                }
                                classes={{
                                    root: classNames(classes.menuItemBorderBottom, {
                                        [classes.sortActive]: sorted && sortDirection === "desc",
                                    }),
                                }}
                                title={
                                    showSortTitle &&
                                    (isCountry
                                        ? p.t("common.search.table.sort_indication_country")
                                        : p.t("common.search.table.sort_indication"))
                                }
                                tabIndex={0}
                            >
                                <FilterList classes={{ root: classes.menuItemIcon }} />
                                <span>
                                    {p.t("common.search.table.sort_from")} {sortTo}{" "}
                                    <ArrowRight className={classes.arrowRight} /> {sortFrom}
                                </span>
                            </MenuItem>
                        </React.Fragment>
                    )}
                </div>

                <div className={classes.menuItemFilter}>
                    <span style={{ marginRight: 4 }}>{p.t("common.search.table.filter")}</span>
                    <HiPin color={selectedFilterIdList.length > 0 ? "primary" : "default"}>
                        {selectedFilterIdList.length}
                    </HiPin>
                </div>

                {maxSelectable && selectedFilterIdList.length === maxSelectable && (
                    <div className={classes.maxSelected}>
                        {p.t("common.search.table.header.column_filter.max_selected", {
                            maxSelectable,
                        })}
                    </div>
                )}

                <div className={classes.buttonGroup}>
                    {!maxSelectable && (
                        <HiButton
                            className={classes.button}
                            disabled={selectedFilterIdList.length === this.state.options.length}
                            onClick={this.selectAllFilters}
                        >
                            {p.t("common.search.table.select_all")}
                        </HiButton>
                    )}
                    <HiButton
                        className={classNames(classes.button, {
                            [classes.buttonCentered]: typeof maxSelectable !== "undefined",
                        })}
                        disabled={selectedFilterIdList.length === 0}
                        onClick={this.deselectAllFilters}
                    >
                        {p.t("common.search.table.deselect_all")}
                    </HiButton>
                </div>

                <HiNestedSelectContent
                    height={320}
                    multiple
                    onChange={this.handleChange}
                    options={MemoizedOptions(this.state.options, sortAppendList)}
                    searchable
                    translations={pureTranslations(p)}
                    type={type}
                    value={selectedFilterIdList}
                    {...(sort && {
                        hiSelectableListProps: {
                            sort,
                            sortAppendList,
                        },
                    })}
                />

                <div className={classes.buttonGroupSubmit}>
                    <HiButton
                        classes={{ root: classes.submitButton }}
                        onClick={onClose}
                        color="primary"
                    >
                        {p.t("common.search.table.cancel")}
                    </HiButton>
                    <HiButton
                        variant="contained"
                        color="primary"
                        classes={{ root: classes.submitButton }}
                        onClick={this.handleSubmit}
                        disabled={this.areArraysEqual(initialFilterIdList, selectedFilterIdList)}
                    >
                        {p.t("common.search.table.apply")}
                    </HiButton>
                </div>
            </Popover>
        );
    }
}

export default withStylesAndTranslate(stylesColumnFilter)(ColumnFilter);
