import * as actionTypes from "./actionTypes";
import { reduceFetchedReference } from "./utils";
import * as staticReferences from "./static_datas";

export const reducerManager = (references) => {
    const initialState = {
        ...staticReferences,
        ...references,
    };

    return (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.FETCH_EMPTY_RELATIVE_REFERENCES:
                return {
                    ...state,
                    [action.meta.id]: {
                        [action.meta.endpoint]: {
                            data: [],
                            loading: false,
                            error: null,
                        },
                    },
                };
            case actionTypes.FETCH_REFERENCES_REQUEST:
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        [action.meta.endpoint]: {
                            data: [],
                            loading: true,
                            error: null,
                            endpoint: action.meta.endpoint,
                        },
                    },
                };
            case actionTypes.FETCH_REFERENCES_SUCCESS:
                // Use a clojure to set default image for payment_means.
                let data = reduceFetchedReference(
                    action.payload,
                    action.meta.reference,
                    action.meta.p
                );
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        [action.meta.endpoint]: {
                            ...state[action.meta.id][action.meta.endpoint],
                            data: data,
                            loading: false,
                            error: null,
                            translate: !!action.meta.reference.translate, // boolean default false
                        },
                    },
                };
            case actionTypes.FETCH_REFERENCES_FAILURE:
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        [action.meta.endpoint]: {
                            ...state[action.meta.id][action.meta.endpoint],
                            data: [],
                            loading: false,
                            error: action.payload,
                        },
                    },
                };

            case actionTypes.ADD_REFERENCES_DATA:
                if (typeof state[action.meta.id][action.meta.endpoint] === "undefined") {
                    return {
                        ...state,
                        [action.meta.id]: {
                            ...state[action.meta.id],
                            [action.meta.endpoint]: {
                                data: [action.meta.dataToAdd],
                                loading: false,
                                error: null,
                            },
                        },
                    };
                }
                return {
                    ...state,
                    [action.meta.id]: {
                        ...state[action.meta.id],
                        [action.meta.endpoint]: {
                            ...state[action.meta.id][action.meta.endpoint],
                            data: [
                                ...state[action.meta.id][action.meta.endpoint]["data"],
                                action.meta.dataToAdd,
                            ],
                            loading: false,
                            error: null,
                        },
                    },
                };

            default:
                return state;
        }
    };
};
