import { ucFirst } from "@console/core";

const authorsData = {
    id: "authors",
    endpoint: "/authors",
    withSelectedAccounts: true,
    reduceFunc: (previous, current) => {
        const label = current["label"]
            ? `ref.transaction.request_source.${current["label"]}`
            : ucFirst(current["firstname"]) + " " + ucFirst(current["surname"]);
        let img = null;
        if (current["label"]) {
            if (current["label"] === "CONS") {
                img = "/assets/transaction/img/unknown_icon.svg";
            } else {
                img = "/assets/transaction/img/api_icon.svg";
            }
        }
        return current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"].toString(),
                      label,
                      translate: !!current["label"],
                      img,
                      firstname: current["firstname"] ? ucFirst(current["firstname"]) : null,
                      surname: current["surname"] ? ucFirst(current["surname"]) : null,
                  },
              ]
            : previous;
    },
    organizeFunc: (authors, p) => {
        // translate some elements
        return authors.map((author) => {
            return author.translate
                ? {
                      ...author,
                      label: p.t(author.label),
                  }
                : author;
        });
    },
};

export default authorsData;
