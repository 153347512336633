import pure from "recompose/pure";
import { useCallback } from "react";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

const useStylesCellTextStyled = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: "auto 0",
        display: "flex",
        verticalAlign: "middle",
    },
    label: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        textAlign: "left",
        "&>span": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    },
    link: {
        fontSize: theme.typography.button.fontSize,
        fontFamily: theme.typography.button.fontFamily,
        fontWeight: theme.typography.button.fontWeight,
        lineHeight: theme.typography.button.lineHeight,
        letterSpacing: theme.typography.button.letterSpacing,
        textTransform: theme.typography.button.textTransform,
    },
    sideButton: {
        textTransform: "none",
    },
}));

/**
 * CellText functional component
 * (don't includes complex ellipsis: middle, after-first-word...)
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CellTextStyledClass = (props) => {
    const {
        label,
        color,
        value = "",
        withFormating,
        active = false,
        title = "",
        cellFormat,
        onClick,
        href,
        className,
        description,
    } = props;

    const classes = useStylesCellTextStyled(props);

    let valueString = "";
    if (label) {
        valueString = label.toString();
    } else if (withFormating === false) {
        valueString = JSON.stringify(cellFormat);
    } else if (value) {
        valueString = value.toString();
    }

    const isTitle = title ? title : valueString;

    const labelClassname = classNames(className, classes.label, { [classes.link]: !!onClick });

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onClick(e);
    }, []);

    function openInNewTab() {
        window.open(href, "_blank").focus();
    }

    let textType = null;
    if (!!value && color && !onClick && !!href) {
        textType = "side-button";
    } else if (color && !onClick && !href) {
        textType = "colored-label";
    } else if (!!value && color && !!onClick) {
        textType = "button";
    } else if (!description && !color && !onClick) {
        textType = "text";
    } else if (description && !color && !onClick) {
        textType = "innerHTML";
    } else if (!!value && !color && !!onClick) {
        textType = "neutral-button";
    }

    return (
        <div
            className={classNames(classes.root, className)}
            title={isTitle || (title ? title : undefined)}
            onClick={onClick && handleClick}
        >
            {textType === "side-button" && (
                <div className={labelClassname}>
                    {valueString}
                    <HiButton
                        sx={{ ml: 5 }}
                        className={classes.sideButton}
                        variant="containedLight"
                        size="small"
                        color={color}
                        onClick={openInNewTab}
                        disableElevation
                    >
                        Pappers
                    </HiButton>
                </div>
            )}
            {textType === "colored-label" && (
                <HiColoredLabel
                    label={valueString}
                    color={color}
                    active={active}
                    fontSize={13}
                    className={labelClassname}
                />
            )}
            {textType === "button" && (
                <HiButton
                    className={labelClassname}
                    onClick={onClick}
                    variant="containedLight"
                    size="small"
                    color={color}
                    href={onClick}
                    disableElevation
                >
                    {valueString}
                </HiButton>
            )}
            {textType === "innerHtml" && (
                <div className={labelClassname} dangerouslySetInnerHTML={{ __html: valueString }} />
            )}
            {textType === "text" && <div className={labelClassname}>{valueString}</div>}
            {textType === "neutral-button" && (
                <HiButton
                    className={labelClassname}
                    onClick={onClick}
                    variant="text"
                    size="small"
                    color="neutral"
                >
                    {valueString}
                </HiButton>
            )}
        </div>
    );
};

export const CellTextStyled = pure(CellTextStyledClass);
