import { formFieldTypes, displayTypes } from "@console/core";

const attributes = {
    batch_id: {
        id: "id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        order: 1,
    },
    type: {
        id: "type",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        order: 2,
        reference: "batch_type",
    },
    status: {
        id: "status",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
        },
        order: 4,
        reference: "batch_status",
    },
    message: {
        id: "message",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        order: 5,
    },
    success_logs: {
        id: "success_logs",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        order: 6,
    },
    ignored_logs: {
        id: "ignored_logs",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        order: 7,
    },
    warning_logs: {
        id: "warning_logs",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        order: 8,
    },
    error_logs: {
        id: "error_logs",
        displayType: displayTypes.DISPLAY_CHIP,
        displayOptions: {
            exclude: false,
            lookup: false,
            sortable: false,
        },
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {},
        order: 9,
    },
    started_at: {
        id: "started_at",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            displayTime: true,
            exclude: false,
            lookup: false,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        order: 10,
    },
    finished_at: {
        id: "finished_at",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            displayTime: true,
            exclude: false,
            lookup: false,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        order: 11,
    },
    origin: {
        id: "origin",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            exclude: false,
            lookup: true,
            sortable: true,
        },
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        order: 14,
    },
    options: {
        id: "options",
        displayType: displayTypes.DISPLAY_STRINGIFY_OBJECT,
        displayOptions: {
            exclude: false,
        },
        order: 15,
    },
};

export default attributes;
