import React from "react";
import { isBrowser } from "react-device-detect";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ContentAnalytics } from "./ContentAnalytics";
import { FlexCarousel } from "./FlexCarousel";
import KpiLoading from "./Kpi/KpiLoading";
import { SLOT_WIDTH, SLOT_SPACING, SLOT_WIDTH_MOBILE } from "../../../constants/analyticsBanner";
import { withStylesAndTranslate } from "../../../../hoc/withStylesAndTranslate";
import { foldAccents } from "../../../../utils/strings";

const stylesAnalyticsBanner = (theme) => ({
    root: {
        width: "100%",
        position: "relative",
        backgroundColor: theme.palette.secondary.light,
        boxShadow:
            "inset 0px 6px 10px 0px rgba(0,0,0,.16), inset 0px -6px 10px 0px rgba(0,0,0,.16)",
    },
    contentRoot: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    settings: {
        height: 48,
        width: 72,
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 2,
        paddingRight: 24,
    },
    icon: {
        height: "100%",
        lineHeight: "1",
        color: theme.palette.primary.contrastText,
    },
    error: {
        color: theme.palette.primary.contrastText,
        textAlign: "center",
    },
});

/**
 * Analytics Banner for statistic/aggregates.
 *
 * data should come as:
 * {
 *  datas: [
 *      {
 *          id: `identity of kpi as defined in kpiAttributes`,
 *          value: `value of kpi`,
 *      }
 *  ],
 * }
 * and orderedKpis as:
 * [
 *  {
 *      id: `identity of kpi as defined in kpiAttributes`,
 *      displayed: `boolean`,
 *      order: `integer`,
 *      invDisp: `boolean`,
 *      label: `label of kpi`,
 *  },
 *  {
 *      id: `identity of kpi as defined in kpiAttributes`,
 *      displayed: `boolean`,
 *      order: `integer`,
 *      invDisp: `boolean`,
 *      label: `label of kpi`,
 *  },
 * ]
 */
class AnalyticsBannerClass extends React.Component {
    static propTypes = {
        /**
         * Module name
         */
        module: PropTypes.string.isRequired,
        /**
         * True if analyticsBanner should print multiple account warning
         */
        disableForMultipleAccount: PropTypes.bool,
        /**
         * User settings
         */
        userSettings: PropTypes.object,
        /**
         * Kpis data
         */
        data: PropTypes.object,
        /**
         * Kpis Attributes
         */
        kpiAttributes: PropTypes.object.isRequired,
        /**
         * Association between kpi and attributes
         */
        kpis: PropTypes.arrayOf(PropTypes.object),
        /**
         * Ordered kpis table
         */
        orderedKpis: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = { disableForMultipleAccount: false };

    /**
     * Associate kpis' datas and kpis' attribute to only one object
     */
    resultsToBeDisplayed = () => {
        const { kpiAttributes, data, orderedKpis, loading } = this.props;

        return orderedKpis
            .filter((kpi) => kpi.displayed === true)
            .reduce((acc, kpi) => {
                const dataKpi = data.datas
                    ? data.datas.filter((dK) => {
                          return dK.kpiId === kpi.id || dK.id === kpi.id;
                      })
                    : []; // Search for kpi's data
                if (dataKpi.length > 0) {
                    dataKpi.forEach((value) => {
                        return acc.push({
                            ...kpiAttributes[kpi.id],
                            label: kpi.label,
                            ...value,
                            default: false,
                        });
                    });
                    return acc;
                }
                if (loading || kpiAttributes[kpi.id].displayOptions.default) {
                    acc.push({
                        ...kpiAttributes[kpi.id],
                        label: kpi.label,
                        default: true,
                    });
                }
                return acc;
            }, []);
    };

    isKpiEnabled = () => {
        return this.props.orderedKpis.some((kpi) => kpi.displayed === true);
    };

    filterSuggestions = (items, query) => {
        if (query === "") {
            return items;
        }
        return items.filter(
            (item) =>
                foldAccents(
                    this.props.p
                        .t(`modules.${this.props.module}.kpi.${item.id}.label`)
                        .toLowerCase()
                ).search(foldAccents(query.trim().toLowerCase())) !== -1
        );
    };

    render() {
        const {
            classes,
            module,
            disableForMultipleAccount,
            userSettings,
            orderedKpis,
            loading,
            error,
            p,
            isWatchingMultipleAccount,
        } = this.props;

        const isError =
            disableForMultipleAccount ||
            !this.isKpiEnabled() ||
            this.resultsToBeDisplayed().length === 0 ||
            error;

        let content = null;
        if (disableForMultipleAccount && isWatchingMultipleAccount) {
            content = (
                <span className={classes.error}>
                    {p.t("modules.data_analytics.multiple_accounts")}
                </span>
            );
        } else if (!this.isKpiEnabled()) {
            content = (
                <span className={classes.error}>{p.t("modules.data_analytics.no_data")}</span>
            );
        } else if (this.resultsToBeDisplayed().length === 0) {
            content = (
                <span
                    className={classes.error}
                    dangerouslySetInnerHTML={{
                        __html: p.t("modules.data_analytics.no_widget_selected"),
                    }}
                />
            );
        } else {
            content = (
                <ContentAnalytics
                    module={module}
                    datas={this.resultsToBeDisplayed()}
                    userSettings={userSettings}
                />
            );
        }

        return (
            <div className={classes.root} style={{ height: 152 }}>
                {!loading && (
                    <div
                        className={classNames({ [classes.contentRoot]: isError })}
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                    >
                        {content}
                    </div>
                )}
                {loading && (
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                        }}
                    >
                        <FlexCarousel
                            minSpacing={SLOT_SPACING}
                            slotWidth={isBrowser ? SLOT_WIDTH : SLOT_WIDTH_MOBILE}
                        >
                            {orderedKpis
                                .filter((kpi) => kpi.displayed === true)
                                .map((v, i) => {
                                    return <KpiLoading key={i} />;
                                })}
                        </FlexCarousel>
                    </div>
                )}
            </div>
        );
    }
}

export const AnalyticsBanner = withStylesAndTranslate(stylesAnalyticsBanner)(AnalyticsBannerClass);
