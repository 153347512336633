import { useContext } from "react";
import { ContextMenuContext } from "./ContextMenu.context";
import { NOTICE_ACTIONS, SEARCH_ACTIONS } from "./ContextMenu.constants";

export function useContextMenu(data, actions = []) {
    const onContextMenu = useContext(ContextMenuContext);
    if (data && actions.length) {
        return (event) => onContextMenu(event, data, actions);
    }
    return null;
}

export function useNoticeContextMenu(data, actions = NOTICE_ACTIONS) {
    return useContextMenu(data, actions);
}

export function useSearchContextMenu(data, actions = SEARCH_ACTIONS) {
    return useContextMenu(data, actions);
}
