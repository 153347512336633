import pure from "recompose/pure";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";

const styleRoot = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};
const styleIcon = {
    marginRight: 5,
    height: 24,
};
const styleLabel = {
    display: "inline-block",
    margin: "auto 0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: 17,
    lineHeight: "17px",
    fontSize: "1em",
};

/**
 * Cette cellule permet d'afficher une icône et un label (optionnel).
 * En vue small (s) elle affiche uniquement l'icône avec une largeur fixe.
 */
const CellIconStyledClass = ({
    color = "neutral",
    icon,
    label = "",
    view = "m",
    style = styleIcon,
    className,
}) => {
    let itemIcon;
    if (icon) {
        const iconProps = {
            style: {
                color: color,
                ...style,
            },
        };
        if (typeof icon === "string") {
            itemIcon = <HiIcon icon={icon} {...iconProps} />;
        } else if (typeof icon === "object") {
            itemIcon = { ...icon };
            itemIcon.props = {
                ...itemIcon.props,
                ...iconProps,
            };
        }
    }

    return (
        <div className={className} style={styleRoot} title={view === "s" ? label : undefined}>
            {itemIcon}
            {view !== "s" && label && <div style={styleLabel}>{label}</div>}
        </div>
    );
};

export const CellIconStyled = pure(CellIconStyledClass);
