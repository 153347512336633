const disputeReasons = {
    id: "dispute_reasons",
    data: [
        {
            id: "already-refund",
            label: "ref.transaction.dispute_reasons.already-refund",
        },
        {
            id: "chargeback-for-fraud",
            label: "ref.transaction.dispute_reasons.chargeback-for-fraud",
        },
        {
            id: "delivery-confirmation",
            label: "ref.transaction.dispute_reasons.delivery-confirmation",
        },
        {
            id: "conforming",
            label: "ref.transaction.dispute_reasons.conforming",
        },
        {
            id: "compliant-credit",
            label: "ref.transaction.dispute_reasons.compliant-credit",
        },
        {
            id: "other",
            label: "ref.transaction.dispute_reasons.other",
        },
    ],
    translate: true,
};

export default disputeReasons;
