import React from "react";
import PropTypes from "prop-types";
import { translate } from "../../../../services/i18n/translate";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import { VIEWS } from "../../../../constants";

const stylesCellSentinel = {
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 19,
        lineHeight: "19px",
        margin: "auto 0",
        verticalAlign: "middle",
    },
    smartDecision: {
        marginLeft: 2,
        display: "inline-block",
        minWidth: 18,
    },
    nowrap: { whiteSpace: "nowrap" },
};

/**
 * Cette cellule permet d'afficher le résultat de Sentinel (score & fraudResult & smartDecision)
 */
class CellSentinelClass extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.object,
        value: PropTypes.string,
        color: PropTypes.string,
        label: PropTypes.string,
        letter: PropTypes.string,
        active: PropTypes.bool,
        reviewer: PropTypes.string,
        outlined: PropTypes.bool,
    };

    static defaultProps = {
        active: false,
        outlined: false,
    };

    render() {
        const {
            active,
            classes,
            color,
            label,
            letter,
            outlined,
            reviewer,
            value,
            p,
            className,
            view,
        } = this.props;

        const _label = label ? label : value;
        const _letter = letter ? letter : _label[0];

        return (
            <div
                className={classNames(classes.wrapper, className)}
                title={reviewer ? `${_label} ${p.t("by")}\n${reviewer}` : _label}
            >
                <HiColoredLabel
                    active={active}
                    classes={{ root: classes.label }}
                    color={color}
                    label={view === VIEWS.SMALL ? _letter : _label}
                    outlined={outlined}
                    fontSize={13}
                />
            </div>
        );
    }
}

export const CellSentinel = translate(withStyles(stylesCellSentinel)(CellSentinelClass));
