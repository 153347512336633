import _isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";
import _isEqual from "lodash/isEqual";
import _transform from "lodash/transform";
import _intersection from "lodash/intersection";

/**
 * Get deep property value from path
 * @param obj
 * @param path
 * @returns {*}
 */
export function deepFind(obj, path) {
    if (!isDefined(obj) || !isDefined(path)) {
        return undefined;
    }
    if (!path.includes(".")) {
        return obj[path];
    }

    let paths = path.split(".");
    let current = obj;
    let i;

    for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
            return undefined;
        }
        current = current[paths[i]];
    }
    return current;
}

/**
 * merge target object with an array path
 * ex: {a: {b: 1, c:3}}, [a, b], 2 => {a: {b: 2, c:3}}
 * @param target
 * @param path
 * @param value
 * @returns {{}}
 */
export const deepMergeByPath = (target = {}, path, value) => {
    let [key, ...others] = path;
    if (others.length === 0) {
        return {
            ...target,
            [key]: value,
        };
    }

    return {
        ...target,
        [key]: deepMergeByPath(target[key], others, value),
    };
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
    return _transform(object, (result, value, key) => {
        if (!_isEqual(value, base[key])) {
            result[key] =
                _isObject(value) && _isObject(base[key]) ? difference(value, base[key]) : value;
        }
    });
}

export function exists(a, b) {
    return _isEmpty(_intersection(a, b)) === 0;
}

export const getValue = (object, path) => {
    let o = object;
    path = path.replace(/\[(\w+)\]/g, ".$1");
    path = path.replace(/^\./, "");
    let a = path.split(".");
    while (a.length) {
        let n = a.shift();
        if (n in o) {
            o = o[n];
        } else {
            return;
        }
    }
    return o;
};

export function isEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

export function isEmpty(obj) {
    // ECMA 5+
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;

    // Pre-ECMA 5+
    //return JSON.stringify(obj) === JSON.stringify({});
}

export function isDefined(obj) {
    return typeof obj !== "undefined";
}

/**
 * Are objects equal ?
 * @param obj1
 * @param obj2
 * @returns {number}
 */
export function objectsEquals(obj1, obj2) {
    try {
        if (
            typeof obj1 === "object" &&
            typeof obj2 === "object" &&
            Object.keys(obj1).length !== Object.keys(obj2).length
        ) {
            return false;
        }
        return Object.keys(difference(obj1, obj2)).length === 0;
    } catch (e) {
        if (e instanceof RangeError) {
            console.error(obj1, obj2);
            return false;
        }
    }
}

export const setValue = (object, path, value) => {
    let a = path.split(".");
    let o = object;
    for (let i = 0; i < a.length - 1; i++) {
        let n = a[i];
        if (n in o) {
            o = o[n];
        } else {
            o[n] = {};
            o = o[n];
        }
    }
    o[a[a.length - 1]] = value;
};
