export default {
    id: "reserve_released",
    data: [
        {
            id: "true",
            label: "ref.financial_report.reserve_released.true",
        },
        {
            id: "false",
            label: "ref.financial_report.reserve_released.false",
        },
    ],
    translate: true,
};
