import { availableLanguages } from "../constants";

/**
 * Format locale correctly.
 */
export function formatLocale(locale) {
    let loc = locale.split("-");
    if (loc.length !== 2) {
        loc = locale.split("_");
        if (loc.length !== 2) {
            return false;
        }
    }
    loc[1] = loc[1].toUpperCase();
    return loc.join("_");
}

/**
 * Get default navigator language
 *
 * @param defaultValue
 * @returns {string}
 */
export const getDefaultLanguage = (defaultValue = "en-US") => {
    let value = defaultValue;
    const options = availableLanguages.map((locale) => locale.id);
    if (navigator.language && navigator.language.length === 5) {
        if (options.indexOf(navigator.language.replace("-", "_")) >= 0) {
            value = navigator.language;
        }
    } else if (navigator.language && navigator.language.length === 2) {
        options.forEach((language) => {
            if (language.indexOf(navigator.language.replace("-", "_")) === 0) {
                value = language;
            }
        });
    }
    return value.replace("-", "_");
};

/**
 * Return number formatted properly depending on user number settings
 *
 * @param number
 * @param locale
 * @param precision
 * @param currency
 * @param format
 * @param maximumSignificantDigits
 * @param signDisplay
 * @returns {string}
 */
export function toUserLocalNumber(
    number,
    locale = navigator.language,
    precision = 0,
    currency,
    format,
    maximumSignificantDigits,
    signDisplay = "auto"
) {
    /* options available
     * https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Number/toLocaleString
     */
    let returnValue;
    let unit;
    if (format === "s") {
        if (number > 9999999999 || number < -9999999999) {
            number /= 1000000000;
            unit = "G";
        } else if (number > 9999999 || number < -9999999) {
            number /= 1000000;
            unit = "M";
        } else if (number > 9999 || number < -9999) {
            number /= 1000;
            unit = "k";
        }
    }

    let options = {
        ...(currency && {
            style: "currency",
            currency: currency,
            currencyDisplay: "symbol",
        }),
        minimumFractionDigits: format !== "s" ? precision : 0,
        maximumFractionDigits: format !== "s" ? precision : 0,
        maximumSignificantDigits,
        signDisplay,
    };
    if (number) {
        returnValue = Number.parseFloat(number).toLocaleString(locale.replace(/_/g, "-"), options);
    } else {
        returnValue = (0).toLocaleString(locale.replace(/_/g, "-"), options);
    }

    // Place unit (k, M) after number (don't move currency symbol !)
    if (unit !== "") {
        returnValue = returnValue.replace(/([\D]*)([\d,.\s]+)([\D]*)/, (match, p1, p2, p3) =>
            [p1, p2, unit, p3].join("")
        );
    }

    return returnValue;
}

/**
 * Replace any keyword by their correct uppercase translation
 * Case insensitive
 * (OR => OU, et => ET, AND => ET, in => DANS, ...)
 * @param query             La query à traduire
 * @param phrases           La liste des traductions à utiliser
 * @param type              Keywords ou operators
 * @param forceEnglish      Force la traduction de la query en anglais
 * @param selectionStart    Position du carret
 */
export const translateKeywordsOperators = (
    query,
    phrases,
    type,
    forceEnglish = false,
    selectionStart = 0
) => {
    let toReplace = {};
    if (type === "keywords") {
        toReplace = {
            "app.qsearch.keywords.and": "AND",
            "app.qsearch.keywords.not": "NOT",
            "app.qsearch.keywords.or": "OR",
        };
    } else if (type === "operators") {
        toReplace = {
            "app.qsearch.operators.not_in": "NOT IN",
            "app.qsearch.operators.in": "IN",
            "app.qsearch.operators.to": "TO",
        };
    }

    let q = query;

    Object.keys(toReplace).forEach((key) => {
        const regex = new RegExp(
            `(?!=)(.)\\s+(${phrases[key]}|${toReplace[key]})(?=\\s)(?!\\w)`,
            "gi"
        );
        let replaceWith = forceEnglish ? `$1 ${toReplace[key]}` : `$1 ${phrases[key]}`;
        if (
            type === "operators" &&
            q.slice(selectionStart).trimLeft().charAt(0) !== "(" &&
            selectionStart > 0
        ) {
            replaceWith += " (  )";
        }
        q = q.replace(regex, replaceWith);
    });

    return q.trimLeft();
};

/**
 * Translate options from references or staticOptions
 * handle hierarchic by children prop
 *
 * @param optionList
 * @param translate
 * @param p
 * @param selectableList
 * @param accountList
 * @returns {*}
 */
export const translateOptions = (optionList, translate, p, selectableList, accountList) => {
    if (accountList && accountList.length === 1 && accountList[0] === "_every") {
        accountList = [];
    }
    if (translate) {
        return optionList
            .map((option) => {
                if (
                    !selectableList ||
                    !selectableList.length ||
                    selectableList.indexOf(option.id) >= 0
                ) {
                    return {
                        ...option,
                        label: p.t(option.label, { smart_count: 1 }),
                        ...(option.label2 && { label2: p.t(option.label2, { smart_count: 1 }) }), // secondary translated label
                        ...(option.children &&
                            option.children.length > 0 && {
                                children: translateOptions(option.children, translate, p),
                            }),
                    };
                }
                return null;
            })
            .filter((option) => option !== null);
    } else if (accountList && accountList.length) {
        return optionList.filter((option) => {
            return option.accountId && accountList.indexOf(option.accountId.toString()) >= 0;
        });
    }
    return optionList;
};

/**
 * Replace key operator by their translation
 * (OR => OU, AND => ET, ...)
 * @param query
 * @param polyglot
 * @returns {*}
 */
export const translateQuery = (query, polyglot) => {
    const keywords = ["AND", "OR", "NOT", "TO"];
    let q = query;
    keywords.forEach((k) => {
        let re = new RegExp(`(^| )${k}(?!\\w)`, "gi");
        q = q.replace(re, ` ${polyglot.t(k)}`);
    });
    return q.trimLeft();
};

/**
 * Replace translated operator by their key
 * (OU => OR, ET => AND, ...)
 * @param query
 * @param polyglot
 * @returns {*}
 */
export const untranslateQuery = (query, polyglot) => {
    const keywords = ["AND", "OR", "NOT", "TO"];
    let q = query;
    keywords.forEach((k) => {
        let re = new RegExp(`(^| )${polyglot.t(k)}(?!\\w)`, "gi");
        q = q.replace(re, ` ${k}`);
    });
    return q.trimLeft();
};
