const cardCategories = {
    id: "card_categories",
    endpoint: "/card-categories",
    withSelectedAccounts: true,
    reduceFunc: (previous, current) =>
        current["id"]
            ? [
                  ...previous,
                  {
                      id: current["id"],
                      label:
                          current["label"].charAt(0).toUpperCase() +
                          current["label"].slice(1).toLowerCase(), // capitalize
                  },
              ]
            : previous,
};

export default cardCategories;
