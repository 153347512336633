import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

const stylesCellMonth = {
    wrapper: {
        width: "100%",
        height: 17,
        margin: "auto 0",
        display: "flex",
        lineHeight: "17px",
        verticalAlign: "middle",
    },
    rightEllipsisSpan: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        width: "100%",
    },
};

/**
 * Cette cellule permet d'afficher une date en fonction de la locale de l'utilisateur
 */
class CellMonthClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Date as string
         */
        date: PropTypes.string,
        /**
         * Timezone de l'utilisateur
         */
        dateTimezone: PropTypes.object,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    static defaultProps = {
        displayTime: false,
        formatShort: "DD/MM/YYYY",
        view: "m",
    };

    monthFormat = "MMMM YYYY";

    render() {
        const { classes, date, view, title = "", className } = this.props;

        if (!date) {
            return "";
        }

        let mdate = moment(date, "MM-yyyy");
        const _title = mdate.format(this.monthFormat);
        const isTitle = title ? title : _title;

        let label = mdate.format(this.monthFormat);

        return (
            <div
                className={classNames(classes.wrapper, className)}
                title={view === "s" ? isTitle : title ? title : undefined}
            >
                <div className={classes.rightEllipsisSpan}>{label}</div>
            </div>
        );
    }
}

export const CellMonth = withStyles(stylesCellMonth, { name: "HmuiCellDate" })(CellMonthClass);
