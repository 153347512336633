import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../../../services/i18n";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import HiFormLabel from "@hipay/hipay-material-ui/HiForm/HiFormLabel";

const useStylesWeekDayPicker = makeStyles((theme) => ({
    weekdays: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(5),
    },
    day: {
        color: theme.palette.neutral.dark,
        cursor: "pointer",
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 40,
        height: 40,
        backgroundColor: "#EEEEEE",
        borderRadius: "50%",
        paddingTop: 10,
        textAlign: "center",
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
        },
    },
    activeDay: {
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
    },
    focusedLabel: { color: theme.palette.primary.main },
}));

export const WeekDayPicker = (props) => {
    const { value, onChange } = props;

    const p = useP();
    const classes = useStylesWeekDayPicker();

    const [focusedLabel, setFocusedLabel] = useState(false);

    const handleDayKeyDown = useCallback((event) => {
        if (event.keyCode === 32) {
            // Space
            onChange(event);
        }
    }, []);

    const handleFocus = () => {
        setFocusedLabel(true);
    };

    const handleBlur = () => {
        setFocusedLabel(false);
    };

    return (
        <HiFormControl component="fieldset" onMouseEnter={handleFocus} onMouseLeave={handleBlur}>
            <HiFormLabel
                component="legend"
                className={classNames({ [classes.focusedLabel]: focusedLabel === true })}
            >
                {p.t("common.search.export.form.weekday_recurrence")}
            </HiFormLabel>
            <div className={classes.weekdays}>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.monday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "1" })}
                    data-value="1"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.monday")}
                </span>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.tuesday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "2" })}
                    data-value="2"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.tuesday")}
                </span>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.wednesday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "3" })}
                    data-value="3"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.wednesday")}
                </span>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.thursday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "4" })}
                    data-value="4"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.thursday")}
                </span>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.friday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "5" })}
                    data-value="5"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.friday")}
                </span>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.saturday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "6" })}
                    data-value="6"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.saturday")}
                </span>
                <span
                    onClick={onChange}
                    onKeyDown={handleDayKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    title={p.t("common.search.export.form.weekDayPicker.sunday")}
                    className={classNames(classes.day, { [classes.activeDay]: value === "7" })}
                    data-value="7"
                    tabIndex="0"
                >
                    {p.t("common.search.export.form.weekDayPicker.letters.sunday")}
                </span>
            </div>
        </HiFormControl>
    );
};

WeekDayPicker.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};
