const customerOperatingSystems = {
    id: "customer_operating_systems",
    endpoint: "/customer-operating-systems",
    withSelectedAccounts: true,
    organizeFunc: (operatingSystems, p) => {
        // alphabetic sort
        return operatingSystems.sort((a, b) => {
            return a.label.toLowerCase() === b.label.toLowerCase()
                ? 0
                : a.label.toLowerCase() < b.label.toLowerCase()
                ? -1
                : 1;
        });
    },
};

export default customerOperatingSystems;
