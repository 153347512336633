import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import { toUserLocalNumber } from "../../../../utils/i18n";
import { DISPLAY_AMOUNT } from "../../../constants/displayTypes";

const stylesCellNumeric = () => ({
    wrapper: {
        width: "100%",
        height: 17,
        margin: "auto 0",
        display: "flex",
        lineHeight: "17px",
        verticalAlign: "middle",
        textAlign: "right",
    },
    numericText: {
        width: "100%",
        display: "inline-block",
        fontSize: "1em",
    },
    left: { textAlign: "left" },
});

/**
 * Cette cellule permet d'afficher un volume ou un montant avec devise
 */
class CellNumericClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Code de la devise (ISO 4217)
         */
        currency: PropTypes.string,
        /**
         * Booléen pour afficher ou non la devise
         */
        displayCurrency: PropTypes.bool,
        /**
         * Type d'affichage de l'attribute
         */
        displayType: PropTypes.string,
        /**
         * Locale BCP 47
         */
        locale: PropTypes.string,
        /**
         * Nombre de chiffres après la virgule
         */
        precision: PropTypes.number,
        /**
         * Volume/Amount
         */
        value: PropTypes.number.isRequired,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
        /**
         * Align (center/left/right)
         */
        align: PropTypes.oneOf(["center", "left", "right"]),
        /**
         * Display + or -
         */
        signDisplay: PropTypes.oneOf(["auto", "never", "always", "exceptZero", undefined]),
    };

    static defaultProps = {
        align: "right",
        view: "m",
    };

    render() {
        const {
            classes,
            value,
            locale,
            currency,
            precision,
            view,
            title = "",
            align,
            className,
            displayCurrency,
            displayType,
            signDisplay,
        } = this.props;

        let _precision = typeof precision !== "undefined" ? precision : view === "s" ? 0 : 2;

        let displayedValue = toUserLocalNumber(
            value,
            locale,
            _precision,
            currency,
            view,
            undefined,
            signDisplay
        );
        let _title = toUserLocalNumber(value, locale, _precision, currency);

        if (displayType === DISPLAY_AMOUNT && !!currency && !displayCurrency) {
            displayedValue = value;
            _title = value;
        }

        const isTitle = title ? title : _title;

        return (
            <div
                className={classNames(classes.wrapper, className, {
                    [classes.left]: align === "left",
                })}
                title={view === "s" ? isTitle : title ? title : undefined}
            >
                <div className={classes.numericText}>{displayedValue}</div>
            </div>
        );
    }
}

export const CellNumeric = withStyles(stylesCellNumeric)(CellNumericClass);
