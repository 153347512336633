import React from "react";
import Collapse from "@mui/material/Collapse";
import { withStylesAndTranslate } from "../../../../../hoc";
import Row from "./Row";

const stylesRowParent = {
    collapseEntered: {
        overflow: "initial",
        height: "auto",
    },
    collapseWrapperInner: {
        display: "flex",
        flexDirection: "column",
    },
    buttonIcon: { margin: 4 },
};

const customDataBtnMore = { custom_data: { isBtnMore: true } };

/**
 * Build Row & handle details rows (inline or collapsed payment operation rows...)
 */
class RowParent extends React.Component {
    constructor(props) {
        super(props);

        this.state = { openedDetailName: null };
    }

    toggleOpenDetail = (e, detailName) => {
        this.setState((prevState) => ({
            openedDetailName: prevState.openedDetailName === detailName ? null : detailName,
        }));
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            this.state.openedDetailName !== nextState.openedDetailName ||
            this.props.isDisplayRank !== nextProps.isDisplayRank ||
            this.props.isDisplaySelector !== nextProps.isDisplaySelector ||
            JSON.stringify(this.props.columnList) !== JSON.stringify(nextProps.columnList)
        );
    }

    render() {
        const {
            datas,
            details,
            rowId,
            classes,
            index,
            isDisplaySelector,
            isDisplayRank,
            isDisabled,
            ...other
        } = this.props;
        const { openedDetailName } = this.state;

        if (details) {
            return (
                <React.Fragment>
                    <Row
                        key={`row-${rowId}`}
                        details={details}
                        rowId={rowId}
                        openedDetailName={openedDetailName}
                        index={index}
                        isDisplaySelector={isDisplaySelector}
                        isDisplayRank={isDisplayRank}
                        {...other}
                        datas={datas}
                        onClickOpenDetail={this.toggleOpenDetail}
                        isDisabledRow={isDisabled}
                    />
                    {Object.keys(details).map((detailName) => {
                        if (details[detailName].rows.length > 1) {
                            return (
                                <Collapse
                                    key={`row-${rowId}-details-${detailName}`}
                                    id={`row-${rowId}-details-${detailName}`}
                                    in={openedDetailName === detailName}
                                    classes={{
                                        entered: classes.collapseEntered,
                                        wrapperInner: classes.collapseWrapperInner,
                                    }}
                                >
                                    {openedDetailName &&
                                        details[detailName].rows.map((row, detailIdx) => {
                                            if (detailIdx === 5 && detailName === "custom_data") {
                                                return (
                                                    <Row
                                                        key={`row-${rowId}-detail-${detailName}-${row.rowId}`}
                                                        id={`row-${rowId}-detail-${detailName}-${row.rowId}`}
                                                        rowId={rowId}
                                                        displayAsDetailRow
                                                        isDisplaySelector={isDisplaySelector}
                                                        isDisplayRank={isDisplayRank}
                                                        {...other}
                                                        datas={customDataBtnMore}
                                                    />
                                                );
                                            } else if (
                                                detailIdx > 5 &&
                                                detailName === "custom_data"
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <Row
                                                    key={`row-${rowId}-detail-${detailName}-${row.rowId}`}
                                                    id={`row-${rowId}-detail-${detailName}-${row.rowId}`}
                                                    rowId={rowId}
                                                    displayAsDetailRow
                                                    isDisplaySelector={isDisplaySelector}
                                                    isDisplayRank={isDisplayRank}
                                                    {...other}
                                                    datas={row.datas}
                                                />
                                            );
                                        })}
                                </Collapse>
                            );
                        }
                        return null;
                    })}
                </React.Fragment>
            );
        }
        return (
            <Row
                key={`row-${rowId}`}
                id={`row-${rowId}`}
                details={details}
                rowId={rowId}
                index={index}
                isDisplaySelector={isDisplaySelector}
                isDisplayRank={isDisplayRank}
                {...other}
                datas={datas}
                isDisabledRow={isDisabled}
            />
        );
    }
}

export default withStylesAndTranslate(stylesRowParent)(RowParent);
