import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import ChevronRight from "mdi-material-ui/ChevronRight";
import withStyles from "@mui/styles/withStyles";
import { CarouselContainer } from "./CarouselContainer";
import { CarouselSlot } from "./CarouselSlot";
import { Swipeable } from "react-swipeable";
import { isBrowser } from "react-device-detect";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";

const styles = (theme) => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        margin: "auto",
        position: "relative",
    },
    btn: {
        position: "absolute",
        width: 72,
        top: "calc(50% - 24px)",
    },
    btnLeft: {
        left: 0,
        paddingLeft: 24,
    },
    btnRight: {
        right: 0,
        paddingRight: 24,
    },
    icon: {
        height: "100%",
        lineHeight: "1",
        color: theme.palette.primary.contrastText,
    },
    browserWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginLeft: 68,
        marginRight: 68,
        overflow: "hidden",
    },
    mobileWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        overflow: "hidden",
    },
    slot: { display: "inherit" },
});

/**
 * Give the width and height of an element
 *
 * @param {node} element
 */
const measureElement = (element) => {
    const DOMNode = ReactDOM.findDOMNode(element);
    return {
        width: DOMNode.offsetWidth,
        height: DOMNode.offsetHeight,
    };
};

/**
 * The flex carousel display inline components with auto spacing if it has
 * enough space, or a carousel if not.
 */
class FlexCarouselClass extends React.Component {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Components to be displayed
         */
        children: PropTypes.node,
        /**
         * Min spacing between two components
         */
        minSpacing: PropTypes.number.isRequired,
        /**
         * Slot width
         */
        slotWidth: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            displayCarousel: false,
            position: 0,
            sliding: false,
            direction: "next",
        };
    }

    /**
     * Ensure to be tested when component does has a sized
     */
    componentDidMount() {
        this.shouldCarouselBeDisplayed();
    }

    /**
     * Call only if the number of children change
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps && prevProps.children.length !== this.props.children.length) {
            this.shouldCarouselBeDisplayed();
        }
    }

    /**
     * See if we must display a carousel or not
     */
    shouldCarouselBeDisplayed() {
        const { children, minSpacing, slotWidth } = this.props;

        const spacing = 2 * minSpacing;
        const slotWidthWithSpacing = slotWidth + spacing;
        const wrapperWidth = children.length * slotWidthWithSpacing;
        const btnWidth = 2 * 68;

        this.content &&
            this.setState({
                displayCarousel:
                    measureElement(this.content).width <= wrapperWidth + btnWidth ? true : false,
            });
    }

    /**
     * Get order of element
     */
    getOrder = (itemIndex) => {
        const { position } = this.state;
        const { children } = this.props;
        const numItems = children.length * 2 || 1;
        if (itemIndex - position < 0) {
            return numItems - Math.abs(itemIndex - position);
        }
        return itemIndex - position;
    };

    /**
     * Display next slot
     */
    nextSlide = () => {
        const { position } = this.state;
        const { children } = this.props;
        const numItems = children.length * 2 || 1;
        this.doSliding("next", position === numItems - 1 ? 0 : position + 1);
    };

    /**
     * Display previous slot
     */
    prevSlide = () => {
        const { position } = this.state;
        const { children } = this.props;
        const numItems = children.length * 2 || 1;
        this.doSliding("prev", position === 0 ? numItems - 1 : position - 1);
    };

    /**
     * Active transition animation
     */
    doSliding = (direction, position) => {
        this.setState({
            sliding: true,
            direction,
            position,
        });
        setTimeout(() => {
            this.setState({ sliding: false });
        }, 50);
    };

    /**
     * To make a smooth loop, we take the last child and put it at the beginning
     * of the loop, it will be hidden with a translation.
     */
    loopOnChildren(slots) {
        const numItems = slots.length || 1;
        let loopingChildren = [slots[numItems - 1], ...slots, ...slots];
        loopingChildren.pop();
        return loopingChildren;
    }

    /**
     * Handle swipping
     */
    handleSwipe = (isNext) => {
        if (isNext) {
            this.nextSlide();
        } else {
            this.prevSlide();
        }
    };

    render() {
        const { classes, children, minSpacing, slotWidth } = this.props;

        const { displayCarousel, sliding, direction } = this.state;

        return (
            <div id={"carousel"} ref={(r) => (this.content = r)} className={classes.container}>
                {displayCarousel ? (
                    <div className={classes.container}>
                        {isBrowser && (
                            <div className={classNames(classes.btn, classes.btnLeft)}>
                                <HiIconButton onClick={this.prevSlide}>
                                    <ChevronLeft className={classes.icon} />
                                </HiIconButton>
                            </div>
                        )}
                        <Swipeable
                            onSwipedLeft={this.nextSlide}
                            onSwipedRight={this.prevSlide}
                            className={isBrowser ? classes.browserWrapper : classes.mobileWrapper}
                            preventDefaultTouchmoveEvent={true}
                        >
                            <CarouselContainer
                                id={"carousel-container"}
                                sliding={sliding}
                                direction={direction}
                                slotWidth={slotWidth}
                                spacing={minSpacing}
                            >
                                {this.loopOnChildren(children).map((child, index) => (
                                    <CarouselSlot
                                        id={`carousel-slot-${index}`}
                                        key={index}
                                        width={slotWidth}
                                        spacing={minSpacing}
                                        nbslots={children.length}
                                        order={this.getOrder(index)}
                                        style={{ width: slotWidth }}
                                    >
                                        {child}
                                    </CarouselSlot>
                                ))}
                            </CarouselContainer>
                        </Swipeable>
                        {isBrowser && (
                            <div className={classNames(classes.btn, classes.btnRight)}>
                                <HiIconButton onClick={this.nextSlide}>
                                    <ChevronRight className={classes.icon} />
                                </HiIconButton>
                            </div>
                        )}
                    </div>
                ) : (
                    <div id={"carousel-container"} className={classes.container}>
                        <div className={isBrowser ? classes.browserWrapper : classes.mobileWrapper}>
                            {children.map((child, index) => (
                                <div
                                    id={`carousel-slot-${index}`}
                                    key={index}
                                    className={classes.slot}
                                    style={{ width: slotWidth }}
                                >
                                    {child}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const FlexCarousel = withStyles(styles)(FlexCarouselClass);
