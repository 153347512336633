import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const prettyPrint = (json) => {
    json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
            var cls = "number";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "key";
                } else {
                    cls = "string";
                }
            } else if (/true|false/.test(match)) {
                cls = "boolean";
            } else if (/null/.test(match)) {
                cls = "null";
            }
            return '<span class="' + cls + '">' + match + "</span>";
        }
    );
};

const Cell = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.onClick && props.onClick(e);
    };

    return (
        <div className={props.className} onClick={handleClick}>
            {props.hasExpandFeature && (
                <div className="header-cell">
                    {props.isExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    <Typography variant="body2">{props.message}</Typography>
                </div>
            )}
            {(props.isExpand || !props.hasExpandFeature) && (
                <pre>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: prettyPrint(JSON.stringify(props.value, null, 4)),
                        }}
                    />
                </pre>
            )}
        </div>
    );
};

const StyledCell = styled(Cell)`
    font-size: 12px;
    width: 100%;
    margin: 0 8px;

    cursor: ${(props) => (props.hasExpandFeature ? "pointer" : "non")};

    .header-cell {
        display: flex;
        justify-content: left;
        align-items: center;
    }

    pre {
        text-overflow: clip;
        white-space: pre-wrap;
        overflow: visible;
        margin-left: 20px;
    }

    .string {
        color: olive;
    }
    .number {
        color: tomato;
    }
    .boolean {
        color: blue;
    }
    .null {
        color: magenta;
    }
    .key {
        color: brown;
    }
`;

Cell.propTypes = {
    hasExpandFeature: PropTypes.bool,
    isExpand: PropTypes.bool,
    message: PropTypes.string,
    value: PropTypes.object.isRequired,
    onClick: PropTypes.func,
};

Cell.defaultProps = {
    hasExpandFeature: false,
    isExpand: false,
};

export const CellStringifyObject = StyledCell;
