import React, { useCallback, useMemo, useState, useRef } from "react";
import { ScrollbarsContext } from "./scrollbars-context";
import { Scrollbars } from "react-custom-scrollbars";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        transition: ".2s",
        "&>div": {
            backgroundColor: "inherit",
            backgroundImage: "inherit",
        },
    },
}));

export const ScrollbarsProvider = ({ children }) => {
    const classes = useStyles();
    const scrollbarsRef = useRef(null);
    const [scrolled, setScrolled] = useState(false);

    /**
     * Smooth scroll to scrollTop value (px)
     * @type {Function}
     */
    const scrollTo = useCallback(
        (_value, append = false) => {
            if (!scrollbarsRef.current) {
                return;
            }
            scrollbarsRef.current.view.scroll({
                top: append ? _value + scrollbarsRef.current.getScrollTop() : _value,
                behavior: "smooth",
            });
        },
        [scrollbarsRef]
    );

    /**
     * Handle scroll frame
     * - set scrolled state (boolean)
     */
    const handleScrollFrame = useCallback(
        (values) => {
            if (values.scrollTop > 0 !== scrolled) {
                setScrolled(!scrolled);
            }
        },
        [scrolled, setScrolled]
    );

    /**
     * build and memo value
     */
    const value = useMemo(
        () => ({
            scrolled,
            scrollTo,
        }),
        [scrolled, scrollTo]
    );

    return (
        <Scrollbars
            className={classes.root}
            onScrollFrame={handleScrollFrame}
            ref={scrollbarsRef}
            renderThumbVertical={({ style, ...props }) => (
                // Fix scrollbar zIndex
                <div
                    {...props}
                    style={{
                        ...style,
                        zIndex: 999,
                        backgroundColor: "rgba(0,0,0,0.2)",
                        borderRadius: "inherit",
                    }}
                />
            )}
            renderTrackHorizontal={() => <div />}
        >
            <ScrollbarsContext.Provider value={value}>
                {React.Children.only(children)}
            </ScrollbarsContext.Provider>
        </Scrollbars>
    );
};
