import { formFieldTypes, displayTypes } from "@console/core";

/**
 * Attributes internes (anciennement dataFieldType)
 *
 * Ce sont les propriétés de l'entité du module.
 * Ils permettent de générer:
 * - les champs du formulaire de recherche
 * - les colonnes du tableau de résultat
 * ... plus ou moins tout ce qui touche aux données du module.
 *
 */
export default {
    // Technical
    acquirer_name: {
        id: "acquirer_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { lazy: true },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "technical.acquirer.acquirer" },
        displayOptions: {},
        needMultipleAccounts: true,
        qsearch: true,
        order: 35,
        internal: true,
    },
    // Settlement
    settlement_status: {
        id: "settlement_status",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { sort: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "settlement_statuses",
        jsonApiConf: { valuePath: "settlement.status" },
        qsearch: true,
    },
    settlement_sepa: {
        id: "settlement_sepa",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_BOOLEAN,
        jsonApiConf: { valuePath: "settlement.sepa" },
        displayOptions: {},
        reference: "settlement_sepa",
    },
    creditor_bic: {
        id: "creditor_bic",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            sort: true,
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "settlement.bank.creditor_bic" },
        displayOptions: {},
        reference: "creditor_bics",
    },
    debitor_iban: {
        id: "debitor_iban",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX_TO_SELECT,
        formFieldOptions: {
            helperText: false,
            sort: true,
            restrictedBySelectedAccountList: true,
            hasEmptyOption: false,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "settlement.bank.debitor_iban" },
        displayOptions: {},
        reference: "debitor_ibans",
    },
    debitor_bic: {
        id: "debitor_bic",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            sort: true,
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "settlement.bank.debitor_bic" },
        displayOptions: {},
        reference: "debitor_bics",
    },
    merchant_bank_country: {
        id: "merchant_bank_country",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX_TO_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
            hasEmptyOption: false,
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { ellipsis: "middle" },
        reference: "merchant_bank_countries",
        jsonApiConf: { valuePath: "settlement.bank.creditor_bank_country" },
        qsearch: false,
        order: 120,
    },
    // Interchange
    interchange_amount_internal: {
        id: "interchange_amount_internal",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "interchange.amount_v2",
            additional: [
                {
                    id: "currency",
                    valuePath: "interchange.currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    // Scheme fee
    schemefee_amount_internal: {
        id: "schemefee_amount_internal",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "schemefee.amount_v2",
            additional: [
                {
                    id: "currency",
                    valuePath: "schemefee.currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
};
