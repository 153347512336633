import { formFieldTypes, displayTypes } from "@console/core";
import { capitalize } from "@hipay/hipay-material-ui/utils/helpers";

/**
 * Attributes internes (anciennement dataFieldType)
 *
 * Ce sont les propriétés de l'entité du module.
 * Ils permettent de générer:
 * - les champs du formulaire de recherche
 * - les colonnes du tableau de résultat
 * ... plus ou moins tout ce qui touche aux données du module.
 *
 */
export default {
    // Account
    account: {
        id: "account",
        formFieldType: formFieldTypes.FORM_FIELD_ACCOUNT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        jsonApiConf: { valuePath: "account.account_id" },
    },
    account_id: {
        id: "account_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        jsonApiConf: {
            valuePath: "account.publicref",
            additional: [
                {
                    id: "id",
                    valuePath: "account.account_id",
                },
            ],
        },
        qsearch: true,
        order: 60,
    },
    account_name: {
        id: "account_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: {
            valuePath: "account.name",
            additional: [
                {
                    id: "color",
                    valuePath: "account.color",
                },
                {
                    id: "publicref",
                    valuePath: "account.publicref",
                },
                {
                    id: "id",
                    valuePath: "account.account_id",
                },
            ],
        },
        displayOptions: {},
        needMultipleAccounts: true,
        qsearch: true,
        order: 65,
    },
    business_id: {
        id: "business_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "account.business.business_id" },
        displayOptions: {},
        needMultipleAccounts: true,
        qsearch: true,
        order: 50,
    },
    business_name: {
        id: "business_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "account.business.name" },
        displayOptions: {},
        needMultipleAccounts: true,
        qsearch: true,
        order: 55,
    },
    // Financial Op
    amount_excl_tax: {
        id: "amount_excl_tax",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "amount_excl_tax_v2",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    amount_incl_tax: {
        id: "amount_incl_tax",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "amount_incl_tax_v2",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    amount_tax: {
        id: "amount_tax",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "amount_tax_v2",
            additional: [
                {
                    id: "currency",
                    valuePath: "currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    collecting_date: {
        id: "collecting_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        jsonApiConf: { valuePath: "date_posted" },
        displayOptions: {
            exclude: false,
            sortable: true,
        },
    },
    financial_operation_currency: {
        id: "financial_operation_currency",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            sort: true,
            restrictedBySelectedAccountList: true,
            hasEmptyOption: false,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { fixedCellWidth: true },
        reference: "operation_currencies",
        jsonApiConf: { valuePath: "currency" },
    },
    financial_operation_date: {
        id: "financial_operation_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        jsonApiConf: { valuePath: "date_posted" },
        displayOptions: {
            exclude: false,
            sortable: true,
        },
        qsearch: true,
        order: 10,
    },
    financial_operation_description: {
        id: "financial_operation_description",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: true,
            noData: "attributes.financial_report.financial_operation_description.noData",
            restrictedBySelectedAccountList: true,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        jsonApiConf: { valuePath: "description" },
        reference: "financial_operation_descriptions",
    },
    financial_operation_mode: {
        id: "financial_operation_mode",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "mode" },
        displayOptions: {},
        reference: "financial_operation_modes",
    },
    financial_operation_type: {
        id: "financial_operation_type",
        formFieldType: formFieldTypes.FORM_FIELD_NESTED_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            sort: false,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "type" },
        displayOptions: {},
        reference: "financial_operation_types",
        qsearch: true,
        order: 5,
    },
    financial_operation_id: {
        id: "financial_operation_id",
        formFieldOptions: {},
        jsonApiConf: { valuePath: "opid" },
        displayOptions: {},
    },
    product_name: {
        id: "product_name",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            restrictedBySelectedAccountList: true,
            helperText: false,
            sort: false,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "product.name" },
        displayOptions: {},
        reference: "product_names",
        qsearch: false, // DYNAMIC REFERENCE NOT HANDLE ON QSEARCH YET
        order: 5,
    },
    // Interchange
    interchange_amount: {
        id: "interchange_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "interchange.mch_amount",
            additional: [
                {
                    id: "currency",
                    valuePath: "interchange.currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    // Invoice
    invoice_number: {
        id: "invoice_number",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "invoice.reference" },
        displayOptions: { clickable: (can) => can("manage-module", "invoice") },
        qsearch: true,
        order: 15,
    },
    invoice_status: {
        id: "invoice_status",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "invoice.status" },
        displayOptions: {},
        reference: "invoice_statuses",
        qsearch: true,
        order: 20,
    },
    // Reserve
    reserve_release_date: {
        id: "reserve_release_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        jsonApiConf: { valuePath: "reserve.date_release" },
        displayOptions: {
            exclude: false,
            sortable: true,
        },
    },
    reserve_released: {
        id: "reserve_released",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "reserve.is_released" },
        displayOptions: {},
        reference: "reserve_released",
    },
    // Scheme fee
    schemefee_amount: {
        id: "schemefee_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "schemefee.mch_amount",
            additional: [
                {
                    id: "currency",
                    valuePath: "schemefee.currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    // Settlement
    settlement_date: {
        id: "settlement_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        jsonApiConf: { valuePath: "settlement.date_value" },
        displayOptions: {
            exclude: false,
            sortable: true,
        },
        qsearch: true,
        order: 30,
    },
    settlement_id: {
        id: "settlement_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "settlement.settlement_id" },
        displayOptions: {},
        qsearch: true,
        order: 25,
    },
    original_settlement_id: {
        id: "original_settlement_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "settlement.original_settlement_id" },
        displayOptions: {},
        qsearch: true,
        order: 80,
    },
    settlement_original_amount: {
        id: "settlement_original_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "settlement.original.amount",
            additional: [
                {
                    id: "currency",
                    valuePath: "settlement.original.currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    settlement_original_currency: {
        id: "settlement_original_currency",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            sort: true,
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "settlement_original_currencies",
        jsonApiConf: { valuePath: "settlement.original.currency" },
    },
    settlement_amount: {
        id: "settlement_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { onlyNumbers: true },
        displayType: displayTypes.DISPLAY_AMOUNT,
        jsonApiConf: {
            valuePath: "settlement.amount",
            additional: [
                {
                    id: "currency",
                    valuePath: "settlement.currency",
                },
                {
                    id: "precision",
                    value: 3,
                },
            ],
        },
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 3,
            amountStats: true,
        },
    },
    settlement_currency: {
        id: "settlement_currency",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            sort: true,
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "settlement_currencies",
        jsonApiConf: { valuePath: "settlement.currency" },
        qsearch: false, // DYNAMIC REFERENCE NOT HANDLE ON QSEARCH YET
        order: 120,
    },
    creditor_iban: {
        id: "creditor_iban",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX_TO_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            restrictedBySelectedAccountList: true,
            helperText: false,
            sort: false,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "settlement.bank.creditor_iban" },
        displayOptions: {},
        reference: "creditor_ibans",
    },
    settlement_notified: {
        id: "settlement_notified",
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: {
            label: (value, p) => {
                return p.t(`ref.financial_report.settlement_notified.${value}`);
            },
        },
        jsonApiConf: { valuePath: "settlement.notified" },
    },
    // Technical
    acquirer_registration_id: {
        id: "acquirer_registration_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {
            helperText: true,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "technical.acquirer.registration_id" },
        displayOptions: {},
        qsearch: true,
        order: 70,
    },
    // Transaction
    authorization_date: {
        id: "authorization_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        jsonApiConf: { valuePath: "transaction.operation.date_authorized" },
        displayOptions: {
            exclude: false,
            sortable: true,
        },
        qsearch: true,
        order: 40,
    },
    capture_date: {
        id: "capture_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        jsonApiConf: { valuePath: "transaction.operation.date_value" },
        displayOptions: {
            exclude: false,
            sortable: true,
        },
    },
    custom_data: {
        id: "custom_data",
        formFieldType: formFieldTypes.FORM_FIELD_CUSTOM_DATA,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_CUSTOM_DATA,
        jsonApiConf: { valuePath: "transaction.custom_data" },
        displayOptions: {
            sortable: false,
            dataType: "custom_data",
            indicator: true,
        },
        multiple: true,
        rgpdCompliance: true,
    },
    customer_id: {
        id: "customer_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "transaction.customer.customer_id" },
        displayOptions: {},
        qsearch: true,
        rgpdCompliance: true,
    },
    ip_country: {
        id: "ip_country",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX_TO_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
            hasEmptyOption: false,
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { ellipsis: "middle" },
        reference: "ip_countries",
        jsonApiConf: { valuePath: "transaction.customer.ip_country" },
        qsearch: false,
        rgpdCompliance: true,
    },
    mch_orderid: {
        id: "mch_orderid",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "transaction.operation.mch_orderid" },
        displayOptions: {
            displayOptions: { clickable: (can) => can("manage-module", "transaction") },
        },
        qsearch: true,
        order: 100,
    },
    mch_operation_id: {
        id: "mch_operation_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "transaction.payment.mch_operation_id" },
        displayOptions: {},
        qsearch: true,
        order: 90,
    },
    payment_means: {
        id: "payment_means",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            sort: true,
            fallbackImage: "/img/references/payment_means/no-logo.svg",
            hasEmptyOption: false,
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        jsonApiConf: { valuePath: "transaction.payment.brand" },
        displayOptions: { size: 32 },
        reference: "payment_means",
    },
    payment_method: {
        id: "payment_method",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "icon",
            hideCheckbox: true,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_ICON,
        displayOptions: {},
        reference: "payment_methods",
        jsonApiConf: { valuePath: "transaction.payment.method" },
    },
    remittance_reference: {
        id: "remittance_reference",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            formatable: true,
            format: ({ infos }) => {
                return infos || {};
            },
            title: ({ currency, infos }) => {
                let formatLabel = (str) => capitalize(str.replace(/_/g, " ")); // "field_type" >> "Field type"
                let formatDate = (str) =>
                    [str.slice(0, 4), str.slice(4, 6), str.slice(6)].filter(Boolean).join("/"); // "20191231" >> "2019/12/31"
                let formatAmount = (str, curr) =>
                    str.toLocaleString(undefined, {
                        curr,
                        currencyDisplay: "symbol",
                        style: "currency",
                    }); // "12.5" >> "12,50 €"
                if (infos) {
                    return Object.keys(infos)
                        .map((k) => {
                            if (k === "fields") {
                                return infos["fields"]
                                    .filter(({ name, v }) => name && v)
                                    .map(({ name, v }) => {
                                        let field = `${formatLabel(name)} : `;
                                        if (/date/i.test(name)) {
                                            field += formatDate(v);
                                        } else if (/amount/i.test(name)) {
                                            field += formatAmount(v, currency);
                                        } else {
                                            field += v;
                                        }
                                        // specific separation for ONEY_CRP partial remittance
                                        if (/Payment_amount/.test(name)) {
                                            field = "-\n" + field;
                                        }
                                        return field;
                                    })
                                    .join("\n");
                            }
                            return `${formatLabel(k)} : ${infos[k]}`;
                        })
                        .join("\n");
                }
                return null;
            },
        },
        jsonApiConf: {
            valuePath: "transaction.operation.remittance_reference",
            additional: [
                {
                    id: "currency",
                    valuePath: "transaction.currency",
                },
                {
                    id: "acquirer",
                    valuePath: "transaction.operation.acquirer",
                },
                {
                    id: "partial_remittance",
                    valuePath: "transaction.operation.partial_remittance",
                },
            ],
        },
    },
    transaction_currency: {
        id: "transaction_currency",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            sort: true,
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { fixedCellWidth: true },
        reference: "transaction_currencies",
        jsonApiConf: { valuePath: "transaction.currency" },
    },
    transaction_id: {
        id: "transaction_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        jsonApiConf: { valuePath: "transaction.trxid" },
        displayOptions: { clickable: (can) => can("manage-module", "transaction") },
        qsearch: true,
        order: 45,
    },
    // Attibutes for dashboard
    settled_date: {
        id: "settled_date",
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            displayTime: false,
            view: "m",
        },
    },
    settled_amount: {
        id: "settled_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    sales_amount: {
        id: "sales_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    refunds_amount: {
        id: "refunds_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    fees_amount: {
        id: "fees_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    chargebacks_amount: {
        id: "chargebacks_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    rolling_reserve_amount: {
        id: "rolling_reserve_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    deffered_amount: {
        id: "deffered_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    invoice_fees_amount: {
        id: "invoice_fees_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
    other_amount: {
        id: "other_amount",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            precision: 2,
        },
    },
};
