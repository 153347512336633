import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ArrowBottomRight from "mdi-material-ui/ArrowBottomRight";
import ArrowTopRight from "mdi-material-ui/ArrowTopRight";
import Equal from "mdi-material-ui/Equal";
import { formatRate } from "@hipay/hipay-material-ui/utils/helpers";
import withStyles from "@mui/styles/withStyles";

const stylesCellRate = (theme) => ({
    flexContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        textAlign: "right",
    },
    trendChipIcon: {
        width: 14,
        height: 14,
        position: "relative",
        top: 2,
    },
    positive: { color: theme.palette.success.main },
    negative: { color: theme.palette.error.main },
    neutral: { color: theme.palette.neutral.main },
    spanIndicator: {
        fontSize: 11,
        float: "right",
        color: "#737373",
        position: "relative",
        right: 3,
    },
    rateDiv: { position: "relative" },
});

/**
 * Cette cellule permet d'afficher un pourcentage
 * avec une icône d'évolution (optionnelle) et un indicateur du type de données représentées (optionnel)
 */
class CellRateClass extends React.PureComponent {
    static propTypes = {
        /**
         * Useful to extend the style applied to components.
         */
        className: PropTypes.string,
        /**
         * Useful to extend the style applied to components.
         */
        classes: PropTypes.object,
        /**
         * Spécifie le type de donnée évalué (ex: 'volume', 'amount', ...)
         */
        indicator: PropTypes.string,
        /**
         * True si une augmentation est considérée comme success, détermine la couleur de l'icône
         */
        isPositive: PropTypes.bool,
        /**
         * Locale BCP 47
         */
        locale: PropTypes.string,
        /**
         * Évolution par rapport à la période précédente, détermine l'icône associée
         */
        trendchip: PropTypes.oneOf(["up", "down", "equal"]).isRequired,
        /**
         * Rate
         */
        value: PropTypes.number.isRequired,
        /**
         * View (L/M/S)
         */
        view: PropTypes.oneOf(["l", "m", "s"]),
    };

    static defaultProps = { isPositive: true };

    render() {
        const {
            classes,
            value,
            trendchip,
            isPositive,
            indicator,
            locale,
            view,
            title = "",
            className,
        } = this.props;

        let trendIcon;
        const displayedRate = formatRate(value, view, locale);

        switch (trendchip) {
            case "equal":
                trendIcon = (
                    <Equal className={classNames(classes.trendChipIcon, classes.neutral)} />
                );
                break;
            case "up":
                trendIcon = (
                    <ArrowTopRight
                        className={classNames(classes.trendChipIcon, {
                            [classes.success]: isPositive,
                            [classes.error]: !isPositive,
                        })}
                    />
                );
                break;
            case "down":
                trendIcon = (
                    <ArrowBottomRight
                        className={classNames(classes.trendChipIcon, {
                            [classes.success]: !isPositive,
                            [classes.error]: isPositive,
                        })}
                    />
                );
                break;
            default:
                break;
        }

        const isTitle = title ? title : displayedRate;

        return (
            <div
                className={classNames(classes.flexContent, className)}
                title={view === "s" ? isTitle : title ? title : undefined}
            >
                <div className={(typeof indicator === "undefined" && classes.rateDiv) || ""}>
                    {displayedRate}
                    {trendIcon}
                </div>
                {typeof indicator !== "undefined" && (
                    <span className={classes.spanIndicator}>{indicator}</span>
                )}
            </div>
        );
    }
}

export const CellRate = withStyles(stylesCellRate)(CellRateClass);
