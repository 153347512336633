import payment_means_colors from "../constants/payment_means_colors";

const payment_means = {
    id: "payment_means",
    endpoint: "/payment_means",
    organizeFunc: (paymentMeans, p) => {
        return paymentMeans.map((paymentMean) => {
            return {
                ...paymentMean,
                label: p.t(`ref.transaction.payment_means.${paymentMean.id}`),
                img: `/assets/core/img/references/payment_means/${paymentMean.id}.svg`,
                fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
                ...(payment_means_colors[paymentMean.id]
                    ? payment_means_colors[paymentMean.id]
                    : {}),
            };
        });
    },
};

export default payment_means;
