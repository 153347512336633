import { useInheritContext } from "../ContextForwarder";
import { AbilityProvider } from "./ability";
import { AbilityContext } from "./ability-context";
import { createCanBoundTo } from "@casl/react";
import { withCan } from "./withCan";

const Can = (props) => {
    const ability = useInheritContext("ability", AbilityContext);
    const Wrapper = createCanBoundTo(ability);
    return <Wrapper {...props} />;
};

const useCan = () => {
    const abl = useInheritContext("ability", AbilityContext);
    return abl.can.bind(abl);
};

export {
    AbilityProvider, // Provider
    Can, // hoc
    useCan, // hook
    withCan,
};
