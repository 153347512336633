import { useCallback } from "react";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import { useP, usePhrases } from "../../../../services/i18n";
import { useModule } from "../../../../services/module";
import QueryParser from "../../../../services/qsearch/QueryParser";

export const QsearchFilter = (props) => {
    const { onDeleteFilter, qsearch } = props;

    const { id, attributes } = useModule();
    const p = useP();
    const [phrases] = usePhrases();

    const prefix = p.t("common.search.filters.qsearch") + p.t("colon");

    const decodedQuery = QueryParser.decodeQuery(qsearch, id, attributes, phrases);

    /**
     * Delete filter
     * @param id
     * @param type
     * @param value
     * @returns {Function}
     */
    const handleDelete = useCallback(() => {
        if (onDeleteFilter) {
            onDeleteFilter("qsearch", "qsearch");
        } else {
            console.warn("onDeleteFilter is undefined.");
        }
    }, [onDeleteFilter]);

    if (decodedQuery === "") {
        return null;
    }

    return (
        <HiChip
            key="qsearch"
            id="chip-qsearch"
            icon="search"
            label={decodedQuery}
            onDelete={onDeleteFilter ? handleDelete : undefined}
            prefix={prefix}
            style={{ "background-color": "#cceefa" }}
        />
    );
};
