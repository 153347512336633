import { useMemo } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import HiAvatar from "@hipay/hipay-material-ui/HiAvatar";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { generateProfileColor, getInitials, ucFirst } from "../../../../utils";

const useStylesCellAvatar = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        ...theme.typography.body3,
        overflow: "hidden",
    },
    badge: {
        width: 10,
        height: 10,
        top: 5,
        borderRadius: "50%",
        backgroundColor: theme.palette.success.main,
        display: "flex",
        position: "absolute",
        flexWrap: "wrap",
    },
    disableAccount: {
        backgroundColor: "rgba(255, 255, 255, 0.54)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    avatar: {
        width: 24,
        height: 24,
        marginRight: 8,
        fontSize: 13,
        fontWeight: 500,
    },
    disabledOverlay: {
        position: "absolute",
        left: 0,
        top: 0,
        width: 24,
        height: 24,
        opacity: 0.75,
        margin: 8,
        backgroundColor: "#FFF",
        "&>span": { margin: 4 },
        borderRadius: "50%",
    },
}));

export const useBadgeStylesCellAvatar = makeStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.success.main,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid #44b700",
            content: '""',
        },
        top: "30%",
        left: "12%",
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

/**
 * Cette cellule permet d'afficher un avatar (24px largeur)
 */
export const CellAvatar = (props) => {
    const { src, isDisabled, isOnline, isInternal, label, value, view, firstname, surname } = props;

    const classes = useStylesCellAvatar(props);
    const badgeClasses = useBadgeStylesCellAvatar();

    const _label = label || value;

    let initials;
    let formatLabel;
    if (_label && isNaN(_label)) {
        const subname = _label.split(" ");
        if (firstname && surname) {
            initials = getInitials(firstname, surname);
        } else {
            initials = subname[0].charAt(0).toUpperCase();
            if (subname.length > 1) {
                initials += subname[subname.length - 1].charAt(0).toUpperCase();
            }
        }
        // format label from view
        // L >> (_) John Doe
        if (view === "l") {
            formatLabel = subname.map(ucFirst).join(" ");
        } else if (view === "m") {
            // M >> (_) J. Doe
            formatLabel = subname[0].charAt(0).toUpperCase();
            if (subname.length > 1) {
                formatLabel += `. ${ucFirst(subname[subname.length - 1])}`;
            }
        } else {
            // S >> (_)
            formatLabel = null;
        }
    }

    const avatarColor = useMemo(
        () => (initials ? generateProfileColor(initials) : "#bdbdbd"),
        [initials]
    );

    let avatarComponent = null;

    // with img
    if (src) {
        avatarComponent = <Avatar alt={formatLabel} className={classes.avatar} src={src} />;
    } else if (initials) {
        // with initials
        avatarComponent = (
            <HiAvatar
                alt={formatLabel}
                className={classes.avatar}
                color={avatarColor}
                internal={isInternal}
                children={initials}
            />
        );
    }

    // add disabled filter
    if (isDisabled) {
        avatarComponent = (
            <>
                {avatarComponent}
                <div className={classes.disabledOverlay}>
                    <HiIcon icon={"visibility_off"} color={"neutral"} size={16} />
                </div>
            </>
        );
    } else if (isOnline) {
        // add online badge
        avatarComponent = (
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                variant="dot"
                classes={badgeClasses}
            >
                {avatarComponent}
            </Badge>
        );
    }

    return (
        <div className={classes.root} title={_label}>
            {avatarComponent}
            {formatLabel && <span className={classes.label}>{formatLabel}</span>}
        </div>
    );
};

CellAvatar.propTypes = {
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * Label displayed
     * (parsed to be used as initials)
     */
    label: PropTypes.string,
    /**
     * Value is displayed if label is undefined
     */
    value: PropTypes.string,
    /**
     * Source path of avatar image
     */
    src: PropTypes.string,
    /**
     * Compte est-il actif ?
     */
    isDisabled: PropTypes.bool,
    /**
     * Personne est-elle connectée ?
     */
    isOnline: PropTypes.bool,
    /**
     * Est un compte interne HiPay
     */
    isInternal: PropTypes.bool,
    /**
     * View (L/M/S)
     */
    view: PropTypes.oneOf(["l", "m", "s"]),
};

CellAvatar.defaultProps = {
    label: "",
    src: "",
    isDisabled: false,
    isOnline: false,
    isInternal: false,
    view: "l",
};
