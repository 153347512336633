import { connect } from "react-redux";
import { isFeatureEnabled } from "../../app/reducers/features";

function EnabledFeatureClass({ isEnabled, children }) {
    if (isEnabled) {
        return children;
    }

    return null;
}

export const EnabledFeature = connect((store, { name }) => ({
    isEnabled: isFeatureEnabled(store.app.features, name),
}))(EnabledFeatureClass);
