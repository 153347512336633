import moment from "moment-timezone";
import { formFieldTypes, displayTypes } from "@console/core";
import { capitalize, foldAccents } from "@hipay/hipay-material-ui/utils/helpers";
import { sentinelColorById } from "../references/static_datas/sentinel_results";

/**
 * Attributes (anciennement dataFieldType)
 *
 * Ce sont les propriétés de l'entité du module.
 * Ils permettent de générer:
 * - les champs du formulaire de recherche
 * - les colonnes du tableau de résultat
 * ... plus ou moins tout ce qui touche aux données du module.
 *
 */
const attributes = {
    account: {
        id: "account",
        formFieldType: formFieldTypes.FORM_FIELD_ACCOUNT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        types: ["transactions"],
    },
    account_id: {
        id: "account_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        qsearch: true,
        order: 13,
    },
    account_name: {
        id: "account_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ label, publicref }) => `${label} - ${publicref}`,
        },
        needMultipleAccounts: true,
        qsearch: true,
        order: 7,
    },
    account_number: {
        id: "account_number", // publicref
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        qsearch: true,
        order: 13,
    },
    acquirer: {
        id: "acquirer",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            restrictedBySelectedAccountList: true,
            numericId: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "acquirers",
        order: 31,
        internal: true,
    },
    acquirer_chargeback_date: {
        id: "acquirer_chargeback_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            dataType: "payment_operation",
            exclude: null,
            indicator: true,
        },
        qsearch: false,
        internal: true,
    },
    acquirer_connection_id: {
        id: "acquirer_connection_id",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT_REFERENCE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        order: 35,
        internal: true,
    },
    acquirer_return_code: {
        id: "acquirer_return_code",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        order: 34,
        internal: true,
    },
    acquirer_return_message: {
        id: "acquirer_return_message",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        internal: true,
    },
    acquirer_trxid: {
        id: "acquirer_trxid",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { title: ({ value }) => value },
        qsearch: true,
        identifier: true,
        order: 32,
    },
    amount: {
        id: "amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            amountStats: true,
            precision: 2,
        },
        qsearch: true,
        order: 19,
    },
    auth: {
        id: "auth",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            type: "image",
        },
        displayType: displayTypes.DISPLAY_ICON,
        displayOptions: {
            size: 24,
            title: ({ authMethod }) => authMethod,
            defaultValue: "not_authenticable",
            fixedCellWidth: false,
        },
        reference: "auth_status",
        qsearch: true,
        order: 72,
    },
    auth_method: {
        id: "auth_method",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "auth_methods",
        qsearch: true,
        order: 71,
    },
    authentication_indicator: {
        id: "authentication_indicator",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            sort: true,
            numericId: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }, p) => p.t(`ref.transaction.authentication_indicator.value_${value}`),
        },
        reference: "authentication_indicator",
        qsearch: true,
        order: 70,
    },
    author: {
        id: "author",
        displayType: displayTypes.DISPLAY_AVATAR,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            noData: "attributes.transaction.author.noData",
        },
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        reference: "authors",
    },
    authorization_code: {
        id: "authorization_code",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    authorized_by_payment_means_date: {
        id: "authorized_by_payment_means_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    balance: {
        id: "balance",
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            lookup: false,
            exclude: false,
            sortable: false,
            precision: 2,
        },
    },
    balance_capture: {
        id: "balance_capture",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {},
    },
    balance_collect: {
        id: "balance_collect",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {},
    },
    balance_refund: {
        id: "balance_refund",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {},
    },
    balance_debit: {
        id: "balance_debit",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {},
    },
    bank: {
        id: "bank",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    banked_date: {
        id: "banked_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            dataType: "payment_operation",
            indicator: false,
            exclude: null,
        },
    },
    bank_batchid: {
        id: "bank_batchid",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: false,
            exclude: null,
        },
    },
    basket_amount: {
        id: "basket_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            amountStats: true,
            precision: 2,
        },
    },
    basket_item_count: {
        id: "basket_item_count",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: false,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_NUMERIC,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
        },
        qsearch: true,
        order: 26,
    },
    basket_item_quantity: {
        id: "basket_item_quantity",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_NUMERIC,
        displayOptions: {},
    },
    bic: {
        id: "bic",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    billing_country: {
        id: "billing_country",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: {
            ellipsis: "middle",
            title: ({ value }) => value,
        },
        reference: "countries",
        rgpdCompliance: true,
        qsearch: true,
        order: 53,
    },
    billing_postcode: {
        id: "billing_postcode",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            filterable: false,
            title: ({ value }) => value,
        },
        rgpdCompliance: true,
        qsearch: true,
        order: 54,
    },
    billing_street: {
        id: "billing_street",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: false,
            filterable: false,
            title: ({ value }) => value,
        },
        reference: "billing_street",
        rgpdCompliance: true,
        qsearch: false,
    },
    blocked_date: {
        id: "blocked_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    business_id: {
        id: "business_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        qsearch: true,
    },
    business_name: {
        id: "business_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: {},
        displayOptions: {},
        qsearch: true,
    },
    cancel_author: {
        id: "cancel_author",
        displayType: displayTypes.DISPLAY_AVATAR,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: true,
            restrictedBySelectedAccountList: true,
            noData: "attributes.transaction.cancel_author.noData",
        },
        displayOptions: {},
        reference: "cancel_authors",
    },
    cancel_reason: {
        id: "cancel_reason",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: true,
            helperText: false,
            multiple: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "cancel_reasons",
    },
    captured_amount: {
        id: "captured_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            amountStats: true,
            precision: 2,
        },
    },
    captured_date: {
        id: "captured_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    card_category: {
        id: "card_category",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            noData: "attributes.transaction.card_category.noData",
            restrictedBySelectedAccountList: true,
            sort: true,
            maxSelectable: 10,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "card_categories",
        rgpdCompliance: true,
    },
    card_country: {
        id: "card_country",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: {},
        reference: "countries",
        rgpdCompliance: true,
        qsearch: true,
        order: 47,
    },
    card_id: {
        id: "card_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        rgpdCompliance: true,
        qsearch: true,
        order: 7,
    },
    card_token: {
        id: "card_token",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        rgpdCompliance: true,
        qsearch: true,
        order: 8,
    },
    card_type: {
        id: "card_type",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "card_types",
    },
    financial_institution: {
        id: "financial_institution",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            maxSelectable: 10,
            noData: "attributes.transaction.financial_institution.noData",
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { noticeType: displayTypes.DISPLAY_IMAGE },
        reference: "financial_institutions",
    },
    to_be_reviewed_date: {
        id: "to_be_reviewed_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    chargeback_amount: {
        id: "chargeback_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            align: "left",
            type: "numeric",
            amountStats: true,
            precision: 2,
        },
        qsearch: false,
    },
    chargeback_date: {
        id: "chargeback_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    chargeback_id: {
        id: "chargeback_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        qsearch: false,
        order: 93,
    },
    chargeback_reason: {
        id: "chargeback_reason",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            restrictedBySelectedAccountList: true,
            noData: "attributes.transaction.chargeback_reason.noData",
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        reference: "chargeback_reasons",
    },
    chargeback_refund_amount: {
        id: "chargeback_refund_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            amountStats: true,
            precision: 2,
        },
        qsearch: false,
    },
    chargeback_type: {
        id: "chargeback_type",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        reference: "chargeback_types",
    },
    cms_name: {
        id: "cms_name",
        formFieldType: formFieldTypes.FORM_FIELD_NESTED_SELECT,
        formFieldOptions: {
            hasAll: false,
            helperText: false,
            multiple: true,
            noData: "attributes.transaction.cms_name.noData",
            parentItemSelectable: true,
            sort: true,
            restrictedBySelectedAccountList: true,
            translations: {
                n_children: "attributes.transaction.cms_name.n_children",
                n_items_selected: "attributes.transaction.cms_name.n_children",
                one_child: "attributes.transaction.cms_name.one_child",
            },
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "cms",
    },
    collected_date: {
        id: "collected_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    created_date: {
        id: "created_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
        qsearch: true,
        order: 92,
    },
    custom_data: {
        id: "custom_data",
        formFieldType: formFieldTypes.FORM_FIELD_CUSTOM_DATA,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_CUSTOM_DATA,
        displayOptions: {
            sortable: false,
            dataType: "custom_data",
            indicator: true,
        },
        multiple: true,
        rgpdCompliance: true,
        // qsearch: true, - TODO
    },
    customer_account_number: {
        id: "customer_account_number",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value, country, expirationDate }, p) =>
                p.t("attributes.transaction.customer_account_number.tooltip", {
                    value: value,
                    expirationDate: moment(expirationDate).format("L"),
                    country: country,
                }),
            sortable: false,
        },
        qsearch: true,
        rgpdCompliance: true,
        order: 3,
    },
    customer_id: {
        id: "customer_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: true,
        order: 11,
        rgpdCompliance: true,
    },
    customer_birthdate: {
        id: "customer_birthdate",
        displayType: displayTypes.DISPLAY_DATE,
        rgpdCompliance: true,
        displayOptions: {
            lookup: false,
            displayTime: false,
        },
    },
    customer_city: {
        id: "customer_city",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { title: ({ value }) => value },
        rgpdCompliance: true,
        qsearch: true,
        order: 51,
    },
    customer_company_name: {
        id: "customer_company_name",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            maxSelectable: 10,
            noData: "attributes.transaction.financial_institution.noData",
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { ellipsis: "name" },
        reference: "customer_companies",
    },
    customer_country: {
        id: "customer_country",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "countries",
        rgpdCompliance: true,
        qsearch: true,
        order: 52,
    },
    customer_device: {
        id: "customer_device",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_ICON,
        displayOptions: {},
        reference: "devices",
        qsearch: true,
    },
    customer_email: {
        id: "customer_email",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        rgpdCompliance: true,
        qsearch: true,
        order: 1,
    },
    customer_language: {
        id: "customer_language",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            lookup: false,
            exclude: false,
        },
        // reference: 'locales',
    },
    customer_mandate_id: {
        id: "customer_mandate_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    customer_name: {
        id: "customer_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { ellipsis: "name" },
        rgpdCompliance: true,
        qsearch: true,
        order: 8,
    },
    customer_operating_system: {
        id: "customer_operating_system",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            restrictedBySelectedAccountList: true,
            noData: "attributes.transaction.customer_operating_system.noData",
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "customer_operating_systems",
    },
    customer_type: {
        id: "customer_type",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "customer_types",
    },
    cvc_result: {
        id: "cvc_result",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    debit_agreement_id: {
        id: "debit_agreement_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    delivery_company: {
        id: "delivery_company",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    delivery_method: {
        id: "delivery_method",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    delivery_number: {
        id: "delivery_number",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    delivery_time: {
        id: "delivery_time",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_NUMERIC,
        displayOptions: {},
    },
    discount: {
        id: "discount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: { precision: 2 },
    },
    eci: {
        id: "eci",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { numericId: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { title: ({ value }, p) => p.t(`ref.transaction.eci.value_${value}`) },
        reference: "eci",
        qsearch: true,
    },
    eci_out: {
        id: "eci_out",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { numericId: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { title: ({ value }, p) => p.t(`ref.transaction.eci_out.value_${value}`) },
        reference: "eci_out",
        qsearch: true,
    },
    device_id: {
        id: "device_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        rgpdCompliance: true,
    },
    device_fingerprint: {
        id: "device_fingerprint",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        rgpdCompliance: true,
    },
    dispute_author: {
        id: "dispute_author",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { hasEmptyOption: false },
        displayType: displayTypes.DISPLAY_AVATAR,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
            lookup: false,
        },
        internal: true,
        reference: "dispute_authors",
    },
    dispute_comment: {
        id: "dispute_comment",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: { helperText: false },
        displayOptions: {
            dataType: "payment_operation",
            exclude: false,
            lookup: false,
            indicator: true,
        },
    },
    dispute_date: {
        id: "dispute_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        displayType: displayTypes.DISPLAY_DATE,
        formFieldOptions: { helperText: false },
        displayOptions: {
            dataType: "payment_operation",
            exclude: null,
            indicator: true,
        },
    },
    dispute_reason: {
        id: "dispute_reason",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        formFieldOptions: { sort: false },
        reference: "dispute_reasons",
    },
    dsp2_status: {
        id: "dsp2_status",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "dsp2_statuses",
        qsearch: true,
        order: 68,
    },
    external_reference: {
        id: "external_reference",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }) => value,
            indicator: true,
        },
    },
    financial_operation_status: {
        id: "financial_operation_status",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
        reference: "financial_operation_status",
    },
    fraud_reported_by_acquirer: {
        id: "fraud_reported_by_acquirer",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { hasEmptyOption: false },
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: { fixedCellWidth: true },
        reference: "fraud_reported_by_acquirers",
    },
    fraud_reported_by_acquirer_date: {
        id: "fraud_reported_by_acquirer_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    funded_date: {
        id: "funded_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            dataType: "payment_operation",
            indicator: false,
            exclude: null,
        },
    },
    fund_batchid: {
        id: "fund_batchid",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: false,
            exclude: null,
        },
    },
    iban: {
        id: "iban",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: true },
    },
    iban_creditor: {
        id: "iban_creditor",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }) => value,
            indicator: true,
        },
    },
    iban_debitor: {
        id: "iban_debitor",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }) => value,
            indicator: true,
        },
        rgpdCompliance: true,
    },
    ip_address: {
        id: "ip_address",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        rgpdCompliance: true,
        qsearch: true,
        order: 54,
    },
    ip_country: {
        id: "ip_country",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { ellipsis: "middle" },
        reference: "countries",
        rgpdCompliance: true,
        qsearch: true,
        order: 55,
    },
    is_acquirer_tra_activated: {
        id: "is_acquirer_tra_activated",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { hasEmptyOption: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        internal: true,
        reference: "boolean_references",
    },
    issuer_financial_institution_country: {
        id: "issuer_financial_institution_country",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { ellipsis: "middle" },
        reference: "countries",
    },
    issuer_name: {
        id: "issuer_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { ellipsis: "name" },
        rgpdCompliance: true,
        qsearch: true,
        order: 200,
    },
    item_category: {
        id: "item_category",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "item_categories",
        qsearch: true,
        order: 36,
    },
    item_name: {
        id: "item_name",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { fallbackImage: "/assets/core/img/common/notice/basket-item-empty.svg" },
    },
    item_reference: {
        id: "item_reference",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    item_type: {
        id: "item_type",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            helperText: false,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "item_types",
        qsearch: true,
        order: 41,
    },
    merchant_order_id: {
        id: "merchant_order_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        qsearch: true,
        displayOptions: {
            ellipsis: "middle",
            title: ({ value }, p) =>
                p.t("attributes.transaction.merchant_order_id.tooltip", { value: value }),
        },
        order: 4,
    },
    mid: {
        id: "mid",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        needMultipleAccounts: true,
        qsearch: true,
        order: 30,
    },
    mix_payment: {
        id: "mix_payment",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { hasEmptyOption: false },
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: { fixedCellWidth: true },
        reference: "boolean_references",
    },
    one_click: {
        id: "one_click",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { hasEmptyOption: false },
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: { fixedCellWidth: true },
        reference: "one_click",
    },
    old_custom_data: {
        id: "old_custom_data",
        formFieldType: formFieldTypes.FORM_FIELD_CUSTOM_DATA,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_CUSTOM_DATA,
        displayOptions: {
            sortable: false,
            fixedCellWidth: true,
            dataType: "custom_data",
            indicator: true,
            exclude: true,
            lookup: true,
        },
        multiple: true,
        rgpdCompliance: true,
    },
    operation_amount: {
        id: "operation_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
            amountStats: true,
            precision: 2,
            lookup: false,
        },
    },
    operation_date: {
        id: "operation_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            dataType: "payment_operation",
            exclude: null,
            indicator: true,
        },
    },
    operation_id: {
        id: "operation_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        qsearch: true,
    },
    operation_merchant_id: {
        id: "operation_merchant_id",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        qsearch: false,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
            ellipsis: true,
        },
        order: 76,
    },
    operation_status: {
        id: "operation_status",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        reference: "operation_statuses",
        qsearch: true,
        order: 75,
    },
    operation_type: {
        id: "operation_type",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
            clickable: (can) => can("manage-module", "financial_report"),
        },
        reference: "operation_types",
        qsearch: true,
        order: 76,
    },
    order_amount: {
        id: "order_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            precision: 2,
        },
    },
    order_currency: {
        id: "order_currency",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            noData: "attributes.transaction.order_currency.noData",
            sort: true,
            restrictedBySelectedAccountList: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { fixedCellWidth: true },
        reference: "order_currency",
        order: 15,
    },
    order_date: {
        id: "order_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
        qsearch: true,
        order: 6,
    },
    payment_means: {
        id: "payment_means",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            type: "image",
            sort: true,
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { size: 32 },
        reference: "payment_means",
        qsearch: true,
        order: 14,
    },
    payment_brand_incentive: {
        id: "payment_brand_incentive",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    payment_method: {
        id: "payment_method",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            type: "icon",
            hideCheckbox: true,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_ICON,
        displayOptions: {},
        reference: "payment_methods",
        qsearch: true,
        order: 15,
    },
    payment_operation_mode: {
        id: "payment_operation_mode",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "operation_mode",
        qsearch: true,
    },
    payment_url: {
        id: "payment_url",
        displayType: displayTypes.DISPLAY_LINK,
        displayOptions: { lookup: false },
    },
    phone_number: {
        id: "phone_number",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { sortable: false },
        rgpdCompliance: true,
        qsearch: true,
        order: 17,
    },
    provider: {
        id: "provider",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            restrictedBySelectedAccountList: true,
            numericId: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "providers",
        order: 33,
        internal: true,
    },
    provider_mid: {
        id: "provider_mid",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        identifier: true,
        order: 38,
        internal: true,
    },
    provider_trxid: {
        id: "provider_trxid",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        identifier: true,
        order: 39,
        internal: true,
    },
    provider_return_code: {
        id: "provider_return_code",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        order: 40,
        internal: true,
    },
    provider_return_message: {
        id: "provider_return_message",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        internal: true,
    },
    // TODO - remove
    rank: {
        id: "rank",
        displayType: displayTypes.DISPLAY_TEXT,
    },
    recurring_payment: {
        id: "recurring_payment",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { hasEmptyOption: false },
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: { fixedCellWidth: true },
        reference: "recurring_payment",
    },
    refund_method: {
        id: "refund_method",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "refund_methods",
        qsearch: true,
        order: 18,
    },
    refunded_amount: {
        id: "refunded_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            ellipsis: "middle",
            amountStats: true,
            precision: 2,
        },
    },
    refunded_date: {
        id: "refunded_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: { exclude: null },
    },
    refund_reasons: {
        id: "refund_reasons",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldOptions: { sort: false },
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
        },
        reference: "refund_reasons",
    },
    refund_deadline: {
        id: "refund_deadline",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    refusal_reasons: {
        id: "refusal_reasons",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            restrictedBySelectedAccountList: true,
            sort: true,
            multiple: true,
            noData: "attributes.transaction.refusal_reasons.noData",
            //tooltip: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            ellipsis: true,
            //tooltip:true,
        },
        reference: "refusal_reasons",
    },
    refused_amount: {
        id: "refused_amount",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: {
            helperText: true,
            onlyNumbers: true,
        },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: {
            type: "numeric",
            precision: 2,
        },
    },
    remittance_reference: {
        id: "remittance_reference",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
            formatable: true,
            format: ({ infos }) => {
                return infos || {};
            },
            title: ({ value, currency, infos }) => {
                let formatLabel = (str) => capitalize(str.replace(/_/g, " ")); // "field_type" >> "Field type"
                let formatDate = (str) =>
                    [str.slice(0, 4), str.slice(4, 6), str.slice(6)].filter(Boolean).join("/"); // "20191231" >> "2019/12/31"
                let formatAmount = (str, _currency) =>
                    str.toLocaleString(undefined, {
                        currency: _currency,
                        currencyDisplay: "symbol",
                        style: "currency",
                    }); // "12.5" >> "12,50 €"
                if (infos) {
                    return Object.keys(infos)
                        .map((k) => {
                            if (k === "fields") {
                                return infos["fields"]
                                    .filter(
                                        ({ name: _name, value: _value }) =>
                                            _name !== null && _value !== null
                                    )
                                    .map(({ name: _name, value: _value }) => {
                                        let field = `${formatLabel(_name)} : `;
                                        if (/date/i.test(_name)) {
                                            field += formatDate(_value);
                                        } else if (/amount/i.test(_name)) {
                                            field += formatAmount(_value, currency);
                                        } else {
                                            field += _value;
                                        }
                                        // specific separation for ONEY_CRP partial remittance
                                        if (/Payment_amount/.test(_name)) {
                                            field = "-\n" + field;
                                        }
                                        return field;
                                    })
                                    .join("\n");
                            }
                            return `${formatLabel(k)} : ${infos[k]}`;
                        })
                        .join("\n");
                }
                return null;
            },
        },
        qsearch: true,
    },
    remitted_date: {
        id: "remitted_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            dataType: "payment_operation",
            indicator: false,
            exclude: null,
        },
    },
    rem_batchid: {
        id: "rem_batchid",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            dataType: "payment_operation",
            indicator: false,
            exclude: null,
        },
    },
    retraction_delay: {
        id: "retraction_delay",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            label: (_value, p) => {
                // <1 day
                if (parseInt(_value) === 0) {
                    return p.t("attributes.transaction.retraction_delay.0_day");
                } else if (parseInt(_value) === 1) {
                    return p.t("attributes.transaction.retraction_delay.1_day");
                }
                // 2 days || NOT 3 days
                return p.t("attributes.transaction.retraction_delay.n_days", { value: _value });
            },
        },
    },
    route_reference: {
        id: "route_reference",
        formFieldType: formFieldTypes.FORM_FIELD_TEXT_REFERENCE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        qsearch: false,
        order: 36,
        internal: true,
    },
    same_day_refund: {
        id: "same_day_refund",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: true,
        },
        displayType: displayTypes.DISPLAY_BOOLEAN,
        displayOptions: { fixedCellWidth: true },
        reference: "same_day_refund",
    },
    sca_preference: {
        id: "sca_preference",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { sort: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }, p) =>
                p.t(`modules.transaction.notice.sca_preferences_tooltips.value_${value}`),
        },
        reference: "sca_preferences",
        qsearch: true,
    },
    sca_source: {
        id: "sca_source",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: { sort: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }, p) =>
                p.t(`modules.transaction.notice.sca_sources_tooltips.${value}`),
        },
        reference: "sca_sources",
        qsearch: true,
    },
    sdd_authentication_indicator: {
        id: "sdd_authentication_indicator",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { sort: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
        reference: "sdd_authentication_indicator",
        qsearch: false,
    },
    sdd_display_page: {
        id: "sdd_display_page",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { sort: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "sdd_display_page",
        qsearch: false,
    },
    sentinel_profile: {
        id: "sentinel_profile",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hideCheckbox: true,
            hasAll: false,
            helperText: false,
            multiple: false,
            sort: false,
            restrictedBySelectedAccountList: true,
            lazy: true,
            // fields linked to it, as options are relative to selected value
            // fields will be add only if a value is selected
            // fields will be remove with parent
            relativeFormFields: ["sentinel_rule"],
            noData: "attributes.transaction.sentinel_profile.noData",
            filterFunc: (item, search) => {
                if (search === "") {
                    return true;
                }

                if (
                    foldAccents(item.label.toString().toLowerCase()).indexOf(
                        foldAccents(search.toLowerCase())
                    ) !== -1
                ) {
                    return true;
                }

                if ("secondaryLabel" in item) {
                    if (
                        foldAccents(item.secondaryLabel.toString().toLowerCase()).indexOf(
                            foldAccents(search.toLowerCase())
                        ) !== -1
                    ) {
                        return true;
                    }
                }

                if ("info" in item) {
                    if (
                        foldAccents(item.info.toString().toLowerCase()).indexOf(
                            foldAccents(search.toLowerCase())
                        ) !== -1
                    ) {
                        return true;
                    }
                }

                return false;
            },
        },
        displayType: displayTypes.DISPLAY_SENTINEL,
        displayOptions: { fixedCellWidth: true },
        reference: "sentinel_profiles",
    },
    sentinel_score: {
        id: "sentinel_score",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_SCORE,
        displayOptions: {
            fixedCellWidth: true,
            color: ({ result }) => sentinelColorById[result] || "neutral",
        },
        qsearch: true,
        order: 24,
    },
    sentinel_result: {
        id: "sentinel_result",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            helperText: false,
            multiple: true,
        },
        displayType: displayTypes.DISPLAY_SENTINEL,
        displayOptions: {},
        reference: "sentinel_results",
        qsearch: true,
        order: 90,
    },
    sentinel_review_result: {
        id: "sentinel_review_result",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: {
            helperText: false,
            multiple: true,
            searchable: false,
        },
        displayType: displayTypes.DISPLAY_SENTINEL,
        displayOptions: {},
        reference: "sentinel_review_results",
        qsearch: true,
        order: 85,
    },
    sentinel_reviewer: {
        id: "sentinel_reviewer",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_AVATAR,
        displayOptions: { ellipsis: "name" },
        qsearch: true,
        order: 97,
    },
    sentinel_rule: {
        id: "sentinel_rule",
        formFieldType: formFieldTypes.FORM_FIELD_NESTED_SELECT,
        formFieldOptions: {
            hasEmptyOption: false,
            hasAll: false,
            helperText: false,
            multiple: true,
            noData: "attributes.transaction.sentinel_rule.noData",
            filterFunc: function (item, search) {
                if (search === "") {
                    return true;
                }

                if (
                    foldAccents(item.label.toString().toLowerCase()).indexOf(
                        foldAccents(search.toLowerCase())
                    ) !== -1
                ) {
                    return true;
                }

                if (item.secondaryLabel) {
                    if (
                        foldAccents(item.secondaryLabel.toString().toLowerCase()).indexOf(
                            foldAccents(search.toLowerCase())
                        ) !== -1
                    ) {
                        return true;
                    }
                }

                if (item.info) {
                    if (
                        foldAccents(item.info.toString().toLowerCase()).indexOf(
                            foldAccents(search.toLowerCase())
                        ) !== -1
                    ) {
                        return true;
                    }
                }

                return false;
            },
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { fixedCellWidth: true },
        reference: "sentinel_rules",
        // get relative field value in params to get options list
        // sentinel_profile define sentinel_rules
        relativeField: "sentinel_profile",
    },
    sentinel_rule_family: {
        id: "sentinel_rule_family",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            checkbox: true,
            helperText: false,
            multiple: true,
            sort: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { fixedCellWidth: true },
        reference: "sentinel_rules_family",
        qsearch: true,
        order: 88,
    },
    session_id: {
        id: "session_id",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    settled_date: {
        id: "settled_date",
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_DATE,
        displayOptions: {
            dataType: "payment_operation",
            indicator: true,
            exclude: null,
        },
    },
    shipping_country: {
        id: "shipping_country",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            helperText: false,
            type: "image",
            iconAll: "compass_outline",
            sort: true,
            fallbackImage: "/assets/core/img/references/countries/no-country.svg",
        },
        displayType: displayTypes.DISPLAY_IMAGE,
        displayOptions: { ellipsis: "middle" },
        reference: "countries",
        rgpdCompliance: true,
        qsearch: true,
        order: 55,
    },
    shipping_city: {
        id: "shipping_city",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: false },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { title: ({ value }) => value },
        rgpdCompliance: true,
        qsearch: true,
        order: 56,
    },
    shipping_postcode: {
        id: "shipping_postcode",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: true,
            filterable: false,
            title: ({ value }) => value,
        },
        rgpdCompliance: true,
        qsearch: true,
        order: 56,
    },
    shipping_street: {
        id: "shipping_street",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            sortable: false,
            filterable: false,
            title: ({ value }) => value,
        },
        rgpdCompliance: true,
        qsearch: false,
    },
    status: {
        id: "status",
        formFieldType: formFieldTypes.FORM_FIELD_STATUS,
        formFieldOptions: {
            hasEmptyOption: false, // Only for reference field => remove empty option from column filter
            helperText: true,
            multiFields: {
                // associatedFields
                period: {
                    // name used by FORM_FIELD_TYPE
                    id: "status_period", // 'datafield'
                    value: "current", // default value
                    // ... props could be add to customized form field
                },
            },
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "transaction_statuses",
        qsearch: true,
        order: 2,
    },
    store_address: {
        id: "store_address",
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    store_name: {
        id: "store_name",
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {
            noData: "attributes.transaction.store_name.noData",
            sort: true,
            restrictedBySelectedAccountList: true,
            numericId: true,
        },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { sortable: false },
        reference: "stores",
    },
    tax_rate: {
        id: "tax_rate",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_NUMERIC,
        displayOptions: {},
    },
    three_ds_v2_attempt: {
        id: "three_ds_v2_attempt",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        reference: "boolean_references",
    },
    three_dsv2_integration: {
        id: "three_dsv2_integration",
        formFieldType: formFieldTypes.FORM_FIELD_CHECKBOX,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {
            title: ({ value }, p) =>
                p.t(`modules.transaction.notice.three_dsv2_integration_tooltips.${value}`),
        },
        reference: "three_dsv2_integration",
    },
    tpe_manufacturer: {
        id: "tpe_manufacturer",
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    tpe_model: {
        id: "tpe_model",
        formFieldOptions: {},
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    tpe_reference: {
        id: "tpe_reference",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
    },
    trxid: {
        id: "trxid",
        formFieldType: formFieldTypes.FORM_FIELD_SUGGEST,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: {},
        identifier: true,
        qsearch: true,
        order: 5,
    },
    unit_price: {
        id: "unit_price",
        formFieldType: formFieldTypes.FORM_FIELD_NUMERIC,
        formFieldOptions: { helperText: true },
        displayType: displayTypes.DISPLAY_AMOUNT,
        displayOptions: { precision: 2 },
    },
    user_agent: {
        id: "user_agent",
        displayType: displayTypes.DISPLAY_TEXT,
        displayOptions: { lookup: false },
    },
    website: {
        id: "website",
        displayType: displayTypes.DISPLAY_LINK,
        displayOptions: { lookup: false },
    },
};

export default attributes;
