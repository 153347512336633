export default {
    id: "payment_means",
    data: [
        // { id: "1eurocom", label: "ref.financial_report.payment_means.1eurocom", img: "/assets/core/img/references/payment_means/1eurocom.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "3xcb",
            label: "ref.financial_report.payment_means.3xcb",
            img: "/assets/core/img/references/payment_means/3xcb.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#CCD900",
            colorSecondary: "#7BC200",
        },
        {
            id: "3xcb-no-fees",
            label: "ref.financial_report.payment_means.3xcb-no-fees",
            img: "/assets/core/img/references/payment_means/3xcb-no-fees.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#CCD900",
            colorSecondary: "#7BC200",
        },
        {
            id: "4xcb",
            label: "ref.financial_report.payment_means.4xcb",
            img: "/assets/core/img/references/payment_means/4xcb.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#CCD900",
            colorSecondary: "#7BC200",
        },
        {
            id: "4xcb-no-fees",
            label: "ref.financial_report.payment_means.4xcb-no-fees",
            img: "/assets/core/img/references/payment_means/4xcb-no-fees.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#CCD900",
            colorSecondary: "#7BC200",
        },
        // { id: "afterpay", label: "ref.financial_report.payment_means.afterpay", img: "/assets/core/img/references/payment_means/afterpay.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "american-express",
            label: "ref.financial_report.payment_means.american-express",
            img: "/assets/core/img/references/payment_means/american-express.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#009DDC",
        },
        // { id: "american-express-direct", label: "ref.financial_report.payment_means.american-express-direct", img: "/assets/core/img/references/payment_means/american-express.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#009DDC' },
        // { id: "applepay", label: "ref.financial_report.payment_means.applepay", img: "/assets/core/img/references/payment_means/apple-pay.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#000000' },
        {
            id: "aura",
            label: "ref.financial_report.payment_means.aura",
            img: "/assets/core/img/references/payment_means/aura.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#007BC3",
            colorSecondary: "#FFDD53",
        },
        // { id: "aurore", label: "ref.financial_report.payment_means.aurore", img: "/assets/core/img/references/payment_means/aurore.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "bacs", label: "ref.financial_report.payment_means.bacs", img: "/assets/core/img/references/payment_means/bacs.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#231F20' },
        // { id: "baloto", label: "ref.financial_report.payment_means.baloto", img: "/assets/core/img/references/payment_means/baloto.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "banamex",
            label: "ref.financial_report.payment_means.banamex",
            img: "/assets/core/img/references/payment_means/banamex.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#02BDF2",
            colorSecondary: "#012D72",
        },
        {
            id: "banco-do-brasil",
            label: "ref.financial_report.payment_means.banco-do-brasil",
            img: "/assets/core/img/references/payment_means/banco-do-brasil.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#F8D117",
        },
        // { id: "bancontact-mobile", label: "ref.financial_report.payment_means.bancontact-mobile", img: "/assets/core/img/references/payment_means/bancontact-mobile.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#005498' },
        {
            id: "bancontact",
            label: "ref.financial_report.payment_means.bancontact",
            img: "/assets/core/img/references/payment_means/bancontact.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#005498",
        },
        {
            id: "bancontactqrcode",
            label: "ref.financial_report.payment_means.bancontactqrcode",
            img: "/assets/core/img/references/payment_means/bancontactqrcode.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#005498",
        },
        // { id: "bank-transfer", label: "ref.financial_report.payment_means.bank-transfer", img: "/assets/core/img/references/payment_means/bank-transfer.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "bbva-bancomer",
            label: "ref.financial_report.payment_means.bbva-bancomer",
            img: "/assets/core/img/references/payment_means/bbva-bancomer.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#004B93",
        },
        {
            id: "bcmc",
            label: "ref.financial_report.payment_means.bcmc",
            img: "/assets/core/img/references/payment_means/bcmc.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#005498",
        },
        {
            id: "bcmc-mobile",
            label: "ref.financial_report.payment_means.bcmc-mobile",
            img: "/assets/core/img/references/payment_means/bcmc-mobile.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#005498",
        },
        // { id: "bcp", label: "ref.financial_report.payment_means.bcp", img: "/assets/core/img/references/payment_means/bcp.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "belfius-directnet",
            label: "ref.financial_report.payment_means.belfius-directnet",
            img: "/assets/core/img/references/payment_means/belfius-directnet.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "D1104A",
        },
        {
            id: "bnpp-3xcb",
            label: "ref.financial_report.payment_means.bnpp-3xcb",
            img: "/assets/core/img/references/payment_means/bnpp-3xcb.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#28B968",
            colorSecondary: "#1A7A4A",
        },
        {
            id: "bnpp-4xcb",
            label: "ref.financial_report.payment_means.bnpp-4xcb",
            img: "/assets/core/img/references/payment_means/bnpp-4xcb.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#28B968",
            colorSecondary: "#1A7A4A",
        },
        {
            id: "boleto-bancario",
            label: "ref.financial_report.payment_means.boleto-bancario",
            img: "/assets/core/img/references/payment_means/boleto-bancario.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#221819",
        },
        {
            id: "bradesco",
            label: "ref.financial_report.payment_means.bradesco",
            img: "/assets/core/img/references/payment_means/bradesco.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#D92031",
            colorSecondary: "#A3021E",
        },
        {
            id: "caixa",
            label: "ref.financial_report.payment_means.caixa",
            img: "/assets/core/img/references/payment_means/caixa.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#029BDC",
            colorSecondary: "#0273AF",
        },
        // { id: "carte-accord", label: "ref.financial_report.payment_means.carte-accord", img: "/assets/core/img/references/payment_means/carte-accord.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "carte-cadeau",
            label: "ref.financial_report.payment_means.carte-cadeau",
            img: "/assets/core/img/references/payment_means/carte-cadeau.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#8BBC06",
        },
        {
            id: "carte-titre-restaurant",
            label: "ref.financial_report.payment_means.carte-titre-restaurant",
            img: "/assets/core/img/references/payment_means/carte-titre-restaurant.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#242563",
        },
        // { id: "cashu", label: "ref.financial_report.payment_means.cashu", img: "/assets/core/img/references/payment_means/cashu.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "cb",
            label: "ref.financial_report.payment_means.cb",
            img: "/assets/core/img/references/payment_means/cb.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#19984F",
            colorSecondary: "#012C61",
        },
        {
            id: "cbc-online",
            label: "ref.financial_report.payment_means.cbc-online",
            img: "/assets/core/img/references/payment_means/cbc-online.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#00AFF5",
            colorSecondary: "#00366C",
        },
        // { id: "citibank colombia s.a.", label: "ref.financial_report.payment_means.citibank colombia s.a.", img: "/assets/core/img/references/payment_means/citibank colombia s.a..svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "cofinoga",
            label: "ref.financial_report.payment_means.cofinoga",
            img: "/assets/core/img/references/payment_means/cofinoga.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "",
        },
        {
            id: "credit-long",
            label: "ref.financial_report.payment_means.credit-long",
            img: "/assets/core/img/references/payment_means/credit-long.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#7BC200",
            colorSecondary: "#CCD900",
        },
        {
            id: "cup",
            label: "ref.financial_report.payment_means.cup",
            img: "/assets/core/img/references/payment_means/cup.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#414344",
        },
        // { id: "dankort", label: "ref.financial_report.payment_means.dankort", img: "/assets/core/img/references/payment_means/dankort.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#9C9EA1' },
        {
            id: "dcb-ch-easypay",
            label: "ref.financial_report.payment_means.dcb-ch-easypay",
            img: "/assets/core/img/references/payment_means/dcb-ch-easypay.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#9C9EA1",
        },
        // { id: "dcb-es-pagosmovistar", label: "ref.financial_report.payment_means.dcb-es-pagosmovistar", img: "/assets/core/img/references/payment_means/dcb.png", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#00ABE1' },
        // { id: "dcb-es-pagosmovistar-advance", label: "ref.financial_report.payment_means.dcb-es-pagosmovistar-advance", img: "/assets/core/img/references/payment_means/dcb.png", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#00ABE1' },
        {
            id: "dexia-directnet",
            label: "ref.financial_report.payment_means.dexia-directnet",
            img: "/assets/core/img/references/payment_means/dexia-directnet.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#D1104A",
        },
        // { id: "diners", label: "ref.financial_report.payment_means.diners", img: "/assets/core/img/references/payment_means/diners.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#0079BE' },
        {
            id: "discover",
            label: "ref.financial_report.payment_means.discover",
            img: "/assets/core/img/references/payment_means/discover.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "",
        },
        // { id: "divido", label: "ref.financial_report.payment_means.divido", img: "/assets/core/img/references/payment_means/divido.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#0E7AD3' },
        // { id: "ecarte-bleue", label: "ref.financial_report.payment_means.ecarte-bleue", img: "/assets/core/img/references/payment_means/ecarte-bleue.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "edankort", label: "ref.financial_report.payment_means.edankort", img: "/assets/core/img/references/payment_means/edankort.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "elo-card", label: "ref.financial_report.payment_means.elo-card", img: "/assets/core/img/references/payment_means/elo-card.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "fivory", label: "ref.financial_report.payment_means.fivory", img: "/assets/core/img/references/payment_means/fivory.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "giropay",
            label: "ref.financial_report.payment_means.giropay",
            img: "/assets/core/img/references/payment_means/giropay.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#000268",
        },
        // { id: "hipercard", label: "ref.financial_report.payment_means.hipercard", img: "/assets/core/img/references/payment_means/hipercard.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "iban", label: "ref.financial_report.payment_means.iban", img: "/assets/core/img/references/payment_means/iban.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "ideal",
            label: "ref.financial_report.payment_means.ideal",
            img: "/assets/core/img/references/payment_means/ideal.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#FD6EB6",
            colorSecondary: "#CC0066",
        },
        {
            id: "illicado",
            label: "ref.financial_report.payment_means.illicado",
            img: "/assets/core/img/references/payment_means/illicado.png",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "",
        },
        {
            id: "ing-homepay",
            label: "ref.financial_report.payment_means.ing-homepay",
            img: "/assets/core/img/references/payment_means/ing-homepay.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#FF6200",
        },
        // { id: "ingenico", label: "ref.financial_report.payment_means.ingenico", img: "/assets/core/img/references/payment_means/ingenico.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "internet-plus-box", label: "ref.financial_report.payment_means.internet-plus-box", img: "/assets/core/img/references/payment_means/internet-plus-box.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "itau",
            label: "ref.financial_report.payment_means.itau",
            img: "/assets/core/img/references/payment_means/itau.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#312782",
        },
        // { id: "jcb", label: "ref.financial_report.payment_means.jcb", img: "/assets/core/img/references/payment_means/jcb.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#181830', colorSecondary: '#3778B6' },
        {
            id: "kbc-online",
            label: "ref.financial_report.payment_means.kbc-online",
            img: "/assets/core/img/references/payment_means/kbc-online.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#00AFF5",
            colorSecondary: "#00366C",
        },
        {
            id: "klarnacheckout",
            label: "ref.financial_report.payment_means.klarnacheckout",
            img: "/assets/core/img/references/payment_means/klarnacheckout.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#F2A2AE",
            colorSecondary: "#EB6F93",
        },
        {
            id: "klarnainvoice",
            label: "ref.financial_report.payment_means.klarnainvoice",
            img: "/assets/core/img/references/payment_means/klarnainvoice.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#F2A2AE",
            colorSecondary: "#EB6F93",
        },
        {
            id: "maestro",
            label: "ref.financial_report.payment_means.maestro",
            img: "/assets/core/img/references/payment_means/maestro.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#0099DF",
            colorSecondary: "#D32011",
        },
        {
            id: "mastercard",
            label: "ref.financial_report.payment_means.mastercard",
            img: "/assets/core/img/references/payment_means/mastercard.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#F79E1B",
            colorSecondary: "#EB001B",
        },
        {
            id: "mbway",
            label: "ref.transaction.payment_means.mbway",
            img: "/assets/core/img/references/payment_means/mbway.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#DA0000",
            colorSecondary: "#C50000",
        },
        // { id: "mercadopago", label: "ref.financial_report.payment_means.mercadopago", img: "/assets/core/img/references/payment_means/mercadopago.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "moneybookers", label: "ref.financial_report.payment_means.moneybookers", img: "/assets/core/img/references/payment_means/moneybookers.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "multi-payment",
            label: "ref.financial_report.payment_means.multi-payment",
            img: "/assets/core/img/references/payment_means/multi-payment.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "",
        },
        {
            id: "multibanco",
            label: "ref.financial_report.payment_means.multibanco",
            img: "/assets/core/img/references/payment_means/multibanco.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#3B75C4",
            colorSecondary: "#295A9B",
        },
        {
            id: "mybank",
            label: "ref.financial_report.payment_means.mybank",
            img: "/assets/core/img/references/payment_means/mybank.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#1DC2F1",
            colorSecondary: "#1A4C68",
        },
        {
            id: "oxxo",
            label: "ref.financial_report.payment_means.oxxo",
            img: "/assets/core/img/references/payment_means/oxxo.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#DA3832",
        },
        {
            id: "paypal",
            label: "ref.financial_report.payment_means.paypal",
            img: "/assets/core/img/references/payment_means/paypal.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#0288CD",
            colorSecondary: "#02368C",
        },
        {
            id: "paysafecard",
            label: "ref.financial_report.payment_means.paysafecard",
            img: "/assets/core/img/references/payment_means/paysafecard.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#028ACA",
            colorSecondary: "#37A4DC",
        },
        {
            id: "payshop",
            label: "ref.financial_report.payment_means.payshop",
            img: "/assets/core/img/references/payment_means/payshop.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#DF0323",
        },
        {
            id: "payulatam",
            label: "ref.financial_report.payment_means.payulatam",
            img: "/assets/core/img/references/payment_means/payulatam.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#94BE3E",
        },
        // { id: "payu-latam", label: "ref.financial_report.payment_means.payu-latam", img: "/assets/core/img/references/payment_means/payu-latam.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#94BE3E' },
        // { id: "payulatamapi", label: "ref.financial_report.payment_means.payulatamapi", img: "/assets/core/img/references/payment_means/payulatamapi.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#94BE3E' },
        // { id: "postepay", label: "ref.financial_report.payment_means.postepay", img: "/assets/core/img/references/payment_means/postepay.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "postfinance-card",
            label: "ref.financial_report.payment_means.postfinance-card",
            img: "/assets/core/img/references/payment_means/postfinance-card.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#FCD205",
        },
        {
            id: "postfinance-efinance",
            label: "ref.financial_report.payment_means.postfinance-efinance",
            img: "/assets/core/img/references/payment_means/postfinance-efinance.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#FCD205",
        },
        {
            id: "przelewy24",
            label: "ref.financial_report.payment_means.przelewy24",
            img: "/assets/core/img/references/payment_means/przelewy24.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#E13034",
        },
        {
            id: "qiwi-wallet",
            label: "ref.financial_report.payment_means.qiwi-wallet",
            img: "/assets/core/img/references/payment_means/qiwi-wallet.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#FFA303",
        },
        // { id: "rapipago", label: "ref.financial_report.payment_means.rapipago", img: "/assets/core/img/references/payment_means/rapipago.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        // { id: "safetypay", label: "ref.financial_report.payment_means.safetypay", img: "/assets/core/img/references/payment_means/safetypay.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#0298D1' },
        {
            id: "santander-cash",
            label: "ref.financial_report.payment_means.santander-cash",
            img: "/assets/core/img/references/payment_means/santander-cash.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#EC0400",
        },
        {
            id: "santander-home-banking",
            label: "ref.financial_report.payment_means.santander-home-banking",
            img: "/assets/core/img/references/payment_means/santander-home-banking.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#EC0400",
        },
        {
            id: "sct",
            label: "ref.financial_report.payment_means.sct",
            img: "/assets/core/img/references/payment_means/sct.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#0A4A8D",
        },
        {
            id: "sdd",
            label: "ref.financial_report.payment_means.sdd",
            img: "/assets/core/img/references/payment_means/sdd.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#0A4A8D",
        },
        {
            id: "sepa-direct-debit",
            label: "ref.financial_report.payment_means.sepa-direct-debit",
            img: "/assets/core/img/references/payment_means/sdd.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#0A4A8D",
        },
        // { id: "sepa-refund-transfer", label: "ref.financial_report.payment_means.sepa-refund-transfer", img: "/assets/core/img/references/payment_means/sepa-refund-transfer.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#0A4A8D' },
        // { id: "sipsqrcode", label: "ref.financial_report.payment_means.sipsqrcode", img: "/assets/core/img/references/payment_means/sipsqrcode.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "sisal",
            label: "ref.financial_report.payment_means.sisal",
            img: "/assets/core/img/references/payment_means/sisal.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#B7D232",
            colorSecondary: "#00643B",
        },
        // { id: "skrill", label: "ref.financial_report.payment_means.skrill", img: "/assets/core/img/references/payment_means/skrill.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#862164' },
        // { id: "sofort-lastschrift", label: "ref.financial_report.payment_means.sofort-lastschrift", img: "/assets/core/img/references/payment_means/sofort-lastschrift.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '#F2A2AE', colorSecondary: '#EB6F93' },
        {
            id: "sofort-uberweisung",
            label: "ref.financial_report.payment_means.sofort-uberweisung",
            img: "/assets/core/img/references/payment_means/sofort-uberweisung.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#F2A2AE",
            colorSecondary: "#EB6F93",
        },
        // { id: "test-bank-astropay", label: "ref.financial_report.payment_means.test-bank-astropay", img: "/assets/core/img/references/payment_means/test-bank-astropay.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "tpe",
            label: "ref.financial_report.payment_means.tpe",
            img: "/assets/core/img/references/payment_means/tpe.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "",
        },
        // { id: "ukash", label: "ref.financial_report.payment_means.ukash", img: "/assets/core/img/references/payment_means/ukash.svg", fallbackImage: '/assets/core/img/references/payment_means/no-logo.svg', colorPrimary: '' },
        {
            id: "visa",
            label: "ref.financial_report.payment_means.visa",
            img: "/assets/core/img/references/payment_means/visa.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#EC982D",
            colorSecondary: "#263379",
        },
        {
            id: "webmoney-transfer",
            label: "ref.financial_report.payment_means.webmoney-transfer",
            img: "/assets/core/img/references/payment_means/webmoney-transfer.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#0067A3",
        },
        {
            id: "wechatpay",
            label: "ref.financial_report.payment_means.wechatpay",
            img: "/assets/core/img/references/payment_means/wechatpay.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#00CF00",
        },
        {
            id: "yandex",
            label: "ref.financial_report.payment_means.yandex",
            img: "/assets/core/img/references/payment_means/yandex.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#E52620",
        },
        {
            id: "alma-3x",
            label: "ref.financial_report.payment_means.alma-3x",
            img: "/assets/core/img/references/payment_means/alma-3x.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#E52620",
        },
        {
            id: "alma-4x",
            label: "ref.financial_report.payment_means.alma-4x",
            img: "/assets/core/img/references/payment_means/alma-4x.svg",
            fallbackImage: "/assets/core/img/references/payment_means/no-logo.svg",
            colorPrimary: "#E52620",
        },
    ],
    translate: true,
};
