import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Cell } from "./Cell";
import { HEADER_CELL_HEIGHT, HEADER_CELL_HEIGHT_DENSE } from "../../../../constants/grid";
import { isMobile } from "react-device-detect";
import styled from "styled-components/macro";

/**
 * Header
 * Handle header row creation and update from orderedColumns & columns
 */
class HeaderCell extends React.Component {
    static propTypes = {
        /**
         * Nom du module
         */
        module: PropTypes.string.isRequired,
        /**
         * Colonnes du tableau (label, largeur, données, visibles/cachées, l/m/s )
         */
        columnList: PropTypes.array.isRequired,
        /**
         * Filtres des colonnes des valeurs de référence (status, pays, moyen de paiement, ...)
         */
        columnParams: PropTypes.object.isRequired,
        /**
         * Dense header height
         */
        dense: PropTypes.bool,
        /**
         * Fonction asynchrone permettant de récupérer les stats d'une colonne numerique de type montant
         */
        fetchAmountStats: PropTypes.func,
        /**
         * Fonction de màj de la configuration des colonnes (sans sauvegarde en BDD).
         */
        handleColumnsOrderUpdate: PropTypes.func,
        /**
         * Identifiant de la ou des colonnes sur la/lesquelles il y a une 'lookup' search
         */
        lookupColumnIdList: PropTypes.array,
        /**
         * Fonction pour scroller en haut des résultats
         */
        onClickBackToTop: PropTypes.func,
        /**
         * Fonction de callback sur le filtre d'une colonne
         * Passe l'id de la colonne et la liste des id des éléments inclus
         */
        onFilterChange: PropTypes.func,
        /**
         * Fonction de callback sur le tri d'une colonne
         * Passe l'id de la colonne et la direction du tri (ou false si tri par défaut)
         */
        onSort: PropTypes.func,
        /**
         * Id de la colonne trié
         */
        sortColumnId: PropTypes.string,
        /**
         * Sens du tri
         */
        sortDirection: PropTypes.oneOf(["asc", "desc"]),
        /**
         * Liste des ids de compte sélectionnés
         */
        selectedAccountIdList: PropTypes.array,
        /**
         * How many lines does chip fitlers have.
         */
        chipFilterLines: PropTypes.number,
        /**
         * How many lines does chip favorites have.
         */
        chipFavoritesLines: PropTypes.number,
    };

    static defaultProps = {
        dense: false,
        sortDirection: "asc",
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            JSON.stringify(nextProps.columnList) !== JSON.stringify(this.props.columnList) ||
            nextProps.sortColumnId !== this.props.sortColumnId ||
            nextProps.sortDirection !== this.props.sortDirection ||
            nextProps.selectedAccountIdList !== this.props.selectedAccountIdList ||
            JSON.stringify(nextProps.columnParams) !== JSON.stringify(this.props.columnParams) ||
            JSON.stringify(nextProps.formParams) !== JSON.stringify(this.props.formParams) ||
            JSON.stringify(nextProps.lookupColumnIdList) !==
                JSON.stringify(this.props.lookupColumnIdList) ||
            nextProps.chipFilterLines !== this.props.chipFilterLines ||
            nextProps.chipFavoritesLines !== this.props.chipFavoritesLines ||
            nextProps.isDisplaySelector !== this.props.isDisplaySelector ||
            nextProps.isDisplayRank !== this.props.isDisplayRank ||
            nextProps.isDisplayFavorites !== this.props.isDisplayFavorites ||
            nextProps.fetchAmountStats !== this.props.fetchAmountStats
        );
    }

    render() {
        const {
            columnList,
            dense,
            module,
            attributes,
            columnParams,
            formParams,
            onFilterChange,
            onSort,
            sortColumnId,
            sortDirection,
            lookupColumnIdList,
            isDisplaySelector,
            isDisplayRank,
            onClickBackToTop,
            defaultSortParams,
            fetchAmountStats,
            handleColumnsOrderUpdate,
            chipFilterLines,
            chipFavoritesLines,
        } = this.props;

        let top = 64;
        if (!isMobile) {
            // add result bar height
            top += 48;
            // add filter block height
            if (chipFilterLines) {
                top += 40;
                // add extra filter lines height
                if (chipFilterLines > 1) {
                    top += (chipFilterLines - 1) * 26 - 2;
                }
            }
            // add favorite block height
            if (chipFavoritesLines) {
                top += 48;
                // add single line margin
                if (chipFavoritesLines === 1) {
                    top += 14;
                }
                // add extra favorite lines height
                top += chipFavoritesLines * 26;
            }
        }

        let style = { top: top };

        return (
            <div
                id="search-results-header"
                className={classNames(this.props.className, {
                    dense: dense,
                    "not-filtered": chipFilterLines === 0,
                    "no-favorites-bar": chipFavoritesLines === 0,
                })}
                style={style}
            >
                {isDisplayRank && (
                    <div
                        className={classNames("sticky-left", "cell-header", "sticky-header", {
                            "relative-rank": isMobile,
                        })}
                    >
                        #
                    </div>
                )}
                {isDisplaySelector && (
                    <div
                        className={classNames("sticky-left", "cell-header", "sticky-header", {
                            "sticky-with-rank": isDisplayRank,
                        })}
                    />
                )}
                {columnList
                    .filter((column) => column.displayed !== false)
                    .map(({ colId, ...column }, index) => (
                        <Cell
                            key={`${colId}-${index}`}
                            module={module}
                            colId={colId}
                            index={index}
                            attribute={attributes[colId]}
                            lookedUp={lookupColumnIdList.includes(colId)}
                            formParam={formParams[colId]}
                            formParamAccounts={formParams.account}
                            onFilterChange={onFilterChange}
                            onSort={!isMobile ? onSort : undefined}
                            sortDirection={sortDirection}
                            sorted={colId === sortColumnId}
                            selectedFilterIdList={columnParams[colId]}
                            sortable={attributes[colId].displayOptions.sortable !== false}
                            filterable={attributes[colId].displayOptions.filterable !== false}
                            hasColumnParam={columnParams.hasOwnProperty(colId)}
                            isDisplaySelector={isDisplaySelector}
                            isDisplayRank={isDisplayRank}
                            onClickBackToTop={onClickBackToTop}
                            defaultSortParams={defaultSortParams}
                            fetchAmountStats={fetchAmountStats}
                            columnList={columnList}
                            handleColumnsOrderUpdate={handleColumnsOrderUpdate}
                            {...column}
                        />
                    ))}
            </div>
        );
    }
}

const StyledHeader = styled(HeaderCell)`
    display: flex;
    height: ${HEADER_CELL_HEIGHT}px;
    min-width: 100%;
    background-color: ${({ theme }) => theme.palette.background3};
    position: sticky;
    top: 236px;
    z-index: 13;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    .dense {
        height: ${HEADER_CELL_HEIGHT_DENSE}px;
    }

    .cell-header {
        display: inline-flex;
        overflow: hidden;
        height: 40px;
        align-self: center;
        align-items: center;
        justify-content: flex-end;
    }

    .sticky-left-header {
        position: sticky;
        left: 0px;
    }

    .sticky-header {
        width: 40px;
        position: sticky;
        left: 0px;
        padding-right: 8px;
        padding-left: 0px;
        background-color: inherit;
        z-index: 14;
    }

    .sticky-with-rank {
        left: 40px !important;
    }

    .sticky-with-selector-and-rank {
        left: 80px;
    }

    .relative-rank {
        position: relative;
    }

    .not-filtered {
        top: 196px;
    }

    .no-favorites-bar {
        top: 148px;
        &.not-filtered {
            top: 108px;
        }
    }
`;

export default StyledHeader;
