import React from "react";
import DesktopMac from "mdi-material-ui/DesktopMac";
import Cellphone from "mdi-material-ui/Cellphone";
import Tablet from "mdi-material-ui/Tablet";

const devices = {
    id: "devices",
    data: [
        {
            id: "desktop",
            label: "ref.transaction.devices.desktop",
            icon: <DesktopMac />,
        },
        {
            id: "mobile",
            label: "ref.transaction.devices.mobile",
            icon: <Cellphone />,
        },
        {
            id: "tablet",
            label: "ref.transaction.devices.tablet",
            icon: <Tablet />,
        },
    ],
    translate: true,
};

export default devices;
