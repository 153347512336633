import attributes from "./attributes";

/**
 * Form
 *
 * L'état initial du formulaire de recherche.
 */
export const defaultFields = {
    email: null,
    firstname: null,
    lastname: null,
    status: null,
    role: null,
    company: null,
    department: null,
};

export const availableFields = [
    attributes.email.id,
    attributes.firstname.id,
    attributes.lastname.id,
    attributes.status.id,
    attributes.type.id,
    attributes.role.id,
    attributes.company.id,
    attributes.department.id,
    attributes.partnership.id,
    attributes.phone.id,
    attributes.country.id,
    attributes.gdpr_access.id,
    attributes.privileges.id,
];
